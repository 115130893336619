import React, { useEffect } from "react";
import { useState } from "react";
import {
    Grid,
    Button,
    TextField,
    FormLabel,
    Typography,
    Autocomplete,
    FormControlLabel,
    Radio,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    getAllTemplates,
    getTemplateHeader,
    getCommanConfig
} from "../../../../redux/actions";
import theme, {
    buttonClasses,
    inputClasses,
    textClasses,
} from "../../../../static/styles/theme";
import {
    capitalizeSentence,
} from "../../../../services/functions";
import history from "../../../../services/history";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import AlertDialog from "../../../Components/DialogBox/dialogbox";

const ReceiveASNDialogBox = ({ status, entry, handleClose }) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState("");
    const [receivingMethod, setReceivingMethod] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [allTemplates, setAllTemplates] = useState([]);
    const [configDetail, setConfigDetail] = useState({});
    const templateHeader = useSelector((state) => state.inventory.templateHeader);
    const receivingMethodOptions = configDetail?.receivingMethod?.allOptions || [];


    const fetchTemplate = (templateLevel = "", callback) => {
        if (templateLevel) {
            dispatch(
                getAllTemplates(
                    {
                        type: "wms",
                        templateType: "inwardDataEntry",
                        status: "published",
                        fetchAttributes: true,
                        templateLevel: templateLevel || ""
                    },
                    (data) => {
                        setAllTemplates(data || [])
                        if (callback) callback(data)
                    }
                )
            );
        }
        else {
            setAllTemplates([])
            if (callback) callback([])
        }
    }

    const redirectingLogic = async () => {
        try{
            setLoader("initialPhase")
            const [tempConfigData, headers] = await Promise.all([
                new Promise((resolve) => {
                    dispatch(getCommanConfig({ configType: "asnReceiving" }, (data) => {
                        resolve(data?.configDetail || {});
                    }))
                }),
                new Promise((resolve) => {
                    dispatch(getTemplateHeader({ template_id: entry.template_id }, (data) => {
                        resolve(data);
                    }));
                }),
            ]);
            setLoader("")
            setConfigDetail(tempConfigData)
            const redirectedObject = {
                asnDetail: { ASN_Id: entry?.ASN_Id },
                asnTemplateHeader: headers,
                // asnTemplateHeader: templateHeader,
            };
            const allReceivingOptions = tempConfigData?.receivingMethod?.allOptions || []
            const selectedReceivingOption = allReceivingOptions?.find((dl) => dl?.value == tempConfigData?.receivingMethod?.value)
            let selectedReceivingMethod = ""
            if (allReceivingOptions?.length && selectedReceivingOption) {
                selectedReceivingMethod = selectedReceivingOption?.value;
            }
            else {
                selectedReceivingMethod = allReceivingOptions?.at(0)?.value || "pallets";
            }
            setReceivingMethod(selectedReceivingMethod)
            fetchTemplate(selectedReceivingOption?.templateType || "", (data) => {
                let selectedTemplate = {}
                if (data && data?.length) {
                    if (tempConfigData?.isSelectedTemplate?.isActive && tempConfigData?.isSelectedTemplate[selectedReceivingMethod]) {
                        let temp = data?.find((dl) => dl?._id == tempConfigData?.isSelectedTemplate[selectedReceivingMethod])
                        if (temp) {
                            selectedTemplate = temp
                        }
                        else {
                            selectedTemplate = data?.at(0) || {}
                        }
                    }
                    else {
                        selectedTemplate = data?.at(0) || {}
                    }
                }
                setSelectedTemplate(selectedTemplate);
                redirectedObject["receivingMethod"] = selectedReceivingMethod;
                redirectedObject["inwardTemplate"] = selectedTemplate;
                // Handle redirection 
                if (tempConfigData?.isAutoRedirect?.isActive==true) {
                    setLoader("redirectingPhase")
                    setTimeout(() => {
                        history.push("/dashboard/data/inward", redirectedObject);
                    }, tempConfigData?.isAutoRedirect?.value || 0);
                }
            })
        }
        catch (error) {
            setLoader("")
            console.error("Error in redirection:", error);
        }
    }

    useEffect(() => {
        if (status == "receive" && entry) {
            redirectingLogic()
            // let redirectedObject = {
            //     asnDetail: { ASN_Id: entry?.ASN_Id },
            //     asnTemplateHeader: templateHeader,
            // };
            // setLoader("initialPhase")
            // dispatch(getCommanConfig({ configType: "asnReceiving" }, (data) => {
            //     setLoader("")
            //     let tempConfigData = data?.configDetail || {}
            //     setConfigDetail(tempConfigData)
            //     const allReceivingOptions = tempConfigData?.receivingMethod?.allOptions || []
            //     const selectedReceivingOption = allReceivingOptions?.find((dl) => dl?.value == tempConfigData?.receivingMethod?.value)
            //     let selectedReceivingMethod = ""
            //     if (allReceivingOptions?.length && selectedReceivingOption) {
            //         selectedReceivingMethod = selectedReceivingOption?.value;
            //     }
            //     else {
            //         selectedReceivingMethod = allReceivingOptions?.at(0)?.value || "pallets";
            //     }
            //     setReceivingMethod(selectedReceivingMethod)
            //     fetchTemplate(selectedReceivingOption?.templateType || "", (data) => {
            //         let selectedTemplate = {}
            //         if (data && data?.length) {
            //             if (tempConfigData?.isSelectedTemplate?.isActive && tempConfigData?.isSelectedTemplate[selectedReceivingMethod]) {
            //                 let temp = data?.find((dl) => dl?._id == tempConfigData?.isSelectedTemplate[selectedReceivingMethod])
            //                 if (temp) {
            //                     selectedTemplate = temp
            //                 }
            //                 else {
            //                     selectedTemplate = data?.at(0) || {}
            //                 }
            //             }
            //             else {
            //                 selectedTemplate = data?.at(0) || {}
            //             }
            //         }
            //         setSelectedTemplate(selectedTemplate);
            //         redirectedObject["receivingMethod"] = selectedReceivingMethod;
            //         redirectedObject["inwardTemplate"] = selectedTemplate;
            //         // Handle redirection 
            //         if (tempConfigData?.isAutoRedirect?.isActive===true) {{
            //             setLoader("redirectingPhase")
            //             setTimeout(() => {
            //                 history.push("/dashboard/data/inward", redirectedObject);
            //             }, tempConfigData?.isAutoRedirect?.value || 0);
            //         }
            //     })
            // }))
            // dispatch(getTemplateHeader({ template_id: entry.template_id }));
        }
    }, [status]);

    return (
        <AlertDialog
            viewDialog={status != null}
            handleClose={handleClose}
            viewSearchInput={false}
            showDivider={true}
            width="60%"
            actionsCSS={{ justifyContent: 'flex-start' }}
            title={
                <Typography sx={{ ...textClasses.cardTitle }}>
                    Select Inward Data Entry Template & Receiving Method
                </Typography>
            }
            body={<>
                {(loader == "initialPhase" || loader == "redirectingPhase") ?
                    loader == "redirectingPhase"
                        ? <Grid item={true} data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
                            <Grid item><GenericLoader loaderText="ASN Receiving Initiating..." /></Grid>
                        </Grid>
                        : <Grid item={true} data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
                            <Grid item><GenericLoader /></Grid>
                        </Grid> :
                    status == "receive" && (
                        <>
                            <Grid container sx={{ mt: 1 }}>
                                <Grid container item sm={4}>
                                    <FormLabel required sx={{ mt: 1, color: "#000000" }}>
                                        Receiving Method :
                                    </FormLabel>
                                </Grid>
                                <Grid container item sm={8}>
                                    {receivingMethodOptions?.map((m, i) => {
                                        return (
                                            <Grid item sm={6} key={i}>
                                                <FormControlLabel
                                                    onClick={() => {
                                                        setReceivingMethod(m.value)
                                                        setSelectedTemplate({});
                                                        if (m?.value) {
                                                            const selectedReceivingOption = receivingMethodOptions?.find((dl) => dl?.value == m.value)
                                                            fetchTemplate(selectedReceivingOption?.templateType)
                                                        }
                                                        else {
                                                            setAllTemplates([])
                                                        }
                                                    }}
                                                    checked={receivingMethod == m.value}
                                                    label={
                                                        <Typography sx={{ fontSize: "13px" }}>
                                                            {m.label}
                                                        </Typography>
                                                    }
                                                    control={
                                                        <Radio
                                                            value={m.value}
                                                            sx={{
                                                                "&, &.Mui-checked": { color: theme.themeOrange },
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mt: 1 }}>
                                <Grid container item sm={4}>
                                    <FormLabel required sx={{ mt: 1, color: "#000000" }}>
                                        Select Template :
                                    </FormLabel>
                                </Grid>
                                <Grid container item sm={8}>
                                    <Autocomplete
                                        value={selectedTemplate}
                                        onChange={(e, option) => {
                                            setSelectedTemplate(option);
                                        }}
                                        getOptionLabel={(option) =>
                                            capitalizeSentence(option.name || "")
                                        }
                                        size="small"
                                        options={allTemplates}
                                        sx={{
                                            ...inputClasses.filterField,
                                            minWidth: "220px",
                                            m: 0,
                                            ".MuiInputBase-input": {
                                                fontSize: "14px",
                                                color: "#455A64",
                                            },
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{ color: "#455A64", ...textClasses.normalText }}
                                                {...params}
                                                label="Template"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
            </>
            }

        >
            <Button
                variant="outlined"
                size="small"
                sx={{ ...buttonClasses.lynkitBlackEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                onClick={handleClose}
            >
                Cancel
            </Button>
            <Button
                variant="contained"
                size="small"
                sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                disabled={selectedTemplate == null || receivingMethod == null}
                onClick={() =>
                    history.push("/dashboard/data/inward", {
                        asnDetail: { ASN_Id: entry?.ASN_Id },
                        asnTemplateHeader: templateHeader,
                        inwardTemplate: selectedTemplate,
                        receivingMethod: receivingMethod,
                    })
                }
            >
                {status == "receive" ? "Receive" : { status }}
            </Button>
        </AlertDialog>


    );
};

export default ReceiveASNDialogBox;