import React, { useState,useEffect  ,Children, cloneElement} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Card, CardContent, Typography, Box, IconButton, Divider, Grid, Skeleton } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import theme, { textClasses } from "../../../../../static/styles/theme";
import DateRangeFilter from "../../DateRangeFilter";
import SimCardDownloadOutlined  from '@mui/icons-material/SimCardDownloadOutlined';
import GenericLoader from "../../../../Components/Generic/GenericLoader";
const AnalyticDynamicChartWidget = ({ type, title, data, 
    xAxisLabel, yAxisLabel, seriesConfig, item, setLayout ,styleView,isDefaultDashboard = false,dataLoader=false, children}) => {
    // console.log("coming here",type,title,data,xAxisLabel,yAxisLabel,seriesConfig)
    const [dateRange, setDateRange] = useState("7d");
    const handleFilterChange = (range, customRange) => {
        // console.log("rangeComing",range,customRange);
      setDateRange(range === "dateRange" ? customRange.from- customRange.to : range);
    };
//   console.log("dateRangeasd",dateRange);
  // Separate children into TypeAndSearch and DateRangeFilter
  const searchFilter = Children.toArray(children).find(child => child?.type?.name === "TypeAndSearch");
  const skuFilter = Children.toArray(children).find(child => child?.props["data-testid"] === "skuFilter");

  const dateFilter = Children.toArray(children).find(child => child?.type?.name === "DateRangeFilter");

  useEffect(() => {
        
    const chart = am4core.create(`chart-${type}`, type === "PieChart" ? am4charts.PieChart : am4charts.XYChart);
    chart.data = data;
    // Disable amCharts logo
    chart.logo.disabled = true;

    if (type !== "PieChart") {
      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.title.text = xAxisLabel;

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = yAxisLabel;

      seriesConfig?.forEach(({ name, field, color }) => {
        const series = chart.series.push(type === "BarChart" ? new am4charts.ColumnSeries() : new am4charts.LineSeries());
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "category";
        series.name = name;
        series.stroke = am4core.color(color);
        series.fill = am4core.color(color);
      });
    } else {
      const pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "category";
      chart.data = [
        { category: "A", value: Math.floor(Math.random() * 100) + 10 },
        { category: "B", value: Math.floor(Math.random() * 100) + 10 },
        { category: "C", value: Math.floor(Math.random() * 100) + 10 },
        { category: "D", value: Math.floor(Math.random() * 100) + 10 }
      ];
     
      chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom"; // Positions the legend at the bottom
      chart.legend.maxHeight = 100; // Ensures it doesn't take up too much space
      chart.legend.scrollable = true; 
     
    return () => chart.dispose();
}  
  }, [type, data, seriesConfig, xAxisLabel, yAxisLabel,dataLoader]);


  return (
      <Card sx={{ width: 'inherit', height: 'inherit', p: 1, ...styleView }}>
          <IconButton
              size="small"
              sx={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  color: theme.themeOrange,
              }}
              onClick={() => {
                  setLayout((prev) => prev.filter((l) => l.i !== item.i));
              }}
          >
              <ClearIcon fontSize="small" />
          </IconButton>
          <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Grid item xs={3} >
                  <Typography sx={{ ...textClasses.t16n, color: theme.themeOrange }}>{title}</Typography>
              </Grid>
              <Grid item xs={1}>
                  <IconButton size="small" sx={{ color: theme.themeOrange, }} onClick={() => { }}>
                      <SimCardDownloadOutlined />
                  </IconButton>
              </Grid>
          </Grid>
          {searchFilter && (
              <Grid container spacing={2} alignItems="center" justifyContent= "flex-end"  >
                  <Grid item xs={12} md={4}>
                      {searchFilter}
                  </Grid>
                  {
                      skuFilter && <Grid item xs={12} md={4}>
                          {skuFilter}
                      </Grid>
                  }

              </Grid>
          )}
          <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 1 }}>
              <Grid item xs={4} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                  {/* Here will be the job name selection come */}
              </Grid>
              {
                  isDefaultDashboard
                      ? <Box sx={{
                          padding: 2
                      }}>
                          {dateFilter}
                      </Box>
                      : item?.isDateFilter
                          ? <Box sx={{ display: "flex", justifyContent: "flex-end", px: 2, mt: -1 }}>
                              <DateRangeFilter dateFilterOption={item?.dateFilterOption} />
                          </Box>
                          : ""
              }
          </Grid>
          <Divider />
         {!dataLoader ? (<CardContent>
              {/* <Typography variant="h6">{title}</Typography> */}
              <Box sx={{ display: "flex", gap: 2, mt: 1, justifyContent: 'flex-end' }}>
                  {type != "PieChart" && seriesConfig?.map(({ name, color }) => (
                      <Typography key={name} sx={{ display: "flex", alignItems: "center", gap: 1, ...textClasses.t13n }} >
                          <Box sx={{ width: 12, height: 12, backgroundColor: color, borderRadius: "50%" }} /> {name}
                      </Typography>
                  ))}
              </Box>
              <Box sx={{ height: 300 }} id={`chart-${type}`} />
          </CardContent> ) :<Grid>
                  <GenericLoader />
          </Grid> }
      </Card>
  );
};

export default AnalyticDynamicChartWidget;