import React from 'react'
import { Box, Paper, TablePagination, Grid } from '@mui/material'
import theme, { textClasses } from '../../../static/styles/theme';
import { DataNotFound } from '../TemplateInputConfiguration';
  
export default function GenericTable({ header, rows, totalRows, page, limit, setPage, setLimit, handleRowsPerChange }) {
    return (
        <Paper>
            <Box className="muitable" sx={{
                maxWidth: "100vw",
                // maxWidth: "1260px",
                overflowX: "auto",
            }}>
                <table style={{ width: "100%" }}>
                    <thead style={{ backgroundColor: theme.themeGray }}>
                        <tr className='roundedRow'>
                            {
                                header.slice(0, 2).map((head, i) => {
                                    return (
                                        <td style={{ padding: "13px 10px 13px 10px", ...textClasses.boldText, textAlign: "center" }}>{head}</td>
                                    )
                                })
                            }
                            {
                                header.slice(2, header.length).map((head, i) => {
                                    return (
                                        <td style={{ padding: "13px 10px 13px 10px", ...textClasses.boldText, textAlign: "center" }}>{head}</td>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rows.length > 0
                                ?
                                <>
                                    {
                                        rows.map((row, i) => (
                                            <tr
                                                key={i}
                                                style={{ borderBottom: "1px solid #0000002B" }}
                                            >
                                                {
                                                    row.map((col, j) => {
                                                        return (
                                                            // <td component="th" scope="row" style={{...textClasses.normalText, textAlign: "left", overflowWrap: "break-word", padding: "5px"}}>
                                                            <td style={{ padding: "10px 5px 10px 10px", ...textClasses.normalText, textTransform: "capitalize", textAlign: "center" }}>
                                                                {col}
                                                            </td>
                                                        )
                                                    })
                                                }

                                            </tr>
                                        ))
                                    }

                                </>
                                :
                                <tr
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <td component="th" scope="row" colSpan={11} style={{ ...textClasses.normalText, textAlign: "center", overflowWrap: "break-word" }}>
                                        <Grid container justifyContent={"center"} alignItems="center">
                                            <DataNotFound />
                                        </Grid>
                                    </td>

                                </tr>


                        }
                    </tbody>
                </table>
                {
                    rows.length > 0 && totalRows &&
                    <Grid container justifyContent="center" justifyItems="center">
                        <TablePagination
                            component="div"
                            count={totalRows}
                            page={page}
                            onPageChange={(e, newPage) => setPage(newPage)}
                            rowsPerPage={limit}
                            onRowsPerPageChange={(e) => { setLimit(e.target.value); setPage(0) }}
                            sx={{
                                ".MuiTablePagination-displayedRows": {
                                    "margin-top": "1em",
                                    "margin-bottom": "1em"
                                },
                                ".MuiTablePagination-selectLabel": {
                                    "margin-top": "1em",
                                    "margin-bottom": "1em"
                                }
                            }}
                        />
                    </Grid>
                }
            </Box>
        </Paper>
    );
}
