import React, { useEffect } from 'react'
import CountCardWidget from './CountCardWidget'
const CountTypeWidget = ({ item, setLayout ,styleView={} ,isDefaultDashboard=false ,dataLoader=false ,children  }) => {
        
    return (
            <CountCardWidget item={item} setLayout={setLayout}  styleView={styleView} isDefaultDashboard={isDefaultDashboard} dataLoader={dataLoader} >
                <div style={{ display: "flex", justifyContent: "flex-end"}}>
            {children}
        </div></CountCardWidget>
    )
}

export default CountTypeWidget;