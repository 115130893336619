import { FETCH_DRAGGABLE_WIDGETS, FETCH_ALL_CUSTOM_DASHBOARD, FETCH_CUSTOM_DASHBOARD_STATUS_COUNT, FETCH_CUSTOM_DASHBOARD_DETAIL,FETCH_DEFAULT_DASHBOARD_DATA, FETCH_CUSTOM_DASHBOARD_JOB_STATUS_COUNT, FETCH_WAREHOUSE_COUNT_DATA, GET_WAREHOUSE_OPRATION_COUNT_CUSTOM_DASHBOARD, FETCH_CUSTOM_DASHBOARD_WAREHOUSE_INVENTORY} from "../types"
const INIT_STATE = {
    allDraggableWidgets:{},
    allCustomDashboardList:[],
    customDashboardRowsCount:0,
    statusCount:0,
    customDashboardDetail:{},
    defaultDashboardData:{},
    warehouseCountData:{},
    jobStatusData:{},
    customWarehouseInventory:{},
    customWarehouseOperation:{}
}
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_DRAGGABLE_WIDGETS: return { ...state, allDraggableWidgets: action.payload || {} }
        case FETCH_ALL_CUSTOM_DASHBOARD: return { ...state, allCustomDashboardList: action?.payload?.rows || [], customDashboardRowsCount: action?.payload?.count || 0 }
        case FETCH_CUSTOM_DASHBOARD_STATUS_COUNT: return { ...state, statusCount: action.payload }
        case FETCH_CUSTOM_DASHBOARD_DETAIL: return { ...state, customDashboardDetail: action?.payload?.data }
        case FETCH_DEFAULT_DASHBOARD_DATA: return{ ...state ,defaultDashboardData:action?.payload?.data }
        case FETCH_WAREHOUSE_COUNT_DATA :return{ ...state ,warehouseCountData:action?.payload?.data }
        case FETCH_CUSTOM_DASHBOARD_JOB_STATUS_COUNT: return { ...state, jobStatusData: action.payload.data }
        case FETCH_CUSTOM_DASHBOARD_WAREHOUSE_INVENTORY : return{ ...state , customWarehouseInventory: action?.payload.data}
        case GET_WAREHOUSE_OPRATION_COUNT_CUSTOM_DASHBOARD : return{ ...state , customWarehouseOperation: action?.payload.data}
        default: return state
    }
}