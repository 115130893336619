import React, { useEffect, useState , useMemo} from 'react'
import {
    Grid, Card, Typography,Box, 
} from "@mui/material";
import colors, { inputClasses, cardClasses, textClasses, buttonClasses } from '../../static/styles/theme';
import { showToast } from "../../services/functions";
import axios from '../../services/Api';
import GenericLabelTable from "../Components/CustomTable/GenericLabelTable";

const INVDetail = (props) => {
 
    const [state,setState]=useState({loader:true,data:[], tableData:{}})

    const fetchData = async () => {
        let { search, origin, pathname } = window.location;
    
        setState((prev) => ({ ...prev, loader: true, data: [], tableData:{} }));
    
        try {
            const { data } = await axios.get(pathname + search);
    
            setState((prev) => ({
                ...prev,
                loader: false,
                data: data?.data || [],
                tableData: data?.itemData || {},
            }));
        } catch (error) {
            console.error("Error fetching data:", error.message);
            setState((prev) => ({ ...prev, loader: false, data: [], tableData:{} }));
        }
    };
    useEffect(()=>{
        fetchData()
    },[])

    const rows = useMemo(() => {
        let tempRows = [];
        if ( state?.tableData?.isItemExist && state?.tableData?.data && state?.tableData?.data?.length > 0) {
            state.tableData?.data?.forEach((ele, index) => {
                let eachRow = [index + 1]; // Add index as the first column
    
                state?.tableData?.headers?.forEach(header => {
                    eachRow.push(ele[header.value]); // Extract value based on header.value
                });
    
                tempRows.push(eachRow);
            });
        }
        return tempRows;
    }, [state?.tableData?.data, state?.tableData?.headers]); // Add dependencies

    return(
        <>
            <Grid container sx={{ height: "100vh", backgroundColor: "#F77200" }}
                justifyContent="center" alignItems="center" direction={"column"}
            >
                <Card item sx={{
                    p: 3, ...cardClasses.basic, minWidth: "30vw", minHeight: "20vh", maxHeight: '90vh', overflowY: 'auto',    // For WebKit browsers (Chrome, Safari)
                    "&::-webkit-scrollbar": {
                        width: "3px", // Make it very thin
                        borderTopRightRadius:'100px'
                    },
                    "&::-webkit-scrollbar-track": {
                        background: "transparent", // Faint track line
                        borderTopRightRadius: "15px",  
                    },
                    "&::-webkit-scrollbar-thumb": {
                        background: "linear-gradient(to bottom, transparent 10px, gray 10px, gray calc(100% - 15px), transparent calc(100% - 15px))",
                        borderTop:'15px solid transparent'
                    },
                    // For Firefox
                    scrollbarWidth: "auto", // "auto" or "thin"
                }} className="mui-custom-card">
                    <Box className="item-detail">
                        {
                            state?.data && state?.data?.map((detailElement, detaiIndex) => {
                                return (
                                    <Typography key={detaiIndex}>
                                        <span style={{
                                            "fontSize": "14px",
                                            "fontWeight": "700",
                                            "lineHeight": "17px"
                                        }}>{detailElement.label} : </span>
                                        <span style={{
                                            "fontSize": "14px",
                                            "fontWeight": "00",
                                            "lineHeight": "17px"
                                        }}>{detailElement.value || "-"}</span>
                                    </Typography>
                                )
                            })
                        }
                        {
                            state?.tableData?.isItemExist &&
                            <Box sx={{mt:2}}>
                                <GenericLabelTable
                                    headers={["Sr.NO", ...state?.tableData?.headers?.map(dl => dl?.label)]}
                                    rows={rows || []}
                                    headerValueStyle={
                                        {
                                            "fontSize": "12px",
                                            "fontWeight": "800",
                                            "lineHeight": "10px",
                                            "whiteSpace": "nowrap"
                                        }
                                    }
                                    rowValueStyle={
                                        {
                                            "fontSize": "12px",
                                            "fontWeight": "000",
                                            "lineHeight": "10px",
                                            "padding": "4px 8px"
                                        }
                                    }
                                />
                            </Box>
                        }
                    </Box>
                </Card>

            </Grid>
        </>
    )
   

};

export default INVDetail;