/* eslint-disable react/react-in-jsx-scope */

import { toast } from 'react-toastify';
import $ from 'jquery'
import axios, { all } from 'axios';
import url from '../services/config'
import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import * as XLSX from 'xlsx';
import SheetXLSX from 'sheetjs-style'
import * as FileSaver from 'file-saver';
import {
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
  IconButton,
  Autocomplete,
  Box,

} from "@mui/material";
// import "../static/css/toast.css"
import {
  inputClasses,
  textClasses
} from "../static/styles/theme";
import {
  Clear,
  Search,
} from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import ClearIcon from "@mui/icons-material/Clear";
import TypeAndSearch from '../views/Components/TypeAndSearch';
import { Number_Only_Validation } from './regexValidation';
import GenericMultiDateRange,{ multiDateToISOConvertor } from "../views/Components/Generic/GenericMultiDateRange";
import { getOptionValueLabel } from './formFunction';



export const getSmallestRepeatingSubstring = (str, length = 6) => {
  const n = str.length;
  const strLen = length || 6

  // Try substrings from length 6 up to half the string length
  for (let len = 6; len <= n; len++) {
    if (n % len === 0) { // The substring should evenly divide the full string
      const sub = str.slice(0, len);
      const repeated = sub.repeat(n / len);

      if (repeated === str) {
        return sub; // Return the smallest valid repeating substring
      }
    }
  }

  return str; // If no repeating pattern found, return original string
}


export const showToast = (message, error) => {
  if (error) {
    toast.error(message, { position: toast.POSITION.TOP_RIGHT, autoClose: 3000, className: 'toast-message' });
  } else {
    toast.success(message, { position: toast.POSITION.TOP_RIGHT, autoClose: 3000, className: 'toast-message' });
  }
}

export const getSpentTime = (then, now) => {
  if (then != null) {
      then = moment(then);
      now = now ? moment(now) : moment();

      let dayDiff = now.diff(then, "days");
      let hourDiff = now.diff(then, "hours");
      let minDiff = now.diff(then, "minutes");
      let timeSpent;

      if (dayDiff < 1) {
          if (hourDiff < 1) {
              timeSpent = `${minDiff} min ago`
          }
          else {
              timeSpent = `${hourDiff} hrs ago`
          }
      }
      else {
          timeSpent = `${dayDiff} d ago`
      }
      return timeSpent
  }
  else {
      return "-"
  }
}
export const hexToRgba = (hex, opacity) => {
  // Remove the '#' symbol if present
  hex = hex.replace(/^#/, '');

  // Parse the hex values for red, green, and blue
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Ensure the opacity is a valid number between 0 and 1
  const validOpacity = isNaN(opacity) ? 1 : Math.min(Math.max(opacity, 0), 1);

  // Return the RGBA color code
  return `rgba(${r}, ${g}, ${b}, ${validOpacity})`;
}

export const getCamelCaseText = (text = "") => {
  let parts = text.toLowerCase().split(" ");
  parts = parts.map(p => p.charAt(0).toUpperCase() + p.slice(1));
  return parts.join(" ");
}

export const timeConversionFunction = (value) => {
  let seconds = value

  let minutes = Math.round(Math.abs(seconds / 60));
  let hours = Math.round(Math.abs(minutes / 60));
  let days = Math.round(Math.abs(hours / 24));
  let months = Math.round(Math.abs(days / 30.416));
  let years = Math.round(Math.abs(days / 365));
  if (Number.isNaN(seconds)) {
    return '';
  } else if (seconds <= 45) {
    return 'a few seconds';
  } else if (seconds <= 90) {
    return '1 minute';
  } else if (minutes <= 45) {
    return minutes + ' minutes';
  } else if (minutes <= 90) {
    return '1 hour';
  } else if (hours <= 22) {
    return hours + ' hours';
  } else if (hours <= 36) {
    return '1 day';
  } else if (days <= 25) {
    return days + ' days';
  } else if (days <= 45) {
    return '1 month';
  } else if (days <= 345) {
    return months + ' months';
  } else if (days <= 545) {
    return '1 year';
  } else {
    return years + ' years';
  }
}
export const getShortText = (text, limit) => {
  // //(text.length > limit ? text.substring(0, limit) + "..." : text);
  return text.length > limit ? text.substring(0, limit) + "..." : text
}

export const _showModal = (id) => {
  $(".custom-modal-overlay").addClass("custom-modal-is-visible")
  $(`#${id}`).addClass("custom-modal-is-visible")
}

export const _closeModal = () => {
  $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
  $(".custom-modal").removeClass("custom-modal-is-visible")
}


export const selectInit = (callback, elem = '') => {
  window.jQuery((elem ? elem : ".select2-single") + ":not(.bound)").addClass('bound').select2({
    maximumSelectionSize: 6,
    containerCssClass: ":all:",
    minimumResultsForSearch: 10
  }).on('change', (evt) => {
    callback(evt.target.id, evt.target.value)
  })
}

export const base64Convert = (file) => {
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onload = (e) => {
      resolve(fr.result)
    }
    fr.onerror = (error) => {
      reject('Error: ' + error);
    }
    fr.readAsDataURL(file)
  })
}

export const base64MimeType = (encoded) => {
  var result = null;
  if (typeof encoded !== 'string') {
    return result;
  }
  try {
    var mime = encoded.split(";base64")[0].split(":")[1].split("/")
    if (mime && mime.length) {
      if (mime[0] === 'image') {
        result = mime[0]
      }
      else {
        result = mime[1];
      }
    }
  }
  catch (e) {
    console.error(e.message)
  }
  return result;
}

export const get_pdf_thumbnail = (base64) => {
  return new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = function (ev) {
      window.pdfjsLib.getDocument(fileReader.result).promise.then(function (doc) {
        // var pages = []; while (pages.length < doc.numPages) pages.push(pages.length + 1);
        return doc.getPage(1).then(makeThumb)
          .then(function (canvas) {
            resolve(canvas.toDataURL('image/jpeg'))
          })
          .catch((err) => {
            //("Error",err)
            resolve()
          })
        // return Promise.all(pages.map(function (num) {
        // }))
      }, function (error) {
        //(error);
        resolve()
      });
    };
    fileReader.readAsArrayBuffer(base64);
  })
}

//single file
export const convertDocsBase64 = (file) => {
  if (file) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
          reject(error);
        }
      }, 400)
    })
  }
}

export const isFileValid = (file, allowedExtensions = ['jpg', 'jpeg', 'png']) => {
  const fileExtension = file.name.split('.').pop().toLowerCase();
  return allowedExtensions.includes(fileExtension);
};

export const make_donut_chart = (id, options) => {
  var canvas = document.getElementById(id)
  var ctx = canvas.getContext("2d")
  var total_value = 0;
  var color_index = 0;
  var colors = []
  for (var categ of options.data) {
    colors.push(categ.color)
    total_value += categ.value
  }
  ctx.clearRect(0, 0, canvas.width, canvas.height)
  if (total_value === 0) {
    drawPieSlice(
      ctx,
      canvas.width / 2,
      canvas.height / 2,
      Math.min(canvas.width / 2, canvas.height / 2),
      0,
      6.28319,
      "#eaeaea",
      ""
    )
  }
  else {
    var start_angle = 0;
    for (categ of options.data) {
      var slice_angle = 2 * Math.PI * categ.value / total_value;
      drawPieSlice(
        ctx,
        canvas.width / 2,
        canvas.height / 2,
        Math.min(canvas.width / 2, canvas.height / 2),
        start_angle,
        start_angle + slice_angle,
        colors[color_index % colors.length],
        categ['label']
      )
      start_angle += slice_angle;
      color_index++;
    }
    //drawing a white circle over the chart
    //to create the doughnut chart
    if (options.doughnutHoleSize) {
      drawPieSlice(
        ctx,
        canvas.width / 2,
        canvas.height / 2,
        options.doughnutHoleSize * Math.min(canvas.width / 2, canvas.height / 2),
        0,
        2 * Math.PI,
        "#fff",
        "Total-" + total_value
      );
    }
    start_angle = 0;
    for (categ of options.data) {
      slice_angle = 2 * Math.PI * categ.value / total_value;
      var pieRadius = Math.min(canvas.width / 2, canvas.height / 2);
      var labelX = canvas.width / 2 + (pieRadius / 2) * Math.cos(start_angle + slice_angle / 2);
      var labelY = canvas.height / 2 + (pieRadius / 2) * Math.sin(start_angle + slice_angle / 2);

      if (options.doughnutHoleSize) {
        var offset = (pieRadius * options.doughnutHoleSize) / 2;
        labelX = canvas.width / 2 + (offset + pieRadius / 2) * Math.cos(start_angle + slice_angle / 2);
        labelY = canvas.height / 2 + (offset + pieRadius / 2) * Math.sin(start_angle + slice_angle / 2);
      }

      // var labelText = Math.round(100 * categ.value / total_value);
      ctx.fillStyle = "white";
      ctx.font = "bold 12px Arial";
      if (categ.label && categ.label != '0') {
        ctx.fillText(categ.label, labelX, labelY);
      }
      start_angle += slice_angle;
    }
  }
}

export const make_pie_chart = (id, arr) => {
  var canvas = document.getElementById(id);
  var elemLeft = canvas.offsetLeft
  var elemTop = canvas.offsetTop
  var elements = []
  var ctx = canvas.getContext("2d");
  var lastend = 0;
  var data = [];
  var myTotal = 0;
  var myColor = [];
  var labels = [];
  arr.map((el) => {
    data.push(el.data)
    myColor.push(el.color)
    labels.push(el.label)
  })

  for (var e = 0; e < data.length; e++) {
    myTotal += data[e];
  }

  // make the chart 10 px smaller to fit on canvas
  var off = 10
  var w = (canvas.width - off) / 2
  var h = (canvas.height - off) / 2
  for (var i = 0; i < data.length; i++) {
    ctx.fillStyle = myColor[i];
    ctx.strokeStyle = 'white';
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(w, h);
    var len = (data[i] / myTotal) * 2 * Math.PI
    var r = h - off / 2
    ctx.arc(w, h, r, lastend, lastend + len, false);
    ctx.lineTo(w, h);
    ctx.fill();
    ctx.stroke();
    ctx.fillStyle = 'white';
    ctx.font = "16px Arial";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    var mid = lastend + len / 2
    ctx.fillText(labels[i], w + Math.cos(mid) * (r / 2), h - 10 + Math.sin(mid) * (r / 2));
    ctx.font = "12px Arial";
    let percent = Math.floor(((data[i] / myTotal) * 100) + 0.5)
    ctx.fillText("(" + percent + "%)", w + Math.cos(mid) * (r / 2), h + 10 + Math.sin(mid) * (r / 2));
    elements.push({ start: lastend, end: Math.PI * 2 * (data[i] / myTotal), text: `${labels[i]}:${data[i]}`, w, h, r })
    lastend += Math.PI * 2 * (data[i] / myTotal);
  }
  // try{
  //   canvas.addEventListener('click', function(event) {
  //     //("hello")
  //     // var x = event.pageX - elemLeft,
  //     //     y = event.pageY - elemTop;
  //     // //(x, y);
  //     // //(elements)
  //     var mouse = oMousePos(canvas, event)
  //     try{
  //       elements.forEach(function(element) {
  //         // drawElement(element, ctx);
  //         let circle = new Path2D();
  //         circle.arc(element.w , element.h, element.r, element.start,element.end,false);
  //         let context = canvas.getContext("2d");
  //         if(context.isPointInPath(circle,mouse.x, mouse.y)){//(element.text)}else{//("not in path")}
  //       });

  //     }
  //     catch(e){
  //       //(e)
  //     }

  //   }, false);

  // }
  // catch(e){
  //   //(e)
  // }
}

export const formatLineChartData = (values, chartHeight) => {

  const widgetSize = chartHeight;
  const pointSize = 16;

  const base = (widgetSize - pointSize / 2) / values.length;

  let sortedValues = sortValues([...values]);

  const topMostPoint = sortedValues[0].value;
  let leftOffset = pointSize; //padding for left axis labels
  let nextPoint = 0;
  let rise = 0;
  let cssValues = [];

  for (var i = 0, len = values.length - 1; i < len; i++) {

    var currentValue = {
      left: 0,
      bottom: 0,
      hypotenuse: 0,
      angle: 0,
      value: 0
    };

    currentValue.value = values[i].value;
    currentValue.name = values[i].name;
    currentValue.left = leftOffset;
    leftOffset += base;
    currentValue.bottom = (widgetSize - pointSize) * (currentValue.value / topMostPoint);
    nextPoint = (widgetSize - pointSize) * (values[i + 1].value / topMostPoint);

    rise = currentValue.bottom - nextPoint;
    currentValue.hypotenuse = Math.sqrt((base * base) + (rise * rise));
    currentValue.angle = radiansToDegrees(Math.asin(rise / currentValue.hypotenuse));

    cssValues.push(currentValue);
  }

  var lastPoint = {
    left: leftOffset,
    bottom: (widgetSize - pointSize) * (values[values.length - 1].value / topMostPoint),
    hypotenuse: 0,
    angle: 0,
    value: values[values.length - 1].value,
    name: values[values.length - 1].name
  };

  cssValues.push(lastPoint);

  return cssValues;
}

export const removeLeadingZeros = (number) => {
  // Convert the number to a string
  const numberStr = number.toString();

  // Base case: if the number is empty or the first character is not zero, return the number
  if (numberStr === '' || numberStr[0] !== '0') {
    return number;
  }

  // Recursive case: remove the leading zero by calling the function again with the remaining digits
  const remainingDigits = numberStr.slice(1);
  return removeLeadingZeros(Number(remainingDigits));
}

export const removeLeadingChars = (numberStr) => {
  let str = numberStr.toString();
  if (str.length === 0) {
    return '';
  }

  const firstChar = str[0];

  if (!isNaN(firstChar) && firstChar !== ' ') {
    return str;
  }

  return removeLeadingChars(str.slice(1));
}

export const dateTimeFormater = (date, format ) => {
  let tempFormat=format ||  "DD MMM YYYY hh:mm A"
  if (date) return moment(date).format(tempFormat);
  else return "-"
}

export const textCapitalize = (d) => {
  try {
    let dataArr = d.split(/(?=[A-Z])/)
    let label = ""
    for (let data of dataArr) {
      data = data.slice(0, 1).toUpperCase() + data.slice(1).toLowerCase()
      if (label) {
        label = label + " " + data
      }
      else {
        label = data
      }
    }
    return label

  }
  catch (error) {
    console.log(error)
    return ""
  }
}

export const getMonthlyMaxDays = (type = "current", currentScheduleTime = new Date()) => {
  // If the next month has 28 days, add one day to the date.
  const now = new Date(currentScheduleTime);
  const year = now.getFullYear();
  const month = now.getMonth() + (type == "current" ? 0 : 1);

  // console.log("lastDayOfNextMonth1",year,month)
  // Calculate the last day of the next month
  const lastDayOfNextMonth = new Date(year, month + 1, 0).getDate();

  // console.log("lastDayOfNextMonth2",year,month)
  return lastDayOfNextMonth;
};
export const getTimeFormISODate = (currentTime = new Date(),isISOChangeRequired=true) => {
  let date = new Date(currentTime);
  if(isISOChangeRequired){
    date.setHours(date.getHours() - 5);
    date.setMinutes(date.getMinutes() - 30);
    date.setSeconds("00");
  }
  // console.log("getTimeFormISODate", currentTime, date)

  // Get the time components
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Format the time as a string (e.g., "18:30:59")
  const timeString = `${hours}:${minutes}:${seconds}`;
  return timeString;
};

const valueFormattor = (value = "", keyOption) => {
  let tempValue = value
  if (["checkbox", "radio", "select"].includes(keyOption?.type) && keyOption.values?.length > 0 &&
    ((["radio", "select"].includes(keyOption?.type) && !!value) || (keyOption?.type == "checkbox" && Array.isArray(value) && value?.length > 0))
  ) {
    if (keyOption?.type == "checkbox") {
      tempValue = tempValue?.map(element=>keyOption?.values?.find(dl => dl.value == element)?.label || element)
    }
    else {
      tempValue = keyOption?.values?.find(dl => dl.value == tempValue)?.label
    }
  }
  if (keyOption?.formatWord || keyOption?.splitWord) {
    if (keyOption?.splitWord) {
      tempValue = splitBySpecialCharsAndSpace(tempValue, keyOption?.splitOn || null, keyOption?.joinWith || null)
      // console.log(tempValue,"tempValue")
    }
    if (keyOption?.formatWord == "toCapitalize") {
      tempValue = capitalizeSentence(tempValue)
    }
    else if (keyOption?.formatWord == "toUpperCase") {
      tempValue = capitalizeSentence(tempValue)
      tempValue = (tempValue + "").toUpperCase()

    }
    else if (keyOption?.formatWord == "toLowerCase") {
      tempValue = (tempValue + "").toLowerCase()
    }
  }
  else if (keyOption?.splitWord) {
    tempValue = splitBySpecialCharsAndSpace(tempValue, keyOption?.splitOn || null, keyOption?.joinWith || null)
  }

  return tempValue
}

export const dataReadFunction = (keyOption = {}, data = {},readKey="name",labelConvertion=false,formDataDynamicLabel) => {
  // console.log("ffff",keyOption)
  if (keyOption?.renderOption?.length > 0) {
    return renderOptionHandler(data, keyOption?.renderOption || [])
  }
  else {
    let key = keyOption && keyOption[readKey] || ""
    let value = data;
    let keyArr = key.split(".")

    if (keyArr.length == 0) return ""
    let start=1
    for (let key of keyArr) {
      if (value && Array.isArray(value) && start==keyArr.length) {
        if(value?.length>0){
          value = value?.map(dl=>dl[key]) || []
        }
        else{
          value = []
          break;
        }
      }
      else if (value && typeof value=="object" && value[key]) {
        value = value[key]
      }
      else {
        value = ""
        break;
      }
      start++
    }

    if(labelConvertion && ["select","checkbox","radio"].includes(keyOption?.type)){
      value=getOptionValueLabel(keyOption,value,formDataDynamicLabel)
    }
    value = valueFormattor(value, keyOption)
   
    // if (keyOption?.type == "date" && !!value) {
    //   value = dateTimeFormater(value, keyOption?.format)
    // }

    // console.log(keyOption,value)
    if(!value || value=="-") value=keyOption?.defaultValue || keyOption?.default_value || ""
    else  truncateNumber(value)
    return value;

  }
}

export const splitBySpecialCharsAndSpace = (inputString = "-", regexInput = null, joinWith = null) => {
  // This regex matches any of the special characters /, #, @, &, -, _, or space
  const regex = !!regexInput ? new RegExp(regexInput) : /[\/#@&\-_\s]+/;
  return (inputString + "").split(regex).join(joinWith || ", ");
}
export const truncateNumber = (value = "", decimals = 3,skipCheck) => {
  let tempValue = value || ""
  // console.log("truncateNumber",value,decimals,skipCheck)
  if ((typeof tempValue == "string" || typeof tempValue == "number") && (skipCheck? true :Number_Only_Validation(tempValue)?.success)) {
    const factor = Math.pow(10, decimals); // 10^decimals
    return Number(Math.round(Number(tempValue) * factor) / factor);
  }
  return tempValue;
}
// // Example usage:
// const inputString = "example/text#with @special &characters-are_split and spaces";
// const result = splitBySpecialCharsAndSpace(inputString);
// console.log(result); // Output will be an array of substrings split at each special character and spaces.

export const renderOptionHandler = (item, renderOptions = []) => {
  let str = ''
  if (renderOptions && renderOptions.length) {
    for (let d of renderOptions) {
      let value = getPathValue(item, d)
      if(!value && d?.default_value){
        value=d?.default_value || null
      }
      if (value) {
        if (d.formatDate) {
          value = dateTimeFormater(value, d.format)
        }
        else {
          value = valueFormattor(value, d)
          if(value) truncateNumber(value)
        }

        let tempstr = ""
        if (d.label) {
          if (d.label.includes("$")) {
            let str1 = d.label.slice(1)
            d.label = item[str1]
            if (!d.label) {
              for (let key of d.keys) {
                let newkey = key.split(".")[0]
                if (item[newkey]) {
                  d.label = newkey
                  break;
                }
              }
            }
          }
          tempstr += d.label + " : "
        }
        tempstr += value
        if (d.prefix) tempstr = d.prefix + tempstr
        if (d.postfix) tempstr += d.postfix
        if (d.uppercase) {
          tempstr = tempstr.toUpperCase()
        }
        str += tempstr
      }
    }
  }

  function getPathValue(data, dd) {
    let value = data;
    if (dd.multiple) {
      let val_exists = false
      for (let c of dd.keys) {
        let val = getPathValueInternal(c, value)
        if (val) {
          val_exists = true
          value = val
          break;
        }
      }
      if (!val_exists) {
        value = null
      }
    }
    else {
      value = getPathValueInternal(dd.key, value)
    }
    return value || null

    function getPathValueInternal(path, value) {
      let path_array = path ? path.split(".") : []
      for (let d of path_array) {
        if (d.includes("$")) {
          let tmpstr = d.slice(1)
          d = item[tmpstr]
        }
        if (value[d]) {
          value = value[d]
        }
        else {
          value = null
          break;
        }
      }
      return value
    }
  }
  return str || '-'
}

export const capitalizeSentence = (sentence = "-", splitBase = " ") => {
  const words = (sentence + "").split(splitBase || " ");
  // //("words",words);
  // const words = sentence.toLowerCase().split(" ");
  const capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // //("capitalizedWords",capitalizedWords);
  return capitalizedWords.join(" ");
}

export const exportToExcel = (data) => {
  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, 'data.xlsx');
};

const sortValues = values => values.sort((a, b) => b.value - a.value)

const radiansToDegrees = (rads) => rads * (180 / Math.PI)

function drawPieSlice(ctx, centerX, centerY, radius, startAngle, endAngle, color, label = '') {
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.moveTo(centerX, centerY);
  ctx.arc(centerX, centerY, radius, startAngle, endAngle);
  ctx.closePath();
  ctx.fill();
}

function makeThumb(page) {
  // //(page.view)
  // draw page to fit into 96x96 canvas
  var vp = page.getViewport({ scale: 1 });
  var canvas = document.createElement("canvas");
  canvas.width = 612;
  canvas.height = 792;
  var scale = Math.min(canvas.width / vp.width, canvas.height / vp.height);
  return page.render({ canvasContext: canvas.getContext("2d"), viewport: { scale: scale } }).promise.then(function () {
    return canvas;
  })
}

export const useGetPermissions = () => {
  const [permissions, setPermissions] = useState({})
  const token = JSON.parse(window.localStorage.getItem("token"))
  const config = {
    headers: { Authorization: token }
  };
  useEffect(() => {
    axios.post(`${url.baseURL}role/getUserPermission`, {}, { headers: config.headers })
      .then(response => {
        let data = response.data.data
        let status = response.data.success
        if (status === true) {
          setPermissions(data)
        }
        else {

        }
      })
      .catch(error => {
        //('error', error.message);
      })
  }, [])

  return { permissions };
};


export const compareTwoArrayOrObject = (value, other, matchType = 'all') => {
  if (other && typeof other == 'object') {
    other = JSON.parse(JSON.stringify(other));
  }
  if (value && typeof value == 'object') {
    value = JSON.parse(JSON.stringify(value));
  }
  if ((typeof value == 'string' || typeof value == 'number' || typeof value == 'boolean') && typeof value == typeof other) {
    if (value === other) {
      return true;
    } else {
      return false;
    }
  }
  // Get the value type
  var type = Object.prototype.toString.call(value);
  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;
  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  // Compare two items
  var compare = (item1, item2) => {
    // Get the object type
    var itemType = Object.prototype.toString.call(item1);
    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (compareTwoArrayOrObject(item1, item2, matchType)) return true;
    }
    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;
      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() === item2.toString()) return true;
      } else {
        if (item1 === item2) return true;
      }
    }
    return false;
  };

  // Compare properties
  if (type === '[object Array]') {
    if (matchType === 'one') {
      for (var i = 0; i < value.length; i++) {
        var foundMatch = false;
        for (var j = 0; j < other.length; j++) {
          if (compare(value[i], other[j])) {
            foundMatch = true;
            break;
          }
        }
        if (foundMatch) {
          return true;
        }
      }
      return false;
    } else {
      if (value.length !== other.length) return false;
      for (var i = 0; i < value.length; i++) {
        var foundMatch = false;
        for (var j = 0; j < other.length; j++) {
          if (compare(value[i], other[j])) {
            foundMatch = true;
            break;
          }
        }
        if (!foundMatch) {
          return false;
        }
      }
      return true;
    }
  }
  else {
    // Handle objects with 'matchType' option
    if (matchType === 'one') {
      for (var key in value) {
        if (value.hasOwnProperty(key)) {
          var foundMatch = false;
          for (var subKey in other) {
            if (other.hasOwnProperty(subKey)) {
              if (compare(value[key], other[subKey])) {
                foundMatch = true;
                break;
              }
            }
          }
          if (foundMatch) {
            return true;
          }
        }
      }
      return false;
    } else {
      if (Object.keys(value).length !== Object.keys(other).length) return false;
      for (var key in value) {
        if (value.hasOwnProperty(key)) {
          var foundMatch = false;
          for (var subKey in other) {
            if (other.hasOwnProperty(subKey)) {
              if (compare(value[key], other[subKey])) {
                foundMatch = true;
                break;
              }
            }
          }
          if (!foundMatch) {
            return false;
          }
        }
      }
      return true;
    }
  }

  // If nothing matched, return false
  return false;
}

export const deepCopy_Object = (obj) => {
  // Create an immutable copy of an object
  var cloneObj = function () {
    // Create new object
    var clone = {};
    // Loop through each item in the original
    // Recursively copy it's value and add to the clone
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        clone[key] = deepCopy_Object(obj[key]);
      }
    }
    return clone;
  };

  // Create an immutable copy of an array
  var cloneArr = function () {
    return obj.map(function (item) {
      return deepCopy_Object(item);
    });
  };

  // Get object type
  var type = Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
  // If an object
  if (type === "object") {
    return cloneObj();
  }

  // If an array
  if (type === "array") {
    return cloneArr();
  }
  return obj;
}

export const validateFieldsMultiple = (formFields = [], items = []) => {
  let newErrors = [];

  let rowNumber = 1
  for (const row of items) {
    let rowErrors = {};

    for (const targetField of formFields) {
      const fieldId = targetField["_id"];
      const element = row[fieldId];

      if (targetField) {
        if (targetField.required && (!element || element === "")) {
          rowErrors[fieldId] = {
            name: targetField.label,
            message: `${targetField.label} is required`,
            rowNumber
          };
        } else if (element && (targetField.pattern || targetField.regex)) {
          if (targetField?.multiple && Array.isArray(element)) {
            element.forEach(item => {

              // console.log("item",item)
              if (!(new RegExp(targetField.pattern || targetField.regex)).test(item)) {
                rowErrors[fieldId] = {
                  name: targetField.label,
                  message:  `${targetField.errorText || "Invalid value"} for ${targetField.label}`,
                  rowNumber
                };
              }
            })
          }
          else if (!(new RegExp(targetField.pattern || targetField.regex)).test(element)) {
            rowErrors[fieldId] = {
              name: targetField.label,
              message:  `${targetField.errorText || "Invalid value"} for ${targetField.label}`,
              rowNumber
            };
          }
        }
      }
    }

    newErrors.push(rowErrors);
    // if (Object.keys(rowErrors).length > 0) {
    //   newErrors.push(rowErrors);
    // }
    rowNumber++
  }

  let minOneErrorExist = newErrors.some(dl => Object.keys(dl).length > 0)
  if (!minOneErrorExist) {
    newErrors = []
  }
  return newErrors;
};


export const validateFieldsSingle = (formFields = [], dataObj = {}) => {
  let rowErrors = {};

  for (const field of formFields) {
    const fieldId = field._id;
    const element = dataObj[fieldId];

    if (field.required && (!element || element === "")) {
      rowErrors[fieldId] = {
        name: field.label,
        message: `${field.label} is required`
      };
    }
    // else if (element && (field.pattern || field.regex) && !(new RegExp(field.pattern || field.regex)).test(element)) {
    //   rowErrors[fieldId] = {
    //     name: field.label,
    //     message: `${field.errorText || "Invalid value"} for ${field.label}`
    //   };
    // }
    else if (element && (field.pattern || field.regex)) {
      if (field?.multiple && Array.isArray(element)) {
        element.forEach(item => {
          if (!(new RegExp(field.pattern || field.regex)).test(item)) {
            rowErrors[fieldId] = {
              name: field.label,
              message: `${field.errorText || "Invalid value"} for ${field.label}`,
              rowNumber
            };
          }
        })
      }
      else if (!(new RegExp(field.pattern || field.regex)).test(element)) {
        rowErrors[fieldId] = {
          name: field.label,
          message: `${field.errorText || "Invalid value"} for ${field.label}`,
          rowNumber
        };
      }
    }
  }

  return rowErrors;
};

export const uploadExcelFile = (e) => {
  e.preventDefault();
  let files = e.target?.files || ""
  if (e.target?.files?.length) {
    let fileObj = files[0];
    if (!fileObj || !fileObj.name) {
      showToast("File name is required in uploaded file!")
      return { target: { files: "" } };
    }
    let fileExtension = fileObj.name.split('.');
    if (!["xlsx", "xls"].includes(fileExtension[fileExtension.length - 1])) {
      showToast("You can upload excel file only!!")
      return { target: { files: "" } };
    }
  }
  else {
    return { target: { files: "" } };
  }

  return { target: { files: files } };
}
export const convertExcelToArray = (file = null) => {
  return new Promise((resolve) => {
    if (!file) {
      return resolve([]);
    }

    const fileExtension = file.name.split('.').pop();
    const allowedExtensions = ['xls', 'xlsx'];

    if (!allowedExtensions.includes(fileExtension)) {
      console.log("File format not supported.");
      return resolve([]);
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const binaryData = event.target.result;
        const workbook = SheetXLSX.read(binaryData, {
          type: 'binary',
          cellText: false,
          cellDates: true
        });

        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonOptions = {
          header: 0, // Consider using 1 for an array of arrays output
          defval: "",
          raw: false,
          dateNF: "YYYY-MM-DD"
        };

        const jsonData = SheetXLSX.utils.sheet_to_json(worksheet, jsonOptions);
        return resolve(jsonData);
      }
      catch (error) {
        console.log("Error processing file:", error);
        return resolve([]);;
      }
    };

    reader.onerror = (error) => {
      console.log("Error reading file:", error);
      return resolve([]);;
    };

    reader.readAsBinaryString(file);
  });
};


export const convertInExcel = (formFields = [], fileName = "smapleFile") => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml;charset=UTF-8";
  const fileExt = ".xlsx";

  const excelData = {};
  const theaders = (formFields || [])?.map(dl => dl.label);
  for (let i = 0; i < theaders.length; i++) {
    excelData[theaders[i]] = ""
  }

  const ws = SheetXLSX.utils.json_to_sheet([excelData]);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const buffer = SheetXLSX.write(wb, { bookType: 'xlsx', type: "array" });
  const data = new Blob([buffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExt);

}

export const renderHeadersFilter = (validHeader = [], filter, setFilter, setPage, setLimit, tabIndex, advancedFilterPart = false,showStatusFilterIndex=[0]) => {
  let allFilter = validHeader?.filter(dl => dl.isSearchable && dl?.mappingPath);
  if (!allFilter || allFilter.length == 0) return null;
  return allFilter?.map((element, index) => {
    let payloadKey = element?.payloadKey || element?.mappingPath;
    if (element.searchInputType === 'input') {
      return (<Grid item xs={12}
        sm={advancedFilterPart ? 12 : 6}
        md={advancedFilterPart ? 12 : 3}
        lg={advancedFilterPart ? 12 : 1.8}>
        <FormControl fullWidth variant="outlined" size="small">
          <OutlinedInput
            data-testid="search-input"
            value={payloadKey && filter?.search[payloadKey] || ""}
            onChange={(e) => {
              let tempSearch = {
                ...(filter["search"] || {})
              }
              tempSearch[payloadKey] = e?.target?.value || ""
              setFilter({ ...filter, "search": tempSearch })
              if (setPage) setPage(0);
              if (setLimit) setLimit(10);
            }}
            placeholder={`Search by ${element?.fieldName || ""}`}
            sx={{
              ...inputClasses.filterField,
              "& input::placeholder": { fontSize: "14px" },
              "& .MuiSelect-iconOutlined": {
                // display: idFilter != "" ? "none" : "",
              },
            }}
            endAdornment={
              filter?.search[payloadKey] == "" || filter?.search[payloadKey] == undefined ? (
                <InputAdornment position="end">
                  <Search fontSize="small" />
                </InputAdornment>
              ) : (
                <IconButton
                  sx={{
                    visibility: filter?.search[payloadKey] != "" ? "visible" : "hidden",
                    fontSize: "14px",
                  }}
                  onClick={() => {
                    let tempSearch = { ...(filter["search"] || {}) }
                    delete tempSearch[payloadKey]
                    setFilter({ ...filter, "search": tempSearch })
                    if (setPage) setPage(0);
                    if (setLimit) setLimit(10);
                  }}
                >
                  <Clear fontSize="inherit" />
                </IconButton>
              )
            }
          />
        </FormControl>
      </Grid>
      )
    }
    else if (element.searchInputType === 'date') {
      return (<Grid item xs={12} sm={advancedFilterPart ? 12 : 6}
        md={advancedFilterPart ? 12 : 3}
        lg={advancedFilterPart ? 12 : 2.3}>
        {/* <FormControl fullWidth variant="outlined" size="small">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <DatePicker
              data-testid="datepicker"
              value={payloadKey && filter?.other[payloadKey] || null}
              format="DD/MM/YYYY"
              onChange={(d) => {
                let tempSearch = { ...(filter["other"] || {}) };
                if (d) {
                  tempSearch[payloadKey] = d || "";
                } else {
                  delete tempSearch[payloadKey];
                }
                setFilter({ ...filter, "other": tempSearch });
                if (setPage) setPage(0);
                if (setLimit) setLimit(10);
              }}
              label={`${element?.fieldName || ""}`}
              sx={{ ...inputClasses.filterField, flexGrow: 1 }}
              slotProps={{ textField: { size: "small" } }}
            />
            {filter?.other[payloadKey] && (
              <Box sx={{ position: 'absolute', right: '35px' }}>
                <ClearIcon
                  sx={{
                    color: "grey",
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    let tempSearch = { ...(filter["other"] || {}) };
                    delete tempSearch[payloadKey];
                    setFilter({ ...filter, "other": tempSearch });
                  }}
                />
              </Box>
            )}
          </Box>
        </FormControl> */}
        <FormControl sx={{ width: '100%', display: "flex", height: "100%" }} fullWidth>
          <GenericMultiDateRange
            label={`${element?.fieldName || ""}`}
            getOnChange={(d) => {
              let tempSearch = { ...(filter["other"] || {}) };
              if (d) {
                tempSearch[payloadKey] = d || [];
              } else {
                delete tempSearch[payloadKey];
              }
              setFilter({ ...filter, "other": tempSearch });
              if (setPage) setPage(0);
              if (setLimit) setLimit(10);
            }}
            value={payloadKey && filter?.other[payloadKey] || []}
            inputStyle={inputClasses.filterField}
            portal={!advancedFilterPart}
          />
        </FormControl>
      </Grid>)
    }
    else if (element.searchInputType === 'dropdown' && (["approvalStatus", "status", "approved"].includes(element?.mappingPath) ? showStatusFilterIndex.includes(tabIndex) : true)) {
      // console.log("data",payloadKey , filter["other"] && filter["other"][payloadKey] || "")
      return (<Grid item xs={12} sm={advancedFilterPart ? 12 : 6}
        md={advancedFilterPart ? 12 : 3}
        lg={advancedFilterPart ? 12 : 1.8}>
        {element?.dynamic == true ?
          <TypeAndSearch
            data-testid="locationFilter"
            //  disabled={!selectedData?.inventoryType["value"] || !selectedData?.inventoryId}
            fullWidth
            component={{ ...element, label: element?.fieldName, renderOption: [] }}
            value={payloadKey && filter["other"] && filter["other"][payloadKey] && filter["other"][payloadKey]?.value || ""}
            label={payloadKey && filter["other"] && filter["other"][payloadKey] && filter["other"][payloadKey]?.label || ""}
            set_Value={true}
            setEmptyOnChange={false}
            options={payloadKey && filter["other"] && filter["other"][payloadKey] && filter["other"][payloadKey]?.value ? [filter["other"][payloadKey]] : []}
            handleChange={(event, option) => {
              let tempSearch = { ...(filter["other"] || {}) }
              if (option?.value) {
                tempSearch[payloadKey] = option || ""
              }
              else {
                delete tempSearch[payloadKey];
              }
              setFilter({ ...filter, "other": tempSearch })
              if (setPage) setPage(0);
              if (setLimit) setLimit(10);
            }}
            componentStyle={{
              ...inputClasses.filterField,
              ".MuiInputBase-input": {
                fontSize: "15px",
                color: "#455A64",
              },
            }}
          />
          :
          <Autocomplete
            data-testid="dropdownid"
            value={payloadKey && filter["other"] && filter["other"][payloadKey]
              ? element?.values?.find(dl => dl.value == filter["other"][payloadKey])
              : null
            }
            onChange={(e, option) => {
              let tempSearch = { ...(filter["other"] || {}) }
              if (option?.value) {
                tempSearch[payloadKey] = option?.value || ""
              }
              else {
                delete tempSearch[payloadKey];
              }
              setFilter({ ...filter, "other": tempSearch })
              if (setPage) setPage(0);
              if (setLimit) setLimit(10);
            }}
            fullWidth
            // getOptionLabel={(option) => option.label}
            size="small"

            options={element?.values || []}
            sx={{
              ...inputClasses.filterField,
              ".MuiInputBase-input": {
                fontSize: "15px",
                color: "#455A64",
              },
            }}
            renderInput={(params) => (
              <TextField
                sx={{ color: "#455A64", ...textClasses.normalText }}
                {...params}
                label={`${element?.fieldName || ""}`}
              />
            )}
          />
        }
      </Grid>)
    }
    return null;
  })
}

export const calculateFormulaHelper = (data = {}, formulaFields = [],insertIn={},makeDisabled=false,skipFields=[]) => {
  try {
    // console.log("formulaFields",data, formulaFields )
    if (formulaFields?.length > 0) {
      for (let element of formulaFields) {
        let { targetElement, formula = [],isPrimary=false, type="number" } = element || {}
        if (isPrimary  && skipFields && !skipFields.includes(targetElement)){
          skipFields.push(targetElement)
        }
        if (targetElement && formula?.length > 0) {
          let calculatedValue = calculateFormula(data, formula, type)
          // console.log('Calculated Value:', calculatedValue || "",data,formula)
          if (!calculatedValue || ["", " ", "-"].includes(calculatedValue)) {
            calculatedValue = ""
          }
          insertIn[targetElement] = calculatedValue
          if (!insertIn.disabled) insertIn.disabled = []
          // console.log("",{calculatedValue,insertIn,makeDisabled})
          if (!calculatedValue) {
            insertIn.disabled.splice(insertIn.disabled.indexOf(targetElement), 1)
          }
          else if (calculatedValue && makeDisabled && !insertIn.disabled?.includes(targetElement)) {
            insertIn.disabled.push(targetElement)
          }
        }
      }
    }
    return;
  }
  catch(error){
    console.log(error);
    return;
  }
}


let timeUnits = ["timestamp", "hours", "minutes", "seconds", "milliseconds", "days","weeks","months","years","decades","centuries","millennia"];


export const calculateFormula = (data = {}, formula = [], type = "") => {
  try {
    let tmp = [];

    let tempFormula=deepCopy_Object(formula)
    for (let field of tempFormula) {
      if (field.FieldAs === "field") {
        let fieldValue = data;
        for (let key of (field?.FieldValue || "").split(".")) {
          if (!fieldValue || !fieldValue[key]) {
            fieldValue = "";
            break;
          }
          fieldValue = fieldValue[key] || "";
        }

       
        if (!fieldValue && field?.OtherValue?.length > 0) {
          for (let otherkey of field?.OtherValue) {
            fieldValue = data
            for (let key of (otherkey || "")?.split(".")) {
              if (!fieldValue || !fieldValue[key]) {
                fieldValue = ""
                break;
              }
              // console.log("formulaFields2",{fieldValue})
              fieldValue = fieldValue[key] || ""
            }
            // console.log("formulaFields3",{fieldValue})
            if (fieldValue) {
              break;
            }
          }
        }

        // console.log("formulaFields2",{fieldValue,field})
        if (field.FieldType === "Date") {
          if(!fieldValue){
            return ""
          }
          field.value = fieldValue && new Date(fieldValue).getTime() || 0;
          field.unit = "timestamp";
          field.FieldType = "Number";
        }
        else if (field.FieldType === "Number") {
          if (!fieldValue && field?.Unit && timeUnits.includes(field.Unit)) {
            return ""
          }
          // console.log("formulaFields3",{fieldValue,field})
          field.value = Number(fieldValue || 0);
          if (!field.Unit || field?.Unit == "Number") {
            field.unit = "timestamp";
          }
          else {
            field.unit = field?.Unit || "timestamp";
          }
        }
        else {
          field.value = fieldValue;
        }
        // console.log("formulaFields1",{fieldValue,field})
        tmp.push(field);
      }
      else if (field.FieldAs === "operator") {
        field.value = field.FieldValue;
        tmp.push(field);
      } else if (field.FieldAs === "variable") {
        field.value = field.FieldValue;
        tmp.push(field);
      }
    }

    let { data: resp, success, message } = resolveFormula(tmp);
    if (!success) {
      return "";
    }

    let tempValue = resp || "";
    tempValue = Number(tempValue || 0).toFixed(3);
    tempValue = Number(tempValue || 0)
    // console.log("tempValue",{tempValue,type})
    if (tempValue && !!type && [...timeUnits,"date"].includes(type)) {
      tempValue =convertFromTimestamp(tempValue, type)
    }
    // console.log(tempValue,"formulaFields11")
    return tempValue;
  } catch (err) {
    return "-";
  }
};

const convertFromTimestamp = (timestamp=0, convertUnit) => {
  const millisecondsInASecond = 1000;
  const secondsInAMinute = 60;
  const minutesInAnHour = 60;
  const hoursInADay = 24;
  const daysInAWeek = 7;
  const monthsInAYear = 12;
  const daysInAYear = 365; // Approximate

  let value;

  // Ensure timestamp is in milliseconds
  if (timestamp < 1000000000000) {
      timestamp *= 1000; // Convert seconds to milliseconds if necessary
  }

  switch (convertUnit) {
      case "date":
          if(timestamp){
            value = new Date(timestamp)
            value = value.toISOString().split('T')[0]; // Formats as YYYY-MM-DD
          }
          break;
      case "days":
          value = timestamp / (millisecondsInASecond * secondsInAMinute * minutesInAnHour * hoursInADay);
          break;
      case "hours":
          value = timestamp / (millisecondsInASecond * secondsInAMinute * minutesInAnHour);
          break;
      case "minutes":
          value = timestamp / (millisecondsInASecond * secondsInAMinute);
          break;
      case "seconds":
          value = timestamp / millisecondsInASecond;
          break;
      case "milliseconds":
          value = timestamp;
          break;
      case "weeks":
          value = timestamp / (millisecondsInASecond * secondsInAMinute * minutesInAnHour * hoursInADay * daysInAWeek);
          break;
      case "months":
          value = timestamp / (millisecondsInASecond * secondsInAMinute * minutesInAnHour * hoursInADay * daysInAYear / monthsInAYear);
          break;
      case "years":
          value = timestamp / (millisecondsInASecond * secondsInAMinute * minutesInAnHour * hoursInADay * daysInAYear);
          break;
      case "decades":
          value = timestamp / (millisecondsInASecond * secondsInAMinute * minutesInAnHour * hoursInADay * daysInAYear * 10);
          break;
      case "centuries":
          value = timestamp / (millisecondsInASecond * secondsInAMinute * minutesInAnHour * hoursInADay * daysInAYear * 100);
          break;
      case "millennia":
          value = timestamp / (millisecondsInASecond * secondsInAMinute * minutesInAnHour * hoursInADay * daysInAYear * 1000);
          break;
      default:
          // console.warn(`Unit "${convertUnit}" is not recognized.`);
          value = timestamp;
          break;
  }

  return value;
};


const resolveFormula = (formula = []) => {
  try {
      let eq = [];
      for (let i = 0; i < formula.length; i++) {
          if (formula[i].value === "=") {
              let { success, message, data: evaluation } = evaluateEquation(eq);
              if (success && evaluation.value) {
                  formula.splice(0, i + 1);
                  eq = resolveFormula(formula);
              } else {
                  return { success: false, message: "Error evaluating formula!" };
              }
          } else if (formula[i].value === "(") {
              let depth = 1;
              let j = i + 1;
              while (j < formula.length && depth > 0) {
                  if (formula[j].value === "(") depth++;
                  else if (formula[j].value === ")") depth--;
                  j++;
              }
              let subFormula = formula.slice(i + 1, j - 1);
              let resolvedSubFormula = resolveFormula(subFormula);
              if (resolvedSubFormula.success) {
                  eq.push({ value: resolvedSubFormula.data });
                  i = j - 1;
              } else {
                  return { success: false, message: "Error evaluating sub-formula!" };
              }
          } else if (formula[i].value === ")") {
              let eqs = evaluateEquation(eq);
              return { success: true, data: eqs };
          } else {
              eq.push(formula[i]);
          }
      }
      
       

      let { success, message, data: evaluation } = evaluateEquation(eq);
      
      if (success) {
          return { success: true, data: evaluation.value || "" };
      } else {
          return { success: false, message: "Something went wrong here" };
      }
  } catch (err) {
      return { success: false, message: err.message };
  }
};

const evaluateEquation = (eqArray=[]) => {
  try {
    
      if (Array.isArray(eqArray)) {
       
          let eq = "";
            
          let { success = false, data=[], message } = convertUnits(eqArray);
          if (!success) {
              return { success: false, message: message || "Invalid Formula" };
          }
          eqArray=data
          if (eqArray) {
              for (let i = 0; i < eqArray.length; i++) {
                  let equ = eqArray[i];
                  if (equ.value === "/") {
                      if (i === 0 && [null, "", " "].includes(eqArray[0].value)) {
                          equ.value = '0';
                      }
                  }
                  eq += equ.value;
              }

              try {
                  let result = eval(eq);
                  return { success: true, data: { value: result } };
              } catch (e) {
                  return { success: true, data: { value: 0 } };
              }
          } else {
              return { success: false, message: "Invalid Formula" };
          }
      } 
      else {
       
          return { success: false, message: "Invalid Formula" };
      }
  } catch (err) {
   
      return { success: false, message: err.message };
  }
};

const convertUnits = (eqArray) => {
  try {
   
      let lengthUnits = ["meters", "kilometers", "millimeters", "centimeters", "feet", "inches"];
      let weightUnits = ["grams", "kilograms", "milligrams", "tonnes", "micrograms", "imperialtons", "pounds", "ounces"];

      let unit = "";
      let length = eqArray.length;

      if (length > 2) {
          for (let i = 0; i < eqArray.length - 1; i += 2) {
              if (eqArray[i + 1].FieldAs !== "operator") {
                  return { success: false, message: "Invalid formula" };
              } 
              else {
                  if (timeUnits.includes(eqArray[i].unit) && timeUnits.includes(eqArray[i + 2].unit)) {
                      unit = "timestamp";
                  } 
                  else if (lengthUnits.includes(eqArray[i].unit) && lengthUnits.includes(eqArray[i + 2].unit)) {
                      unit = "kilometres";
                  } 
                  else if (weightUnits.includes(eqArray[i].unit) && weightUnits.includes(eqArray[i + 2].unit)) {
                      unit = "kilograms";
                  } 
                  else {
                      return { success: false, message: "Invalid units" };
                  }
                  eqArray = convertUnitFunc(eqArray, unit);
              }
          }
      }

      return { success: true, data: eqArray };
  } 
  catch (err) {
      return { success: false, message: err.message };
  }
};

const convertUnitFunc = (eqArray, targetUnit) => {

  for (let i = 0; i < eqArray.length; i++) {
    if (eqArray[i].FieldAs == "operator" || eqArray[i].unit == "number" || eqArray[i].unit == "Number") {
      continue;
    }
    const currentUnit = eqArray[i].unit;
    let value = Number(eqArray[i].value || 0);

    switch (targetUnit) {
      case "timestamp":
        switch (currentUnit) {
          case "hours":
            value = value * 60 * 60 * 1000;
            break;
          case "timestamp":
            value = value; // Assuming timestamp is already in milliseconds
            break;
          case "seconds":
            value = value * 1000;
            break;
          case "milliseconds":
            value = value;
            break;
          case "days":
            value = value * 24 * 60 * 60 * 1000;
            break;
          case "weeks":
            value = value * 7 * 24 * 60 * 60 * 1000;
            break;
          case "months":
            value = value * 30 * 24 * 60 * 60 * 1000; // Approximate month length
            break;
          case "years":
            value = value * 365 * 24 * 60 * 60 * 1000; // Approximate year length
            break;
          case "decades":
            value = value * 10 * 365 * 24 * 60 * 60 * 1000; // Approximate decade length
            break;
          case "centuries":
            value = value * 100 * 365 * 24 * 60 * 60 * 1000; // Approximate century length
            break;
          case "millennia":
            value = value * 1000 * 365 * 24 * 60 * 60 * 1000; // Approximate millennium length
            break;
          default:
            console.warn(`Unit "${currentUnit}" is not recognized.`);
            value = 0;
            break;
        }
        break;
      case "kilometres":
        switch (currentUnit) {
          case "metres":
            value = value / 1000;
            break;
          case "millimetres":
            value = value / 1000000;
            break;
          case "centimetres":
            value = value / 100000;
            break;
          case "feet":
            value = value / 3281;
            break;
          case "inches":
            value = value / 39370;
            break;
          default:
            console.warn(`Unknown length unit: ${currentUnit}`);
        }
        break;

      case "kilograms":
        switch (currentUnit) {
          case "grams":
            value = value / 1000;
            break;
          case "milligrams":
            value = value / 1000000;
            break;
          case "tonnes":
            value = value * 1000;
            break;
          case "imperialtons":
            value = value * 1016;
            break;
          case "micrograms":
            value = value / 1000000000;
            break;
          case "pounds":
            value = value / 2.205;
            break;
          case "ounces":
            value = value / 35.274;
            break;
          default:
            console.warn(`Unknown weight unit: ${currentUnit}`);
        }
        break;
      default:
        console.warn(`Unknown target unit: ${targetUnit}`);
        break;
    }

    // Update the unit and value in the array
    eqArray[i].unit = targetUnit;
    eqArray[i].value = value;
  }
  

  return eqArray;
}

export const  paginateAfterDelete=(data, page, limit,indexToDelete)=> {
  // Ensure page and limit are valid
  page = page || 1;
  limit = limit || 10;
  indexToDelete=indexToDelete || 0
  // Delete row (assuming data is an array of objects and we're deleting by index)
  if (data.length > 0) {
      data.splice(indexToDelete, 1);
  }

  // Calculate the number of pages
  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / limit);

  // Ensure the current page is within the range of total pages
  if (page > totalPages) {
      page = totalPages;
  }

  // Calculate the start and end index for the current page
  const startIndex = (page - 1) * limit;
  const endIndex = Math.min(startIndex + limit, totalItems);

  // Get the items for the current page
  const paginatedItems = data.slice(startIndex, endIndex);

  // If the current page is empty and it's not the first page, go to the previous page
  if (paginatedItems.length === 0 && page > 1) {
      page--;
  }

  return {
      page,
      totalPages,
      paginatedItems
  };
}

// This function we are using in handle Submit and Draft button onSubmit case
export const showErrorDynamicField = (errors={}) =>{
  showToast(Object.values(errors)?.at(0)?.message,true)
}