import {
    CHECK_DISTINCT_MASTER_EXIST,
    GET_MAPPED_MASTER_IDS,
    FLUSH_MAPPED_IDS,
    GET_AREA_MAPPING_DATA,
    GET_AREA_CATEGORY_OPTIONS,
    FETCH_DOCK_MAPPING_DATA,
    FETCH_AREA_MAPPING_STATUS_COUNT,
  } from "../types";

const intialState = {
    mappedArea:{},
    mappedAnalytics:[],
    distinctMasters: [],
    mappedMasterIds: [],
    areaMappingDetail: [],
    mappedAreaForView: [],
    areaCategoryOptions: {},
    dockMappedTableData:[],
    dockMappedTableDataCount:0,
    areaMappingStatusCount:null
};


const areaMappingReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case "GET_MAPPED_AREA":
            return { ...state, mappedArea:payload }
        case "GET_MAPPED_AREA_FOR_VIEW" : 
        return {...state, mappedAreaForView: payload}
        case GET_AREA_MAPPING_DATA:
            return { ...state, areaMappingDetail: payload }
        
        case "GET_MAPPED_ANALYTICS":
            return { ...state, mappedAnalytics:payload }
        case FLUSH_MAPPED_IDS: 
        return {
            ...state, mappedMasterIds: []
        }
        case CHECK_DISTINCT_MASTER_EXIST : 
        return { ...state, distinctMasters: payload}
        case GET_MAPPED_MASTER_IDS : 
        return { ...state, mappedMasterIds: payload}
        case GET_AREA_CATEGORY_OPTIONS : 
        return {...state, areaCategoryOptions: payload}
        case FETCH_DOCK_MAPPING_DATA : 
        return {...state, dockMappedTableData: payload?.data || [], dockMappedTableDataCount: payload?.count || 0}
        case FETCH_AREA_MAPPING_STATUS_COUNT : 
        return {...state, areaMappingStatusCount: payload?.data || {}}
        default:
            return state;
    }
}
export default areaMappingReducer;
