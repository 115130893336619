import React from 'react';
import {lynkitIOURL} from '../../../../services/config';
import Integration from '../../../Components/Integration';
import { useLocation} from "react-router-dom";

const testingUrl="http://192.168.2.141:4406"


const Addressbook = () => {
    const location = useLocation();
    const todayDate = new Date().getTime();

    const queryParams = new URLSearchParams(location.search);
    // const myVariable = `http://192.168.1.86:4406/login?action=/dashboard/inventory/all&auth=WMS&timestamp=${todayDate}`;
    // const myVariable = `${testingUrl}/login?action=/dashboard/templates&auth=WMS&timestamp=${todayDate}`;
    // const myVariable = `http://localhost:4406/login?action=/dashboard/entity-master/item-master&auth=WMS&timestamp=${todayDate}`;
    // const myVariable = `http://192.168.1.147:4302/login?action=/address-book&filterType=goods_owner&auth=WMS&timestamp=${todayDate}`;
    // const myVariable = `${lynkitIOURL}/login?action=/address-book&filterType=goods_owner&auth=WMS&timestamp=${todayDate}`;
    const myVariable = `${lynkitIOURL}/login?action=/address-book&filterType=${queryParams.get('filterType') || ""}&auth=WMS&timestamp=${todayDate}`;
    return (
       <>
        <Integration
            myVariable={myVariable}
            // baseURL={testingUrl}
            // baseURL={"http://localhost:4406"}
            baseURL={lynkitIOURL}
        />
       </>
    );
};

export default Addressbook;
