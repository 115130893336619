import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid,
    Paper,
    Typography,
    Box,
    Tabs,
    Tab,
    Skeleton
} from "@mui/material";
import { getDynamicPageConfigDetails } from "../../../redux/actions";
import theme, {textClasses, cardClasses } from '../../../static/styles/theme'
import axios from "../../../services/Api";
import { FETCH_DYNAMIC_PAGE_CONFIG} from "../../../redux/types";


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
const GenericPageConfig = ({
    storedInPage, pageName, pageTabCount = {}, pageStatusCount = {}, handlePageTabChange, selectedPageTab = "",
    handleStatusTabChange, selectedStatusTab = 0,disabledPageTabs=false,disabledStatusTabs=false
}) => {
    const dispatch = useDispatch();
    const { pageTabs = [], statusTabs = {}, breadcrumb = [], isMultiTabPage = false} = useSelector(state => state.DynamicPageConfig[storedInPage]) || {};

    const [imageUrls, setImageUrls] = useState({});
    const [allLoader, setAllLoader] = useState("");

    const fetchImageUrls = async (imageKeys,cb) => {
        try {
            const response = await axios.get('/base/getAWSFilePath', {
                params: { keys: imageKeys.join(',') }
            });
            setImageUrls(response?.data?.data || {});
            if(cb)cb()
        } catch (error) {
            console.error('Error fetching image URLs:', error);
            if(cb)cb()
            setImageUrls({});
        }
    };

      
    useEffect(() => {
        setImageUrls([])
        setAllLoader("tabData")
        dispatch(getDynamicPageConfigDetails(storedInPage, { pageName: pageName },(data)=>{
            let imageKeys=data?.pageTabs?.length && data?.pageTabs?.map(tab => tab.icon) || []
            imageKeys=[...new Set(imageKeys)]
            if(imageKeys?.length){  
                setAllLoader("tabImage")
                fetchImageUrls(imageKeys,()=>{
                    setAllLoader("")
                })
            }
            else{
                setAllLoader("")
            }
        }))
        return () => {
              dispatch({ type: FETCH_DYNAMIC_PAGE_CONFIG, payload: {storedInPage: storedInPage, data: {} }})
        }
    }, [pageName])

    useEffect(() => {
        if (pageTabs?.length &&!selectedPageTab) {
            handlePageTabChange(pageTabs[0]?.value || "")
        }
    }, [pageTabs])
    useEffect(() => {
        if ((!pageTabs || pageTabs?.length == 0) && selectedStatusTab <0 && Object.keys(statusTabs).length > 0 && statusTabs["default"]?.length > 0) {
            handleStatusTabChange(0, statusTabs["default"][0])
        }
    }, [statusTabs])
 
    return (
        <>

            {allLoader=="tabData" ? 
            <Box sx={{ display: 'flex', alignItems: 'center',m:2}}>
                <Skeleton  variant="rounded" width={"40vh"} height={"10vh"} sx={{ mr: 2 }} />
                <Skeleton  variant="rounded" width={"40vh"} height={"10vh"} />
            </Box>
                : <Grid container spacing={2} sx={{ mt: 0 }}>
                    {pageTabs?.map((dl) => {
                        return <Grid item md={2.4} sm={4} xs={12}>
                            <Paper
                                data-testid="generic-card"
                                sx={{
                                    cursor: "pointer",
                                    ...cardClasses.basic,
                                    p: 2,
                                    border:
                                        dl?.value == selectedPageTab
                                            ? `1px solid ${theme.themeOrange}`
                                            : "1px solid #D9D9D9",
                                    height: "100%",
                                    ...disabledPageTabs &&  dl?.value != selectedPageTab? {
                                        backgroundColor: '#f0f0f0',
                                        color: '#a0a0a0',
                                        pointerEvents: 'none',
                                        opacity: 0.5,
                                    } : {},
                                }}
                                onClick={() => {
                                    if(!disabledPageTabs){
                                        handlePageTabChange(dl?.value)
                                    }
                                }}
                                disabled={disabledPageTabs}
                            >
                                <Grid container spacing={2}>
                                    <Grid
                                        container
                                        item
                                        xs={4}
                                        justifyContent="center"
                                        alignItems={"center"}
                                    >
                                        {allLoader == "tabImage" ?
                                            <Skeleton variant="circular" width={55} height={60}/> :
                                            <img src={dl?.icon && imageUrls[dl.icon] || ""} width={55} height={60} />
                                        }
                                    </Grid>
                                    <Grid container item direction={"column"} xs={8}>
                                        <Typography
                                            sx={{
                                                ...textClasses.t20n,
                                                fontWeight: "600",
                                                color: dl?.value == selectedPageTab && theme.themeOrange,
                                            }}
                                        >
                                            {pageTabCount && dl?.dataReadKey ? pageTabCount[dl?.dataReadKey] || 0 : 0}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                ...textClasses.normalText,
                                                color: dl?.value == selectedPageTab && theme.themeOrange,
                                            }}
                                        >
                                            {dl?.label || ""}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    })}
                </Grid>}
            {selectedPageTab && statusTabs[selectedPageTab]?.length && <Box
                sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    mt: 1,
                    mb: 1,
                    width: "100%",
                }}
            >
                <Tabs
                    value={selectedStatusTab}
                    aria-label="disabled tabs example"
                    onChange={(event, tabIndex) => {
                        handleStatusTabChange(tabIndex, statusTabs[selectedPageTab][tabIndex])
                    }}
                    sx={{
                        "& .MuiTabs-indicator": { backgroundColor: theme.themeOrange },
                        "& .MuiTab-root.Mui-selected": { color: theme.themeOrange },
                    }}
                   
                >
                    {statusTabs[selectedPageTab]?.map((dl, index) => {
                        return <Tab
                            key={index}
                            data-testid={dl?.label || ""}
                            label={`${dl?.label || ""} (${pageStatusCount && dl?.dataReadKey ? pageStatusCount[dl?.dataReadKey] || 0 : 0
                                })`}
                            {...a11yProps(0)}
                            sx={{ ...textClasses.normalText, textTransform: "none" }}
                            disabled={disabledStatusTabs}
                        />
                    })}
                </Tabs>
            </Box>}
        </>
    )
}
export default GenericPageConfig;