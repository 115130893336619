import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SHOW_MESSAGE,
  } from "../types";
import axios from "../../services/Api";

export const getfilterRFID = (bodydata, cb) =>
{
    return async (dispatch) => {
   
        dispatch({type: FETCH_START});
        await axios.post('/RFIDSeal/getSeal', bodydata
        ).then(({data}) => {
          if (data.success) 
          {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            dispatch({ type: "GET_RFID_DATA", payload: data });
            // dispatch({type: SHOW_MESSAGE, payload: data.message});
            if(cb){
              cb();
            }
          } 
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({ type: "GET_RFID_DATA", payload: {} });
            if(cb){
              cb();
            }
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          //("Error****:", error.message);
          if(cb){
            cb();
          }
        });
      }
};

export const uploadRFID = (bodydata) =>
{
    return async (dispatch) => {
   
        dispatch({type: FETCH_START});
        await axios.post('/RFIDSeal/uploadSealExcel', bodydata
        ).then(({data}) => {
          if (data.success) 
          {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            dispatch({type: SHOW_MESSAGE, payload: data.message});
          } 
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          //("Error****:", error.message);
        });
      }
};

export const RFIDMultiMapTagging = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('tag/multiMapTag', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
            }
			if(cb){
				cb(data)
			}
    
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            if(cb){
                cb({success:false,message:error.message})
            }
        });
    }
};

export const getCheckpointDashboardList = (payload, cb) =>
  {
      return async (dispatch) => {
     
          dispatch({type: FETCH_START});
          axios.post('/checkpoint/getCheckpointDashboardList', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: "GET_CHECKPOINT_DASHBOARD_DATA", payload: data?.data ||[] });
              if(cb){
                cb();
              }
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: "GET_CHECKPOINT_DASHBOARD_DATA", payload: [] });
                if(cb){
                  cb();
                }
            }
            if (cb) {
                cb(data)
            }

        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          //("Error****:", error.message);
          if(cb){
            cb();
          }
        });
  };
}
