import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import theme, { textClasses } from "../../../static/styles/theme";
import { PaginationComp } from '../../Components/CustomTable/GenericTable';
import { getSkuAssociatedJobDetails } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import GenericLoader from '../../Components/Generic/GenericLoader';
import { dateTimeFormater, textCapitalize } from '../../../services/functions';
import { DataNotFound } from '../../Components/TemplateInputConfiguration';
import history from '../../../services/history';
import ViewWorkOrderList from '../Jobs/JobView/viewWorkOrderList';
import {
 FiberManualRecord as FiberManualRecordIcon
} from "@mui/icons-material";

const AssociatedJobsSection = ({ skuId, setTabIndex, fromDate, toDate }) => {
    const dispatch = useDispatch();
    const { jobQtyData = [] } = useSelector((state) => state.InventoryJourney);
    const [loader, setLoader] = useState(false);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [associatedJobsData, setAssociatedJobData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [checkedRow, setCheckedRow] = useState({ visible: false, selectedRow: [], type: "", jobComponentId: "" });

    const getAssociatedJobDetailsData = (page, limit) => {
        const payload = {
            SKU_ID: skuId,
            page: page,
            limit: limit,
        };
        if (fromDate && toDate) {
            payload.fromDate = fromDate;
            payload.toDate = toDate;
        }
        dispatch(getSkuAssociatedJobDetails(payload, (data) => {
            setLoader(false);
            if (data?.success) {
                setAssociatedJobData(data?.data);
                setTotalRows(data?.count);
            }
        }));
    };

    const handleClickWorkOrderDetails = (jobCompId) => {
        setCheckedRow({ ...checkedRow, visible: true, type: "viewWorkOrderList", jobComponentId: jobCompId })
    }

    useEffect(() => {
        setLoader(true);
        getAssociatedJobDetailsData(page + 1, limit);
    }, [skuId, page, limit, fromDate, toDate]);


    return loader ? (
        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ p: 15 }}>
            <Grid item>
                <GenericLoader />
            </Grid>
        </Grid>
    ) : (
        <>
            {associatedJobsData?.length > 0 ? (
                <>
                    {associatedJobsData.map((jobItem, index) => {
                        const jobQty = jobQtyData.find(
                            (qtyItem) => qtyItem?.jobId === jobItem?.jobId
                        ) || {};

                        return (
                            <Grid container key={index} sx={{ p: 2, m: 2, border: 1, borderColor: "divider", borderRadius: 1 }}>
                                <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Grid item lg={6} sx={{ display: 'flex', justifyContent: 'flex-start',alignItems:'center' }} data-testid="job-header">

                                    <Typography variant="span"
								sx={{
									background: theme.themeOrange,
									transform: "translate(-16px, -16px)",
									borderTopLeftRadius: "10px",
									padding: "5px 10px"
								}}
							>
								{((page) * (limit)) + index + 1}
							</Typography>
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            sx={{ textAlign: "top" }}
                                            gutterBottom

                                        >
                                        </Typography>
                                        <FiberManualRecordIcon
                                            sx={{
                                                color: jobItem?.status == "completed" && jobItem?.isApproved ? theme.themeGreen : theme.themeYellow
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                ...textClasses.normalText,
                                                fontWeight: 450,
                                                color: theme.themeBlack,
                                                textAlign: 'center',
                                                alignItems: 'center'
                                            }}
                                        >

                                            {`${jobItem?.jobType} (${jobItem?.jobId})`}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={6} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }} data-testid="work-order-link">
                                        <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, textDecoration: 'underline', cursor: 'pointer', fontWeight: 550 }}
                                            onClick={() => handleClickWorkOrderDetails(jobItem?._id)}
                                        >
                                            View Work Order Details
                                        </Typography>
                                        <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, textDecoration: 'underline', cursor: 'pointer', fontWeight: 550 }}
                                            onClick={() => { history.push(`/dashboard/jobs?jobId=${jobItem?.jobId}`) }} data-testid="full-details"
                                        >
                                            View Full Details
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }} mt={1}>
                                    <Grid item lg={3}>
                                        <Typography sx={{ ...textClasses.t14n, color: '#827878', wordWrap: 'break-word', mr: 1 }}>
                                            Job Category: <span style={{ color: theme.themeBlack }}>{textCapitalize(jobItem?.jobCategory)}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={3}>
                                        <Typography sx={{ ...textClasses.t14n, color: '#827878', wordWrap: 'break-word', mr: 1 }}>
                                            Total Associated QTY: <span style={{ color: theme.themeBlack }}>{jobQty?.totalQuantity || 0}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={3}>
                                        <Typography sx={{ ...textClasses.t14n, color: '#827878', wordWrap: 'break-word', mr: 1 }}>
                                            Scanned QTY: <span style={{ color: theme.themeBlack }}>{jobQty?.scannedQuantity || 0}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={3}>
                                        <Typography sx={{ ...textClasses.t14n, color: '#827878', wordWrap: 'break-word', mr: 1 }}>
                                            Completed On: <span style={{ color: theme.themeBlack }}>{dateTimeFormater(jobItem?.completedOn)}</span>
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }} mt={1}>
                                    <Grid item lg={3}>
                                        <Typography sx={{ ...textClasses.t14n, color: '#827878', wordWrap: 'break-word', mr: 1 }}>
                                            Completed By: <span style={{ color: theme.themeBlack }}>{jobItem?.completedBy}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={3}>
                                        <Typography sx={{ ...textClasses.t14n, color: '#827878', wordWrap: 'break-word', mr: 1 }}>
                                            Created On: <span style={{ color: theme.themeBlack }}>{dateTimeFormater(jobItem?.createdOn)}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={3}>
                                        <Typography sx={{ ...textClasses.t14n, color: '#827878', wordWrap: 'break-word', mr: 1 }}>
                                            Remark: <span style={{ color: theme.themeBlack }}>{jobItem?.remark?.remarkValue || '-'}</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        position:'sticky',
                        bottom: 0, 
                        backgroundColor: 'white', 
                        zIndex: 1000,  
                    }}>
                        <PaginationComp
                            pageCount={totalRows}
                            pageNumber={page}
                            rowsPerPage={limit}
                            handleChangePage={(event, pagevalue) => {
                                setPage(pagevalue);
                            }}
                            handleChangeRowsPerPage={(event) => {
                                setLimit(+event.target.value);
                                setPage(0);
                            }}
                        />
                    </Box>
                </>
            ) : (
                <Grid container justifyContent="center" alignItems="center" sx={{ p: 4 }}>
                    <DataNotFound label="No data found for this selected date range." />
                </Grid>
            )}
            {/* Work Order List */}
            {checkedRow?.visible == true && checkedRow?.type == "viewWorkOrderList" &&
                <ViewWorkOrderList
                    checkedRow={checkedRow}
                    setCheckedRow={setCheckedRow}
                    _id={checkedRow?.jobComponentId}
                />
            }
        </>
    );

};

export default AssociatedJobsSection;
