import React, { useEffect, useState } from "react";
import AlertDialog from "../../../Components/DialogBox/dialogbox";
import { Grid, Box, Typography, Button, FormControl, OutlinedInput, IconButton, InputAdornment } from "@mui/material";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import theme, { textClasses, buttonClasses, inputClasses } from "../../../../static/styles/theme";
import { useDispatch } from "react-redux";
import GenericSingleRangeComponent from "../../../Components/Generic/GenericSingleRangeComponent";
import { startKitPartialBundlingProcess } from "../../../../redux/actions";
import { showToast } from "../../../../services/functions";

const ItemConfirmationPopup = ({ viewModal, setViewModal, handleSubmitData, handleClosePopup }) => {
    return (
        <>

            <AlertDialog
                viewDialog={viewModal.type === "confirmationPopup"}
                handleClose={() => setViewModal({ ...viewModal, type: '' })}
                showDivider={true}
                width="40%"
                actionsCSS={{ justifyContent: 'flex-start' }}
                // title={`Total Item count : ${viewModal?.action?.totalItemCount}`}
                body={
                    <>
                        {
                            <Box sx={{ width: "inherit" }}>
                                <Grid container justifyContent={"center"} alignItems="center">
                                    <Typography sx={{ ...textClasses.cardTitle }}>
                                        Are you Sure ?
                                    </Typography>
                                </Grid>

                                <Grid container justifyContent={"center"} alignItems="center">
                                    <Typography sx={{ ...textClasses.t15nBold }}>
                                        You are about to submit this pallet with {viewModal?.action?.defaultNumberOfItemsInPallet} items.
                                    </Typography>
                                </Grid>
                                <Grid container justifyContent={"center"} alignItems="center" textAlign={"center"} sx={{mt: 1 }}>
                                    <Typography sx={{ ...textClasses.t14n }}>
                                        Once submitted, changes may not be possible. Please review and confirm before proceeding.

                                    </Typography>
                                </Grid>

                                <Grid container justifyContent={"center"} alignItems="center">
                                    <Typography sx={{ ...textClasses.t15n, color: theme.themeOrange }}>
                                        Total Item Count : {viewModal?.action?.totalItemCount}
                                    </Typography>
                                </Grid>
                            </Box>
                        }
                    </>
                }

            >
                <Button
                    variant="outlined"
                    data-testid="cancel-btn"
                    onClick={() => {
                        handleClosePopup();
                        setViewModal({ ...viewModal, type: '' })
                    }} sx={{ ...buttonClasses.lynkitBlackEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                >No, Cancel
                </Button>
                <Button
                    variant="outlined"
                    data-testid="submit-btn"
                    onClick={() => {
                        handleSubmitData(viewModal?.action?.submissionData);
                        setViewModal({ ...viewModal, type: '' });
                    }}
                    sx={{ ...buttonClasses.lynkitOrangeFill, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                >Yes, Submit
                </Button>
            </AlertDialog>
        </>
    )
}
export default ItemConfirmationPopup;
