import React from "react";
import {
    Grid, 
    TextField,
    Typography,
    Box,
    Autocomplete,
} from "@mui/material";

import { selectStylesOverride } from "../../../../utils/util";
import TypeAndSearch from "../../../Components/TypeAndSearch";
import {
    StockTranferState,
    reasonComponent,
} from "../Constant";


export default function StockTranferElement({
    allWarehouse = [],
    allUser = [],
    stockTransferDetail,
    setStockTransferDetail,
    floorOptions,
    jobType
}) {
    let stockTransferOption = [
        {
            label: `Other Warehouse ( ${allWarehouse.length} )`,
            value: "otherWarehouse",
        },
        { label: `Internal User ( ${allUser.length} )`, value: "internalUser" },
        { label: "External User", value: "externalUser" },
        { label: "Internal Consumption", value: "internalConsumption" },

    ];

    return (
        <>
            <Grid mt={3}>
                <Box>
                    <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
                        Stock Transfer Details
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", flexWrap: "wrap", mt: 2 }}>
                    <Box sx={{ display: "flex", gap: "2em", flexBasis: "100%", mb: 2 }}>
                        <Autocomplete
                            id="stock-transfer-from-warehouse"
                            size="small"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Transfer From * (Warehouse)"
                                    sx={{
                                        ...selectStylesOverride,
                                    }}
                                />
                            )}
                            sx={{ flexBasis: "50%", mb: 2 }}
                            options={allWarehouse}
                            onChange={(e, option) =>
                                setStockTransferDetail({
                                    ...StockTranferState,
                                    transferFromWarehouse: option,
                                })
                            }
                            value={stockTransferDetail?.transferFromWarehouse?.label || ""}
                        />
                        <Autocomplete
                            id="stock-transfer-to-category"
                            size="small"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Transfer To*"
                                    sx={{
                                        ...selectStylesOverride,
                                    }}
                                />
                            )}
                            disabled={!stockTransferDetail?.transferFromWarehouse?.value}
                            sx={{ flexBasis: "50%", mb: 2 }}
                            options={stockTransferOption}
                            onChange={(e, option) =>
                                setStockTransferDetail({
                                    ...StockTranferState,
                                    transferFromWarehouse:
                                        stockTransferDetail?.transferFromWarehouse || {},
                                    transferToCategory: option,
                                })
                            }
                            value={stockTransferDetail?.transferToCategory?.label || ""}
                        />
                    </Box>
                    {stockTransferDetail?.transferToCategory?.value == "otherWarehouse" ? (
                        <Box sx={{ display: "flex", gap: "2em", flexBasis: "100%" }}>
                            <Autocomplete
                                id="stock-transfer-to-warehouse"
                                size="small"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Warehouse*"
                                        sx={{
                                            ...selectStylesOverride,
                                        }}
                                    />
                                )}
                                sx={{ flexBasis: "49%", mb: 2 }}
                                options={allWarehouse}
                                getOptionDisabled={(option) =>
                                    option?.value ==
                                    stockTransferDetail?.transferFromWarehouse?.value
                                }
                                onChange={(e, option) =>
                                    setStockTransferDetail({
                                        ...stockTransferDetail,
                                        transferToWarehouse: option,
                                        transferToFloor: {},
                                    })
                                }
                                value={stockTransferDetail?.transferToWarehouse?.label || ""}
                            />

                            <Autocomplete
                                id="stock-transfer-to-floor"
                                size="small"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Floor*"
                                        sx={{
                                            ...selectStylesOverride,
                                        }}
                                    />
                                )}
                                sx={{ flexBasis: "49%", mb: 2 }}
                                options={floorOptions(
                                    stockTransferDetail?.transferToWarehouse?.value || ""
                                )}
                                onChange={(e, option) =>
                                    setStockTransferDetail({
                                        ...stockTransferDetail,
                                        transferToFloor: option,
                                    })
                                }
                                value={stockTransferDetail?.transferToFloor?.label || ""}
                            />
                        </Box>
                    ) : (
                        ""
                    )}
                    {stockTransferDetail?.transferToCategory?.value == "internalUser" ? (
                        <Box sx={{ display: "flex", gap: "2em", flexBasis: "100%" }}>
                            <Autocomplete
                                id="stock-transfer-to-user"
                                size="small"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select User*"
                                        sx={{
                                            ...selectStylesOverride,
                                        }}
                                    />
                                )}
                                sx={{ flexBasis: "49%", mb: 2 }}
                                options={allUser}
                                onChange={(e, option) =>
                                    setStockTransferDetail({
                                        ...stockTransferDetail,
                                        transferToInternalUser: option,
                                    })
                                }
                                value={stockTransferDetail?.transferToInternalUser?.label || ""}
                            />
                        </Box>
                    ) : (
                        ""
                    )}

                    {stockTransferDetail?.transferToCategory?.value == "externalUser" ? (
                        <Box sx={{ display: "flex", gap: "2em", flexBasis: "100%" }}>
                            <TextField
                                id="outlined-adornment-Rows"
                                label="Enter User Name*"
                                type="text"
                                size="small"
                                sx={{ flexBasis: "50%", ...selectStylesOverride }}
                                value={stockTransferDetail?.name || ""}
                                onChange={(event) =>
                                    setStockTransferDetail({
                                        ...stockTransferDetail,
                                        name: event.target.value,
                                    })
                                }
                            />
                            <TextField
                                id="outlined-adornment-Rows"
                                label="Enter User Email*"
                                type="email"
                                size="small"
                                sx={{ flexBasis: "50%", ...selectStylesOverride }}
                                value={stockTransferDetail?.email || ""}
                                onChange={(event) =>
                                    setStockTransferDetail({
                                        ...stockTransferDetail,
                                        email: event.target.value,
                                    })
                                }
                            />
                            <TextField
                                id="outlined-adornment-Rows"
                                label="Enter User Contact Number*"
                                type="number"
                                inputProps={{
                                    pattern: "[0-9]*", // Only allow numbers
                                }}
                                onKeyDown={(e) => {
                                    if (["e", "E", "+", "-"].includes(e.key)) {
                                        e.preventDefault();
                                    } else if (
                                        e.key == "0" &&
                                        (e.target.value == "" || e.target.value[0] == "0")
                                    ) {
                                        e.preventDefault();
                                    }
                                }}
                                size="small"
                                sx={{ flexBasis: "50%", ...selectStylesOverride }}
                                value={stockTransferDetail?.mobile || ""}
                                onChange={(event) => {
                                    if (event.target.value.length > 10) {
                                        return;
                                    }
                                    setStockTransferDetail({
                                        ...stockTransferDetail,
                                        mobile: event.target.value,
                                    });
                                }}
                            />
                            <TextField
                                id="outlined-adornment-Rows"
                                label="User Address"
                                type="text"
                                sx={{ flexBasis: "50%", ...selectStylesOverride }}
                                size="small"
                                value={stockTransferDetail?.address || ""}
                                onChange={(event) =>
                                    setStockTransferDetail({
                                        ...stockTransferDetail,
                                        address: event.target.value,
                                    })
                                }
                            />
                        </Box>
                    ) : (
                        ""
                    )}
                    {stockTransferDetail?.transferToCategory?.value == "internalConsumption" ? (
                        <Grid item sm={5.9}>
                            <TypeAndSearch
                                data-testid="select-reason"
                                component={reasonComponent("stockTransfer")}
                                setEmptyOnChange={false}
                                value={stockTransferDetail?.reason?.value}
                                label={stockTransferDetail?.reason?.label}
                                handleChange={(newValue, option) => {
                                    setStockTransferDetail({
                                        ...stockTransferDetail,
                                        reason: option,
                                    })
                                }}
                                reasonType={"stockTransfer"}
                                addNewOptions={{ isEnabled: true, label: 'Add New Reason' }}
                            />
                        </Grid>
                    ) : (
                        ""
                    )}
                </Box>
            </Grid>
        </>
    );
};
