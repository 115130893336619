import React, { useState } from "react";
import dayjs from "dayjs";
import { Grid, IconButton, Typography, Box, Paper } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronLeft";
import ChevronLeftIcon from '@mui/icons-material/ChevronRight';
const stockData = {
  "2024-08-02": "ok",
  "2024-08-05": "out",
  "2024-08-10": "low",
  "2024-08-14": "out",
  "2024-08-17": "safety",
  "2024-08-23": "excess",
  "2024-08-30": "ok",
};

const statusColors = {
  ok: "#3E621E",
  low: "#FCCB3E",
  out: "#E9001A",
  safety: "#7FCA3D",
  excess: "darkgreen",
};

const statusLabels = [
  { label: "OK Stock", color: "#3E621E" },
  { label: "Low Stock", color: "#FCCB3E" },
  { label: "Stock Out", color: "#E9001A" },
  { label: "Safety Stock", color: "#7FCA3D" },
  { label: "Excess Stock", color: "darkgreen" },
];

const GenericCustomisedDayCalendar = () => {
  const [currentMonth, setCurrentMonth] = useState(dayjs());

  const startOfMonth = currentMonth.startOf("month");
  const endOfMonth = currentMonth.endOf("month");
  const startDay = startOfMonth.day();
  const daysInMonth = endOfMonth.date();

  const prevMonthDays = Array(startDay).fill(null);
  const monthDays = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  const handlePrevMonth = () => {
    setCurrentMonth(currentMonth.subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setCurrentMonth(currentMonth.add(1, "month"));
  };

  return (
    <Box width="100%" textAlign="center" mt={2}>
      {/* Legend */}
      <Box display="flex" justifyContent="center" gap={2} mb={2}>
        {statusLabels.map((status) => (
          <Paper
            key={status.label}
            sx={{
              display: "flex",
              alignItems: "center",
              p: 1,
              border: `1px solid ${status.color}`,
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: "50%",
                backgroundColor: status.color,
                marginRight: 1,
              }}
            />
            <Typography variant="body2" sx={{color:status.color}}>{status.label}</Typography>
          </Paper>
        ))}
      </Box>

      {/* Month Navigation */}
      <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
        <IconButton onClick={handlePrevMonth}><ChevronRightIcon fontSize="large"/></IconButton>
        <Typography variant="h5">{currentMonth.format("MMMM YYYY")}</Typography>
        <IconButton onClick={handleNextMonth}>< ChevronLeftIcon fontSize="large"/></IconButton>
      </Box>

      {/* Calendar Grid */}
      <Grid container spacing={1} sx={{ width: "100%", maxWidth: "800px", margin: "auto" }}>
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <Grid key={day} item xs={1.71}>
            <Typography fontWeight="bold">{day}</Typography>
          </Grid>
        ))}
        {[...prevMonthDays, ...monthDays].map((day, index) => {
          const dateKey = day
            ? `${currentMonth.format("YYYY-MM")}-${String(day).padStart(2, "0")}`
            : null;
          const stockStatus = dateKey && stockData[dateKey];

          return (
            <Grid
              key={index}
              item
              xs={1.71}
              sx={{
                height: "100px",
                border: "1px solid #827878",
                backgroundColor: day ? "white" : "#f0f0f0",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: 1,
              }}
            >
              {day && (
                <>
                  {/* Day number at the top left */}
                  <Typography
                    variant="body2"
                    sx={{
                      position: "absolute",
                      top: 4,
                      left: 4,
                      fontWeight: "bold",
                    }}
                  >
                    {day}
                  </Typography>

                  {/* Stock status centered */}
                  {stockStatus && (
                    <Box
                      sx={{
                        width: 50,
                        height: 50,
                        borderRadius: "50%",
                        backgroundColor: statusColors[stockStatus],
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {/* {stockStatus.charAt(0).toUpperCase()} */}
                    </Box>
                  )}
                </>
              )}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default GenericCustomisedDayCalendar;
