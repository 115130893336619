import {
    Button,
    Grid,
    Typography,
    Box,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import theme, {
    buttonClasses,
    cardClasses,
    inputClasses,
    textClasses,
} from "../../../../static/styles/theme";
import { useDispatch, useSelector } from "react-redux";
import {
    updateInventoryDetail, getSelectedTemplate
} from "../../../../redux/actions";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import AlertDialog from "../../../Components/DialogBox/dialogbox";
import StaticFormRender from "../../../Components/DynamicFormRender/staticRendererForm";
import { useForm } from 'react-hook-form'
import { showErrorDynamicField, showToast } from "../../../../services/functions";
import { keyPressActions } from "../../../../services/constants";



const UpdateInventoryCompoent = (props) => {
    const dispatch = useDispatch();
    const {detail, invType, modelVisible, handleClose,handleRemoveSelection,refreshTableData } = props
    const { register, handleSubmit, watch, getValues, setValue, control, reset, formState: { errors } } = useForm();


    const [loader, setLoader] = useState(false);
    const [attributes, setAttributes] = useState([]);
    const [disabled, setDisabled] = useState([]);

    const onKeyPressEnterTrigger = (e) => {
        if (e.key === keyPressActions["submit"]) {
            handleSubmit(handleUpadate, showErrorDynamicField)();
        }
    };

    const handleUpadate = () => {
        handleSubmit((data) => {
            let payload = {
                formData: { ...data },
                inventoryId:detail[`${invType.toLowerCase()}Id`],
                inventoryType: invType.toLowerCase()
            }
            setLoader(true);

            dispatch(
                updateInventoryDetail(payload, ({ message, success = false }) => {
                    setLoader(false);
                    showToast(message, !success)
                    if (success) {
                        handleClose("success");
                        refreshTableData("success");
                        if(handleRemoveSelection)handleRemoveSelection(detail._id);
                    }
                })
            )
        }, (errors) => {
            let errorField = Object.keys(errors)[0];
            showToast(`${errors[errorField]?.message}`, true);
        })();
    }

    useEffect(() => {
        const template_id=invType == "Shipment" ? detail?.template_id : detail?.dataEntryTemplate_Id
        if (template_id) {
            const formData=detail[invType == "Shipment" ? "formData" : "dynamicData"] || {}
            for (const key in formData) {
                setValue(key, formData[key])
            }
            setLoader(true);
            dispatch(getSelectedTemplate({ template_id, fetchForInward: true, }, (data) => {
                let tempDisabled = ["count", "packageQuantity"]
                let tempAttr = data?.attributes || []
                if (invType == "Item") {
                    tempAttr=(data?.otherTemplate?.find(o => o?.templateType == "itemDetailTemplate")?.attributes || [])
                }
                else if (invType == "Package") {
                    tempAttr=(data?.otherTemplate?.find(o => o?.templateType == 'packageDetailTemplate')?.attributes || [])
                }
                tempDisabled=tempDisabled.concat(tempAttr.filter(o => !o.updateInDetail).map(o => o?._id))
                setAttributes(tempAttr);
                setLoader(false);
                setDisabled(tempDisabled)
            }))
        }
        else {
            setAttributes([])
        }
    }, [detail]);

    return (
        <AlertDialog
            viewDialog={modelVisible}
            showDivider={true}
            handleClose={handleClose}
            sx={{ borderRadius: "50px" }}
            width="70%"
            title={
                <Typography
                    sx={{ fontWeight: "1000", fontSize: "20px",color:theme.themeBlack }}
                >
                    Update Detail : ID - {detail[`${invType.toLowerCase()}Id`] || ""}
                </Typography>
            }
            body={
                <Box sx={{ width: "inherit",padding:"20px 10px 0 10px" }}>
                    {loader
                        ? <Grid container direction={"column"} justifyContent={"center"} alignItems="center" sx={{ p: 15 }}>
                            <Grid item >
                                <GenericLoader />
                            </Grid>
                        </Grid>
                        : <StaticFormRender
                            formHooks={{ register, control, setValue, errors, getValues, reset }}
                            tempAttr={attributes}
                            disabled={disabled}
                            onKeyPressEnterTrigger={onKeyPressEnterTrigger}
                        />
                    }
                </Box>
            }
        >
            <Button
                variant="outlined"
                disabled={loader}
                onClick={handleClose}
                sx={{
                    ...buttonClasses.lynkitOrangeEmpty,
                    m: 1,
                    fontSize: ".7rem",
                    minWidth: "100px",
                    display: 'flex !important',
                }}
            >
                Cancel
            </Button>
            <Button
                variant="outlined"
                disabled={loader || attributes.length == 0 || attributes.every(o=>disabled.includes(o._id))}
                onClick={handleUpadate}
                sx={{
                    ...buttonClasses.lynkitOrangeFill,
                    m: 1,
                    fontSize: ".7rem",
                    minWidth: "100px",
                    display: 'flex !important',
                }}
            >
                Update
            </Button>
        </AlertDialog>
    )
}

export default UpdateInventoryCompoent

