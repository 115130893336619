import React, { useEffect } from 'react'
import TableCardWidget from './TableCardWidget';
const TableTypeWidget = ({ item, setLayout, styleView = {}, onFilterChange,isDefaultDashboard = false,dataLoader=false ,children }) => {

    return (
        <TableCardWidget item={item} setLayout={setLayout} onFilterChange={onFilterChange} isDefaultDashboard={isDefaultDashboard} dataLoader={dataLoader} styleView={styleView} >
            {children}
        </TableCardWidget>
    )
}

export default TableTypeWidget;