import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import theme, { buttonClasses } from "../../../../static/styles/theme";
import { selectStylesOverride } from "../../../../utils/util";
import TypeAndSearch from "../../../Components/TypeAndSearch";
import { useCallback, useMemo, useState } from "react";
import GenericCollapsableItemList from "../../../Components/Generic/GenericCollapsableItemList";
import SelectInventories from "./SelectInventories";
import { showToast } from "../../../../services/functions";
import { Add, Delete, Remove } from "@mui/icons-material";
import { Edit } from "@mui/icons-material";
import SelectLocationDialog from "./SelectLocationDialog";
import { getLabelKeyForJobConfig,checkboxChangeSameForALL } from "../Constant";
import GenericSingleRangeComponent from "../../../Components/Generic/GenericSingleRangeComponent";


const LocationWiseCategory = ({
  isJobType,
  floorOptions,
  warehouse,
  jobFloorSelect,
  floor,
  cell,
  setCell,
  setAddedItems,
  addedItems=[],
  clearStates,
  jobProcessConfig,
  category,
  jobType
}) => {
  const [showInventories, setShowInventories] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [alreadySelectedItem, setAlreadySelectedItem] = useState();
  const [itemForLocationChange, setItemForLocationChange] = useState();
  const [isEdit, setEdit] = useState(false);
  const floors = floorOptions(warehouse?.value ?? "");
  const [newLocations, setNewLocations] = useState([]);
  const component = {
    _id: "cellId",
    type: "select",
    disabled: false,
    label: `Select Cell`,
    placeholder: `Select cell`,
    description: "Select Cell",
    required: true,
    dynamic: true,
    auth: true,
    apiDomain: "/v1/",
    apiEndPoint: "grid/getGridDetail",
    apiMethod: "post",
    fetched: false,
    name: "Storage List",
    apiDataReturnKey: "data",
    dontFetch:false,
    apiParameters: [
      {
        label: "fetch_for",
        value: "pickUp",
      },
      {
        label: "type",
        value: ["rack", "block"],
      },
      {
        label: "floorId",
        value: floor.value,
      },
      // {
      //   label: "pickUpAllowed",
      //   value: [
      //     "changeLocation",
      //     // "inventoryAudit",
      //     // "inHouseQualityCheck",
      //     // "inHouseQualityCheck"
      //   ].includes(jobType) || "all",
      // },
      {
        label: "status",
        value: ["partial", "full"],
      },
      {
        label: "cellName",
        value: "$self",
      },
      {
        label: "page",
        value: 1,
      },
      {
        label: "limit",
        value: 25,
      },
    ],
    apiBindingKey: "name",
    apiBindingValue: "name",
    suffix: "",
    extraValue: [
      { label: "cellId", value: "cellId" },
      { label: "parentId", value: "parentId" },
      { label: "name", value: "name" },
      { label: "type", value: "type" },
    ],
  };

  const skuComponent = {
    _id: "skuId",
    type: "select",
    disabled: false,
    label: `Select SKU`,
    placeholder: `Select SKU`,
    description: "Select SKU",
    required: true,
    dynamic: true,
    auth: true,
    apiDomain: "/v1/",
    apiEndPoint: "job/getWarehousewiseSKU",
    apiMethod: "post",
    fetched: false,
    name: "Storage List",
    apiDataReturnKey: "data",
    // dontFetch:true,
    apiParameters: [
      {
        label: "fetch_for",
        value: jobType,
      },
      {
        label: "isAllSKU",
        value: false,
      },
      {
        label: "warehouseId",
        value: warehouse?.value,
      },
      {
        label: "searchKey",
        value: "itemMasterDetail.SKU_ID",
      },
      {
        label: "searchValue",
        value: "onSearch",
      },
      {
        label: "page",
        value: 1,
      },
      {
        label: "pickUpAllowed",
        value: "all",
      },
      {
        label: "limit",
        value: 100,
      },
    ],
    apiBindingKey: "SKU_ID",
    apiBindingValue: "SKU_ID",
    suffix: "",
    extraValue: [
      { label: "count", value: "count" },
      { label: "SKU_Detail", value: "SKU_Detail" },
    ],
    "renderOption": [
      {
        "label": "",
        "multiple": false,
        "prefix": "",
        "postfix": "",
        "key": "SKU_ID",
        "keys": [

        ]
      },
      {
        "label": "",
        "multiple": false,
        "prefix": " (",
        "postfix": ")",
        "key": "SKU_Detail"
      }
    ],
  };


  const header = isJobType?.isChangeLocation ? [
    "Shipment ID",
    "Package ID/Name",
    "Item ID",
    "Available QTY",
    "Selected QTY",
    "Old Location",
    "New Location",
    "Actions",
  ] : [
    "Sr. No",
    getLabelKeyForJobConfig(jobProcessConfig, "shipment", "label"),
    getLabelKeyForJobConfig(jobProcessConfig, "package", "label"),
    getLabelKeyForJobConfig(jobProcessConfig, "item", "label"),
    "Location",
    "Available QTY",
    "Action",
  ];

  const handleRowDelete = useCallback((indx) => {
    setAddedItems((o) => o.filter((_, i) => i !== indx));
  },[setAddedItems]);

  const handleItemCountChange = (countValue, i, limit) => {
    const oldAddedData = [...addedItems];
    if (countValue <= 0) {
      oldAddedData[i].selectedQTY = 0
    }
    else {
      oldAddedData[i].selectedQTY = Math.min(limit, countValue)
    }
    delete oldAddedData[i].cellDetail;
    setAddedItems(oldAddedData);
  }

  const handleSelectData = useCallback((index)=>{
    setItemForLocationChange({...addedItems[index],isSameForAll:addedItems[index]?.isSameForAll || false});
    setEdit(true)
  },[addedItems])

  const handleCheckboxChange = (index,event) => {
    if (event.target.checked) {
    const updatedItems = [...addedItems];
    const newItem = { ...updatedItems[index], isSameForAll: true };
    updatedItems[index] = newItem;
    const processedItems = checkboxChangeSameForALL(index, updatedItems);
    setAddedItems(processedItems);
    }else{
      const updatedItems = [...addedItems];
      delete updatedItems[index]?.isSameForAll;
      setAddedItems(updatedItems);
    }
  }

  const rows = addedItems.map((data, indx) => {
    let row = [];
    if(isJobType?.isChangeLocation){
      const remainingCellDetails = data?.cellDetail?.length - 2;
      const locationColumn = () => {
  
        if (data?.cellDetail?.length>0) {
          return <div key={`location-column-${indx}`} style={{ display: 'flex', alignItems: 'center' }}><Typography sx={{ color: "#FF7200" }}>
            {data.cellDetail?.slice(0, 2)?.map((detail, index) => (
              <React.Fragment key={index}>
                {`${detail.name} (${detail.count} QTY)`}
                {index < data.cellDetail.length - 1 && ", "}
              </React.Fragment>
            ))}
            {remainingCellDetails > 0 && (
              <>
                {" "}
                <Typography
                  component="span"
                  style={{
                    color: "#FF7200",
                    textDecoration: "underline",
                    fontWeight: 600,
                  }}
                >
                  {`+${remainingCellDetails} more`}
                </Typography>
              </>
            )}
          </Typography>
            {indx < addedItems.length - 1 && (
              <FormControlLabel
              key={`checkbox-${indx}`}
                control={
                  <Checkbox
                    data-testid="checkbox"
                    checked={Boolean(data?.isSameForAll)}
                    onChange={(event) => { handleCheckboxChange(indx, event) }}
                  />}
                label="Same for all next"
                sx={{ marginLeft: 1 }}
              />
            )}
          </div>
        }
        else {
          return (<div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              variant="outlined"
              sx={{
                size: "small",
                ...buttonClasses?.lynkitOrangeFill,
                minHeight: "36px",
                minWidth: "130px",
              }}
              disabled={!data?.selectedQTY || data?.selectedQTY <= 0}
              onClick={() => {
                
                setItemForLocationChange({...data,isSameForAll:data?.isSameForAll || false});
              }}
            >
              Select Location
            </Button>
            {indx < addedItems.length - 1 && (
              <FormControlLabel
                control={<Checkbox
                  // checked={data?.isSameForAll || false}
                  disabled={!data?.cellDetail}
                />}
                label="Same for all next"
                sx={{ marginLeft: 1 }}
              />
            )}
          </div>
          )
        }

      }

      const icons = () => {
        if (data.cellDetail) {
          return <Typography sx={{ display: "flex" }}>
            {data?.cellDetail ? <IconButton
              aria-label="delete"
              key={indx}
              onClick={() => {
                handleSelectData(indx)
              }}
            >
              <Edit sx={{ color: "black" }} />
            </IconButton> : undefined}
            <IconButton
              aria-label="delete"
              key={indx}
              onClick={() => handleRowDelete(indx)}
            >
              <Delete sx={{ color: "red" }} />
            </IconButton>
          </Typography>
        } else {
          return <Typography sx={{ display: "flex" }}>
  
            <IconButton
              aria-label="delete"
              key={indx}
              onClick={() => handleRowDelete(indx)}
            >
              <Delete sx={{ color: "red" }} />
            </IconButton>
          </Typography>
        }
      }
  
      row.push(
        <Typography>
          {data.shipmentId ?? "-"}
        </Typography>,
        <Typography>
          {data.packageId ? data.packageId + "/" + data.packageName : "-"}
        </Typography>,
        <Typography>{data?.itemId ?? "-"}</Typography>,
        <Typography>{data?.availableQty ?? "-"}</Typography>,
        <GenericSingleRangeComponent
          startRange={data?.selectedQTY || null}
          endRange={Number(data?.availableQty || 0)}
          getOnChange={(countValue) => handleItemCountChange(countValue, indx,data?.availableQty)}
          endRangeInfinity={false}
          valueType={"decimal"}
        />,
        <Typography>{data.rackName ?? data.cellName ?? "-"}</Typography>,
        locationColumn(),
        icons(),
      );
    }
    
    else {
      const {
        cellName,
        rackName,
        count
      } = data;

      // ////("data", data);
      // ////("mappia",mappingArea);
      row.push(
        indx + 1,
        getLabelKeyForJobConfig(jobProcessConfig, "shipment", "key", data, ["gateInTime"], true),
        getLabelKeyForJobConfig(jobProcessConfig, "package", "key", data, ["gateInTime"], true),
        getLabelKeyForJobConfig(jobProcessConfig, "item", "key", data, ["gateInTime"], true),
        rackName?cellName+"-"+rackName:cellName,
        count || 1,
        [
          <IconButton
            aria-label="delete"
            key={indx}
            onClick={() => handleRowDelete(indx)}
          >
            <Delete sx={{ color: "red" }} />
          </IconButton>,
        ]
      );
    }

    return row;
  });
  
  const handleSelectCell = useCallback(
    (option, newValue) => {
      let key="cellId"
      if(category=="skuWise"){
        key="value"
      }
      setCell((prev) => [...prev, { label: option, value: newValue[key] }]);
    },
    [setCell]
  );

  const handleRemoveSelectedObject = useCallback(
    (i, item) => {
      setCell((prev) => {
        return prev.filter((selectedCell) => selectedCell.value !== item.value);
      });
    },
    [setCell]
  );

  const handleSubmit = useCallback(() => {
    /*****
     * check for duplicate entries
     */
    setAddedItems((prev) => [...selectedItems, ...prev]);
    setShowInventories(false);
  }, [selectedItems]);

  const handleCloseLocationDialog = useCallback(() => {
    setNewLocations([])
    setItemForLocationChange();
  }, [setItemForLocationChange, setNewLocations]);

  const handleClose = useCallback(() => {
    setShowInventories(false);
    setSelectedItems([]);
    setItemForLocationChange()
  }, []);

  const handleNewLocationSubmit = useCallback(() => {
    const itemToAddDetails = addedItems.find((item) => item._id === itemForLocationChange?._id)
    itemToAddDetails["cellDetail"] = newLocations;
    itemToAddDetails["isSameForAll"] = itemForLocationChange?.isSameForAll;
    const index = addedItems.findIndex(item => item._id === itemForLocationChange?._id)
    const temporaryAddedItems = addedItems;
    temporaryAddedItems[index] = itemToAddDetails;
    if (temporaryAddedItems[index]?.isSameForAll == true) {
      const updatedItems = checkboxChangeSameForALL(index, temporaryAddedItems);
      setAddedItems(updatedItems);
    }
    setAddedItems(temporaryAddedItems)
    setItemForLocationChange()
    setNewLocations([])

  }, [newLocations, itemForLocationChange, addedItems]);

  return (
    <>
      <Grid mt={3}>
        <Box>
          <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
            Inventory Details
          </Typography>
        </Box>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            mt: 2,
            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='gray' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
            p: 3,
          }}
        >
          <Grid item xs={12}>
            <GenericCollapsableItemList
              items={cell}
              limit={3}
              itemSpacing={{ sm: 3, xs: 4 }}
              handleRemoveItem={handleRemoveSelectedObject}
              getContent={(itemList, i) => {
                let itemObj = itemList[i];
                return itemObj?.label;
              }}
              sx={{ mb: 3 }}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={2}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            {category=="locationWise" && (
              <Grid item xs={4}>
                <Autocomplete
                  id="floor-label"
                  data-testid="floor-select"
                  size="small"
                  required
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <Typography sx={{ color: theme.themeOrange }}>
                          Floor*
                        </Typography>
                      }
                      sx={{
                        ...selectStylesOverride,
                        color: theme.themeOrange,
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.label}
                    </Box>
                  )}
                  sx={{
                    // minWidth: "10em",
                    width: "100%",
                    // "& .Mui-focused.MuiOutlinedInput-notchedOutline": {
                    // 	borderColor: theme.themeOrange,
                    // },
                    mr: 1,
                    "& legend span": {
                      color: theme.themeOrange,
                    },
                  }}
                  options={floors}
                  onChange={jobFloorSelect}
                  value={floor.label}
                />
              </Grid>
            )}
            {(category=="locationWise" ? floor?.value:true) && (
              <Grid item xs={4}>
                <TypeAndSearch
                  component={category=="skuWise"? skuComponent:component}
                  setEmptyOnChange={true}
                  handleChange={(option, newValue = {}) => {
                    let key = "cellId"
                    if (category == "skuWise") {
                      key = "value"
                    }
                    const isAlreadyExist = cell?.find(
                      (data) => newValue && data?.value === newValue[key]
                    );
                    if (isAlreadyExist) {
                      showToast(
                        `${option.target.value} is already selected`,
                        true
                      );
                    }
                    if (option.target.value && !isAlreadyExist) {
                      handleSelectCell(option?.target.value, newValue);
                    }
                  }}
                  value={""}
                  set_Value={true}
                />
              </Grid>
            )}
    
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    ml: 2,
                    backgroundColor: theme.themeOrange,
                    "&:hover": {
                      backgroundColor: theme.themeOrange,
                    },
                  }}
                  onClick={() => setShowInventories(true)}
                  disabled={ (category=="skuWise" ? false : !floor?.value) || (!cell || cell.length==0)}
                >
                  Select Inventory Of This Location
                </Button>
              </Grid>
          </Grid>
        </Grid>
        {addedItems.length ? (
          <TableContainer
            component={Paper}
            sx={{
              marginTop: "2em",
              maxWidth: "100%",
              overflowX: "auto",
            }}
          >
            <Table aria-label="simple table">
              <TableHead sx={{ backgroungColor: theme.themeGray }}>
                <TableRow sx={{ backgroundColor: theme.themeGray }}>
                  {header.map((head, i) => {
                    return (
                      <TableCell
                        sx={{
                          fontSize: "13px",
                          textAlign: "center",
                          fontWeight: 600,
                        }}
                        key={i}
                      >
                        {head}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.length > 0
                  ? rows.map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          "& .MuiTableCell-root": {
                            // borderLeft:
                            // "1px solid rgba(224, 224, 224, 1)",
                          },
                        }}
                      >
                        {row.map((col, j) => {
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                fontSize: "12px",
                                textAlign: "center",
                                overflowWrap: "break-word",
                              }}
                              key={j}
                            >
                              {col}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        ) :   null}
      </Grid>
      {itemForLocationChange?.selectedQTY && <SelectLocationDialog
        open={!!itemForLocationChange}
        floorOptions={floors}
        isEdit={isEdit}
        newLocations={newLocations}
        setNewLocations={setNewLocations}
        selectedLocation={itemForLocationChange}
        onClose={handleCloseLocationDialog}
        onSubmit={handleNewLocationSubmit}
        setSelectedLocation={setItemForLocationChange}
      />}
      {showInventories &&
        <SelectInventories
          open={showInventories}
          handleRemoveSelectedObject={handleRemoveSelectedObject}
          cell={cell}
          onClose={handleClose}
          setSelectedItems={setSelectedItems}
          selectedItems={selectedItems}
          onSubmit={handleSubmit}
          warehouse={warehouse}
          floor={floor}
          category={category}
          jobType={jobType}
          addedItems={addedItems}
        />
      }
    </>
  );
};

export default LocationWiseCategory;
