import React, { useEffect, useState } from "react";
import AlertDialog from "../../../Components/DialogBox/dialogbox";
import { Grid, Typography, Button } from "@mui/material";
import theme,{buttonClasses, textClasses} from "../../../../static/styles/theme";
import { useDispatch } from 'react-redux';
import GenericLoader from "../../../Components/Generic/GenericLoader";
import { saveCustomDashboardWidgets } from "../../../../redux/actions/CustomDashboard";
import { showToast } from "../../../../services/functions";
import InputField from "../../../Components/TemplateInputConfiguration/InputComponent/InputField";
import TextAreaField from "../../../Components/TemplateInputConfiguration/InputComponent/TextAreaField";
import RadioField from "../../../Components/TemplateInputConfiguration/InputComponent/RadioField";
import { initialFieldValues, dashboardNameComponent,dashboardDescriptionComponent, dashboardVisibilityComponent } from "../constant";
import history from "../../../../services/history";

const PublishCustomDashboardModal = ({ viewModal, setViewModal }) => {
    const dispatch = useDispatch();
    const [dataReceieved, isDataReceived] = useState(false)
    const [fieldValues, setFieldValues] = useState(initialFieldValues);
    const handleChangeFieldValues = (component, event) => {
        let value = event?.target?.value || "";
        const fieldId = component?._id;
        setFieldValues((prev) => ({
            ...prev,
            [fieldId]: value, // Dynamically update the value based on the `_id`
        }));
    };
    const handleChangeRadio = (component, selectedValue) => {
        setFieldValues((prev) => ({
            ...prev,
            [component._id]: selectedValue
        }))
    };
    const handlePublishCustomDashboard = () => {
        let payload = {
            name: fieldValues?.dashboardName || "",
            description: fieldValues?.dashboardDescription || "",
            scope: fieldValues?.dashboardVisibilityScope || null,
            attributes: viewModal?.action?.layout || [],
        }
        
        isDataReceived(true)
        dispatch(
            saveCustomDashboardWidgets(payload, (response) => {
                showToast(response.message, !response.success);
                isDataReceived(false)
                if (response?.success) {
                    history.push("/dashboard/view-custom-dashboard");
                }
            })
        )
    }
    useEffect(()=>{
        setFieldValues({...fieldValues,dashboardVisibilityScope:'private'})
    },[])
    return (
        <>
            <AlertDialog
                viewDialog={viewModal.type === "publishCustomDashboardModal"}
                handleClose={() => setViewModal({ ...viewModal, type: '', action:{} })}
                viewSearchInput={true}
                showDivider={true}
                width="35%"
                actionsCSS={{ justifyContent: 'flex-start' }}
                body={
                    <>
                        {false ?
                            <Grid item container data-testid="loader" direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 5, height: "80vh" }}>
                                <Grid item><GenericLoader /></Grid>
                            </Grid>
                            : <>
                                <Grid container sx={{ p: 2 }} alignItems={"center"} justifyContent={"center"}>
                                    <Grid item sm={12} xs={12}>
                                          <Typography sx={{ ...textClasses.cardTitle }}>Publish Custom Dashboard</Typography>
                                                        <Typography sx={{ ...textClasses.normalText, color: "#455A64", mt: 1, mb: 1 }}>
                                                            To save template fill the below details
                                                        </Typography>
                                        {/* <Typography align='center' sx={{ fontWeight: "600", fontSize: "20px", lineHeight: "27px", }}>Please fill the dashboard details...</Typography> */}
                                    </Grid>
                                    <Grid item sm={12} xs={12} sx={{mt:1}}>
                                    <InputField
                                            component={dashboardNameComponent}
                                            componentValues={fieldValues} // Pass the shared state
                                            disabled={false} // Field remains enabled
                                            handleChange={handleChangeFieldValues}
                                            errors={{}} // Pass errors if required
                                            // extraCSS={{
                                            //     backgroundColor: theme.themeWhite, ".MuiInputBase-input": {
                                            //         fontSize: "15px",
                                            //         color: "#455A64",
                                            //     },
                                            //     "& label.Mui-focused": {
                                            //         color: "#000",
                                            //     }
                                            // }}
                                        />
                                    
                                    </Grid>
                                    <Grid item sm={12} sx={{mt:1}}>
                                    <TextAreaField
                                            component={dashboardDescriptionComponent}
                                            componentValues={fieldValues} // Pass the shared state
                                            disabled={false} // Field remains enabled
                                            handleChange={handleChangeFieldValues}
                                            errors={{}} // Pass errors if required
                                            // extraCSS={{
                                            //     backgroundColor: theme.themeWhite, ".MuiInputBase-input": {
                                            //         fontSize: "15px",
                                            //         color: "#455A64",
                                            //     },
                                            //     "& label.Mui-focused": {
                                            //         color: "#000",
                                            //     }
                                            // }}
                                        />
                                    </Grid>
                                    <Grid item sm={12} sx={{mt:1}}>
                                    <RadioField
                                            component={dashboardVisibilityComponent}
                                            componentValues={fieldValues} // Pass the shared state
                                            disabled={false} // Field remains enabled
                                            handleChange={handleChangeRadio}
                                            errors={{}} // Pass errors if required
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </>
                }
            >
                <Button
                    variant="outlined"
                    data-testid="cancel-btn"
                    onClick={() => { setViewModal({ ...viewModal, type: '', action:{} }) }}
                    sx={{ ...buttonClasses.lynkitBlackFill, fontSize: ".7rem", minWidth: "130px" }}
                >
                    Cancel
                </Button>
                <Button
                    variant="outlined"
                    data-testid="cancel-btn"
                    onClick={handlePublishCustomDashboard}
                    sx={{ ...buttonClasses.lynkitOrangeEmpty,fontSize: ".7rem", minWidth: "130px" }}
                    disabled={dataReceieved || !fieldValues?.dashboardName || !fieldValues?.dashboardVisibilityScope ||!viewModal?.action?.layout}
                >
                    Publish
                </Button>
            </AlertDialog>
        </>
    )
}
export default PublishCustomDashboardModal;
