import React, { useEffect } from 'react'
import AnalyticDynamicChartWidget from './AnalyticDynamicChartWidget'
import { capitalizeSentence } from '../../../../../services/functions'
const AnalyticsTypeWidget = ({ item, setLayout,styleView={} ,isDefaultDashboard = false,  dataLoader=false,children }) => {
  
    
  return (
        <AnalyticDynamicChartWidget
        type={capitalizeSentence(item?.chartType)}
        title={item?.title || ""}
        xAxisLabel={item?.xAxisLabel || ""}
        yAxisLabel={item?.yAxisLabel || ""}
        data={item?.values || []}
        seriesConfig={item?.seriesConfig || []}
        item={item}
        setLayout={setLayout}
        styleView={styleView}
        isDefaultDashboard={isDefaultDashboard}
        dataLoader={dataLoader}
      >
{children}
      </AnalyticDynamicChartWidget>
    )
}

export default AnalyticsTypeWidget;

