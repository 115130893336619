import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { multiDateToISOConvertor } from "../../../Components/Generic/GenericMultiDateRange";
import {   getWarehouseInventoryCustomDashboard} from "../../../../redux/actions/CustomDashboard";
import DateRangeFilter from "../WidgetComponents/DateRangeFilter";
import AnalyticsTypeWidget from "../WidgetComponents/Analytics";



const convertToDateRange = (value) => {
    const now = new Date(); // Current date
    let dateFrom = new Date(now); // Start date
    let dateTo = new Date(now); // End date

    switch (value) {
        case "1d":
            dateFrom.setDate(now.getDate() - 1);
            break;
        case "7d":
            dateFrom.setDate(now.getDate() - 7);
            break;
        case "1m":
            dateFrom.setMonth(now.getMonth() - 1);
            break;
        case "1y":
            dateFrom.setFullYear(now.getFullYear() - 1);
            break;
        default:
            throw new Error("Invalid value");
    }

    return {
        dateFrom: dateFrom.toISOString(),
        dateTo: dateTo.toISOString()
    };
};

const AreaUtilizationComponent = ({ item, styleView ,warehouseDetails }) => {
    const dispatch = useDispatch()
    const [selectedRange, setSelectedRange] = useState("7d");
    const [dateFilter, setDateFilter] = useState([]);
    const [updatedItem, setUpdatedItem] = useState(item || []);


    const handleSelection = (value) => {
        setSelectedRange(value);
        let dates
        if (value !== 'dateRange') {
            dates = convertToDateRange(value)
        }
        setDateFilter([])
        getDataDateRangeWise(dates?.dateFrom, dates?.dateTo)
    };

    const handleDateChange = (newDates) => {
        setDateFilter(newDates);
        let datarry;

        if (newDates.length >= 2) {
            datarry= multiDateToISOConvertor(newDates)
        }
        if (datarry?.length >= 2) {
            getDataDateRangeWise(datarry[0], datarry[1])
        }

    };
    const getDataDateRangeWise = (dateFrom = '', dateTo = '') => {
        let warehouseIds= warehouseDetails?.warehouseId?.map((item) => item?._id)
        let payload = {
            wareHouse: warehouseIds || [],
            dateFrom: dateFrom,
            dateTo: dateTo
        }
        if (!item) return;
        dispatch(getWarehouseInventoryCustomDashboard(payload, (data) => {
            const transformedArray = data?.data?.map(obj => ({
                category: obj?.date,
                in: obj?.totalInward,
                out: obj?.totalOutward,
                current: obj?.instock
            }));
            setUpdatedItem((prev) => ({
                ...prev,
                title: "Warehouse Area Utilization",
                values: transformedArray
            }))
        }))
    }
    useEffect(() => {
        getDataDateRangeWise()
    }, [warehouseDetails, dispatch])
    return (<>
        < >

            <AnalyticsTypeWidget item={updatedItem} isDefaultDashboard={true} styleView={styleView}>
                <DateRangeFilter dateFilterOption={item?.dateFilterOption}
                    selectedRange={selectedRange}
                    dateFilter={dateFilter}
                    handleSelection={handleSelection}
                    handleDateChange={handleDateChange} />
            </AnalyticsTypeWidget>
        </>

    </>)
}

export default AreaUtilizationComponent;