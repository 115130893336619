const intialState = {
    RFIDTableData: {},
    checkpointDashboardData:[]
};


const RFIDReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case "GET_RFID_DATA":
            return { ...state, RFIDTableData: payload }
        case  "GET_CHECKPOINT_DASHBOARD_DATA":
            // return { ...state, checkpointDashboardData: payload }
        default:
            return state;
    }
}
export default RFIDReducer;