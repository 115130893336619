import React from 'react'
import { Grid, Typography, Button } from '@mui/material'
import BarcodeImage from "../../../../../static/images/data_entry/barcode.svg"
import theme, { textClasses,buttonClasses  } from '../../../../../static/styles/theme'
import history from '../../../../../services/history'
import { useState } from 'react'
import { downloadInventoryDataExcel } from '../../../../../redux/actions'
import GenerateLabel from "../../../Inventory/Inventory/Label"
import { useDispatch, useSelector } from 'react-redux'

export default function ShipmentAdded({spiType, templateId,shipmentId, packageCount, itemCount, handleAddMore}) {
    const dispatch = useDispatch();
    const handleGateIn = () => {
        history.push("/dashboard/inventory/all")
    }
    const [dataReceieved, isDataReceived] = useState(false)
    const [view,setView] = useState(false)
    const { userPermissions } = useSelector((state) => state.userPermission);
    let permissions = userPermissions || {};

    const successMessage = (() => {
        let message = "";
        if(spiType){
            if(spiType == "shipmentOnly"){
                return message
            }
            else if(spiType == "shipmentPackageOnly"){
                message = message.concat(`with ${packageCount} packages`)
            }
            else if(spiType == "SPI"){
                message = message.concat(`with ${packageCount} packages and ${itemCount} items`)
            }
            else if(spiType == "shipmentItemOnly"){
                message = message.concat(`with ${itemCount} items`)
            }
        }
        return message;
    })();

        const handleDownload = () =>{
            isDataReceived(true)
            dispatch(
                downloadInventoryDataExcel({shipmentId:shipmentId,excelDataType:"inventoryPIExcel"}, (response) => {
                    isDataReceived(false)
                    // setIsDataSubmit(false)
                    // if (response) {
                    //     history.push("/dashboard/inventory/asn");
                    // }
                })
            )
        }
    
    
    return (
        <Grid container direction="column" justifyContent={"center"} alignItems="center">
            <img src={BarcodeImage} height="250"/>
            <Typography sx={{...textClasses.boldText, fontSize: "20px"}}>New Shipment Added To Your Inventory</Typography>
            <Typography sx={{...textClasses.boldText, fontSize: "12px", textAlign: "center", mt: 1, mb: 2}}>
                <span style={{color: theme.themeOrange}}>Shipment ID {shipmentId}</span> {successMessage} has been added with Gate In Pending status. create and assign Gate-In Job to your internal workers. 
            </Typography>
        <Grid container spacing={2} justifyContent="center" alignItems="center" >

                <Grid container item sm={6} xs={6} justifyContent="center" alignItems="center">
                {permissions && (permissions.permissions || []).some(item => item.permissionId === "viewInventory") ?
                    <Button variant='outlined' fullWidth size='small' sx={{...buttonClasses.lynkitOrangeEmpty}} onClick={handleGateIn}>
                        View Inventory
                    </Button>
         
                               
                    : ''}
 
                </Grid>
                <Grid container item sm={6} xs={6} justifyContent="center" alignItems="center">
                {permissions && (permissions.permissions || []).some(item =>(item.permissionId === "addNewInventory" || item?.permissionId=="asnReceiving")) ?
                     <Button variant='contained' fullWidth size='small' sx={{...buttonClasses.lynkitOrangeFill}} onClick={handleAddMore}>
                        Add More Shipment
                    </Button>
         
                               
                    : ''}
                   
                </Grid>
                {permissions && (permissions.permissions || []).some(item => item.permissionId === "generateInventoryLabel") ?
                    <>
                        {templateId && shipmentId && <Grid item sm={12} xs={12} justifyContent="center" alignItems="center">
                            <Button variant='contained' fullWidth size='small' sx={{ ...buttonClasses.lynkitBlackFill }} onClick={() => setView(true)}>
                                Generate Label
                            </Button>
                        </Grid>}
                    </>


                    : ''}
               

                {permissions && (permissions.permissions || []).some(item => (item.permissionId === "downloadPackageItemExcel")) ?
                    <Grid item sm={12} xs={12} justifyContent="center" alignItems="center">
                        <Button variant='contained' fullWidth size='small' sx={{ ...buttonClasses.lynkitBlackFill }}
                        onClick={handleDownload} disabled={dataReceieved}
                        >
                            Download Excel
                        </Button>
                    </Grid>
                    : ''}

            </Grid>

            { view && <GenerateLabel
                data={{templateId,shipmentId}}
                showModal={view}
                handleCloseModal={()=>setView(false)}
                isItemExists={itemCount > 0}
                isPackageExists={packageCount > 0}
            />}
        </Grid>
    )
}
