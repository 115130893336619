import React, { useEffect } from "react";
import { TextField, Typography, FormControlLabel, Grid, Radio, RadioGroup, FormLabel, FormControl } from "@mui/material";
import theme, { inputClasses, textClasses } from "../../../../static/styles/theme";


const RadioField = ({ component, componentValues, disabled, handleChange, errors, isLabel=true,label=false }) => {
    
    
    return (
        <>
            <Grid sx={{ ...inputClasses.shadowField, border: "1px ", width: "100%", padding: "10px" }}>
                <FormControl>
                    {isLabel &&

                    <FormLabel required={component?.required} sx={{ ...textClasses.boldText, color: theme.themeOrange }} id="demo-row-radio-buttons-group-label">{component?.label} :</FormLabel>
                    }
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        sx={{ mt: 1 }}
                        key={component?._id}
                        onChange={(event) => {
                            const selectedValue = event.target.value;
                            label ? handleChange(component, selectedValue, label) : handleChange(component, selectedValue)
                        }}
                        value={componentValues[component?._id]}
                    >
                        {
                            component.values && component.values.map((op, i) => {
                                return <FormControlLabel
                                    sx={{}}
                                    disabled={disabled || false}
                                    control={<Radio sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }}
                                    value={op.value} key={op.label} size="small" />}
                                    label={op.label} key={i} />
                            })
                        }

                    </RadioGroup>
                </FormControl>
                {errors && errors[component._id] &&
                    <Grid >
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </Grid>
        </>
    )
};

export default RadioField