import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import theme from '../../../static/styles/theme';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import StarBorder from '@mui/icons-material/StarBorder';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { Typography } from '@mui/material';
import history from '../../../services/history';

export default function DrawerItemList({ items, open, expandList, handleExpandList }) {
    const currentUrl = window?.location?.pathname
    const urlList = {
        "entity-master": [
            "/dashboard/entity-master/item-master", "/dashboard/entity-master/customer-master",
            "/dashboard/entity-master/supplier-master", "/dashboard/entity-master/kit-master",
            "/dashboard/entity-master/pallet-master",
        ],
        "inventory": ["/dashboard/inventory/all"],
        "area": ["/dashboard/area/grid", "/dashboard/area/3d", "/dashboard/area/mapping"],
        "user_management": ["/dashboard/user_management/my-team", "/dashboard/user_management/user-role"]
    }

    const activeSubMenu = (itemLength, url) => {
        if (!open) {
            if (itemLength == 0) {
                return currentUrl === url
            }
            else {
                let urlParam = currentUrl.split('/')[2]
                let subRoute = urlList[urlParam]
                return (subRoute || []).includes(url)
            }
        }
        else {
           if(!itemLength){
            return currentUrl === url
           }
           else{
            return null
           }
        }
    }

    // useEffect(()=>{
    //     if()
    // })





    const listItemButton = (item) => {
        return (
            <ListItemButton
                dense
                sx={{
                    minHeight: 30,
                    justifyContent: open ? 'initial' : 'center',
                }}
            >
                <ListItemIcon
                    sx={{
                        height: "17px",
                        minWidth: 0,
                        mr: open ? 2 : 'auto',
                        justifyContent: 'center',
                    }}
                >
                    {currentUrl == item.url ? item.activeIcon : item.icon}


                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }} style={{ color: !item.subItems && currentUrl == item.url ? "white" : "black" }}>
                    <Typography variant='caption'>
                        {item.title}
                    </Typography>
                    { item?.subItems?.length>0 && item.title == expandList && <ExpandLess onClick={() => handleExpandList(0)} />}
                    { item?.subItems?.length>0 && item.title != expandList && <ExpandMore onClick={() => handleExpandList(1, item.title)} />}
                </ListItemText>
            </ListItemButton>
        )
    }

    const collapseList = (item) => {
        return (
            <Collapse in={item.title == expandList} timeout="auto" unmountOnExit>
                <List component="div" disablePadding dense>
                    {
                        item?.subItems?.length>0 &&
                        item.subItems.map((subItem, j) => {
                            return (
                                <Link to={subItem.url} style={{ textDecoration: "none" }}>
                                    <ListItemButton
                                        key={subItem.title}
                                        sx={{ pl: 1, backgroundColor: currentUrl == subItem.url ? theme.themeOrange : "white", color: currentUrl == subItem.url ? "white" : "black", }}
                                    >
                                        <ListItemIcon >
                                        </ListItemIcon>

                                        <ListItemText style={{ color: "black" }}>
                                            <Typography variant='caption'>
                                                {subItem.title}
                                            </Typography>
                                        </ListItemText>
                                    </ListItemButton>
                                </Link>
                            )
                        })
                    }
                </List>
            </Collapse>
        )
    }

    return (
        <>
            {
                items.map((item, i) => {
                    const itemStyle = {
                        display: "block",
                        backgroundColor: activeSubMenu(item?.subItems?.length || 0, item.url) ? theme.themeOrange : "white",
                        color: !item.subItems && currentUrl == item.url ? "white" : "black",
                    }

                    return (
                        <div key={item.title}>
                            {
                                item?.subItems?.length>0
                                    ?
                                    <ListItem dense key={item.title} disablePadding sx={itemStyle} onMouseEnter={() => handleExpandList(1, item.title)} onMouseLeave={() => handleExpandList(0)}>
                                        {listItemButton(item)}
                                        {collapseList(item)}
                                    </ListItem>
                                    :
                                    <ListItem dense key={item.title} disablePadding sx={itemStyle} component={Link} to={item.url}  >
                                        {listItemButton(item)}
                                    </ListItem>
                            }
                        </div>
                    )
                })
            }
        </>
    )
}
