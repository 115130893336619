import { Card, CardContent, CardHeader, Dialog, DialogContent, Grid, IconButton, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFile, getTemplateHeader } from "../../../../redux/actions";
import FileModal from "../../EntityMaster/FileModal";
import React from "react";
import theme, { textClasses, buttonClasses } from "../../../../static/styles/theme";
import { Close } from "@mui/icons-material";
import { dataReadFunction, truncateNumber } from "../../../../services/functions";

const DetailDialog = ({ data, onClose, readKey = "SKU_ID" }) => {
    const dispatch = useDispatch();
    const [templateHeader,setTemplateHeader] =useState([])
    // console.log(templateHeader,"temmmmmmmmmm")
    let titleElement = templateHeader?.find((dl) => dl?.name == readKey) || {}
    const DetailRow = ({ title, children }) => {
        return (
            <Grid container sx={{ mt: 1 }}>
                <Grid item sm={4} xs={4}>
                    <Typography sx={{ ...textClasses.t12n, color: "#827878" }}>{title}</Typography>
                </Grid>
                <Grid container item sm={8} xs={8} direction="row" alignItems={"center"}>
                    {children}
                </Grid>
            </Grid>
        )
    }

    useEffect(() => {
        setTemplateHeader([])
        if (data) {
            dispatch(
                getTemplateHeader({
                    template_id: data.template_id
                },(response)=>{
                    setTemplateHeader(response || [])
                })
            )
        }
    }, [data])
    const [displayUrl, setDisplayUrl] = useState("");
    const [openModal, setOpenModal] = React.useState(false);

    const displayFile = (fileUrl, fileKey) => {
        const imageUrlRegex = /\.(jpg|jpeg|png|gif|bmp|tiff)$/i;
        const isValidImageUrl = imageUrlRegex.test(fileKey);
        if (isValidImageUrl) {
            setOpenModal(true);
            setDisplayUrl(fileUrl);
        } else {
            window.open(fileUrl, "_blank");
        }
    };

    const handleFile = (fileKey) => {
        setOpenModal(false);
        setDisplayUrl('');
        dispatch(fetchFile({ fileKey }, displayFile));
    };

    return (
        <Dialog
            open={data != null}
            onClose={onClose}
            scroll="paper"
            PaperProps={{ sx: { width: "100%", borderRadius: "8px" } }}
            maxWidth="sm"

        >
            <DialogContent sx={{ p: 0 }}>
                {
                    data != null &&
                    <Card sx={{ m: 0, border: `1px solid ${theme.themeOrange}`, borderRadius: "8px" }}>
                        <CardHeader
                            title={<Typography >
                                {/* <Sell fontSize='inherit' sx={{mr: 1}}/> */}

                                <Typography sx={{ fontSize: "16px", color: "white", lineHeight: "22px" }}>
                                    {titleElement?.label || "SKU ID"} : {dataReadFunction(titleElement, data?.formData || {})}
                                </Typography>


                            </Typography>}
                            sx={{ backgroundColor: theme.themeOrange }}
                            action={<IconButton size='small' onClick={onClose}><Close fontSize='inherit' sx={{ color: "white" }} /></IconButton>}
                        />
                            <CardContent>
                                <DetailRow title={"Current Stock"}>
                                    <Button
                                        sx={{
                                            p: 0.3,
                                            cursor: "text !important",
                                            ...buttonClasses.small,
                                            color: theme.themeGreen,
                                            backgroundColor: theme.themeLightGreen,
                                            border: `1px solid ${theme.themeGreen}`,
                                            "&:hover": { backgroundColor: theme.themeLightGreen },
                                        }}
                                    >
                                        {truncateNumber(data?.currentStock) || 0} {data?.formData?.UOM || ""}                                
                                    </Button>
                                </DetailRow>
                                {
                                    templateHeader?.length > 0 && templateHeader?.map((h, i) => {
                                        let value = dataReadFunction(h, data?.formData || {})
                                        return (
                                            <DetailRow title={h.label}>
                                                <Typography sx={{ ...textClasses.normalText, ml: 1 }}>
                                                    {value && value != "-" ?
                                                        h.type === "file" ? (
                                                            <div onClick={() => handleFile(dataReadFunction(h, data?.formData || {}))}>
                                                                <span
                                                                    style={{
                                                                        ...buttonClasses.viewFile,
                                                                        cursor: "pointer"
                                                                    }}
                                                                >
                                                                    View File
                                                                </span>
                                                            </div>
                                                        ) : Array.isArray(value) ? value.join(", ") : value
                                                        : "-"}
                                                </Typography>
                                            </DetailRow>
                                        )
                                    })
                                }
                            </CardContent>
                    </Card>
                }
            </DialogContent>
            <FileModal
                openModal={openModal}
                displayUrl={displayUrl}
                setOpenModal={setOpenModal} />

        </Dialog>
    )
}

export default DetailDialog;