import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import theme, { buttonClasses, textClasses } from "../../../../static/styles/theme";
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  Autocomplete,
  ListSubheader,
  ListItemText,
  RadioGroup,
  Radio,
  Link,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useForm, Controller } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import {
  getGridDetail,
  addMappedArea,
  checkDistinctMasterExist,
  getAreaCategoryOptions,
  saveDockToStorageMapping,
  getDockDetail
} from "../../../../redux/actions";
import "../Style/AreaMapping.css";
import { showToast,deepCopy_Object } from "../../../../services/functions";
import { ToastContainer, toast } from "react-toastify";
import { selectStylesOverride } from "../../../../utils/util";
import TypeAndSearch from "../../TypeAndSearch";
import GenericLoader from "../../Generic/GenericLoader";
import { component } from "../../GridView/Components/utils";
import CustomChip from "../../CustomChip";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  transition: "all 0.3s ease-out",
  minWidth: 600,
  // maxHeight: '80vh',
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 3,
};

const mappingTypeOptions = [
  { label: "Block", value: "block", },
  { label: "Rack", value: "rack", },
]

function DockAllocateModal(props) {
  const dispatch = useDispatch();
  const { selectedRackData, isEdit, onSuccess, tableLoader = "", setTableLoader,setSelectedEntries,setActionIndex } = props;
  const [open, setOpen] = useState(false);
  const handleClose = (e,) => {
    setOpen(!open);
    setSelectedRack([]);
    setSelectedEntries([]);
    setActionIndex(null);
    props.onClose(false);
  };
  useEffect(() => {
    setOpen(props.show);
  }, [props.show]);

  const [selectedRack, setSelectedRack] = useState([]);
  const { control, formState: { errors }, } = useForm();
  const [dataReceieved, isDataReceived] = useState(false)

  const [mappingTypeValues, setMappingTypeValues] = useState(
    selectedRack.reduce((acc, _, index) => {
      acc[index] = "block"; // Set "Block" as default for all items
      return acc;
    }, {})
  );
  const [mappedLocations, setMappedLocations] = useState(
    selectedRack.reduce((acc, _, index) => {
      acc[index] = []; // Initialize each item with an empty array
      return acc;
    }, {})
  );
  const stringToFunction = ( mappedLocationString) => {
    mappedLocationString?.forEach((item, index) => {
      let mappedData = [];
      let splitArray = item?.mappedLocation?.split(",");
      
      
      splitArray?.forEach((subItem) => {
        let obj = subItem.split("::");
        if (obj.length === 3) {
          mappedData.push({ label: obj[0], cellId: obj[1], value: obj[0] });
        }
      });
      setMappedLocations((prev) => ({
        ...prev,
        [item?.dockId]: mappedData,
      }));
      
    });
    
  };

  const { gridRackDetails } = useSelector((state) => state.warehouseData);
  const tableHeader = useSelector((state) => state.customHeader["dockMappingTable"]);
  
  
  const [areaCategories, setAreaCategories] = useState([])
  const distinctMasters = useSelector(
    (state) => state.areaMappingData.distinctMasters
  );
  // console.log("mappedLo", mappedLocations)
  // console.log("radio", mappingTypeValues)
  // console.log("selectedR", selectedRack)

  const handleMappingTypeChange = (index, event) => {
    setMappingTypeValues((prev) => ({

      ...prev,
      [index]: event.target.value, // Update mapping type for the specific item
    }));
    // Reset mappedLocations for that index only
    setMappedLocations((prev) => ({
      ...prev,
      [index]: [], // Clear mapped locations only for this item
    }));
  };

  const handleTypeAndSearchChange = (index, e, option) => {
    setMappedLocations((prev) => {
      const currentList = prev[index] ?? [];
      const isAlreadyAdded = currentList.some((item) => item.cellId === option.cellId);

      if (isAlreadyAdded) {
        showToast("This location is already added!", true);
        return prev;
      }

      return {
        ...prev,
        [index]: [option, ...currentList], // Only update the specific index
      };
    });
  };
  // console.log("selectedRack",selectedRack)
  const collectData = () => {
    let submitArray = [];
    for (let i = 0; i < selectedRack?.length; i++) {
      for (let j = 0; j < selectedRack[i]?.data?.length; j++) {
        let obj = {
          actionType: selectedRack[i].actionType,
          cellId: selectedRack[i].cellId,
          multiple: selectedRack[i].multiple || false,
          isRackExist: selectedRack[i].isRackExist,
          renderId: selectedRack[i].renderId,
          selectType: selectedRack[i].selectType,
          materialType: selectedRack[i].data[j].materialType,
          oldMaterialType: selectedRack[i]?.actionType == "update" && selectedRack[i]?.data[j]?.oldMaterialType || "",
          category: selectedRack[i].data[j].category || "",//?? "item-master"
          entityMasterId: selectedRack[i]?.data[j]?.entityMasterId || [],
          areaCategory: selectedRack[i]?.data[j]?.areaCategory || ""
        };

        if (selectedRack[i]?.data[j]?.category && ["itemMaster"].includes(selectedRack[i]?.data[j]?.category)
          && (!selectedRack[i]?.data[j]?.entityMasterId || selectedRack[i]?.data[j]?.entityMasterId?.length == 0)) {
          let tempMess = "SKU"
          if (selectedRack[i]?.data[j]?.category == "itemMaster") {
            tempMess = "SKU"
          }
          let message_d = `${tempMess} is empty for block ${selectedRack[i]?.name || ""
            }!`;
          if (obj.selectType === "rack" || obj.multiple) {
            message_d = `${tempMess} is empty for block ${selectedRack[i]?.name || ""
              } in ${j + 1} row!`;
          }
          return { success: false, message: message_d };
        }

        if (!obj.materialType || obj.materialType.length === 0) {
          let message_d = `Area name is empty for block ${selectedRack[i]?.name || ""
            }!`;
          if (obj.selectType === "rack" || obj.multiple) {
            message_d = `Area name is empty for block ${selectedRack[i]?.name || ""
              } in ${j + 1} row!`;
          }
          return { success: false, message: message_d };
        }

        if (selectedRack[i].isRackExist && obj.selectType === "rack") {
          let allRack = selectedRack[i]?.data[j]?.rackId || [];

          if (allRack.length === 0) {
            let message_d = `Select at least one rack for block ${selectedRack[i]?.name || ""
              } in ${j + 1} row!`;
            return { success: false, message: message_d };
          }
          obj.rackId = allRack;
        }

        submitArray.push(obj);
      }
    }

    return { success: true, data: submitArray };
  };

  const handleSaveClick = () => {
    isDataReceived(true)

    let payload = {
      type: "wms",
      floorId: selectedRackData?.floorId,
      warehouseId: selectedRackData?.warehouseId,
      locationMappingData:
        selectedRackData?.areaTypeData?.map((dl, index) => ({
          actionType: dl.actionType, // Assuming action type is always "addNew"
          cellId: dl.cellId, // Using the dock ID from areaTypeData
          locationId: mappedLocations[dl.cellId]?.map((loc) => loc.cellId) || [], // Get associated locations for each index
        })) || [],
    };
    dispatch(
      saveDockToStorageMapping(payload, (data) => {
        showToast(data.message, !data.success);
        isDataReceived(false)
        if (setTableLoader) setTableLoader("");
        if (data.success) {
          if (onSuccess) {
            onSuccess();
          }
          handleClose();
        }
      })
    );
  };
useEffect(() => {
    if (selectedRackData?.areaTypeData) {
      let fetchcellID = selectedRackData.areaTypeData.map(
        (item) => item.cellId
      );
      if (setTableLoader) setTableLoader("areaDetail")
      dispatch(getGridDetail({ floorId: selectedRackData.floorId, type: "rack", parentId: fetchcellID, }, () => {
        dispatch(checkDistinctMasterExist({ master_Type: ["item-master"] }, ({ data = {} }) => {
          let tempArea = [...deepCopy_Object(selectedRackData.areaTypeData)]
          if (data["item-master"]) {
            tempArea = tempArea.map(dl => {
              let temObj = { ...dl }
              if (dl?.actionType != "update") {
                if (temObj?.data?.length) {
                  temObj["data"] = dl?.data?.map(innerEl => {
                    return { ...innerEl, category: "itemMaster" }
                  })
                }
              }
              return temObj
            })
          }
          // console.log("tempArea",tempArea);
          setSelectedRack(tempArea);
          if (setTableLoader) setTableLoader("")
        }));
      })
      );
      dispatch(getAreaCategoryOptions({ dataType: "areaCategory" }, (data) => {
        if (data?.success) {
          setAreaCategories(data?.data)
        } else {
          setAreaCategories([])
        }
      }))
    }
    else {
      setSelectedRack([]);
    }
  }, [selectedRackData]);

  useEffect(() => {
    const dockId = selectedRack?.map(item => item.cellId)
    if (dockId?.length) {
      dispatch(getDockDetail({ dockId }, (data) => {
        let mappedID = data?.data?.map((item) => {
          return {
            dockId: item?.dockId,
            mappedLocation: item?.mappedLocation
          }
        })
        if (mappedID) {
          stringToFunction(mappedID)
        }

      }))
    }
  }, [selectedRack?.length])
  return (
    <>
      <Modal
        open={open}
        data-testid="DockAllocateModal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="overFlowAuto">
          <>
            <Box>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <span className="AM-header">Area Mapping : Dock-to-Storage Mapping</span>
                <span className="curser" onClick={() => handleClose()}>
                  <CloseIcon />
                </span>
              </Typography>
            </Box>

            {!!tableLoader ? <Grid item={true} data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
              <Grid item><GenericLoader /></Grid>
            </Grid>
              : <Box sx={{ overflow: "auto", maxHeight: "70vh" }}>
                {selectedRack.map((item, index) => (
                  <div key={index}>
                    <Box
                      border={1}
                      sx={{
                        marginTop: "10px",
                        borderRadius: "10px",
                        padding: "1rem",
                        minWidth: "500px",
                        borderColor: "grey.500",
                      }}
                    >
                      {/* First Row: Selected Dock on Left, Mapping Type + Radio Buttons on Right */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ ...textClasses.t15nBold }}>
                          Selected Dock: {item.name}
                        </Typography>

                        {/* Mapping Type + Radio Buttons in one line */}
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            data-testid="mapping-options"
                            sx={{
                              fontSize: "14px",
                              fontWeight: 800,
                              marginRight: "10px",
                            }}
                          >
                            Mapping Type:
                          </Typography>
                          <FormControl>
                            <RadioGroup
                              row
                              value={mappingTypeValues[index] || "block"}
                              onChange={(event) => handleMappingTypeChange(index, event)}
                            >
                              {mappingTypeOptions.map((option) => (
                                <FormControlLabel
                                  key={option.value}
                                  sx={{
                                    color: "#000",
                                    "& .MuiFormControlLabel-label": {
                                      fontSize: "12px",
                                    },
                                  }}
                                  value={option.value}
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        "&.Mui-checked": {
                                          color: "#FF7200",
                                        },
                                      }}
                                    />
                                  }
                                  label={option.label}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Box>

                      {/* Second Row: TypeAndSearch with fixed width */}
                      <Box sx={{ display: "flex", alignItems: "flex-start", marginTop: "10px", mb: 2 }}>
                        <TypeAndSearch
                          sx={{ maxWidth: "400px" }} // Restrict width
                          component={deepCopy_Object(
                            component(mappingTypeValues[index] || "block", selectedRackData?.floorId, "", "")
                          )}
                          setEmptyOnChange={true}
                          handleChange={(e, option) => handleTypeAndSearchChange(item?.cellId, e, option)}
                        />
                      </Box>

                      {/* Third Row: GenericAutoSizerComponent */}
                        


                      {mappedLocations[item?.cellId]?.length > 0 && (
                        
                        <Box sx={{ border:`1px solid ${theme.themeGray}`, borderRadius:"10px", padding: "5px" ,minHeight:"10vh",maxHeight:"40vh",overflowX:'auto'  }}> 
                          <CustomChip  
                          data={mappedLocations[item?.cellId].map(elem=>elem.label)} 
                          handleDelete={(deleteIndex) => {
                            setMappedLocations((prev) => ({
                              ...prev,
                              [item?.cellId]: prev[item?.cellId].filter((_, i) => i !== deleteIndex),
                            }))}
                          }
                            />
                         </Box>
                        
                       
                        // {/* <GenericAutoSizerComponent
                        //   mappedData={mappedLocations[item?.cellId]?.map((dl) => dl.value) || []}
                        //   containerHeight="100px"
                        //   onDelete={(deleteIndex) => {
                        //     setMappedLocations((prev) => ({
                        //       ...prev,
                        //       [item?.cellId]: prev[item?.cellId].filter((_, i) => i !== deleteIndex),
                        //     }));
                        //   }}
                        //   xsCol={4}
                        //   smCol={6}
                        //   mdCol={8}
                        //   lgCol={8}
                        //   xlCol={12}
                        // /> */}

                      )}
                    </Box>
                  </div>
                ))}

              </Box>


            }
          </>
        
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                // className="AllocateCancel-btn"
                sx={{
                  ...buttonClasses.lynkitOrangeEmpty,
                  textTransform: "none",
                  width: "50%",
                  marginLeft: "5px",
                }}
                onClick={() => handleClose()}
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                sx={{
                  ...buttonClasses.lynkitOrangeFill,
                  textTransform: "none",
                  width: "50%",
                  marginLeft: "5px",
                }}
                variant="contained"
                disabled={
                  !!tableLoader || dataReceieved ||
                  selectedRack.length === 0 ||  // Ensure selectedRack is not empty
                  selectedRack.some((item, index) => (mappedLocations[item?.cellId] || []).length === 0)}
                onClick={handleSaveClick}
              >
                {isEdit ? "Update" : "Map Docks With Storage"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <ToastContainer
        position="bottom-center"
        autoClose={false}
        closeOnClick={false}
      />
    </>
  );
}

export default DockAllocateModal;
