import React, { useEffect } from 'react'
import NotificationWidget from './NotificationWidget'
import WelcomeNote from './WelcomeNote'
const AlertTypeWidget = ({ item, setLayout ,styleView={}}) => {
    return (
        <>
            {
                item?.elementID == "welcomeNote" &&
                <WelcomeNote item={item} setLayout={setLayout} styleView={styleView} />
            }
            {
                item?.elementID == "scheduledEvents" &&
                <NotificationWidget item={item} setLayout={setLayout} styleView={styleView} />
            }
        </>
    )
}

export default AlertTypeWidget;