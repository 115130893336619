import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    GET_IDENTIFIER_SETTING,
    FETCH_LABEL_CONFIGRATION,
    GENERATE_UNIQUE_SERIAL_NUMBER
}
from "../types"
import axios from "../../services/Api"
import { showToast } from "../../services/functions";
export const getAllIdentifierSetting = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/base/getSettings', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type:GET_IDENTIFIER_SETTING, payload: {rows: [], count: 0}})
                if(cb){
                    cb();
                }
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: GET_IDENTIFIER_SETTING, payload: {rows: data.data, count: data.count}})
                if(cb){
                    cb();
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:",error.message);
            if(cb){
                cb();
            }
        });
    }
};


export const addIdentifierSetting = (payload, callback) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/base/addSettings', payload).then(({data}) => {
            if (data.success) {
                process(data)
            } 
            else {
                //(data.message);
                dispatch({type: FETCH_ERROR, payload: data.message});
                showToast(data.message, true)
                callback(null);
            }
            function process(data) {
                dispatch({ type: FETCH_SUCCESS });
                callback(data);
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            callback(null)
        });
    }
};

export const editIdentifierSetting = (body, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/base/updateSettings', {
            ...body
        }
        ).then(({data}) => {
        if (data.success) 
        {
            callback();
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
            showToast(data.message, true);
        }

        function callback() 
        {
            dispatch({ type: FETCH_SUCCESS });
            showToast("Settings updated successfully", false)
            cb()
            
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            showToast(error.message, true);
            //("error", error.message)
        });
    }
};


export const getLabelConfigration = (body, callback) =>{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/base/getLabelConfigration', body).then(({data}) => {
        if (data.success) {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: FETCH_LABEL_CONFIGRATION,payload:data });
        } 
        else {
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({ type: FETCH_LABEL_CONFIGRATION,payload:{} });
        }

        if(callback){
            callback(data)
        }
       
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch({ type: FETCH_LABEL_CONFIGRATION,payload:{} });
            if(callback){
                callback({data:[],success:false,message:error.message})
            }
            //("error", error.message)
        });
    }
};
export const uploadLabelLogo = (body, callback) =>{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/base/uploadLabelLogo', body).then(({data}) => {
        if (data.success) {
            dispatch({ type: FETCH_SUCCESS });
        } 
        else {
            dispatch({type: FETCH_ERROR, payload: data.message});
        }

        if(callback){
            callback(data)
        }
       
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            if(callback){
                callback({data:"",success:false,message:error.message})
            }
            //("error", error.message)
        });
    }
};


export const generateUniqueSerialNumber = (body,callBack) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.post("base/generateUniqueSerialNumber", body)
			.then(({ data: { success, data="", message } }) => {
				if (success) {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({type: GENERATE_UNIQUE_SERIAL_NUMBER,payload:data || ""});
				} 
				else {
					dispatch({type: FETCH_ERROR,payload: message});
					dispatch({type: GENERATE_UNIQUE_SERIAL_NUMBER,payload:""});
				}
				
				if(callBack){
					callBack({success:success || false,message,data})
				}
			})
			.catch(function (error) {
				if(callBack){
					callBack({success:false,message:error.message,data:""})
				}
				dispatch({type: GENERATE_UNIQUE_SERIAL_NUMBER,payload:""});
				dispatch({ type: FETCH_ERROR, payload: error.message });
				//("Error****:", error.message);
			});
	};
};





