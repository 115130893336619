import React, {
    useEffect,
    useState,
  } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import GenericSingleRangeComponent from "../../../Components/Generic/GenericSingleRangeComponent";
  import {
    Grid, Tooltip,
    Typography,
    Box,
    Checkbox,
    FormControlLabel,
    IconButton,
    Paper,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Table,
  } from "@mui/material";
  import { Delete } from "@mui/icons-material";
  import theme, {
    textClasses,
    buttonClasses,
  } from "../../../../static/styles/theme";
  import {
    // getItemDetail,
    // getPackageDetail,
    // getPackageItemDetail,
    getAllInventory,
  } from "../../../../redux/actions/Inventory";
  import {
    showToast,
    removeLeadingChars,
    dateTimeFormater,
    truncateNumber
  } from "../../../../services/functions";
  import {
    getLabelKeyForJobConfig,
  } from "../Constant";
  import PackageObjectsSlider from "./PackageObjectsSlider";
  import GenericCollapsableItemList from "../../../Components/Generic/GenericCollapsableItemList";
  import CreatePackageDialog from "./CreatePackageDialog";
  import { getShortText } from "../../../../services/functions";
  import GenericDropdown from "../../../Components/Generic/GenericDropdown";
  

  export default function InventoryComponent({
    addedItems,
    setAddedItems,
    allProductType = [],
    status = ["pending", "partialGateIn"],
    jobProcessConfig,
    isTypeGateIn = false,
    isTypeStockTransfer = false,
    isTypeQCInhouse = false,
    isTypeQCInward = false,
    children,
    disableElement: { listDropDown = false, categoryDropdown = false },
    countFor_SPI: {
      countFor_PKG_Item = {},
      countFor_Shipment_Item = {},
      countFor_Shipment_PKG = {},
    },
    category,
    packagingList,
    setPackagingList,
    isTypePickingAndPackaging = false,
    inventoryData: { shipments = [], packages = [], items },
    setInventoryData,
    warehouseId = "",
    dataFilter = {},
    filterValue = [],
    setDisableElement,
    filterEnable
  
  }){
    const dispatch = useDispatch();
    const isDataLoader=useSelector((state)=>state.common)?.loading==true
    // const shipments = useSelector((state) => state?.job?.shipments  || []);
    // const packages = useSelector((state) => state?.inventory?.packageDetail || []);
    // const items = useSelector((state) => state?.inventory?.itemDetail || []);
  
    //countFor_PKG_Item, countFor_Shipment_Item, countFor_Shipment_PKG
    // ////("jhhhhhhhhhhhh>>>>countFor_SPI",{ countFor_PKG_Item,countFor_Shipment_Item,countFor_Shipment_PKG});
    const initial = {
      isPackageExist: false,
      isPackageExist: false,
      isAdd: false,
      isCountOnly: false,
      checked: [],
      saveAs: "single",
    };
  
    const valid_mappingArea = allProductType?.length > 0 && allProductType || "";
    // console.log("valid_mappingArea", valid_mappingArea)
    const [selectedData, setSelectedData] = useState(initial);
  
    // For Picking & Packing
    const [objectsInPackage, setObjectsInPackage] = useState([]);
    const [openCreatePackage, setOpenCreatePackage] = useState(false);
    const [packageDialogType, setPackageDialogType] = useState(null);
    const [slideIndex, setSlideIndex] = useState(0);
    const [packageEditIndex, setPackageEditIndex] = useState(null);
  
    const generate_balance_Count = (_data = {}) => {
      let balance_Count = _data
        ? typeof _data == "object"
          ? Number(_data?.physical || 0)
          : Number(_data || 0)
        : 0;
  
      return Number(balance_Count || 0);
    };
  
    let selectedCount = (key, value) => {
      return truncateNumber((addedItems || []).reduce((prev, current) => {
        let tempCount = prev;
        if (current[key] == value && current?.count)
          tempCount = tempCount + Number(current?.count);
        return tempCount;
      }, 0));
    };
    const shipmentOptions = () => {
    // const shipmentOptions = useMemo(() => {
      let arr = [];
  
      for (let _ of shipments) {
        let selected_count = selectedCount("shipmentId", _?.shipmentId);
        const allGateInCount=truncateNumber(Number(_?.gateInCount || 0)+Number(_?.gateInHoldingQty || 0))
        const allGateOutCount=truncateNumber(generate_balance_Count(_?.balanceCount)- Number(_?.gateOutHoldingQty || 0) || 0)
        if (isTypeGateIn || isTypeQCInward) {
          if (Number(_?.totalCount || 0) - allGateInCount <= selected_count) continue;
        }
        else {
          if (allGateOutCount <= selected_count) continue;
        }
        arr.push({
          label: getLabelKeyForJobConfig(jobProcessConfig, "shipment", "key", _),
          // label: _.shipmentId + " (" + (statusConfig[_.status] || "") + ")",
          value: _.shipmentId,
          isPackageExist: _.isPackageExist || false,
          isItemExist: _.isItemExist || false,
          mappingArea: (_?.mappingArea?.length && _.mappingArea[0]) || "",
          all_S_Count: Number(_?.totalCount || 1),
          gateIn_S_Count:allGateInCount,
          balance_S_Count: allGateOutCount,
          gateInTime: _?.gateInTime,
        });
      }
  
      // console.log("arr",arr,shipments)
      return arr;
    }
    // },[shipments,addedItems,selectedData]);
  
    const packageOptions = () => {
    // const packageOptions = useMemo(() => {
      let opt = [];
      for (let _ of packages) {
        let selected_count = selectedCount("packageId", _?.packageId);
        const allGateInCount=truncateNumber(Number(_?.gateInCount || 0)+Number(_?.gateInHoldingQty || 0))
        const allGateOutCount=truncateNumber(Number(generate_balance_Count(_?.balanceCount)- Number(_?.gateOutHoldingQty || 0) || 0))
        if (isTypeGateIn || isTypeQCInward) {
          if (
            Number(_?.count || 0) - allGateInCount <=
            selected_count
          )
            continue;
        } else {
          if (allGateOutCount <= selected_count) continue;
        }
        let condition_val = false;
        if (["packageName"].includes(category)) condition_val = true;
        else {
          condition_val = _.shipmentId
            ? _.shipmentId == selectedData.shipmentId
            : false;
        }
  
        if (condition_val) {
          opt.push({
            ...(_?.dynamicData || {}),
            label: getLabelKeyForJobConfig(jobProcessConfig, "package", "key", _),
            // label:
            //   _[getLabelKeyForJobConfig(jobProcessConfig, "package", "key")] +
            //   " (" +
            //   (statusConfig[_.status] || "") +
            //   ")",
            value: _.packageId,
            shipmentId: _.shipmentId,
            isItemExist: _.isItemExist || false,
            mappingArea: (_?.mappingArea?.length && _.mappingArea[0]) || "",
            all_P_Count: Number(_?.count || 1),
            gateIn_P_Count:allGateInCount,
            balance_P_Count: allGateOutCount,
            gateInTime: _?.gateInTime,
          });
        }
      }
      return opt;
    };
    // },[shipments,addedItems,packages,selectedData]);
  
    const itemOptions =() => {
    // const itemOptions = useMemo(() => {
      let opt = [];
  
      for (let _ of items) {
        let selected_count = selectedCount("itemId", _?.itemId);
        const allGateInCount=truncateNumber(Number(_?.gateInCount || 0)+Number(_?.gateInHoldingQty || 0))
        const allGateOutCount=truncateNumber(Number(generate_balance_Count(_?.balanceCount)- Number(_?.gateOutHoldingQty || 0) || 0))
        if (isTypeGateIn || isTypeQCInward) {
          if (
            Number(_?.count || 0) -allGateInCount <=
            selected_count
          )
            continue;
        } else {
          if (allGateOutCount <= selected_count) continue;
        }
  
        let condition_val = false;
        if ((selectedData?.checked || []).includes("package")) {
          condition_val =
            (_.packageId &&
              packageOptions()
                .map((dl) => dl.value)
                .includes(_.packageId)) ||
            false;
        } else if (selectedData.isPackageExist) {
          condition_val =
            (_.packageId && _.packageId == selectedData.packageId) || false;
        } else {
          condition_val =
            (_.shipmentId && _.shipmentId == selectedData.shipmentId) || false;
        }
  
        if (condition_val) {
          opt.push({
            ...(_?.dynamicData || {}),
            label: getLabelKeyForJobConfig(jobProcessConfig, "item", "key", _),
            // label:
            //   _[getLabelKeyForJobConfig(jobProcessConfig, "item", "key")] +
            //   " (" +
            //   (statusConfig[_.status] || "") +
            //   ")",
            value: _.itemId,
            shipmentId: _.shipmentId,
            packageId: _.packageId || "",
            mappingArea: Array.isArray(_.mappingArea || "")
              ? (_?.mappingArea?.length && _.mappingArea[0]) || ""
              : _.mappingArea || "",
            all_I_Count: Number(_?.count || 1),
            gateIn_I_Count: allGateInCount,
            balance_I_Count: allGateOutCount,
            gateInTime: _?.gateInTime,
          });
        }
      }
  
      // ////("ItemDiabefffffffff",{selectedData:selectedData.shipmentId,countFor_PKG_Item,countFor_Shipment_Item});
      return opt;
    };
    // },[shipments,addedItems,packages,items,selectedData]);
  
    const fetchPackageItem = ({
      shipmentId = "",
      packageId = "",
      dataFetchType = "",
    }) => {
      let req_payload = {
        status,
        projection_for: "job",
        // warehouseId,
      };
  
      if (shipmentId) req_payload["shipmentId"] = shipmentId;
  
      if (allProductType?.length > 0) {
  
        req_payload["mappingArea"] = allProductType;
      }
      if (isTypeGateIn || isTypeQCInward) {
        req_payload["fetch_for"] = "beforeEntry";
      }
      else {
        req_payload["warehouseId"] = warehouseId;
        req_payload["fetch_for"] = "afterEntry";
        if (filterEnable() && dataFilter?.value) {
          req_payload = { ...req_payload, filterType: dataFilter?.value };
  
          if (dataFilter?.value == "date" || dataFilter?.value == "dateRange") {
            let isTrue = false;
            if (dataFilter?.value == "date" && filterValue?.length > 0) {
              isTrue = true;
            }
            if (dataFilter?.value == "dateRange" && filterValue?.length == 2) {
              isTrue = true;
            }
            if (isTrue) {
              req_payload["filterValue"] = filterValue;
            } else {
              delete req_payload["filterType"];
            }
          }
        }
      }
  
      if (dataFetchType == "packageWise") {
        req_payload["dataFetchType"] = "packageWise";
        // console.log(req_payload, "valid_mappingAreaiiiii", allProductType)
        dispatch(
          getAllInventory(req_payload, ({ success = false, data = [] }) => {
            setInventoryData({ shipments, packages: data, items: [] });
            if (isTypeStockTransfer && setDisableElement) {
              setDisableElement({ categoryDropdown, listDropDown: false });
            }
          })
        );
      }
      else if (dataFetchType == "itemWise") {
        if (packageId) req_payload["packageId"] = packageId;
        req_payload["dataFetchType"] = "itemWise";
  
        dispatch(
          getAllInventory(req_payload, ({ success = false, data = [] }) => {
            setInventoryData({
              shipments,
              packages: packageId ? packages : [],
              items: data,
            });
          })
        );
      }
      else {
  
      }
    };
  
    const getValidCount_For_SPI = (option, type = "shipment") => {
      if (!option.value) return 0;
      else {
        let valid_count = 0;
        if (isTypeGateIn || isTypeQCInward) {
          if (type == "item") {
            valid_count = option?.all_I_Count - option?.gateIn_I_Count || 0;
          } else if ("package") {
            valid_count = option?.all_P_Count - option?.gateIn_P_Count || 0;
          } else {
            valid_count = option?.all_S_Count - option?.gateIn_S_Count || 0;
          }
        } else {
          if (type == "item") {
            valid_count = option?.balance_I_Count || 0;
          } else if ("package") {
            valid_count = option?.balance_P_Count || 0;
          } else {
            valid_count = option?.balance_S_Count || 0;
          }
        }
  
        return truncateNumber(valid_count);
      }
    };
  
    const handleShipmentSelect = (e, option) => {
      let temp_data = { ...initial };
  
      // ////("option",option);
  
      if (option?.value) {
        const {
          label,
          value,
          isPackageExist = false,
          isItemExist = false,
          mappingArea,
          all_S_Count,
          gateIn_S_Count,
          balance_S_Count = 0,
        } = option; //e.target;
        // ////(option);
        temp_data = {
          S_label: label,
          shipmentId: value,
          mappingArea,
          all_S_Count,
          gateIn_S_Count,
          isPackageExist,
          isItemExist,
          isCountOnly: false,
          balance_S_Count,
        };
  
        let valid_count = getValidCount_For_SPI(option, "shipment");
        // let req_payload={
        // 	shipmentId: value,
        // 	status,
        // 	projection_for:"job"
        // }
  
        // if(valid_mappingArea?.length){
        // 	req_payload["mappingArea"]=valid_mappingArea
  
        // }
  
        // if(isTypeGateIn || isTypeQCInward){
        // 	req_payload["fetch_for"]="beforeEntry"
        // }
        // else{
        // 	req_payload["fetch_for"]="afterEntry"
        // }
  
        if (isPackageExist) {
          temp_data["count"] = 0;
          fetchPackageItem({
            shipmentId: value,
            packageId: "",
            dataFetchType: "packageWise",
          });
          // req_payload["dataFetchType"]= "packageWise"
          // dispatch(getAllInventory(req_payload, ({ success = false, data = [] }) => {
          // 	setInventoryData({shipments,packages: data, items: [] })
          // }));
        } else if (isItemExist) {
          temp_data["count"] = 0;
          fetchPackageItem({
            shipmentId: value,
            packageId: "",
            dataFetchType: "itemWise",
          });
          // req_payload["dataFetchType"]= "itemWise"
          // dispatch(getAllInventory(req_payload, ({ success = false, data = [] }) => {
          // 	setInventoryData({ shipments, packages: [], items: data })
          // }));
        } else {
          setInventoryData({ shipments, packages: [], items: [] });
          temp_data["isAdd"] = true;
          temp_data["isCountOnly"] = true;
          temp_data["count"] = valid_count;
          //only count
        }
      } else {
        temp_data["isAdd"] = false;
        temp_data["count"] = 0;
        setInventoryData({ shipments, packages: [], items: [] });
      }
      // ////(temp_data);
  
      setSelectedData(temp_data);
    };
  
    const handlePackageSelect = (e, option, returnData = false) => {
      let temp_data = { ...selectedData };
      // ////("Package Option", option);
      if (option?.value) {
        const { label, value, isItemExist, shipmentId } = option;
  
        temp_data = {
          ...temp_data,
          ...option,
          packageId: value,
          P_label: label,
          isAdd: true,
          isCountOnly: false,
          isPackageExist: true,
        };
  
        let valid_count = getValidCount_For_SPI(option, "package");
  
        // let req_payload={
        // 	shipmentId,status,
        // 	packageId: value,
        // 	projection_for:"job"
        // }
  
        // if(valid_mappingArea?.length){
        // 	req_payload["mappingArea"]=valid_mappingArea
        // }
        // if(isTypeGateIn || isTypeQCInward){
        // 	req_payload["fetch_for"]="beforeEntry"
        // }
        // else{
        // 	req_payload["fetch_for"]="afterEntry"
        // }
        if (isItemExist) {
          temp_data["isAdd"] = false;
          temp_data["count"] = 0;
          fetchPackageItem({
            shipmentId,
            packageId: value,
            dataFetchType: "itemWise",
          });
          // req_payload["dataFetchType"]= "itemWise"
          // dispatch(getAllInventory(req_payload, ({ success = false, data = [] }) => {
          // 	setInventoryData({ shipments, packages, items: data })
          // }));
        } else {
          //package more than one count
          // if(Number(all_P_Count || 0)>1){
          // 	isBool=false
          // }
          temp_data["isAdd"] = true;
          temp_data["isCountOnly"] = true;
          temp_data["count"] = valid_count;
          setInventoryData({ shipments, packages, items: [] });
        }
      } else {
        setInventoryData({ shipments, packages, items: [] });
        delete temp_data["packageId"];
        delete temp_data["P_label"];
        delete temp_data["all_P_Count"];
        delete temp_data["gateIn_P_Count"];
        delete temp_data["balance_P_Count"];
        temp_data["isCountOnly"] = true;
        temp_data["count"] = 0;
        temp_data["isAdd"] = false;
      }
  
      delete temp_data["I_label"];
      delete temp_data["itemId"];
  
      // ////("iiiiiiii",temp_data);
      if (returnData) return temp_data;
      else {
        setSelectedData(temp_data);
      }
    };
  
    const handleItemSelect = (e, option, returnData = false) => {
      let temp_data = { ...selectedData };
      // ////("Item Option", option);
      if (option?.value) {
        const { label, value } = option;
        let valid_count = getValidCount_For_SPI(option, "item");
        temp_data = {
          ...temp_data,
          ...option,
          isItemExist: true,
          isAdd: true,
          itemId: value,
          I_label: label,
          count: valid_count,
          isItemExist: true,
        };
      } else {
        delete temp_data["itemId"];
        delete temp_data["I_label"];
        temp_data["isAdd"] = false;
        temp_data["count"] = 0;
      }
  
      temp_data["isCountOnly"] = true;
  
      ////("temp_data",temp_data);
      if (returnData) return temp_data;
      else {
        setSelectedData(temp_data);
      }
    };
  
    const diableShipment = ({ shipmentId }) => {
      if (!shipmentId) {
        return false;
      }
  
      let isShipmentAdded = false,
        allAddedCount = 0;
      const { isItemExist = false, isPackageExist = false } = shipments.find(
        (el) => el.shipmentId == shipmentId
      );
  
      for (let ele of addedItems) {
        if (ele?.shipmentId == shipmentId) {
          if (isItemExist || isPackageExist) {
            isShipmentAdded = true;
            allAddedCount++;
            continue;
          }
  
          return true;
        }
      }
  
      if (isShipmentAdded) {
        let available = 0;
  
        if (isItemExist) {
          if (isPackageExist) {
            for (let key in countFor_PKG_Item || {}) {
              if (key.includes(shipmentId)) {
                available = available + countFor_PKG_Item[key];
              }
            }
            // countFor_PKG_Item[packageId] || 0
          } else {
            available = countFor_Shipment_Item[shipmentId] || 0;
          }
        } else {
          available = countFor_Shipment_PKG[shipmentId] || 0;
        }
  
        // ////("shipmentId",shipmentId,{
        // 	allAddedCount,available,countFor_PKG_Item,countFor_Shipment_PKG,
        // 	countFor_Shipment_Item,isItemExist
        // });
        //check disable condition
        if (allAddedCount >= available) {
          return true;
        }
      }
  
      return false;
    };
    // ////(addedItems);
    const diablePackage = ({ packageId, isItemExist }) => {
      if (!packageId) {
        return true;
      }
  
      let isPKGAdded = false,
        allAddedCount = 0;
  
      for (let ele of addedItems) {
        if (ele?.packageId == packageId) {
          if (isItemExist) {
            isPKGAdded = true;
            allAddedCount++;
            // allAddedCount+=Number(isItemExist ? 1 : ele.count || 1)
            continue;
          }
          // ////("inside not Itmem",packageId);
          return true;
        }
      }
      // ////("added",isPKGAdded,allAddedCount);
  
      if (isPKGAdded) {
        let available = countFor_PKG_Item[packageId] || 0;
        // ////("inside isItme",packageId,allAddedCount,available);
        if (allAddedCount >= available) {
          return true;
        }
      }
  
      return false;
    };
  
    const diableItem = ({ ItemId }) => {
      // ////("diableItem",ItemId);
      if (!ItemId) {
        return true;
      }
      return addedItems.find((el) => el?.itemId == ItemId) ? true : false;
    };
  
    const header = (() => {
      if (isTypePickingAndPackaging) {
        let arr = [
          "Sr. No",
          getLabelKeyForJobConfig(jobProcessConfig, "shipment", "label"),
          getLabelKeyForJobConfig(jobProcessConfig, "package", "label"),
          getLabelKeyForJobConfig(jobProcessConfig, "item", "label"),
          "Gate In Time",
          "Packed In",
          "Quantity",
        ];
  
        if (["packageName"].includes(category)) arr.splice(1, 1);
        return arr;
      }
      else {
        let arr = [
          "Sr. No",
          getLabelKeyForJobConfig(jobProcessConfig, "shipment", "label"),
          getLabelKeyForJobConfig(jobProcessConfig, "package", "label"),
          getLabelKeyForJobConfig(jobProcessConfig, "item", "label"),
          "Mapping Area",
          "Count",
          "Action",
        ];
  
        if (!isTypeGateIn && !isTypeQCInward) arr.splice(5, 0, "Gate In Time");
  
        if (["packageName"].includes(category)) arr.splice(1, 1);
        return arr;
      }
    })();
  
    const handleRowDelete = (indx) => {
      setAddedItems((o) => o.filter((_, i) => i !== indx));
    };
  
    const countHandler = (count_num = "", index, available_count) => {
      let temp_added = [...addedItems];
  
      let elem = temp_added[index],count_convert = removeLeadingChars(count_num);
  
      // ////("fffff",{count_num,count_convert});
  
      if (Number(count_convert) > available_count) {
        showToast(
          `Maximum count should be ${available_count} for row number ${index + 1
          }`,
          true
        );
        elem = { ...elem, count: available_count };
        temp_added.splice(index, 1, elem);
      } else {
        elem = { ...elem, count: count_convert };
        temp_added.splice(index, 1, elem);
      }
      setAddedItems(temp_added);
    };
    const rows = (() => {
      if (isTypePickingAndPackaging) {
        let allRows = [];
        let counter = 0;
        for (let i = 0; i < packagingList.length; i++) {
          const pkg = packagingList[i];
          for (let j = 0; j < pkg.itemDetail.length; j++) {
            const element = pkg.itemDetail[j];
            let row = [];
            row.push(
              counter + 1,
              getLabelKeyForJobConfig(jobProcessConfig, "shipment", "key", element, ["gateInTime"]) || "NA",
              getLabelKeyForJobConfig(jobProcessConfig, "package", "key", element, ["gateInTime"]) || "NA",
              getLabelKeyForJobConfig(jobProcessConfig, "item", "key", element, ["gateInTime"]) || "NA",
              dateTimeFormater(element.gateInTime),
              // element.shipmentId ? element.shipmentId : "NA",
              // element[
              //   getLabelKeyForJobConfig(jobProcessConfig, "package", "key")
              // ] || "NA",
              // element[getLabelKeyForJobConfig(jobProcessConfig, "item", "key")] ||
              //   "NA",
              pkg.masterPackageId,
              element.count
            );
            if (["packageName"].includes(category)) row.splice(1, 1);
            allRows.push(row);
            counter++;
          }
        }
        return allRows;
      } else {
        return addedItems.map((data, indx) => {
          let row = [];
          const {
            shipmentId,
            packageId,
            itemId,
            mappingArea,
            count,
            isCountOnly = false,
            balance_P_Count,
            balance_S_Count,
            all_S_Count,
            gateIn_S_Count,
            all_P_Count,
            gateIn_P_Count,
            all_I_Count,
            gateIn_I_Count,
            balance_I_Count,
            isPackageExist = false,
            isItemExist = false,
            availableCount = false
          } = data;
  
          // ////("data", data);
          // ////("mappia",mappingArea);
          row.push(
            indx + 1,
            getLabelKeyForJobConfig(jobProcessConfig, "shipment", "key", data, ["gateInTime"], true),
            getLabelKeyForJobConfig(jobProcessConfig, "package", "key", data, ["gateInTime"], true),
            getLabelKeyForJobConfig(jobProcessConfig, "item", "key", data, ["gateInTime"], true),
            // shipmentId,
            // data[getLabelKeyForJobConfig(jobProcessConfig, "package", "key")] ||
            //   "NA",
            // data[getLabelKeyForJobConfig(jobProcessConfig, "item", "key")] ||
            //   "NA",
            mappingArea && mappingArea != null && mappingArea != ""
              ? mappingArea
              : "PUT IT ANY WHERE",
            count || 1,
            [
              <IconButton
                aria-label="delete"
                key={indx}
                onClick={() => handleRowDelete(indx)}
              >
                <Delete sx={{ color: "red" }} />
              </IconButton>,
            ]
          );
  
          if (isCountOnly) {
            let available_count = 0;
            if (isItemExist) {
              if (Number(all_I_Count) == 1) {
                row.splice(5, 1, 1);
                if (!isTypeGateIn && !isTypeQCInward) {
                  row.splice(5, 0, dateTimeFormater(data.gateInTime));
                }
                if (["packageName"].includes(category)) row.splice(1, 1);
  
                return row;
              }
              if (isTypeGateIn || isTypeQCInward) {
                if (availableCount) {
                  available_count = availableCount
                }
                else {
                  available_count = truncateNumber(Number(all_I_Count || 1) - Number(gateIn_I_Count || 0));
                }
              }
              else {
                available_count = Number(balance_I_Count);
              }
            }
            else if (isPackageExist) {
              if (Number(all_P_Count) == 1) {
                row.splice(5, 1, 1);
                if (!isTypeGateIn && !isTypeQCInward) {
                  row.splice(5, 0, dateTimeFormater(data.gateInTime));
                }
                if (["packageName"].includes(category)) row.splice(1, 1);
                return row;
              }
              if (isTypeGateIn || isTypeQCInward) {
                if (availableCount) {
                  available_count = availableCount
                }
                else {
                  available_count =truncateNumber(Number(all_P_Count || 1) - Number(gateIn_P_Count || 0));
                }
              } else {
                available_count = Number(balance_P_Count);
              }
            }
            else {
              if (Number(all_S_Count) == 1) {
                row.splice(5, 1, 1);
                if (!isTypeGateIn && !isTypeQCInward) {
                  row.splice(5, 0, dateTimeFormater(data.gateInTime));
                }
                if (["packageName"].includes(category)) row.splice(1, 1);
                return row;
              }
              if (isTypeGateIn || isTypeQCInward) {
                if (availableCount) {
                  available_count = availableCount
                }
                else {
                  available_count =truncateNumber(Number(all_S_Count || 1) - Number(gateIn_S_Count || 0));
                }
              }
              else {
                available_count = Number(balance_S_Count);
              }
            }
  
            if(isTypeQCInhouse || isTypeQCInward){
            }
            else{
              row.splice(
                5,
                1,
                <GenericSingleRangeComponent
                  startRange={count || null}
                  endRange={Number(available_count || 0)}
                  getOnChange={(countValue) => countHandler(countValue, indx, available_count)}
                  endRangeInfinity={false}
                  valueType={"decimal"}
                />,
              );
            }
          }
  
          if (!isTypeGateIn && !isTypeQCInward) {
            row.splice(5, 0, dateTimeFormater(data.gateInTime));
          }
          if (["packageName"].includes(category)) row.splice(1, 1);
          return row;
        });
      }
    })();
  
    // Picking & Packing functions
    // ////("Added Items", addedItems);
  
    const handleCreatePackaging = (data) => {
      data = {
        ...data,
        masterPackageId: `${packagingList.length + 1}`,
        itemDetail: objectsInPackage,
      };
  
      setPackagingList([...packagingList, data]);
      setOpenCreatePackage(false);
      setPackageDialogType(null);
      setObjectsInPackage([]);
    };
  
    const handleSavePackaging = (data, i) => {
      let oldPkgObjects = packagingList[i].itemDetail;
      let currentPkgObjects = data.itemDetail.map((it) => {
        let itemKey = it.isItemExist
          ? "itemId"
          : it.isPackageExist
            ? "packageId"
            : "shipmentId";
        return it[itemKey];
      });
      let excludedObjects = oldPkgObjects
        .filter((o) => {
          let itemKey = o.isItemExist
            ? "itemId"
            : o.isPackageExist
              ? "packageId"
              : "shipmentId";
          if (!currentPkgObjects.includes(o[itemKey])) {
            return true;
          }
          return false;
        })
        .map((it) => {
          let itemKey = it.isItemExist
            ? "itemId"
            : it.isPackageExist
              ? "packageId"
              : "shipmentId";
          return it[itemKey];
        });
  
      let oldAdded = JSON.parse(JSON.stringify(addedItems));
      oldAdded = oldAdded.filter((od) => {
        let itemKey = od.isItemExist
          ? "itemId"
          : od.isPackageExist
            ? "packageId"
            : "shipmentId";
        if (!excludedObjects.includes(od[itemKey])) {
          return true;
        }
        return false;
      });
      setAddedItems(oldAdded);
  
      let oldP = JSON.parse(JSON.stringify(packagingList));
      oldP[i] = data;
      setPackagingList(oldP);
      setOpenCreatePackage(false);
      setPackageDialogType(null);
      setObjectsInPackage([]);
    };
  
    const removeObjectFromAdded = (objectToRemove) => {
      // Find in addedItems
      let itemKey = objectToRemove.isItemExist
        ? "itemId"
        : objectToRemove.isPackageExist
          ? "packageId"
          : "shipmentId";
      let itemIndex = addedItems
        .map((ai) => ai[itemKey])
        .indexOf(objectToRemove[itemKey]);
  
      if (itemIndex >= 0) {
        let oldAdded = JSON.parse(JSON.stringify(addedItems));
        oldAdded.splice(itemIndex, 1);
        setAddedItems(oldAdded);
      }
    };
  
    const handleRemoveSelectedObject = (i) => {
      let oldS = JSON.parse(JSON.stringify(objectsInPackage));
      let objectToRemove = oldS[i];
  
      removeObjectFromAdded(objectToRemove);
  
      oldS.splice(i, 1);
      setObjectsInPackage(oldS);
      if (oldS.length == 0) {
        setOpenCreatePackage(false);
        setPackageDialogType(null);
        setPackageEditIndex(null);
      }
    };
  
    const handleDeletePackaging = (i) => {
      let oldPackaging = [...packagingList];
  
      let excludedObjects = oldPackaging[i].itemDetail.map((it) => {
        let itemKey = it.isItemExist
          ? "itemId"
          : it.isPackageExist
            ? "packageId"
            : "shipmentId";
        return it[itemKey];
      });
  
      let oldAdded = JSON.parse(JSON.stringify(addedItems));
      oldAdded = oldAdded.filter((od) => {
        let itemKey = od.isItemExist
          ? "itemId"
          : od.isPackageExist
            ? "packageId"
            : "shipmentId";
        if (!excludedObjects.includes(od[itemKey])) {
          return true;
        }
        return false;
      });
      setAddedItems(oldAdded);
  
      oldPackaging.splice(i, 1);
      oldPackaging = oldPackaging.map((pkg, i) => ({
        ...pkg,
        masterPackageId: i + 1,
      }));
      setPackagingList(oldPackaging);
    };
    // ////("Selected Item", selectedData);
  
    const getRemainingCount = () => {
      return selectedData.isItemExist
        ? selectedData.balance_I_Count
        : selectedData.isPackageExist
          ? selectedData.balance_P_Count
          : selectedData.balance_S_Count
    }
  
    const handleCountChange = (countValue) => {
      let oldSelectedData = JSON.parse(JSON.stringify(selectedData));
      if (countValue <= 0) {
        oldSelectedData.count = 0
      }
      else {
        const remaining = getRemainingCount();
        oldSelectedData.count = Math.min(remaining, countValue)
      }
      setSelectedData(oldSelectedData);
    };
  
    const validToAdd = () => { };
  
    const selectAllHandler = (checked = false, type = "package") => {
      let temp_checked = { ...selectedData},
        isAdd = false,
        isItemExist = false;
      if (checked) {
        if (type == "package") {
          temp_checked["checked"] = ["package"];
          let allPackage = packageOptions().filter((dl) => dl.isItemExist); //.map(el=>el.packageId)
          if (allPackage?.length > 0) {
            fetchPackageItem({
              packageId: allPackage.map((el) => el.value),
              dataFetchType: "itemWise",
            });
            isAdd = selectedData.itemId ? true : false;
            isItemExist = true;
            temp_checked["saveAs"] = "single";
          } else {
            isAdd = true;
            isItemExist = false;
            temp_checked["saveAs"] = "multiple";
          }
        } else {
          temp_checked["checked"] = [...(temp_checked["checked"] || []), type];
          temp_checked["saveAs"] = "multiple";
          isItemExist = true;
          isAdd = true;
        }
      } else {
        if (type == "package") {
          temp_checked["checked"] = [];
          isAdd = false;
          isItemExist = ["packageName"].includes(category)
            ? false
            : temp_checked.isItemExist || false;
        } else {
          temp_checked["checked"] = (temp_checked["checked"] || []).filter(
            (dl) => dl != type
          );
          isAdd = false;
          isItemExist = true;
        }
        temp_checked["saveAs"] = "single";
      }
  
      if (type == "package") {
        delete temp_checked["packageId"];
        delete temp_checked["P_label"];
        delete temp_checked["all_P_Count"];
        delete temp_checked["gateIn_P_Count"];
        delete temp_checked["balance_P_Count"];
      }
      delete temp_checked["I_label"];
      delete temp_checked["itemId"];
      //("temp_checked",temp_checked);
      setSelectedData({ ...temp_checked, isAdd, isItemExist });
    };
  
    const handleItemAdd = () => {
      //{shipments=[],packages=[],items},setInventoryData
      let inventoryShipment = { ...shipments };
      if (selectedData?.saveAs == "multiple" && selectedData?.checked?.length && Array.isArray(selectedData?.checked)) {
        let tempData = [],
          isInvalid = true;
        if (selectedData.checked.includes("item")) {
          isInvalid = false;
          tempData = itemOptions().map((option) => {
            const { label, value, packageId } = option;
            let valid_count = getValidCount_For_SPI(option, "item");
            let packageInfo = {};
            if (packageId)
              packageInfo =
                packages.find((dl) => dl.packageId == packageId) || {};
            let temp_data = {
              ...selectedData,
              ...packageInfo,
              ...option,
              isAdd: true,
              itemId: value,
              I_label: label,
              count: valid_count,
              isItemExist: true,
              isCountOnly: true,
            };
            return temp_data;
          });
        }
        let remaining_PKG = packageOptions().filter(
          (pkg_info) => !pkg_info.isItemExist
        );
        // let total_count=0,balance_count=0,gateIn_count=0
        if (
          selectedData.checked.includes("package") && remaining_PKG?.length > 0
        ) {
          isInvalid = false;
          let tempArr = remaining_PKG.map((option) => {
            const { label, value } = option;
            let valid_count = getValidCount_For_SPI(option, "package");
            let temp_data = {
              ...selectedData,
              ...option,
              packageId: value,
              P_label: label,
              isAdd: true,
              isCountOnly: true,
              isPackageExist: true,
              count: valid_count,
              isItemExist: false,
            };
  
            // total_count=total_count+Number(option?.all_P_Count || 0)
            // balance_count=balance_count+Number(option?.balance_P_Count || 0)
            // gateIn_count=gateIn_count+Number(option?.gateIn_P_Count || 0)
  
            return temp_data;
          });
          if (tempArr?.length > 0) {
            tempData.push(...tempArr);
          }
        }
  
        if (isInvalid) {
          showToast("Not able to add data!", true);
          return;
        }
        // ////("tempData",tempData);
        setAddedItems((o) => [...tempData, ...o]);
      } 
      else {
        if (selectedData.count <= 0) {
          showToast("Count cannot be 0", true);
          return;
        }
        setAddedItems((o) => [selectedData, ...o]);
        if (isTypePickingAndPackaging) {
          setObjectsInPackage([...objectsInPackage, selectedData]);
        }
      }
      setSelectedData(initial);
    };
  
    const DynamicFields = (
      <Grid container spacing={2} flexDirection={"row"}>
        {!["packageName"].includes(category) && (
          <Grid container item sm={3}>
            <GenericDropdown
              options={shipmentOptions()}
              option={{ label: selectedData.S_label || "" }}
              handleSelect={(e, option) => handleShipmentSelect(e, option)}
              disabled={listDropDown}
              id="shipmentId-select"
              size="small"
              key="shipmentId-select_gateOut"
              label="Select Shipment"
              noOptionsText={isDataLoader ? "Loading..." : "No Shipment found"}
            />
            {/* <Autocomplete
              fullWidth
              id="shipmentId-select"
              size="small"
              noOptionsText={isDataLoader ? "Loading..." : "No Shipment found"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Shipment ID"
                  sx={{
                    ...selectStylesOverride,
  
                  }}
                />
              )}
              disabled={listDropDown}
              // getOptionLabel={(option) => option.label}
              // sx={{ flexBasis: "30%" }}
              options={shipmentOptions()}
              onChange={handleShipmentSelect}
              // getOptionDisabled={(option) => diableShipment({...option,shipmentId:option.value})}
              value={selectedData.S_label || ""}
              // isOptionEqualToValue={(option, value) => {
              // 	return option.label === value.label;
              // }}
              key="shipmentId-select_gateOut"
            /> */}
          </Grid>
        )}
        {(selectedData?.isPackageExist || ["packageName"].includes(category)) && (
          <Grid container item sm={3} justifyContent="end">
            <GenericDropdown
              options={packageOptions()}
              option={{ label: selectedData.P_label || "" }}
              handleSelect={(e, option) => handlePackageSelect(e, option, false)}
              disabled={listDropDown || (selectedData?.checked || []).includes("package")}
              id="packageId-select"
              size="small"
              label="Select Package"
              key="packageId-select_gateOut"
              noOptionsText={isDataLoader ? "Loading..." : "No Package found"}
            />
            {/* <Autocomplete
              fullWidth
              id="packageId-select"
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Package"
                  sx={{
                    ...selectStylesOverride,
                  }}
                />
              )}
              noOptionsText={isDataLoader ? "Loading..." : "No Package found"}
              disabled={listDropDown || (selectedData?.checked || []).includes("package")}
              // getOptionLabel={(option) => option.label}
  
              options={packageOptions()}
              onChange={(e, option) => handlePackageSelect(e, option, false)}
              // getOptionDisabled={(option) => diablePackage({...option,packageId:option.value})}
              value={selectedData.P_label || ""}
              // isOptionEqualToValue={(option, value) => {
              // 	return option.label === value.label;
              // }}
              // inputValue={selectedData.P_label || ""}
              // isOptionEqualToValue={(option, value) => {
              // 	return option.label === value.label;
              // }}
              key="packageId-select_gateOut"
            /> */}
            {!isTypePickingAndPackaging && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={(selectedData?.checked || []).includes("package")}
                    onChange={(e) =>
                      selectAllHandler(e.target.checked, "package")
                    }
                    color="primary"
                    inputProps={{ "aria-label": "controlled-checkbox" }}
                    variant="contained"
                    sx={{
                      "&:hover": {
                        color: theme.themeOrange,
                      },
                    }}
                    disabled={packageOptions()?.length == 0}
                  />
                }
                label={
                  <Typography style={{ color: theme.themeOrange }}>
                    All Package
                  </Typography>
                }
                labelPlacement="start"
              />
            )}
          </Grid>
        )}
        {selectedData?.isItemExist && (
          <Grid container item sm={3} justifyContent="end">
            <GenericDropdown
              options={itemOptions()}
              option={{ label: selectedData.I_label || "" }}
              handleSelect={(e, option) => handleItemSelect(e, option, false)}
              disabled={(selectedData?.checked || []).includes("item")}
              id="itemId-select"
              size="small"
              key="itemId-select_gateOut"
              label="Select Item"
              noOptionsText={isDataLoader ? "Loading..." : "No Item found"}
            />
            {/* <Autocomplete
              fullWidth
              id="itemId-select"
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  // type="search"
                  label="Select Item"
                  sx={{
                    ...selectStylesOverride,
                  }}
                />
              )}
              noOptionsText={isDataLoader ? "Loading..." : "No Item found"}
              disabled={(selectedData?.checked || []).includes("item")}
              // getOptionLabel={(option) => option.label}
              options={itemOptions()}
              onChange={(e, option) => handleItemSelect(e, option, false)}
              // getOptionDisabled={(option) => diableItem({ItemId:option?.value || ""})}
              value={selectedData.I_label || ""}
              // inputValue={selectedData.I_label || ""}
              // isOptionEqualToValue={(option, value) => {
              // 	return option.label === value.label;
              // }}
              key="itemId-select_gateOut"
            /> */}
            {!isTypePickingAndPackaging && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={(selectedData?.checked || []).includes("item")}
                    onChange={(e) => selectAllHandler(e.target.checked, "item")}
                    color="primary"
                    inputProps={{ "aria-label": "controlled-checkbox" }}
                    variant="contained"
                    sx={{
                      "&:hover": {
                        color: theme.themeOrange,
                      },
                    }}
                    disabled={itemOptions()?.length == 0}
                  />
                }
                label={
                  <Typography style={{ color: theme.themeOrange }}>
                    All Item
                  </Typography>
                }
                labelPlacement="start"
              />
            )}
          </Grid>
        )}
        <Grid container item sm={3} justifyContent="center">
          <Box sx={{ display: "flex" }}>
            {isTypePickingAndPackaging && (
              <GenericSingleRangeComponent
                startRange={!selectedData || !selectedData.isAdd ? 0:selectedData?.count || null}
                endRange={getRemainingCount() || 0}
                getOnChange={(countValue) => handleCountChange(countValue)}
                endRangeInfinity={false}
                disabled={!selectedData || !selectedData.isAdd}
                valueType={"decimal"}
              />
            )}
            <Box>
              <Button
                variant="contained"
                size="small"
                sx={{
                  ml: 2,
                  backgroundColor: theme.themeOrange,
                  "&:hover": {
                    backgroundColor: theme.themeOrange,
                  },
                }}
                onClick={handleItemAdd}
                disabled={!selectedData || !selectedData.isAdd}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  
    useEffect(() => {
      let condition = ["packageName"].includes(category)
      if (condition && (isTypeGateIn || isTypeQCInward)) {
        condition = allProductType.length > 0
      }
      else if (condition && (isTypeStockTransfer)) {
        condition = !!warehouseId
      }
      if (condition) {
  
        fetchPackageItem({
          shipmentId: "",
          packageId: "",
          dataFetchType: "packageWise",
        });
        // let req_payload={
        // 	status,
        // 	projection_for:"job"
        // }
  
        // if(valid_mappingArea?.length){
        // 	req_payload["mappingArea"]=valid_mappingArea
        // }
        // if(isTypeGateIn || isTypeQCInward){
        // 	req_payload["fetch_for"]="beforeEntry"
        // }
        // else{
        // 	req_payload["fetch_for"]="afterEntry"
        // }
        // req_payload["dataFetchType"]= "packageWise"
        // dispatch(getAllInventory(req_payload, ({ success = false, data = [] }) => {
        // 	setInventoryData({ shipments:[], packages:data, items: [] })
        // }));
      }
    }, [category, dataFilter, filterValue, allProductType, warehouseId]);
  
    return (
      <>
        {children && <Grid mt={1}>{children || ""}</Grid>}
        <Grid mt={3}>
          <Box>
            <Typography sx={{ fontSize: "18px", fontWeight: 400 }}>
              {isTypePickingAndPackaging ? "Packaging Details" : "Inventory Details"}
            </Typography>
          </Box>
  
          {isTypePickingAndPackaging ? (
            <>
              <Grid
                container
                sx={{
                  // border: "1px dashed #D9D9D9", p: 3,
                  mt: 2,
                  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='gray' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                  p: 3,
                }}
              >
                {packagingList.length > 0 && (
                  <PackageObjectsSlider
                    objects={packagingList}
                    slideIndex={slideIndex}
                    setSlideIndex={setSlideIndex}
                    handleOpenEditModal={(i) => {
                      setPackageEditIndex(i);
                      setPackageDialogType("edit");
                      setOpenCreatePackage(true);
                    }}
                    handleDeleteObject={handleDeletePackaging}
                  />
                )}
                {/* getLabelKeyForJobConfig(jobProcessConfig, "shipment", "key",data),
            getLabelKeyForJobConfig(jobProcessConfig, "package", "key",data),
            getLabelKeyForJobConfig(jobProcessConfig, "item", "key",data), */}
                <GenericCollapsableItemList
                  items={objectsInPackage}
                  limit={3}
                  itemSpacing={{ sm: 3, xs: 4 }}
                  handleRemoveItem={handleRemoveSelectedObject}
                  getContent={(itemList, i) => {
                    let itemObj = itemList[i];
                    // let itemKey = itemObj.isItemExist
                    //   ? getLabelKeyForJobConfig(
                    //       jobProcessConfig,
                    //       "item",
                    //       "key"
                    //     ) || "NA"
                    //   : itemObj.isPackageExist
                    //   ? getLabelKeyForJobConfig(
                    //       jobProcessConfig,
                    //       "package",
                    //       "key"
                    //     ) || "NA"
                    //   : getLabelKeyForJobConfig(
                    //       jobProcessConfig,
                    //       "shipment",
                    //       "key"
                    //     ) || "NA";
                    // return `${itemObj[itemKey]} (${itemObj.count} qty)`;
                    let labelToView = itemObj.isItemExist
                      ? getLabelKeyForJobConfig(jobProcessConfig, "item", "key", itemObj, ["gateInTime"]) || "NA"
                      : itemObj.isPackageExist
                        ? getLabelKeyForJobConfig(jobProcessConfig, "package", "key", itemObj, ["gateInTime"]) || "NA"
                        : getLabelKeyForJobConfig(jobProcessConfig, "shipment", "key", itemObj, ["gateInTime"]) || "NA";
                    return <Tooltip title={`${labelToView} (${itemObj.count} qty)`} placement="bottom">
                      <Typography sx={{ ...textClasses.t13n }}>
                        {getShortText(`${labelToView} (${itemObj.count} qty)`, 21)}
                      </Typography>
                    </Tooltip>
                      ;
                  }}
                  sx={{ mb: 3 }}
                />
  
                <CreatePackageDialog
                  open={openCreatePackage}
                  onClose={() => {
                    setOpenCreatePackage(false);
                    setPackageDialogType(null);
                  }}
                  items={objectsInPackage}
                  getLabelKeyForJobConfig={getLabelKeyForJobConfig}
                  jobProcessConfig={jobProcessConfig}
                  handleRemoveSelection={handleRemoveSelectedObject}
                  handleCreatePackaging={handleCreatePackaging}
                  dialogType={packageDialogType}
                  editIndex={packageEditIndex}
                  packageObj={
                    packageDialogType == "edit"
                      ? packagingList[packageEditIndex]
                      : null
                  }
                  handleSavePackaging={handleSavePackaging}
                />
                {DynamicFields}
                <Grid container sx={{ mt: 2 }}>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{
                      ...buttonClasses.lynkitBlackFill,
                      backgroundColor:
                        objectsInPackage.length < 1 ? "#D9D9D9" : "black",
                      minWidth: "150px",
                      minHeight: "40px",
                    }}
                    disabled={objectsInPackage.length < 1}
                    onClick={() => {
                      setOpenCreatePackage(true);
                      setPackageDialogType("create");
                    }}
                  >
                    Create Package
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <>{DynamicFields}</>
          )}
  
          {(isTypePickingAndPackaging && packagingList.length > 0) ||
            (!isTypePickingAndPackaging && addedItems.length > 0) ? (
            <TableContainer
              component={Paper}
              sx={{
                marginTop: "2em",
                maxWidth: "100%",
                // width: "60%",
                maxHeight: "60vh",
                overflowX: "auto",
              }}
            >
              <Table aria-label="simple table">
                <TableHead sx={{ backgroungColor: theme.themeGray }}>
                  <TableRow sx={{ backgroundColor: theme.themeGray }}>
                    {/* <TableCell sx={{fontSize: "10px", textAlign: "center"}}>
                          <Checkbox size='small'/>
                      </TableCell> */}
                    {header.map((head, i) => {
                      return (
                        <TableCell
                          sx={{
                            fontSize: "13px",
                            textAlign: "center",
                            fontWeight: 600,
                            // borderLeft: "1px solid #a3a3a3",
                            // '& .MuiTableCell-root':{
                            // }
                          }}
                          key={i}
                        >
                          {head}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    maxHeight: "60%",
                    overflow: "auto",
                  }}
                >
                  {rows.length > 0
                    ? rows.map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          // "&:last-child td, &:last-child th": {
                          // 	border: 0,
                          // },
                          "& .MuiTableCell-root": {
                            // borderLeft:
                            // "1px solid rgba(224, 224, 224, 1)",
                          },
                        }}
                      >
                        {row.map((col, j) => {
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                fontSize: "12px",
                                textAlign: "center",
                                overflowWrap: "break-word",
                              }}
                              key={j}
                            >
                              {col}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </Grid>
      </>
    );
  };

  