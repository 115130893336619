import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Switch, Redirect } from "react-router-dom";
import { socketServices, Operation } from '../../services/socketServices'

import { useDispatch, useSelector } from 'react-redux'

import { Box ,Backdrop, CircularProgress } from "@mui/material"
import { styled, useTheme } from '@mui/material/styles';


import DrawerNavigate from "../../views/Components/Drawer/Drawer";
import GridView from "../../views/Components/GridView/index.js";
import ThreeDView from "../../views/Components/3dView/index.js";

import TemplatesHome from "../../views/Modules/Templates"
import EditTemplate from '../../views/Modules/Templates/EditTemplate';
import CreateTemplate from '../../views/Modules/Templates/CreateTemplate';

import Inward from '../../views/Modules/DataEntry/Inward/Inward';
import AreaMapping from '../../views/Components/AreaMapping/AreaMapping';
import ViewTemplate from '../../views/Modules/Templates/ViewTemplate';
import GenericSlotBookingUI from '../../views/Components/BookingSlot/CreateSlotBookingUI.js';

//For SuperAdmin
import CompanyPage from '../../views/Modules/SuperAdmin/Company/index.js';
import AddCompany from '../../views/Modules/SuperAdmin/Company/AddCompany.js';
import WarehouseFloorPage from '../../views/Modules/SuperAdmin/WarehouseFloor/index.js';
import AddWarehouse from '../../views/Modules/SuperAdmin/WarehouseFloor/Warehouse/AddWarehouse.js';
import AddFloor from '../../views/Modules/SuperAdmin/WarehouseFloor/Floor/AddFloor.js';
import AddGridPage from '../../views/Modules/SuperAdmin/Grid/addGrid.js';
import AddAdmin from '../../views/Modules/SuperAdmin/Users/Admin/AddAdmin.js';
import AddWorker from '../../views/Modules/SuperAdmin/Users/Worker/AddWorker.js';
//dummy
import Map from '../../views/Modules/BLE/Map/index';
import AllInvetory from '../../views/Modules/Inventory/Inventory/index.js';
import QuantityControl from '../../views/Modules/Inventory/QualityControl/index.js';
import IdentificationConfig from '../../views/Modules/Inventory/IdConfiguration';
// for inventory adjustment
// for inventory adjustment
import InventoryAdjustments from '../../views/Modules/Inventory/Adjustment/index.js';
import NewInventoryAdjustment from '../../views/Modules/DataEntry/Adjustment/AddAdjustment';
import EditInventoryAdjustment from '../../views/Modules/DataEntry/Adjustment/EditAdjustment';

//Palletization Entry
import PalletizationEntry from '../../views/Modules/DataEntry/Palletization/index.jsx';
import MultiAsnReceiving from '../../views/Modules/DataEntry/MultiASNReceiving/index.jsx';
//Custom Dashboard
import CustomDashboard from '../../views/Modules/CustomDashboard/index.jsx';
import CreateCustomDashboard from '../../views/Modules/CustomDashboard/CreateDashboard/CreateCustomDashboard.jsx';
// for inventory journey
import ViewInventoryJourney from '../../views/Modules/InventoryJourney/index.js';
// For ASN
import AdvanceShipment from '../../views/Modules/Inventory/ASN';
import AddAsn from '../../views/Modules/DataEntry/Asn/AddAsn';
import ViewAsn from '../../views/Modules/DataEntry/Asn/ViewAsn';
import EditAsn from '../../views/Modules/DataEntry/Asn/EditAsn';
// For Kit
import KitAndBundle from '../../views/Modules/DataEntry/KitAndBundle/index.js'
import AddKit from '../../views/Modules/DataEntry/KitAndBundle/AddKit.jsx';
import ViewKit from '../../views/Modules/DataEntry/KitAndBundle/ViewKit.js';

//For In-house Palletization
import AddPallet from '../../views/Modules/DataEntry/InhousePalletization/Palletization/AddPallet.jsx';
import InHousePalletization from '../../views/Modules/DataEntry/InhousePalletization/index.js';
import ViewPallet from '../../views/Modules/DataEntry/InhousePalletization/Palletization/ViewPallet.js';
import EditPallet from '../../views/Modules/DataEntry/InhousePalletization/Palletization/EditPallet.js';

import MainDashboard from '../../views/Modules/MainDashboard';
import Navigation from '../../views/Modules/Navigation';

import 'react-toastify/dist/ReactToastify.css';
import JobModule from '../../views/Modules/Jobs';
import JobCreate from '../../views/Modules/Jobs/JobCreation/index.js';
import CreateUpdateJobType from '../../views/Modules/Jobs/JobTypeDashboard/CreateUpdateJobType.js';
import JobTypeView from '../../views/Modules/Jobs/JobTypeDashboard/index';
import UserManagement from '../../views/Modules/UserManagement';
import UserRole from '../../views/Modules/UserManagement/UserRole/UserRole';
import AddRole from '../../views/Modules/UserManagement/UserRole/AddRole';
import { getUserPermissions, getCentralTheming } from '../../redux/actions';
import Workflow from '../../views/Modules/Settings/Workflow';
import AddWorkflow from '../../views/Modules/Settings/AddWorkflow';
import ItemMaster from '../../views/Modules/EntityMaster/ItemMaster';
import CreateRuleAutomation from '../../views/Modules/Settings/AutomationRules/CreateRuleAutomation/index';
import AutomationRule from '../../views/Modules/Settings/AutomationRules';
import CreateSkuGroup from '../../views/Modules/Settings/AutomationRules/CreateSkuGroup.js';
import ReportDashboard from '../../views/Modules/ReportMangement/index';
import DownloadReport from '../../views/Modules/ReportMangement/DownloadReport';
import AddReturnProcess from "../../views/Modules/DataEntry/Return/AddReturn.js"
import AllReturnProcess from "../../views/Modules/Inventory/StockReturn"
import CreateSalesOrder from '../../views/Modules/SalesOrderProcessing/createSalesOrder.js';
import SalesOrderProcessing from '../../views/Modules/SalesOrderProcessing/index.js';
import ViewSales from '../../views/Modules/SalesOrderProcessing/Component/ViewSales.js';
import PageNotFound from '../../views/Landing/pageNotFound.js';
// import InternalServerError from '../../views/Landing/internalServerError.js';
//INTEGRATION
import RFIDDashboard from '../../views/Modules/RFID';
import Trip from '../../views/Modules/TMS/Trip/index.js';
import TripDetail from '../../views/Modules/TMS/Trip/tripDetail.js';
import Addressbook from '../../views/Modules/TMS/Addressbook/index.js';
import AdminWorkerPage from '../../views/Modules/SuperAdmin/Users/index.js';
import ManageControls from '../../views/Modules/Settings/AutomationRules/ManageControls/index.js';
import PreviewCustomDashboard from '../../views/Modules/CustomDashboard/PreviewDashboard/index.js';
import CustomDashboardLanding from '../../views/Modules/CustomDashboard/DefaultDashboard/index.js';
import Checkpoint from '../../views/Modules/Lynkid/Checkpoint/index.js';
import CheckpointDashboard from '../../views/Modules/Lynkid/Checkpoint/CheckpointDashboard.js';


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));




function App(props) {
  const dispatch = useDispatch();
  // // const name = useSelector(state => state.auth.name)
  // const { userPermissions={},sidebarList=[]} = useSelector((state) => state.userPermission);
  let isFrameActive = !!localStorage.getItem("activeURL")
  const style = { flexGrow: 1, p: 3 }
  if (isFrameActive) {
    // style.position = "relative"
    // style.top = "-5px"
    style.pt = 0
    style.pl = 0
    style.pr = 0
  }
  const [isLoading, setIsLoading] = React.useState(true);
  const [userPermissions, setUserPermissions] = React.useState({});
  const [sidebarList, setSidebarList] = React.useState([]);
  const [configDetail, setConfigDetail] = React.useState({});

  const getRedirectPath = () => {
    let url = sidebarList?.at(0)?.subItems?.length > 0
      ? sidebarList?.at(0)?.subItems.at(0)?.url
      : sidebarList?.at(0)?.url

    // //("url",url)
    return url
  }

  useEffect(() => {
   
    setUserPermissions({})
    setSidebarList([])
    localStorage.removeItem("slotsBooked");
    dispatch(getUserPermissions({}, ({ data }) => {
   
      // console.log(data?.data?.permissions,'llll');
      setUserPermissions(data?.data || {})
      setSidebarList(data?.sidebarList || [])
      setConfigDetail(data?.configDetail || {})

      // Assuming you receive the permission response from the API
    }))


  }, [window?.location?.pathname])

  useEffect(() => {
    setIsLoading(true)
    dispatch(getCentralTheming({}, () => {
      setIsLoading(false);
    }))
  }, [])

  useEffect(() => {
    if (localStorage.getItem("token")) {
      var socket = socketServices()

      if (socket) {
        socket.on("socket-change", socketData => {
          if (socketData && socketData?.data?.type) {
            // && ["ReportDownload","ParcelDimensions"].includes(socketData?.data?.type)
            Operation(socketData.data, dispatch)
          }
        });
      }
    }
    return () => {
      if (socket) {
        socket.disconnect()
      }
    };
  }, [window?.location?.pathname])

  return (
    <>
      {
        isLoading
          ? <Backdrop
            sx={{ backgroundColor: 'rgba(0, 0, 0, 0)', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          :
          <Box sx={{ display: 'flex' }}>
            {!isFrameActive && <DrawerNavigate {...props} />}
            <Box component="main" sx={style}>
              {configDetail?.header?.visible && <DrawerHeader />}
              <Switch>
                {/* Superadmin route start */}
                <Route exact path="/dashboard/grid/addGrid/:floorId"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "manageFloorGrid",
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/grid/addGrid/:floorId" render={() => <AddGridPage />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/grid/viewGrid/:floorId"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "manageFloorGrid",
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/grid/viewGrid/:floorId" render={() => <AddGridPage />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/company"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "createCompany",
                        "viewCompany",
                        "changeCompanyStatus",
                        "createWarehouse",
                        "viewWarehouse",
                        "changeWarehouseStatus",
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/company" render={() => <CompanyPage />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/company/add"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "createCompany"
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/company/add" render={() => <AddCompany />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/company/edit"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "createCompany",
                        "viewCompany",
                        "changeCompanyStatus",
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/company/edit" render={() => <AddCompany />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/warehouseFloor"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "addWarehouse",
                        "viewWarehouse",
                        "changeWarehouseStaus",
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/warehouseFloor" render={() => <WarehouseFloorPage />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/warehouse/add"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "addWarehouse",
                        "createCompany",
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/warehouse/add" render={() => <AddWarehouse />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/warehouse/edit"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "editWarehouse",
                        "createCompany",

                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/warehouse/edit" render={() => <AddWarehouse />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/floor/add"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "addFloor",
                        "createCompany",
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/floor/add" render={() => <AddFloor />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/floor/edit"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "editFloor",
                        "createCompany",
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/floor/edit" render={() => <AddFloor />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/users"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "createCompany",
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/users" render={() => <AdminWorkerPage />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/admin/add"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "addFloor",
                        "createCompany",
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/admin/add" render={() => <AddAdmin />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/admin/edit"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "editFloor",
                        "createCompany",
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/admin/edit" render={() => <AddAdmin />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/worker/add"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "addFloor",
                        "createCompany",
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/worker/add" render={() => <AddWorker />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/worker/edit"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "editFloor",
                        "createCompany",
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/worker/edit" render={() => <AddWorker />} />
                    </ProtectedRoute>
                  )}
                />
                {/* Superadmin route end */}
                <Route exact path="/dashboard/addressBook"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "add_business_partners", "gstin_mandate",
                        "get_business_partners", "address_book", "request_referal",
                        "gstin_api", "manage_customer", "get_registered_address",
                        "view_transporter", "view_customer", "delete_customer",
                        "delete_transporter", "manage_transporter", "update_fav_partner",
                        "delete_fav_partner", "fav_partner_detail", "manage_customer",
                        "blacklist_vendor"
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/addressBook" render={() => <Addressbook />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/trip"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "create_trip",
                        "update_trip",
                        "get_trips"
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/trip" render={() => <Trip />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/trip/trip-detail"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "create_trip",
                        "update_trip",
                        "get_trips"
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/trip/trip-detail" render={() => <TripDetail />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/view-checkpoint-dashboard"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "getDashboardV2"
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/view-checkpoint-dashboard" render={() => <Checkpoint />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/checkpoint-dashboard"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "getDashboardV2"
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/checkpoint-dashboard" render={() => <CheckpointDashboard />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["dashboard", 'viewDefaultDashboard']}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard" render={() => <MainDashboard />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/view-Default-dashboard"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['viewCustomDashboard', "createCustomDashboard"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/view-Default-dashboard" render={() => <CustomDashboardLanding />} />
                    </ProtectedRoute>
                  )}
                />

                <Route exact path="/dashboard/view-custom-dashboard"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["viewCustomDashboard", "createCustomDashboard", "updateCustomDashboard", "changeCustomDashboardStatus", "deleteCustomDashboard"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/view-custom-dashboard" render={() => <CustomDashboard />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/create-custom-dashboard"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["createCustomDashboard"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/create-custom-dashboard" render={() => <CreateCustomDashboard />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/custom/view/:dashboardID"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["createCustomDashboard"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/custom/view/:dashboardID" render={() => <PreviewCustomDashboard />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/setting/manage-workflows"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['viewWorkFlowList', "viewWorkFlowDetail", 'AddWorkFlow', "editWorkFlow", "enableDisableWorkFlow", "deleteWorkFlow"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/setting/manage-workflows" render={() => <Workflow />} />
                    </ProtectedRoute>
                  )} />
                <Route exact
                  path="/dashboard/report-management"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['viewReportDashboard', 'inwardReport', 'inventorySummaryReport', 'itemMasterReport', 'inwardSummaryReport',
                        'outwardSummaryReport', 'stockTransferReport', 'areaWiseStockSummaryReport', 'dayWiseIrStockReport', 'IrStockSummaryReport',
                        'IrClosingStockReport', 'areaWiseSKUSummaryReport', 'cellwiseAreaUtilizationReport', 'ASNReceivingReport', 'inventoryAgeReport']}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/report-management" render={() => <ReportDashboard />} />
                    </ProtectedRoute>
                  )}
                />

                <Route exact
                  path="/dashboard/report-Download"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['downloadReport']}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/report-Download" render={() => <DownloadReport />} />
                    </ProtectedRoute>
                  )}
                />

                <Route
                  exact
                  path="/dashboard/salesOrder"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "createSalesOrder",
                        "editSalesOrder",
                        "allocateSalesOrder",
                        "rejectSalesOrder",
                        "approveSalesOrder",
                        "viewSalesOrder",
                        "viewDetailSalesOrder"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/salesOrder" render={() => <SalesOrderProcessing />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/createSalesOrder"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "createSalesOrder", "viewSalesOrder", "viewDetailSalesOrder"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/createSalesOrder" render={() => <CreateSalesOrder />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/view-Sales/:salesId"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["viewSalesOrder", "viewDetailSalesOrder"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/view-Sales/:salesId" render={() => <ViewSales />} />
                    </ProtectedRoute>
                  )}
                />


                <Route
                  exact
                  path="/dashboard/bookSlot"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["bookSlot"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/bookSlot" render={() => <GenericSlotBookingUI />} />
                    </ProtectedRoute>
                  )}
                />


                {/* <Route exact path="/dashboard/salesOrder" render={() => <SalesOrderProcessing />} />
                  <Route exact path="/dashboard/createSalesOrder" render={() => <CreateSalesOrder />} />
                  <Route exact path="/dashboard/view-Sales/:salesId" render={() => <ViewSales />} /> */}


                <Route exact path="/dashboard/setting/create-workflow"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['AddWorkFlow', "editWorkFlow"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/setting/create-workflow" render={() => <AddWorkflow />} />
                    </ProtectedRoute>
                  )} />

                <Route exact path="/dashboard/setting/edit-workflow/:workflowId"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['viewItemMaster', "addItemMaster", 'deleteItemMaster', "updateItemMaster"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/setting/edit-workflow/:workflowId" render={() => <AddWorkflow />} />
                    </ProtectedRoute>
                  )} />


                <Route exact path="/dashboard/entity-master/item-master"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['viewItemMaster', "addItemMaster", 'deleteItemMaster', "updateItemMaster"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/entity-master/item-master" render={() => <ItemMaster />} />
                    </ProtectedRoute>
                  )} />
                <Route exact path="/dashboard/entity-master/kit-master"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['addKitMaster', "deleteKitMaster", 'updateKitMaster', "viewKitMaster"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/entity-master/kit-master" render={() => <ItemMaster />} />
                    </ProtectedRoute>
                  )} />
                <Route exact path="/dashboard/entity-master/pallet-master"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['viewPalletMaster', "addPalletMaster", 'deletePalletMaster', "updatePalletMaster"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/entity-master/pallet-master" render={() => <ItemMaster />} />
                    </ProtectedRoute>
                  )} />
                <Route exact path="/dashboard/entity-master/service-master"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['viewServiceMaster', "addServiceMaster", 'deleteServiceMaster', "updateServiceMaster"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/entity-master/service-master" render={() => <ItemMaster />} />
                    </ProtectedRoute>
                  )} />
                <Route exact path="/dashboard/entity-master/customer-master"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['viewCustomerMaster', "addCustomerMaster", 'deleteCustomerMaster', "updateCustomerMaster"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/entity-master/customer-master" render={() => <ItemMaster />} />
                    </ProtectedRoute>
                  )} />

                <Route exact path="/dashboard/entity-master/supplier-master"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['viewSupplierMaster', "addSupplierMaster", 'deleteSupplierMaster', "updateSupplierMaster"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/entity-master/supplier-master" render={() => <ItemMaster />} />
                    </ProtectedRoute>
                  )} />


                <Route exact path="/dashboard/data/view-journey"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["viewInventoryJourneyItemMaster", "viewInventoryJourneyKitMaster"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/data/view-journey" render={() => <ViewInventoryJourney />} />
                    </ProtectedRoute>
                  )}
                />

                <Route exact path="/dashboard/setting/automation-rules"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['viewAutomationRule', 'createAutomationRule', 'createSKUPlacementRules',
                        'deleteSKUPlacementRules', 'viewSKUPlacementRules'
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/setting/automation-rules" render={() => <AutomationRule />} />
                    </ProtectedRoute>
                  )} />

                <Route exact path="/dashboard/setting/automation-rules/CreateRuleAutomation"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['createAutomationRule']}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/setting/automation-rules/CreateRuleAutomation" render={() => <CreateRuleAutomation />} />
                    </ProtectedRoute>
                  )} />

                <Route exact path="/dashboard/setting/automation-rules/createSKUPlacementRule"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['createSKUPlacementRules']}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/setting/automation-rules/createSKUPlacementRule" render={() => <CreateSkuGroup />} />
                    </ProtectedRoute>
                  )} />
                <Route exact path="/dashboard/setting/manage-controls"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['ViewProcessControls', 'updateProcessControls']}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/setting/manage-controls" render={() => <ManageControls />} />
                    </ProtectedRoute>
                  )} />
                <Route
                  exact
                  path="/dashboard/data/inward"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['asnReceiving', "addNewInventory"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/data/inward" render={() => <Inward />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/data/multi-asn-receiving"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['asnReceiving', "addNewInventory", "packingListReceiving"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/data/multi-asn-receiving" render={() => <MultiAsnReceiving />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/inventory/palletization"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['singlePackageDataEntry']}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/inventory/palletization" render={() => <PalletizationEntry />} />
                    </ProtectedRoute>
                  )}
                />

                <Route
                  exact
                  path="/dashboard/inventory/asn"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['viewASN', "createASN", "deleteASN", "updateASN", "acceptRejectASN", "asnReceiving"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/inventory/asn" render={() => <AdvanceShipment />} />
                    </ProtectedRoute>
                  )}
                />

                <Route
                  exact
                  path="/dashboard/inventory/kit"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['viewKit', "createKit", "acceptRejectKit"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/inventory/kit" render={() => <KitAndBundle />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/data/kit"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['viewKit', "createKit", "acceptRejectKit"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/data/kit" render={() => <AddKit />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/data/view-kit/:kitId"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['viewKit', "createKit", "acceptRejectKit"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/data/view-kit/:kitId" render={() => <ViewKit />} />
                    </ProtectedRoute>
                  )}
                />

                <Route
                  exact
                  path="/dashboard/inventory/inhouse-palletization"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['createInhousePallet', "editInhousePallet", "viewInhousePallet", "palletizationInitiation", "palletizationApproval", "viewDismantleInhousePallet", "dismantlePalletInitiation","dismantlePalletApproval"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/inventory/inhouse-palletization" render={() => <InHousePalletization />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/data/inhouse-palletization"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["createInhousePallet",]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/data/inhouse-palletization" render={() => <AddPallet />} />
                    </ProtectedRoute>
                  )} />

                <Route exact path="/dashboard/data/edit-pallet/:compId"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["editInhousePallet"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/data/edit-pallet/:compId" render={() => <EditPallet />} />
                    </ProtectedRoute>
                  )} />

                <Route exact path="/dashboard/data/view-pallet/:palletId"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['dismantlePalletApproval', "createInhousePallet", "palletizationApproval"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/data/view-pallet/:palletId" render={() => <ViewPallet />} />
                    </ProtectedRoute>
                  )}
                />


                <Route exact path="/dashboard/data/asn"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["createASN"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/data/asn" render={() => <AddAsn />} />
                    </ProtectedRoute>
                  )} />
                <Route exact path="/dashboard/data/view-asn/:asnId"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['viewASN', "createASN", "deleteASN", "updateASN", "acceptRejectASN", "asnReceiving"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/data/view-asn/:asnId" render={() => <ViewAsn />} />
                    </ProtectedRoute>
                  )}
                />
                <Route exact path="/dashboard/data/edit-asn/:compId"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["updateASN"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/data/edit-asn/:compId" render={() => <EditAsn />} />
                    </ProtectedRoute>
                  )} />
                <Route
                  exact
                  path="/dashboard/data/adjustment"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['createInventoryAdjustment']}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/data/adjustment" render={() => <NewInventoryAdjustment />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/data/edit-adjustment/:refNo"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['updateInventoryAdjustment']}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/data/edit-adjustment/:refNo" render={() => <EditInventoryAdjustment />} />
                    </ProtectedRoute>
                  )}
                />
                {/* Inventory Table URLS */}

                <Route
                  exact
                  path="/dashboard/inventory/all"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['addNewInventory', "viewInventory", "viewInventoryDetails", "deleteInventory"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/inventory/all" render={() => <AllInvetory />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/inventory/qualityControl"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['addNewInventory', "viewInventory", "viewInventoryDetails", "deleteInventory"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/inventory/qualityControl" render={() => <QuantityControl />} />
                    </ProtectedRoute>
                  )}
                />


                <Route
                  exact
                  path="/dashboard/inventory/adjustments"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "createInventoryAdjustment",
                        "deleteInventoryAdjustment",
                        "approvedInventoryAdjustment",
                        "rejectInventoryAdjustment",
                        "updateInventoryAdjustment",
                        "viewInventoryAdjustment",
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/inventory/adjustments" render={() => <InventoryAdjustments />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/inventory/add-return"
                  render={() => (
                    <ProtectedRoute
                      // permissionId={['allReturns', "addReturns"]}
                      redirectPath={getRedirectPath()}
                    // userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/inventory/add-return" render={() => <AddReturnProcess />} />
                    </ProtectedRoute>
                  )}
                />

                <Route
                  exact
                  path="/dashboard/inventory/returns"
                  render={() => (
                    <ProtectedRoute
                      // permissionId={['viewASN', "createASN", "deleteASN", "updateASN", "acceptRejectASN"]}
                      // permissionId={['allReturns', "addReturns"]}
                      redirectPath={getRedirectPath()}
                    // userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/inventory/returns" render={() => <AllReturnProcess />} />
                    </ProtectedRoute>
                  )}
                />

                <Route
                  exact
                  path="/dashboard/inventory/configuration"
                  render={() => (
                    <ProtectedRoute
                      permissionId={['viewIdentifier', "updateIdentifier"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/inventory/configuration" render={() => <IdentificationConfig />} />
                    </ProtectedRoute>
                  )}
                />

                <Route
                  exact
                  path="/dashboard/templates/edit/:templateId"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["editTemplate"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/templates/edit/:templateId" render={() => <EditTemplate />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/templates/view/:templateId"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["viewTemplate", "createTemplate", "deleteTemplate", "editTemplate", "enabledisableTemplate"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/templates/view/:templateId" render={() => <ViewTemplate />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/templates/create"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["createTemplate"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/templates/create" render={() => <CreateTemplate />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/templates"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["viewTemplate", "createTemplate", "deleteTemplate", "editTemplate", "enabledisableTemplate"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/templates" render={() => <TemplatesHome />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/jobs/create-job"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["createNewJob", "createPickPackJob", "createPutAwayJob", "createStockTranferJob",
                        "createReturnSellerJob", "createDispatchJob", "completeDispatchJob",
                        "completeReturnSellerJob", "completeStockTranferJob", "completePutAwayJob",
                        "completePickPackJob"
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/jobs/create-job" render={() => <JobCreate />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/jobs/create-job-type"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["createNewJob", "createPickPackJob", "createPutAwayJob", "createStockTranferJob",
                        "createReturnSellerJob", "createDispatchJob", "completeDispatchJob",
                        "completeReturnSellerJob", "completeStockTranferJob", "completePutAwayJob",
                        "completePickPackJob"
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/jobs/create-job-type" render={() => <CreateUpdateJobType />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/jobs/edit-job-type/:jobTypeId"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["createNewJob", "createPickPackJob", "createPutAwayJob", "createStockTranferJob",
                        "createReturnSellerJob", "createDispatchJob", "completeDispatchJob",
                        "completeReturnSellerJob", "completeStockTranferJob", "completePutAwayJob",
                        "completePickPackJob"
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/jobs/edit-job-type/:jobTypeId" render={() => <CreateUpdateJobType />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/job/viewJobTypes"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["viewJobType", "addJobType", "deleteJobType", "editJobType"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/job/viewJobTypes" render={() => <JobTypeView />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/jobs"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "viewJobType", "addJobType", "deleteJobType", "editJobType", "viewAllJobs", "viewJobDetails",
                        "createNewJob", "viewPendency", "createPickPackJob", "createPutAwayJob", "createStockTranferJob",
                        "createReturnSellerJob", "createDispatchJob", "completeDispatchJob", "completeReturnSellerJob",
                        "completeStockTranferJob", "completePutAwayJob", "completePickPackJob",
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/jobs" render={() => <JobModule />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/area/grid"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["viewGrid", "generateGridQR"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/area/grid" render={() => <GridView />} />
                    </ProtectedRoute>
                  )}
                />
                {/* <Route
                exact
                path="/dashboard/area/3d"
                render={() => <ThreeDView />}
              /> */}
                <Route
                  exact
                  path="/dashboard/area/3d"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["viewThreeDimentionalGrid"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/area/3d" render={() => <ThreeDView />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/area/mapping"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["viewAreaMapping", "allocateArea", "viewDockMapping","createDockMapping","updateDockMapping","deleteDockMapping"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/area/mapping" render={() => <AreaMapping />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/BLE/map"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["viewBleTracking"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/BLE/map" render={() => <Map />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/RFID"
                  render={() => (
                    <ProtectedRoute
                      permissionId={[
                        "addTag",
                        "deleteTag",
                        "getTag",
                        "updateTag"
                      ]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/RFID" render={() => <RFIDDashboard />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/user_management/my-team"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["viewTeamMembers", "addNewMember", "editTeamMember", "disableEnableTeamMember", "deleteTeamMember"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/user_management/my-team" render={() => <UserManagement />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/user_management/user-role"
                  render={() => (
                    <ProtectedRoute
                      permissionId={["addRole", "disableEnableUserRole", "viewUserRoles", "editRole", "createAdmin", "viewAdmin", "changeAdminStatus"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/user_management/user-role" render={() => <UserRole />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/user_management/:roleAction"
                  render={() => (
                    <ProtectedRoute
                      permissionId={window?.location?.pathname.split('/').includes("edit-role") ? ["addRole", "createAdmin", "viewAdmin", "changeAdminStatus"] : ["editRole", "createAdmin", "viewAdmin", "changeAdminStatus"]}
                      redirectPath={getRedirectPath()}
                      userPermissions={userPermissions}
                    >
                      <Route exact path="/dashboard/user_management/:roleAction" render={() => <AddRole />} />
                    </ProtectedRoute>
                  )}
                />
                <Route
                  exact
                  path="/dashboard/navigation"
                  render={() => <Navigation />}
                />
                {/* <Route
                  exact
                  path="/error"
                  render={() => <InternalServerError />}
                /> */}
                <Route component={PageNotFound} />
                <Redirect to={getRedirectPath()} />
              </Switch>
            </Box>
          </Box >}

    </>


  );
}

export default App;

const ProtectedRoute = ({ permissionId, redirectPath = "/", userPermissions, children }) => {

  let condition = false
  if (userPermissions?.permissions?.length > 0) {
    condition = true
  }

  if (!condition) {
    return children;
  }
  else {
    let vaild_perm = permissionId.some(key => userPermissions.permissions.some(permission => permission.permissionId === key))

    if (vaild_perm) {
      return children;
    }
    else {
      return <Redirect to={redirectPath} replace />;
    }
  }
};
