export const inputClasses = {
  textField: {
    // boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "12px",
    // borderColor: "1px solid #D9D9D9",
    borderColor: "var(--gray)",
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: "400",
    "& label.Mui-focused": {
      color: "var(--primary-color)",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primary-color)",
        borderWidth: 1,
      },
    },
  },
  shadowField: {
    // boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "12px",
    borderColor: "var(--gray)",
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "400",
    "& label.Mui-focused": {
      color: "var(--primary-color)",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primary-color)",
        borderWidth: 1,
      },
    },
  },
  themeBoundary: {
    "& label.Mui-focused": {
      color: "var(--primary-color)",
    },
    "& .MuiFormLabel-root": {
      fontSize: "14px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primary-color)",
        borderWidth: 1,
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primary-color)",
        borderWidth: 1,
      },
      "&:hover fieldset": {
        borderColor: "var(--primary-color)",
        borderWidth: 1,
      },
    },
  },
  filterField: {
    boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "12px",
    borderColor: `1px solid "var(--gray)"`,
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "400",
    color: "#455A64",
    "& input::placeholder": {
      fontSize: "14px",
    },
    "& label.Mui-focused": {
      color: "var(--primary-color)",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primary-color)",
        borderWidth: 1,
      },
      "&:hover fieldset": {
        borderColor: "var(--primary-color)",
        borderWidth: 1,
      },
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "var(--primary-color)",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "var(--primary-color)",
    },
    "&:hover > .MuiOutlinedInput-notchedOutline ": {
      borderColor: "var(--primary-color)",
      borderWidth: 1,
    },
    "&:focused > .MuiOutlinedInput-notchedOutline ": {
      borderColor: "var(--primary-color)",
      borderWidth: 1,
    },
  },
};

export const cardClasses = {
  basic: {
    borderRadius: "15px",
    border: "1px solid #0000002B",
    boxShadow: "0px 4px 4px 0px #00000040",
  },
};

export const textClasses = {
  t12n: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
  },
  t13n: {
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "16px",
  },
  t14n: {
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "16px",
    fontFamily: 'Nunito'
  },
  normalText: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "17px",
  },
  boldText: {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
    color: "#455A64",
  },
  t15nBold: {
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "24px",
    fontFamily: 'Nunito',
  },
  t16n: {
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "24px",
    fontFamily: 'Nunito',
  },
  t20n: {
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "27px",
  },
  cardTitle: {
    fontSize: "24px",
    lineHeight: "33px",
    fontFamily: 'Nunito',
    fontWeight: "600",
  },
  pageTitle: {
    fontSize: "32px",
    lineHeight: "44px",
    fontWeight: "600",
    color: "var(--primary-color)",
  },
};

export const baseButton = {
  border: "1px solid var(--gray)",
  borderRadius: "12px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "22px",
  minHeight: "48px",
  textTransform: "none",
};
  
export const buttonClasses = {
  small: {
    borderRadius: "8px",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    color: "var(--white)",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--dark-orange)",
      color: "var(--white)",
      borderColor: "var(--dark-orange)",
    },
  },
  normal: {
    border: `1px solid "var(--gray)"`,
    borderRadius: "12px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontWeight: "600",
    fontSize: "16px",
    color: "var(--white)",
    lineHeight: "22px",
    minHeight: "48px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--dark-orange)",
      color: "var(--white)",
      borderColor: "var(--dark-orange)",
    },
  },
  medium: {
    boxShadow: "0px 4px 4px 0px #00000040",
    fontWeight: "600",
    fontSize: "14px",
    textTransform: "none",
    color: "#746a63",
    border: `1px solid "var(--gray)"`,
    borderRadius: "12px",
    backgroundColor: "var(--white)",
    lineHeight: "19px",
    minHeight: "37px",
    "&:hover": {
      border: `1px solid var(--primary-color)`,
      backgroundColor: "var(--white)",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
  },
  orange: {
    ...baseButton,
    backgroundColor: "var(--primary-color)",
    "&:hover": {
      backgroundColor: "var(--dark-orange)",
      color: "white",
      borderColor: "var(--dark-orange)",
    },
    "&:disabled": {
      border: 0,

    }
  },
  smallOrange: {
    border: `1px solid var(--gray)`,
    borderRadius: "4px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "27px",
    maxHeight: "24px",
    maxWidth: "24px",
    textTransform: "none",
    backgroundColor: "var(--primary-color)",
    color: "var(--white)",
    padding: "2px",
    "&:hover": {
      backgroundColor: "var(--dark-orange)",
      color: "var(--white)",
      borderColor: "var(--dark-orange)",
    },
    "&:disabled": {
      border: 0,
      cursor:"not-allowed"
    }
  },
  outlined: {
    border:  `1px solid var(--primary-color)`,
    color: "var(--primary-color)",
    borderRadius: "12px",
    backgroundColor: "var(--white)",
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "400",
    textTransform: "none",
    minHeight: "40px",
    "&:hover": {
      border: `1px solid var(--primary-color)`,
      backgroundColor: "var(--white)",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    "&:disabled": {
      border: 0,
    }
  },
  lynkitOrangeEmpty: {
    backgroundColor: "var(--white)",
    borderRadius: "8px",
    color: "var(--primary-color)",
    border: `1px solid var(--primary-color)`,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--primary-color)",
      border: `1px solid var(--primary-color)`,
      color: "var(--white)",
    },
    "&:disabled": {
      backgroundColor: "var(--dark-gray)",
      // border: 0,
    }
  },
  lynkitOrangeFill: {
    backgroundColor: "var(--primary-color)",
    borderRadius: "8px",
    border: `1px solid var(--primary-color)`,
    // border: `1px solid #fff`,
    textTransform: "none",
    color: "var(--white)",
    // "&:hover": {
    //   backgroundColor: "#FF7200",
    //   border: `1px solid #FF7200`,
    //   // color: "#FF7200",
    // },
    "&:hover": {
      backgroundColor: "var(--white)",
      border: `1px solid var(--primary-color)`,
      color: "var(--primary-color)",
    },
    "&:disabled": {
      backgroundColor: "var(--dark-gray)",
      // border: 0,
    }
  },
  lynkitGreenFill: {
    backgroundColor: "#004846",
    borderRadius: "8px",
    border: `1px solid #004846
`,
    // border: `1px solid #fff`,
    textTransform: "none",
    color: "#fff",
    // "&:hover": {
    //   backgroundColor: "#FF7200",
    //   border: `1px solid #FF7200`,
    //   // color: "#FF7200",
    // },
    "&:hover": {
      backgroundColor: "white",
      border: `1px solid #004846`,
      color: "#004846",
    },
    "&:disabled": {
      backgroundColor: "var(--dark-gray)",
      // border: 0,
    }
  },
  approvedButton: {
    backgroundColor: "#3E621E",
    borderRadius: "8px",
    border: "1px solid #3E621E",
    textTransform: "none",
    color: "var(--white)",
    // "&:hover": {
    //   backgroundColor: "#FF7200",
    //   border: `1px solid #FF7200`,
    //   // color: "#FF7200",
    // },
    "&:hover": {
      backgroundColor: "#3E621E",
      border: "1px solid #3E621E",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    "&:disabled": {
      backgroundColor: "var(--dark-gray)",
      // border: 0,
    }
  },
  lynkitBlackEmpty: {
    backgroundColor: "var(--white)",
    borderRadius: "8px",
    color: "#000",
    border: `1px solid var(--black)`,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--black)",
      border: `1px solid var(--black)`,
      color: "var(--white)",
    },
    "&:disabled": {
      backgroundColor: "var(--dark-gray)",
      // border: 0,
    }
  },
  lynkitBlackFill: {
    backgroundColor: "#000",
    borderRadius: "8px",
    color: "#ffffff",
    border: `1px solid #000`,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "white",
      border: `1px solid #000`,
      color: "#000",
    },
    "&:disabled": {
      backgroundColor: "grey",
      // border: 0,
    }
  },
  viewFile: {
    ...textClasses.t14n,
    textDecoration: "underline",
    color: "var(--primary-color)",
  },
};

export default {
  themeRoyalBlue: "var(--secondary-color)",
  themeOrange: "var(--primary-color)",
  themeLightOrange: "var(--light-orange)",
  themeLightCoral: "var(--light-coral)",
  themeOrangeBrown: "var(--orange-brown)",
  themeDarkOrange: "var(--dark-orange)",
  themeDarkRed: "var(--dark-red)",
  themeOrangeShadow: "var(--orange-shadow)",
  themeLightGreen: "var(--light-green)",
  themeBlack: "var(--black)",
  themeGray: "var(--gray)",
  themeRed: "var(--red)",
  themeYellow: "var(--yellow)",
  themeGreen: "var(--green)",
  themeError: "var(--error-color)",
  themeWhite: "var(--white)",
  themeText: "var(--text-color)",
  themeShadow: "var(--shadow-color)",
};
