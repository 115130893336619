import {
    FETCH_ERROR, FETCH_SUCCESS, FETCH_START,
} from "../types"
import axios from "../../services/Api";
import Cookies from 'js-cookie';
import { useApplyTheme } from "../../utils/hooks";


export const forgotPasswordCase = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('users/forgotPassword', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }
            if (cb) {
                cb(data)
            }

        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};

export const verifyOTPCase = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('users/verifyOtp', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }
            if (cb) {
                cb(data)
            }

        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};
export const changePasswordCase = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('users/changePassword', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }
            if (cb) {
                cb(data)
            }

        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};

export const getCentralTheming = (payload, cb) => {
    try {
        const cachedData = Cookies.get("centralTheming");
        if (cachedData) {
            if (cb) {
                cb();
            }
            useApplyTheme(JSON.parse(cachedData));
            return { type: FETCH_SUCCESS };
        }
        return (dispatch) => {
            dispatch({ type: FETCH_START });
            axios.get('base/getTheme', payload).then(({ data }) => {
                if (data.success) {
                    dispatch({ type: FETCH_SUCCESS });
                    // dispatch({ type: FETCH_LOGO_THEME, payload: data.data })
                    Cookies.set("centralTheming", JSON.stringify(data.data), { expires: 7 });
                    // window.location.reload();
                }
                else {
                    Cookies.remove("centralTheming", { path: "/" });
                    dispatch({ type: FETCH_ERROR, payload: data.message });
                    // dispatch({ type: FETCH_LOGO_THEME, payload: null })
                }
                useApplyTheme(data?.data || {})
                // window.dispatchEvent(new Event('themeChange')); // Notify App to update theme
                if (cb) {
                    cb();
                }
            }).catch(function (error) {
                dispatch({ type: FETCH_ERROR, payload: error.message });
                Cookies.remove("centralTheming", { path: "/" });
                useApplyTheme({})
                // window.dispatchEvent(new Event('themeChange')); // Notify App to update theme
                if (cb) {
                    cb();
                }
            });
        }
    }
    catch (error) {
        console.log(error)
        Cookies.remove("centralTheming", { path: "/" });
        useApplyTheme({})
        // window.dispatchEvent(new Event('themeChange')); // Notify App to update theme
        if (cb) {
            cb();
        }
    }
};
