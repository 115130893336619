import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {  getWarehouseCountData } from "../../../../redux/actions/CustomDashboard";
import { Box, Grid, Skeleton } from "@mui/material";
import CountTypeWidget from "../WidgetComponents/Count";


const WarehouseCountComponent = ({ item, styleView , warehouseDetails}) => {
    const dispatch = useDispatch()
    
    const [loader, setLoader] = useState(true)
    
    const [updatedItem, setUpdatedItem] = useState(item || []);
    
    const getDataDateRangeWise = (dateFrom = '', dateTo = '') => {
        let warehouseIds= warehouseDetails?.map(item => item._id)
        let payload = {
            wareHouse: warehouseIds || [],
            dateFrom: dateFrom,
            dateTo: dateTo
        }
        if (!item) return;
        dispatch(getWarehouseCountData(payload, (data) => {
            const transformedArray = [
                {
                    read: false,
                    category: "All Active",
                    value: data?.data?.activeWareHouse
                },
                {
                    read: false,
                    category: "All Blocked",
                    value: data?.data?.InActiveWareHouse
                }
            ];

            setUpdatedItem((prev) => ({
                ...prev,
                values: transformedArray
            }))
            setLoader(false)
        }))
    }
    useEffect(() => {
        getDataDateRangeWise()
    }, [warehouseDetails,dispatch])
    return (<>{
        !loader ? (< >
            <CountTypeWidget item={updatedItem} isDefaultDashboard={true} styleView={styleView}>

            </CountTypeWidget>
        </>) : <Grid item>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="rectangular" width={210} height={60} />
                <Skeleton variant="rounded" width={210} height={60} />
        </Grid>}

    </>)
}

export default WarehouseCountComponent;