import {
    FETCH_INHOUSE_PALLET_DETAIL, FETCH_INHOUSE_PALLETIZATION_TABLE_DATA, FETCH_ERROR,
    FETCH_SUCCESS, FETCH_START, FETCH_INHOUSE_PALLET_STATUS_COUNT, FETCH_INHOUSE_PALLET_PICKING_READY,
    FETCH_INHOUSE_DEPALLETIZATION_TABLE_DATA,FETCH_INHOUSE_DEPALLET_DETAIL,FETCH_INHOUSE_DEPALLET_STATUS_COUNT,
    FETCH_INHOUSE_PALLET_CARDTYPEWISE_COUNT
} from "../types"
import axios from "../../services/Api";
import { showToast } from "../../services/functions";


export const getInHousePalletizationCardTypeWiseCount = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('inhousePallet/getPalletizationAndDismatleCount', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_INHOUSE_PALLET_CARDTYPEWISE_COUNT, payload: data.data })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_INHOUSE_PALLET_CARDTYPEWISE_COUNT, payload: null })
            }
            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};


export const saveInHousePalletEntry = (payload, callback) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('inhousePallet/createInhousePallet', payload).then(({ data }) => {
            if (data.success) {
                process(data)
            }
            else {
                //(data.message);
                dispatch({ type: FETCH_ERROR, payload: data.message });
                showToast(data.message, true)
                callback(null);
            }
            function process(data) {
                dispatch({ type: FETCH_SUCCESS });
                showToast(data.message, false);
                callback(data);
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            //("Error****:", error.message);
            callback(null)
        });
    }
};

export const editInHousePalletEntry = (payload, callback) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('inhousePallet/editPalletizationData', payload).then(({ data }) => {
            if (data.success) {
                process(data)
            }
            else {
                //(data.message);
                dispatch({ type: FETCH_ERROR, payload: data.message });
                showToast(data.message, true)
                callback(null);
            }
            function process(data) {
                dispatch({ type: FETCH_SUCCESS });
                showToast(data.message, false);
                callback(data);
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            //("Error****:", error.message);
            callback(null)
        });
    }
};


export const getAllInHousePalletTableData = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('inhousePallet/getInhousePalletList', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_INHOUSE_PALLETIZATION_TABLE_DATA, payload: { data: data?.data || [], count: data?.count } })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_INHOUSE_PALLETIZATION_TABLE_DATA, payload: { data: [], count: 0 } })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};

export const approveRejectInhousePalletStatus = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('inhousePallet/updateInhousePalletStatus', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });

            showToast(error.message, true);
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};


export const getInHousePalletDetails = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('inhousePallet/getInhousePalletDetail', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_INHOUSE_PALLET_DETAIL, payload: { data: data?.data || {}, count: data?.count } })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_INHOUSE_PALLET_DETAIL, payload: { data: {}, count: 0 } })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};


export const getInHousePalletizationStatusWiseCount = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('inhousePallet/getInhousePalletStatusWiseCount', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_INHOUSE_PALLET_STATUS_COUNT, payload: data.data })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_INHOUSE_PALLET_STATUS_COUNT, payload: null })
            }
            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};

export const getInHouseCheckPickingReadyPallet = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('inhousePallet/checkPickingReadyPallet', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_INHOUSE_PALLET_PICKING_READY, payload: data.data })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_INHOUSE_PALLET_PICKING_READY, payload: null })
            }
            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};


export const getAllInHouseDePalletTableData = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('/inhousePallet/getDismantlingPalletList', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_INHOUSE_DEPALLETIZATION_TABLE_DATA, payload: { data: data?.data || [], count: data?.count } })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_INHOUSE_DEPALLETIZATION_TABLE_DATA, payload: { data: [], count: 0 } })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};


export const getInHouseDePalletizationStatusWiseCount = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('inhousePallet/getDismantlingPalletStatusWiseCount', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_INHOUSE_DEPALLET_STATUS_COUNT, payload: data.data })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_INHOUSE_DEPALLET_STATUS_COUNT, payload: null })
            }
            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};



export const getInHouseDePalletDetail = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('inhousePallet/getDismantlingPalletComponent', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_INHOUSE_DEPALLET_DETAIL, payload: data.data })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_INHOUSE_DEPALLET_DETAIL, payload: null })
            }
            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};

export const approveRejectInhouseDePalletStatus = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('/inhousePallet/palletDismantleProcessApproval', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });

            showToast(error.message, true);
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};

export const initiateDePalletProcess = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('inhousePallet/initiatePalletDismantleProcess', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }
            if (cb) {
                cb(data)
            }

        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};