import React from 'react'
import { Typography, Grid } from '@mui/material'
import theme, { textClasses } from "../../../static/styles/theme"

import GenericComponentsCollapse from '../../Components/Generic/GenericComponentsCollapse'

export default function PreviousUsedComponents({usedComponents}) {
    ////(" Used Components --- ");
    ////(usedComponents);
    return (
        <Grid container sx={{zIndex: 100, mr: 2, overflowY: "auto", maxHeight:'75vh'}}>
            <Grid container item sm={12}>
                <Typography variant='h6' sx={{color: theme.themeOrange}}>Previous Used Component</Typography>
                <Typography sx={{...textClasses.normalText, fontSize: "12px", color: "#455A64"}}>
                    All form field which are already used in any template. Just drag a field and drop into your new template, no need to defined field header, placeholder and input validations again and again 
                </Typography>
                {
                    usedComponents.map((section, i) => {
                        return (
                            <GenericComponentsCollapse
                                index={i+1}
                                title={section._id ? section._id : "Most used components"}
                                components={section.elements}
                                componentType={"previous"}
                            />
                        )
                    })
                }
                {/* <GenericComponentsCollapse
                    index={1}
                    title={"Text and Input fields"}
                    components={textComponents}
                    componentType={"previous"}
                />
                <GenericComponentsCollapse
                    index={2}
                    title={"Frequently used components"}
                    components={mostUsedComps}
                    type="list"
                    componentType={"previous"}
                /> */}
            </Grid>
            
        </Grid>
    )
}

