import * as React from "react";
import { Button, Link, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { textClasses, buttonClasses } from "../../../../static/styles/theme";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Add, KeyboardArrowDown } from "@mui/icons-material";
import GenericPageConfig from "../../../Components/Generic/GenericPageConfig";
import { getAutomationRulesStatusCount } from "../../../../redux/actions/AutomationRule";
import SkuGroupPlacementRule from "./SkuGroupPlacementRule";
import AutomationProcessRules from "./AutomationProcessRules";
import TransitionRules from "./TransitionRules";
import InventoryMovementRules from "./InventoryMovementRules";


const AutomationRule = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pageTitle = "Automation Rules" } = useSelector(state => state?.DynamicPageConfig?.automationRulesPage) || {};
  const { userPermissions } = useSelector((state) => state.userPermission);
  let permissions = userPermissions || {};
  const [tabIndex, setTabIndex] = useState(-1);
  const [statusFilter, setStatusFilter] = useState({});
  const [automationRuleStatusCount, setAutomationRuleStatusCount] = useState({});
  const [loader, setLoader] = useState("");

  useEffect(() => {
    dispatch(getAutomationRulesStatusCount({}, (data) => {
      if (data?.success) {
        setAutomationRuleStatusCount({ ...data?.data })
      }
    }))
  }
    , [])


  const handleTabChange = (value, statusObject = {}) => {
    setStatusFilter(statusObject)
    setTabIndex(value);
  };

  const renderTabDetails = (selectedTab) => {
    switch (selectedTab?.value) {
      case "automationRules":
        return <AutomationProcessRules dataCount={automationRuleStatusCount[selectedTab?.dataReadKey]} selectedTab={statusFilter?.value} />;
      case "transitionRules":
        return <TransitionRules dataCount={automationRuleStatusCount[selectedTab?.dataReadKey]} selectedTab={statusFilter?.value} />;
      case "inventoryMovementRules":
        return <InventoryMovementRules dataCount={automationRuleStatusCount[selectedTab?.dataReadKey]} selectedTab={statusFilter?.value} />;
      case "placementRules":
        return <SkuGroupPlacementRule dataCount={automationRuleStatusCount[selectedTab?.dataReadKey]} selectedTab={statusFilter?.value} />;
      default:
        return ;
    }
  };

  return (
    <>
      <Grid container data-testid="automation-rules">
        <Grid
          item
          sm={8}
        >
          <Typography
            sx={{ ...textClasses.cardTitle }}
          >
            {pageTitle}
          </Typography>
          {/* <Breadcrumbs aria-label="breadcrumb">
            <Link
              sx={{
                color: "#FF7200",
                ...textClasses.t12n,
              }}
              underline="hover"
              color="inherit"
              href=""
            >
              Setting
            </Link>
            <Typography
              sx={{
                ...textClasses.normalText,
              }}
              color="text.primary"
            >
              AutomationRule
            </Typography>
          </Breadcrumbs> */}
        </Grid>
        <Grid container item sm={4} sx={{ display: 'flex', alignItems: "center", justifyContent: 'flex-end' }}>
          {permissions &&
            (permissions.permissions || []).some(
              (item) => item.permissionId === "createSKUPlacementRules"
            ) ? (
            <Button
              data-testid="create-new-company"
              variant="outlined"
              size="small"
              startIcon={<Add />}
              sx={{ ...buttonClasses.lynkitOrangeEmpty }}
              onClick={() => {
                statusFilter?.value == "placementRules"
                  ? history.push("/dashboard/setting/automation-rules/createSKUPlacementRule")
                  : history.push("/dashboard/setting/automation-rules/CreateRuleAutomation");
              }}>
              {statusFilter?.value == "placementRules" ? "Create Placement Rule" : "Create Rule"}
            </Button>
          )
            : (null)}
        </Grid>
        <GenericPageConfig
          storedInPage={"automationRulesPage"}
          pageName={"automationRules"}
          // pageTabCount={companystatusCount}
          pageStatusCount={{ ...automationRuleStatusCount, outwardOperationRulesCount: 3, inventoryMovementRulesCount: 2 }}
          // handlePageTabChange={handleTypeChange}
          selectedPageTab={"default"}
          selectedStatusTab={tabIndex}
          handleStatusTabChange={handleTabChange}
          disabledPageTabs={loader}
          disabledStatusTabs={loader}

        />
      </Grid>
      {statusFilter?.value && renderTabDetails(statusFilter)}
    </>
  );
};
export default AutomationRule;
