

import React, { useState } from "react";
import { Stepper, Step, StepLabel, Button, Typography, Paper, IconButton  } from "@mui/material";
import theme,{buttonClasses} from "../../../../../static/styles/theme";
import ClearIcon from '@mui/icons-material/Clear';

const WelcomeNote = ({ item, setLayout, styleView={} }) => {

  // Extract steps dynamically from `values`
  const steps = item?.values?.map(val => val.value) || [];

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <Paper elevation={3} sx={{width:'inherit',height:'inherit',p:1 ,...styleView}}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel
              sx={{
                "& .MuiStepIcon-root.Mui-active": { color: theme.themeOrange}, // Active step color
                "& .MuiStepIcon-root.Mui-completed": { color: theme.themeGreen }, // Completed step color (optional)
              }}
            >{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <IconButton
            size="small"
            sx={{
                position: "absolute",
                top: "1px",
                right: "5px",
                color: theme.themeOrange,
            }}
            onClick={() => {
                setLayout((prev) => prev.filter((l) => l.i !== item.i));
            }}
        >
            <ClearIcon fontSize="small" />
        </IconButton>
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <Typography variant="h6">{steps[activeStep]}</Typography>
        <Typography variant="body1" >
          {activeStep === 0
            ? "We're excited to have you. Let's get started!"
            : `This is step ${activeStep + 1}.`}
        </Typography>
        <div>
          <Button sx={{...buttonClasses.lynkitOrangeEmpty}}
          disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            sx={{...buttonClasses.lynkitOrangeFill,ml:1}}
            disabled={steps?.length == activeStep}
          >
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default WelcomeNote;
