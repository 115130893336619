import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
	FormControl, Grid, 
	Button, TextField,MenuItem, Typography,Box, Tooltip, Autocomplete,
	Breadcrumbs
} from "@mui/material";
import colors, { buttonClasses, } from '../../../../static/styles/theme';
import { selectStylesOverride } from "../../../../utils/util";

import {
	assignJobToUser, getUsersToAssignJob,
} from "../../../../redux/actions/Job";
import theme from "../../../../static/styles/theme";
import { showToast} from "../../../../services/functions";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import AlertDialog from "../../../Components/DialogBox/dialogbox";
import CustomChip from "../../../Components/CustomChip";


const initFilters = {
	"search": {},
    "other": {},
	page: 1,
	limit: 10,
	// jobStatus: "",
	// jobName: "",
	// jobType: "",
	// createdOn: null,
	// completedOn: null,
	// jobId: "",
	// shipmentId: "",
};


export default function  AssignJobToUserSection(props){
	let dispatch = useDispatch()
	let { checkedRow, setCheckedRow, setFilter, setTabIndex, get_Job_All } = props
	let [userList, setUserList] = useState([])
	let [assignTo, setAssignTo] = useState(null)
	let [loader, setLoader] = useState(null)


	let assignUserHandler = () => {
		setLoader(true)
		dispatch(assignJobToUser({ jobId: checkedRow?.selectedRow?.map(dl => dl.groupBy) || [], userId: assignTo?.value }, ({ success, message }) => {
			showToast(message, !success)
			setLoader(false)
			if (success) {
				setAssignTo(null)
				setCheckedRow({ visible: false, selectedRow: [] })
				setFilter({ ...initFilters })
				get_Job_All(initFilters, true)
				setTabIndex(0)
			}
		}))
	}

	useEffect(() => {
		// setLoader(true)
		if (checkedRow?.selectedRow?.length > 0) {
			dispatch(getUsersToAssignJob({ jobId: checkedRow?.selectedRow?.map(dl => dl?.groupBy) || [] }, ({ data }) => {
				setUserList(data?.map(dl => ({ label: (dl.name + ", " + dl.designation), value: dl._id })) || [])
				// setLoader(false)
			}))
		}
		else {
			setUserList([])
		}
	}, [checkedRow.selectedRow])

	return <AlertDialog
		viewDialog={checkedRow?.visible}
		sx={{ borderRadius: "50px" }}
		bodyCSS={{ color: theme.themeBlack }}
		handleClose={() => setCheckedRow({ ...checkedRow, visible: false })}
		actionsCSS={{ marginTop: "-15px" }}
		body={
			<Box sx={{ marginTop: "-10px" }}>
				<Grid container xs={12} >
					<Grid container item xs={12} sm={8.5} justifyContent="flex-start">
						<Typography sx={{ color: theme.themeBlack, fontWeight: 600, fontSize: "1.3rem" }} fullWidth>
							Assign Job{checkedRow?.selectedRow?.length > 1 ? "(s)" : ""} To Worker
						</Typography>
					</Grid>
					<Grid item data-testid="user-select" xs={12} sm={3.5} justifyContent="flex-end">
						<FormControl
							variant="outlined"
							size="small"
							fullWidth
							sx={{ ...selectStylesOverride }}
						>
							<Autocomplete
								size="small"
								disabled={loader}
								labelId="user--select-label"
								renderInput={(params) => (
									<TextField
										{...params}
										label={<Typography sx={{ fontSize: "12px" }}>Select User</Typography>}
										sx={{
											...selectStylesOverride,
											fontSize: "12px",
											"& input::placeholder": {
												fontSize: "9px",
											},
										}}
									/>
								)}
								options={userList}
								noOptionsText="No user found, Which have all permissions for completion of selected job(s)"
								onChange={(e, option) => setAssignTo(option?.value ? option : null)}
								value={assignTo}
								renderOption={(props, option) => (
									<MenuItem value={option?.value || ""} key={option?.value || ""}  {...props}>
										<Tooltip title={option?.label || ""} placement="top">
											<Typography sx={{ fontSize: ".8rem", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
												{option?.label || ""}
											</Typography>
										</Tooltip>
									</MenuItem>
								)
								}
							/>
						</FormControl>
					</Grid>

				</Grid>
				<Box sx={{ mt: 1 }}>
					{loader
						? <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12}>

							<Grid item><GenericLoader /></Grid>
						</Grid>
						: <CustomChip
							data={checkedRow?.selectedRow || []}
							readLabelKey="groupBy"
							makeDisabled={true}
							handleDelete={(index, id) => {
								// console.log(id, "id")
								let tempRowSelect = checkedRow?.selectedRow || []
								tempRowSelect.splice(index, 1)
								setCheckedRow({ ...checkedRow, selectedRow: tempRowSelect })
							}}
						/>
					}
				</Box>
			</Box>
		}
	>
		<Button
			variant="outlined"
			disabled={loader}
			onClick={() => setCheckedRow({ ...checkedRow, visible: false })}
			sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, mt: 0, fontSize: ".7rem", minWidth: "130px" }}
		>Cancel</Button>

		<Button
			autoFocus
			variant="outlined"
			disabled={loader || !checkedRow || !checkedRow.selectedRow || checkedRow.selectedRow.length == 0 || !assignTo || !assignTo?.value}
			sx={{
				...buttonClasses.lynkitOrangeFill, m: 1, mt: 0, fontSize: ".7rem", border: 'none', minWidth: "130px",
			}}
			onClick={assignUserHandler}
		>
			Assign Job{checkedRow?.selectedRow?.length > 1 ? "(s)" : ""}
		</Button>
	</AlertDialog>
}
