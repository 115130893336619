import { Avatar, Box, Divider, Grid, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import theme, { textClasses } from "../../../../static/styles/theme";
import GenericDashboardCard from "../../../Components/GenericDashboardCard";
import { blue, green, red } from "@mui/material/colors";
import { lynkID } from "../../../../services/config";
import { useDispatch, useSelector } from "react-redux";
import { getCheckpointDashboardList } from "../../../../redux/actions";



const CheckpointDashboard = () => {
    
    const dispatch = useDispatch();
    // const { checkpointDashboardData } = useSelector(state => state.checkpointDashboardData);
    const [checkpointDashboardList, setCheckpointDashboardList] = useState([]);
    const [loader, setLoader] = useState(true);
   
    const onClickFunction = (url = '') => {
        window.open(`${lynkID }/login?action=${url}&auth=WMS&timestamp=${new Date().getTime()}&sidebar=true`, "_blank");
    }
    useEffect(() => {
        window.addEventListener("message", receivedMessage, false);
        function receivedMessage(evt) {
            try {
                // console.log("evt receive",evt)
                if (evt.origin === lynkID && evt.data) {
                    let data;
                    if (typeof evt.data === "string") {
                        data = JSON.parse(evt.data);
                    }
                    else {
                        data = evt.data;
                    }
                    // console.log("data key",data,data?.perkey,data?.perkey === 'sendphrase')
                    if (data?.perkey == 'sendphrase') {
                        // console.log("source");
                        evt.source.postMessage({ phrase: localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : "" }, evt.origin);
                    }
                    // console.log("evt.data",evt.data);
                    // if (data.frameHeight) {
                    //     const iframe = document.getElementById('frame');
                    //     iframe.style.height = evt.data.frameHeight + 'px';
                    // }
                    if (data?.frameHeight) {
                        // Update the iframe height state based on the message from the child
                        if (data?.frameHeight < 800) {
                            setFrameHeight('800px');
                        }
                        else {
                            setFrameHeight(data?.frameHeight + 'px');
                        }
                    }
                }
            } catch (e) {
                console.log("evt parsing error", e)
                console.log(e);
            }
        }
        
        return () => {
            window.removeEventListener("message", receivedMessage, false);
        };

       
    }, []);
    useEffect(()=>{
        
        dispatch(
            getCheckpointDashboardList({}, (data) => {
                setCheckpointDashboardList(data?.data);
                setLoader(false);
            })
        )
    },[])
    return (<>
        <Grid>
            <Box>
                <Typography
                    sx={{
                        ...textClasses.cardTitle,
                        color: "#000000",
                        fontWeight: "700",
                    }}
                >
                    Overview
                </Typography>
                <Divider
                    sx={{
                        margin: "10px 0 15px 0",
                    }}
                />
                <Grid container spacing={3}>
                    {!loader &&
                        checkpointDashboardList?.map((item, index) => (
                            <GenericDashboardCard
                                key={index}
                                title={item?.displayName}
                                bgColor={theme.themeOrange}
                                icon={item?.checkpointNumber}
                                onClickFunction={onClickFunction}
                                isReturnHandleClick={true}
                                data={[
                                ]}
                                route={`/checkpoints/${item.checkpointType}/${item?.checkpointNumber}`}
                            />
                        ))}
                </Grid>
            </Box>
        </Grid>

    </>)
}


export default CheckpointDashboard;