import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  FormLabel,
  InputLabel,
  Typography,
  Tab,
  Tabs,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  Chip,
  Paper,
  IconButton,
  Menu,
  Tooltip,
  setRef,
  Autocomplete,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  Add,
  Cancel,
  Clear,
  KeyboardArrowDown,
  Search,
} from "@mui/icons-material";
import ShareSharpIcon from '@mui/icons-material/ShareSharp';
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllWarehouses,
  approveRejectSales,
  getAllJobTypes,
  getAllUsers
} from "../../../redux/actions";
import theme, {
  buttonClasses,
  inputClasses,
  textClasses,
} from "../../../static/styles/theme";
import { Link } from "react-router-dom";

import { capitalizeSentence, showToast,renderHeadersFilter } from "../../../services/functions";
import history from "../../../services/history";
import { DatePicker } from "@mui/x-date-pickers";
import GenericCustomiseHeader from "../../Components/Generic/GenericCustomiseHeader";
import GenericTable from "../../Components/CustomTable/GenericTable";
import { useDebounce } from "../../../utils/hooks";
import GenericLoader from "../../Components/Generic/GenericLoader";
import {
  getAllSALESORDER,
  getSalesStatusCount,
} from "../../../redux/actions/SalesOrder";
import { getAllESalesOrder,approveRejectESales,getESalesStatusCount } from "../../../redux/actions/ExternalForm";
import ESOFormPage from "./eSoFormPage";
import ViewAssociatedJob from "./viewAssociatedJob";
import ViewAssociatedTrip from "./viewAssociatedTrip"
import { multiDateToISOConvertor } from "../../Components/Generic/GenericMultiDateRange";
import AllocateStockModalBox from "./AllocateStockModalBox";



const ConfirmDialog = ({
  status,
  handleClose,
  selectedEntries,
  setSelectedEntries,
  refreshTableData,
  handleRemoveSelection,
  tabIndex
}) => {
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState(false);
  const size = selectedEntries.length;
  const [reason, setReason] = useState("");

  const callBack = (data) => {
    showToast(data.message, false);
    refreshTableData(status);
    handleClose();
    if (setSelectedEntries) {
      setSelectedEntries([]);
    }
  };

  const handleStatusChange = () => {
    let payload = {}
    if(tabIndex == 6){
   
      if(status=="reject"){
        payload = {
          responseId:
            selectedEntries.length == 1
              ? selectedEntries[0].responseId
              : selectedEntries.map((e) => e.responseId),
          reason: reason,
          "approved":false
        };
      }
      else{
        payload = {
          responseId:
            selectedEntries.length == 1
              ? selectedEntries[0].responseId
              : selectedEntries.map((e) => e.responseId),
          "approved":true
        };
      }
      
      dispatch(
        approveRejectESales(payload, (data) => {
          if (data) {
            // console.log("Data",data)
            callBack(data);
          }
        })
      );
    }
    else{

      if(status=="reject"){
        payload = {
        
          Sales_Id:
            selectedEntries.length == 1
              ? selectedEntries[0].Sales_Id
              : selectedEntries.map((e) => e.Sales_Id),
          reason: reason,
          "approved":false
        };
      }
      else{
        payload = {
        
          Sales_Id:
            selectedEntries.length == 1
              ? selectedEntries[0].Sales_Id
              : selectedEntries.map((e) => e.Sales_Id),
          "approved":true
        };
      }
      
      dispatch(
        approveRejectSales(payload, (data) => {
          if (data) {
            callBack(data);
          }
        })
      );
    }
  };

  useEffect(() => {
    setReason("");
  }, [status]);

  return (
    <Dialog
      open={status != null}
      onClose={handleClose}
      scroll={"paper"}
      PaperProps={{ sx: { width: "100%" } }}
      maxWidth={"sm"}
    >
      <DialogContent>
        <Grid container justifyContent={"center"} alignItems="center">
          <Typography sx={{ ...textClasses.cardTitle }}>
            Are you Sure ?
          </Typography>
        </Grid>
        <Grid container justifyContent={"center"} alignItems="center">
          <Typography sx={{ ...textClasses.cardTitle }}>
            You want to {status} the {tabIndex == 6 ? "e-Sale Response":"Sales"}
          </Typography>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={1}>
          {selectedEntries.slice(0, showAll ? size : 5).map((st, i) => {
            return (
              <Grid item sm={4} xs={6} key={i}>
                <Grid
                  container
                  direction={"row"}
                  justifyContent="center"
                  alignItems={"center"}
                  sx={{
                    backgroundColor: theme.themeLightOrange,
                    border: `1px solid ${theme.themeOrange}`,
                    borderRadius: "12px",
                    pl: 1,
                    pr: 1,
                    minHeight: "40px",
                  }}
                >
                  <Grid item sm={10} xs={8}>
                    <Typography
                      sx={{
                        ...textClasses.normalText,
                        color: theme.themeOrange,
                        textAlign: "start",
                      }}
                    >
                      {tabIndex == 6 ?(st.responseId && st.responseId.length > 15
                        ? st.responseId.substring(0, 15) + "..."
                        : st.responseId) :  (st.Sales_Id && st.Sales_Id.length > 15
                        ? st.Sales_Id.substring(0, 15) + "..."
                        : st.Sales_Id) }
                    
                    </Typography>
                  </Grid>
                  {handleRemoveSelection && (
                    <Grid item sm={2} xs={4}>
                      <IconButton
                        sx={{ alignContent: "right" }}
                        onClick={() => handleRemoveSelection(st._id)}
                      >
                        <Cancel sx={{ color: theme.themeOrange }} />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            );
          })}
          {size > 5 && (
            <>
              <Grid
                container
                item
                sm={4}
                xs={6}
                justifyContent="center"
                alignItems={"center"}
              >
                <Typography
                  sx={{
                    ...textClasses.boldText,
                    color: theme.themeOrange,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowAll(!showAll)}
                >
                  {showAll ? <>Collapse</> : <>View All ({size})</>}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        {
          status =="reject" ?
          <Grid container direction={"row"} sx={{ mt: 1 }}>

          <Typography sx={{ ...textClasses.boldText }}>Reason*</Typography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </Grid>
        :
        ""

        }
       
        <Grid container sx={{ p: 1, mt: 3 }}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              ...buttonClasses.lynkitBlackFill,
              height: "40px",
              minWidth: "150px",
            }}
            onClick={handleClose}
          >
            No, Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              ml: 1,
              ...buttonClasses.small,
              backgroundColor: theme.themeOrange,
              minWidth: "150px",
            }}
            disabled={selectedEntries.length <= 0}
            onClick={handleStatusChange}
          >
            Yes, {status}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const getSpentTime = (then, now) => {
  if (then != null) {
    then = moment(then);
    now = now ? moment(now) : moment();

    let dayDiff = now.diff(then, "days");
    let hourDiff = now.diff(then, "hours");
    let minDiff = now.diff(then, "minutes");
    let timeSpent;

    if (dayDiff < 1) {
      if (hourDiff < 1) {
        timeSpent = `${minDiff} min ago`;
      } else {
        timeSpent = `${hourDiff} hrs ago`;
      }
    } else {
      timeSpent = `${dayDiff} d ago`;
    }
    return timeSpent;
  } else {
    return "-";
  }
};

let initial_state = {
  "search": {},
  "other": {},
}

export default function SalesOrderProcessing() {
  const dispatch = useDispatch();
  const { AllUserData } = useSelector((state) => state.users);
  const allTemplateTypes = useSelector(
    (state) => state.templates.templateTypes
  );
  const [tabIndex, setTabIndex] = useState(0);
  const totalRecords = useSelector((state) => tabIndex == 6 ? state.ExternalForm.totalRows: state.SalesProcess.totalRows);
  const salesOrderData = useSelector(
    (state) => tabIndex==6? state.ExternalForm.allESalesOrder: state.SalesProcess.allSalesOrder
  );
  const statusCount = useSelector((state) => state.SalesProcess.statusCount);
  const eStatusCount = useSelector((state)=> state?.ExternalForm?.statusCount)
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loader, setLoader] = useState(false);
  const [statusFilter, setStatusFilter] = useState(null);
  const [filter, setFilter] = useState(initial_state)
  const [initialRender, setInitialRender] = useState(true)
  const [tableType,setTableType]= useState("SaleOrderTable")
  const statusList = [
    { label: "Pending", value: "pending" },
    { label: "Approved", value: "approved" },
    { label: "Inprogress", value: "inprogress" },
    { label: "Dispatched", value: "dispatched" },
    // { label: "Drafted", value: "drafted" },
    { label: "Rejected", value: "rejected" },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState(null);
  const { userPermissions } = useSelector((state) => state.userPermission);
  let permissions = userPermissions || {};
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [confirmModal, setConfirmModal] = useState(null);
  const [actionConfirmModal, setActionConfirmModal] = useState(null);
  const [actionDispatchModal, setActionDispatchModal] = useState(null)
  const [dispatchModal, setDispatchModal] = useState(null)
  const [esoFormVisible,setEsoFormVisible]=useState(false);
  const debouncedFilterSearch = useDebounce(filter?.search, 500);
  const [viewModal,setViewModal]=useState({type:"",action:""})
  const handleOpenAction = (event, i) => {
    setAnchorEl(event.currentTarget);
    setActionIndex(i);
  };
  const handleCloseAction = () => {
    setActionConfirmModal(null);
    if (setSelectedEntries) {
      setSelectedEntries([]);
    }
    setActionIndex(null);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1);
  };

  const isFilterApplied = (() => {
    if (tabIndex == 0 && statusFilter) {
      return true;
    }
    if (Object.values(filter["search"])?.some(dl=>dl && dl.length>0) || Object.values(filter["other"]).some(dl=>dl && dl.length>0)) {
      return true;
    }
    return false;
  })();


  const isSelectionRejectable = (() => {
    let flag = true;
    if (
      selectedEntries.filter((st) => ["pending"].indexOf(st.approvalStatus) < 0)
        .length > 0
    ) {
      flag = false;
    }
    return flag;
  })();

  const isSelectionAllocatable = (() => {
    let flag = true;
    if(
      selectedEntries.filter(
        (st) => {
          if(!["approved", "inprogress"].includes(st.approvalStatus)){
            return true;
          }
          let ap_c = Number(st.approvalQuantity || 0);
          let al_c = Number(st.allocatedQuantity || 0);
          let rj_c = Number(st.rejectedQuantity || 0);
          if(ap_c - al_c - rj_c <= 0){
            return true
          }
          return false;
        }
      ).length > 0
    ) {
      flag = false;
    } 
    return flag;
  })();

  const isSelectionAllocatableForAction = (salesOrderDetail) => {
    if(!salesOrderDetail ){
      return false;
    }
    if(!["approved", "inprogress"].includes(salesOrderDetail?.approvalStatus)){
      return false;
    }
    let ap_c = Number(salesOrderDetail?.approvalQuantity || 0);
    let al_c = Number(salesOrderDetail?.allocatedQuantity || 0);
    let rj_c = Number(salesOrderDetail?.rejectedQuantity || 0);
    if(ap_c - al_c - rj_c <= 0){
      return false
    }
    return true;
  };
 

  const handleTabChange = (event, value) => {
    switch (value) {
      case 0:
        setStatusFilter(null);
        break;
      case 1:
        setStatusFilter(statusList[0]);
        break;
      case 2:
        setStatusFilter(statusList[1]);
        break;
      case 3:
        setStatusFilter(statusList[2]);
        break;
      case 4:
        setStatusFilter(statusList[3]);
        break;
      case 5:
        setStatusFilter(statusList[4]);
        break;
      case 6:
        setStatusFilter(null);
        break;
      default:
        setStatusFilter(null);
    }
    if(value==6){
      setTableType("ESaleOrderTable");
    }else{
      setTableType("SaleOrderTable")
    }
    setPage(0);
    setTabIndex(value);
    setSelectedEntries([])
    refreshTableData(1, limit, value && statusList[value - 1]?.value || "", false,value)

  };

  const handleCheckAll = () => {
    if (selectedEntries.length < salesOrderData.length) {
      let sT = salesOrderData.map((t) => ({
        _id: t._id,
        Sales_Id: t.Sales_Id,
        approvalStatus: t.approvalStatus,
        name: t.name,
        rejectedQuantity:t.rejectedQuantity,
        allocatedQuantity:t.allocatedQuantity,
        approvalQuantity:t.approvalQuantity
      }));
      
      setSelectedEntries(sT);
    } else {
      setSelectedEntries([]);
    }
  };

  const tableHeader = useSelector((state) => state.customHeader[tabIndex === 6 ? "EsaleTableHeader" : "saleTableHeader"]);
  //   let validHeaderIds =
  //     tableHeader && tableHeader.validHeader
  //       ? tableHeader.validHeader.map((h) => h.fieldKey)
  //       : [];
  let header =
    tableHeader && tableHeader.validHeader
      ? tableHeader.validHeader.map((h) => {
          if (h.handleType === "selectRow") {
            return (
              <Checkbox
                data-testid="checkbox"
                checked={
                  salesOrderData.length != 0 &&
                  selectedEntries.length == salesOrderData.length
                }
                onChange={handleCheckAll}
                size="small"
              />
            );
          }
          return h.fieldName;
        })
      : [];

 const handleShareFormClick = ()=>{
  setEsoFormVisible(true);
 }

  const getTableCell = (header, row, i) => {
    if (header?.handleType) {
      if (header?.handleType === "selectRow") {
        return (
          <Checkbox
            size="small"
            checked={selectedEntries.map((o) => o._id).indexOf(row._id) >= 0}
            onChange={(e) => handleCheckChange(e, row)}
          />
        );
      }
      else if (header?.handleType === "showDetail") {

        return (
          <Link
            to={`/dashboard/view-Sales/${tabIndex == 6 ? row.responseId : row.Sales_Id}?type=${tabIndex == 6 ? 'e-sales' : 'sales'}`}
            style={{
              ...textClasses.normalText,
              textDecoration: "none",
              color: theme.themeOrange,
              textAlign: "center",
            }}
          >
            {row[header?.mappingPath] ?? "-"}
          </Link>
        );
      }
      else if (header?.handleType === "action") {
        return (
          <Typography
            data-testid="dropdown"
            sx={{
              ...textClasses.normalText,
              color: theme.themeOrange,
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenAction(e, i)}
          >
            Action <KeyboardArrowDown />
          </Typography>
        );
      }
      else if (header?.handleType === "viewJobDetail" || header?.handleType === "viewTripDetail") {
        return (
          row[header?.mappingPath]?.length > 0 ? <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, cursor: "pointer" }}
            onClick={() => {
              setViewModal({ ...viewModal, type:header?.handleType, action: row?.Sales_Id || "" })
            }}>
            {row[header?.mappingPath]
              ? Array.isArray(row[header?.mappingPath])
                ? row[header?.mappingPath]?.length > 0 && row[header?.mappingPath].join(", ") || "-"
                : row[header?.mappingPath]
              : "-"
            }
          </Typography> : "-"
        )
      }
    }
     else if (header?.isFromRenderFunction === true) {
      let no;
      if (page === 0) {
        no = i + 1;
      } else {
        no = page * 10 + (i + 1);
      }
      if (header?.renderFunction === "srNo") {
        return (
          <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
            {no}
          </Typography>
        );
      } else if (header?.renderFunction === "showUser") {
        if (row?.lastActionBy == "") {
          return (

            <Typography
              sx={{
                ...textClasses.normalText,
                textAlign: "center",
                textTransform: "none",
              }}
            >
              {"-"}
            </Typography>
          );
        }
        else {
          return (

            <Typography
              sx={{
                ...textClasses.normalText,
                textAlign: "center",
                textTransform: "none",
              }}
            >
              {AllUserData?.data?.find(dl => dl?._id == row?.lastActionBy)?.name},{" "}
              {AllUserData?.data?.find(dl => dl?._id == row?.lastActionBy)?.designation}
            </Typography>
          );
        }
      } 
      else if (header?.renderFunction === "approvalStatus") {
        const statusColor = {
          dispatched: "#3E621E",
          inprogress: "#ff0c64",
          approved: "#FF7200",
          rejected: "#E9001A",
          drafted: "#FF0101",
          pending: "#3E621E",
          default: "#000",
        };
        const statusText = {
          dispatched: "Dispatched",
          inprogress: "Inprogress",
          approved: "Approved",
          rejected: "Rejected",
          drafted: "Drafted",
          pending: "Pending",
        };

        return (
          <>
            <Typography
              sx={{
                ...textClasses.normalText,
                textTransform: "capitalize",
                textAlign: "center",
                color:
                  statusColor[
                    row.approvalStatus
                      ? row.approvalStatus.toLowerCase()
                      : "default"
                  ],
              }}
            >
              {row.approvalStatus
                ? statusText[row.approvalStatus.toLowerCase()]
                : row.approvalStatus}
            </Typography>
          </>
        );
      }
    } else {
      if (header?.fieldType === "array") {
          return (
            <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
              {header.mappingPath && row[header.mappingPath]
                ? Array.isArray(row[header.mappingPath])
                  ? row[header.mappingPath].join(", ")
                  : row[header.mappingPath]
                : "-"}
            </Typography>
          );
      } else if (header?.fieldType === "date") {
        return (
          <Typography
            sx={{
              ...textClasses.normalText,
              textAlign: "center",
              textTransform: "none",
            }}
          >
            {header.mappingPath && row[header.mappingPath]
              ? moment(row[header.mappingPath]).format(
                  header?.format || "DD MMM YYYY hh:mm A"
                )
              : "-"}
          </Typography>
        );
      } 
      
      else {
        return (
          <Typography
            sx={{
              ...textClasses.normalText,
              textAlign: "center",
              textTransform: "none",
            }}
          >
            {header.mappingPath ? row[header.mappingPath] : "-"}
          </Typography>
        );
      }
    }
  };

  // const tableHeader = [<Checkbox data-testid="checkbox" checked={salesOrderData.length != 0 && selectedEntries.length == salesOrderData.length} onChange={handleCheckAll} size='small' />,"S.No","ASN ID", "W.O./P.O. Number", "Ordered QTY", "Status", "ASN Submission at", "ASN Received at", "Action"]
  const tableRows = salesOrderData.map((row, i) => {
    let items = [];

    const validHeaders = tableHeader?.validHeader ?? [];
    for (const header of validHeaders) {
      items.push(getTableCell(header, row, i));
    }

    return items;
  });

  ////("Table Header", validHeaderIds);
  ////("Table rows", tableRows);

  const handleCheckChange = (event, row) => {
    if (event.target.checked) {
      handleAddSelection(row);
    } else {
      handleRemoveSelection(row._id);
    }
  };

  const handleFilterChange = (data) => {
    ////(data);
  };

  const handleAddSelection = (obj) => {
    setSelectedEntries([...selectedEntries, obj]);
  };

  const handleRemoveSelection = (id) => {
    let oldEntries = [...selectedEntries];
    const i = oldEntries.map((o) => o._id).indexOf(id);
    if (i >= 0) {
      oldEntries.splice(i, 1);
      setSelectedEntries(oldEntries);
      if (oldEntries.length == 0) {
        handleCloseConfirmModal();
      }
    }
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(null);
  };

  const handleCloseActionConfirmModal = () => {
    setActionConfirmModal(null);
  };


  const handleCloseActionDispatchModal = () => {
    setActionDispatchModal(null);
  }

  const handleCloseDispatchModal = () => {
    setDispatchModal(null);
  }

  const handleClearFilter = () => {
    setFilter(initial_state)
    setPage(0);
    setLimit(10);
    if (tabIndex == 0) {
      setStatusFilter(null);
    }
  };

  // useEffect(() => {
  //     dispatch(getAllUsers({ "isAdminIncluded": true }));
  //     dispatch(
  //         getTemplateTypes()
  //     )
  // }, [])

  const handleApproveOrders = () => {
    let payload = {
      Sales_Id:
        selectedEntries.length == 1
          ? selectedEntries[0].Sales_Id
          : selectedEntries.map((e) => e.Sales_Id),
      "approved":true
    };
    dispatch(
      approveRejectSales(payload, (data) => {
        if (data) {
          refreshTableData(page + 1, limit)
          setSelectedEntries([]);
        }
      })
    );
  }

  const searchableFilterItems = useMemo(() => {
    if (!tableHeader || !tableHeader.validHeader) return [];
    return tableHeader.validHeader.filter((dl) => dl?.isSearchable) || [];
  }, [tableHeader]);

  const refreshTableData = (pageNo, limitNo,status = "", fetchCount = true,tab_Index,actionType) => {
    setLoader(true);
    const payload = {
      page: pageNo,
      limit: limitNo,
      approvalStatus: tab_Index<6 && status || ""
    };
    if (filter && Object.keys(filter).length > 0) {
      for (let key in filter) {
        if (key && Object.keys(filter[key]).length > 0) {
          Object.assign(payload, filter[key])
        }
      }
      // Process date filter items
      const dateFilterItems = searchableFilterItems?.filter(
        (item) => item?.searchInputType === "date"
      );

      dateFilterItems.forEach((element) => {
        const payloadKey = element?.payloadKey || element?.mappingPath || "";
        if (payloadKey && payload[payloadKey]) {
          payload[payloadKey] = multiDateToISOConvertor(payload[payloadKey]);
        }
      });

    }
    if (tab_Index == 6) {
      dispatch(
        getAllESalesOrder(payload, () => {
          setLoader(false);
        })
      );
    }
    else {
      dispatch(
        getAllSALESORDER(payload, () => {
          setLoader(false);
        })
      );
    }
    if (initialRender || tab_Index == 6) {
      dispatch(getESalesStatusCount({ approvalStatus: "" }))
      dispatch(getAllUsers({ isAdminIncluded: true }));
    }
    if (tab_Index < 6 || (tab_Index == 6 && actionType == "approve")) {
      dispatch(getSalesStatusCount({ type: "wms", status: "" }));
    }

  

    // Do not remove these, View ASN depends on these 2
    // dispatch({ type: FETCH_ASN_DETAIL, payload: { data: null, count: 0 } });
    // dispatch({ type: FETCH_TEMPLATE_HEADER, payload: null });
  };

  let fetchData = (fetchCount = false) => {
    refreshTableData(page + 1, limit, statusFilter?.value || "", fetchCount,tabIndex);
    setConfirmModal(null);
    setActionConfirmModal(null);
    setAnchorEl(null);
    setActionIndex(null);
  };

  useEffect(() => {

    fetchData(initialRender);
    if (initialRender) {
      setInitialRender(false)
    }

  }, [page, limit, filter.other, debouncedFilterSearch]);

  // useEffect(() => {
  //   fetchData();
  // }, [statusFilter, createdAt, receivedAt, page, limit]);

  // useEffect(() => {
  //   fetchData();
  // }, [debouncedIdFilter, debouncedSOFilter, debouncedJob_IDFilter]);

  return (
    <>
      {
        // !loader && templates.length == 0
        // ?
        //     <EmptyPage/>
        // :
        <>
          <Grid container>
            <Grid item sm={8}>
              <Typography sx={{ ...textClasses.cardTitle }}>
                Sales Order Processing
              </Typography>
            </Grid>
            <Grid container item sm={4} sx={{ display: 'flex', alignItems: "center", justifyContent: 'flex-end' }}>
            {permissions &&
              (permissions.permissions || []).find(
                (item) => item.permissionId === "createSalesOrder"
              ) ? (
                <Button
                data-testid="add"
                variant="outlined"
                size="small"
                startIcon={<Add />}
                sx={{ ...buttonClasses.lynkitOrangeEmpty }}
                onClick={() => history.push("/dashboard/createSalesOrder")}
              >
                Create New
              </Button>
            )
              : (
                null
              )}
            {permissions &&
              (permissions.permissions || []).find(
                (item) => item.permissionId === "shareSalesOrderProcessingForm"
              ) ? (
                <Button
                data-testid="share-ESOPFORM"
                variant="outlined"
                size="small"
                startIcon={<ShareSharpIcon />}
                sx={{ ...buttonClasses.lynkitBlackFill,marginLeft:'10px' }}
                onClick={handleShareFormClick}
              >
                Share e-SO Form
              </Button>

            ) : (
              null
            )}
            </Grid>
            
          </Grid>
          {esoFormVisible === true && 
          <ESOFormPage     
          open={esoFormVisible}
          hide={() => {
              setEsoFormVisible(false)
          }}
          // action={status}
          // item={item}
          >
            <h1>asds</h1></ESOFormPage>}

               {
            viewModal?.type=== "viewJobDetail" &&
            <ViewAssociatedJob
              viewModal={viewModal}
              setViewModal={setViewModal}
              AllUserData={AllUserData}
              />
          }
               {
            viewModal?.type=== "viewTripDetail" &&
            <ViewAssociatedTrip
              viewModal={viewModal}
              setViewModal={setViewModal}
              AllUserData={AllUserData}
              />
          }
          <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 1 }}>
            <Tabs
              value={tabIndex}
              aria-label="disabled tabs example"
              onChange={handleTabChange}
              sx={{
                "& .MuiTabs-indicator": { backgroundColor: theme.themeOrange },
                "& .MuiTab-root.Mui-selected": { color: theme.themeOrange },
              }}
            >
              <Tab
                label={`All Orders (${statusCount?.totalCount || 0})`}
                {...a11yProps(0)}
                sx={{ ...textClasses.normalText, textTransform: "none" }}
              />

              {statusList.map((s, i) => {
                return (
                  <Tab
                    data-testid={`${s.label}`}
                    label={`${s.label} (${
                      statusCount && statusCount[`${s.value}Count`]
                        ? statusCount[`${s.value}Count`]
                        : 0
                    })`}
                    {...a11yProps(1)}
                    sx={{ ...textClasses.normalText, textTransform: "none" }}
                    key={i}
                  />
                );
              })}
                <Tab
                label={`e-SO Form Response (${eStatusCount?.totalCount || 0})`}
                {...a11yProps(0)}
                sx={{ ...textClasses.normalText, textTransform: "none" }}
              />
            </Tabs>
          </Box>
          <Grid container spacing={2}>
          {renderHeadersFilter(tableHeader?.validHeader?.filter(dl => dl.isSearchable && dl?.mappingPath),filter,setFilter,setPage,setLimit,tabIndex,false,[0,6])}
            {isFilterApplied && (
              <Grid container item xs={"auto"} alignItems={"center"}>
                <Typography
                  sx={{
                    ...textClasses.boldText,
                    color: theme.themeOrange,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={handleClearFilter}
                >
                  Clear Filter(s)
                </Typography>
              </Grid>
            )}
            <Grid container item xs={"auto"} alignItems={"flex-start"}>
             <GenericCustomiseHeader
                tableName={tableType=="ESaleOrderTable"?"EsaleTableHeader":"saleTableHeader"} // (saleTableHeader)for sales table data
                tableType={tableType}  // (SaleOrderTable) for sales table data
              />
            </Grid>
          </Grid>
          {confirmModal && <ConfirmDialog
            status={confirmModal}
            handleClose={handleCloseConfirmModal}
            selectedEntries={selectedEntries}
            setSelectedEntries={setSelectedEntries}
            refreshTableData={(status) => refreshTableData(page + 1, limit,"",true,tabIndex,status)}
            handleRemoveSelection={handleRemoveSelection}
            tabIndex={tabIndex}
          />}
          {dispatchModal && <AllocateStockModalBox
            status={dispatchModal}
            entry={selectedEntries}
            handleClose={handleCloseDispatchModal}
          />}

          {selectedEntries.length > 0 && (
            <Grid container sx={{ mt: 2, textTransform: "none" }}>
              {selectedEntries.length == 1 && (
                <>
                  {(selectedEntries[0].status == "drafted" ||
                    (selectedEntries[0].status == "incoming" &&
                      selectedEntries[0].rejectedQuantity === 0)) && (
                    <Button
                      size="small"
                      variant="contained"
                      sx={{
                        ...buttonClasses.lynkitOrangeFill,
                        mr: 1,
                        textTransform: "none",
                      }}
                      onClick={() =>
                        history.push(
                          `/dashboard/data/edit-asn/${selectedEntries[0]._id}`
                        )
                      }
                    >
                      Edit
                    </Button>
                  )}
                {permissions &&
              (permissions.permissions || []).find(
                (item) => item.permissionId === "viewDetailSalesOrder"
              ) ? (
                <Button
                size="small"
                variant="contained"
                sx={{
                  ...buttonClasses.lynkitOrangeFill,
                  mr: 1,
                  textTransform: "none",
                }}
                onClick={() =>
                  history.push(
                    `/dashboard/View-Sales/${tabIndex === 6 ? selectedEntries[0]?.responseId : selectedEntries[0]?.Sales_Id}?type=${tabIndex === 6 ? 'e-sales' : 'sales'}`
                  )
                }
              >
                View
              </Button>
              ) : (
                ""
              )}

                  {/* {["pending"].indexOf(selectedEntries[0].approvalStatus) >=
                    0 && (''
                    // <Button
                    //   size="small"
                    //   variant="contained"
                    //   sx={{
                    //     ...buttonClasses.lynkitOrangeFill,
                    //     mr: 1,
                    //     backgroundColor: "#3E621E",
                    //     border: "1px solid #3E621E",
                    //     textTransform: "none",
                    //   }}
                    //   onClick={() => setConfirmModal("Approve")}
                    // >
                    //   Approve
                    // </Button>
                  )} */}
                </>
              )}


              {permissions &&
              (permissions.permissions || []).find(
                (item) => item.permissionId === "approveSalesOrder"
              ) ? <>
                {
                isSelectionRejectable && (
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      ...buttonClasses.approvedButton,                   
                    }}
                    // onClick={handleApproveOrders}
                    onClick={() => setConfirmModal("approve")}
                  >
                     Mark As Approved
                  </Button>
                )}
              </>
               : (
                ""
              )}

              {permissions &&
              (permissions.permissions || []).find(
                (item) => item.permissionId === "rejectSalesOrder"
              ) ? <>
                {
                 isSelectionRejectable && (
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    ...buttonClasses.lynkitOrangeFill,
                    backgroundColor: "#E9001A",
                    border: "1px solid #E9001A",
                    ml: 1,
                  }}
                  onClick={() => setConfirmModal("reject")}
                >
                  Reject
                </Button>
              )}
                
              </>
               : (
                ""
              )}

              {permissions &&
              (permissions.permissions || []).find(
                (item) => item.permissionId === "allocateSalesOrder"
              ) ? <>
                {
                  isSelectionAllocatable && (
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      ...buttonClasses.lynkitOrangeFill,
                      mr: 1,
                      backgroundColor: "#3E621E",
                      border: "1px solid #3E621E",
                      textTransform: "none",
                    }}
                    onClick={() => setDispatchModal("dispatch")}
                  >
                    Allocate Stock
                  </Button>
                )
              }
                
              </>
               : (
                ""
              )}
            </Grid>
          )}
          <Box container sx={{ mt: 2 }}>
            {loader ? (
              <Grid
                data-testid="loader"
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ p: 15 }}
              >
                <Grid item>
                  <GenericLoader />
                </Grid>
              </Grid>
            ) : (
              <>
                  <GenericTable
                    header={header}
                    rows={tableRows}
                    pageCount={totalRecords}
                    pageNumber={page}
                    handleChangePage={(event, pagevalue) => {
                      setPage(pagevalue);
                    }}
                    handleChangeRowsPerPage={(event) => {
                      setLimit(+event.target.value);
                    }}
                    rowsPerPage={limit}
                  />
                
              </>
            )}
          </Box>
          { actionConfirmModal && <ConfirmDialog
            status={actionConfirmModal}
            handleClose={handleCloseActionConfirmModal}
            selectedEntries={actionIndex != null ? [salesOrderData[actionIndex]]:[]}
            refreshTableData={(status) => refreshTableData(page + 1, limit,"",true,tabIndex,status)}
            tabIndex={tabIndex}
          />}
          {actionDispatchModal && <AllocateStockModalBox
            status={actionDispatchModal}
            entry={actionIndex != null ? [salesOrderData[actionIndex]] : []}
            handleClose={handleCloseActionDispatchModal}
          />}
          
          {actionIndex != null && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={actionIndex != null}
              onClose={handleCloseAction}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                display:
                  actionIndex != null ? "block !important" : "none !important",
              }}
            >
            {permissions?.permissions?.find((item) => item.permissionId === "viewDetailSalesOrder") 
            ? (
                <>
                <MenuItem
                key={1}
                sx={{ ...textClasses.normalText }}
                onClick={() =>
                  history.push(
                    `/dashboard/View-Sales/${tabIndex === 6 ? salesOrderData[actionIndex]?.responseId : salesOrderData[actionIndex]?.Sales_Id}?type=${tabIndex === 6 ? 'e-sales' : 'sales'}`
                  )
                }
              >
                View
              </MenuItem>
              {salesOrderData[actionIndex].approvalStatus == "pending" && (
                <>
                        <MenuItem
                          key={2}
                          sx={{ ...textClasses.normalText }}
                          onClick={() =>
                            setActionConfirmModal("approve")
                          }
                        >
                          Mark As Approved
                        </MenuItem>
                        <MenuItem
                          key={2}
                          sx={{ ...textClasses.normalText }}
                          onClick={() =>
                            setActionConfirmModal("reject")
                          }
                        >
                          Reject
                        </MenuItem>
                </>
              )}
                </>

              ) : (
                ""
              )}
            
              {permissions &&
              (permissions.permissions || []).find(
                (item) => item.permissionId === "allocateSalesOrder"
              ) ? <>
                  {
                    isSelectionAllocatableForAction(salesOrderData[actionIndex]) && 
                    (
                      <MenuItem
                        key={3}
                        sx={{ ...textClasses.normalText }}
                        onClick={() => setActionDispatchModal("dispatch")}
                      >
                        Allocate Stock
                      </MenuItem>
                    )
                  }
                
              </>
               : (
                ""
              )}
            
            </Menu>
            
          )}
        </>
      }
    </>
  );
}
