import React, { useCallback, useEffect, useRef,useMemo } from "react";
import { useState } from "react";
import {
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Button,
  TextField,
  Select,
  MenuItem,
  FormLabel,
  InputLabel,
  Typography,
  Tab,
  Tabs,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  Chip,
  Paper,
  IconButton,
  Menu,
  Tooltip,
  setRef,
  Autocomplete,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  Add,
  ArrowDownward,
  Cancel,
  Clear,
  Delete,
  ExpandMore,
  KeyboardArrowDown,
  Search,
  WrongLocation,
} from "@mui/icons-material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTemplates,
  getAllASN,
  getASNStatusCount,
  rejectAsn,
  closeAsn,
  getTemplateHeader,
  approveASNArrival,
  approveASNDataEntry
} from "../../../../redux/actions";
import theme, {
  buttonClasses,
  inputClasses,
  textClasses,
} from "../../../../static/styles/theme";
import { Link } from "react-router-dom";
import {
  capitalizeSentence,
  showToast,dataReadFunction,
} from "../../../../services/functions";
import { useForm } from "react-hook-form";
import history from "../../../../services/history";
import { FETCH_ASN_DETAIL, FETCH_TEMPLATE_HEADER } from "../../../../redux/types";
import GenericCustomiseHeader from "../../../Components/Generic/GenericCustomiseHeader";
import GenericTable from "../../../Components/CustomTable/GenericTable";
import { useDebounce } from "../../../../utils/hooks";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import CloseDialog from "./CloseDialog";
import { renderHeadersFilter } from "../../../../services/functions";
import { multiDateToISOConvertor } from "../../../Components/Generic/GenericMultiDateRange";
import ReceiveASNDialogBox from "../../DataEntry/Asn/ReceiveASNDialogBox";
import MultiASNReceivingDialogBox from "../../DataEntry/MultiASNReceiving/MultiASNReceivingDialogBox";


const ConfirmDialog = ({
  status,
  handleClose,
  selectedEntries,
  setSelectedEntries,
  refreshTableData,
  handleRemoveSelection,
}) => {
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState(false);
  const size = selectedEntries.length;
  const [reason, setReason] = useState("");
  const [dataReceived,isDataReceived]= useState(false)

  const callBack = (data) => {
    // showToast(data.message, !data.success);
    isDataReceived(false);
    if (data.success) {
      refreshTableData();
      handleClose();
      if (setSelectedEntries) {
        setSelectedEntries([]);
      }
    }
  };

  const handleStatusChange = () => {
    isDataReceived(true)
    let payload = {
      ASN_Id:
        selectedEntries?.length == 1
          ? selectedEntries[0].ASN_Id
          : selectedEntries?.map((e) => e.ASN_Id),
      reason: reason,
    };
    dispatch(
      rejectAsn(payload, (data) => {
        if (data) {
          callBack(data);
        }
      })
    );
  };

  useEffect(() => {
    setReason("");
  }, [status]);

  return (
    <Dialog
      open={status != null}
      onClose={handleClose}
      scroll={"paper"}
      PaperProps={{ sx: { width: "100%" } }}
      maxWidth={"sm"}
    >
      <DialogContent>
        <Grid container justifyContent={"center"} alignItems="center">
          <Typography sx={{ ...textClasses.cardTitle }}>
            Are you Sure ?
          </Typography>
        </Grid>
        <Grid container justifyContent={"center"} alignItems="center">
          <Typography sx={{ ...textClasses.cardTitle }}>
            You want to {status} the ASN
          </Typography>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={1}>
          {selectedEntries?.slice(0, showAll ? size : 5)?.map((st, i) => {
            return (
              <Grid item sm={4} xs={6} key={i}>
                <Grid
                  container
                  direction={"row"}
                  justifyContent="center"
                  alignItems={"center"}
                  sx={{
                    backgroundColor: theme.themeLightOrange,
                    border: `1px solid ${theme.themeOrange}`,
                    borderRadius: "12px",
                    pl: 1,
                    pr: 1,
                    minHeight: "40px",
                  }}
                >
                  <Grid item sm={10} xs={8}>
                    <Typography
                      sx={{
                        ...textClasses.normalText,
                        color: theme.themeOrange,
                        textAlign: "start",
                      }}
                    >
                      {st.ASN_Id && st.ASN_Id.length > 15
                        ? st.ASN_Id.substring(0, 15) + "..."
                        : st.ASN_Id}
                    </Typography>
                  </Grid>
                  {handleRemoveSelection && (
                    <Grid item sm={2} xs={4}>
                      <IconButton
                        sx={{ alignContent: "right" }}
                        onClick={() => handleRemoveSelection(st._id)}
                      >
                        <Cancel sx={{ color: theme.themeOrange }} />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            );
          })}
          {size > 5 && (
            <>
              <Grid
                container
                item
                sm={4}
                xs={6}
                justifyContent="center"
                alignItems={"center"}
              >
                <Typography
                  sx={{
                    ...textClasses.boldText,
                    color: theme.themeOrange,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowAll(!showAll)}
                >
                  {showAll ? <>Collapse</> : <>View All ({size})</>}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Grid container direction={"row"} sx={{ mt: 1 }}>
          <Typography sx={{ ...textClasses.boldText }}>Reason*</Typography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </Grid>
        <Grid container sx={{ p: 1, mt: 3 }}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              ...buttonClasses.lynkitBlackFill,
              height: "40px",
              minWidth: "150px",
            }}
            onClick={handleClose}
          >
            No, Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              ml: 1,
              ...buttonClasses.small,
              backgroundColor: theme.themeOrange,
              minWidth: "150px",
            }}
            disabled={selectedEntries.length <= 0 || dataReceived}
            onClick={handleStatusChange}
          >
            Yes, {status}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const getSpentTime = (then, now) => {
  if (then != null) {
    then = moment(then);
    now = now ? moment(now) : moment();

    let dayDiff = now.diff(then, "days");
    let hourDiff = now.diff(then, "hours");
    let minDiff = now.diff(then, "minutes");
    let timeSpent;

    if (dayDiff < 1) {
      if (hourDiff < 1) {
        timeSpent = `${minDiff} min ago`;
      } else {
        timeSpent = `${hourDiff} hrs ago`;
      }
    } else {
      timeSpent = `${dayDiff} d ago`;
    }
    return timeSpent;
  } else {
    return "-";
  }
};


let initial_state = {
  "search": {},
  "other": {},
}

export default function AdvanceShipment() {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.templates.allTemplates);
  const adjustments = useSelector(
    (state) => state.inventoryAdjustment.allAdjustments
  );
  // const totalRecords = useSelector(state => state.templates.totalRows)
  const advanceShipment = useSelector((state) => state.asnEntry.allASN);
  const totalRecords = useSelector((state) => state.asnEntry.totalRows);
  const allLabelOptions = useSelector((state) => state.asnEntry.allLabelOptions);

  const statusCount = useSelector((state) => state.asnEntry.statusCount);
  const { AllUserData } = useSelector((state) => state.users);
  const allTemplateTypes = useSelector(
    (state) => state.templates.templateTypes
  );
  const [tabIndex, setTabIndex] = useState(0);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loader, setLoader] = useState(false);

  const [filter, setFilter] = useState({...initial_state})
  const [statusFilter, setStatusFilter] = useState(null);
  const statusList = [
    { label: "Under Approval", value: "underApproval" },
    { label: "Incoming", value: "incoming" },
    // { label: "Partial Arrived", value: "partialArrived"},
    { label: "Arrived", value: "arrived" },
    { label: "Partially Received", value: "partialReceived" },
    { label: "Received", value: "received" },
    { label: "Rejected", value: "rejected" },
    { label: "Drafted", value: "drafted" },
  ];
  const debouncedFilterSearch = useDebounce(filter?.search, 500);

  const isFilterApplied = (() => {
    if (tabIndex == 0 && statusFilter) {
      return true;
    }
    if (Object.values(filter["search"])?.some(dl=>dl && dl.length>0) || Object.values(filter["other"]).some(dl=>dl && dl.length>0)) {
      return true;
    }
    return false;
  })();

  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState(null);
  const openAction = Boolean(anchorEl);

  const { userPermissions } = useSelector((state) => state.userPermission);
  let permissions = userPermissions || {};

  const handleOpenAction = (event, i) => {
    setAnchorEl(event.currentTarget);
    setActionIndex(i);
  };
  const handleCloseAction = () => {
    setActionConfirmModal(null);
    setActionIndex(null);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1);
  };
  const [selectedEntries, setSelectedEntries] = useState([]);
  ////("Selected Entries", selectedEntries);

  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [confirmModal, setConfirmModal] = useState(null);
  const [closeModal, setCloseModal] = useState(null);
  const [actionConfirmModal, setActionConfirmModal] = useState(null);
  const [receiveModal, setReceiveModal] = useState(null);
  const [multiReceiveModal, setMultiReceiveModal] = useState(null);
  const [actionReceiveModal, setActionReceiveModal] = useState(null);
  const [closeReason, setCloseReason] = useState("")

  const isSelectionRejectable = (() => {
    let flag = true;
    if (
      selectedEntries.filter((st) => ["incoming","arrived","underApproval"].indexOf(st.status) < 0)
        .length > 0
    ) {
      flag = false;
    }
    return flag;
  })();

 

  const handleTabChange = (event, value) => {
    if (value === 0) {
      setStatusFilter(null);
    }
    else if (value >= 1 && value <= statusList.length) {
      setStatusFilter(statusList[value - 1]);
    }
    setPage(0);
    setTabIndex(value);
  };

  const handleCheckAll = () => {
    if (selectedEntries.length < advanceShipment.length) {
      let sT = advanceShipment?.map((t) => ({
        _id: t._id,
        status: t.status,
        name: t.name,
        ASN_Id: t.ASN_Id,
        totalQuantity: t.totalQuantity,
        receivedQuantity: t.receivedQuantity,
        rejectedQuantity: t.rejectedQuantity,
        remainingQuantity: t.remainingQTY,
        closedQuantity: t.closedQuantity,
        exceedQuantity: t.exceedQuantity,
      }));
      setSelectedEntries(sT);
    }
    else {
      setSelectedEntries([]);
    }
  };


  const isActionEnable = (row) => {
    let isEnable=false
    if (row?.status == "drafted") {
      isEnable= permissions?.permissions?.some((item) => ["viewASN","updateASN"].includes( item.permissionId))
    }
    else if (row?.status == "underApproval") {
      isEnable= permissions?.permissions?.some((item) => ["viewASN","updateASN","acceptRejectASN"].includes( item.permissionId))
    }
    else if (row?.status == "incoming") {
      isEnable= permissions?.permissions?.some((item) => ["viewASN","updateASN","acceptRejectASN"].includes( item.permissionId))
    }
    else if (row?.status == "arrived") {
      isEnable= permissions?.permissions?.some((item) => ["viewASN","acceptRejectASN","asnReceiving"].includes( item.permissionId))
    }
    else if (row?.status == "rejected") {
      isEnable= permissions?.permissions?.some((item) => item.permissionId === "viewASN")
    }
    else if (row?.status == "partialReceived") {
      isEnable= permissions?.permissions?.some((item) => ["viewASN","acceptRejectASN","asnReceiving"].includes( item.permissionId))
    }
    else if (row?.status == "received") {
      isEnable= permissions?.permissions?.some((item) => item.permissionId === "viewASN")
    }
    else {
      isEnable= false
    }
    return isEnable
  };
  
  

  const tableHeader = useSelector((state) => state.customHeader["asnHeader"]);
 
  let header = tableHeader && tableHeader?.validHeader?.length > 0
      ? tableHeader?.validHeader?.map((h) => {
        if (h.handleType === "selectRow") {
          return (
            <Checkbox
              data-testid="checkbox"
              checked={
                advanceShipment.length != 0 &&
                selectedEntries.length == advanceShipment.length
              }
              onChange={handleCheckAll}
              size="small"
            />
          )
        }
        return h.fieldName
      })
      : [];

  // const filterIndex = {
  //   ASN_Id:
  //     tableHeader && tableHeader?.validHeader?.length > 0
  //       ? tableHeader?.validHeader?.map((h) => h.mappingPath).indexOf(`ASN_Id`)
  //       : -1,
  //   purchaseOrderID:
  //     tableHeader && tableHeader?.validHeader?.length > 0
  //       ? tableHeader?.validHeader?.map((h) => h.mappingPath).indexOf(`purchaseOrderID`)
  //         : -1,
  //   product_IDs:
  //     tableHeader && tableHeader?.validHeader?.length > 0
  //       ? tableHeader?.validHeader?.map((h) => h.mappingPath).indexOf(`product_IDs`)
  //       : -1,
  //   status:
  //     tableHeader && tableHeader?.validHeader?.length > 0
  //       ? tableHeader?.validHeader?.map((h) => h.mappingPath).indexOf(`status`)
  //       : -1,
  //   receivedDate:
  //     tableHeader && tableHeader?.validHeader?.length > 0
  //       ? tableHeader?.validHeader?.map((h) => h.mappingPath).indexOf(`receivedDate`)
  //       : -1,
  //   submissionDate:
  //     tableHeader && tableHeader?.validHeader?.length > 0
  //       ? tableHeader?.validHeader?.map((h) => h.mappingPath).indexOf(`submissionDate`)
  //       : -1,
  // };


  const getTableCell = (header, row, i) => {
    let value = ""
    if (header?.mappingPath) {
      value = dataReadFunction({ name: header?.mappingPath || "" }, row)
      if ((!value || (Array.isArray(value) || value.length == 0)) && header?.mappingPathKeys?.length > 0) {
        for (let ele of header?.mappingPathKeys) {
          value = dataReadFunction({ name: ele || "" }, row)
          if (value && (Array.isArray(value) ? value.length > 0 : true)) {
            break;
          }
        }
      }
    }

    if (!value) {
      if (header?.fieldType == "number") value = "0"
      else value = ""
    }

    if (header?.handleType) {
      if (header?.handleType === "selectRow") {
        return (
          <Checkbox
            size="small"
            checked={selectedEntries?.map((o) => o._id).indexOf(row._id) >= 0}
            onChange={(e) => handleCheckChange(e, row)}
          />
        );
      }
      else if (header?.handleType === "showDetail") {
        return (
          <Link
            to={`/dashboard/data/view-asn/${row.ASN_Id}`}
            style={{
              ...textClasses.normalText,
              textDecoration: "none",
              color: theme.themeOrange,
              textAlign: "center",
            }}
          >
            {value ?? "-"}
          </Link>
        );
      }
      else if (header?.handleType === "action") {
        if(isActionEnable(row)){
          return (
            <Typography
              data-testid="dropdown"
              sx={{
                ...textClasses.normalText,
                color: theme.themeOrange,
                cursor: "pointer",
              }}
              onClick={(e) => handleOpenAction(e, i)}
            >
              Action <KeyboardArrowDown />
            </Typography>
          );
        }
        return "-"
      }
    }
    else if (header?.isFromRenderFunction === true) {
      if (header?.renderFunction === "srNo") {
        return (
          <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
            {page == 0 ? i + 1 : (page * limit) + (i + 1)}
          </Typography>
        );
      } else if (header?.renderFunction === "status") {
        const statusColor = {
          partialreceived: "#3E621E",
          received: "#3E621E",
          rejected: "#E9001A",
          drafted: "#FF0101",
          incoming: "#FF7200",
          arrived: "#FF7200",
          underapproval:"#FF7200",
          default: "#000",
        };
        const statusText = {
          partialreceived: "Partially Received",
          received: "Received",
          rejected: "Rejected",
          drafted: "Drafted",
          incoming: "Incoming",
          arrived: "Arrived",
          underapproval: "Under Approval"
        };

        return (
          <>
            {row.status == "drafted" ? (
              <Typography
                sx={{
                  ...textClasses.normalText,
                  textAlign: "center",
                  color:
                    statusColor[
                    row.status ? row.status.toLowerCase() : "default"
                    ],
                }}
              >
                {row.status ? statusText[row.status.toLowerCase()] : "-"}
              </Typography>
            ) : (
              <></>
            )}
            {row.status == "incoming" || row.status == "arrived"? (
              <Typography
                sx={{
                  ...textClasses.normalText,
                  textAlign: "center",
                  color:
                    statusColor[
                    row.status ? row.status.toLowerCase() : "default"
                    ],
                }}
              >
                {row.status ? statusText[row.status.toLowerCase()] : "-"} (
                {row.totalQuantity})
              </Typography>
            ) : (
              <></>
            )}
            {row.status == "underApproval" ? (
              <Typography
                sx={{
                  ...textClasses.normalText,
                  textAlign: "center",
                  color:
                    statusColor[
                    row.status ? row.status.toLowerCase() : "default"
                    ],
                }}
              >
                {row.status ? statusText[row.status.toLowerCase()] : "-"} (
                {row.totalQuantity})
              </Typography>
            ) : (
              <></>
            )}
            {row.status == "received" || row.status == "partialReceived" ? (
              <Typography
                sx={{
                  ...textClasses.normalText,
                  textAlign: "center",
                  color:
                    statusColor[
                    row.status ? row.status.toLowerCase() : "default"
                    ],
                }}
              >
                {row.status ? statusText[row.status.toLowerCase()] : "-"} (
                {row.receivedQuantity})
              </Typography>
            ) : (
              <></>


            )}
            {row.status == "rejected" ? (
              <Typography
                sx={{
                  ...textClasses.normalText,
                  textAlign: "center",
                  color:
                    statusColor[
                    row.status ? row.status.toLowerCase() : "default"
                    ],
                }}
              >
                {row.status ? statusText[row.status.toLowerCase()] : "-"}
              </Typography>
            ) : (
              <></>
            )}
          </>
        );
      }
    }
    else {
      if (header?.fieldType === "array") {
        return (
          <Tooltip title={value ? (Array.isArray(value) ? value.join(", ") : value) : "-"} placement="top">
            <Typography
              sx={{
                ...textClasses.normalText,
                textAlign: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "280px", // Adjust maxWidth as needed
              }}
            >
              {header.mappingPath && value
                ? Array.isArray(value)
                  ? value.join(", ") // Join array with a comma
                  : value
                : "-"}{" "}
              {value && header.suffix ? header.suffix : ""}
            </Typography>
          </Tooltip>
        );
      
      } 
      else if (header?.fieldType === "date") {
        return (
          <Typography
            sx={{
              ...textClasses.normalText,
              textAlign: "center",
              textTransform: "none",
            }}
          >
            {header.mappingPath && value
              ? moment(value).format(
                header?.format || "DD MMM YYYY hh:mm A"
              )
              : "-"} {value && header.suffix ? header.suffix : ""}
          </Typography>
        );
      } else {
        let readkey=header?.mappingPath?.split(".")?.pop()
        if (header.dynamic && value && row?.formDataDynamicLabel && row?.formDataDynamicLabel[value]) value = row?.formDataDynamicLabel[value]
        else if (value) {
          let fieldOption = allLabelOptions?.find(x => x._id == readkey)
          if (fieldOption?.values?.length > 0 && fieldOption?.values?.find(x => x.value == value)){
            value = fieldOption?.values?.find(x => x.value == value)?.label
          }
        }
        return (
          <Typography
            sx={{
              ...textClasses.normalText,
              textAlign: "center",
              textTransform: "none",
            }}
          >
            {header.mappingPath ? value : "-"} {value && header.suffix ? header.suffix : ""}
          </Typography>
        );
      }
    }
  };

  // const tableHeader = [<Checkbox data-testid="checkbox" checked={advanceShipment.length != 0 && selectedEntries.length == advanceShipment.length} onChange={handleCheckAll} size='small' />,"S.No","ASN ID", "W.O./P.O. Number", "Ordered QTY", "Status", "ASN Submission at", "ASN Received at", "Action"]
  const tableRows = advanceShipment?.map((row, i) => {
    let items = [];

    const validHeaders = tableHeader?.validHeader ?? [];
    for (const header of validHeaders) {
      items.push(getTableCell(header, row, i));
    }

   
    return items;
  });
  const handleCheckChange = (event, row) => {
    if (event.target.checked) {
      handleAddSelection(row);
    } else {
      handleRemoveSelection(row._id);
    }
  };



  const handleAddSelection = (obj) => {
    setSelectedEntries([...selectedEntries, obj]);
  };

  const handleRemoveSelection = (id) => {
    let oldEntries = [...selectedEntries];
    const i = oldEntries?.map((o) => o._id)?.indexOf(id);
    if (i >= 0) {
      oldEntries.splice(i, 1);
      setSelectedEntries(oldEntries);
      if (oldEntries.length == 0) {
        handleCloseConfirmModal();
      }
    }
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(null);
  };

  const handleCloseReceiveModal = () => {
    setReceiveModal(null);
  };
  const handleCloseMultiReceiveModal = () => {
    setMultiReceiveModal(null);
  };

  const handleCloseActionReceiveModal = () => {
    setActionReceiveModal(null);
  };

  const handleClearFilter = () => {
    setFilter({...initial_state})
    setPage(0);
    setLimit(10);
    if (tabIndex == 0) {
      setStatusFilter(null);
    }
  };

  // useEffect(() => {
  //     dispatch(getAllUsers({ "isAdminIncluded": true }));
  //     dispatch(
  //         getTemplateTypes()
  //     )
  // }, [])
  const searchableFilterItems = useMemo(() => {
    if (!tableHeader || !tableHeader.validHeader) return [];
    return tableHeader.validHeader.filter((dl) => dl?.isSearchable) || [];
  }, [tableHeader]);

  const refreshTableData = (pageNo, limitNo) => {
    setLoader(true);
    const payload = {
      page: pageNo,
      limit: limitNo,
      fetchlabelOption:true,
    };
    if (filter && Object.keys(filter).length > 0) {
      let allDynamicKeys = tableHeader?.validHeader?.filter(dl => dl?.dynamic)?.map(dl => dl?.payloadKey || dl?.mappingPath)
      for (let key in filter) {
        if (key && Object.keys(filter[key] || {}).length > 0) {
          for (let filterKey in filter[key]) {
            // if (filterKey && Object.keys(filter[key][filterKey] || {}).length > 0) {
            if (allDynamicKeys?.includes(filterKey)) {
              // Object.assign(payload, filter[key][filterKey])
              payload[filterKey] = filter[key][filterKey]?.value || ""
            } else {
              payload[filterKey] = filter[key][filterKey] || ""
            }
          }
        }
      }
      // Process date filter items
      const dateFilterItems = searchableFilterItems?.filter(
        (item) => item?.searchInputType === "date"
      );

      dateFilterItems.forEach((element) => {
        const payloadKey = element?.payloadKey || element?.mappingPath || "";
        if (payloadKey && payload[payloadKey]) {
          payload[payloadKey] = multiDateToISOConvertor(payload[payloadKey]);
        }
      });
    }
    if (statusFilter != null) {
      payload["status"] = statusFilter.value;
    }

    dispatch(
      getAllASN(payload, () => {
        setLoader(false);
      })
    );
    payload.status = "";
    dispatch(getASNStatusCount(payload));

    // Do not remove these, View ASN depends on these 2
    dispatch({ type: FETCH_ASN_DETAIL, payload: { data: null, count: 0 } });
    dispatch({ type: FETCH_TEMPLATE_HEADER, payload: null });
  };

  let fetchData = () => {
    refreshTableData(page + 1, limit);
    setSelectedTemplates([]);
    setConfirmModal(null);
    setReceiveModal(null);
    setActionConfirmModal(null);
    setAnchorEl(null);
    setActionIndex(null);
  };

  // useEffect(() => {
  //   fetchData();
  // }, [statusFilter, createdAt, receivedAt, page, limit]);

  // useEffect(() => {
  //   fetchData();
  // }, [debouncedIdFilter, debouncedPoFilter, debouncedProduct_IDFilter]);

  useEffect(() => {

    fetchData();
  }, [page, limit, filter.other, debouncedFilterSearch,statusFilter]);

  const handleCloseModal = useCallback(() => {
    setCloseModal(null)
    setCloseReason("")
  }, [])

  const callBack = (data) => {
    refreshTableData();
    handleCloseModal();
    if (setSelectedEntries) {
      setSelectedEntries([]);
    }
  };

  const handleCloseConfirm = (operationType = "CloseASNShipment") => {
    let payload = {
      ASN_Id: actionIndex !== null ? advanceShipment[actionIndex].ASN_Id : selectedEntries.length == 1
        ? selectedEntries[0].ASN_Id
        : selectedEntries?.map((e) => e.ASN_Id),
    };
    if (operationType == "ArrivalASNShipment") {
      payload["arrivalType"] = "ASN"
      dispatch(
        approveASNArrival(payload, (data) => {
          if (data) {
            callBack(data);
          }
        })
      );
    }
    else if(operationType == "ApproveASNShipment"){
      payload["arrivalType"] = "ASN"
      dispatch(
        approveASNDataEntry(payload, (data) => {
          if (data) {
            callBack(data);
          }
        })
      );
    }
    else {
      payload = {
        ...payload,
        closingType: "ASN",
        reason: closeReason,
      };
      dispatch(
        closeAsn(payload, (data) => {
          if (data) {
            callBack(data);
          }
        })
      );
    }
  };

  return (
    <>
      {
        <>
          <Grid container sx={{display:'flex', justifyContent:"space-between"}}>
            <Grid item sm={6}>
              <Typography sx={{ ...textClasses.cardTitle }}>
                Advance Shipment Notice
              </Typography>
            </Grid>
            <Grid container item sm={6} justifyContent="end">
                {permissions &&
                  (permissions.permissions || []).some(
                    (item) => item.permissionId === "packingListReceiving"
                  ) ? (
                  <Button
                    data-testid="add"
                    variant="outlined"
                    size="small"
                    // startIcon={<Add />}
                    sx={{ ...buttonClasses.lynkitOrangeEmpty, mr:2 }}
                    onClick={() => setMultiReceiveModal("Receive")}
                    >
                    Multiple ASN Receiving
                  </Button>
                ) : (
                  ""
                )}
                {permissions &&
                  (permissions.permissions || []).some(
                    (item) => item.permissionId === "createASN"
                  ) ? (
                  <Button
                    data-testid="add"
                    variant="outlined"
                    size="small"
                    startIcon={<Add />}
                    sx={{ ...buttonClasses.lynkitOrangeEmpty }}
                    onClick={() => history.push("/dashboard/data/asn")}
                  >
                    Create New
                  </Button>
                ) : (
                  ""
                )}
            
            </Grid>
          </Grid>
          <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 1 }}>
            <Tabs
              value={tabIndex}
              aria-label="disabled tabs example"
              onChange={handleTabChange}
              sx={{
                "& .MuiTabs-indicator": { backgroundColor: theme.themeOrange },
                "& .MuiTab-root.Mui-selected": { color: theme.themeOrange },
              }}
            >
              <Tab
                label={`All ASN (${statusCount && statusCount.totalCount
                    ? statusCount.totalCount
                    : 0
                  })`}
                {...a11yProps(0)}
                sx={{ ...textClasses.normalText, textTransform: "none" }}
              />
              {statusList?.map((s, i) => {
                return (
                  <Tab
                    data-testid={`${s.label}`}
                    label={`${s.label} (${statusCount &&
                        statusCount[
                        `${s.value == "incoming" ? "upcomming" : s.value}Count`
                        ]
                        ? statusCount[
                        `${s.value == "incoming" ? "upcomming" : s.value
                        }Count`
                        ]
                        : 0
                      })`}
                    {...a11yProps(1)}
                    sx={{ ...textClasses.normalText, textTransform: "none" }}
                    key={i}
                  />
                  // <Tab  data-testid= {`${s.label}`} label={`${s.label} (${statusCount && statusCount[`${s.value}Count`] ? statusCount[`${s.value}Count`]: 0})`} {...a11yProps(1)} sx={{ ...textClasses.normalText, textTransform: "none" }} key={i} />
                );
              })}
            </Tabs>
          </Box>
          <Grid container spacing={2}>

            {renderHeadersFilter(tableHeader?.validHeader?.filter(dl => dl.isSearchable && dl?.mappingPath),filter,setFilter,setPage,setLimit,tabIndex)}
            {isFilterApplied && (
              <Grid container item xs={"auto"} alignItems={"center"}>
                <Typography
                  sx={{
                    ...textClasses.boldText,
                    color: theme.themeOrange,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={handleClearFilter}
                >
                  Clear Filter(s)
                </Typography>
              </Grid>
            )}
            <Grid container item xs={"auto"} alignItems={"center"}>
              <GenericCustomiseHeader
                tableName={"asnHeader"}
                tableType={"ASNTable"}
              />
            </Grid>
          </Grid>
          <ConfirmDialog
            status={confirmModal}
            handleClose={handleCloseConfirmModal}
            selectedEntries={selectedEntries}
            setSelectedEntries={setSelectedEntries}
            refreshTableData={() => refreshTableData(page + 1, limit)}
            handleRemoveSelection={handleRemoveSelection}
          />
          {receiveModal && <ReceiveASNDialogBox
            status={receiveModal}
            entry={selectedEntries.length > 0 ? selectedEntries[0] : null}
            handleClose={handleCloseReceiveModal}
          />}
          {multiReceiveModal && <MultiASNReceivingDialogBox
            status={multiReceiveModal}
            handleClose={handleCloseMultiReceiveModal}
          />}
          {selectedEntries.length > 0 && (
            <Grid container sx={{ mt: 2, textTransform: "none" }}>
              {selectedEntries.length == 1 && (
                <>
                  {permissions?.permissions?.some((item) => item.permissionId === "updateASN") &&
                    (["incoming", "drafted", "underApproval"].includes(selectedEntries[0].status) &&
                      selectedEntries[0].rejectedQuantity === 0)
                    && (
                      <Button
                        size="small"
                        variant="contained"
                        sx={{
                          ...buttonClasses.lynkitOrangeFill,
                          mr: 1,
                          textTransform: "none",
                        }}
                        onClick={() =>
                          history.push(
                            `/dashboard/data/edit-asn/${selectedEntries[0]._id}`
                          )
                        }
                      >
                        Edit
                      </Button>
                    )}

                  {permissions?.permissions?.some((item) => item.permissionId === "viewASN") && <Button
                    size="small"
                    variant="contained"
                    sx={{
                      ...buttonClasses.lynkitOrangeFill,
                      mr: 1,
                      textTransform: "none",
                    }}
                    onClick={() =>
                      history.push(
                        `/dashboard/data/view-asn/${selectedEntries[0].ASN_Id}`
                      )
                    }
                  >
                    View
                  </Button>}
                  {
                  selectedEntries.some((entry) => ["arrived", "partialReceived"].includes(entry.status)) &&
                    permissions?.permissions?.some((item) => item.permissionId === "asnReceiving") &&
                    (
                      <Button
                        size="small"
                        variant="contained"
                        sx={{
                          ...buttonClasses.lynkitOrangeFill,
                          mr: 1,
                          backgroundColor: "#3E621E",
                          border: "1px solid #3E621E",
                          textTransform: "none",
                        }}
                        onClick={() => setReceiveModal("receive")}
                      >
                        Receive
                      </Button>
                    )}
                </>
              )}

              {selectedEntries.some((entry) => entry.status === "underApproval") &&
                permissions?.permissions?.some((item) => item.permissionId === "acceptRejectASN") &&
                (
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      ...buttonClasses.approvedButton,
                      mr: 1,
                    }}
                    onClick={() => setCloseModal("ApproveASNShipment")}
                  >
                    Mark As Approved
                  </Button>
                )}
              {selectedEntries.some((entry) => entry.status === "incoming") &&
                permissions?.permissions?.some((item) => item.permissionId === "acceptRejectASN") &&
                (
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      ...buttonClasses.lynkitOrangeFill,
                      mr: 1,
                      backgroundColor: "#3E621E",
                      border: "1px solid #3E621E",
                      textTransform: "none",
                    }}
                    onClick={() => setCloseModal("ArrivalASNShipment")}
                  >
                    Mark As Arrived
                  </Button>
                )}
              {permissions?.permissions?.some((item) => item.permissionId === "closeASN") && 
              selectedEntries.some((entry) => entry.status === "partialReceived")
                && (
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      ...buttonClasses.lynkitOrangeFill,
                      mr: 1,
                      backgroundColor: "#3E621E",
                      border: "1px solid #3E621E",
                      textTransform: "none",
                    }}
                    onClick={() => setCloseModal("CloseASNShipment")}
                  >
                    Close
                  </Button>
                )}

              {isSelectionRejectable && permissions?.permissions?.some((item) => item.permissionId === "acceptRejectASN") && (
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    ...buttonClasses.lynkitOrangeFill,
                    backgroundColor: "#E9001A",
                    border: "1px solid #E9001A",
                    mr: 1,
                  }}
                  onClick={() => setConfirmModal("Reject")}
                >
                  Reject
                </Button>
              )}
            </Grid>
          )}
          <Box container sx={{ mt: 2 }}>
            {
              loader ? (
                <Grid
                  data-testid="loader"
                  container
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems="center"
                  sx={{ p: 15 }}
                >
                  <Grid item>
                    <GenericLoader />
                  </Grid>
                </Grid>
              ) : (
                <GenericTable
                  data-testid="asn-table"
                  header={header}
                  rows={tableRows}
                  pageCount={totalRecords}
                  pageNumber={page}
                  handleChangePage={(event, pagevalue) => {
                    setPage(pagevalue);
                  }}
                  handleChangeRowsPerPage={(event) => {
                    setLimit(+event.target.value);
                  }}
                  rowsPerPage={limit}
                />
              )
              // <TemplatesTable
              //     header={header}
              //     rows={tableRows}
              //     totalRows={totalRecords}
              //     page={page}
              //     limit={limit}
              //     setPage={setPage}
              //     setLimit={setLimit}
              // />
            }
          </Box>
          {actionConfirmModal && <ConfirmDialog
            status={actionConfirmModal}
            handleClose={handleCloseAction}
            selectedEntries={
              actionIndex != null ? [advanceShipment[actionIndex]] : []
            }
            refreshTableData={() => refreshTableData(page + 1, limit)}
          />}
         { closeModal && <CloseDialog
            status={closeModal}
            closeReason={closeReason}
            handleConfirm={()=>handleCloseConfirm(closeModal)}
            setCloseReason={setCloseReason}
            subTitle={
              closeModal === "ArrivalASNShipment"
                ? "You want to approve the arrival of ASN."
                : closeModal === "ApproveASNShipment"
                ? "You want to approve the data entry of ASN."
                : "You want to close the further receiving."
            }            
            handleClose={handleCloseModal}
            selectedEntries={
              actionIndex !== null
                ? [advanceShipment[actionIndex]]
                : closeModal == "ApproveASNShipment"
                  ? selectedEntries.filter((entry) => entry.status === "underApproval")
                  : closeModal == "ArrivalASNShipment"
                    ? selectedEntries.filter((entry) => entry.status === "incoming")
                    : selectedEntries.filter((entry) => entry.status === "partialReceived")
            }
            handleRemoveSelection={handleRemoveSelection}
            refreshTableData={() => refreshTableData(page + 1, limit)}
          />}
          {actionReceiveModal && <ReceiveASNDialogBox
            status={actionReceiveModal}
            entry={actionIndex != null ? advanceShipment[actionIndex] : null}
            handleClose={handleCloseActionReceiveModal}
          />}
          {actionIndex != null && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={actionIndex != null}
              onClose={handleCloseAction}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                display:
                  actionIndex != null ? "block !important" : "none !important",
              }}
            >
              {permissions?.permissions?.some((item) => item.permissionId === "viewASN") &&
                <MenuItem
                  key={1}
                  sx={{ ...textClasses.normalText }}
                  onClick={() =>
                    history.push(
                      `/dashboard/data/view-asn/${advanceShipment[actionIndex].ASN_Id}`
                    )
                  }
                >
                  View
                </MenuItem>}
              {(permissions?.permissions?.some((item) => item.permissionId === "updateASN") &&
              (["drafted","incoming","underApproval"].includes(advanceShipment[actionIndex].status) &&
                  advanceShipment[actionIndex].rejectedQuantity === 0))
                && (
                  <MenuItem
                    key={0}
                    sx={{ ...textClasses.normalText }}
                    onClick={() =>
                      history.push(
                        `/dashboard/data/edit-asn/${advanceShipment[actionIndex]._id}`
                      )
                    }
                  >
                    Edit
                  </MenuItem>
                )}
                   {advanceShipment[actionIndex]?.status=="underApproval" &&
                permissions?.permissions?.some((item) => item.permissionId === "acceptRejectASN") &&
                (
                  <MenuItem
                    key={2}
                    sx={{ ...textClasses.normalText }}
                    onClick={() => setCloseModal("ApproveASNShipment")}
                  >
                    Mark As Approved
                  </MenuItem>
                )}

              {advanceShipment[actionIndex]?.status=="incoming"&&
                permissions?.permissions?.some((item) => item.permissionId === "acceptRejectASN") &&
                (
                  <MenuItem
                    key={2}
                    sx={{ ...textClasses.normalText }}
                    onClick={() => setCloseModal("ArrivalASNShipment")}
                  >
                    Mark As Arrived
                  </MenuItem>
                )}
              {["arrived", "partialReceived"].indexOf(advanceShipment[actionIndex].status) >= 0 &&
                permissions?.permissions?.some((item) => item.permissionId === "asnReceiving") &&
                (
                  <MenuItem
                    key={2}
                    sx={{ ...textClasses.normalText }}
                    onClick={() => setActionReceiveModal("receive")}
                  >
                    Receive
                  </MenuItem>
                )}
              {["incoming","arrived","underApproval"].includes(advanceShipment[actionIndex].status) &&
                permissions?.permissions?.some((item) => item.permissionId === "acceptRejectASN") &&
                (
                  <MenuItem
                    key={3}
                    sx={{ ...textClasses.normalText }}
                    onClick={() => setActionConfirmModal("Reject")}
                  >
                    Reject
                  </MenuItem>
                )}
              {advanceShipment[actionIndex].status == "partialReceived" &&
                permissions?.permissions?.some((item) => item.permissionId === "closeASN") &&
                (
                  <MenuItem
                    key={3}
                    sx={{ ...textClasses.normalText }}
                    onClick={() => setCloseModal("CloseASNShipment")}
                  >
                    Close
                  </MenuItem>
                )}
            </Menu>
          )}
        </>
      }
    </>
  );
}
