import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ExternalFormHeader from "./ExternalFormHeader";
import ShipmentSection from "./ShipmentDetails";
import OrderSection from "./OrderDetails";
import UserDetailSection from "./UserDetails";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import theme, {
  textClasses,
  buttonClasses,
  cardClasses,
} from "../../../static/styles/theme";
import { showToast, showErrorDynamicField, validateFieldsMultiple, validateFieldsSingle } from "../../../services/functions";
import { useForm } from "react-hook-form";
import {
  getESOPFormTemplateDetails,
  saveESOPFormTemplateDetails
} from "../../../redux/actions";
import logo from "./../../../static/images/Icon/logo-white1.png";
import history from "../../../services/history";
import { initialCheckboxItems } from "../../Modules/SalesOrderProcessing/eSoFormPage";
import {  useLocation} from "react-router-dom";

let dummyObject = {
  "handle": true,
  "label": "Sales Detail ID",
  "description": "Sales Detail ID",
  "placeholder": "Sales Detail ID",
  "_id": "salesDetailID",
  "type": "input",
  "className": "form-control",
  "icon": "simple-icon-note",
  "subtype": "text",
  "forType": "table",
  "required": true,
  "multiple": false,
  "hideLabel": false,
  "delete": false,
  "value": "",
  "elementType": "Text and input",
  "sortType": 1
}

const ExternalForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const passcodeParam = queryParams.get('passcode');
  const [shipmentMasterId, setShipmentMasterId] = useState({});
  const [configDetail, setConfigDetail] = useState({});
  const [userInformationDetail, setUserInformationDetail] = useState([])
  const [apiCalled, setApiCalled] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const [items, setItems] = useState([])
  const [errors2, setErrors2] = useState({})
  const fileInputRef = useRef();

  const isQuantityApplicable = configDetail?.templateDetails?.attributes?.some((entry)=>entry?._id=="quantity")
  const OrderQuantity = watch("quantity");
  const countTillNow = items.map((i) => Number(i.quantity)).reduce((a, b) => a + b, 0);
  const remainingItems = OrderQuantity - countTillNow;

  // console.log("Config details", configDetail);
  const allFields =  configDetail?.templateDetails?.otherTemplate?.length>0  && configDetail?.templateDetails?.otherTemplate[0]?.attributes || [];

  const validateFields = () => {
    return validateFieldsMultiple(allFields || [], items || [])
  }

  const validateFieldsShipmentField = () => {

    let rowErrors = {};
    let row = getValues() || {}

    if (!items || items.length == 0) {
      rowErrors["OrderDetail"] = {
        name: "Order Detail",
        message: `Order Detail is required`
      }
    }
    let tempErrors = validateFieldsSingle(configDetail?.templateDetails?.attributes || [], row || {}) || {}
    rowErrors = { ...rowErrors, ...tempErrors }
    return rowErrors;
  }

  const checkItemSubmit = () => {
    let zeroCount = items.filter(it => !it.quantity);
    if (zeroCount.length > 0) {
      showToast("Item count cannot be 0", true)
      return false;
    }

    let newErrors = validateFields();
    setErrors2(newErrors);

    if (newErrors.filter(row => Object.keys(row).length != 0).length != 0) {
      showToast("Form Error: Please check for errors", true);
      return false;
    }

    return true;
  }

  const userDetailConvertor = (informationToBeCaptured) => {
    let tempFormDetail = []
    if (informationToBeCaptured?.length > 0) {

      informationToBeCaptured.forEach(ele => {

        if (ele) {
          let copy = { ...dummyObject, _id: ele }
          const item = initialCheckboxItems.find(dl => dl.value === ele);
          if (item) {
            copy.description = item.label;
            copy.label = item.label;
            copy.placeholder = item.label
          }
          else {
            copy.description = ele;
            copy.label = ele;
            copy.placeholder = ele
          }
          if (ele == "mobileNumber") {
            copy["regex"] = "^[6-9][0-9]{9}$"
            copy["type"] = "number"
          }
          // else if (ele == "email") {
          //   copy["regex"] = "/^\S+@\S+\.\S+$/"
          // }

          tempFormDetail.push(copy)
        }
      });
    }

    return tempFormDetail
  }

  const seperateDataAttrFunction = (data, attr) => {
    let tempObj = {}
    for (let ele of attr) {
      if (ele?._id && data[ele?._id]) {
        tempObj[ele?._id] = data[ele?._id]
      }
    }
    return tempObj;
  }


  const handleClear = () => {

    //  setSelectedTemplate(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""
    }
    let resetD = { ...getValues() };
    let rKeys = Object.keys(resetD);
    // let keys = Object.keys(getValues())
    for (let i = 0; i < rKeys.length; i++) {
      // setValue(keys[i], "");
      resetD[rKeys[i]] = ""

    };
    reset({
      ...resetD
    });
    // setConnectedCust_Sup_Detail({});
    setItems([]);

  }

  const handleUpcoming = (data) => {
    handleSave({ ...data, drafted: false })
  }


  const handleSave = (data) => {
    const shipmentValidation = validateFieldsShipmentField();

    if (Object.keys(shipmentValidation).length > 0) {
      showToast(shipmentValidation[0].message, true);
      return
    }
    let payload = {
      template_id: configDetail?.templateId,
      shipmentData: seperateDataAttrFunction(data, configDetail?.templateDetails?.attributes),
      userData: seperateDataAttrFunction(data, userInformationDetail),
      drafted: false
    }
    // console.log("payload", payload)
    const itemValidation = checkItemSubmit();
    if (itemValidation) {
      if (configDetail?.templateDetails?.connectedMaster && configDetail?.templateDetails?.connectedMaster?.itemMaster) {
        let nonSearched = items.filter(i => i.itemDetail == null)
        if (nonSearched.length > 0) {
          showToast(`Please finish search of Product Id ${nonSearched[0].product_ID}`, true)
          return;
        }
      }

      const orderItems = items.map(obj => {
        const { tableHeader, itemDetail, ...rest } = obj;
        return rest;
      });

      let bookedSlotData = JSON.parse(localStorage.getItem("e-slotsBooked"))

      if (bookedSlotData?.slotFrom) {
        payload['slotFrom'] = bookedSlotData?.slotFrom;
        payload['slotTo'] = bookedSlotData?.slotTo;
      }
      
      payload['itemData'] = orderItems;
      setApiCalled(true)
      dispatch(
        saveESOPFormTemplateDetails(payload, (data) => {
          setApiCalled(false)
          showToast(data?.message,!data?.success)

          if (data) {
            setTimeout(()=>{
              localStorage.removeItem("passToken")
              localStorage.removeItem("e-slotsBooked")
              history.push(`/passcode/thankyou`)
            },500)
          }
        })
      )
    }

  }


  useEffect(() => {
    if (localStorage.getItem("passToken")) {
      // dispatch(getSelectedTemplate({ template_id: "65a8fc0c608777504f1fd214" }));
      dispatch(
        getESOPFormTemplateDetails({}, ((data) => {
          showToast(data?.message, !data.success)
          setConfigDetail(data?.data || {})
          if (data?.success) {
            let userDetail = userDetailConvertor(data?.data?.formConfig?.informationToBeCaptured || null)
            setUserInformationDetail(userDetail)

          }
          else {
            setUserInformationDetail([])
          }
        }))
      )

    }
    else {
      history.push(`/passcode/${passcodeParam}`)
    }
    return (()=>{
      localStorage.removeItem("passToken")
    })
  }, []);
  return (
   
        <>
          {/* <ExternalFormHeader /> */}
          {/* <Divider sx={{ m: "0 5px 0 5px", mb: 2, mt: 6 }} /> */}
          <Grid container direction="column" sx={{ minHeight: "100vh" }}>
        <Paper
          elevation={3}
          sx={{
            ...cardClasses.basic,
            borderRadius: "8px",
            p: 2,
            mt: 5,
            width: "98%",
            marginLeft: "auto",
            marginRight: "auto",
            overflowX: "hidden",
          }}
        >
          <Typography sx={{
            ...textClasses.cardTitle,

          }}>
            Fill the Sales Order Form
          </Typography>
          {configDetail?.templateId && (
            <ShipmentSection
              data-testid="shipment-section"
              template={configDetail?.templateDetails || {}}
              shipmentMasterId={shipmentMasterId}
              formHooks={{ register, control, setValue, errors }}
              slotBookingField={configDetail?.formConfig?.isSlotBookingIncluded}
            />
          )}
          {configDetail?.templateId && userInformationDetail?.length > 0 && (
            <UserDetailSection
              data-testid="user-section"
              details={userInformationDetail || []}
              formHooks={{ register, control, setValue, errors }}
            />
          )}
          {configDetail?.templateId && (
            <OrderSection
              data-testid="order-section"
              template={configDetail?.templateDetails || {}}
              items={items}
              setItems={setItems}
              totalQuantity={OrderQuantity}
              countTillNow={countTillNow}
              remainingItems={remainingItems}
              errors={errors2}
              informationShownForSKU={configDetail?.formConfig?.informationShownForSKU || []}
              isQuantityApplicable={isQuantityApplicable}
            />
          )}
          <Grid container direction={"row"} sx={{ mt: 2, ml: 4 }}>
            <Button
              data-testid="clearall-btn"
              variant="outlined"
              size="small"
              sx={{
                ...buttonClasses.outlined,
                color: theme.themeOrange,
                minWidth: "150px",
              }}
              disabled={apiCalled}
              onClick={handleClear}
            >
              Clear All
            </Button>
            <Button
              data-testid="submit-btn"
              variant="contained"
              size="small"
              sx={{
                ...buttonClasses.small,
                minHeight: "40px",
                backgroundColor: theme.themeOrange,
                minWidth: "150px",
                ml: 2,
              }}
              disabled={
                apiCalled ||
                validateFields()?.length > 0 ||
                items.length == 0
              }
              onClick={handleSubmit(handleUpcoming, showErrorDynamicField)}
            >
              Submit
            </Button>
          </Grid>
        </Paper>
        <Box
          sx={{
            backgroundColor: "#000000",
            height: "64px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "auto", // Pushes footer to the bottom
          }}
        >
          <Typography
            sx={{
              ...textClasses.cardTitle,
              fontFamily: "Nunito",
              color: "#FFFFFF",
            }}
          >
            Powered By :
          </Typography>
          <Box className="logopanel">
            <Typography>
              <img src={logo} alt="logo" />
            </Typography>
          </Box>
        </Box>
        </Grid>
       
   
        </>
  );
}

export default ExternalForm;


