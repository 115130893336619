import { INIT_CANVAS, ADD_SECTION, REMOVE_SECTION, ADD_FIELD, REMOVE_FIELD, EDIT_FIELD, SET_EDIT_FIELD, REMOVE_EDIT_FIELD, SELECT_TEMPLATE, REPLACE_EDIT_CANVAS, SELECT_SUB_TEMPLATE, FETCH_SUPPLIER_MASTER_TEMPLATES, FETCH_CUSTOMER_MASTER_TEMPLATES, FETCH_ITEM_MASTER_TEMPLATES, SELECT_SUPPLIER_MASTER, SELECT_CUSTOMER_MASTER, SELECT_ITEM_MASTER, IS_ADDRESS_BOOK_CONNECTED, SET_ADDRESS_BOOK_FIELD } from "../types";
import config from "../../services/config";



export const convertToCanvasObject = (categories) => {
    const canvas = {
        attributes: {
            status: true,
            mandatory: [],
            other: []
        },
        packageTemplate: {
            status: true,
            mandatory: [],
            other: []
        },
        itemTemplate: {
            status: true,
            mandatory: [],
            other: []
        }
    };
    for (let i = 0; i < categories.length; i++) {
        const category = categories[i];
        for (let j = 0; j < category.allTemplateTypes.length; j++) {
            const template = category.allTemplateTypes[j];
            canvas.attributes.mandatory = [
                ...canvas.attributes.mandatory,
                ...template.attributes.map(attr => {
                    const newAttr = {
                        ...attr,
                        templateType: template.templateTypekey,
                    }
                    if(attr._id == "UOM"){
                        newAttr.apiMethod = "post"
                    }
                    return newAttr
                })
            ];
            if(template.packageAttributes){
                canvas.packageTemplate.mandatory = [
                    ...canvas.packageTemplate.mandatory,
                    ...template.packageAttributes.map(attr => {
                        const newAttr = {
                            ...attr,
                            templateType: template.templateTypekey
                        }
                        if(attr._id == "UOM"){
                            newAttr.apiMethod = "post"
                        }
                        return newAttr
                    })
                ];
            }
            if(template.itemAttributes){
                canvas.itemTemplate.mandatory = [
                    ...canvas.itemTemplate.mandatory,
                    ...template.itemAttributes.map(attr => {
                        const newAttr = {
                            ...attr,
                            templateType: template.templateTypekey
                        }
                        if(attr._id == "UOM"){
                            newAttr.apiMethod = "post"
                        }
                        return newAttr
                    })
                ];
            }
        }
        
    }
    //("Returning canvas", canvas);
    return canvas
}

const INIT_STATE = {
    components: convertToCanvasObject([]),
    editField: null,
    selectedTemplate: "-",
    selectedSubTemplate: "-",
    supplierMasterTemplates: [],
    customerMasterTemplates: [],
    itemMasterTemplates: [],
    selectedSupplierMaster: "-",
    selectedCustomerMaster: "-",
    selectedItemMaster: "-",

}

export default (state=INIT_STATE, action) => {
    let newComps;
    let sectionId = action.payload && action.payload.sectionId;

    switch(action.type) {

        case SELECT_TEMPLATE: return { ...state, selectedTemplate: action.payload.templateType, selectedSubTemplate: action.payload.subTemplateType, components: action.payload.canvas , subMasterTypeKey:action.payload.subMasterTypeKey }

        case INIT_CANVAS: return { ...state, components: action.payload, selectedSupplierMaster: "-", selectedCustomerMaster: "-", selectedItemMaster: "-" }

        case REPLACE_EDIT_CANVAS: return { ...state, selectedTemplate: action.payload.templateType, selectedSubTemplate: action.payload.templateCategory, components: action.payload.newComponents }

        case ADD_SECTION:
            
            newComps = {...state.components};
            newComps[sectionId].status = true;
            return { ...state, components: newComps }
        
        case REMOVE_SECTION:
            newComps = {...state.components};
            newComps[sectionId].status = false;
            newComps[sectionId].other = []
            return { ...state, components: newComps }

        case ADD_FIELD:
            newComps = {...state.components};
            newComps[sectionId].other = [...newComps[sectionId].other, ...action?.payload?.fields]
            return { ...state, components: newComps }

        case REMOVE_FIELD:
            if(action?.payload?.type == "other"){
            newComps = {...state.components}
            let newArr = [...newComps[sectionId].other]
            newArr.splice(action.payload.index, 1);
            newComps[sectionId].other = newArr
            return { ...state, components: newComps}
            }
            else { 
                newComps = {...state.components}
                let newArr = [...newComps[sectionId].addressBook]
                newArr.splice(action.payload.index, 1);
                newComps[sectionId].addressBook = newArr
                return { ...state, components: newComps}
            }

        case SET_EDIT_FIELD: return {...state, editField: {...action.payload}}

        case REMOVE_EDIT_FIELD: return {...state, editField: null}

        case EDIT_FIELD:
            newComps = JSON.parse(JSON.stringify(state.components))
            newComps[sectionId][action.payload.type][action.payload.index] = action.payload.field
            return { ...state, components: newComps}

        case FETCH_SUPPLIER_MASTER_TEMPLATES: return {...state, supplierMasterTemplates: action.payload}
        case FETCH_CUSTOMER_MASTER_TEMPLATES: return {...state, customerMasterTemplates: action.payload}
        case FETCH_ITEM_MASTER_TEMPLATES: return {...state, itemMasterTemplates: action.payload} 
        case SELECT_ITEM_MASTER: return {...state, selectedItemMaster: action.payload}
        case SET_ADDRESS_BOOK_FIELD:
            newComps = {...state.components};
            newComps[sectionId].addressBook = action?.payload?.fields || [];
            return { ...state, components: newComps }
        default: return state
            

    }
}