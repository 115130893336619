import { removeLeadingChars } from "../../../../services/functions";
import { Mobile_Validation, Email_Validation } from "../../../../services/regexValidation"


export const gateINJobValidation = (action = "gateIn", req_payload, floor, warehouse) => {

    if (!warehouse?.value) {
        return { success: false, message: "Please provide warehouse!" }
    }
    if (!floor?.value) {
        return { success: false, message: "Please provide floor!" }
    }

    

    return { success: true, message: `Valid`, warehouseId: warehouse?.value, floorId: floor?.value }
}
export const gateOutJobValidation = (action = "sendTouser", req_payload, warehouse) => {

    if (!warehouse?.value) {
        return { success: false, message: "Please provide warehouse!" }
    }

    

    return { success: true, message: `Valid`, warehouseId: warehouse?.value, floorId: "" }
}
export const invAuditJobValidation = (action = "inventoryAudit", req_payload, warehouse, StockTranferState = {}) => {
    const { reason = {} } = StockTranferState
    let jobExtraDetail = {}
    if (!warehouse?.value) {
        return { success: false, message: "Please provide warehouse!" }
    }
    if (reason?._id) {
        jobExtraDetail["reasonId"] = reason?._id 
    }
    

    return { success: true, message: `Valid`, warehouseId: warehouse?.value, floorId: "", jobExtraDetail }
}

export const stockJobValidation = (action = "stockTransfer", req_payload, StockTranferState) => {
    const {
        transferFromWarehouse = {}, transferToCategory = {},
        //for warehouse
        transferToWarehouse = {}, transferToFloor = {},
        //for Intenal User
        transferToInternalUser = {},
        //for externaluser
        name = "", email = "", mobile = "", address = "", reason = {}
    } = StockTranferState

    let jobExtraDetail = {}
    if (!transferFromWarehouse?.value) {
        return { success: false, message: "Please provide warehouse from which you want to transfer stock!!" }
    }

    if (!transferToCategory?.value) {
        return { success: false, message: "Stock Transfer category is required!!" }
    }

    jobExtraDetail["category"] = transferToCategory?.value

    if (transferToCategory.value == "otherWarehouse") {
        if (!transferToWarehouse?.value) {
            return { success: false, message: "Please provide warehouse in which you want to transfer stock!!" }
        }
        if (!transferToFloor?.value) {
            return { success: false, message: "Please provide floor in which you want to transfer stock!!" }
        }

        jobExtraDetail["warehouseId"] = transferToWarehouse?.value
        jobExtraDetail["floorId"] = transferToFloor?.value

    }
    else if (transferToCategory.value == "internalUser") {
        if (!transferToInternalUser?.value) {
            return { success: false, message: "Please provide Internal user detail whom you want to assigend stock!!" }
        }
        jobExtraDetail["userId"] = transferToInternalUser?.value
    }
    else if (transferToCategory.value == "internalConsumption") {
        jobExtraDetail = {
            ...jobExtraDetail,
            reasonId: reason?._id
        }
    }
    else {
        if (!name) {
            return { success: false, message: "User name is required!!" }
        }

        if (!email) {
            return { success: false, message: "User email is required!!" }
        }
        let email_output = Email_Validation(email)
        if (!email_output || !email_output.success) {
            return { success: false, message: email_output.message }
        }

        if (!mobile) {
            return { success: false, message: "User mobile number is required!!" }
        }
        let mobile_output = Mobile_Validation(mobile)
        if (!mobile_output || !mobile_output.success) {
            return { success: false, message: mobile_output.message }
        }

        jobExtraDetail = {
            ...jobExtraDetail,
            name, email, mobile, address: address || ""
        }

    }

    return { success: true, message: `Valid`, warehouseId: transferFromWarehouse?.value, floorId: "", jobExtraDetail }

}

export const loction_validation = (validate_for_cell = false, validataion_data, validate_for = "", req_payload, floorId = "", warehouseId = "", category, action) => {
    let start = 1, location = []

    for (let item of validataion_data) {
        const { shipmentId = "", packageId, itemId, isItemExist, isPackageExist, count = "", mappingArea, cellId, rackDetail = [], isRackExist, SKU_ID, inventoryType, Sales_Id = "" } = item
        if (validate_for_cell) {
            if (!cellId) {
                return { success: false, message: `Cell is required for row number ${start}` }
            }
            let com_obj = { cellId, floorId, warehouseId }

            if (isRackExist) {
                if (rackDetail?.length > 0) {
                    com_obj["rackId"] = rackDetail.map(dl => dl.value)
                }
            }
            location.push(com_obj)
        }
        else if (category === "quantityWise") {
            let com_obj = { warehouseId }
            if (!SKU_ID) {
                return { success: false, message: `SKU_ID is required for row number ${start}` }
            }

            if (!inventoryType) {
                return { success: false, message: `Inventory Type is required for row number ${start}` }
            }

            let count_convert = removeLeadingChars(count || "")
            if (!count_convert || count_convert <= 0 || count_convert == "0") {
                return { success: false, message: `Valid count is required for row number ${start}` }
            }

            // Add Sales Id if coming from sales order
            if (Sales_Id) {
                com_obj["sales_Id"] = Sales_Id
            }

            com_obj["SKU_ID"] = SKU_ID;
            com_obj["inventoryType"] = inventoryType;
            com_obj["count"] = Number(count)
            location.push(com_obj)
        }
        else if (["locationWise","skuWise"].includes(category)) {
            const { shipmentId, cellId, packageId, cellDetail, floorId, warehouseId, rackId, itemId,count } = item;
            let com_obj = { floorId, warehouseId, packageId, shipmentId, itemId }
            let rackKey = "rackId";
            let cellKey = "cellId"
            if (action == "changeLocation") {
                if (!floorId) {
                    return { success: false, message: `Floor Id is required for row number ${start}` }
                }
                if (!cellDetail?.length) {
                    return { success: false, message: `New Location is required for row number ${start}` }
                }
                const cellDetails = cellDetail?.map(({ cellId, count, rackId, floorId }) => ({ cellId, count, rackId, floorId }))
                com_obj["cellDetail"] = cellDetails;
                rackKey = "oldRackId";
                cellKey = "oldCellId";
            }
            else {
                com_obj["count"] = count || 0;
            }

            if (!shipmentId) {
                return { success: false, message: `Shipment Id is required for row number ${start}` }
            }
            if (!cellId) {
                return { success: false, message: `Cell is required for row number ${start}` }
            }

            com_obj[cellKey] = cellId

            if (rackId) {
                com_obj[rackKey] = rackId
            }

            location.push(com_obj)

        }
        else {
            if (!shipmentId) {
                return { success: false, message: `Shipment Id is required for row number ${start}` }
            }
            let com_obj = { shipmentId, mappingArea: mappingArea || "", floorId, warehouseId }


            if (isPackageExist) {
                if (!packageId) {
                    return { success: false, message: `Package Id is required for row number ${start}` }
                }
                com_obj["packageId"] = packageId
            }

            if (isItemExist) {
                if (!itemId) {
                    return { success: false, message: `Item Id is required for row number ${start}` }
                }
                com_obj["itemId"] = itemId
            }

            let count_convert = removeLeadingChars(count || "")
            if (!count_convert || count_convert <= 0 || count_convert == "0") {
                return { success: false, message: `Valid count is required for row number ${start}` }
            }

            com_obj["count"] = Number(count)
            location.push(com_obj)
        }


        start++
    }

    req_payload["location"] = location

    return { success: true, message: `Valid` }
}