import React, { useState } from "react";
import { FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import theme, { inputClasses, textClasses } from "../../../../static/styles/theme";
import { selectStylesOverride } from "../../../../utils/util";
import GenericMultiDateRange from "../../../Components/Generic/GenericMultiDateRange";

const DateRangeFilter = ({ dateFilterOption = [], selectedRange = "7d", dateFilter, handleSelection, handleDateChange }) => {

    return (
        <>
            {/* FormControl and RadioGroup */}
            <FormControl component="fieldset" sx={{ ...textClasses.t12n, color: theme.themeBlack }}>
                <RadioGroup row value={selectedRange} onChange={(e) => handleSelection(e.target.value)}>
                    {dateFilterOption.map((item) => (
                        <FormControlLabel
                            key={item.value}
                            value={item.value}
                            control={
                                <Radio
                                    size="small"
                                    sx={{
                                        // color: theme.themeGray, // Unselected radio color
                                        "&.Mui-checked": {
                                            color: theme.themeOrange, // Selected radio color
                                        },
                                    }}
                                />
                            }
                            label={item.category}
                            sx={{
                                "& .MuiTypography-root": {
                                    fontSize: "12px",
                                    //   color: theme.themeBlack // Label color
                                }
                            }}
                        />
                    ))}
                </RadioGroup>
            </FormControl>

            {/* Custom Date Range Fields */}
            {selectedRange === "dateRange" &&
                <FormControl sx={{ ...selectStylesOverride, width: '30%', display: "flex", height: "100%" }} fullWidth>
                    <GenericMultiDateRange
                        label={"Custom Date Range"}
                        getOnChange={(newValue) => handleDateChange(newValue || [])}
                        value={dateFilter || []}
                        inputStyle={inputClasses.filterField}
                    />
                </FormControl>
            }
        </>

    );
};

export default DateRangeFilter;
