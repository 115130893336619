import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { multiDateToISOConvertor } from "../../../Components/Generic/GenericMultiDateRange";
import { getWarehouseInventoryCustomDashboard } from "../../../../redux/actions/CustomDashboard";
import DateRangeFilter from "../WidgetComponents/DateRangeFilter";
import AnalyticsTypeWidget from "../WidgetComponents/Analytics";
import TypeAndSearch from "../../../Components/TypeAndSearch";
import { component } from "../../MainDashboard/utils";



const convertToDateRange = (value) => {
    const now = new Date(); // Current date
    let dateFrom = new Date(now); // Start date
    let dateTo = new Date(now); // End date

    switch (value) {
        case "1d":
            dateFrom.setDate(now.getDate() - 1);
            break;
        case "7d":
            dateFrom.setDate(now.getDate() - 7);
            break;
        case "1m":
            dateFrom.setMonth(now.getMonth() - 1);
            break;
        case "1y":
            dateFrom.setFullYear(now.getFullYear() - 1);
            break;
        default:
            throw new Error("Invalid value");
    }

    return {
        dateFrom: dateFrom.toISOString(),
        dateTo: dateTo.toISOString()
    };
};

const WarehouseStockCount = ({ item, styleView , warehouseDetails}) => {
    const dispatch = useDispatch()
    const [operationCount, setOperationCount] = useState({})
    const [selectedRange, setSelectedRange] = useState("7d");
    const [dateFilter, setDateFilter] = useState([]);
    const [dataLoader, setDataLoader] = useState(true)
    const [mappingAreaFilter, setMappingAreaFilter] = useState("")
    const [skuFilter, setSKUFilter] = useState("");

    const [updatedItem, setUpdatedItem] = useState(item || []);
    const handleSKUChange = (e, option) => {
        setSKUFilter(option?.value || "")
      }
    const handleMappingAreaChange = (e, option) => {
        setMappingAreaFilter(option?.value || "")
    }

    const handleSelection = (value) => {
        setSelectedRange(value);
        let dates
        if (value !== 'dateRange') {
            dates = convertToDateRange(value)
        }
        setDateFilter([])
        getDataDateRangeWise(dates?.dateFrom, dates?.dateTo)
    };

    const handleDateChange = (newDates) => {
        setDateFilter(newDates);
        let datarry;

        if (newDates.length >= 2) {
            datarry = multiDateToISOConvertor(newDates)
        }
        if (datarry?.length >= 2) {
            getDataDateRangeWise(datarry[0], datarry[1])
        }

    };
    const getDataDateRangeWise = (dateFrom = '', dateTo = '', mappingArea = "") => {
        setDataLoader(true)
        let warehouseIds = warehouseDetails?.map(item => item._id)
        let payload = {
            wareHouse: warehouseIds || [],
            dateFrom: dateFrom,
            dateTo: dateTo
        }
        payload.mappingArea= mappingAreaFilter ||"";
        payload.SKU_ID=skuFilter

        if (!item) return;
        dispatch(getWarehouseInventoryCustomDashboard(payload, (data) => {
            const transformedArray = data?.data?.map(obj => ({
                category: obj?.date,
                in: obj?.in,
                out: obj?.out,
                current: obj?.current
            }));

            setOperationCount(data)
            setUpdatedItem((prev) => ({
                ...prev,
                values: transformedArray
            }))
            setDataLoader(false)
        }))
    }
    useEffect(() => {
        getDataDateRangeWise()
    }, [skuFilter,mappingAreaFilter,warehouseDetails,dispatch])
    return (<>
        < >

            <AnalyticsTypeWidget item={updatedItem} isDefaultDashboard={true} styleView={styleView} dataLoader={dataLoader}>
                <TypeAndSearch
                    data-testid="mappingFilter"
                    component={component({ type: "mappingArea" })}
                    handleChange={(e, option) => {
                        handleMappingAreaChange(e, option);
                    }}
                    label={mappingAreaFilter || ""}
                    value={mappingAreaFilter || ""}
                    sx={{
                        width: "100%", // Full width for responsiveness
                    }}
                />
                <TypeAndSearch
                    data-testid="skuFilter"
                    component={component({ type: "SKU" })}
                    handleChange={(e, option) => {
                        handleSKUChange(e, option);
                    }}
                    label={skuFilter || ""}
                    value={skuFilter || ""}
                    sx={{
                        width: "100%", // Full width for responsiveness
                    }}
                />
                <DateRangeFilter dateFilterOption={item?.dateFilterOption}
                    selectedRange={selectedRange}
                    dateFilter={dateFilter}
                    handleSelection={handleSelection}
                    handleDateChange={handleDateChange} />
            </AnalyticsTypeWidget>
        </>

    </>)
}

export default WarehouseStockCount;