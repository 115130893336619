import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { Button, Divider, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import React from 'react'
import theme, { buttonClasses, cardClasses, textClasses } from '../../../../static/styles/theme';

export default function PackageObjectsSlider({objects, slideIndex, setSlideIndex, handleOpenEditModal, handleDeleteObject}) {
    const totalObjectCount = objects.length;

    return (
        <>
            <Grid container>
                <Grid container item sm={1} xs={2} alignItems="center" justifyContent={"center"}>
                    <IconButton
                        disabled={totalObjectCount < 5}
                        onClick={() => setSlideIndex(slideIndex >= 1 ? slideIndex - 1: slideIndex)}
                        sx={{backgroundColor: theme.themeOrange, color: "white", "&:hover": {backgroundColor: theme.themeOrange}}}
                    >
                        <KeyboardArrowLeft/>
                    </IconButton>
                </Grid>
                <Grid container item sm={10} xs={8}>
                    <Grid container direction={"row"} spacing={1} sx={{p: 2}}>
                        {
                            objects.slice(slideIndex * 4, slideIndex * 4 + 4).map((p, i) => {
                                return (
                                    <Grid item sm={3} xs={6} key={i}>
                                        <PackageCard
                                            packages={objects}
                                            pIndex={slideIndex * 4 + i}
                                        
                                            handleOpenEditModal={handleOpenEditModal}
                                            handleDeleteObject={handleDeleteObject}
                                        />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
                <Grid container item sm={1} xs={2} alignItems="center" justifyContent={"center"}>
                    <IconButton
                        disabled={objects.length < 5}
                        onClick={() => setSlideIndex(slideIndex < Math.ceil(objects.length / 4) - 1 ? slideIndex + 1 : slideIndex)}
                        sx={{backgroundColor: theme.themeOrange, color: "white", "&:hover": {backgroundColor: theme.themeOrange}}}
                    >
                        <KeyboardArrowRight/>
                    </IconButton>
                </Grid>
            </Grid>
            
            <Divider/>
        </>
    )
}

function PackageCard({packages, pIndex, handleOpenEditModal, handleDeleteObject}) {

    const packageObj = packages[pIndex]
    
    return (
        <Paper sx={{...cardClasses.basic, borderRadius: "8px", backgroundColor: theme.themeLightOrange, p: 2}}>
            
                
            <Grid container alignItems={"center"}>
                <Typography sx={{...textClasses.boldText, color: theme.themeOrange}}>Package Name: </Typography>
                <Tooltip title={packageObj.packageName}>
                    <Typography sx={{...textClasses.normalText, color: theme.themeOrange, ml: 1}}>
                        {packageObj.packageName && packageObj.packageName.length > 14 ? packageObj.packageName.substring(0, 14) + "..." : packageObj.packageName}
                    </Typography>
                </Tooltip>
            </Grid>

            <Grid container alignItems={"center"}>
                <Typography sx={{...textClasses.boldText, color: theme.themeOrange}}>Item Count: </Typography>
                <Typography sx={{...textClasses.boldText, color: theme.themeBlack, ml: 1, textDecoration: "underline", cursor: "pointer"}} >Items ({packageObj.itemDetail.map((i) => Number(i.count)).reduce((a, b) => a + b, 0)})</Typography>
                
            </Grid>
                
            <Grid container sx={{mt: 1}}>
                <Button variant='contained' size='small' sx={{...buttonClasses.small, color: "white", backgroundColor: theme.themeOrange}} onClick={() => handleOpenEditModal(pIndex)}>Edit</Button>
                <Button variant='contained' size='small' sx={{...buttonClasses.small, color: "white", backgroundColor: "black", ml: 1}} onClick={() => handleDeleteObject(pIndex)}>Delete</Button>
            </Grid>

        </Paper>
    )
}