import React, {
    useState,
    
  } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import {
    Grid,
    TextField,
    Typography,
    Box,
    Checkbox,
    ListItemText,
    IconButton,
    Autocomplete,
    Paper,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Table,
    MenuItem,
    FormControl,
  } from "@mui/material";
  import {Delete} from "@mui/icons-material";
  import {
    getGridDetail,
  } from "../../../../redux/actions";
  import theme from "../../../../static/styles/theme";
  import { selectStylesOverride } from "../../../../utils/util";
 
  export default function  InventoryCheckComponent({
    addedItems,
    setAddedItems,
    jobCreationData,
    isTypeInventoryCheck = true,
    status = ["partial", "full", "available"],
    children,
    listDropDown = false,
    warehouseId = "",
    floorId = "",
    countFor_SPI: { countFor_Shipment_Item = {} },
  }){
    const dispatch = useDispatch();
    const { gridCellDetails = [], gridRackDetails = [] } = useSelector(
      (state) => state.warehouseData
    );
    const initial = {
      cellId: "",
      cellName: "",
      isRackExist: false,
      isAdd: false,
    };
    const [selectedData, setSelectedData] = useState(initial);
  
    const cellOptions = () => {
      let opt = [];
      for (let _ of gridCellDetails) {
        if (_?.warehouseId == warehouseId && _?.floorId == floorId) {
          opt.push({
            label: _.name + " ( " + ([_.status] || "") + " )",
            cellName: _.name,
            value: _.cellId,
            isRackExist: _.isRackExist || false,
            mappingArea: _.subProductType || [],
          });
        }
      }
      return opt;
    };
  
    const rackOptions = () => {
      let opt = [];
      for (let _ of gridRackDetails) {
        if (
          _?.warehouseId == warehouseId &&
          _?.floorId == floorId &&
          _?.parentId == selectedData?.cellId
        ) {
          opt.push({
            label: _.name + " ( " + ([_.status] || "") + " )",
            name: _.name,
            value: _.cellId,
            mappingArea: (_.productType && [_.productType]) || "",
            parentId: _?.parentId || "",
          });
  
          if (selectedData.cellId) {
            countFor_Shipment_Item[selectedData.cellId] = opt.length;
          }
        }
      }
      return opt;
    };
  
    const handleCellSelect = (e, option) => {
      let temp_data = { ...selectedData };
      if (option?.value) {
        const {
          label,
          value,
          mappingArea,
          isRackExist = false,
          cellName,
        } = option;
        temp_data["isAdd"] = true;
        temp_data = {
          ...temp_data,
          isRackExist,
          cellName,
          C_label: label,
          cellId: value,
          isAdd: true,
          mappingArea,
        };
  
        if (isRackExist) {
          dispatch(
            getGridDetail({
              parentId: value,
              type: "rack",
              floorId,
              warehouseId,
              status,
              fetch_for:"pickUp",
            })
          );
        } else {
          temp_data["isAdd"] = true;
          temp_data["isRackExist"] = false;
        }
      } else {
        delete temp_data["C_label"];
        delete temp_data["cellId"];
        delete temp_data["mappingArea"];
        temp_data["isRackExist"] = false;
        temp_data["isAdd"] = false;
      }
  
      delete temp_data["rackDetail"];
      setSelectedData(temp_data);
    };
  
    const handleRackSelect = (e, option) => {
      ////("option",option);
      let temp_data = { ...selectedData };
      if (option?.length) {
        temp_data["rackDetail"] = option;
      } else {
        delete temp_data["rackDetail"];
      }
      setSelectedData(temp_data);
    };
  
    const handleItemAdd = () => {
      // ////("fffsdewfc>>>>handleItemAdd");
      setAddedItems((o) => [selectedData, ...o]);
      setSelectedData(initial);
    };
  
    const diableCell = ({ cellId, isRackExist = false }) => {
      if (!cellId) {
        return true;
      }
  
      let isPKGAdded = false,
        allAddedCount = 0;
  
      for (let ele of addedItems) {
        if (ele?.cellId == cellId) {
          if (isRackExist) {
            isPKGAdded = true;
            allAddedCount = allAddedCount + (ele?.rackDetail?.length || 0);
            continue;
          }
          return true;
        }
      }
  
      if (isPKGAdded) {
        let available = countFor_Shipment_Item[cellId] || 0;
        if (allAddedCount >= available) {
          return true;
        }
      }
  
      return false;
    };
  
    const diableRack = ({ rackId }) => {
      if (!rackId) {
        return true;
      }
      return addedItems.find((el) =>
        el?.rackDetail?.find((dl) => dl.value == rackId) ? true : false
      )
        ? true
        : false;
    };
  
    const header = ["Sr. No", "Cell", "Rack", "Mapping Area", "Action"];
  
    const handleRowDelete = (indx) => {
      setAddedItems((o) => o.filter((_, i) => i !== indx));
    };
  
    const rows = addedItems.map((data, indx) => {
      let row = [];
      ////("data",data);
      const {
        cellId,
        cellName,
        isRackExist = false,
        C_label,
        mappingArea,
        rackDetail = [],
      } = data;
  
      row.push(
        indx + 1,
        cellName,
        isRackExist
          ? rackDetail.length == 0
            ? "All"
            : rackDetail.map((dl) => dl.name).join(", ")
          : "N/A",
        (mappingArea?.length && mappingArea.join(", ")) || "PUT IT ANY WHERE",
        [
          <IconButton
            aria-label="delete"
            key={indx}
            onClick={() => handleRowDelete(indx)}
          >
            <Delete sx={{ color: "red" }} />
          </IconButton>,
        ]
      );
      return row;
    });
  
    return (
      <>
        {children && <Grid mt={1}>{children}</Grid>}
        <Grid mt={3}>
          <Box>
            <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
              Inventory Details
            </Typography>
          </Box>
          <Box mt={2} sx={{ display: "flex", gap: "2em" }}>
            <Autocomplete
              id="Cell-select"
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Cell"
                  sx={{
                    ...selectStylesOverride,
                  }}
                />
              )}
              disabled={listDropDown}
              getOptionLabel={(option) => option.label}
              sx={{ flexBasis: "30%" }}
              options={cellOptions()}
              onChange={handleCellSelect}
              getOptionDisabled={(option) =>
                diableCell({
                  ...option,
                  cellId: option.value,
                  isRackExist: option.isRackExist || false,
                })
              }
              inputValue={selectedData.C_label || ""}
              isOptionEqualToValue={(option, value) => {
                return option.label === value.label;
              }}
              key="cell-select_gateOut"
            />
            {selectedData?.isRackExist ? (
              <FormControl
                size="small"
                // fullWidth
                sx={{ flexBasis: "30%" }}
              >
                <Autocomplete
                  multiple
                  id="rack-select"
                  size="small"
                  limitTags={5}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Rack"
                      sx={{
                        ...selectStylesOverride,
                      }}
                    />
                  )}
                  getOptionLabel={(option) => option.label}
                  options={rackOptions()}
                  onChange={handleRackSelect}
                  renderOption={(props, option, { selected }) => {
                    const { label, value } = option;
                    return (
                      <MenuItem
                        value={value}
                        key={value}
                        sx={{ ml: 2 }}
                        {...props}
                      >
                        <Checkbox checked={selected} />
                        <ListItemText primary={label} />
                      </MenuItem>
                    );
                  }}
                  getOptionDisabled={(option) =>
                    diableRack({ ...option, rackId: option.value })
                  }
                  value={selectedData?.rackDetail || []}
                  key="rack-select_gateOut"
                  isOptionEqualToValue={(option, value) => {
                    return option.label === value.label;
                  }}
                  disableCloseOnSelect
                />
              </FormControl>
            ) : null}
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: theme.themeOrange,
                "&:hover": {
                  backgroundColor: theme.themeOrange,
                },
              }}
              onClick={handleItemAdd}
              disabled={!selectedData || !selectedData.isAdd}
            >
              Add
            </Button>
          </Box>
  
          {addedItems.length ? (
            <TableContainer
              component={Paper}
              sx={{
                marginTop: "2em",
                maxWidth: "100%",
                // width: "60%",
                overflowX: "auto",
              }}
            >
              <Table aria-label="simple table">
                <TableHead sx={{ backgroungColor: theme.themeGray }}>
                  <TableRow sx={{ backgroundColor: theme.themeGray }}>
                    {header.map((head, i) => {
                      return (
                        <TableCell
                          sx={{
                            fontSize: "13px",
                            textAlign: "center",
                            fontWeight: 600,
                            // borderLeft: "1px solid #a3a3a3",
                            // '& .MuiTableCell-root':{
                            // }
                          }}
                          key={i}
                        >
                          {head}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.length > 0
                    ? rows.map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          // "&:last-child td, &:last-child th": {
                          // 	border: 0,
                          // },
                          "& .MuiTableCell-root": {
                            // borderLeft:
                            // "1px solid rgba(224, 224, 224, 1)",
                          },
                        }}
                      >
                        {row.map((col, j) => {
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                fontSize: "12px",
                                textAlign: "center",
                                overflowWrap: "break-word",
                              }}
                              key={j}
                            >
                              {col}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </Grid>
      </>
    );
  };
