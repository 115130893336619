import { Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import React, { useRef } from 'react'
import { useDrag } from 'react-dnd'
import { InfoOutlined } from '@mui/icons-material';
import { styled } from "@mui/material/styles";
import { textClasses } from '../../../static/styles/theme';

const OrangeTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
    color: #FFFFF;
    background-color: #FF7200;
`)

export default function GenericDraggableComponent({ componentType, component, index, moveComponent, orientation }) {

    const ref = useRef(null);
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "component",
        item: { ...component, componentType: componentType },
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging
            }
        }
    }))

    drag(ref);

    const getDraggableCard = () => {
        if (component.templateType && component.templateType == "freeTemplate") {
            return (
                <Paper ref={ref} sx={{ p: 1, width: "100%", alignItems: "left", justifyContent: "center", textAlign: "left", "&:hover": { boxShadow: "0px 2px 6px #444444" } }}>
                    <Grid container>
                        <Grid container item sm={1} xs={2} alignItems="center">
                            <span class="material-symbols-outlined">
                                ad_group
                            </span>                          </Grid>
                        <Grid container item sm={10} xs={8} alignItems="center">
                            <Typography sx={{ ...textClasses.normalText, ml: 1 }}>{component.name}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            )
        }
        else {
            if (orientation && orientation == "list") {
                return (
                    <Paper ref={ref} sx={{ p: "10px", width: "100%", alignItems: "left", justifyContent: "center", textAlign: "left", "&:hover": { boxShadow: "0px 2px 6px #444444" } }}>
                        <Grid container>
                            <Grid container item sm={1} xs={2} alignItems="center">
                                <span class="material-symbols-outlined" >
                                    {component.icon}
                                </span> </Grid>
                            <Grid container item sm={10} xs={8} alignItems="center">
                                <Typography sx={{ ...textClasses.normalText, ml: 1 }}>{component.label}</Typography>
                            </Grid>
                            {
                                componentType == "previous" &&
                                <Grid container item sm={1} xs={2} alignItems="center">
                                    <OrangeTooltip title={`used in ${component.frequency} templates`} sx={{ float: "right" }} arrow placement="top">
                                        <IconButton size='small' sx={{ fontSize: '14px' }}>
                                            <InfoOutlined />
                                        </IconButton>
                                    </OrangeTooltip>
                                </Grid>
                            }
                        </Grid>

                    </Paper>
                )
            }
            else {
                return (
                    <Paper ref={ref} sx={{ p: "10px", width: "100%", height: "100%", alignItems: "center", justifyContent: "center", textAlign: "center", "&:hover": { boxShadow: "0px 2px 6px #444444" } }}>
                        {component.icon ?
                            <span class="material-symbols-outlined" style={{
                                fontSize: "40px",
                            }}>
                                {component.icon}
                            </span>
                            : <span class="material-symbols-outlined" style={{
                                fontSize: "40px",
                            }}>
                                text_fields
                            </span>
                        }
                        <Grid container justifyContent={"center"} alignItems={"center"} sx={{ mt: 1 }}>
                            <Grid container item sm={componentType == "previous" ? 10 : 12} justifyContent={componentType == "previous" ? "left" : "center"} alignItems={"center"}>
                                <Typography sx={{ ...textClasses.normalText }}>{component.label}</Typography>
                            </Grid>
                            {
                                componentType == "previous" &&
                                <Grid container item sm={2} alignItems={"center"}>
                                    <OrangeTooltip title={`used in ${component.frequency} templates`} arrow placement="top">
                                        <IconButton size='small'>
                                            <InfoOutlined fontSize='4px' />
                                        </IconButton>
                                    </OrangeTooltip>
                                </Grid>
                            }

                        </Grid>

                    </Paper>
                )
            }
        }
    }

    return (
        <>
            {
                getDraggableCard()
            }
        </>

    )
}
