export const ASN_Component={
    "_id": "ASN_ID",
    "type": "select",
    "label": "Search By ASN ID",
    "placeholder": "Search By ASN ID",
    "dynamic": true,
    "auth": true,
    "apiBindingValue": "ASN_Id",
    "apiBindingKey": "ASN_Id",
    "extraValue": [],
    "apiDomain": "",
    "baseURL":"",
    "apiEndPoint": "ASN/getASNList",
    "apiMethod": "post",
    "name": "ASN List",
    "apiDataReturnKey": "data",
    "apiParameters": [
        {
            "label": "fetch_for",
            "value": "asnConsumption"
        },
        {
            "label": "status",
            "value": ["arrived","partialReceived"]
        },
        {
            "label": "page",
            "value": 1
        },
        {
            "label": "limit",
            "value":25
        },
        {
            "label": "ASN_ID",
            "value": "onSearch"
        }
    ],
    "renderOption":[
        {
            "label": "",
            "multiple": false,
            "prefix": "",
            "default_value": "-",
            "postfix": "",
            "key": "ASN_Id",

        },
        {
            "label": "",
            "multiple": false,
            "prefix": " (",
            "postfix": ")",
            "default_value": "0",
            "key": "remainingQTY"
        },
    ]
}