import {
    FETCH_ERROR,
    FETCH_SUCCESS, FETCH_START, FETCH_ALL_CUSTOM_DASHBOARD, FETCH_CUSTOM_DASHBOARD_STATUS_COUNT, FETCH_CUSTOM_DASHBOARD_DETAIL, FETCH_WORKFLOW_DETAILS,
    FETCH_DRAGGABLE_WIDGETS,FETCH_DEFAULT_DASHBOARD_DATA,
    FETCH_WAREHOUSE_COUNT_DATA,
    FETCH_CUSTOM_DASHBOARD_JOB_STATUS_COUNT,
    FETCH_CUSTOM_DASHBOARD_WAREHOUSE_INVENTORY,
    GET_WAREHOUSE_OPRATION_COUNT_CUSTOM_DASHBOARD,
} from "../types"
import axios from "../../services/Api";

export const getDraggableWidgets = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('/customDashboard/getWidgetsList', {
            ...payload
        }
        ).then(({ data }) => {  // here we have destructured the data so we will have the data field coming i.e data:{array1:[],array2:[]}
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_DRAGGABLE_WIDGETS, payload: data?.data || {} })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_DRAGGABLE_WIDGETS, payload: {} })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};


export const saveCustomDashboardWidgets = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('/customDashboard/saveDashboardConfig', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }
            if (cb) {
                cb(data)
            }

        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};



export const getAllCustomDashboardList = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('/customDashboard/getDashboardConfigList', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_ALL_CUSTOM_DASHBOARD, payload: { rows: data?.data || [], count: data?.count } })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_ALL_CUSTOM_DASHBOARD, payload: { rows: [], count: 0 } })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            dispatch({ type: FETCH_ALL_CUSTOM_DASHBOARD, payload: { rows: [], count: 0 } })
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};

export const getCustomDashboardStatusCount = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('customDashboard/getDashboardConfigStatuswiseCount', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_CUSTOM_DASHBOARD_STATUS_COUNT, payload: data.data })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_CUSTOM_DASHBOARD_STATUS_COUNT, payload: null })
            }
            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
  };


  export const getCustomDashboardDetail = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('customDashboard/getDashboardConfigDetail', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_CUSTOM_DASHBOARD_DETAIL, payload: { data: data?.data || {} } })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_CUSTOM_DASHBOARD_DETAIL, payload: { data: {}} })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};

export const updateDashboardStatus = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('/customDashboard/updateDashboardStatus', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }
            if (cb) {
                cb(data)
            }

        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};


export const getDefaultCustomDashboard = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('/customDashboard/getDefaultCustomDashboard', {
            ...payload
        }
        ).then(({ data }) => {
            
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_DEFAULT_DASHBOARD_DATA, payload: { rows: data?.data || [], count: data?.count } })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_DEFAULT_DASHBOARD_DATA, payload: { rows: [], count: 0 } })
            }
            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            dispatch({ type: FETCH_DEFAULT_DASHBOARD_DATA, payload: { rows: [], count: 0 } })
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};
export const getOpertionCountData = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('/customDashboard/getOperationsCountData', {
            ...payload
        }
        ).then(({ data }) => {
            
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                // dispatch({ type: FETCH_DEFAULT_DASHBOARD_DATA, payload: { rows: data?.data || [], count: data?.count } })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                // dispatch({ type: FETCH_DEFAULT_DASHBOARD_DATA, payload: { rows: [], count: 0 } })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            // dispatch({ type: FETCH_DEFAULT_DASHBOARD_DATA, payload: { rows: [], count: 0 } })
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};

export const getInventoryCount = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('/customDashboard/getInventoryCount', {
            ...payload
        }
        ).then(({ data }) => {
            
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                // dispatch({ type: FETCH_DEFAULT_DASHBOARD_DATA, payload: { rows: data?.data || [], count: data?.count } })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                // dispatch({ type: FETCH_DEFAULT_DASHBOARD_DATA, payload: { rows: [], count: 0 } })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            // dispatch({ type: FETCH_DEFAULT_DASHBOARD_DATA, payload: { rows: [], count: 0 } })
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};

export const getInventoryWeightCount = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('/customDashboard/getInventoryWeightCount', {
            ...payload
        }
        ).then(({ data }) => {
            
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                // dispatch({ type: FETCH_DEFAULT_DASHBOARD_DATA, payload: { rows: data?.data || [], count: data?.count } })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                // dispatch({ type: FETCH_DEFAULT_DASHBOARD_DATA, payload: { rows: [], count: 0 } })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            // dispatch({ type: FETCH_DEFAULT_DASHBOARD_DATA, payload: { rows: [], count: 0 } })
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};

export const getWarehouseCountData = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('/customDashboard/getWarehouseCountData', {
            ...payload
        }
        ).then(({ data }) => {
            
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_WAREHOUSE_COUNT_DATA, payload: { rows: data?.data || [], count: data?.count } })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                // dispatch({ type: FETCH_DEFAULT_DASHBOARD_DATA, payload: { rows: [], count: 0 } })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            // dispatch({ type: FETCH_DEFAULT_DASHBOARD_DATA, payload: { rows: [], count: 0 } })
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};

export const getWarehouseAllJobs =(body, callBackFunction) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post("/customDashboard/getCustomDashboardJobList", body)
		.then(({ data: { success, count=0, data, message } }) => {
			if (success) {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({type: FETCH_CUSTOM_DASHBOARD_JOB_STATUS_COUNT,payload: { data, count }})
			}
			else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({type: FETCH_CUSTOM_DASHBOARD_JOB_STATUS_COUNT,payload: { data:[], count:0 }})
			}

			if(callBackFunction)callBackFunction({ success, count, data, message });
		})
		.catch(function (error) {
			dispatch({ type: FETCH_ERROR, payload: error.message });
			// //("Error:", error.message);
		});
		
	};
};
export const getWarehouseInventoryCustomDashboard =(body, callBackFunction) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post("/customDashboard/getWareHouseInventoryCountDataDayWise", body)
		.then(({ data: { success, count=0, data, message } }) => {
			if (success) {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({type: FETCH_CUSTOM_DASHBOARD_WAREHOUSE_INVENTORY,payload: data })
			}
			else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({type: FETCH_CUSTOM_DASHBOARD_WAREHOUSE_INVENTORY,payload: { data:[], count:0 }})
			}

			if(callBackFunction)callBackFunction({ success, count, data, message });
		})
		.catch(function (error) {
			dispatch({ type: FETCH_ERROR, payload: error.message });
			// //("Error:", error.message);
		});
		
	};
};

export const getWarehouseOperationCustomDashboard = (body,cb) => {
    return async (dispatch) => {
      dispatch({ type: FETCH_START });
      await axios
        .post("/customDashboard/getWareHouseOperationCountDataDayWise",body)
        .then(({ data }) => {
          if (data.success) {
            dispatch({ type: FETCH_SUCCESS, payload: data.success });
            dispatch({ type: GET_WAREHOUSE_OPRATION_COUNT_CUSTOM_DASHBOARD , payload: data });
          } else {
            dispatch({ type: FETCH_ERROR, payload: data.message });
          }
          if (cb) {
            cb(data)
        }
        })
        .catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          if (cb) {
            cb({ success: false, message: error.message })
        }
        });
    };
  };
