import React from 'react';
import {lynkID} from '../../../../services/config';
import Integration from '../../../Components/Integration';
import { Grid } from '@mui/material';


const Checkpoint = () => {
    const todayDate = new Date().getTime();
    const myVariable = `${lynkID}/login?action=/dashboard&auth=WMS&timestamp=${todayDate}`;
    return (
        <Grid data-testid="checkpointHome">
            <Integration
                myVariable={myVariable}
                baseURL={lynkID}
            />
        </Grid>
    );
};

export default Checkpoint;