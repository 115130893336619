import React, { useState } from 'react';
import { deepCopy_Object, renderHeadersFilter } from '../../../services/functions';
import GenericAdvanceFilterComponent from './GenericAdvanceFilterComponent';
import { Button,Grid } from '@mui/material';
import {  buttonClasses } from '../../../static/styles/theme';
import Badge from '@mui/material/Badge';
import theme from '../../../static/styles/theme';
import FilterListIcon from '@mui/icons-material/FilterList';


const initAdvTempFilters = {
    "search": {},
    "other": {}
}

const GenericFilterSection = ({
    validHeader = [],
    filter,
    setFilter,
    setPage,
    setLimit,
    tabIndex,
    showStatusFilterIndex=[0]
}) => {

    const [openAdvanceFilter, setOpenAdvanceFilter] = useState({ right: false });
    const [advancedTempFilter, setAdvancedTempFilter] = useState({ ...initAdvTempFilters });

    const applyAdvanceFilter = (tempAdvFilters) => {
        setFilter({ ...filter, ...tempAdvFilters })
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenAdvanceFilter({ ...openAdvanceFilter, [anchor]: open });
        setAdvancedTempFilter(open == true ? { ...filter } : { ...initAdvTempFilters });
    };



    return (<>
        {renderHeadersFilter(deepCopy_Object(validHeader?.slice(0, 4)), filter, setFilter, setPage, setLimit, tabIndex,false,showStatusFilterIndex)}
        {
            validHeader?.length > 4 &&
   
            <>
                <Grid item xs={"auto"} alignItems={"center"} justifyContent={"center"}>
                    <Badge badgeContent={
                        Object.values(filter?.search || {})?.filter(dl =>typeof dl == "object" && !Array.isArray(dl) ? dl?.value : dl?.length)?.length
                        +
                        Object.values(filter?.other || {})?.filter(dl =>typeof dl == "object" && !Array.isArray(dl) ? dl?.value : dl?.length)?.length
                    } sx={{
                        '& .MuiBadge-badge': {
                            backgroundColor: theme.themeOrange,
                            color: 'white',
                        },
                    }}>
                        <Button
                            // variant="contained"
                            size="small"
                            variant="contained"
                            startIcon={<FilterListIcon />}
                            data-testid="viewJob-TypeButton"
                            sx={{ ...buttonClasses.lynkitOrangeEmpty }}
                            onClick={(event) => toggleDrawer('right', true)(event)}

                        >
                            Advance Filter(s)
                        </Button>
                    </Badge>
                </Grid>
                {
                    openAdvanceFilter?.right && <GenericAdvanceFilterComponent
                        validHeader={deepCopy_Object(validHeader)}
                        tabIndex={tabIndex}
                        applyAdvanceFilter={applyAdvanceFilter}
                        isFilterOpen={openAdvanceFilter?.right == true}
                        advancedTempFilter={advancedTempFilter}
                        setAdvancedTempFilter={setAdvancedTempFilter}
                        initAdvTempFilters={initAdvTempFilters}
                        toggleDrawer={toggleDrawer}
                        showStatusFilterIndex={showStatusFilterIndex}
                    />
                }
            </>
        }
    </>
    );
};

export default GenericFilterSection;
