import React, { useEffect, useState, useRef } from 'react'
import {
    TextField, Typography, Grid, Button, Breadcrumbs, Paper, Card,
    Modal, IconButton, Autocomplete, Divider,
} from '@mui/material'
import theme, { buttonClasses, cardClasses, inputClasses, textClasses } from '../../../../static/styles/theme'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    getAllTemplates, getSelectedTemplate, getCommanConfig,
    getCompany, getFloorMapping, saveSinglePackageEntry, generateUniqueSerialNumber,
} from '../../../../redux/actions'
import SelectImage from "../../../../../src/static/images/data_entry/select_location.svg"
import { useForm } from 'react-hook-form'
import { capitalizeSentence, showErrorDynamicField, showToast, validateFieldsSingle, validateFieldsMultiple } from '../../../../services/functions';
import GenericLoader from '../../../Components/Generic/GenericLoader'
import { ShipmentSection } from '../Inward/Sections';
import PackageSection from './PackageSection';
import ItemSection from './ItemSection'
import PalletAddedModal from './PalletAddedModal'
import { keyPressActions } from '../../../../services/constants'
import ItemConfirmationPopup from './ItemConfirmationPopup'
import Cookies from 'js-cookie';


const style = (width) => {
    return {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 3,
    }
};

const PalletizationEntry = () => {
    const dispatch = useDispatch();
    const { register, handleSubmit, watch, getValues, setValue, control, reset, formState: { errors } } = useForm();
    const { register: register1, handleSubmit: handleSubmit1, watch: watch1, getValues: getValues1, setValue: setValue1, control: control1, reset: reset1, formState: { errors: errors1 } } = useForm();

    const [receivingConfig, setReceivingConfig] = useState({
        "pkgMultiple": {
            "isActive": false,
        },
        "itemMultiple": {
            "isActive": false,
        },
        "receivingTypeVisibility": {
            "isActive": false
        },
        "isWarehouseInput": {
            "isActive": false,
            "isRequired": false
        },
        "isFloorInput": {
            "isActive": false,
            "isRequired": false
        }
    })

    const bundleType = "uniqueItems"
    const [receivingMethod, setReceivingMethod] = useState(null)
    const [template, setTemplate] = useState(null)
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [allTemplateOptions, setAllTemplateOptions] = useState([]);
    const [allReceivingMethodOptions, setAllReceivingMethodOptions] = useState([]);
    const [showSuccess, setShowSuccess] = useState(null);
    const [wfDetail, setWfDetail] = useState({ warehouseList: [], floorList: [], selectedWarehouse: {}, selectedFloor: {} });
    const [templateLoader, setTemplateLoader] = useState(false);
    const [submitInProgress, setSubmitInProgress] = useState(false)
    const [isScannedOn, setIsScannedOn] = useState(true);
    const [packageNameLoader, setPackageNameLoader] = useState("");
    const [isPackageNameDisabled,setIsPackageNameDisabled] = useState(false);
    const [items, setItems] = useState([]);
    const [errors2, setErrors2] = useState([])
    const [viewModal,setViewModal]=useState({type:"",action:{totalItemCount:0,defaultNumberOfItemsInPallet:0}})
    const scannedRef = useRef(null);
    const usnTypedRef = useRef(null);
    const totalQuantity = watch1("count");
    const countTillNow = items.map((i) => Number(i.count)).reduce((a, b) => a + b, 0);
    const remainingItems = totalQuantity - countTillNow;
    let defaultUOMValue =receivingConfig?.defaultUOM?.value || ""

    
    const showWarehosueFloorHandler = (configDetail) => {
        if (configDetail?.isWarehouseInput?.isActive == true || configDetail?.isFloorInput?.isActive == true) {
            if (configDetail?.isWarehouseInput?.isActive) {
                dispatch(getCompany({ type: "warehouse", status: "active", projection: { name: 1, _id: 1 } }, (data) => {
                    let list = data?.data?.map((dl => ({ label: dl?.name, value: dl?._id }))) || []
                    if (list?.length) {
                        selectWarehouse(list?.at(0), list || [])
                    }
                    else {
                        setWfDetail({ warehouseList: list || [], floorList: [], selectedWarehouse: {}, selectedFloor: {} });
                    }
                }))
            }
            else {
                dispatch(getCompany({ type: "floor", status: "active", includeCount: false, projection: { name: 1, _id: 1, warehouseId: 1 } }, (data) => {
                    let list = data?.data?.map((dl => ({ label: dl?.name, value: dl?._id, warehouseId: dl?.warehouseId }))) || []
                    if (list?.length) {
                        selectFloor(list?.at(0) || {}, list || [])
                    }
                    else {
                        setWfDetail({ warehouseList: [], floorList: list || [], selectedWarehouse: {}, selectedFloor: {} });
                    }
                }))
            }
            if (configDetail?.isWarehouseInput?.isRequired == true || configDetail?.isFloorInput?.isRequired == true) {
                return;
            }
            else {
                fetchMappingArea()
            }

        }
        else {
            fetchMappingArea()
        }
    }

    const selectWarehouse = (selectedWarehouse = {}, dataList = []) => {
        let tempWfDetail = { warehouseList: dataList, selectedWarehouse, floorList: [], selectedFloor: {} }
        setWfDetail({ ...tempWfDetail })
        if (receivingConfig?.isFloorInput?.isActive == true && selectedWarehouse?.value) {
            dispatch(getCompany({ type: "floor", status: "active", warehouseId: selectedWarehouse?.value || "", projection: { name: 1, _id: 1, warehouseId: 1 } }, ({ data = [] }) => {
                let list = data?.map((dl => ({ label: dl?.name, value: dl?._id, warehouseId: dl?.warehouseId }))) || []
                if (list?.length) {
                    selectFloor(list?.at(0) || {}, list || [], selectedWarehouse, dataList)
                }
                else {
                    setWfDetail({ warehouseList: dataList, selectedWarehouse, floorList: list || [], selectedFloor: {} })
                    fetchMappingArea(selectedWarehouse?.value || "", "")
                }
            }))
        }
        else {
            fetchMappingArea(selectedWarehouse?.value || "", "")
        }
    }

    const selectFloor = (selectedFloor = {}, dataList = [], selectedWarehouse = {}, warehouseList = []) => {
        // console.log("tempWfDetailF",{selectedFloor, dataList,selectedWarehouse,warehouseList})
        setWfDetail({ selectedWarehouse, warehouseList, floorList: dataList, selectedFloor: selectedFloor || {} });
        fetchMappingArea(selectedWarehouse?.value || "", selectedFloor?.value || "")
    }

    const fetchMappingArea = (warehouseId = "", floorId = "") => {
        dispatch(getFloorMapping({ warehouseId, floorId }, (data) => {
            if(Number(getValues1("count"))>0){
                let mappingArea=process.env.REACT_APP_DEFAULT_AREA_NAME
                if (data?.length > 0) {
                    mappingArea=data?.find(dl=>dl?.isDefault)?.value || data?.at(0)?.value || process.env.REACT_APP_DEFAULT_AREA_NAME
                }
                
                const tempItem=items?.map((item)=>{
                    return {...item, mappingArea}
                })
                setItems(tempItem)
            }
        }))
    
    }

    const handleOpenSuccessModal = (response) => {
        setShowSuccess(response);
    }
    const handleCloseSuccessModal = () => {
        setShowSuccess(null);
    }

    const dispatchPackageNameGenerator = () => {
        setPackageNameLoader("packageName");
        dispatch(
            generateUniqueSerialNumber(
                { templateId: template?._id || "", serialNumberType: "packageName" },
                ({ success = false, message, data = "" }) => {
                    showToast(message, !success);
                    setTimeout(() => {
                        setPackageNameLoader("");
                    }, 500)
                    if (success) {
                        Cookies.set("palletPackageName", JSON.stringify(data), { expires: 7 });
                        setValue1("packageName", data);
                        setIsPackageNameDisabled(true);
                        // setPOGenerated(true);
                    }
                }
            )
        );
    };


    const handleAddMore = () => {
        handleClearAll();
        handleCloseSuccessModal();
        // if(receivingConfig?.isPackageGenerate?.isActive && receivingConfig?.isPackageGenerate?.isAutoGenerate){
        //     dispatchPackageNameGenerator();
        // }
        // Delay focus by 100ms
        setTimeout(() => {
          if (scannedRef.current) {
            scannedRef.current.focus();
          }
        }, 100);
        setTimeout(() => {
          if (usnTypedRef.current) {
            usnTypedRef.current.focus();
          }
        }, 100);
      };

    const validateFieldsPackageField = () => {

        let rowErrors = {};
        let row = getValues1() || {}

        if (!items || items.length == 0) {
            rowErrors["ItemDetail"] = {
                name: "Item Detail",
                message: `Item Details is required`
            }
        }
        let tempAttr = template?.othertemplate?.find(dl => dl?.templateType == "packageDetailTemplate")?.attributes || [];
        if (isScannedOn) {
            tempAttr = tempAttr?.filter(dl => dl?._id != "count")
        }
        let tempErrors = validateFieldsSingle(tempAttr, row || {}) || {}
        
        rowErrors = { ...rowErrors, ...tempErrors }
        return rowErrors;
    }


    const checkItemSubmit = () => {
        let zeroCount = items.filter(it => !it.count);
        if (zeroCount.length > 0) {
            showToast("Item count cannot be 0", true)
            return false;
        }
        let itemField = template?.otherTemplate?.find(dl => dl?.templateType == "itemDetailTemplate")?.attributes || []
        itemField = [itemField?.find(dl => dl?._id == "USN_Value")].concat(itemField?.filter(dl => dl?._id != "USN_Value"));
        let newErrors = validateFieldsMultiple(itemField || [], items || []);
        setErrors2(newErrors);

        if (newErrors.filter(row => Object.keys(row).length != 0).length != 0) {
            showToast("Form Error: Please check for errors", true);
            return false;
        } else if (!isScannedOn && remainingItems != 0) {
            showToast(`Form Error: Total items should be equal to ${countTillNow + remainingItems}, currently = ${countTillNow}`, true)
            return false;
        }
        return true;
    }

    const getMatchedOperator = (operator) => {
        let matchedOperator = receivingConfig?.numberOfItemsInPallet?.matchTypeOptions?.find(op => op?.value == operator);
        return matchedOperator;
    }

    const checkNumberOfItemsInPallet = (totalItemsCreated, matchType, data) => {
        let matchedOperator = getMatchedOperator(matchType);
        let defaultNumberOfItemsInPallet = receivingConfig?.numberOfItemsInPallet?.value || 0;
        
        // Handle all conditions
        let isCondition = true
        switch (matchedOperator?.value) {
            case "equal":
                if (totalItemsCreated !== defaultNumberOfItemsInPallet) {
                    isCondition= false;
                }
                break;
            case "greaterThan":
                if (totalItemsCreated <= defaultNumberOfItemsInPallet) {
                    isCondition= false;
                }
                break;
            case "lessThan":
                if (totalItemsCreated >= defaultNumberOfItemsInPallet) {
                    isCondition= false;
                }
                break;
            case "greaterThanOrEqualTo":
                if (totalItemsCreated < defaultNumberOfItemsInPallet) {
                    isCondition= false;
                }
                break;
            case "lessThanOrEqualTo":
                if (totalItemsCreated > defaultNumberOfItemsInPallet) {
                    isCondition= false;
                }
                break;
            default:
                break;
        }
        if(!isCondition){
            setViewModal({...viewModal,type:"confirmationPopup",action:{totalItemCount:totalItemsCreated,defaultNumberOfItemsInPallet:defaultNumberOfItemsInPallet, submissionData:data}})
            return false;
        }
    
        return true;  // Continue if no toast is shown
    };

    const submitForm = (data) => {
        
        let payload = {
            template_id: template._id,
            shipmentData: {}
        }
        const packageDetailData = watch1()
        payload['isSelectFromList'] = data?.isSelectFromList?.customShipment == true;
        delete data["isSelectFromList"];
        payload['shipmentData'] = data
        const otherData = [{
            ...packageDetailData, count: countTillNow,
            itemDetail: items || []
        }]
        payload['otherData'] = otherData

        if (wfDetail?.selectedWarehouse?.value) {
            payload['warehouseId'] = wfDetail?.selectedWarehouse?.value;
        }
        if (wfDetail?.selectedFloor?.value) {
            payload['floorId'] = wfDetail?.selectedFloor?.value;
            payload['warehouseId'] = wfDetail?.selectedFloor?.warehouseId;
        }

        // console.log("payload", payload)
        setSubmitInProgress(true)
        dispatch(
            saveSinglePackageEntry(payload, (response) => {
                setTimeout(() => {
                    setSubmitInProgress(false)
                }, 500)
                if (response) {
                    handleOpenSuccessModal(response);
                }
            })
        )
    }

    
    
    const handleSave = (data) => {

        const packageValidation = validateFieldsPackageField();

        if (Object.keys(packageValidation).length > 0) {
            showToast(Object.values(packageValidation)[0]?.message, true);
            return;
        }
        const itemValidation = checkItemSubmit();
        if (!itemValidation) {
            return;
        }
        // Check number of items in pallet validation
        if (receivingConfig?.numberOfItemsInPallet?.isActive && !checkNumberOfItemsInPallet(items?.length, receivingConfig?.numberOfItemsInPallet?.matchType,data)) {
            return;  // Stop further execution if validation fails
        }
        submitForm(data);
      
    }

    const handleClearAll = () => {
        let resetD = { ...getValues1() };
        let rKeys = Object.keys(resetD);
        for (let i = 0; i < rKeys.length; i++) {
            if (rKeys[i] == "mappingArea") {
                resetD[rKeys[i]] = "PUT IT ANY WHERE"
            }
            else {
                resetD[rKeys[i]] = resetD[rKeys[i]] && Array.isArray(resetD[rKeys[i]]) ? [] : ""
            }
        }
        reset1({
            ...resetD
        });
        setValue1("count", "")
        // showWarehosueFloorHandler(receivingConfig || {})
        setValue("isSelectFromList", {})
        setValue("customShipment", "")
        setItems([]);
        setErrors2([]);
        setTemplateLoader(false);
        setSubmitInProgress(false)
    }

    const onKeyPressEnterTrigger = (e) => {
        if (e.key === keyPressActions["submit"]) {
            handleSubmit1(handleSave, showErrorDynamicField)();
        }
    };
   
    useEffect(() => {
        dispatch(getCommanConfig({ configType: "palletDataEntry" }, (data) => {
            setReceivingConfig(data?.configDetail || {})
            setAllReceivingMethodOptions(data?.configDetail?.receivingMethod?.allOptions?.filter(dl=>dl?.value=="bothPalletItem"))
            setReceivingMethod(data?.configDetail?.receivingMethod?.allOptions?.find(dl=>dl?.value=="bothPalletItem"));
            setIsScannedOn(data?.configDetail?.showUSNInputOption?.value=="scan" ? true : false)
        }))
    }, [])

    useEffect(() => {
        if(receivingMethod){
            let payload = {
                type: "wms",
                templateType: "inwardDataEntry",
                status: "published",
                fetchAttributes: false,
                templateLevel: receivingMethod?.templateType || [],
            }
            dispatch(
                getAllTemplates(payload, (data) => {
                    setAllTemplateOptions(data)
                    // if (data && data.length == 1) {
                        setSelectedTemplate(data?.at(0) || null)
                    // }
                })
            )
        }
    }, [receivingMethod]);

    useEffect(() => {
        handleClearAll();
        if (selectedTemplate) {
            setTemplateLoader(true);
            // ////("Dispatching", selectedTemplate._id);
            dispatch(
                getSelectedTemplate({ template_id: selectedTemplate._id, fetchForInward: true,isForPalletEntry:true }, (data) => {
                    if (data?.otherTemplate?.length > 0) {

                        let itemAttrIndex = data?.otherTemplate?.findIndex(o => o.templateType == "itemDetailTemplate")
                        if (itemAttrIndex >= 0 && data.otherTemplate[itemAttrIndex]["attributes"]) {
                            let index = data.otherTemplate[itemAttrIndex]["attributes"]?.findIndex(dl => dl._id == "USN_Value")
                            if (index >= 0) {
                                data.otherTemplate[itemAttrIndex]["attributes"]?.splice(index, 1, {
                                    ...(data.otherTemplate[itemAttrIndex]["attributes"][index] || {}),
                                    required: true
                                })
                            }
                        }
                    }
                    setTemplateLoader(false)
                    setTemplate(data)
                })
            )
            showWarehosueFloorHandler(receivingConfig || {})
        }
    }, [selectedTemplate])

    useEffect(() => {
        if (!isScannedOn) {
            setErrors2([]);
        }
    }, [totalQuantity])

   
    return (
        <>
            <Grid>
                <Typography sx={{ ...textClasses.cardTitle }}>Pallet Data Entry</Typography>
                <Breadcrumbs sx={{ mb: 1 }}>
                    <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }}>
                        <Link style={{ color: theme.themeOrange, textDecoration: "none" }} to={"/dashboard/inventory/all"}>
                            Manage Inventory
                        </Link>
                    </Typography>

                    <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }} >
                        Pallet Data Entry
                    </Typography>
                </Breadcrumbs>
                <Paper sx={{ ...cardClasses.basic, mt: 1 }}>
                    <Grid container>
                        <Grid container item sm={3} xs={12} sx={{ p: 2 }} alignContent="center" justifyContent={"left"}>
                            <Autocomplete
                                value={receivingMethod}
                                onChange={(e, option) => {
                                    setReceivingMethod(option);
                                    setSelectedTemplate(null);
                                    handleClearAll()
                                    setAllTemplateOptions([])
                                }}
                                getOptionLabel={(option) => capitalizeSentence(option.label || "")}
                                size='small'
                                options={allReceivingMethodOptions}
                                sx={{
                                    ...inputClasses.shadowField,
                                    minWidth: "220px",
                                    m: 0,
                                    ml: 2,
                                    ".MuiInputBase-input": {
                                        fontSize: "14px",
                                        color: "#455A64"
                                    }
                                }}
                                renderInput={(params) => <TextField sx={{ color: "#455A64", ...textClasses.normalText }} {...params} label="Receiving Method" />}
                            />
                        </Grid>


                        <Grid container item sm={9} xs={12} sx={{ p: 2 }} alignContent="center" justifyContent="end">
                            <Autocomplete
                                value={selectedTemplate}
                                onChange={(e, option) => {
                                    setSelectedTemplate(option);
                                    handleClearAll()
                                }}
                                getOptionLabel={(option) => capitalizeSentence(option.name || "")}
                                size='small'
                                options={allTemplateOptions}
                                sx={{
                                    ...inputClasses.shadowField,
                                    minWidth: "220px",
                                    m: 0,
                                    ml: 2,
                                    ".MuiInputBase-input": {
                                        fontSize: "14px",
                                        color: "#455A64"
                                    }
                                }}
                                renderInput={(params) => <TextField sx={{ color: "#455A64", ...textClasses.normalText }} {...params} label="Template" />}
                            />
                            {receivingConfig?.isWarehouseInput?.isActive == true && selectedTemplate &&
                                <Autocomplete
                                    id="warehouse"
                                    value={wfDetail?.selectedWarehouse?.value && wfDetail?.selectedWarehouse || null}
                                    onChange={(e, option) => {
                                        selectWarehouse(option, wfDetail?.warehouseList || [])
                                        // handleClearAll()
                                    }}
                                    size='small'
                                    options={wfDetail?.warehouseList || []}
                                    getOptionLabel={(option) => option.label || ""}  // Ensure it displays the label properly
                                    sx={{
                                        ...inputClasses.shadowField,
                                        minWidth: "220px",
                                        m: 0,
                                        ml: 2,
                                        ".MuiInputBase-input": {
                                            fontSize: "14px",
                                            color: "#455A64"
                                        }
                                    }}
                                    renderInput={(params) => <TextField
                                        sx={{ color: "#455A64", ...textClasses.normalText }}
                                        {...params}
                                        label={`Warehouse ${receivingConfig?.isWarehouseInput?.isRequired == true ? "*" : ""}`}
                                    />
                                    }
                                />
                            }
                            {receivingConfig?.isFloorInput?.isActive == true && selectedTemplate &&
                                <Autocomplete
                                    id="floor"
                                    value={wfDetail?.selectedFloor?.value && wfDetail?.selectedFloor || null}
                                    onChange={(e, option) => {
                                        selectFloor(option, wfDetail?.floorList || [], wfDetail?.selectedWarehouse, wfDetail?.warehouseList || [])
                                        // handleClearAll()
                                    }}
                                    size='small'
                                    getOptionLabel={(option) => option.label || ""}  // Ensure it displays the label properly
                                    options={wfDetail?.floorList || []}
                                    sx={{
                                        ...inputClasses.shadowField,
                                        minWidth: "220px",
                                        m: 0,
                                        ml: 2,
                                        ".MuiInputBase-input": {
                                            fontSize: "14px",
                                            color: "#455A64"
                                        }
                                    }}
                                    renderInput={(params) => <TextField
                                        sx={{ color: "#455A64", ...textClasses.normalText }}
                                        {...params}
                                        label={`Floor ${receivingConfig?.isFloorInput?.isRequired == true ? "*" : ""}`}
                                    />
                                    }
                                />
                            }
                        </Grid>

                    </Grid>
                    <Divider sx={{ m: "0 5px 0 5px" }} />
                    {
                        templateLoader ?
                            <Grid container justifyContent={"center"} sx={{ m: 2 }}>
                                <GenericLoader />
                            </Grid>
                            : selectedTemplate && template
                                ?
                                <>
                                    <Grid container sx={{ p: 2 }}>
                                        {template?.attributes?.some((dl) => dl?._id !== "packageQuantity") && <Grid item sm={12} xs={12} sx={{ mb: 2 }}>
                                            <ShipmentSection
                                                template={{
                                                    ...template,
                                                    attributes: template?.attributes?.filter((dl) => dl?._id !== "packageQuantity"),
                                                }}
                                                spiType={"SPI"}
                                                // asn={asn}
                                                // asnTemplateHeader={asnTemplateHeader}
                                                receivingMethod={receivingMethod}
                                                formHooks={{ register, control, setValue, errors, getValues, reset }}
                                                isPalletEntry={true}
                                            />
                                        </Grid>}
                                        <Grid item sm={12} xs={12}>
                                            <PackageSection
                                                template={template}
                                                watch={watch1}
                                                formHooks={{
                                                    register: register1, watch: watch1, getValues: getValues1,
                                                    setValue: setValue1, control: control1, reset: reset1, errors: errors1
                                                }}
                                                isScanned={isScannedOn}
                                                generatePackageNameBtnData={receivingConfig?.isPackageGenerate || {}}
                                                onKeyPressEnterTrigger={onKeyPressEnterTrigger}
                                                packageNameLoader={packageNameLoader}
                                                setPackageNameLoader={setPackageNameLoader}
                                                isPackageNameDisabled={isPackageNameDisabled}
                                                setIsPackageNameDisabled={setIsPackageNameDisabled}

                                            />
                                        </Grid>
                                        <Grid item sm={12} xs={12}>

                                            <ItemSection
                                                template={template || {}}
                                                errors={errors2}
                                                setErrors2={setErrors2}
                                                items={items}
                                                setItems={setItems}
                                                setValue1={setValue1}
                                                totalQuantity={totalQuantity}
                                                remainingItems={remainingItems}
                                                countTillNow={countTillNow}
                                                bundleType={bundleType}
                                                defaultUOMValue={defaultUOMValue}
                                                isScannedOn={isScannedOn}
                                                setIsScannedOn={setIsScannedOn}
                                                scannedRef={scannedRef}
                                                usnTypedRef={usnTypedRef}
                                                showToggleScanManual={receivingConfig?.showUSNInputOption || {}}
                                                onKeyPressEnterTrigger={onKeyPressEnterTrigger}
                                            />
                                        </Grid>

                                        <Grid container item direction={"row"} sm={12} xs={12} sx={{ mt: 2 }}>
                                            <Button variant='outlined' size='small' sx={{ ...buttonClasses.outlined, color: theme.themeOrange, minWidth: "150px" }}
                                                onClick={handleClearAll}
                                            >
                                                Clear All
                                            </Button>
                                            <Button variant='contained' size='small'
                                                sx={{
                                                    ...buttonClasses.small, minHeight: "40px",
                                                    backgroundColor: theme.themeOrange, minWidth: "150px", ml: 2
                                                }}
                                                // onClick={() => saveEntry()}
                                                onClick={handleSubmit1(handleSave, showErrorDynamicField)}
                                                disabled={
                                                    // !isDataSubmittable ||
                                                    submitInProgress}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>

                                        <Modal
                                            open={showSuccess != null}
                                        >
                                            <Card sx={style("40%")}>
                                                <PalletAddedModal
                                                    spiType={"SPI"}
                                                    templateId={showSuccess && showSuccess.templateId}
                                                    shipmentId={showSuccess && showSuccess.shipmentId}
                                                    packageCount={showSuccess && showSuccess.totalPackage}
                                                    itemCount={showSuccess && showSuccess.totalItem}
                                                    handleAddMore={handleAddMore}
                                                    packageId={showSuccess && showSuccess.packageId}
                                                    success={showSuccess && showSuccess.success}
                                                    operationsAfterPalletEntry={receivingConfig?.operationsAfterPalletEntry || {}}
                                                />
                                            </Card>
                                        </Modal>
                                    </Grid>

                                </>
                                : <Grid container direction={"column"} justifyContent="center" alignItems="center" sx={{ p: 4 }}>
                                    <img src={SelectImage} />
                                    <Typography sx={{ ...textClasses.cardTitle, mt: 2 }}>select a template first..</Typography>
                                    <Typography sx={{ ...textClasses.t12n, textAlign: "center", maxWidth: "500px", mt: 2 }}>
                                        Hey, you need to select a previously created inward data entry template, to enter details for upcoming shipment and to automate the inward operation(s).
                                    </Typography>
                                </Grid>
                    }
                </Paper>

            </Grid>
            {viewModal.type == "confirmationPopup" &&
                <ItemConfirmationPopup
                    viewModal={viewModal}
                    setViewModal={setViewModal}
                    handleClosePopup={() => {
                        setTimeout(() => {
                            if (scannedRef.current) {
                                scannedRef.current.focus();
                            }
                        }, 100);
                    }}
                    handleSubmitData={submitForm}
                />
            }
        </>
    )
}

export default PalletizationEntry
