import React, { useEffect, useState } from 'react'
import { Grid, Typography, Button } from '@mui/material'
import BarcodeImage from "../../../../static/images/data_entry/barcode.svg"
import theme, { textClasses, buttonClasses } from '../../../../static/styles/theme'
import { downloadInventoryDataExcel } from '../../../../redux/actions'
import history from '../../../../services/history'
import { useDispatch, useSelector } from 'react-redux'
import GenerateLabel from "../../Inventory/Inventory/Label"
import Cookies from 'js-cookie';

export default function PalletAddedModal({ spiType, templateId, shipmentId, packageCount, itemCount, handleAddMore, packageId, success, operationsAfterPalletEntry }) {
    const dispatch = useDispatch();
    const [dataReceieved, isDataReceived] = useState(false)
    const [view, setView] = useState(false)
    const { userPermissions } = useSelector((state) => state.userPermission);
    let permissions = userPermissions || {};

    const successMessage = (() => {
        let message = "";
        if (spiType) {
            if (spiType == "shipmentOnly") {
                return message
            }
            else if (spiType == "shipmentPackageOnly") {
                message = message.concat(`with ${packageCount} packages`)
            }
            else if (spiType == "SPI") {
                message = message.concat(`with ${itemCount} items`)
            }
            else if (spiType == "shipmentItemOnly") {
                message = message.concat(`with ${itemCount} items`)
            }
        }
        return message;
    })();

    const handleDownload = () =>{
        isDataReceived(true)
        dispatch(
            downloadInventoryDataExcel({shipmentId:shipmentId,excelDataType:"inventoryPIExcel"}, (response) => {
                isDataReceived(false)
                // setIsDataSubmit(false)
                // if (response) {
                //     history.push("/dashboard/inventory/asn");
                // }
            })
        )
    }

    useEffect(() => {
        if (success) {
            Cookies.remove("palletPackageName");
        }
    }, [])

    useEffect(() => {
        if (operationsAfterPalletEntry?.isActive) {
            switch (operationsAfterPalletEntry?.value) {
                case "addMorePallet": permissions?.permissions?.some(item => item.permissionId === "singlePackageDataEntry") && handleAddMore();
                    break;
                case "viewAllInventory": permissions?.permissions?.some(item => item.permissionId === "viewInventory") && history.push("/dashboard/inventory/all");
                    break;
                case "generatePalletLabel": permissions?.permissions?.some(item => item.permissionId === "generateInventoryLabel") && setView(true);
                    break;
                case "downloadExcel": permissions?.permissions?.some(item => item.permissionId === "downloadPackageItemExcel") && handleDownload();
                    break;
                default: break;
            }
        }
    }, []);


    return (
            <>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <img src={BarcodeImage} height="250" />
                    <Typography sx={{ ...textClasses.boldText, fontSize: "20px" }}>
                        New Shipment Added To Your Inventory
                    </Typography>
                    <Typography sx={{ ...textClasses.boldText, fontSize: "12px", textAlign: "center", mt: 2, mb: 2 }}>
                        <span style={{ color: theme.themeOrange }}>Pallet ID : {packageId}</span> {successMessage} has been added with Gate In Pending status.
                    </Typography>
                </Grid>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    {permissions?.permissions?.some(item => item.permissionId === "viewInventory") && (
                        <Grid item sm={6} xs={6} justifyContent="center" alignItems="center">
                            <Button variant="outlined" fullWidth size="small" sx={{ ...buttonClasses.lynkitOrangeEmpty }} onClick={() => history.push("/dashboard/inventory/all")}>
                                View All Inventory
                            </Button>
                        </Grid>
                    )}
                    {permissions?.permissions?.some(item => item.permissionId === "singlePackageDataEntry") && (
                        <Grid item sm={6} xs={6} justifyContent="center" alignItems="center">
                            <Button variant="contained" fullWidth size="small" sx={{ ...buttonClasses.lynkitOrangeFill }} onClick={handleAddMore}>
                                Add More Pallet
                            </Button>
                        </Grid>
                    )}
                    {permissions?.permissions?.some(item => item.permissionId === "generateInventoryLabel") && templateId && packageId && (
                        <Grid item sm={12} xs={12}>
                            <Button variant="contained" fullWidth size="small" sx={{ ...buttonClasses.lynkitBlackFill }} onClick={() => setView(true)}>
                                Generate Pallet Label
                            </Button>
                        </Grid>
                    )}
                    {permissions?.permissions?.some(item => item.permissionId === "downloadPackageItemExcel") && (
                        <Grid item sm={12} xs={12} justifyContent="center" alignItems="center">
                            <Button variant="contained" fullWidth size="small" sx={{ ...buttonClasses.lynkitBlackFill }} onClick={handleDownload} disabled={dataReceieved}>
                                Download Excel
                            </Button>
                        </Grid>
                    )}
                </Grid>
    
                {view && (
                    <GenerateLabel
                        data={{ templateId, shipmentId }}
                        showModal={view}
                        handleCloseModal={() => setView(false)}
                        isItemExists={itemCount > 0}
                        isPackageExists={packageCount > 0}
                        isPalletEntry={true}
                    />
                )}
            </>
        )
    

}
