import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    Typography,
    Button
  } from "@mui/material";
  import theme, {
    textClasses,
    buttonClasses,
  } from "../../../static/styles/theme";
  import {
    fetchFile,
  } from "../../../redux/actions";
  import { Close} from "@mui/icons-material";
  import FileModal from "../../Modules/EntityMaster/FileModal";
import { dataReadFunction } from "../../../services/functions";
const DetailDialog = ({ rowDetail = {}, onClose,titleKey="SKU_ID" }) => {
    const { itemDetail: data, tableHeader: templateHeader } = rowDetail;
    const dispatch = useDispatch();
  
  
    const DetailRow = ({ title, children }) => {
      return (
        <Grid container sx={{ mt: 1 }}>
          <Grid item sm={4} xs={4}>
            <Typography sx={{ ...textClasses.t12n, color: "#827878" }}>
              {title}
            </Typography>
          </Grid>
          <Grid
            container
            item
            sm={8}
            xs={8}
            direction="row"
            alignItems={"center"}
          >
            {children}
          </Grid>
        </Grid>
      );
    };
  
    // useEffect(() => {
    //   if (data) {
    //     dispatch(
    //       getTemplateHeader({
    //         template_id: data.template_id,
    //       })
    //     );
    //   }
    // }, [data]);
    const [displayUrl, setDisplayUrl] = useState("");
    const [openModal, setOpenModal] = React.useState(false);
  
    const displayFile = (fileUrl, fileKey) => {
      const imageUrlRegex = /\.(jpg|jpeg|png|gif|bmp|tiff)$/i;
      const isValidImageUrl = imageUrlRegex.test(fileKey);
      if (isValidImageUrl) {
        setOpenModal(true);
        setDisplayUrl(fileUrl);
      } else {
        window.open(fileUrl, "_blank");
      }
    };
  
    const handleFile = (fileKey) => {
      setOpenModal(false);
      setDisplayUrl("");
      dispatch(fetchFile({ fileKey }, displayFile));
    };
  
    return (
      <Dialog
        open={data != null}
        onClose={onClose}
        scroll="paper"
        PaperProps={{ sx: { width: "100%", borderRadius: "8px" } }}
        maxWidth="sm"
      >
        <DialogContent sx={{ p: 0 }}>
          {data != null && (
            <Card
              sx={{
                m: 0,
                border: `1px solid ${theme.themeOrange}`,
                borderRadius: "8px",
              }}
            >
              <CardHeader
                title={
                  <Typography>
                    {/* <Sell fontSize='inherit' sx={{mr: 1}}/> */}
  
                    {templateHeader &&
                      templateHeader.map((h, i) => {
                        return h.name == titleKey ? (
                          <>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                color: "white",
                                lineHeight: "22px",
                              }}
                            >
                              {h.label} : {dataReadFunction(h,data)}
                            </Typography>
                          </>
                        ) : (
                          <></>
                        );
                      })}
                  </Typography>
                }
                sx={{ backgroundColor: theme.themeOrange }}
                action={
                  <IconButton size="small" onClick={onClose}>
                    <Close fontSize="inherit" sx={{ color: "white" }} />
                  </IconButton>
                }
              />
              <CardContent>
                {templateHeader &&
                  templateHeader.map((h, i) => {
                    return (
                      <DetailRow title={h.label}>
                        <Typography sx={{ ...textClasses.normalText, ml: 1 }}>
                          {dataReadFunction(h, data) ? (
                            h.type === "file" ? (
                              <div
                                onClick={() => handleFile(dataReadFunction(h, data))}
                              >
                                <span
                                  style={{
                                    ...buttonClasses.viewFile,
                                    cursor: "pointer",
                                  }}
                                >
                                  View File
                                </span>
                              </div>
                            ) : h.name == "currentStock" ?
                              <Button
                                sx={{
                                  p: 0.3,
                                  cursor: "text !important",
                                  ...buttonClasses.small,
                                  color: theme.themeGreen,
                                  backgroundColor: theme.themeLightGreen,
                                  border: `1px solid ${theme.themeGreen}`,
                                  "&:hover": { backgroundColor: theme.themeLightGreen },
                                }}
                              >
                                {data?.currentStock || 0} {data?.formData?.UOM || ""}
                              </Button>
                              : (
                                dataReadFunction(h, data)
                              )
                          ) : (
                            "-"
                          )}
                        </Typography>
                      </DetailRow>
                    );
                  })}
              </CardContent>
            </Card>
          )}
        </DialogContent>
        <FileModal
          openModal={openModal}
          displayUrl={displayUrl}
          setOpenModal={setOpenModal}
        />
      </Dialog>
    );
  };
  
  export default DetailDialog;