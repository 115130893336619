/* eslint-disable react/react-in-jsx-scope */
import { Box, Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { getFieldsLayoutForEntry } from "../../../Components/TemplateInputConfiguration";
import theme, { textClasses} from "../../../../static/styles/theme";
import { useState } from "react";
import { handleDynamicChange } from "../../../../services/formFunction";
import { keyPressActions } from "../../../../services/constants";



const KitAndBundleDetailSection = ({template, formHooks, onKeyPressEnterTrigger }) => {
// console.log("template found",template)
    const dispatch = useDispatch();

    const {register, control, setValue, errors} = formHooks;
    const [loader, setLoader] = useState("");
    const [items, setItems] = useState({});

    const allFields = template?.attributes?.map((field, i) => {
        // Dynamically assign handleSubmit function to the keyPressEnter property
        field.keyPressEnter = (e) => {
            if (e.key === keyPressActions["submit"]) {
                onKeyPressEnterTrigger();
            }
        };
        return field;
    })
    const autoFillOnFocus = () => {

    }


    const layout = getFieldsLayoutForEntry(allFields, register, control,autoFillOnFocus, errors,"");
// console.log("layout",layout,allFields)

    return (
        <Grid container>
            <Grid item sm={12} xs={10}>
                <Card sx={{borderRadius: "8px"}}>
                    <CardHeader
                        sx={
                            {
                                p: 2,
                                borderRadius: "8px 8px 0px 0px",
                                backgroundColor: theme.themeGray,
                                color: "black",
                                boxShadow: "0px 1px 10px gray"
                            }
                        }
                    
                        title={
                            <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                                <Typography sx={{...textClasses.normalText}}>1. Kit Details</Typography>
    
                            </Box>
                        }
                    />
                        <CardContent>
                            <Grid container sx={{mb: 1}} spacing={2}>
                                {   
                                    layout?.single?.map((field, i) => {
                                        
                                        return (
                                            <Grid container item xs={6} sm={4} justifyContent={"center"} alignItems="center" key={i}>
                                                {
                                                    field
                                                }
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                            
                            <Grid container spacing={2} sx={{pt: 1}}>
                                {
                                    layout?.multi?.map((field, i) => {
                                        return (
                                            <Grid container item xs={6} sm={4} justifyContent={"center"} alignItems="center" key={i}>
                                                {
                                                    field
                                                }
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                            
                           
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default KitAndBundleDetailSection;