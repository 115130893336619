import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
    useMemo 
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Grid,
    TextField,
    Typography,
    Box,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Autocomplete,
    TextareaAutosize,
    Breadcrumbs,
    Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import {
    createJob,
    getAllJobTypes,
    getAllUsers,
    generateUniqueSerialNumber,
    getCompany,
    getMappedArea,
    getAllTemplates,
    getJobProcessConfig,
    getInvForJobCreation
} from "../../../../redux/actions";
import theme, {
    textClasses,
    buttonClasses,
    inputClasses,
} from "../../../../static/styles/theme";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
    getAllInventory,
} from "../../../../redux/actions/Inventory";
import { selectStylesOverride } from "../../../../utils/util";
import TypeAndSearch from "../../../Components/TypeAndSearch";
import {
    showToast,
    capitalizeSentence,
    compareTwoArrayOrObject,
} from "../../../../services/functions";
import {
    StockTranferState,
    templateState,
    initialCountFor_SPI,
    ShipmentFilter,
    reasonComponent,
    pickingOptions
} from "../Constant";
import {
    gateINJobValidation,
    stockJobValidation,
    invAuditJobValidation,
    loction_validation,
    gateOutJobValidation,
} from "./validaterFunction";
import DynamicFormRender from "../../../Components/DynamicFormRender";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import InventoryCheckComponent from "./InventoryCheckComponent";
import QuantityWiseElement from "./QuantityWiseElement";
import LocationWiseCategory from "./LocationWiseCategory";
import InventoryComponent from "./InventoryComponent";
import StockTranferElement from "./StockTranferElement";


let initialJobTypeSelected = {
    isTypeGateIn: false,
    isTypeGateOut: false,
    isTypeQCInward: false,
    isTypeQCInhouse: false,
    isTypeInventoryCheck: false,
    isTypePickingAndPackaging: false,
    isTypePicking: false,
    isChangeLocation: false,
    isTypeInventoryAudit: false
}

export default function JobCreate() {
    const { state } = useLocation();
    const { sortingMethod, salesDetail, salesWarehouse, selectJobTypeId = null, pickingMethod } = state ? state : {}
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        register,
        watch,
        setValue,
        control,
        reset,
        formState: { errors },
        getValues
    } = useForm();

    const job_types = useSelector((state) => state?.job?.job_types?.data || []);
    const { jobProcessConfig } = useSelector((state) => state?.job); //{jobCreationCategory=[]}
    // const { AllUserData } = useSelector((state) => state.users);
    const { AllUserWithoutFilter } = useSelector((state) => state.users);
    const { allWFDetail = [] } = useSelector((state) => state.superadmin);
    const jobCreationData = useSelector((state) => state?.job?.jobCreation);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [isLoader, setIsLoader] = useState("");
    const [jobType, setJobType] = useState({ label: "", value: "", types: [] });
    const [warehouse, setWarehouse] = useState({
        label: "",
        value: "",
        single: false,
    });
    const [floor, setFloor] = useState({ label: "", value: "" });
    const [AllUserData, setAllUserData] = useState([]);
    const [cell, setCell] = useState([]);
    const [category, setCategory] = useState("");
    const [scanning, setScanning] = useState("");
    const [allProductType, setAllProductType] = useState([]);
    const [inventoryData, setInventoryData] = useState({
        shipments: [],
        packages: [],
        items: [],
    });
    const [dataFilter, setDataFilter] = useState({});
    const [filterValue, setFilterValue] = useState([]);
    const [worker, setWorker] = useState("");
    const [initialFetch, setInitialFetch] = useState(true);
    const [disableElement, setDisableElement] = useState({
        categoryDropdown: true,
        listDropDown: true,
        // scanningDropDown: true
    });
    const [remark, setRemark] = useState("");
    const [addedItems, setAddedItems] = useState([]);
    const [inventoryType, setInventoryType] = useState("package");
    const [isJobType, setIsJobType] = useState({ ...initialJobTypeSelected });

    const [stockTransferDetail, setStockTransferDetail] = useState({
        ...StockTranferState,
    });
    let [templateDetail, setTemplateDetail] = useState({ ...templateState });
    const [countFor_SPI, setCountFor_SPI] = useState({ ...initialCountFor_SPI });
    const [scanningInput, setScanningInput] = useState('')

    // For Picking and Packing
    const [packagingList, setPackagingList] = useState([]);
    const usePrevious = (value = "") => {
        const ref = useRef();

        useEffect(() => {
            ref.current = value;
        }, [value]);

        return ref.current;
    };

    let previousWarehouse = usePrevious(stockTransferDetail?.transferFromWarehouse?.value)
    //  useEffect(()=>{
    // 	if(jobType?.value){
    // 		dispatch(getAllUsers({userType:"worker",approved:true, jobActionId : jobType?.value}));
    // 	}
    //  },[jobType, dispatch])

    const getActionType = (action_Types = []) => {
        const aT = action_Types.map((type) => {
            let tempType = (typeof type == "string" && type) || type.actionId;
            return tempType;
        });
        return aT;
    };

    const jobTypeOptions = (allOption = null) => {
        return [
            // { label: "--select--", value: "", types: [] },
            ...(allOption || job_types || []).map((_) => ({
                label: capitalizeSentence(_.name || ""),
                value: _._id,
                types: getActionType(_?.actionTypes || []),
            })),
        ]
    };
    const warehouseOptions = (allOption = null) => {
        return [
            // { label: "--select--", value: "", },
            ...(allOption || allWFDetail || [])
                .filter((el) => el?.type == "warehouse")
                .map((_) => ({
                    label: _.name,
                    value: _._id,
                    single: _.single || false,
                })),
        ]
    }

    const floorOptions = (warehouse_Id, floorOption = null) => {
        let options = []; //{ label: "--select--", value: "", }
        for (let _ of (floorOption || allWFDetail || [])) {
            if (
                warehouse_Id &&
                _?.type == "floor" &&
                _?.warehouseId == warehouse_Id
            ) {
                options.push({
                    label: _.name,
                    value: _._id,
                });
            }
        }

        return options;
    };


    const categoryOptions = (allOption = null, actionTypes = null) => {
        return (allOption || jobProcessConfig?.jobCreationCategory || []).filter((category) => {
            return (
                category.type === "all" || compareTwoArrayOrObject(actionTypes || jobType?.types || [], category?.type || [], "one")
            );
        }) || [];

    };

    const handleJobTypeSelect = (e, option) => {
        clearStates(null, ["worker"]);
        if (option?.value) {
            let templateInfo = { visible: false, templateType: "", title: "" };

            let isTypeGateIn = option.types.includes("gateIn");
            let isChangeLocation = option.types.includes("changeLocation");
            let isTypeInventoryAudit = option.types.includes("inventoryAudit")
            let isTypeGateOut = option.types.includes("sendToUser");
            let isTypeStockTransfer = option.types.includes("stockTransfer");
            let isTypeQCInhouse = option.types.includes("inHouseQualityCheck")
            let isTypeQCInward = option.types.includes("inwardQualityCheck")
            let isTypeInventoryCheck = option.types.includes("inventoryCheck")
            let isTypePickingAndPackaging = option.types.includes("pickingAndPackaging");
            let isTypePicking = option.types.includes("picking");

            if (isTypeGateOut || isTypePicking) {
                templateInfo = {
                    visible: true,
                    templateType: "outwardDataEntry",
                    title: "Dispatch",
                };
                // This we can use in future if we use api of scanningOptions
                if (scanningOptions()?.length == 1) {
                    handleScanningSelect({}, scanningOptions()?.at(0) || {})
                }
            } else if (isTypeQCInhouse || isTypeQCInward) {
                templateInfo = {
                    visible: true,
                    templateType: "qualityCheck",
                    title: "Quality Check",
                    subTitle: "This form to be filled at the time of job completion",
                };
            } else if (isTypeInventoryCheck) {
                templateInfo = {
                    visible: true,
                    templateType: "qualityCheck",
                    title: "Inventory Check",
                    subTitle: "This form to be filled at the time of job completion",
                };
            } else if (isTypeStockTransfer) {
                templateInfo = {
                    visible: true,
                    templateType: "stockTransfer",
                    title: "Stock Transfer",
                };
            } else if (isTypePickingAndPackaging) {
                templateInfo = {
                    visible: true,
                    templateType: "outwardDataEntry",
                    title: "Dispatch",
                };
            }
            setTemplateDetail({ ...templateState, ...templateInfo });
            let tempJobTypes = {
                isTypeGateIn,
                isTypeGateOut,
                isTypeStockTransfer,
                isTypeQCInward, isTypeQCInhouse,
                isTypeInventoryCheck,
                isChangeLocation,
                isTypePickingAndPackaging,
                isTypePicking,
                isTypeInventoryAudit
            }
            setIsJobType(tempJobTypes);
            setJobType(option);
            if (!isTypeStockTransfer) {
                let filteredWarehouses = warehouseOptions();
                if (filteredWarehouses?.length === 1) {
                    jobWarehouseSelect({}, filteredWarehouses?.at(0) || {}, option.types)
                }
            }

            dispatch(
                getAllUsers({
                    userType: "worker",
                    approved: true,
                    jobActionId: option?.types || [],
                }, ({ data }) => {
                    setAllUserData(data || [])
                    if (data?.length === 1) {
                        handleWorkerSelect({}, {
                            label: data?.at(0)?.name,
                            value: data?.at(0)?._id,
                        })
                    }
                })
            );
            if (option?.types?.includes("stockTransfer")) {
                dispatch(
                    getAllUsers({
                        userType: "worker",
                        approved: true,
                        withoutFilterStockTransfer: true,
                    })
                );
            }
        }


    };

    const jobWarehouseSelect = (e, option, jobTypes = null) => {
        // ////(option,'option');
        let resetState = [
            "inventory",
            "floor",
            "category",
            "shipmentId",
            "remark",
            "addedItems",
            "stockTransfer",
            "dataFilter",
            "inventoryType",
            "cell",
        ]
        if (option?.value) {
            setWarehouse(option);
            if ((jobTypes || jobType?.types)?.some(dl => ["inwardQualityCheck", "gateIn"]?.includes(dl))) {
                // Auto-select logic for floor
                let filteredFloors = floorOptions(option?.value)
                if (filteredFloors?.length === 1) {
                    jobFloorSelect({}, filteredFloors?.at(0) || {}, jobTypes)
                    resetState.splice(1, 1)
                }
            }
            else {
            }
        }
        else {
            setWarehouse({ label: "", value: "", single: false });
        }


        clearStates(resetState);
    };

    const jobFloorSelect = (e, option, jobTypes) => {
        // ////({ option });
        let resetState = [
            "inventory",
            "category",
            "shipmentId",
            "remark",
            "addedItems",
            "dataFilter",
            "inventoryType",
        ]
        if (option?.value) {
            setFloor(option);
            if (categoryOptions(null, jobTypes)?.length === 1) {
                handleCategorySelect({}, categoryOptions(null, jobTypes)?.at(0) || {})
                resetState.splice(1, 1)
            }
        } else {
            setFloor({ label: "", value: "" });
        }

        clearStates(resetState);
    };

    const handleFloorForLocationWise = (e, option) => {
        if (option?.value) {
            setFloor(option);
        } else {
            setFloor({ label: "", value: "" });
        }
    };

    let allJobType = [
        { label: "Shipment ID", value: "Shipment ID", type: "all" },
        { label: "Location", value: "Location", type: "inventoryCheck" },
    ];


    const scanningOptions = () => {
        let arr = []
        if (jobProcessConfig?.jobScanningTypeOption?.length > 0) {
            if (["batchWise", "locationWise"].includes(pickingMethod?.value)) {
                arr = jobProcessConfig?.jobScanningTypeOption?.filter(dl => dl?.value !== "anyInventory") || []
            }
            else {
                arr = jobProcessConfig?.jobScanningTypeOption || []
            }
        }

        // arr.push({
        //   value: "restricted",
        //   label: "Restricted"

        // })
        // arr.push({
        //   value: "anyInventory",
        //   label: "Any Inventory"

        // })
        return arr;
    }
    const jobCompletionOptions = [
        { value: "scanSameTypeSingleQR", label: "Scan Same Type Single QR" },
        { value: "scanAllIndividualQR", label: "Scan All individual QR" },
    ];

    const handleCategorySelect = (e, option) => {
        clearStates([
            "inventory",
            "shipmentId",
            "remark",
            "addedItems",
            "stockTransfer",
            "dataFilter",
            "inventoryType",
            "cell",
        ]);

        setAllProductType([]);
        if (option?.value) {
            const { value } = option;
            setCategory(value);
            if (value.match(/shipment[_-\s]*id/i)) {
                //shipment
                if (
                    isJobType?.isTypeGateIn ||
                    isJobType?.isTypeGateOut ||
                    isJobType?.isTypeQCInhouse ||
                    isJobType?.isTypeQCInward ||
                    isJobType?.isTypePickingAndPackaging ||
                    isJobType?.isTypePicking
                ) {
                    fetchShipment({});
                }
            }
            // else if (value.match(/location[_-\s]*/i)) {
            //   let body = { type: "block", status: ["partial", "full", "available"] };
            //   // let body = {type:["block","rack"], status:["partial","full","available"] };
            //   //mapping Area
            //   let wf = {
            //     floorId: floor?.value || "",
            //     warehouseId: warehouse?.value || "",
            //   };
            //   dispatch(getGridDetail({ ...body, ...wf, fetch_for:"pickUp", }));
            // }
            else if (value.match(/package[_-\s]*name/i)) {
                if (isJobType?.isTypeGateIn || isJobType?.isTypeQCInhouse || isJobType?.isTypeQCInward) {
                    let wf = {
                        floorId: floor?.value || "",
                        warehouseId: warehouse?.value || "",
                    };

                    dispatch(
                        getMappedArea({ ...wf, fetchDistinct: true, allAreaNameOnly: true }, ({ data }) => {
                            setAllProductType(data?.length > 0 ? data : [""]);
                        }
                        )
                    );
                }
                setDisableElement({ ...disableElement });
            }
        }
        else {
            setCategory("");
        }


    };

    const handleScanningSelect = (e, option) => {
        setScanningInput(option)
        if (option?.value) {
            const { value } = option;
            setScanning(value);
        }
        else {
            setScanning("");
        }
    }
    const filterEnable = () => {
        return (isJobType?.isTypeGateOut || isJobType?.isTypePicking || isJobType.isTypeStockTransfer || isJobType.isTypeQCInhouse
            || false)
    }

    const fetchShipment = ({
        warehouseId,
        data_Filter = {},
        filter_Value = [],
        floorId,
        tempIsJobType=null
    }) => {
        setInventoryData({ shipments: [], packages: [], items: [] });
        let body = { dataFetchType: "shipmentWise", projection_for: "job" };
        const isJobTypeTemp = tempIsJobType || isJobType || []

        if (isJobTypeTemp?.isTypeGateIn || isJobTypeTemp?.isTypeQCInward) {
            body = { ...body, status: ["pending", "partialGateIn"] };
        } else {
            body = { ...body, status: ["partialGateIn", "gateIn", "partialGateOut"] };
        }

        let wf = {
            floorId: floorId || floor?.value || "",
            warehouseId: warehouseId || warehouse?.value || "",
        };


        if (isJobTypeTemp?.isTypeGateIn || isJobTypeTemp?.isTypeQCInward) {
            body["fetch_for"] = "beforeEntry";
            dispatch(
                getMappedArea(
                    { ...wf, fetchDistinct: true, allAreaNameOnly: true },
                    ({ data }) => {
                        body["mappingArea"] = data;
                        dispatch(
                            getAllInventory(body, ({ success = false, data = [] }) => {
                                setInventoryData({ shipments: data, packages: [], items: [] });
                            })
                        );
                        setAllProductType(data?.length > 0 ? data : [""]);
                    }
                )
            );
        }
        else {
            body["fetch_for"] = "afterEntry";
            body = { ...body, ...wf };

            if (filterEnable() && data_Filter?.value) {
                body = { ...body, filterType: data_Filter?.value };

                if (data_Filter?.value == "date" || data_Filter?.value == "dateRange") {
                    let isTrue = false;
                    if (data_Filter?.value == "date" && filter_Value?.length > 0) {
                        isTrue = true;
                    }
                    if (data_Filter?.value == "dateRange" && filter_Value?.length == 2) {
                        isTrue = true;
                    }
                    if (isTrue) {
                        body["filterValue"] = filter_Value;
                    } else {
                        delete body["filterType"];
                    }
                }
            }

            if (warehouseId) {
                body["warehouseId"] = warehouseId;
            }
            dispatch(
                getAllInventory(body, ({ success = false, data = [] }) => {
                    setInventoryData({ shipments: data, packages: [], items: [] });
                })
            );
            setAllProductType([]);
        }

        // ////("hhhhhh,dfhgeriuhgveriuvhgfr");
        setCountFor_SPI({ ...initialCountFor_SPI });
    };

    const workerOptions = [
        // { label: "--select--", value: "" },
        ...(AllUserData || []).map((_) => {
            return {
                label: _.name,
                value: _._id,
            };
        }),
    ];

    const workersWithoutFilterOptions = [
        // { label: "--select--", value: "" },
        ...(AllUserWithoutFilter?.data || []).map((_) => {
            return {
                label: _.name,
                value: _._id,
            };
        }),
    ];

    const handleWorkerSelect = (e, option) => {
        if (option) {
            // ////(option);
            const { value, label } = option; //e.target;
            setWorker(value);
        } else {
            setWorker("");
        }
    };

    const handleRemarkChange = (e) => {
        const { name, value } = e.target;
        setRemark(value);
    };

    const clearStates = (key = null, skip = []) => {
        /****all options */
        let allOptions = key || [
            "inventory",
            "dataFilter",
            "warehoue",
            "floor",
            "jobtype",
            "worker",
            "category",
            "shipmentId",
            "remark",
            "addedItems",
            "disableElement",
            "isJobType",
            "templateState",
            "stockTransfer",
            "inventoryType",
            "cell"
        ];

        // ////("clearStates",allOptions);
        if (allOptions.includes("jobtype")) {
            setJobType({ label: "", value: "", types: [] });
            setAllProductType([]);
        }
        if (allOptions.includes("filterValue")) {
            setFilterValue([]);
        }
        if (allOptions.includes("cell")) {
            setCell([]);
        }
        if (!skip.includes("worker") && allOptions.includes("worker")) {
            setWorker("");
        }
        if (allOptions.includes("inventoryType")) {
            setInventoryType("package");
        }
        if (allOptions.includes("category")) {
            setCategory("");
        }
        if (allOptions.includes("remark")) {
            setRemark("");
        }
        if (allOptions.includes("addedItems")) {
            setAddedItems([]);
            setPackagingList([]);
        }
        if (allOptions.includes("warehoue")) {
            setWarehouse({ label: "", value: "", single: false });
            setAllProductType([]);
        }
        if (allOptions.includes("floor")) {
            setFloor({ label: "", value: "" });
            setAllProductType([]);
        }
        if (allOptions.includes("disableElement")) {
            setDisableElement({ categoryDropdown: true, listDropDown: true });
        }
        if (allOptions.includes("isJobType")) {
            setIsJobType({ ...initialJobTypeSelected });
        }
        if (allOptions.includes("templateState")) {
            setTemplateDetail({ ...templateState });
        }
        if (allOptions.includes("stockTransfer")) {
            setStockTransferDetail({ ...StockTranferState });
        }
        if (allOptions.includes("dataFilter")) {
            setDataFilter({});
        }
        if (allOptions.includes("inventory")) {
            setInventoryData({ shipments: [], packages: [], items: [] });
        }
    };

    const renderTableFooter = () => {
        let S = [],
            P = [],
            I = [];

        for (let ele of addedItems) {
            if (ele?.shipmentId && !S.includes(ele.shipmentId)) {
                S.push(ele.shipmentId);
            }
            if (ele?.packageId && !P.includes(ele.packageId)) {
                P.push(ele.packageId);
            }
            if (ele?.itemId && !I.includes(ele.itemId)) {
                I.push(ele.itemId);
            }
        }

        if (S.length == 0 && P.length == 0 && I.length == 0) return;
        return (
            <Grid container sx={{ flexDirection: "row", mt: 2 }}>
                <Typography sx={{ fontWeight: "800", fontSize: ".9rem", mr: 4 }}>
                    Total Count(s) :
                </Typography>
                <Typography
                    sx={{
                        fontWeight: "500",
                        color: theme.themeOrange,
                        fontSize: ".9rem",
                        mr: 4,
                    }}
                >
                    Unique Shipments(s) :{S.length}
                </Typography>
                <Typography
                    sx={{
                        fontWeight: "500",
                        color: theme.themeOrange,
                        fontSize: ".9rem",
                        mr: 4,
                    }}
                >
                    Unique Package(s) : {P.length}
                </Typography>
                <Typography
                    sx={{
                        fontWeight: "500",
                        color: theme.themeOrange,
                        fontSize: ".9rem",
                        mr: 4,
                    }}
                >
                    Item(s) : {I.length}
                </Typography>
            </Grid>
        );
    };




    const disableCategoryAndList = () => {
        let listDropDown = true, categoryDropdown = true;
        let isTypeGateIn = isJobType?.isTypeGateIn || false
        let isTypeGateOut = isJobType?.isTypeGateOut || false
        let isTypeQCInward = isJobType?.isTypeQCInward || false
        let isTypeQCInhouse = isJobType?.isTypeQCInhouse || false
        let isTypePickingAndPackaging = isJobType?.isTypePickingAndPackaging || false
        let isTypePicking = isJobType?.isTypePicking || false
        let isChangeLocation = isJobType?.isChangeLocation || false;
        let isTypeInventoryAudit = isJobType?.isTypeInventoryAudit || false;

        if (jobType?.types?.length > 0) {
            if (isTypeGateIn || isJobType?.isTypeInventoryCheck || isTypeQCInward) {
                //if gate or gate Out
                if (warehouse?.value && floor?.value) {
                    categoryDropdown = false;
                }
                if (category) {
                    listDropDown = false;
                }
            }
            else if (isTypeQCInhouse || isTypeGateOut || isTypePickingAndPackaging || isChangeLocation || isTypePicking || isTypeInventoryAudit) {
                //if gate or gate Out
                if (warehouse?.value) {
                    categoryDropdown = false;
                }
                if (category) {
                    listDropDown = false;
                }
            }
            else if (jobType.types.includes("stockTransfer")) {
                categoryDropdown = false;
                // ////("ffff");
                if (stockTransferDetail?.transferFromWarehouse?.value) {
                    if (category == "shipmentId" && stockTransferDetail?.transferFromWarehouse?.value != previousWarehouse) {
                        setInventoryData({ shipments: [], packages: [], items: [] });
                        fetchShipment({
                            warehouseId: stockTransferDetail.transferFromWarehouse.value,
                            data_Filter: dataFilter || {}, filter_Value: filterValue || [],
                        });
                    }
                    listDropDown = false;
                }
            }
        }
        else {
            listDropDown = true;
            categoryDropdown = true;
        }

        setDisableElement({ listDropDown, categoryDropdown });
    };

    const handleFilterSelect = (e, option) => {
        setInventoryData({ shipments: [], packages: [], items: [] });
        let temp = {};
        if (option?.value) {
            temp = { ...option };
        }

        clearStates(["filterValue", "inventory", "addedItems"]);
        if (category == "shipmentId" && (isJobType?.isTypeStockTransfer ? stockTransferDetail?.transferFromWarehouse?.value : true)) {
            fetchShipment({ data_Filter: temp, filter_Value: [], warehouseId: stockTransferDetail?.transferFromWarehouse?.value || "", });
        }
        setDataFilter(temp);
    };

    const handleClearAll = () => {
        clearStates();
    };

    const submitData = (clickType = "submit") => {
        let req_payload = {
            remark: remark || "",
            userId: worker || "",
            jobData: [],
            location: [],
            jobCreationCategory: category,
        },
            floorId = "",
            warehouseId = "";
        //check jobType
        if (!jobType?.types?.length) {
            showToast("Please select Job Name!", true);
            return;
        }

        req_payload["jobTypeId"] = jobType.value || "";

        //category
        if (!category) {
            showToast("Please select category!", true);
            return;
        }
        if ((isJobType?.isTypeQCInhouse || isJobType?.isTypeQCInward)) {
            if (!scanning) {
                showToast("Please select quality check method!", true);
                return;
            }

            req_payload["jobCompletionType"] = scanning || "";
        }

        let validate_for_cell = false;

        // ////("templateDetail",templateDetail);

        for (let action of jobType.types) {
            let actionId = typeof action == "string" ? action : action.actionId;

            req_payload["jobData"].push({ jobType: actionId })

            //validateion for gate in job creation
            if (["gateIn", "inwardQualityCheck", "inventoryCheck"].includes(actionId)) {
                const { success, message, ...rest } = gateINJobValidation(
                    actionId,
                    req_payload,
                    floor,
                    warehouse
                );
                if (!success) {
                    showToast(message, true);
                    return;
                }

                floorId = rest.floorId;
                warehouseId = rest.warehouseId;
                validate_for_cell = actionId == "inventoryCheck";
            }
            if (actionId == "stockTransfer") {
                const { success, message, ...rest } = stockJobValidation(
                    "stockTransfer",
                    req_payload,
                    stockTransferDetail
                );

                if (!success) {
                    showToast(message, true);
                    return;
                }

                req_payload["jobExtraDetail"] = rest.jobExtraDetail || {};
                floorId = rest.floorId || "";
                warehouseId = rest.warehouseId || "";
            }
            if (actionId == "inventoryAudit") {
                const { success, message, ...rest } = invAuditJobValidation(
                    "inventoryAudit",
                    req_payload,
                    warehouse,
                    stockTransferDetail
                );

                if (!success) {
                    showToast(message, true);
                    return;
                }

                req_payload["jobExtraDetail"] = rest.jobExtraDetail || {};
                warehouseId = rest.warehouseId || "";
            }
            if (["sendToUser", "picking"].includes(actionId)) {
                req_payload["pickingMethod"] = pickingMethod?.value
            }
            if (["sendToUser", "picking", "inHouseQualityCheck"].includes(actionId)) {
                const { success, message, ...rest } = gateOutJobValidation(
                    actionId,
                    req_payload,
                    warehouse
                );

                if (!success) {
                    showToast(message, true);
                    return;
                }
                warehouseId = rest.warehouseId || "";

                if (dataFilter.value) {
                    req_payload["jobProcessFilter"] = {
                        filterType: dataFilter?.value,
                    };
                    if (
                        dataFilter?.value === "date" ||
                        dataFilter?.value === "dateRange"
                    ) {
                        if (filterValue?.length) {
                            const isValidDate = filterValue?.every((date) => date?.isValid());
                            if (isValidDate) {
                                req_payload["jobProcessFilter"]["filterValue"] = filterValue;
                            } else {
                                showToast("Please Add Valid Date", true);
                                return;
                            }
                        } else {
                            showToast("Please Add Date", true);
                            return;
                        }
                    }
                }
            }
            if (actionId == "pickingAndPackaging") {
                const { success, message, ...rest } = gateOutJobValidation(
                    "pickingAndPackaging",
                    req_payload,
                    warehouse
                );
                if (!success) {
                    showToast(message, true);
                    return;
                }
                warehouseId = rest.warehouseId || "";
            }
        }
        if (!isJobType.isTypePickingAndPackaging) {
            //Inventory Detail
            if (!addedItems || addedItems.length == 0) {
                showToast("Please Add Packages!", true);
                return;
            }
            const { success, message, ...rest } = loction_validation(
                validate_for_cell,
                addedItems,
                "",
                req_payload,
                floorId,
                warehouseId,
                category,
                isJobType.isChangeLocation ? "changeLocation" : ""
            );
            if (!success) {
                showToast(message, true);
                return;
            }
        }
        else {
            if (!packagingList || packagingList.length == 0) {
                showToast("Please provide Inventory Detail!", true);
                return;
            }
            let locations = [];
            for (let i = 0; i < packagingList.length; i++) {
                const pkg = packagingList[i];
                for (let j = 0; j < pkg.itemDetail.length; j++) {
                    const element = pkg.itemDetail[j];
                    // ////("OOOLLA", element);
                    locations.push({
                        shipmentId: element.shipmentId ? element.shipmentId : "",
                        packageId: element.packageId ? element.packageId : "",
                        itemId: element.itemId ? element.itemId : "",
                        count: element.count,
                        warehouseId: warehouseId,
                        masterPackageId: pkg.masterPackageId,
                    });
                }
            }
            req_payload["location"] = locations;
        }

        if (templateDetail?.templateOption?.value) {
            let formData = watch();
            req_payload["template_Id"] = templateDetail?.templateOption?.value;
            req_payload["templateData"] = formData;
        }
        if (category == "quantityWise") {
            req_payload["jobScanningType"] = scanning
        }

        // Sales order Addition
        if (salesDetail && salesDetail.length > 0) {
            req_payload["isWithSalesOrder"] = true;
        }

        // showToast("Ready for Gate In Job creation :: Need API Integration",false)
        // ////("submitData",req_payload);
        // console.log(req_payload, "sheck req payload")
        if (jobProcessConfig?.requiredFields?.userRequired && (!worker || typeof worker != "string")) {
            showToast("Please provide user detail!", true);
            return;
        }

        setDisableSubmit(true)
        dispatch(
            createJob(req_payload, ({ success, message }) => {
                showToast(message, !success);
                setTimeout(() => {
                    setDisableSubmit(false)
                }, 500)
                if (success) {
                    clearStates();
                    if (clickType == "submit") {
                        setTimeout(() => {
                            if (salesDetail?.length > 0) {
                                history.push("/dashboard/salesOrder");
                            }
                            else {
                                history.push("/dashboard/jobs");
                            }
                        }, 2000);
                    }
                }
            })
        );
    };

    let salesOrderProcess = (jobtypeOption = null, warehouseOption = null, allCategory) => {
        if (salesDetail && salesDetail.length > 0) {
            if ((jobtypeOption && jobtypeOption.length > 0)) {
                let desiredType = jobTypeOptions(jobtypeOption).find(jt => {
                    let condition = jt.types.includes("sendToUser")
                    if (selectJobTypeId) {
                        condition = jt?.value == selectJobTypeId
                    }
                    return condition
                });
                if (desiredType) {
                    handleJobTypeSelect({}, desiredType)
                    jobWarehouseSelect({}, { label: salesWarehouse?.name, value: salesWarehouse?._id, single: false })
                    if (allCategory.length > 0) {
                        let desiredCategory = categoryOptions(allCategory, desiredType?.types || null)?.find(cat => cat.value == "quantityWise");
                        // console.log("inside all category",desiredCategory,categoryOptions(allCategory))
                        if (desiredCategory) {
                            handleCategorySelect({}, desiredCategory);
                            handleFilterSelect({}, sortingMethod);
                        }
                        let desiredScanning = scanningOptions().find(sc => sc.value == "anyInventory");
                        if (desiredScanning) {
                            handleScanningSelect({}, desiredScanning)
                        }
                    }
                }
            }
        }
        setIsLoader(false)
    }

    let processFromInventory = (jobtypeOption = null, warehouseOption = null,allCategory=[]) => {
        if (state?.jobType && job_types) {
            if (["gateIn", "dispatch"].includes(state?.jobType)) {
                let type = state?.jobType == "dispatch" ? "sendToUser" : state?.jobType
                let tempJob = jobTypeOptions(jobtypeOption)?.find(dl => dl?.types?.length == 1 && dl?.types?.includes(type))
                if ((!jobType || !jobType?.value) && tempJob?.value) {
                    /***
                     * set job type first 
                     * next warehouse selection will be happen
                     * if job is gateIn then floor info will be required
                     */

                    handleJobTypeSelect({}, tempJob)

                    let warehouse = warehouseOptions(warehouseOption).find(dl => dl.value == state?.warehouseId)
                    if (warehouse) {
                        jobWarehouseSelect({}, warehouse)
                    }

                    if (state?.jobType == "gateIn") {
                        let floor = floorOptions(state?.warehouseId, warehouseOption?.filter(dl => dl.type == "floor") || null).find(dl => dl.value == state.floorId)
                        if (warehouse) {
                            jobFloorSelect({}, floor,jobtypeOption)
                        }
                    }
                    // const desiredCategory=allCategory.find(cat => cat.value == "shipmentId");
                    // const desiredCategory = categoryOptions(allCategory, tempJob?.types || null)?.find(cat => cat.value == "shipmentId");
                    // if(desiredCategory){
                    //     handleCategorySelect({}, desiredCategory);
                    // }
                    // else{
                    //     setCategory("shipmentId")
                    // }
                    setCategory("shipmentId")
                    const payload = {
                        jobType:
                            state?.jobType == "dispatch" ? "sendToUser" : state?.jobType,
                        inventoryType: state?.inventoryType,
                        inventoryID: state?.inventoryID,
                        warehouseId: state?.warehouseId,
                        ...(state?.jobType == "gateIn" ? { floorId: state?.floorId } : {}),
                    };
                    dispatch(getInvForJobCreation(payload, ({ success = false, data = [] }) => {
                        let formatData = []
                        if (success) {
                            formatData = tableDataFormater(data, state?.jobType == "dispatch" ? "sendToUser" : state?.jobType)
                        }
                        // console.log("formatData",formatData);
                        setAddedItems(formatData)
                    }))
                    const tempIsJobType={
                        isTypeGateIn: tempJob?.types.includes("gateIn"),
                        isTypeGateOut: tempJob?.types.includes("sendToUser")
                    }
                    fetchShipment({
                        warehouseId:state?.warehouseId,tempIsJobType,
                        ...(state?.jobType == "gateIn" ? { floorId: state?.floorId } : {}),
                    })
                }
            }
        }
        setIsLoader(false)
    }


    const fetchData=async()=>{
        let payload = { isForJobCreation: true }
        if (salesDetail && salesDetail.length > 0 || state?.jobType) {
            if (salesDetail && salesDetail.length > 0) {
                payload["addForcefully"] = ["createDispatchJob", "createPickingJob"]
            }
            setIsLoader(true)
        }
        const [tempJobConfig, tempAllJobType,tempAllWFDetail] = await Promise.all([
            new Promise((resolve) => {
                dispatch(getJobProcessConfig({ approved: true }, (data) => {
                    resolve(data?.data || {});
                }))
            }),
            new Promise((resolve) => {
                dispatch(getAllJobTypes(payload, (data) => {
                    resolve(data?.data || []);
                }));
            }),
            new Promise((resolve) => {
                dispatch(getCompany({ type: ["warehouse", "floor"], status: "active" }, (data) => {
                    resolve(data?.data || []);
                }));
            }),
        ]);
        // console.log("tempAllWFDetail",{tempJobConfig, tempAllJobType,tempAllWFDetail})
        let cond = salesDetail && salesDetail.length > 0 || state?.jobType || false
        if (cond && tempAllWFDetail?.length > 0 && tempAllJobType?.length && tempJobConfig?.jobCreationCategory?.length > 0) {
            if (state?.jobType) {
                // console.log("inside inv job")
                processFromInventory(tempAllJobType, tempAllWFDetail, tempJobConfig?.jobCreationCategory)
            }
            else if (salesDetail && salesDetail.length > 0) {
                // console.log("inside sales job")
                salesOrderProcess(tempAllJobType, tempAllWFDetail, tempJobConfig?.jobCreationCategory)
            }
        }
    }
    
    useEffect(() => {
        disableCategoryAndList();
    }, [jobType, stockTransferDetail, warehouse, floor, category]);

    useEffect(() => {
        if (initialFetch) {
            fetchData()
            // let payload = { isForJobCreation: true }
            // if (salesDetail && salesDetail.length > 0 || state?.jobType) {
            //     if (salesDetail && salesDetail.length > 0) {
            //         payload["addForcefully"] = ["createDispatchJob", "createPickingJob"]
            //     }
            //     setIsLoader(true)
            // }

          

            // dispatch(getJobProcessConfig({ approved: true }, (processOut) => {
            // }));
            // dispatch(getAllJobTypes(payload, (jobOut) => {
            // }));
            // dispatch(getCompany({ type: ["warehouse", "floor"], status: "active" }, (warehouseFloorData) => {
            // }));
            setInitialFetch(false);
        }
        // if (initialFetch) {
        //     setInitialFetch(false);
        // }
    }, []);

    // useEffect(() => {
    //     if (!initialFetch) {
    //         /////addForcefully=["createDispatchJob"]
    //         let cond = salesDetail && salesDetail.length > 0 || state?.jobType || false
    //         if (cond && allWFDetail?.length > 0 && job_types?.length && jobProcessConfig?.jobCreationCategory?.length > 0) {
    //             if (state?.jobType) {
    //                 // console.log("inside inv job")
    //                 processFromInventory(job_types, allWFDetail, jobProcessConfig?.jobCreationCategory)
    //             }
    //             else if (salesDetail && salesDetail.length > 0) {
    //                 // console.log("inside sales job")
    //                 salesOrderProcess(job_types, allWFDetail, jobProcessConfig?.jobCreationCategory)
    //             }
    //         }
    //     }

    // }, [job_types, allWFDetail, jobProcessConfig]);
    // console.log("ppppp",category,jobType);


    let tableDataFormater = (data = [], type = "sendToUser") => {
        return data.map((dl) => {
            let obj = {
                shipmentId: dl?.shipmentId,
                packageId: dl?.packageId,
                SKU_Number: dl?.SKU_Number,
                packageName: dl?.packageName,
                itemId: dl?.itemId,
                mappingArea: dl?.mappingArea,
                isPackageExist: dl?.packageId ? true : false,
                isItemExist: dl?.itemId ? true : false,
                count: Number(dl?.count || 0),
                isCountOnly: true,
                gateInTime: dl?.gateInTime || "",
            }

            if (type == "sendToUser") {
                if (dl.isItemExist) {
                    obj["balance_I_Count"] = Number(dl?.count || 0)
                }
                else if (dl.isPackageExist) {
                    obj["balance_P_Count"] = Number(dl?.count || 0)
                }
                else {
                    obj["balance_S_Count"] = Number(dl?.count || 0)
                }
            }
            else {
                obj["availableCount"] = Number(dl?.count || 0)
            }
            // ,
            // balance_S_Count,
            // balance_I_Count:dl?.count,

            // all_S_Count,
            // gateIn_S_Count,
            // all_P_Count,
            // gateIn_P_Count,
            // all_I_Count,
            // gateIn_I_Count,

            return obj
        })
    }
   

    const handleDateChange = useCallback(
        (date, type) => {
            setInventoryData({ shipments: [], packages: [], items: [] });

            let tem_data = [];
            if (!date.isValid()) {
                showToast(`Please select a valid date`, true);
            }
            if (type === "date") {
                tem_data = [date];
                // setFilterValue([date])
            } else if (type === "from") {
                if (filterValue?.length === 2) {
                    if (date?.isAfter(filterValue[1])) {
                        showToast("Start Date must be smaller than End Date", true);
                        tem_data = [undefined, filterValue[1] || undefined];
                        // setFilterValue(prev => [undefined, ...prev.slice(1)])
                    } else {
                        tem_data = [date, filterValue[1] || undefined];
                        // setFilterValue(prev => [date, ...prev.slice(1)]);
                    }
                } else {
                    tem_data = [date, filterValue[1] || undefined];
                    // setFilterValue(prev => [date, ...prev.slice(1)]);
                }
            } else if (type === "to") {
                let tempEndDate = date
                tempEndDate = dayjs(tempEndDate).hour(23).minute(59).second(59).millisecond(999)
                if (filterValue?.length) {
                    if (date?.isBefore(filterValue[0])) {
                        showToast("End Date must be Greater than Start Date", true);
                        tem_data = [filterValue[0] || undefined, undefined];
                        // setFilterValue(prev => [prev[0], undefined])
                    } else {
                        tem_data = [filterValue[0] || undefined, tempEndDate];
                        // setFilterValue(prev => [prev[0], date]);
                    }
                } else {
                    tem_data = [undefined, tempEndDate];
                    // setFilterValue(prev => [undefined, date]);
                }
            }

            setFilterValue(tem_data);

            if (category == "shipmentId" && (isJobType?.isTypeStockTransfer ? stockTransferDetail?.transferFromWarehouse?.value : true)) {
                fetchShipment({ data_Filter: dataFilter, filter_Value: tem_data, warehouseId: stockTransferDetail?.transferFromWarehouse?.value, });
            }
        },
        [filterValue]
    );

    const renderActionElement = (action) => {
        let status = []
        // ////("jhjhhhhhhhdfvegvgtbt>>>>renderActionElement");
        if (["gateIn", "inwardQualityCheck"].includes(action)) {
            status = ["pending", "partialGateIn"];
        }
        else {
            status = ["partialGateIn", "gateIn", "partialGateOut"];
        }

        if (["inventoryCheck"].includes(action)) {
            return (
                <>
                    <InventoryCheckComponent
                        listDropDown={disableElement.listDropDown}
                        addedItems={addedItems}
                        setAddedItems={setAddedItems}
                        status={["partial", "full", "available"]}
                        warehouseId={warehouse?.value || ""}
                        floorId={floor?.value || ""}
                        countFor_SPI={countFor_SPI}
                        setCountFor_SPI={setCountFor_SPI}
                        isTypeInventoryCheck={isJobType?.isTypeInventoryCheck}
                        jobCreationData={jobCreationData}
                    >
                    </InventoryCheckComponent>
                </>
            );
        }
        //  else if (["stockTransfer"].includes(action)) {
        //   children = <>
        //     <StockTranferElement
        //       allWarehouse={warehouseOptions()}
        //       allUser={workersWithoutFilterOptions}
        //       stockTransferDetail={stockTransferDetail}
        //       setStockTransferDetail={setStockTransferDetail}
        //       floorOptions={floorOptions}
        //       jobType={jobType}
        //     />
        //   </>

        // } 
        else if (["sendToUser", "picking"].includes(action) && category === "quantityWise") {
            return (
                <QuantityWiseElement
                    scanningInput={scanningInput}
                    addedItems={addedItems}
                    setAddedItems={setAddedItems}
                    inventoryType={inventoryType}
                    warehouseId={warehouse?.value}
                    dataFilter={dataFilter}
                    filterValue={filterValue}
                    setInventoryType={setInventoryType}
                    salesDetail={salesDetail}
                />
            );
        } else if (
            ["changeLocation", "inventoryAudit", "inHouseQualityCheck"].includes(action) && ["locationWise", "skuWise"].includes(category)
        ) {
            return (
                <LocationWiseCategory
                    isJobType={isJobType}
                    warehouse={warehouse}
                    floorOptions={floorOptions}
                    jobFloorSelect={handleFloorForLocationWise}
                    floor={floor}
                    cell={cell}
                    setCell={setCell}
                    setAddedItems={setAddedItems}
                    addedItems={addedItems}
                    clearStates={clearStates}
                    jobProcessConfig={jobProcessConfig}
                    category={category}
                    jobType={action}
                />
            );
        }

        else if (["shipmentId", "packageName"].includes(category))
            return (
                <>
                    <InventoryComponent
                        allProductType={allProductType}
                        disableElement={disableElement}
                        setDisableElement={setDisableElement}
                        dataFilter={dataFilter}
                        filterValue={filterValue}
                        addedItems={addedItems}
                        setAddedItems={setAddedItems}
                        status={status}
                        isTypeGateIn={isJobType?.isTypeGateIn}
                        isTypeGateOut={isJobType?.isTypeGateOut}
                        isTypePicking={isJobType?.isTypePicking}
                        isTypeQCInward={isJobType?.isTypeQCInward}
                        isTypeQCInhouse={isJobType?.isTypeQCInhouse}
                        isTypePickingAndPackaging={isJobType?.isTypePickingAndPackaging}
                        isTypeStockTransfer={isJobType?.isTypeStockTransfer}
                        countFor_SPI={countFor_SPI}
                        setCountFor_SPI={setCountFor_SPI}
                        packagingList={packagingList}
                        setPackagingList={setPackagingList}
                        inventoryData={inventoryData}
                        setInventoryData={setInventoryData}
                        category={category}
                        jobProcessConfig={jobProcessConfig}
                        warehouseId={isJobType?.isTypeStockTransfer ? (stockTransferDetail?.transferFromWarehouse?.value || "") : warehouse?.value || ""}
                        floorId={floor?.value || ""}
                        jobCreationData={jobCreationData}
                        filterEnable={filterEnable}
                    >
                        {action == "stockTransfer" ? (
                            <StockTranferElement
                                allWarehouse={warehouseOptions()}
                                allUser={workersWithoutFilterOptions}
                                stockTransferDetail={stockTransferDetail}
                                setStockTransferDetail={setStockTransferDetail}
                                floorOptions={floorOptions}
                                jobType={jobType}
                            />
                        ) : ""}
                    </InventoryComponent>
                </>
            );

        else return;
    };
    return (
        <>
            <Grid
                container
                sx={{ flexDirection: "column" }}
                data-testid="job-creation-dashboard"
            >
                <Grid item>
                    <Typography
                        sx={{ ...textClasses.cardTitle }}
                        data-testid="job-creation-header"
                    >
                        Manage Warehouse Jobs
                    </Typography>
                    <Breadcrumbs sx={{ mb: 1 }} data-testid="breadcrumb-jobC">
                        <Typography
                            sx={{
                                ...textClasses.normalText,
                                fontSize: "12px",
                                color: theme.themeOrange,
                            }}
                        >
                            <Link
                                style={{ color: theme.themeOrange, textDecoration: "none" }}
                                to="/dashboard/jobs"
                            >
                                Manage Warehouse Jobs
                            </Link>
                        </Typography>

                        <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>
                            Create Job
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <Card sx={{ mt: 2, border: "1px solid grey" }}>
                    <CardHeader
                        title={
                            <Typography sx={{ fontSize: "18px", fontWeight: 800 }}>
                                Create Job
                            </Typography>
                        }
                        action={
                            <>
                                {!isLoader &&
                                    <Box
                                        sx={{
                                            // marginTop: "25px",
                                            padding: "0px 5px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            flexWrap: "wrap",
                                            flexDirection: { xs: "column", sm: "row" },
                                            gap: 1
                                        }}>
                                        <Autocomplete
                                            data-testid="jobType-select"
                                            id="job-type-label"
                                            size="small"
                                            required
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={
                                                        <Typography sx={{ color: theme.themeOrange }}>
                                                            Job Name *
                                                        </Typography>
                                                    }
                                                    sx={{
                                                        ...selectStylesOverride,
                                                        color: theme.themeOrange,
                                                    }}
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <Box data-testid="MenuOption" component="li" {...props}>
                                                    {option?.label}
                                                </Box>
                                            )}
                                            sx={{
                                                width: 250,
                                                // minWidth: "10em",
                                                // "& .Mui-focused.MuiOutlinedInput-notchedOutline": {
                                                // 	borderColor: theme.themeOrange,
                                                // },
                                                mr: 1,

                                                "& legend span": {
                                                    color: theme.themeOrange,
                                                },
                                            }}
                                            options={jobTypeOptions()}
                                            isOptionEqualToValue={(option, value) => {
                                                return option?.label === value?.label;
                                            }}
                                            onChange={handleJobTypeSelect}
                                            value={jobType?.label || ""}
                                            disabled={salesDetail && salesDetail.length > 0}
                                        />
                                        {(isJobType?.isTypeGateIn ||
                                            isJobType?.isTypeGateOut ||
                                            isJobType?.isTypePicking ||
                                            isJobType?.isTypeQCInhouse ||
                                            isJobType?.isTypeQCInward ||
                                            isJobType?.isChangeLocation ||
                                            isJobType?.isTypeInventoryAudit ||
                                            isJobType?.isTypeInventoryCheck ||
                                            isJobType?.isTypePickingAndPackaging) && (
                                                <Autocomplete
                                                    id="warehouse-label"
                                                    data-testid="warehouse-select"
                                                    size="small"
                                                    required
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={
                                                                <Typography sx={{ color: theme.themeOrange }}>
                                                                    Warehouse *
                                                                    {/* Warehouse {isJobType?.isTypeQCInward ? "" :"*"} */}
                                                                </Typography>
                                                            }
                                                            sx={{
                                                                ...selectStylesOverride,
                                                                color: theme.themeOrange,
                                                            }}
                                                        />
                                                    )}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" {...props}>
                                                            {option.label}
                                                        </Box>
                                                    )}
                                                    sx={{
                                                        // minWidth: "10em",
                                                        width: 250,
                                                        // "& .Mui-focused.MuiOutlinedInput-notchedOutline": {
                                                        // 	borderColor: theme.themeOrange,
                                                        // },
                                                        mr: 1,
                                                        "& legend span": {
                                                            color: theme.themeOrange,
                                                        },
                                                    }}
                                                    options={warehouseOptions()}
                                                    onChange={(e, newoption) => jobWarehouseSelect(e, newoption)}
                                                    value={warehouse.label}
                                                    disabled={salesDetail && salesDetail.length > 0}
                                                />
                                            )}
                                        {(isJobType?.isTypeGateIn ||
                                            isJobType?.isTypeQCInward ||
                                            isJobType?.isTypeInventoryCheck) && (
                                                <Autocomplete
                                                    id="floor-label"
                                                    data-testid="floor-select"
                                                    size="small"
                                                    required
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={
                                                                <Typography sx={{ color: theme.themeOrange }}>
                                                                    Floor *
                                                                    {/* Floor {isJobType?.isTypeQCInward ? "" :"*"} */}
                                                                </Typography>
                                                            }
                                                            sx={{
                                                                ...selectStylesOverride,
                                                                color: theme.themeOrange,
                                                            }}
                                                        />
                                                    )}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" {...props}>
                                                            {option.label}
                                                        </Box>
                                                    )}
                                                    sx={{
                                                        // minWidth: "10em",
                                                        width: 250,
                                                        // "& .Mui-focused.MuiOutlinedInput-notchedOutline": {
                                                        // 	borderColor: theme.themeOrange,
                                                        // },
                                                        mr: 1,
                                                        "& legend span": {
                                                            color: theme.themeOrange,
                                                        },
                                                    }}
                                                    options={floorOptions(warehouse?.value || "")}
                                                    onChange={jobFloorSelect}
                                                    value={floor.label}
                                                />
                                            )}
                                    </Box>}
                            </>
                        }
                    />
                    <CardContent>
                        <Card sx={{ border: "1px solid grey" }} data-testid="task-detail">
                            <CardHeader
                                title={
                                    <Typography
                                        sx={{ fontSize: "18px", fontWeight: 700 }}
                                        data-testid="task-detail-header"
                                    >
                                        Task Details
                                    </Typography>
                                }
                            />
                            <CardContent>

                                {isLoader ?
                                    <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15, pt: 10, pb: 10 }}>
                                        <Grid item><GenericLoader /></Grid>
                                    </Grid>
                                    : <>
                                        <Grid container xs={12} spacing={2}>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Autocomplete
                                                    id="category-label"
                                                    size="small"
                                                    disabled={disableElement?.categoryDropdown || (salesDetail && salesDetail.length > 0)}
                                                    data-testid="category-select"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            required
                                                            label="Select Category"
                                                            sx={{
                                                                ...selectStylesOverride,
                                                            }}
                                                        />
                                                    )}
                                                    sx={{ flexBasis: "50%", mb: 1 }}
                                                    options={categoryOptions()}
                                                    onChange={handleCategorySelect}
                                                    value={
                                                        categoryOptions()?.find((dl) => dl.value == category)
                                                            ?.label || ""
                                                    }
                                                />
                                            </Grid>
                                            {
                                                pickingMethod && pickingMethod?.value && (isJobType?.isTypePicking || isJobType?.isTypeGateOut) && salesDetail && salesDetail.length > 0 &&
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <Autocomplete
                                                        id="picking-label"
                                                        size="small"
                                                        disabled={true}
                                                        data-testid="scanning-select"
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                required
                                                                label="Picking Method"
                                                                sx={{
                                                                    ...selectStylesOverride,
                                                                }}
                                                            />
                                                        )}
                                                        sx={{ flexBasis: "50%", mb: 1 }}
                                                        options={pickingOptions}
                                                        onChange={handleScanningSelect}
                                                        value={
                                                            pickingOptions?.find((dl) => dl?.value == pickingMethod?.value)?.label || ""
                                                        }
                                                    />
                                                </Grid>
                                            }
                                            {
                                                category == "quantityWise" &&
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <Autocomplete
                                                        id="scanning-label"
                                                        size="small"
                                                        // disabled={disableElement?.scanningDropdown || (salesDetail && salesDetail.length > 0)}
                                                        data-testid="scanning-select"
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                required
                                                                label="Select Scanning Type"
                                                                sx={{
                                                                    ...selectStylesOverride,
                                                                }}
                                                            />
                                                        )}
                                                        sx={{ flexBasis: "50%", mb: 1 }}
                                                        options={scanningOptions()}
                                                        onChange={handleScanningSelect}
                                                        value={
                                                            scanningOptions()?.find((dl) => dl.value == scanning)
                                                                ?.label || ""
                                                        }
                                                    />
                                                </Grid>
                                            }
                                            {
                                                (isJobType?.isTypeQCInhouse || isJobType?.isTypeQCInward) &&
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <Autocomplete
                                                        id="completion-method-label"
                                                        size="small"
                                                        data-testid="ompletion-methodselect"
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                required
                                                                label="Select Quanlity Check Method"
                                                                sx={{
                                                                    ...selectStylesOverride,
                                                                }}
                                                            />
                                                        )}
                                                        sx={{ flexBasis: "50%", mb: 1 }}
                                                        options={jobCompletionOptions}
                                                        onChange={handleScanningSelect}
                                                        value={scanningInput?.label || ""}
                                                    />
                                                </Grid>
                                            }
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Autocomplete
                                                    id="assign-user-label"
                                                    size="small"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={`Assign User${jobProcessConfig?.requiredFields?.userRequired ? "*" : ""}`}
                                                            sx={{
                                                                ...selectStylesOverride,
                                                            }}
                                                        />
                                                    )}
                                                    disabled={!jobType?.value}
                                                    data-testid="user-select"
                                                    sx={{ flexBasis: "50%", mb: 1 }}
                                                    options={workerOptions}
                                                    onChange={handleWorkerSelect}
                                                    value={
                                                        workerOptions.find((_) => _.value === worker)?.label ||
                                                        ""
                                                    }
                                                />
                                            </Grid>
                                            {filterEnable() && (
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <Autocomplete
                                                        id="shipmentFilter"
                                                        size="small"
                                                        disabled={!category || (salesDetail && salesDetail.length > 0)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Sorting Method"
                                                                sx={{
                                                                    ...selectStylesOverride,
                                                                }}
                                                            />
                                                        )}
                                                        sx={{ flexBasis: "50%", mb: 1 }}
                                                        options={ShipmentFilter}
                                                        onChange={handleFilterSelect}
                                                        value={dataFilter?.label || ""}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                        {(dataFilter?.value === "date" ||
                                            dataFilter?.value === "dateRange") && (
                                                <Grid container xs={12} mt={1} spacing={2}>
                                                    {dataFilter?.value === "date" && (
                                                        <Grid item xs={12} sm={6} md={4}>
                                                            <DatePicker
                                                                fullWidth
                                                                format="DD/MM/YYYY"
                                                                data-testid="receivedAt"
                                                                disableFuture
                                                                value={filterValue && filterValue[0]}
                                                                onChange={(d) => handleDateChange(d, "date")}
                                                                label="Date"
                                                                sx={{ ...inputClasses.textField, width: "100%" }}
                                                                slotProps={{ textField: { size: "small" } }}
                                                            />
                                                        </Grid>
                                                    )}
                                                    {dataFilter?.value === "dateRange" && (
                                                        <>
                                                            <Grid item xs={12} sm={6} md={4}>
                                                                <DatePicker
                                                                    fullWidth
                                                                    format="DD/MM/YYYY"
                                                                    data-testid="receivedAt"
                                                                    disableFuture
                                                                    value={filterValue && filterValue[0]}
                                                                    onChange={(d) => handleDateChange(d, "from")}
                                                                    label="From"
                                                                    sx={{ ...inputClasses.textField, width: "100%" }}
                                                                    slotProps={{ textField: { size: "small" } }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={4}>
                                                                <DatePicker
                                                                    fullWidth
                                                                    format="DD/MM/YYYY"
                                                                    data-testid="receivedAt"
                                                                    disableFuture
                                                                    value={filterValue && filterValue[1]}
                                                                    onChange={(d) => handleDateChange(d, "to")}
                                                                    label="To"
                                                                    sx={{ ...inputClasses.textField, width: "100%" }}
                                                                    slotProps={{ textField: { size: "small" } }}
                                                                />
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            )}

                                        {jobType?.types?.length && category
                                            ? jobType?.types.map((ele, index) =>
                                                renderActionElement(ele, index)
                                            )
                                            : null}
                                        {addedItems?.length ? renderTableFooter(addedItems) : ""}
                                    </>}
                            </CardContent>
                            <CardActions></CardActions>
                        </Card>

                        {/* Template Section */}
                        {templateDetail?.visible ? (
                            <TemplateRender
                                templateDetail={templateDetail}
                                setTemplateDetail={setTemplateDetail}
                                formHooks={{ register, control, setValue, errors, reset,getValues }}
                            />
                        ) : (
                            ""
                        )}
                        {isJobType?.isTypeInventoryAudit &&
                            <Grid mt={3} data-testid="remark-section">
                                <Box>
                                    <Typography sx={{ fontSize: "18px", fontWeight: 800 }}>
                                        Reason
                                    </Typography>
                                </Box>
                                <Box mt={2} sx={{ display: "flex", gap: "2em" }}>
                                    <Grid item sm={3}>
                                        <TypeAndSearch
                                            data-testid="select-reason"
                                            component={reasonComponent("inventoryAudit")}
                                            value={stockTransferDetail?.reason?.value}
                                            label={stockTransferDetail?.reason?.label}
                                            setEmptyOnChange={false}
                                            handleChange={(newValue, option) => {
                                                setStockTransferDetail({
                                                    ...stockTransferDetail,
                                                    reason: option,
                                                })
                                            }}
                                            reasonType={"inventoryAudit"}
                                            addNewOptions={{ isEnabled: true, label: 'Add New Reason' }}
                                        />
                                    </Grid>
                                </Box>
                            </Grid>
                        }
                        <Grid mt={3} data-testid="remark-section">
                            <Box>
                                <Typography sx={{ fontSize: "18px", fontWeight: 800 }}>
                                    Remark(s)
                                </Typography>
                            </Box>
                            <Box mt={2} sx={{ display: "flex", gap: "2em" }}>
                                <TextareaAutosize
                                    minRows={5}
                                    maxRows={5}
                                    style={{
                                        flexBasis: "50%",
                                        borderColor: "#cacaca",
                                        borderRadius: "12px",
                                        padding: "1em",
                                    }}
                                    onChange={handleRemarkChange}
                                    value={remark}
                                />
                            </Box>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Box sx={{ display: "flex", gap: "1em" }}>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{
                                    ...buttonClasses.lynkitOrangeEmpty,
                                    border: `1px solid ${theme.themeOrange}`,
                                    color: theme.themeOrange,
                                    "&:hover": {
                                        border: `1px solid ${theme.themeOrange}`,
                                    },
                                }}
                                disabled={isLoader}
                                data-testid="clear-btn"
                                onClick={handleClearAll}
                            >
                                Clear All
                            </Button>
                            {
                                (salesDetail && salesDetail.length > 0 || state?.jobType)
                                    ? ""
                                    : <Button
                                        variant="outlined"
                                        size="small"
                                        disabled={isLoader || disableSubmit}
                                        sx={{
                                            ...buttonClasses.lynkitOrangeEmpty,
                                            border: `1px solid ${theme.themeOrange}`,
                                            color: theme.themeOrange,
                                            "&:hover": {
                                                border: `1px solid ${theme.themeOrange}`,
                                            },
                                        }}
                                        data-testid="addnew-btn"
                                        onClick={() => submitData("submitAndCreateNew")}
                                    >
                                        Submit & Create New
                                    </Button>
                            }
                            <Button
                                variant="contained"
                                size="small"
                                data-testid="submit-btn"
                                disabled={isLoader || disableSubmit}
                                sx={{
                                    ...buttonClasses.lynkitOrangeFill,
                                    backgroundColor: theme.themeOrange,
                                    "&:hover": {
                                        backgroundColor: theme.themeOrange,
                                    },
                                }}
                                onClick={() => submitData("submit")}
                            >
                                Submit
                            </Button>
                        </Box>
                    </CardActions>
                </Card>
            </Grid>
        </>
    );
}


const TemplateRender = ({
    templateDetail: {
        visible = false,
        templateOption = {},
        templateType = "",
        title = "",
        subTitle = "",
        isLoader = false,
    },
    setTemplateDetail,
    formHooks,
}) => {
    const dispatch = useDispatch();
    const [templateList, setTemplateList] = useState([]);
    const [initial, setInitial] = useState(true);
    const { jobProcessConfig } = useSelector((state) => state?.job) || {}; //{jobCreationCategory=[]}
    const dispatchOrderNoGenerator = (templateId) => {
        setTemplateDetail((o) => ({ ...o,isLoader: true }));
        dispatch(
            generateUniqueSerialNumber(
                { templateId: templateId|| "",serialNumberType:"dispatchOrder" },
                ({ success = false, message, data = {} }) => {
                    showToast(message, !success);
                    if (success) {
                        formHooks.setValue("dispatchOrderNo", data);
                        setTemplateDetail((o) => ({ ...o, isLoader: false }));
                    }
                }
            )
        );
    };

    const dispatchOrderNoGeneratorVisible=useMemo(()=>{
        let condition=formHooks && templateType == "outwardDataEntry" && templateOption?.value && templateList?.length
        if(!condition) return false
        else if(jobProcessConfig?.dispatchOrderConfig?.isActive && jobProcessConfig?.dispatchOrderConfig?.isButtonVisible) return true
        else return false
    },[templateType,jobProcessConfig,templateList,templateOption])
    
    useEffect(() => {
        formHooks.reset();
        if (templateType) {
            dispatch(
                getAllTemplates({ templateType }, (data) => {
                    setTemplateList(data || []);
                    if (data?.length > 0) {
                        let firstTemplate=data?.at(0) || {}
                        setTemplateDetail({
                            visible,
                            title,
                            templateType,
                            subTitle,
                            templateOption: {
                                value: firstTemplate._id,
                                label: firstTemplate.name,
                                index: 0,
                            },
                        });
                        if (initial && templateType == "outwardDataEntry" && jobProcessConfig?.dispatchOrderConfig?.isActive && jobProcessConfig?.dispatchOrderConfig?.isAutoGenerated ) {
                            dispatchOrderNoGenerator(firstTemplate?._id || "")
                        }
                    }
                })
            );
        } 
        else {
            setTemplateList([]);
        }
        if(initial){
            setInitial(false)
        }
    }, [templateType]);
    //sx={{ border:"1px solid grey" }}
    return (
        <Card sx={{ mt: 4, border: "1px solid grey" }}>
            <CardHeader
                title={
                    <Box sx={{ display: "flex" }}>
                        <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
                            {title} Details
                            {subTitle && (
                                <Typography sx={{ fontSize: "12px", color: "#F77200" }}>
                                    ({subTitle})
                                </Typography>
                            )}
                        </Typography>

                        {dispatchOrderNoGeneratorVisible && (
                                <Button
                                    variant="contained"
                                    size="small"
                                    data-testid="DA-btn"
                                    sx={{
                                        ...buttonClasses.lynkitOrangeEmpty,
                                        ml: 2,
                                    }}
                                    disabled={isLoader}
                                    onClick={() => dispatchOrderNoGenerator(templateOption?.value || "")}
                                >
                                    Generate{" "}
                                    {(templateList[templateOption?.index] &&
                                        templateList[templateOption?.index].attributes?.find(
                                            (dl) => dl._id == "dispatchOrderNo"
                                        )?.label) ||
                                        "DA Number"}
                                </Button>
                            )}
                    </Box>
                }
                action={
                    <Box
                        sx={{
                            padding: "15px 5px 0 5px",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Autocomplete
                            id="template-label"
                            size="small"
                            required
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={
                                        <Typography sx={{ color: theme.themeOrange }}>
                                            Select {(title == "Dispatch" && "OutWard") || title}{" "}
                                            Template 
                                        </Typography>
                                    }
                                    sx={{
                                        ...selectStylesOverride,
                                        color: theme.themeOrange,
                                    }}
                                />
                            )}
                            sx={{
                                width: 250,
                                mr: 1,
                                "& legend span": {
                                    color: theme.themeOrange,
                                },
                            }}
                            options={
                                (templateList &&
                                    templateList.map((dl, index) => ({
                                        value: dl._id,
                                        label: dl.name,
                                        index,
                                    }))) ||
                                []
                            }
                            onChange={(e, option) => {
                                setTemplateDetail({
                                    visible,
                                    title,
                                    templateType,
                                    subTitle,
                                    templateOption: option || {},
                                    isLoader: false,
                                });
                                const daNumber = formHooks.getValues("dispatchOrderNo") || {};
                                formHooks.reset();
                                if (daNumber && templateType == "outwardDataEntry") {
                                    formHooks.setValue("dispatchOrderNo", daNumber);
                                }
                            }}
                            value={templateOption.label || null}
                        />
                    </Box>
                }
                sx={{ pt: 0, mt: 1, mb: 1, pb: 0 }}
            />
            <CardContent>
                <DynamicFormRender
                    formHooks={{
                        ...formHooks,
                        action: templateType == "qualityCheck" ? "view" : "add",
                    }}
                    templateId={templateOption?.value || ""}
                />
            </CardContent>
            <CardActions></CardActions>
        </Card>
    );
};
