import {
	FETCH_ALL_JOBS,
	FETCH_ALL_WORKERS,
	FETCH_CELL_RACK_LOCATION,
	FETCH_JOB_DETAIL,
	FETCH_JOB_STATUS_COUNT,
	FETCH_JOB_TYPE_DETAILS,
	FETCH_JOB_TYPES,
	FETCH_JOB_TYPES_COUNT,
	FETCH_SHIPMENTS,
	FETCH_TEMPLATE_HEADERS,
	FETCH_WAREHOUSE_FLOORS,
	RESET_ALL_JOBS,
	FETCH_ALL_JOB_ACTION,
	FETCH_ALL_JOBTYPE_ACTION_ID,
	FETCH_JOB_LOCATION_DETAIL,
	FETCH_JOB_PENDENCY_DETAIL,
	FETCH_JOB_PROCESS_CONFIG,
	GENERATE_JOB_DETAIL,
	FETCH_JOB_CREATION,
	FETCH_WORK_ORDER_LIST_CONFIG,
	FETCH_JOB_SHIPMENT_DETAIL,
	FETCH_SUGGESTION_LOCATION
} from "../types";

const INIT_STATE = {
	jobs: {
		data: [],
		statusCount: {
			total: 0,
			pending: 0,
			ongoing: 0,
			completed: 0,
		},
		totalCount: 0,
	},
	jobDetail: {},
	jobLocationDetail: {},
	jobPendencyDetail: {},
	jobProcessConfig: {},
	autoGenerateDA_Number: null,
	location_cell_rack: {},
	warehouse_floors: {},
	template_headers: [],
	job_types: {},
	job_types_count: {},
	workers: [],
	shipments: [],
	allJobActions:[],
	allCompanyWiseJobActions:[],
	generatedJobDetail : undefined,
	workOrderListConfig: [],
	jobShipmentDetail: [],
	jobShipmentDetailCount: 0,
	suggestedLocations: {},
	jobTypeDetails:{}
};

export default (state = INIT_STATE, action) => {
	const { type, payload } = action;
	const { data, count=0 } = payload || {};
	switch (type) {
		case FETCH_ALL_JOB_ACTION: {
			return {...state,allJobActions: data};
		}
		case FETCH_ALL_JOBTYPE_ACTION_ID: {
			return {...state,allCompanyWiseJobActions: data};
		}
		case FETCH_ALL_JOBS: {
			return {
				...state,
				jobs: { ...state.jobs, data:data || [], totalCount: count },
			};
		}
		case FETCH_JOB_STATUS_COUNT: {
			return {
				...state,
				jobs: { ...state.jobs, statusCount: data },
			};
		}
		case GENERATE_JOB_DETAIL:
            return { ...state, generatedJobDetail: payload };
		case FETCH_JOB_TYPE_DETAILS:
            return { ...state, jobTypeDetails: payload || {}};
		case FETCH_JOB_DETAIL: {
			return { ...state, jobDetail: payload };
		}
		case FETCH_JOB_LOCATION_DETAIL: {
			return { ...state, jobLocationDetail: payload };
		}
		case FETCH_JOB_PENDENCY_DETAIL: {
			return { ...state, jobPendencyDetail: payload };
		}
		case FETCH_JOB_PROCESS_CONFIG: {
			return { ...state, jobProcessConfig: payload };
		}
		case FETCH_CELL_RACK_LOCATION: {
			return { ...state, location_cell_rack: data };
		}
		case FETCH_WAREHOUSE_FLOORS: {
			return { ...state, warehouse_floors: data };
		}
		case FETCH_TEMPLATE_HEADERS: {
			return { ...state, template_headers: data };
		}
		case FETCH_JOB_TYPES: {
			return { ...state, job_types:payload };
		}
		case FETCH_JOB_TYPES_COUNT: {
			return { ...state, job_types_count:payload };
		}
		case FETCH_ALL_WORKERS: {
			return { ...state, workers: data };
		}
		case FETCH_SHIPMENTS: {
			return { ...state, shipments: data };
		}
		case FETCH_JOB_CREATION :return{...state,jobCreation : action.payload}
		case FETCH_WORK_ORDER_LIST_CONFIG :return{...state, workOrderListConfig: action.payload}
		case FETCH_JOB_SHIPMENT_DETAIL :return{...state, jobShipmentDetail: action?.payload?.data, jobShipmentDetailCount: action?.payload?.count}
		case FETCH_SUGGESTION_LOCATION :return{...state, suggestedLocations: action.payload}
		default:
			return state;
	}
};
