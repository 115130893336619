import React, { useEffect } from 'react'
import { Typography, Grid } from '@mui/material'
import theme from "../../../static/styles/theme"

import GenericComponentsCollapse from '../../Components/Generic/GenericComponentsCollapse'
import { useDispatch, useSelector } from 'react-redux'
import { getAllTemplates } from '../../../redux/actions'
import { preDefineElement } from './componentList'

export default function StandardComponents({stdComponents, predefinedElements}) {

    const dispatch = useDispatch();
    const templates = useSelector(state => state.templates.allTemplates);
    const freeTemplates = templates.filter(t => t.templateType == "freeTemplate")
    // ////('Free Templates');
    // ////(freeTemplates);
    ////("Standard Components");
    ////(stdComponents);

    useEffect(() => {
        dispatch(getAllTemplates({
            type: "wms",
            projection: { attributes: 1, dataEntryType: 1, name: 1, templateCode: 1, templateType: 1, },
            templateType: "freeTemplate",
            status:"published"
        }));
    }, [])
//console.log(freeTemplates, "show freeTemplates")

    return (
        <Grid container sx={{zIndex: 100, mr: 2,overflowY: "auto", maxHeight:'75vh'}}>
            <Grid item sm={12}>
                <Typography variant='h6' sx={{color: theme.themeOrange}}>Standard Form Component</Typography>
                <Typography variant='caption'>
                    Drag a field type to one of the sections on the left to create a custom field for this issue type.
                </Typography>

                {predefinedElements?.elements?.length > 0 && <GenericComponentsCollapse
                     key={1}
                     index={1}
                     title={predefinedElements._id}
                     type="list"
 
                     components={predefinedElements.elements}
                 />}
                
                {
                    stdComponents && stdComponents.filter(section => ["Section Breaker", "Grid"].indexOf(section._id) < 0).map((section, i) => {
                        return (
                            <GenericComponentsCollapse
                                key={i+1}
                                index={i+2}
                                title={section._id}
                                components={section.elements}                            
                            />
                        )
                    })
                }
               {freeTemplates?.length > 0 && <GenericComponentsCollapse
                    key={4}
                    index={4+1}
                    title={"Free Templates"}
                    type="list"
                    components={freeTemplates}
                />}
            </Grid>
            
            
        </Grid>
    )
}
