import React from "react";
import { Badge, styled } from "@mui/material";

const CustomizedBadge = (props = {}) => {
    const { title, backgroundColor, color, hideValue, children } = props;

    const StyledBadge = styled(Badge)(({ theme }) => ({
        "& .MuiBadge-badge": {
            backgroundColor: backgroundColor || "blue", 
            color: color || "white", 
            fontSize: "0.75rem",
            padding: "3px 4px",
            height: "auto",
            minWidth: "auto",
            borderRadius: "8px",
            top: -3,
            left:-4
        },
    }));

    return (
        <StyledBadge badgeContent={title} invisible={hideValue} anchorOrigin={{vertical: 'top', horizontal: 'left',}}>
            {children}
        </StyledBadge>
    );
};

export default CustomizedBadge;
