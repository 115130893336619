import React, { useEffect } from 'react'
import { Box, Typography, Card, CardContent, CardHeader, Avatar, IconButton, Grid, Paper, Skeleton } from "@mui/material";
import theme from '../../../../../static/styles/theme'
import ClearIcon from '@mui/icons-material/Clear';
import DateRangeFilter from '../../DateRangeFilter';


const CountCardWidget = ({ item, setLayout, styleView = {}, isDefaultDashboard = false, dataLoader = false, children }) => {
    // const onFilterChange= (value, customRange)=>{
    //     console.log('value, customRange',value, customRange);   
    // }
    return (
        <Paper elevation={3} sx={{ width: 'inherit', height: 'inherit', p: 1, ...styleView, overflowY: 'auto' }} >
            {/* Delete Icon Button */}
            <IconButton
                size="small"
                sx={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    color: theme.themeOrange,
                }}
                onClick={() => {
                    setLayout((prev) => prev.filter((l) => l.i !== item.i));
                }}
            >
                <ClearIcon fontSize="small" />
            </IconButton>

            {/* Header Section */}
            <Box sx={{ pt: 2, pb: 2, display: "flex", alignItems: "center" }}>
                <Avatar className="br-1" variant="square">
                    {item?.icon ? (
                        <span
                            className="material-symbols-outlined"
                            style={{ fontSize: "40px" }}
                        >
                            {item.icon}
                        </span>
                    ) : (
                        <span
                            className="material-symbols-outlined"
                            style={{ fontSize: "40px" }}
                        >
                            text_fields
                        </span>
                    )}
                </Avatar>
                <Typography
                    sx={{ textAlign: "left", fontWeight: "bold", ml: 1 }}
                    variant="h6"
                    gutterBottom
                >
                    {item?.title}
                </Typography>
            </Box>

            {/* Date Range Filter Section */}
            {
                item?.type != 'warehouse' &&
                    isDefaultDashboard
                    ? <Box sx={{
                        padding: 2
                    }}>
                        {children}
                    </Box>
                    : item?.isDateFilter
                        ? <Box sx={{ display: "flex", justifyContent: "flex-end", px: 2, mt: -1 }}>
                            <DateRangeFilter dateFilterOption={item?.dateFilterOption} />
                        </Box>
                        : ""
            }

            {/* Content Section */}
            {!dataLoader ? <Box
                sx={{
                    flexGrow: 1,
                    // maxHeight: "60px",
                    // overflowY: "auto",
                    pt: 0,
                    pb: 2,
                }}
            >
                {item?.values?.map((dl, index) => (
                    <Typography key={index} sx={{ textAlign: "left" }} variant="body2">
                        {dl.category}: {dl.value}
                    </Typography>
                ))}
            </Box> :  <Box sx={{ width: 'inherit', height: 'inherit'}}>
      <Skeleton  sx={{ width: 'inherit', height: 20}} />
      <Skeleton animation="wave" sx={{ width: 'inherit', height: 20}} />
      <Skeleton animation={false} />    
      </Box>
            }
        </Paper>


    )
}

export default CountCardWidget;