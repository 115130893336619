import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Grid,
    Typography,
    Button,
    TextField,
    FormLabel,
    FormControlLabel,
    Radio,
    Autocomplete,
}
    from "@mui/material";
import theme, {
    buttonClasses,
    inputClasses,
    textClasses,
} from "../../../static/styles/theme";
import {
    getAllWarehouses, getAllJobTypes, getCommanConfig
} from "../../../redux/actions"
import history from "../../../services/history";
import { capitalizeSentence } from '../../../services/functions'
import AlertDialog from "../../Components/DialogBox/dialogbox";
import GenericLoader from "../../Components/Generic/GenericLoader";

const AllocateStockModalBox = ({ status, entry, handleClose }) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState("");
    const [sortingMethod, setSortingMethod] = useState(null);
    const [allJobType, setAllJobType] = useState([]);
    const [jobTypeId, setJobTypeId] = useState(null);
    const [pickingMethod, setPickingMethod] = useState(null)
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [configDetail, setConfigDetail] = useState({});
    const allWarehouses = useSelector((state) => state.inward.allWarehouses);
    const sortingMethodOptions = configDetail?.sortingMethod?.allOptions || []
    const pickingMethodOptions = configDetail?.pickingMethod?.allOptions || []


    const salesOrderAllocationDataArrival = async () => {
        let redirectedObject = { salesDetail: entry };

        try {
            setLoader("initialPhase")
            // Fetch warehouses and job types concurrently
            const [tempConfigData, warehouseResponse, jobTypeResponse] = await Promise.all([
                new Promise((resolve) => {
                    dispatch(getCommanConfig({ configType: "salesOrderStockAllocation" }, (data) => {
                        resolve(data?.configDetail || {});
                    }))
                }),
                new Promise((resolve) => {
                    dispatch(getAllWarehouses({ type: "warehouse" }, (data) => {
                        resolve(data);
                    }));
                }),
                new Promise((resolve) => {
                    dispatch(
                        getAllJobTypes(
                            { fetch_for: "getForFilter", actionType: ["picking", "sendToUser"] },
                            (data) => {
                                resolve(data);
                            }
                        )
                    );
                }),
            ]);
            setConfigDetail(tempConfigData)
            setLoader("")
            // Process warehouse data
            let tempSelectedWarehouse = null;
            if (warehouseResponse?.data?.length) {
                const selectedWarehouse = warehouseResponse.data?.find(
                    (dl) => dl?._id == tempConfigData?.isSelectedWarehouse?.value
                )
                if (
                    tempConfigData?.isSelectedWarehouse?.isActive &&
                    tempConfigData?.isSelectedWarehouse?.value && selectedWarehouse
                ) {
                    tempSelectedWarehouse = selectedWarehouse
                } else {
                    tempSelectedWarehouse = warehouseResponse?.data[0] || null;
                }
            }
            setSelectedWarehouse(tempSelectedWarehouse);
            redirectedObject["salesWarehouse"] = tempSelectedWarehouse;

            // Process job type data
            let tempJobType =
                (jobTypeResponse?.data || [])?.map((dl) => ({
                    label: dl.name,
                    value: dl._id,
                })) || [];
            setAllJobType(tempJobType);

            let tempSelectedJobId = null;
            if (tempJobType?.length) {
                tempSelectedJobId = tempJobType?.find(
                    (dl) => dl?.value == tempConfigData?.isSelectedJobType?.value
                )
                if (
                    tempConfigData?.isSelectedJobType?.isActive &&
                    tempConfigData?.isSelectedJobType?.value && tempSelectedJobId
                ) {
                    tempSelectedJobId = tempSelectedJobId;
                } else {
                    tempSelectedJobId = tempJobType?.at(0) || null;
                }
            }
            setJobTypeId(tempSelectedJobId);
            redirectedObject["selectJobTypeId"] = tempSelectedJobId?.value || "";

            // Handle sorting method
            let tempSortingMethod = tempConfigData?.sortingMethod?.allOptions?.find(
                (dl) => dl?.value == tempConfigData?.sortingMethod?.value
            );
            if (tempSortingMethod) {
                redirectedObject["sortingMethod"] = tempSortingMethod;
                setSortingMethod(tempConfigData?.sortingMethod?.value);
            } else {
                redirectedObject["sortingMethod"] = {
                    label: "First In First Out",
                    value: "FIFO",
                };
                setSortingMethod("FIFO");
            }

            // Handle picking method
            if (
                tempConfigData?.pickingMethod?.isActive &&
                tempConfigData?.pickingMethod?.allOptions?.find(
                    (dl) => dl?.value == tempConfigData?.pickingMethod?.value
                )
            ) {
                redirectedObject["pickingMethod"] =
                    tempConfigData?.pickingMethod?.allOptions?.find(
                        (dl) => dl?.value == tempConfigData?.pickingMethod?.value
                    );
                setPickingMethod(tempConfigData?.pickingMethod?.value);
            } else {
                redirectedObject["pickingMethod"] = {
                    label: "None",
                    value: "",
                };
                setPickingMethod("");
            }

            // Handle redirection
            if (tempConfigData?.isAutoStockAllocation?.isActive===true) {
                setLoader("redirectingPhase")
                setTimeout(() => {
                    history.push("/dashboard/jobs/create-job", redirectedObject);
                }, tempConfigData?.isAutoStockAllocation?.value || 0);
            }
        } catch (error) {
            setLoader("")
            console.error("Error in salesOrderAllocationDataArrival:", error);
        }
    };


    useEffect(() => {
        if (status == "dispatch" && entry && entry.length > 0) {
            salesOrderAllocationDataArrival();
        }
        else {
            setSelectedWarehouse(null);
            setSortingMethod(null);
            setPickingMethod(null);
            setAllJobType([]);
            setJobTypeId(null);
        }
    }, [status]);
    return (
        <AlertDialog
            viewDialog={status != null}
            handleClose={handleClose}
            viewSearchInput={false}
            showDivider={true}
            width="60%"
            actionsCSS={{ justifyContent: 'flex-start' }}
            title={
                <Typography sx={{ ...textClasses.cardTitle }}>
                    Select Dispatch Sorting & Picking Method
                </Typography>
            }
            body={<>
                {(loader == "initialPhase" || loader == "redirectingPhase") ?
                    loader == "redirectingPhase"
                        ? <Grid item={true} data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
                            <Grid item><GenericLoader loaderText="Stock Allocation Initiating..." /></Grid>
                        </Grid>
                        : <Grid item={true} data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
                            <Grid item><GenericLoader /></Grid>
                        </Grid> :
                    status == "dispatch" && (
                        <>
                            <Grid container sx={{ mt: 1 }}>
                                <Grid container item sm={4}>
                                    <FormLabel required sx={{ mt: 1, color: "#000000" }}>
                                        Sorting Method :
                                    </FormLabel>
                                </Grid>
                                <Grid container item sm={8}>
                                    {sortingMethodOptions.map((m, i) => {
                                        return (
                                            <Grid item sm={6} key={i}>
                                                <FormControlLabel
                                                    onClick={() => setSortingMethod(m.value)}
                                                    checked={sortingMethod == m.value}
                                                    label={
                                                        <Typography sx={{ fontSize: "13px" }}>
                                                            {m.label}
                                                        </Typography>
                                                    }
                                                    control={
                                                        <Radio
                                                            value={m.value}
                                                            sx={{
                                                                "&, &.Mui-checked": { color: theme.themeOrange },
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mt: 1 }}>
                                <Grid container item sm={4}>
                                    <FormLabel required sx={{ mt: 1, color: "#000000" }}>
                                        Select Warehouse :
                                    </FormLabel>
                                </Grid>
                                <Grid container item sm={8}>
                                    <Autocomplete
                                        value={selectedWarehouse}
                                        onChange={(e, option) => {
                                            setSelectedWarehouse(option);
                                        }}
                                        getOptionLabel={(option) =>
                                            capitalizeSentence(option.name || "")
                                        }
                                        size="small"
                                        options={allWarehouses}
                                        sx={{
                                            ...inputClasses.filterField,
                                            minWidth: "220px",
                                            m: 0,
                                            ".MuiInputBase-input": {
                                                fontSize: "14px",
                                                color: "#455A64",
                                            },
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{ color: "#455A64", ...textClasses.normalText }}
                                                {...params}
                                                label="Warehouse"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mt: 2 }}>
                                <Grid container item sm={4}>
                                    <FormLabel required sx={{ mt: 1, color: "#000000" }}>
                                        Select Job :
                                    </FormLabel>
                                </Grid>
                                <Grid container item sm={8}>
                                    <Autocomplete
                                        value={jobTypeId}
                                        onChange={(e, option) => {
                                            setJobTypeId(option);
                                        }}
                                        size="small"
                                        options={allJobType}
                                        sx={{
                                            ...inputClasses.filterField,
                                            minWidth: "220px",
                                            m: 0,
                                            ".MuiInputBase-input": {
                                                fontSize: "14px",
                                                color: "#455A64",
                                            },
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{ color: "#455A64", ...textClasses.normalText }}
                                                {...params}
                                                label="Job Type"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mt: 2 }}>
                                <Grid container item sm={4}>
                                    <FormLabel sx={{ mt: 1, color: "#000000" }}>
                                        Picking Method :
                                    </FormLabel>
                                </Grid>
                                <Grid container item sm={8}>
                                    {pickingMethodOptions?.map((m, i) => {
                                        return (
                                            <Grid item sm={12} key={i}>
                                                <FormControlLabel
                                                    onClick={() => setPickingMethod(m.value)}
                                                    checked={pickingMethod == m.value}
                                                    label={
                                                        <Typography sx={{ fontSize: "13px" }}>
                                                            {m.label}
                                                        </Typography>
                                                    }
                                                    control={
                                                        <Radio
                                                            value={m.value}
                                                            sx={{
                                                                "&, &.Mui-checked": { color: theme.themeOrange },
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>

                        </>
                    )}
            </>
            }

        >
            <Button
                variant="outlined"
                size="small"
                sx={{ ...buttonClasses.lynkitBlackEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                onClick={handleClose}
            >
                Cancel
            </Button>
            <Button
                variant="contained"
                size="small"
                sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                disabled={selectedWarehouse == null || sortingMethod == null || jobTypeId == null}
                onClick={() =>
                    history.push("/dashboard/jobs/create-job", {
                        salesDetail: entry,
                        salesWarehouse: selectedWarehouse,
                        sortingMethod: sortingMethodOptions.find(m => m.value == sortingMethod),
                        selectJobTypeId: jobTypeId?.value || null,
                        pickingMethod: pickingMethodOptions?.find(m => m.value == pickingMethod)
                    })
                }
            >
                {status == "dispatch" ? "Allocate" : status}
            </Button>
        </AlertDialog>
    )
};

export default AllocateStockModalBox;