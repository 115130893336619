import React, { useEffect, useState } from "react";
import AlertDialog from "../../../../Components/DialogBox/dialogbox";
import { Grid, Box, Typography, Button,FormGroup,TextField,FormControlLabel,Checkbox } from "@mui/material";
import GenericLoader from "../../../../Components/Generic/GenericLoader";
import theme, { textClasses, buttonClasses, inputClasses } from "../../../../../static/styles/theme";
import { useDispatch } from "react-redux";
import { getAllInventory,initiateDePalletProcess } from "../../../../../redux/actions";
import { showToast } from "../../../../../services/functions";
import MultiSelectAll from "../../../MainDashboard/MultiSelect";
const statusFilters = [
    { label: 'Prepared (Pending)', value: 'pending', isChecked: true },
    { label: 'In Stock (Gated-In)', value: 'gateIn', isChecked: true },
  ]
const CreateDePallet = ({ viewModal, setViewModal}) => {
    // console.log("Viewda",viewModal)
    const dispatch = useDispatch();
    const [invDeKitData,setInvDekitData] = useState([]);
    const [statusFilter,setStatusFilter] = useState(statusFilters)
    const checkedStatusFilters = statusFilter?.filter(status => status.isChecked)?.map(status => status.value) ;
    const [selectedDekit,setSelectedDekit] = useState([]) 
    const [dataReceieved, isDataReceived] = useState(false)
    const [loader, setLoader] = useState(false);
    const handleChange = (event) => {
        const { name, checked } = event.target;
        setStatusFilter(statusFilter.map(currStatus =>
          currStatus.value === name ? { ...currStatus, isChecked: checked } : currStatus
        ));
      };

    const handleSelectionChange =(result)=>{
        setSelectedDekit(result);
    }
    const handleSubmit = () => {
        isDataReceived(true)
        let payload = {
            itemIds: selectedDekit?.map(dl=>dl?._id),
          }
          dispatch(initiateDePalletProcess(payload, (data)=>{
            showToast(data.message, !data.success);
            isDataReceived(false)
            if(data.success){
                setViewModal({ ...viewModal, type: '' })
                if(selectedDekit?.length>0){
                    setSelectedDekit([])
                }
            }
          }))
 
    }
    useEffect(() => {
        setSelectedDekit([])
        const payload = {
            type: "wms",
            dataFetchType: "palletWise",
            projection: { palletId: 1,updatedAt:1,_id:0 },
            status:checkedStatusFilters?.length=== 0 ? "":checkedStatusFilters,
        };
        dispatch(getAllInventory(payload, (data) => {
            if (data.success) {
                setInvDekitData(data?.data?.map(dl=>({
                    name:dl?.palletId,
                    _id:dl?.palletId
                })))
            }
        }))
    }, [statusFilter])
// console.log("asd",invDeKitData)
    return (
        <>

            <AlertDialog
                viewDialog={viewModal.type === "createDePalletRequest"}
                handleClose={() => setViewModal({ ...viewModal, type: '' })}
                showDivider={true}
                width="60%"
                title="Create De-Pallet Request"
                body={
                    <>
                        {loader ?
                            <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                                <Grid item><GenericLoader /></Grid>
                            </Grid> :
                            <Box sx={{ width: "inherit" }}>
                                <Grid container spacing={2}>
                                    <Grid item sm={12} >
                                        <Typography sx={{ ...textClasses.t15n }}>
                                            Select Pallet(s) with In-stock Pallet(s) and Returned Pallet(s) with gated-in or pending status.
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={3} >
                                        <Typography sx={{ ...textClasses.t15n,mt:1 }}>
                                            Filter Pallet(s) By:
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <FormGroup row>
                                            {statusFilter.map((currStatus) => (
                                                <FormControlLabel
                                                    key={currStatus.value}
                                                    control={
                                                        <Checkbox
                                                           data-testid="checkbox"
                                                           checked={currStatus.isChecked}
                                                            onChange={handleChange}
                                                            name={currStatus.value}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={currStatus.label}
                                                    disabled={checkedStatusFilters?.length==1 && currStatus?.isChecked}
                                                />
                                            ))}
                                        </FormGroup>
                                    </Grid>

                                    <Grid item sm={3}>
                                        <Typography sx={{ ...textClasses.t15n,mt:2}}>
                                            Select Pallet(s) : 
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={7}>
                                    <Box sx={{ maxHeight: "300px", overflowY: "auto" }}>
                                    <MultiSelectAll
                                           data-testid="select-dekit"
                                           sx={{ maxheight: "700px" }}
                                           items={invDeKitData || []}
                                           selectAllLabel="Select All"
                                           value={selectedDekit}
                                           onChange={handleSelectionChange}
                                           label="Select Kit(s)"
                                           limitTag={10}
                                           defaultSelectAll={false}
                                    />
                                    </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    </>
                }

            >
                <Button
                    variant="outlined"
                    data-testid="cancel-btn"
                    onClick={() => { setViewModal({ ...viewModal, type: '' }) }}
                    sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                >Cancel
                </Button>
                <Button
                    variant="outlined"
                    data-testid="submit-btn"
                    onClick={handleSubmit}
                    disabled={dataReceieved || selectedDekit?.length<=0}
                    sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                >Submit
                </Button>
            </AlertDialog>
        </>
    )
}
export default CreateDePallet;
