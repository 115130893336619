import React, { useEffect } from 'react'
import { Typography, Grid } from '@mui/material'
import theme from '../../../../static/styles/theme'
import GenericComponentsCollapse from '../../../Components/Generic/GenericComponentsCollapse'

const StandardWidgets = ({stdWidgets, atcsWidgets})=> {


    return (
        <Grid container sx={{zIndex: 100, mr: 2,overflowY: "auto", maxHeight:'75vh'}}>
            <Grid item sm={12}>
                <Typography variant='h6' sx={{color: theme.themeOrange}}>Standard Form Component</Typography>
                <Typography variant='caption'>
                    Drag a field type to one of the sections on the left to create a custom field for this issue type.
                </Typography>
                
                {
                    stdWidgets && stdWidgets.filter(section => ["Section Breaker", "Grid"].indexOf(section._id) < 0).map((section, i) => {
                        return (
                            <GenericComponentsCollapse
                                key={i}
                                index={i + 1}
                                title={section._id}
                                components={section.elements}
                            />
                        )
                    })
                }
                {
                    atcsWidgets && atcsWidgets.map((section, i) => (
                        <GenericComponentsCollapse
                            key={i}
                            index={3 + 1}
                            title={section._id}
                            components={section.elements}
                            type="list"
                        />
                    ))
                }
            </Grid>
            
            
        </Grid>
    )
}

export default StandardWidgets;