import React, { useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import "../Style/3dView.css";
let scene, camera, renderer, controls, container;
const THREE = window.THREE;
const gsap = window.gsap;

const raycaster = new THREE.Raycaster();
const pointer = new THREE.Vector2();
var racks = [];
var grids = [];

const ThreedViewLayout = (props) => {
    const containerRef = useRef(null);

    useEffect(() => {

        // Initialize the scene
        initializeScene();

        // Cleanup on unmount
        return () => {
            // window.removeEventListener("resize", onWindowResize);
            controls.dispose();
            renderer.dispose();
            // scene.traverse((object) => {
            //     if (object.geometry) object.geometry.dispose();
            //     if (object.material) {
            //         if (Array.isArray(object.material)) {
            //             object.material.forEach((material) => material.dispose());
            //         } else {
            //             object.material.dispose();
            //         }
            //     }
            // });
            renderer.forceContextLoss();
            renderer.context = null;
            renderer.domElement = null;
        };

    }, []);

    const initializeScene = () => {
        scene = new THREE.Scene();

        // Camera setup
        camera = new THREE.PerspectiveCamera(75, containerRef.current.clientWidth / containerRef.current.clientHeight, 0.1, 10000);
        camera.position.set(-1500, 1500, 5000);

        // Renderer setup
        renderer = new THREE.WebGLRenderer({ logarithmicDepthBuffer: true });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(containerRef.current.clientWidth, containerRef.current.clientHeight);
        renderer.shadowMap.enabled = true;
        // renderer.outputEncoding = THREE.sRGBEncoding;
        // renderer.toneMapping = THREE.ACESFilmicToneMapping;
        // renderer.toneMappingExposure = 1;
        // renderer.toneMappingExposure = 2;
        // renderer.toneMapping = THREE.NoToneMapping;
        containerRef.current.appendChild(renderer.domElement);

        // OrbitControls setup
        controls = new THREE.OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.25;
        controls.screenSpacePanning = false;
        controls.minDistance = 50;
        controls.maxDistance = 2000;
        controls.maxPolarAngle = Math.PI / 2; //1.54

        // Scene lighting
        scene.background = new THREE.Color(0xf0f0f0);
        scene.fog = new THREE.Fog(0x89909F, 5000, 10000);
        const ambientLight = new THREE.AmbientLight(0xdddddd);
        scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight("#F8F8F8", 1);
        directionalLight.position.set(80, 80, 800);
        scene.add(directionalLight);

        // // Ground plane
        const groundGeo = new THREE.PlaneGeometry(10000, 10000);
        const groundMat = new THREE.MeshLambertMaterial({ color: "#BEBEBE" });
        const ground = new THREE.Mesh(groundGeo, groundMat);
        ground.position.y = -33;
        ground.rotation.x = -Math.PI / 2;
        ground.receiveShadow = true;
        scene.add(ground);


        // const geometry = new THREE.SphereGeometry( 5000, 600, 400 );
        // geometry.scale( - 1, 1, 1 );

        // const texture = new THREE.TextureLoader().load( '/Models/TCom_HarborStreet_header.jpg' );
        // texture.colorSpace = THREE.SRGBColorSpace;
        // const material = new THREE.MeshBasicMaterial( { map: texture } );
        // const mesh = new THREE.Mesh( geometry, material );
        // scene.add( mesh );

        loadModels(scene);

        renderer.domElement.addEventListener('dblclick', onPointerClick, false);
        window.addEventListener("resize", onWindowResize);

        

        const animate = () => {
            requestAnimationFrame(animate);
            controls.update();
            renderer.render(scene, camera);
        };

        animate();
    };

    const loadModels = (scene) => {
        const loader = new THREE.GLTFLoader();

        const fontLoader = new THREE.FontLoader();
        fontLoader.load('/json/helvetica_regular.json', async function (font) {
            const modelPaths = // do not change sequence
            {
                floor: '/Models/texture_studded_metal_floor.glb',
                rack: '/Models/rack.gltf',
                crate: '/Models/cardbox.glb',
                pallet: '/Models/pallet.glb',
                lift: '/Models/elevator2.glb',
                bin: '/Models/cuboid.glb',
                room: '/Models/fmb_building_001.glb',
                warehouse: '/Models/warehouse2/scene.gltf',
                toilet: '/Models/toilet.glb',
                door: '/Models/low_polydoor.glb',
            };

            const [floorModel, rackModel, crateModel, palletModel, liftModel, cuboidModel, roomModel, warehouseModel, toiletModel, doorModel]
                = await Promise.all(Object.values(modelPaths).map((path) => loadModel(loader, path)));

            // console.log(props.floorInfo);

            if (props && props.floorInfo) {
                // createWarehouseModel(warehouseModel);

                createModels({ floorModel, rackModel, crateModel, palletModel, liftModel, cuboidModel, roomModel, toiletModel, doorModel, font });
            }

        });
    }

    function createWarehouseModel(warehouseModel) {
        var modelScale = 1;
        warehouseModel.scale.set(modelScale * (props.floorInfo.col || props.floorInfo.column) * 5, 200, modelScale * props.floorInfo.row * 5); // Scale the model if necessary
        var box = new THREE.Box3().setFromObject(warehouseModel);
        var center = new THREE.Vector3();
        box.getCenter(center);
        var size = new THREE.Vector3();
        box.getSize(size);
        warehouseModel.position.set((-center.x) + 800, (-center.y + (size.y / 2)), -center.z + 400);
        warehouseModel.castShadow = true;
        warehouseModel.receiveShadow = true;
        scene.add(warehouseModel);
    }

    function createModels(models) {
        const { palletModel, rackModel, crateModel, cuboidModel, floorModel, liftModel, roomModel, toiletModel, doorModel, font } = models;

        createRacks();

        function createRacks() {
            grids = props.gridCellDetails;
            for (let index = 0; index < grids.length; index++) {
                var grid = grids[index];
                grid.mergeColumn = grid.mergeColumn ? Number(grid.mergeColumn) : 1;
                grid.mergeRows = grid.mergeRows ? Number(grid.mergeRows) : 1;

                var mergeColumn = grid.mergeColumn;
                var mergeRows = grid.mergeRows;

                var colIndex = grid.colIndex * 10 * 6;
                var rowIndex = grid.rowIndex * 10 * 6;

                // console.log(index, grid.name);

                if (grid.type == 'space' && grid.name) {
                    grid.building = true;
                    createFloor({ mergeColumn, mergeRows, colIndex, rowIndex, grid });
                }
                else if (grid.type == 'block') {
                    if (grid.isRackExist && grid.row) {
                        Array.from({ length: grid.row }, (_, index) => {
                            createRack({ mergeColumn, mergeRows, colIndex, rowIndex, grid, index });
                        });
                    }
                    else {
                        createRack({ mergeColumn, mergeRows, colIndex, rowIndex, grid, index: 0 });
                    }
                }
                else if (grid.type == 'space') {
                    // createFloor( { mergeColumn, mergeRows, colIndex, rowIndex , grid });
                }
            }
        }

        function createFloor(obj) {
            var { mergeRows, mergeColumn, rowIndex, colIndex, grid } = obj;

            var gridSizeX = 6.9 * 4;
            var gridSizeZ = 5.65 * 4;
            var gridSizeY = 2;

            var clone = THREE.SkeletonUtils.clone(floorModel);

            var name = grid.name ? grid.name.toLowerCase() : grid.name;
            if (name.includes('lift')) {
                gridSizeX = 2.5 * 4;
                gridSizeZ = 2.5 * 4;
                gridSizeY = 60;
                clone = THREE.SkeletonUtils.clone(liftModel);
            }
            else if (name.includes('toilet')) {
                gridSizeX = 2.5 * 4;
                gridSizeZ = 2.5 * 4;
                gridSizeY = 80;
                clone = THREE.SkeletonUtils.clone(toiletModel);
            }
            else if (name.includes('gate')) {
                gridSizeX = 2.5 * 4;
                gridSizeZ = 2.5 * 6;
                gridSizeY = 40;
                clone = THREE.SkeletonUtils.clone(doorModel);
                clone.rotation.y = -Math.PI / 2
            }
            else if (name.includes('dock') || name.includes('packing') || name.includes('loading')) {
                gridSizeX = 1.25 * 4;
                gridSizeZ = 3.2 * 4;
                gridSizeY = 80;
                clone = THREE.SkeletonUtils.clone(roomModel);
            }
            else {
                gridSizeX = 1.25 * 4;
                gridSizeZ = 3.2 * 4;
                gridSizeY = 80;
                clone = THREE.SkeletonUtils.clone(roomModel);
            }


            clone.scale.set(gridSizeX * mergeColumn, gridSizeY, gridSizeZ * mergeRows);
            var box1 = new THREE.Box3().setFromObject(clone);
            var center = new THREE.Vector3();
            box1.getCenter(center);
            var size = new THREE.Vector3();
            box1.getSize(size);

            clone.position.set(colIndex + (-center.x + (size.x / 2)), (-center.y + (size.y / 2)), rowIndex - (center.z - (size.z / 2)));

            createText(grid, font, colIndex + (-center.x + size.x / 2), rowIndex - (center.z - size.z / 2), 0, gridSizeY, 0, clone);

            clone.castShadow = true;
            clone.receiveShadow = true;
            scene.add(clone);

            // racks.push(clone)
        }

        function createRack(obj) {
            var gridSizeX = 3.5 * 4;
            var gridSizeZ = 8.5 * 4;

            var { mergeRows, mergeColumn, rowIndex, colIndex, grid, index } = obj;

            var clone = THREE.SkeletonUtils.clone(rackModel);
            clone.castShadow = true;
            clone.receiveShadow = true;
            clone.rotation.y = -Math.PI / 2;
            clone.scale.set(gridSizeX * mergeRows, 60, gridSizeZ * mergeColumn);
            var box1 = new THREE.Box3().setFromObject(clone);
            var center = new THREE.Vector3();
            box1.getCenter(center);
            var size = new THREE.Vector3();
            box1.getSize(size);

            var posX = colIndex + (-center.x + (size.x / 2));
            var posZ = rowIndex - (center.z - (size.z / 2));
            var posY = index * 60;
            clone.position.set(posX, posY, posZ);
            scene.add(clone);

            racks.push(clone)

            var gridSizeY = 60;
            createText(grid, font, posX, posZ, 0, gridSizeY, 0, clone);

            Array.from({ length: grid.col }, (_, palletIndex) => {
                var crateClone = THREE.SkeletonUtils.clone(crateModel);
                var palletClone = THREE.SkeletonUtils.clone(palletModel);
                createPallet({ shelf: crateClone, pallet: palletClone, grid, rackIndex: index }, palletIndex, posX, posY, posZ, { size, center });
            });
        }

        function createPallet(clones, index, posX, posY, posZ, rack) {
            var { shelf, pallet, grid, rackIndex } = clones;
            var { size, center } = rack;

            var gridSizeX = size.x * 2.25;
            var gridSizeY = size.y * 2;
            var gridSizeZ = (size.z * 2.75)///grid.col;
            var gridFactorZ = gridSizeZ - (size.z / 2);

            if (grid.mergeRows > grid.mergeColumn) {
                gridSizeX = size.x * 2.25;
                gridSizeY = size.y * 2;
                gridSizeZ = (size.z * 2.75)///grid.col;
                // shelf.scale.set(gridSizeX * grid.mergeColumn, gridSizeY, gridSizeZ * grid.mergeRows);
            }
            else {
                // shelf.scale.set(gridSizeX * grid.mergeRows, gridSizeY, gridSizeZ * grid.mergeColumn);
            }

            shelf.scale.set(gridSizeX, gridSizeY, gridSizeZ);


            var box1 = new THREE.Box3().setFromObject(shelf);
            var center = new THREE.Vector3();
            box1.getCenter(center);
            var size1 = new THREE.Vector3();
            box1.getSize(size1);

            var x = posX; // width
            var y = 46 + posY;
            var z = posZ

            shelf.position.set(x, y, z);
            shelf.castShadow = true;
            shelf.receiveShadow = true;

            // Apply debug material

            // console.log(grid);
            
            shelf.traverse((node) => 
            {
                if (node.isMesh) 
                {
                    if(grid.status == 'available')
                    {
                        node.material = new THREE.MeshStandardMaterial({ color: 'green' });
                    }
                    else if(grid.status == 'partial')
                    {
                        node.material = new THREE.MeshStandardMaterial({ color: 'yellow' });
                    }
                }
            });

            scene.add(shelf);

            //     // var x = i-6; // width
            //     // var y = 26; //height from base
            //     // var yy = 6; //height from base
            //     // var z = j; // depth or length

            //     // pallet.scale.set(20,20,36);
            //     // pallet.position.set(x, yy, z);
            //     // pallet.castShadow = true;
            //     // pallet.receiveShadow = true;
            //     // scene.add(pallet);;
        }

        // function createPallet(clones, index, i,j, rack)
        // {
        //     var {shelf, pallet, grid, rackIndex} = clones;
        //     var {size,center} = rack;

        //     var gridSizeX = 28 * 3.4;
        //     var gridSizeZ = (34 * 3) / grid.col;
        //     var gridSizeY = 160;
        //     var gridFactorZ = ((34 * 3)/ grid.col )- (size.z/2);

        //     console.log((34 * 3) - (size.z/2));

        //     shelf.scale.set(gridSizeX * grid.mergeColumn, gridSizeY, gridSizeZ * grid.mergeRows); // Scale the model if necessary

        //     var box1 = new THREE.Box3().setFromObject(shelf);
        //     var center = new THREE.Vector3();
        //     box1.getCenter(center);
        //     var size1 = new THREE.Vector3();
        //     box1.getSize(size1);

        //     // console.log(size.y);

        //     var x = i; // width
        //     var y = 46 + (60 * rackIndex); //height from base
        //     var z = (j + (size1.z * index) + (5 + index)) - gridFactorZ; // depth or length

        //     shelf.position.set(x, y, z);
        //     shelf.castShadow = true;
        //     shelf.receiveShadow = true;
        //     scene.add(shelf);

        //     // Array.from({ length: 5}, (_, rackIndex) => 
        //     // {
        //     //     var cuboid = THREE.SkeletonUtils.clone(cuboidModel);
        //     //     createBins(cuboid,i,j,rackIndex,index,y)
        //     // });
        // }

        function createText(grid, font, i, j, scaleX, scaleY = 200, scaleZ, clone) {
            // Calculate the height of the model using its bounding box
            const box = new THREE.Box3().setFromObject(clone);
            const size = new THREE.Vector3();
            box.getSize(size); // size.y represents the height of the model

            // console.log(grid.name);

            const textGeometry = new THREE.TextGeometry(grid.name, {
                font: font,
                size: 12,
                height: 2,
                curveSegments: 12,
            });

            const textMaterial = new THREE.MeshBasicMaterial({ color: '#fff' });
            const textMesh = new THREE.Mesh(textGeometry, textMaterial);

            // Position the text slightly above the model
            const offsetY = size.y - 64; // Add 20 units to ensure it sits visibly above the model
            textMesh.position.set(i - 20, scaleY + offsetY, j - 10);
            textMesh.rotation.set(-Math.PI / 2, 0, 0);

            // Add the text mesh to the scene
            scene.add(textMesh);
        }


        function createBins(bin, i, j, rackIndex, index, y) {
            bin.scale.set(10, 120, 40);

            var x = i - 10; // width
            var z = j; // depth or length
            x = x + (rackIndex * 2);
            bin.position.set(x, y, z); // Position the model in the scene
            bin.castShadow = true;
            bin.receiveShadow = true;
            scene.add(bin);
        }
    }

    const onWindowResize = () => {
        if (!containerRef.current) return;

        camera.aspect = containerRef.current.clientWidth / containerRef.current.clientHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(containerRef.current.clientWidth, containerRef.current.clientHeight);
    };

    function onPointerMove(event) {
        pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
        pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;

        // console.log(pointer);

        raycaster.setFromCamera(pointer, camera);
    }

    function onPointerClick(event) 
    {

        event.preventDefault();

        pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
        pointer.y = - (event.clientY / window.innerHeight) * 2 + 1;

        raycaster.setFromCamera(pointer, camera);

        var intersects = raycaster.intersectObjects(scene.children);
        // console.log(intersects);

        if (intersects.length > 0) 
        {
            const object = intersects[0].point;

            gsap.to(camera.position, {
                x: object.x,
                y: object.y,
                z: object.z,
                duration: 1,
                onUpdate: () => controls.update()
            });

            gsap.to(controls.target, {
                x: object.x,
                y: object.y,
                z: object.z,
                duration: 1
            });

        //     // infoPanel.innerHTML = `Selected ${object.name}`;
        }
    }


    return <Grid id="threeScene" ref={containerRef} style={{ height: "70vh", width: "100%" }} />;
};

export default ThreedViewLayout;

const loadModel = (loader, path) => {
    return new Promise((resolve, reject) => {
        loader.load(path, (gltf) => resolve(gltf.scene), undefined, (error) => reject(error));
    });
}





