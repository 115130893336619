import React, { useEffect,useState, useRef,useMemo } from "react";
import {
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Button,
  TextField,
  Select,
  MenuItem,
  FormLabel,
  InputLabel,
  Typography,
  Tab,
  Tabs,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  Chip,
  Paper,
  IconButton,
  Menu,
  Tooltip,
  setRef,
  Autocomplete,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  Add,
  Cancel,
  Clear,
  KeyboardArrowDown,
  Search,
} from "@mui/icons-material";
import { getAllCustomDashboardList, getCustomDashboardDetail, getCustomDashboardStatusCount } from "../../../redux/actions/CustomDashboard";
import { useDispatch, useSelector } from "react-redux";
import theme,{buttonClasses,textClasses} from "../../../static/styles/theme";
import history from "../../../services/history";
import SelectImage from "../../../static/images/no_data_found.svg"
import { renderHeadersFilter, dataReadFunction, textCapitalize, dateTimeFormater  } from "../../../services/functions";
import GenericCustomiseHeader from "../../Components/Generic/GenericCustomiseHeader";
import { useDebounce } from "../../../utils/hooks";
import GenericTable from "../../Components/CustomTable/GenericTable";
import GenericLoader from "../../Components/Generic/GenericLoader";
import GenericPageConfig from "../../Components/Generic/GenericPageConfig";
import GenericToggle from "../../Components/GenericToggle"
import { multiDateToISOConvertor } from "../../Components/Generic/GenericMultiDateRange";
let initial_state = {
  "search": {},
  "other": {},
}

const CustomDashboard = () =>{
  const dispatch = useDispatch();
  // const {allCustomDashboardList} = useSelector(state => state?.CustomDashboard ) || {}// floor data got when coming from superadmin page
  const { pageTitle = "Custom Dashboard",status } = useSelector(state => state?.DynamicPageConfig?.customDashboardListPage) || {};
  const { userPermissions } = useSelector((state) => state.userPermission);
  let permissions = userPermissions || {};
  const [customDashboardTableData, setCustomDashboardTableData] = useState({
    data: [],
    count: 0
  })
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState(initial_state);
  const [loader, setLoader] = useState("initial");
  const [tabIndex, setTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmModal, setConfirmModal] = useState(null);
  const [actionConfirmModal, setActionConfirmModal] = useState(null);
  const [statusFilter, setStatusFilter] = useState({});
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [actionIndex, setActionIndex] = useState(null);
  const [customDashboardStatusCount, setCustomDashboardStatusCount] = useState({})
    const [initialRender, setInitialRender] = useState(true)
  const debouncedFilterSearch = useDebounce(filter?.search, 500);

  const isFilterApplied = (() => {
    // console.log("filter", filter,statusFilter)
    if (tabIndex == 0 && ((statusFilter?.value && statusFilter?.value!="all") || (filter?.other?.status && filter?.other?.status!="all"))) {
      return true;
    }
    let { status, ...restOther } = { ...filter["other"] || {} }
    // delete other["status"]
    if (Object.values(restOther || {})?.some(dl => dl && (typeof dl == "object" && !Array.isArray(dl) ? dl?.value : dl.length > 0)) || Object.values(filter["search"]).some(dl => dl && (typeof dl == "object" && !Array.isArray(dl) ? dl?.value : dl.length > 0))) {
      return true;
    }
    return false;
  })();


  const handleToggle = (event, rowData, rowIndex = 0) => {
      // let checked = event.target.checked
      // let payload = {
      //     ruleId: [rowData?._id],
      //     approved: checked ? true : false,
      //     ruleType: selectedTab
      // };
      // dispatch(
      //     disableEnableSkuGroupRule(payload, (data) => {
      //         showToast(data.message, !data.success);
      //         if (data) {
      //             refreshTableData();
      //         }
      //     })
      // );
  }

  const handleTabChange = (value,statusObject={}) => {
    setStatusFilter(statusObject)
    let { status, ...restOther } = { ...filter["other"] || {} }
    setFilter({
      search: filter?.search || {},
      other: {
        ...restOther,
        status: statusObject?.value || ""
      }
    })
    setPage(0);
    setTabIndex(value);
    setSelectedEntries([])
    refreshTableData(1, limit, statusObject?.value,true )
    // setViewCompanyDetail(false)
  
  };

  const handleClearFilter = () => {
      setFilter(initial_state)
      setPage(0);
      setLimit(10);
      if (tabIndex == 0) {
          setStatusFilter(null);
      }
  };
  
  const handleOpenAction = (event, i) => {
      setAnchorEl(event.currentTarget);
      setActionIndex(i);
  };

  const handleCloseAction = () => {
      setActionConfirmModal(null);
      if (setSelectedEntries) {
          setSelectedEntries([]);
      }
      setActionIndex(null);
      setTimeout(() => {
          setAnchorEl(null);
      }, 1);
  };
  const handleCheckAll = () => {
      if (selectedEntries.length < customDashboardTableData?.data?.length) {
          let sT = customDashboardTableData?.data?.map((t) => ({
              _id: t._id,
              ruleName: t.ruleName
          }));
          setSelectedEntries(sT);
      } else {
          setSelectedEntries([]);
      }
  };
  const handleAddSelection = (obj) => {
      setSelectedEntries([...selectedEntries, obj]);
  };

  const handleRemoveSelection = (id) => {
      let oldEntries = [...selectedEntries];
      const i = oldEntries.map((o) => o._id).indexOf(id);
      if (i >= 0) {
          oldEntries.splice(i, 1);
          setSelectedEntries(oldEntries);
          if (oldEntries.length == 0) {
              handleCloseConfirmModal();
          }
      }
  };
  const handleCloseConfirmModal = () => {
      setConfirmModal(null);
  };

  const handleCheckChange = (event, row) => {
      if (event.target.checked) {
          handleAddSelection(row);
      } else {
          handleRemoveSelection(row._id);
      }
  };

      const handleRowAndViewClick = (selectedRowData) => {
          let payload = {
              dashboardId: selectedRowData?._id,
          }
          history.push(`/dashboard/custom/view/${payload.dashboardId}`)
      }

  const tableHeader = useSelector((state) => state.customHeader["customDashboardTable"]);
  let header = tableHeader && tableHeader?.validHeader && tableHeader.validHeader.map((h) => {
    if (h.handleType === "selectRow") {
      return (
        <Checkbox
          data-testid="checkbox"
          checked={
            customDashboardTableData?.data?.length != 0 &&
            selectedEntries.length == customDashboardTableData?.data?.length
          }
          onChange={handleCheckAll}
          size="small"
        />
      )
    }
    return h.fieldName
  })
    || [];

  const getTableCell = (header, row, i) => {
    let value = ""
    if (header?.mappingPath) {
      value = dataReadFunction({ name: header?.mappingPath || "",renderOption:header?.renderOption || [] }, row)
    }
    if (header?.handleType) {
      if (header?.handleType === "selectRow") {
        return (<div style={{ display: 'flex', alignItems: 'center' }} >
          <Checkbox
            size="small"
            checked={selectedEntries?.map((o) => o._id).indexOf(row._id) >= 0}
            onChange={(e) => handleCheckChange(e, row)}
          />
          <div
            style={{
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              backgroundColor: 'green',
              marginLeft: '5px',
              visibility: `${row?.isDefaultDashboard ? "visible" : "hidden"}`,
            }}
          ></div>
        </div>
        );
      }
      else if (header?.handleType === "showDetail") {
        return (
          <Typography
            onClick={() => handleRowAndViewClick(row)}
            style={{
              ...textClasses.normalText,
              color: theme.themeOrange,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            {value ?? "-"}
          </Typography>
        );
      }
      else if (header?.handleType === "action") {
        return (
          <Typography
            data-testid="dropdown"
            sx={{
              ...textClasses.normalText,
              color: header?.color || theme.themeOrange,
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenAction(e, i)}
          >
            Action <KeyboardArrowDown />
          </Typography>
        );
      }
    }
    else if (header?.isFromRenderFunction === true) {
      let no;
      if (page === 0) {
        no = i + 1;
      } else {
        no = page * 10 + (i + 1);
      }
      if (header?.renderFunction === "srNo") {
        return (
          <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: header?.color || theme.themeText }}>
            {no}
          </Typography>
        );
      }
      else if (header?.renderFunction === "status") {
          const statusColor = {
              enabled: "#3E621E",
              disabled: "#ff0c64",
          };
          const statusText = {
              enabled: "Enabled",
              disabled: "Disabled",
          };
          return (
              <>
                  {permissions &&
                      (permissions.permissions || []).some(
                          (item) => item.permissionId === "updateSKUPlacementRulesStatus"
                      ) ? (
                      <Typography
                          sx={{
                              ...textClasses.normalText,
                              textAlign: "center"
                          }}
                      >
                          <GenericToggle
                              disbledName="'Disable'"
                              enabledName="'Enable'"
                              isEnable={row?.approved === true}
                              handleToggle={(event) => handleToggle(event, row, i)}
                              name="drop"
                              width={80}
                          />
                      </Typography>
                  ) : (
                      // Show Typography with status if permission is not available
                      <Typography
                          sx={{
                              ...textClasses.normalText,
                              textTransform: "capitalize",
                              textAlign: "center",
                              color: statusColor[row?.approved ? "enabled" : "disabled"],
                          }}
                      >
                          {row?.approved ? statusText["enabled"] : statusText["disabled"]}
                      </Typography>
                  )}
              </>
          );
      }
    }
    else {
      if (header?.fieldType === "array") {
        return (
          <Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "capitalize", color: header?.color || theme.themeText }}>
            {value && Array.isArray(value) ? value.join(", ") : value || "-"}
          </Typography>
        );
      }
      else if (header?.fieldType === "date") {
        return (
          <Typography
            sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", color: header?.color || theme.themeText }}
          >
            {value ? dateTimeFormater(value) : "-"}
          </Typography>
        );
      }
      else {
        return (
          <Typography
            sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", textTransform: "capitalize", color: header?.color || theme.themeText }}
          >
          {value || "-"}
          </Typography>
        );
      }
    }
  };

  const tableRows = customDashboardTableData?.data?.map((row, i) => {
    let items = [];

    const validHeaders = tableHeader?.validHeader ?? [];
    for (const header of validHeaders) {
      items.push(getTableCell(header, row, i));
    }
    return items;
  });

  const searchableFilterItems = useMemo(() => {
    if (!tableHeader || !tableHeader.validHeader) return [];
    return tableHeader.validHeader.filter((dl) => dl?.isSearchable) || [];
  }, [tableHeader]);

  const refreshTableData = (pageNo, limitNo, status = "", fetchCount = false) => {
    setLoader("tableData");
    const payload = {
      page: pageNo,
      limit: limitNo,
      // type: "company",
      status: status 
    };
    let allDynamicKeys = tableHeader?.validHeader?.filter(dl => dl?.dynamic)?.map(dl => dl?.payloadKey || dl?.mappingPath)
    if (filter && Object.keys(filter || {}).length > 0) {
      for (let key in filter) {
        if (key && Object.keys(filter[key] || {}).length > 0) {
          for (let filterKey in filter[key]) {
            // if (filterKey && Object.keys(filter[key][filterKey] || {}).length > 0) {
            if (allDynamicKeys?.includes(filterKey)) {
              // Object.assign(payload, filter[key][filterKey])
              payload[filterKey] = filter[key][filterKey]?.value || ""

            } else {
              payload[filterKey] = filter[key][filterKey] || ""
            }
          }
        }
      }
      // Process date filter items
      const dateFilterItems = searchableFilterItems?.filter(
        (item) => item?.searchInputType === "date"
      );
      dateFilterItems.forEach((element) => {
        const payloadKey = element?.payloadKey || element?.mappingPath || "";
        if (payloadKey && payload[payloadKey]) {
          payload[payloadKey] = multiDateToISOConvertor(payload[payloadKey]);
        }
      });
    }
    // setCompanyData([])
    dispatch(getAllCustomDashboardList(payload, (data) => {
      setLoader("");
      setCustomDashboardTableData({ ...customDashboardTableData, data: data?.data || [], count: data?.count || 0 })
    }));
    if (fetchCount) {
      dispatch(getCustomDashboardStatusCount({ type: "wms" }, (data) => {
        setCustomDashboardStatusCount({ ...(data?.data || {}) })
      }))
    }


  };


  let fetchData = (fetchCount = false) => {
    refreshTableData(page + 1, limit, statusFilter?.value || "", fetchCount);
    // setSelectedTemplates([]);
    setConfirmModal(null);
    // setReceiveModal(null);
    setActionConfirmModal(null);
    setAnchorEl(null);
    setActionIndex(null);
    // setViewCompanyDetail(false)

  };
  
    useEffect(() => {
  
      fetchData(initialRender);
      if (initialRender) {
        setInitialRender(false)
      }
  
    }, [page, limit, filter.other, debouncedFilterSearch]);

  return (

    <>
      {
        loader == "initial" ?
          <Grid container sx={{ mt: 2 }}>
            <Grid item
              xs={12}>
              <Paper>
                <Grid
                  data-testid="loader"
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ p: 10 }}
                >
                  <Grid item
                    xs={12}>
                    <GenericLoader />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid> :
          customDashboardStatusCount?.totalDashboardCount > 0 ?
            <>
              <Grid container>
                <Grid item sm={6}>
                  <Typography sx={{ ...textClasses.cardTitle }}>
                    {pageTitle} 
                  </Typography>
                </Grid>
                <Grid container item sm={6} justifyContent="end" alignItems="end">
                  {permissions &&
                    (permissions.permissions || []).some(
                      (item) => item.permissionId === "createCustomDashboard"
                    ) ? (
                    <Button
                      data-testid="create-btn"
                      variant="outlined"
                      size="small"
                      startIcon={<Add />}
                      sx={{ ...buttonClasses.lynkitOrangeEmpty }}
                      onClick={() => history.push("/dashboard/create-custom-dashboard")}
                    >
                      Create New
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
                <GenericPageConfig
                  storedInPage={"customDashboardListPage"}
                  pageName={"customDashboardList"}
                  // pageTabCount={inventoryTypeCount}
                  pageStatusCount={customDashboardStatusCount}
                  // handlePageTabChange={handleTypeChange}
                  selectedPageTab={"Shipment"}
                  selectedStatusTab={tabIndex}
                  handleStatusTabChange={handleTabChange}
                  disabledPageTabs={loader}
                  disabledStatusTabs={loader}
                />
              </Grid>

              <Grid container spacing={1} xs={12} data-testid="filters">
                {renderHeadersFilter(tableHeader?.validHeader?.filter(dl => dl.isSearchable && dl?.mappingPath), filter, setFilter, setPage, setLimit, tabIndex)}
                {isFilterApplied && (
                  <Grid container item xs={"auto"} alignItems={"center"}>
                    <Typography
                      sx={{
                        ...textClasses.boldText,
                        color: theme.themeOrange,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={handleClearFilter}
                    >
                      Clear Filter(s)
                    </Typography>
                  </Grid>
                )}
                <Grid container item xs={"auto"} alignItems={"center"} data-testid="customise-table">
                  <GenericCustomiseHeader
                    tableName={"customDashboardTable"}
                    tableType={"customDashboardTable"}
                  />
                </Grid>
              </Grid>
              {selectedEntries?.length > 0 &&
                <Grid container sx={{ mt: 2, textTransform: "none" }}>

                  {selectedEntries.length === 1 && permissions && (
                    <>
                      {(permissions.permissions || []).some(
                        (item) => item.permissionId === "viewCustomDashboard"
                      ) && (
                          <Button
                            data-testid="view-btn"
                            size="small"
                            variant="contained"
                            sx={{
                              ...buttonClasses.lynkitOrangeFill,
                              mr: 1,
                              textTransform: "none",
                            }}
                            onClick={() => handleRowAndViewClick(selectedEntries[0])}
                          >
                            View
                          </Button>
                        )}
                    </>
                  )}
                </Grid>

              }

              <Grid container sx={{ mt: 2 }}>

                <Grid item
                  // sm={viewSkuGroupDetail ? 8 : 12}
                  xs={12}>
                  <Paper>
                    {loader == "tableData" ? <Grid container sx={{ mt: 2 }}>
                      <Grid item
                        xs={12}>
                        <Paper>
                          <Grid
                            data-testid="loader"
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ p: 10 }}
                          >
                            <Grid item
                              xs={12}>
                              <GenericLoader />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid> : <GenericTable
                      data-testid="skuGroupTable"
                      header={header}
                      rows={tableRows}
                      pageCount={customDashboardTableData?.count}
                      pageNumber={page}
                      handleChangePage={(event, pagevalue) => {
                        setPage(pagevalue);
                      }}
                      handleChangeRowsPerPage={(event) => {
                        setLimit(+event.target.value);
                      }}
                      rowsPerPage={limit}
                    />}
                  </Paper>
                </Grid>

                {actionIndex != null && (
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={actionIndex != null}
                    onClose={handleCloseAction}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    sx={{
                      display: actionIndex != null ? "block !important" : "none !important",
                    }}
                  >
                    {permissions &&
                      (permissions.permissions || []).some(
                        (item) => item.permissionId === "viewCustomDashboard"
                      ) && (
                        <MenuItem
                          key={1}
                          sx={{ ...textClasses.normalText }}
                          onClick={() => {
                            handleRowAndViewClick(customDashboardTableData?.data[actionIndex]);
                            handleCloseAction();
                          }}
                        >
                          View
                        </MenuItem>
                      )}
                  </Menu>
                )}
              </Grid>

            </> :
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ p: 4, border: `1px solid ${theme.themeGray}`, borderRadius: 2 }}
            >
              <img src={SelectImage} width="430" height="300" />
              <Typography sx={{ ...textClasses.cardTitle, mt: 2 }}>
                Create your first custom dashboard(s)
              </Typography>
              <Typography
                sx={{ ...textClasses.t12n, textAlign: "center", maxWidth: "500px", mt: 2 }}
              >
                Hey, you need to create a customized dashboard by publishing the form.
              </Typography>
              {(permissions.permissions || []).some((item) => item.permissionId === "createCustomDashboard") &&
                (<Button
                  variant="contained"
                  data-testid="create-group"
                  spacing={1}
                  onClick={() => { history.push("/dashboard/create-custom-dashboard") }}
                  sx={{
                    ...buttonClasses.lynkitBlackFill,
                    mt: 2,
                    ...textClasses.t14n,
                  }}
                // startIcon={<AddIcon />}
                >
                  Create Custom Dashboard
                </Button>)
              }
            </Grid>
      }
    </>);

}

export default CustomDashboard;