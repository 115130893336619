import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Paper,
  Badge,
} from "@mui/material";
import { Notifications as NotificationsIcon, CheckCircle as CheckCircleIcon } from "@mui/icons-material";
import theme from "../../../../../static/styles/theme";
import ClearIcon from '@mui/icons-material/Clear';


const NotificationWidget = ({ item, setLayout, styleView={} })=>{

  const [notifications, setNotifications] = useState([]);
  useEffect(()=>{
    setNotifications(item?.values || []);
}, [item])
  const markAsRead = (id) => {
    setNotifications(
      notifications.map((notification) =>
        notification.id === id
          ? { ...notification, read: true }
          : notification
      )
    );
  };

  return (
    <Paper elevation={3}  sx={{width:'inherit',height:'inherit',p:1 ,...styleView}}>
      <Typography variant="h6" style={{ marginBottom: "10px" }}>
        <Badge badgeContent={notifications.filter((n) => !n.read).length} color="primary">
          <NotificationsIcon />
        </Badge>
        Notifications
      </Typography>
      <IconButton
            size="small"
            sx={{
                position: "absolute",
                top: "1px",
                right: "5px",
                color: theme.themeOrange,
            }}
            onClick={() => {
                setLayout((prev) => prev.filter((l) => l.i !== item.i));
            }}
        >
            <ClearIcon fontSize="small" />
        </IconButton>
      <List>
        {notifications.map((notification) => (
          <ListItem key={notification.id} divider>
            <ListItemIcon>
              <Typography variant="h6">{notification.icon}</Typography>
            </ListItemIcon>
            <ListItemText
              primary={notification.title}
              secondary={
                <>
                  {notification.message}
                  <br />
                  <small>{notification.timestamp}</small>
                </>
              }
            />
            <ListItemSecondaryAction>
              {!notification.read && (
                <IconButton edge="end" onClick={() => markAsRead(notification.id)}>
                  <CheckCircleIcon color="primary" />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default NotificationWidget;