import React, { useEffect, useState } from 'react'
import {
    Button,
    Grid,
    Typography,
    Card,
    CardHeader,
    IconButton,
    CardContent,
} from "@mui/material";
import theme, { textClasses, buttonClasses, cardClasses } from '../../../../../static/styles/theme'
import {
    Close,
    SellOutlined,
} from "@mui/icons-material";
import GenericLoader from '../../../../Components/Generic/GenericLoader';
import { useDispatch, useSelector } from 'react-redux'
import { getDeKitDetails , getInHouseDePalletDetail} from '../../../../../redux/actions'
import moment from 'moment';
import ViewDePalletSkuComponents from './ViewDismantlePalletSkuComponents';

const ViewDePalletDetail = ({ cardType, viewDePalletDetailPage, setViewDePalletDetailPage }) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const { AllUserData } = useSelector((state) => state.users);
    const [dePalletDetailData, setDePalletDetailData] = useState({})
    const [viewModal, setViewModal] = useState({ type: "", action: "" })
    const packageId = viewDePalletDetailPage?.packageId
    const componentId = viewDePalletDetailPage?._id
    // console.log("id",viewDePalletDetailPage)
        let statusMapping = {
        'pending': {
            color: "#FF7200",
            background: "#ff720026",
            label: "Pending"
        },
        'approved': {
            color: "#3E621E",
            background: "#D7ECC4",
            label: "Approved"
        },
        'rejected': {
            color: "#E9001A",
            background: "#E9001A26",
            label: "Rejected"
        },
    }
    const status = dePalletDetailData && statusMapping[dePalletDetailData?.status?.toLowerCase()]
    let userInfo = (dePalletDetailData && AllUserData && AllUserData.data) ? AllUserData.data.filter(u => {
        if (dePalletDetailData?.rejected_by && dePalletDetailData?.rejected_by._id) {
            return u._id == dePalletDetailData?.rejected_by?._id
        }
        return false;
    }) : []
    userInfo = userInfo.length > 0 ? userInfo[0] : null
    const DetailRow = ({ title, children }) => {
        return (
            <Grid container sx={{ mt: 1 }}>
                <Grid item sm={4} xs={4}>
                    <Typography
                        sx={{
                            ...textClasses.t12n,
                            color: "#827878",
                            wordWrap: "break-word",
                            mr: 1,
                        }}
                    >
                        {title}:{" "}
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    sm={8}
                    xs={8}
                    direction="row"
                    alignItems={"center"}
                    sx={{ wordWrap: "break-word", wordBreak: "break-all" }}
                >
                    {children}
                </Grid>
            </Grid>
        );
    }
    const refreshContent = () => {
        setLoader(true);
        dispatch(
            getInHouseDePalletDetail({ componentId: componentId}, (data) => {
                if (data) {
                    setDePalletDetailData(data)
                    setLoader(false);
                }
                else {
                    setLoader(false);
                }

            })
        )
    }
    useEffect(() => {
        refreshContent()
    }, [packageId,componentId])
    return (
        <>

            {loader ?
                <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                    <Grid item><GenericLoader /></Grid>
                </Grid> :
                dePalletDetailData && (
                    <Card
                        sx={{
                            ...cardClasses.basic,
                            borderRadius: "8px",
                            border: `1px solid ${theme.themeOrange}`,
                            position: "relative",
                            height: "700px",
                        }}
                    >
                        <CardHeader
                            sx={{ height: "46px", backgroundColor: theme.themeOrange }}
                            title={
                                <Typography
                                    data-testid="card-header"
                                    sx={{
                                        ...textClasses.boldText,
                                        color: "white",
                                        fontSize: "16px",
                                    }}
                                >
                                    <SellOutlined fontSize="inherit" sx={{ mr: 1 }} />
                                    Package ID: {packageId || "-"}
                                </Typography>
                            }
                            action={
                                <IconButton
                                    size="small"
                                    sx={{ p: 0, mb: 1 }}
                                    onClick={() => setViewDePalletDetailPage(null)}
                                >
                                    <Close sx={{ color: "white" }} />
                                </IconButton>
                            }
                        />
                        <CardContent
                            sx={{ minHeight: "600px", maxHeight: "600px", overflowY: "scroll" }}
                        >


                            <DetailRow title="Pallet ID">
                                <Typography data-testid="kit-id" sx={{ ...textClasses.t12n }}>
                                    {dePalletDetailData?.Pallet_ID ? dePalletDetailData?.Pallet_ID : "-"}</Typography>
                            </DetailRow>
                            <DetailRow title="Package ID">
                                <Typography data-testid="item-id" sx={{ ...textClasses.t12n }}>
                                    {dePalletDetailData?.packageId ? dePalletDetailData?.packageId : "-"}</Typography>
                            </DetailRow>
                            <DetailRow title="Current Status">
                                <Button sx={{ p: 0, cursor: "text !important", ...buttonClasses.small, color: status?.color, backgroundColor: status, border: `1px solid ${status?.color}`, "&:hover": { backgroundColor: status?.background } }}>{status?.label}</Button>
                                <Typography data-testid="current-status" sx={{ ...textClasses.t12n, color: theme.themeOrange, ml: 1 }}>
                                </Typography>
                            </DetailRow>
                            <DetailRow title="Created At">
                                <Typography data-testid="created-at" sx={{ ...textClasses.t12n }}>
                                    {dePalletDetailData?.createdAt ? moment(dePalletDetailData?.createdAt).format("DD MMM YYYY hh:mm A") : "-"}</Typography>
                            </DetailRow>
                            <DetailRow title="Updated At">
                                <Typography data-testid="updated-at" sx={{ ...textClasses.t12n }}>
                                    {dePalletDetailData?.updatedAt ? moment(dePalletDetailData?.updatedAt).format("DD MMM YYYY hh:mm A") : "-"}</Typography>
                            </DetailRow>
                            {dePalletDetailData?.components &&
                                <DetailRow title="Components">
                                    <Typography
                                        data-testid="components"
                                        sx={{
                                            ...textClasses.t12n,
                                            color: theme.themeOrange,
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            setViewModal({ ...viewModal, type: "viewComponents", action: dePalletDetailData?.components || "" })
                                        }}
                                    >
                                        View Components ({dePalletDetailData?.components ? dePalletDetailData?.components?.length: "-"})
                                    </Typography>
                                </DetailRow>
                            }
                            {dePalletDetailData?.rejected_by ? <>
                                <DetailRow title="Rejected Reason">
                                    <Typography data-testid="rejected-reason" sx={{ ...textClasses.t12n }}>
                                    {dePalletDetailData?.rejected_by?.rejectedReason ? dePalletDetailData?.rejected_by?.rejectedReason : "-"}
                                    </Typography>
                                </DetailRow>
                                <DetailRow title="Rejected By">
                                    <Typography data-testid="rejected-by" sx={{ ...textClasses.t12n }}>
                                    {userInfo && userInfo.name}, {userInfo && userInfo.designation}
                                    </Typography>
                                </DetailRow>
                                <DetailRow title="Rejected At">
                                    <Typography data-testid="rejected-at" sx={{ ...textClasses.t12n }}>
                                        {dePalletDetailData?.rejected_by?.date ? moment(dePalletDetailData?.rejected_by?.date).format("DD MMM YYYY hh:mm A") : "-"}
                                    </Typography>
                                </DetailRow>
                                </> :
                                " "
                            }
                        </CardContent>
                    </Card>
                )
            }
            {
                viewModal.type == "viewComponents" && cardType == "dePalletization" &&
                <ViewDePalletSkuComponents
                    viewModal={viewModal}
                    setViewModal={setViewModal}
                />
            }
        </>
    )

};

export default ViewDePalletDetail;
