import theme,{inputClasses} from "../static/styles/theme";

export const selectStylesOverride = {
	// "& [class*='MuiInputBase-root-MuiOutlinedInput-root'].Mui-focused .MuiOutlinedInput-notchedOutline":
	// 	{
	// 		borderColor: theme.themeOrange,
	// 		borderWidth: 1,
	// 	},
	// "& [class*='MuiFormLabel-root-MuiInputLabel-roo'].Mui-focused": {
	// 	color: theme.themeBlack,
	// },
	...inputClasses.themeBoundary
};

export function parseCamelCase(str) {
	let result = "";

	str.split("").map((s, i) => {
		if (s.match(/[A-Z]/)) {
			result += " " + s;
		} else if (i == 0) {
			result += s.toUpperCase();
		} else {
			result += s;
		}
	});
	return result;
}

const formulaFields = {
	"targetElement": "expiryTime",
	"isPrimary": true,//will calculate at first render, other will on change
	"type": "date",
	"formula": [
		{
			"type": "formulafields",
			"FieldValue": "manufactureDate",
			"FieldAs": "field",
			"FieldType": "Date"
		},
		{
			"type": "subtract",
			"FieldAs": "operator",
			"FieldType": "",
			"FieldValue": "+"
		},
		{
			"type": "formulafields",
			"FieldValue": "masterDetail.shelfLife",
			"OtherValue": [
				"asnDetail.shelfLife",
				"shelfLife"
			],
			"FieldAs": "field",
			"FieldType": "Number",
			"Unit": "days"
		}
	]
}