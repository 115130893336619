import React, { useEffect, useRef,useMemo } from "react";
import { useState } from "react";
import {
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Button,
  TextField,
  Select,
  MenuItem,
  FormLabel,
  InputLabel,
  Typography,
  Tab,
  Tabs,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  Chip,
  Paper,
  IconButton,
  Menu,
  Tooltip,
  setRef,
  Autocomplete,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  Add,
  Cancel,
  Clear,
  KeyboardArrowDown,
  Search,
} from "@mui/icons-material";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsers,
  getAllKits,
  getKITStatusCount,
  approveRejectCompleteSales,
  getAllDekits,
  getDeKitStatusCount,
  approveRejectDeKit,
  getDeKitDetails,
  getCurrentKitCount,
  getJobProcessConfig,
  getAllWarehouses,
} from "../../../../redux/actions";
import theme, {
  buttonClasses,
  inputClasses,
  textClasses,
  cardClasses,
} from "../../../../static/styles/theme"
import { Link } from "react-router-dom";
import {
  capitalizeSentence,
  getCamelCaseText,
  getShortText,
  showToast,
} from "../../../../services/functions";
import GenericSingleRangeComponent from "../../../Components/Generic/GenericSingleRangeComponent";
import { useForm } from "react-hook-form";
import history from "../../../../services/history";
import { renderHeadersFilter } from "../../../../services/functions";
import { DatePicker } from "@mui/x-date-pickers";
import { FETCH_ASN_DETAIL, FETCH_TEMPLATE_HEADER } from "../../../../redux/types";
import GenericCustomiseHeader from "../../../Components/Generic/GenericCustomiseHeader";
import GenericTable from "../../../Components/CustomTable/GenericTable";
import { useDebounce } from "../../../../utils/hooks";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import { removeUntilE } from "../../../../utils";
import BundlingModal from "./BundlingModal";
import kitImage from "../../../../static/images/inventory/kit.svg"
import ViewDekitDetail from "./ViewDekitDetail";
import CreateDekit from "./CreateDekit";
import ViewDekitSkuComponents from "./ViewDekitSkuComponents";
import { multiDateToISOConvertor } from "../../../Components/Generic/GenericMultiDateRange";
import ApproveRejectConfirmationDialog from "./ApproveRejectConfirmationDialog";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


export const getSpentTime = (then, now) => {
  if (then != null) {
    then = moment(then);
    now = now ? moment(now) : moment();

    let dayDiff = now.diff(then, "days");
    let hourDiff = now.diff(then, "hours");
    let minDiff = now.diff(then, "minutes");
    let timeSpent;

    if (dayDiff < 1) {
      if (hourDiff < 1) {
        timeSpent = `${minDiff} min ago`;
      } else {
        timeSpent = `${hourDiff} hrs ago`;
      }
    } else {
      timeSpent = `${dayDiff} d ago`;
    }
    return timeSpent;
  } else {
    return "-";
  }
};

let initial_state = {
  "search": {},
  "other": {},
}

export default function KitAndBundle() {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [operationType,setOperationType] = useState("kit")
  const kitData = useSelector((state) => operationType === "kit" ? state.Kit.allKit : state.Dekit.allDeKit)
  const allWarehouses = useSelector((state) => state.inward.allWarehouses);
  const currKitDataRows = useSelector((state)=> state.Kit.totalRows)
  const currDekitDataRows = useSelector((state) => state.Dekit.totalRows)
  const statusCount = useSelector((state) => operationType === "kit" ? state.Kit.statusCount: state.Dekit.statusCount);
  const totalKitRows = useSelector((state) => state.Kit.statusCount?.totalCount)
  const totalDekitRows = useSelector((state) => state.Dekit.statusCount?.totalCount)
  const [tabIndex, setTabIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loader, setLoader] = useState(false);
  const [statusFilter, setStatusFilter] = useState(null);
  const [initialRender, setInitialRender] = useState(true)
  const [filter, setFilter] = useState(initial_state)
  const [warehouseFilter, setWarehouseFilter] = useState(null);
  const statusList = operationType == "kit" 
  ? [
      { label: `Pending Kits`, value: "pending" },
      { label: `Partial Approved Kits`, value: "partialApproved" },
      { label: `Approved Kits`, value: "approved" },
      { label: `Completed Kits`, value: "completed" },
      { label: `Drafted Kits`, value: "drafted" },
      { label: `Rejected Kits`, value: "rejected" },
    ]
  : [
      { label: `Pending De-Kits`, value: "pending" },
      { label: `Approved De-Kits`, value: "approved" },
      { label: `Rejected De-Kits`, value: "rejected" },
    ];
  const isFilterApplied = (() => {
    if (tabIndex == 0 && statusFilter) {
      return true;
    }
    if (Object.values(filter["search"])?.some(dl=>dl && dl.length>0) || Object.values(filter["other"]).some(dl=>dl && dl.length>0)) {
      return true;
    }
    return false;
  })();

  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState(null);
  const openAction = Boolean(anchorEl);

  const { userPermissions } = useSelector((state) => state.userPermission);
  let permissions = userPermissions || {};

  const handleOpenAction = (event, i) => {
    setAnchorEl(event.currentTarget);
    setActionIndex(i);
  };
  const handleCloseAction = () => {
    setActionConfirmModal(null);
    setActionIndex(null);
    if (setSelectedEntries) {
      setSelectedEntries([]);
    }
    setTimeout(() => {
      setAnchorEl(null);
    }, 1);
  };
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [confirmModal, setConfirmModal] = useState(null);
  const [actionConfirmModal, setActionConfirmModal] = useState(null);
  const [actionReceiveModal, setActionReceiveModal] = useState(null);
  const [viewModal,setViewModal]=useState({type:"",action:""})
  const [viewDekitDetailPage,setViewDekitDetailPage] = useState(null)
  const debouncedFilterSearch = useDebounce(filter?.search, 500);

  // const approveCondition = (() => {
  //   let flag = true;
  //   // Check if there's any status that is not 'pending' or 'partialPending'
  //   if (
  //     selectedEntries.filter((st) => ["pending", "partialPending"].indexOf(st.status) < 0)
  //       .length > 0
  //   ) {
  //     flag = false;
  //   }
  //   return flag;
  // })();

  // const rejectCondition = (()=> {
  //   let flag = true;
  //   // Check if there's any status that is not 'pending' or 'partialPending'
  //   if (
  //     selectedEntries.filter((st) => ["pending"].indexOf(st.status) < 0)
  //       .length > 0
  //   ) {
  //     flag = false;
  //   }
  //   return flag;
  // })();

// Check if only one checkbox is selected and its status is either 'pending' or 'partialApproved' for Approve button
const approveCondition = selectedEntries.length === 1 && ["pending", "partialApproved"].includes(selectedEntries[0].status);

// Check if only one checkbox is selected and its status is 'pending' for Reject button
const rejectCondition = selectedEntries.length >=1 && selectedEntries[0].status === "pending";

  const handleTabChange = (event, value) => {
    if (operationType == "kit") {
      switch (value) {
        case 0:
          setStatusFilter(null);
          break;
        case 1:
          setStatusFilter(statusList[0]);
          break;
        case 2:
          setStatusFilter(statusList[1]);
          break;
        case 3:
          setStatusFilter(statusList[2]);
          break;
        case 4:
          setStatusFilter(statusList[3]);
          break;
        case 5:
          setStatusFilter(statusList[4]);
          break;
        case 6:
          setStatusFilter(statusList[5]);
          break;
        default:
          break;
      }
    } else {
      switch (value) {
        case 0:
          setStatusFilter(null);
          break;
        case 1:
          setStatusFilter(statusList[0]);
          break;
        case 2:
          setStatusFilter(statusList[1]);
          break;
        case 3:
          setStatusFilter(statusList[2]);
          break;
        default:
          break;
      }
    }
    setPage(0);
    setTabIndex(value);
    setViewDekitDetailPage(null)
    refreshTableData(0, limit, value && statusList[value - 1]?.value || "", false)
  };
  const handleCheckAll = () => {
    if (selectedEntries.length < kitData?.length) {
      const sT = kitData?.map((t) => ({
        _id: t._id,
        status: t.status,
        name: t.name,
        ...(operationType === "kit" 
          ? { KIT_ID: t.KIT_ID, totalQuantity: t.totalQuantity } 
          : { itemId: t.itemId })
      }));
      setSelectedEntries(sT);
    } else {
      setSelectedEntries([]);
    }
  };


const tableHeader = useSelector((state) => operationType === "kit" ? state.customHeader["kitHeader"] : state.customHeader["Dekitting Table"]) || {};
  const getTableCell = (header, row, i) => {

    if (header?.handleType) {
      if (header?.handleType === "selectRow") {
        return (
          <Checkbox
            size="small"
            checked={selectedEntries.map((o) => o._id).indexOf(row._id) >= 0}
            onChange={(e) => handleCheckChange(e, row)}
          />
        );
      } else if (header?.handleType === "showDetail") {
        return (
          operationType === "kit" ? (
            <Link
            to={`/dashboard/data/view-kit/${row.KIT_ID}`}
              style={{
                ...textClasses.normalText,
                textDecoration: "none",
                color: theme.themeOrange,
                textAlign: "center",
              }}
            >
              {row[header?.mappingPath] ?? "-"}
            </Link>
          ) : (
            <Typography
              onClick={() => setViewDekitDetailPage(row)}
              style={{
                ...textClasses.normalText,
                color: theme.themeOrange,
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              {row[header?.mappingPath] ?? "-"}
            </Typography>
          )
        );
      }
      else if (header?.handleType === "viewComponents") {
        return (
          row[header?.mappingPath]?.length > 0 ? <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, cursor: "pointer" }}
            onClick={() => {
              dispatch(
                getDeKitDetails({ kitId: row?.itemId, componentId: row?._id }, (data) => {
                    if (data) {
                      setViewModal({ ...viewModal, type:header?.handleType, action: data?.components || [] })
                    }
                })
            )
              
            }}>
            {row[header?.mappingPath]
              ? Array.isArray(row[header?.mappingPath])
                ? row[header?.mappingPath]?.length > 0 && row[header?.mappingPath].join(", ") || "-"
                : row[header?.mappingPath]
              : "-"
            }
          </Typography> : "-"
        )
      }
      else if (header?.handleType === "action") {
        return (
          <Typography
            data-testid="dropdown"
            sx={{
              ...textClasses.normalText,
              color: theme.themeOrange,
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenAction(e, i)}
          >
            Action <KeyboardArrowDown />
          </Typography>
        );
      }
    }
    else if (header?.isFromRenderFunction === true) {
      let no;
      if (page === 0) {
        no = i + 1;
      } else {
        no = page * 10 + (i + 1);
      }
      if (header?.renderFunction === "srNo") {
        return (
          <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
            {no}
          </Typography>
        );
      }
      else if (header?.renderFunction === "status") {
        const statusColor = {
          pending: "#FF7200",
          partialapproved:'#FF7200',
          approved: "#3E621E",
          completed: "#3E621E",
          drafted: "#FF0101",
          rejected: "#FF0101",
          default: "#000",
          completed: "#3E621E",
          inprogress: "#FF7200",
        };
        const statusText = {
          pending: "Pending",
          partialapproved: "Partial-Approved",
          approved: "Approved",
          completed: "Completed",
          drafted: "Drafted",
          rejected: "Rejected",
          completed: "Completed",
          inprogress: "Inprogress",
        };

        return (
          <>
            {["pending", "partialApproved", "approved", "completed", "drafted", "rejected","completed","inprogress"].includes(row[header?.mappingPath]) && (
              <Typography
                sx={{
                  ...textClasses.normalText,
                  textAlign: "center",
                  color: statusColor[row[header?.mappingPath] ? row[header?.mappingPath].toLowerCase() : "default"],
                }}
              >
                {row[header?.mappingPath] ? statusText[row[header?.mappingPath].toLowerCase()] : "-"}
              </Typography>
            )}
          </>
        );
      }
    }
    else {
      if (header?.fieldType === "array") {
        return (
          <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
            {header.mappingPath && row[header.mappingPath]
              ? Array.isArray(row[header.mappingPath])
                ? row[header.mappingPath].join(", ")
                : row[header.mappingPath]
              : "-"}
          </Typography>
        );
      }
      else if (header?.fieldType === "date") {
        return (
          <Typography
            sx={{
              ...textClasses.normalText,
              textAlign: "center",
              textTransform: "none",
            }}
          >
            {header.mappingPath && row[header.mappingPath]
              ? moment(row[header.mappingPath]).format(
                header?.format || "DD MMM YYYY hh:mm A"
              )
              : "-"}
          </Typography>
        );
      }
      else {
        return (
          <Typography
            sx={{
              ...textClasses.normalText,
              textAlign: "center",
              textTransform: "none",
            }}
          >
            {header.mappingPath ? row[header.mappingPath] : "-"}
          </Typography>
        );
      }
    }
  };


  let header = tableHeader && tableHeader?.validHeader && tableHeader.validHeader.map((h) => {
    if (h.handleType === "selectRow") {
      return (
        <Checkbox
          data-testid="checkbox"
          checked={
            kitData?.length != 0 &&
            selectedEntries.length == kitData?.length
          }
          onChange={handleCheckAll}
          size="small"
        />
      )
    }
    return h.fieldName
  })
    || [];

  const tableRows = kitData?.map((row, i) => {
    let items = [];

    const validHeaders = tableHeader?.validHeader ?? [];
    for (const header of validHeaders) {
      items.push(getTableCell(header, row, i));
    }
    return items;
  });


  const handleCheckChange = (event, row) => {
    if (event.target.checked) {
      handleAddSelection(row);
    } else {
      handleRemoveSelection(row._id);
    }
  };


  const handleAddSelection = (obj) => {
    setSelectedEntries([...selectedEntries, obj]);
  };

  const handleRemoveSelection = (id) => {
    let oldEntries = [...selectedEntries];
    const i = oldEntries.map((o) => o._id).indexOf(id);
    if (i >= 0) {
      oldEntries.splice(i, 1);
      setSelectedEntries(oldEntries);
      if (oldEntries.length == 0) {
        handleCloseConfirmModal();
      }
    }
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(null);
  };

  const handleCloseActionReceiveModal = () => {
    setActionReceiveModal(null);
  };

  const handleClearFilter = () => {
    setFilter(initial_state)
    setPage(0);
    setLimit(10);
    if (tabIndex == 0) {
      setStatusFilter(null);
      // setFilter(initial_state)

    }
  };

  const handleOperationTypeChange = (type) => {
    setOperationType(type);
    setStatusFilter(null)
    setTabIndex(0)
    setFilter(initial_state)
    setPage(0);
    setLimit(10);
    setSelectedEntries([])
    setViewDekitDetailPage(null)
    setWarehouseFilter(null);
  };

  useEffect(() => {
      dispatch(getAllUsers({ "isAdminIncluded": true }));
  }, [])

  const searchableFilterItems = useMemo(() => {
    if (!tableHeader || !tableHeader.validHeader) return [];
    return tableHeader.validHeader.filter((dl) => dl?.isSearchable) || [];
  }, [tableHeader]);
  
 
  const refreshTableData = (pageNo, limitNo, status = "", fetchCount = true) => {
    setLoader(true);
    const payload = {
      page: pageNo,
      limit: limitNo,
      warehouseId: warehouseFilter ? warehouseFilter._id : "",
      status: status || ""
    };

    if (filter && Object.keys(filter).length > 0) {
      for (let key in filter) {
        if (key && Object.keys(filter[key]).length > 0) {
          Object.assign(payload, filter[key])
        }
      }
      // Process date filter items
      const dateFilterItems = searchableFilterItems?.filter(
        (item) => item?.searchInputType === "date"
      );

      dateFilterItems.forEach((element) => {
        const payloadKey = element?.payloadKey || element?.mappingPath || "";
        if (payloadKey && payload[payloadKey]) {
          payload[payloadKey] = multiDateToISOConvertor(payload[payloadKey]);
        }
      });
    }
    if (fetchCount) {
      dispatch(getDeKitStatusCount({ ...payload, status: "" }));
    }
    if (operationType == "kit") {
      dispatch(
        getAllKits(payload, () => {
          setLoader(false);
        })
      );
      dispatch(getKITStatusCount({ ...payload, status: "" }));
    } else {
      dispatch(getAllDekits(payload, () => {
        setLoader(false);
      })
      );
      dispatch(getDeKitStatusCount({ ...payload, status: "" }));
    }
  };

  let fetchData = (fetchCount = false) => {
    refreshTableData(page + 1, limit, statusFilter?.value || "", fetchCount);
    setConfirmModal(null);
    setActionConfirmModal(null);
    setAnchorEl(null);
    setActionIndex(null);
  };

  useEffect(() => {

    fetchData(initialRender);
    if (initialRender) {
      setInitialRender(false)
    }
  }, [page, limit, filter.other, debouncedFilterSearch,operationType, warehouseFilter]);

  useEffect(()=>{
    dispatch(getAllWarehouses({ type: "warehouse" }));
  },[])

  return (
    <>
      {
        <>
          <Grid container>
            <Grid item sm={6}>
              <Typography sx={{ ...textClasses.cardTitle }} role="heading">
                Manage Kit(s)
              </Typography>
            </Grid>
            <Grid container item sm={6} justifyContent="end" alignItems="end">
            <Autocomplete
                  value={warehouseFilter}
                  onChange={(e, option) => {
                    setWarehouseFilter(option);
                    setPage(0);
                    setLimit(10);
                  }}
                  getOptionLabel={(option) => option.name}
                  size="small"
                  options={allWarehouses}
                  sx={{
                    ...inputClasses.filterField,
                    minWidth: "250px",
                    mr: 1,
                    ".MuiInputBase-input": {
                      fontSize: "15px",
                      color: "#455A64",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "#455A64", ...textClasses.normalText }}
                      {...params}
                      label="All Warehouse"
                    />
                  )}
                />
              {operationType === "kit" ? (
                permissions &&
                  (permissions.permissions || []).some(
                    (item) => item.permissionId === "createKit"
                  ) && (
                    <Button
                      data-testid="create-new-kit"
                      // variant="outlined"
                      size="small"
                      startIcon={<Add />}
                      sx={{ ...buttonClasses.lynkitOrangeEmpty }}
                      onClick={() => history.push("/dashboard/data/kit")}
                    >
                      Create New Kit
                    </Button>
                  )
              ) : (
                permissions &&
                (permissions.permissions || []).some(
                  (item) => item.permissionId === "createDekit"
                ) && (
                  <Button
                    data-testid="create-dekit"
                    // variant="outlined"
                    size="small"
                    startIcon={<Add />}
                    sx={{ ...buttonClasses.lynkitOrangeEmpty }}
                    onClick={() => {
                      setViewModal({ ...viewModal, type: "createDekitRequest", action: '' })
                    }}
                  >
                    Create De-kit Request
                  </Button>
                )
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {totalKitRows > 0 && (
              <Grid item md={2.5} sm={4} xs={12}>
                <Paper
                  data-testid="allPack"
                  sx={{
                    cursor: "pointer",
                    ...cardClasses.basic,
                    p: 2,
                    border:
                      operationType == "kit"
                        ? "1px solid #FF7200"
                        : "1px solid #D9D9D9",
                    height: "100%",
                  }}
                  onClick={() => handleOperationTypeChange("kit")}
                >
                  <Grid container spacing={2}>
                    <Grid
                      container
                      item
                      xs={4}
                      justifyContent="center"
                      alignItems={"center"}
                    >
                      <img src={kitImage} width={55} height={50} />
                    </Grid>
                    <Grid container item direction={"column"} xs={8}>
                      <Typography
                        sx={{
                          ...textClasses.t20n,
                          fontWeight: "600",
                          color: operationType == "kit" && theme.themeOrange,
                        }}
                      >
                        {totalKitRows ? totalKitRows : 0}
                      </Typography>
                      <Typography
                        sx={{
                          ...textClasses.normalText,
                          color: operationType == "kit" && theme.themeOrange,
                        }}
                      >
                        Kitting & Bundling
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
            {totalDekitRows >= 0 && (
              <Grid item md={2.5} sm={4} xs={12}>
                <Paper
                  data-testid="allPack"
                  sx={{
                    cursor: "pointer",
                    ...cardClasses.basic,
                    p: 2,
                    border:
                      operationType == "De-kit"
                        ? "1px solid #FF7200"
                        : "1px solid #D9D9D9",
                    height: "100%",
                  }}
                  onClick={() => handleOperationTypeChange("De-kit")}
                >
                  <Grid container spacing={2}>
                    <Grid
                      container
                      item
                      xs={4}
                      justifyContent="center"
                      alignItems={"center"}
                    >
                      <img src={kitImage} width={55} height={50} />
                    </Grid>
                    <Grid container item direction={"column"} xs={8}>
                      <Typography
                        sx={{
                          ...textClasses.t20n,
                          fontWeight: "600",
                          color: operationType == "De-kit" && theme.themeOrange,
                        }}
                      >
                        {totalDekitRows ? totalDekitRows : 0}
                      </Typography>
                      <Typography
                        sx={{
                          ...textClasses.normalText,
                          color: operationType == "De-kit" && theme.themeOrange,
                        }}
                      >
                        De-Kitting
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Grid>
          <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 1 }}>
            <Tabs
              value={tabIndex}
              aria-label="disabled tabs example"
              onChange={handleTabChange}
              sx={{
                "& .MuiTabs-indicator": { backgroundColor: theme.themeOrange },
                "& .MuiTab-root.Mui-selected": { color: theme.themeOrange },
              }}
            >
              <Tab
                label={`All ${operationType} (${statusCount && statusCount.totalCount
                    ? statusCount.totalCount
                    : 0
                  })`}
                {...a11yProps(0)}
                sx={{ ...textClasses.normalText, textTransform: "none" }}
              />
              {statusList.map((s, i) => {
                return (
                  <Tab
                    data-testid={`${s.label}`}
                    label={`${s.label} (${statusCount &&
                        statusCount[
                        `${s.value == "incoming" ? "upcomming" : s.value}Count`
                        ]
                        ? statusCount[
                        `${s.value == "incoming" ? "upcomming" : s.value
                        }Count`
                        ]
                        : 0
                      })`}
                    {...a11yProps(1)}
                    sx={{ ...textClasses.normalText, textTransform: "none" }}

                    key={i}
                  />
                  // <Tab  data-testid= {`${s.label}`} label={`${s.label} (${statusCount && statusCount[`${s.value}Count`] ? statusCount[`${s.value}Count`]: 0})`} {...a11yProps(1)} sx={{ ...textClasses.normalText, textTransform: "none" }} key={i} />
                );
              })}
            </Tabs>
          </Box>
          <Grid container spacing={1}>
          {renderHeadersFilter(tableHeader?.validHeader?.filter(dl => dl.isSearchable && dl?.mappingPath),filter,setFilter,setPage,setLimit,tabIndex)}
            {isFilterApplied && (
              <Grid container item sx={4} sm={2} alignItems={"center"}>
                <Typography
                data-testid="clearfilter-kit"
                  sx={{
                    ...textClasses.boldText,
                    color: theme.themeOrange,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={handleClearFilter}
                >
                  Clear Filter(s)
                </Typography>
              </Grid>
            )}
            <Grid container item xs={"auto"} alignItems={"center"}>
              <GenericCustomiseHeader
                data-testid="customise-table"
                tableName={operationType == "kit" ? "kitHeader" : "Dekitting Table"}
                tableType={operationType == "kit" ? "kitTable" : "dekittingTable"}
              />
            </Grid>
          </Grid>
          <ApproveRejectConfirmationDialog
            status={confirmModal}
            handleClose={handleCloseConfirmModal}
            selectedEntries={selectedEntries}
            setSelectedEntries={setSelectedEntries}
            refreshTableData={() =>{
              refreshTableData(page + 1, limit)
              setTabIndex(0)
            }}
            handleRemoveSelection={handleRemoveSelection}
            operationType={operationType}
          />
         
          {viewModal.type == "startBundling" &&
            <BundlingModal
              viewModal={viewModal}
              setViewModal={setViewModal}
              setSelectedEntries={setSelectedEntries}
              refreshTableData={() => {
                refreshTableData(page + 1, limit)
                setTabIndex(0)
              }}
           />
          }
          {viewModal.type=="createDekitRequest" && 
           <CreateDekit
           viewModal={viewModal}
           setViewModal={setViewModal}
          //  setSelectedEntries={setSelectedEntries}
          //  refreshTableData={() => refreshTableData(page + 1, limit)}
           />
          }
          {viewModal.type==="viewComponents" &&
                 <ViewDekitSkuComponents
                 viewModal={viewModal}
                 setViewModal={setViewModal}
             />
          }
          {selectedEntries.length > 0 && (
            <Grid container sx={{ mt: 2, textTransform: "none" }}>
              {selectedEntries.length == 1 && (
                <>
                  {permissions &&
                    (permissions.permissions || []).find(
                      (item) => item.permissionId === "viewKit"
                    ) ? (
                    <Button
                      data-testid="view-btn"
                      size="small"
                      variant="contained"
                      sx={{
                        ...buttonClasses.lynkitOrangeFill,
                        textTransform: "none",
                      }}
                      onClick={() =>
                        operationType === "kit" ?
                        history.push(
                          `/dashboard/data/view-kit/${selectedEntries[0]?.KIT_ID}`
                        ) :  setViewDekitDetailPage(selectedEntries[0])
                      }
                    >
                      View
                    </Button>
                  ) : (
                    ""
                  )}

                </>
              )}
              {permissions &&
                (permissions.permissions || []).some(
                  (item => ["acceptRejectKit", "acceptRejectDekit"].includes(item.permissionId))
                ) ? <>
                {
                  approveCondition && (
                    <Button
                      data-testid="approve-btn"
                      variant="outlined"
                      sx={{
                        ...buttonClasses.approvedButton,
                        ml: 1,
                        mr: 1
                      }}
                      onClick={() => setConfirmModal("approved")}
                    >Mark As Approved</Button>
                  )}</> : (
                ""
              )
              }
              {permissions &&
                (permissions.permissions || []).some(
                  (item => ["acceptRejectKit","acceptRejectDekit"].includes(item.permissionId))
                ) ? <>

                {rejectCondition && (
                  <Button
                  data-testid="reject-btn"
                  size="small"
                  variant="outlined"
                  sx={{
                    ...buttonClasses.lynkitOrangeFill,
                    backgroundColor: "#E9001A",
                      border: "1px solid #E9001A",
                      mr: 1,
                    }}
                    onClick={() => setConfirmModal("rejected")}
                  >
                    Reject
                  </Button>
                )}</> : (
                  ""
                )
              }
              {permissions?.permissions?.some((item) => item.permissionId === "startPartialBundling") &&
                selectedEntries?.length == 1 && (["approved","partialApproved"].includes(selectedEntries[0].status) && !["completed"].includes(selectedEntries[0].jobStatus)) && selectedEntries[0]?.availableForBundeling
                && operationType=="kit" 
                ? <Button
                  data-testid="startBundling-btn"
                  size="small"
                  variant="outlined"
                  sx={{
                    ...buttonClasses.lynkitOrangeFill,
                    mr: 1,
                  }}
                  onClick={() => {
                    setViewModal({ ...viewModal, type: "startBundling", action: selectedEntries?.at(0) })
                  }}
                >
                  Start Bundling
                </Button> :
                ""
              }
            </Grid>
          )}
          <Grid container sx={{ mt: 2 }}>
          <Grid item sm={viewDekitDetailPage ? 8 : 12} xs={12}>
          <Paper>
             { loader ? (
                <Grid
                  data-testid="loader"
                  container
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems="center"
                  sx={{ p: 10 }}
                >
                  <Grid item>
                    <GenericLoader />
                  </Grid>
                </Grid>
              ) : (
                <GenericTable
                  data-testid="kittingTable"
                  header={header}
                  rows={tableRows}
                  pageCount={operationType=="kit" ? currKitDataRows : currDekitDataRows}
                  pageNumber={page}
                  handleChangePage={(event, pagevalue) => {
                    setPage(pagevalue);
                  }}
                  handleChangeRowsPerPage={(event) => {
                    setLimit(+event.target.value);
                  }}
                  rowsPerPage={limit}
                />
              )}
              </Paper>
            </Grid>
            {
            viewDekitDetailPage && (
            <Grid item sm={4} xs={12} sx={{ p: 1, pt: 0 }}>
            <ViewDekitDetail
            operationType={operationType}
            viewDekitDetailPage={viewDekitDetailPage}
            setViewDekitDetailPage={setViewDekitDetailPage}
            />
            </Grid>
            )
          }
          </Grid>
          <ApproveRejectConfirmationDialog
            status={actionConfirmModal}
            operationType={operationType}
            handleClose={handleCloseAction}
            selectedEntries={
              actionIndex != null ? [kitData[actionIndex]] : []
            }
            refreshTableData={() => refreshTableData(page + 1, limit)}
          />
        
          {actionIndex != null && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={actionIndex != null}
              onClose={handleCloseAction}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                display:
                  actionIndex != null ? "block !important" : "none !important",
              }}
            >
              {permissions &&
                (permissions.permissions || []).find(
                  (item) => item.permissionId === "viewKit"
                ) ? (
                <MenuItem
                  key={1}
                  sx={{ ...textClasses.normalText }}
                  onClick={() =>{
                    operationType === "kit" ?
                    history.push(
                      `/dashboard/data/view-kit/${kitData[actionIndex]?.KIT_ID}`
                    ) :  setViewDekitDetailPage(kitData[actionIndex])
                    handleCloseAction()
                  }}
                >
                  View
                </MenuItem>
              ) : (
                ""
              )}
              {/* {(kitData[actionIndex].status == "pending" || kitData[actionIndex].status == "drafted") && (
                <MenuItem
                  key={0}
                  sx={{ ...textClasses.normalText }}
                // onClick={() =>
                //   history.push(
                //     `/dashboard/data/edit-kit/${kitData[actionIndex]._id}`
                //   )
                // }
                >
                  Edit
                </MenuItem>
              )} */}

              {["pending","partialApproved"].includes(kitData[actionIndex].status)&& (
                <MenuItem
                  key={2}
                  sx={{ ...textClasses.normalText }}
                  onClick={() => setActionConfirmModal("approved")}
                >
                  Mark As Approved
                </MenuItem>
              )}
              {kitData[actionIndex].status == "pending" && (
                <MenuItem
                  key={2}
                  sx={{ ...textClasses.normalText }}
                  onClick={() => setActionConfirmModal("rejected")}
                >
                  Reject
                </MenuItem>
              )}
              {(kitData[actionIndex].status == "approved" && !["completed"]?.includes(kitData[actionIndex].jobStatus) && !!kitData[actionIndex].availableForBundeling &&
                <MenuItem
                  key={2}
                  sx={{ ...textClasses.normalText }}
                  onClick={()=>{setViewModal({...viewModal,type:"startBundling",action:kitData[actionIndex]})
                  handleCloseAction()} }
                >
                  Start Bundling
                </MenuItem>
              )}
            </Menu>
          )}
        </>
      }
    </>
  );
}