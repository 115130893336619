import React, {useState } from "react";
import { useDispatch } from "react-redux";
import {
	Grid, 
	Button,Typography, Box, 
} from "@mui/material";

import {
	approveJobCompletion,
} from "../../../../redux/actions/Job";
import theme,{ buttonClasses}from "../../../../static/styles/theme";
import { showToast} from "../../../../services/functions";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import CustomChip from "../../../Components/CustomChip";
import AlertDialog from "../../../Components/DialogBox/dialogbox";


const initFilters = {
	"search": {},
    "other": {},
	page: 1,
	limit: 10,
	// jobStatus: "",
	// jobName: "",
	// jobType: "",
	// createdOn: null,
	// completedOn: null,
	// jobId: "",
	// shipmentId: "",
};

export default function  ApproveJobCompletionSection (props){
    let dispatch = useDispatch()
    let { checkedRow, setCheckedRow, setFilter, setTabIndex, get_Job_All } = props
    let [loader, setLoader] = useState(null)

    // console.log("hhhh",checkedRow)

    let approveRejectHandler = () => {
        setLoader(true)
        dispatch(approveJobCompletion({ jobId: checkedRow?.selectedRow?.map(dl => dl.groupBy) || [], approved: checkedRow?.type == "rejectTrigger" ? false : true }, ({ success, message }) => {
            showToast(message, !success)
            setLoader(false)
            if (success) {
                setCheckedRow({ visible: false, selectedRow: [] })
                setFilter({ ...initFilters })
                get_Job_All(initFilters, true)
                setTabIndex(0)
            }
        }))
    }


    return <AlertDialog
        viewDialog={checkedRow?.visible}
        sx={{ borderRadius: "50px" }}
        handleClose={() => setCheckedRow({ ...checkedRow, visible: false })}
        bodyCSS={{ color: theme.themeBlack }}
        actionsCSS={{ marginTop: "-15px" }}
        body={
            <Box sx={{ marginTop: "-10px" }}>
                <Grid container xs={12} >
                    <Grid container item xs={12} justifyContent="flex-start">
                        <Typography sx={{ color: theme.themeBlack, fontWeight: 600, fontSize: "1.3rem" }} fullWidth>
                            {checkedRow?.type == "rejectTrigger" ? "Reject" : "Approve"} Job{checkedRow?.selectedRow?.length > 1 ? "(s)" : ""} Completion
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 1 }}>
                    {loader
                        ? <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12}>

                            <Grid item><GenericLoader /></Grid>
                        </Grid>
                        : <CustomChip
                            data={checkedRow?.selectedRow || []}
                            readLabelKey="groupBy"
                            makeDisabled={true}
                            handleDelete={(index, id) => {
                                // console.log(id, "id")
                                let tempRowSelect = checkedRow?.selectedRow || []
                                tempRowSelect.splice(index, 1)
                                setCheckedRow({ ...checkedRow, selectedRow: tempRowSelect })
                            }}
                        />
                    }
                </Box>
            </Box>
        }
    >
        <Button
            variant="outlined"
            disabled={loader}
            onClick={() => setCheckedRow({ ...checkedRow, visible: false })}
            sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, mt: 0, fontSize: ".7rem", minWidth: "130px" }}
        >Cancel</Button>

        <Button
            autoFocus
            variant="outlined"
            disabled={loader || !checkedRow || !checkedRow.selectedRow || checkedRow.selectedRow.length == 0}
            sx={{
                ...buttonClasses.lynkitOrangeFill, m: 1, mt: 0, fontSize: ".7rem", border: 'none', minWidth: "130px",
            }}
            onClick={approveRejectHandler}
        >
            {checkedRow?.type == "rejectTrigger" ? "Reject" : "Approved"}{checkedRow?.selectedRow?.length > 1 ? "(s)" : ""}
        </Button>
    </AlertDialog>
}