import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    TextField,
    Grid,
    Typography,
    Button, Box,
    Paper,
    InputAdornment, IconButton,
    inputClasses
} from "@mui/material";
import theme, {
    textClasses,
    buttonClasses,
} from "../../../../static/styles/theme";
import {
    GetInputFieldForTableRowComponent
} from "../../../Components/TemplateInputConfiguration";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import GenericSingleRangeComponent from "../../../Components/Generic/GenericSingleRangeComponent";
import { truncateNumber, calculateFormulaHelper, showToast,getSmallestRepeatingSubstring } from "../../../../services/functions";
import Table2 from "../Asn/Table2";
import { useDebounceWithUseEffectDetection, useDebounce } from "../../../../utils/hooks";
import { selectStylesOverride } from "../../../../utils/util";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import GenericToggle from "../../../Components/GenericToggle/index"



const ItemSection = ({
    template,
    errors,
    setErrors2,
    // dimensions,
    items,
    setItems,
    setValue1,
    totalQuantity,
    countTillNow,
    remainingItems,
    bundleType,
    defaultUOMValue,
    isScannedOn,
    setIsScannedOn,
    scannedRef,
    usnTypedRef,
    showToggleScanManual,
    onKeyPressEnterTrigger
}) => {
    //
    const [totalItemsLoader, setTotalItemsLoader] = useState(false);
    const mappingLocations = useSelector(state => state.inward.allMapping);
    const [scannedInput, setScannedInput] = useState("");
    const [usnValueInput, setUsnValueInput] = useState("");
    const [isAutoScanning, setIsAutoScanning] = useState(true)
    const debounceScannedInput = useDebounce(scannedInput, 1);
    const lastScannedRef = useRef(""); // Track last scanned QR code
    const lastScanTimeRef = useRef(0); // Timestamp of last scan
    const timerRef = useRef(null); // Track debounce timer

    let mappingField = {
        type: "select",
        label: "Mapping Area",
        required: true,
        _id: "mappingArea",
        values: mappingLocations || []
        // .map((loc) => ({
        //     label: loc.label,
        //     value: loc.value
        // }))
        ,
        defaultValue: "PUT IT ANY WHERE"
    }


    let allFields = template?.otherTemplate && template?.otherTemplate?.length
        ? template?.otherTemplate?.find(ot => ot?.templateType == `itemDetailTemplate`)?.attributes
        : [];

    allFields = allFields?.concat([mappingField]);

    let packageDetailsFields = template?.otherTemplate && template?.otherTemplate?.length ? template?.otherTemplate?.find(ot => ot?.templateType == `packageDetailTemplate`)?.attributes : [];
    const autoFillOnFocus = () => { };

    const convertDocsBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file);
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    };
                    fileReader.onerror = (error) => {
                        reject(error);
                    };
                }, 400);
            });
        } else {
        }
    };

    const handleGenericChange = async (event, i, fieldId, option = {}) => {
        const targetField = allFields.find((f) => f._id == fieldId);
        let oldItems = [...items];
        let oldObject = { ...oldItems[i] };
        if (targetField.type == "file") {
            const file = await convertDocsBase64(event.target.files[0]);
            oldObject[fieldId] = file;
        } else if (targetField.type == "checkbox") {
            oldObject[fieldId] = event?.target?.value || [];
        } else {
            const newValue = event.target.value; // Capture the full value
            if (fieldId == "USN_Value") {
                oldObject["SKU_Name"] = newValue;  // Assign full value
                oldObject["SKU_Number"] = newValue; // Assign full value
            }
            oldObject[fieldId] = newValue; // Assign full value for other fields
        }

        if (targetField?.extraValue?.length) {
            const tempOptions = option && typeof option == "object" ? option : {};
            targetField.extraValue.map(dl => {
                if (typeof dl == "string") {
                    oldObject[dl] = tempOptions[dl] || ""
                }
                else if (typeof dl == "object" && dl?.label) {
                    oldObject[dl.label] = tempOptions[dl?.label] || ""
                }
            })
        }

        oldItems[i] = { ...oldObject };
        setItems([...oldItems]);
    };

    const filteredTableHeaders = useMemo(() => {
        const arr = ["USN_Value", "count", "SKU_Name", "SKU_Number", "mappingArea",]
        if (defaultUOMValue) {
            arr.push("UOM")
        }
        return arr;
    }, [defaultUOMValue])

    const getTableHeader = (headerType) => {
        let base = ["Quantity", <Box sx={{ minWidth: "180px", textAlign: 'left', ml: 2 }}>USN</Box>]
        let otherHeader = allFields
            .filter((f) => filteredTableHeaders.indexOf(f._id) < 0)
            .map((of) => {
                let label = of.label;
                if (
                    headerType == "excel" &&
                    ["select", "radio", "checkbox"].indexOf(of.type) >= 0
                ) {
                    label = `${label} (${of.values.map((o) => o.value).join(",")})`;
                }
                return <Box sx={{ minWidth: (of.multiple || ["select", "radio", "checkbox"].indexOf(of.type) >= 0) ? "200px" : "160px" }}>{label}</Box>;
            });
        return [...base, ...otherHeader];
    };

    const getItemRow = (i) => {
        let item = items[i];

        let base = [
            <GenericSingleRangeComponent
                startRange={item.count || null}
                endRange={Number(remainingItems || 0) + Number(item?.count || 0)}
                getOnChange={(countValue) => handleCountChange(countValue, i)}
                endRangeInfinity={false}
                valueType={"decimal"}
                disabled={bundleType === "uniqueItems"}
            />,
            <GetInputFieldForTableRowComponent
                component={{ ...(allFields.find((f) => f._id == "USN_Value") || {}), disabled: isScannedOn, keyPressEnter: onKeyPressEnterTrigger } || {}}
                items={items}
                setItems={setItems}
                index={i}
                handleChange={handleGenericChange}
                handleFocus={autoFillOnFocus}
                errors={errors}
                showLabelForMulti={false}
                inputProps={null}
                disabled={isScannedOn}
                customStyle={{ width: "40vw" }}
            />
        ];
        const others = allFields
            ?.filter((f) => filteredTableHeaders.indexOf(f._id) < 0)
            .map((of) => {
                let comp = { ...of };
                // debugger;
                if (["SKU_Name", "SKU_Number"].includes(comp._id)) {
                    comp.disabled = true;
                }
                else {
                    if (item?.disabled?.length > 0 && item?.disabled?.includes(of._id)) {
                        comp.disabled = true
                    }
                    else {
                        comp.disabled = false;
                    }
                }
                return (
                    <GetInputFieldForTableRowComponent
                        component={{ ...(comp || {}), keyPressEnter: onKeyPressEnterTrigger } || {}}
                        items={items}
                        setItems={setItems}
                        index={i}
                        handleChange={handleGenericChange}
                        handleFocus={autoFillOnFocus}
                        errors={errors}
                        showLabelForMulti={false}
                        inputProps={null}
                        disabled={comp.disabled == true}
                    />
                );
            });

        return [...base, ...others];
    };

    const header = getTableHeader();
    const rows = items?.map((item, i) => {
        return getItemRow(i);
    });

    const handleRemoveRow = (i) => {
        let oldItems = [...items];
        oldItems.splice(i, 1);
        setItems(oldItems);
        setErrors2([])
    };

    const handleCountChange = (countValue, i) => {
        let oldItems = [...items];
        let oldObject = { ...(oldItems[i] || {}) }
        if (countValue <= 0) {
            oldObject.count = 0
        }
        else {
            let maxCap = truncateNumber(totalQuantity - (countTillNow - Number(oldObject.count)), 3, true,);
            oldObject.count = Math.min(maxCap, countValue)
        }
        let formulaFields = allFields?.find(dl => dl?._id == "count")?.formulaFields || []
        if (formulaFields?.length > 0) {
            calculateFormulaHelper(oldObject || {}, formulaFields, oldObject, true)
        }
        oldItems[i] = oldObject
        setItems(oldItems);
    }

    const handleManualAddScannedItem = () => {
        if(usnValueInput.trim() !== ""){
            if (items?.some(dl => dl?.USN_Value == usnValueInput)) {
                showToast(`${usnValueInput} already exists`, true);
                setTotalItemsLoader(false);
                setUsnValueInput("");
                if (usnTypedRef.current) {
                    usnTypedRef.current.focus();
                }
                return;
            }
            // Refocus the input field after the new item is added
            setItems(prevItems => [{
                count: 1, USN_Value: usnValueInput, SKU_Name: usnValueInput, SKU_Number: usnValueInput, UOM: defaultUOMValue || "",
                mappingArea: mappingLocations?.find(dl => dl?.isDefault)?.value || mappingLocations?.at(0)?.value || process.env.REACT_APP_DEFAULT_AREA_NAME
            }, ...prevItems]);
            setUsnValueInput("");
            if (usnTypedRef.current) {
                usnTypedRef.current.focus();
            }
        }
    }

    const debounce = (func) => {
        return function (...args) {
            const context = this;
            const description = args[0]; // Get the current scanned value

            // Get the current timestamp to check scan intervals
            const currentTime = Date.now();

            // Avoid processing if the same QR code was scanned too quickly (e.g. within 500ms)
            if (lastScannedRef.current === description && currentTime - lastScanTimeRef.current < 500) {
                return; // Skip processing if same QR code scanned quickly
            }

            // Update the last scanned value and timestamp
            lastScannedRef.current = description;
            lastScanTimeRef.current = currentTime;

            // Clear the previous debounce timer
            if (timerRef.current) clearTimeout(timerRef.current);

            // Update scanned input immediately (for UI update)
            setScannedInput(description);

            // Set a debounce timeout to process after 1 second (or any suitable delay)
            timerRef.current = setTimeout(() => {
                timerRef.current = null;
                func.apply(context, args);
            }, 100); // 100 mili second debounce
        };
    };

   

    const scanHandler = (description, items) => {
        setTotalItemsLoader(true); // Start loader immediately when input starts changing
        if (description.trim() === "") {
            setTotalItemsLoader(false);
            return;
        }

        const tempScannedQR=getSmallestRepeatingSubstring(description,7)
        if (items?.some(dl => dl?.USN_Value == tempScannedQR)) {
            showToast(`${tempScannedQR} already exists`, true);
            setTotalItemsLoader(false);
            setScannedInput("");
            if (scannedRef.current) {
                scannedRef.current.focus();
            }
            return;
        }
        setItems(prevItems => [{
            count: 1, USN_Value: tempScannedQR, SKU_Name: tempScannedQR, SKU_Number: tempScannedQR, UOM: defaultUOMValue || "",
            mappingArea: mappingLocations?.find(dl => dl?.isDefault)?.value || mappingLocations?.at(0)?.value || process.env.REACT_APP_DEFAULT_AREA_NAME
        }, ...prevItems]);
        setScannedInput("");
        setTotalItemsLoader(false); // Stop loader after items are updated
        // Refocus the input field after the new item is added
        if (scannedRef.current) {
            scannedRef.current.focus();
        }
    }

    // Use the debounce function to process the scan with delay
    const debouncedScan = debounce(scanHandler);

    useDebounceWithUseEffectDetection(() => {
        if (!isScannedOn) {
            if (totalQuantity > 0) {
                setTotalItemsLoader(true); // Start loader before processing

                if (bundleType === "uniqueItems") {
                    const countPerBox = 1;
                    const boxCount = totalQuantity;
                    let newItems = [];

                    const CHUNK_SIZE = 500; // Number of boxes processed per chunk
                    let processedBoxes = 0;

                    const processChunk = () => {
                        const remainingBoxes = boxCount - processedBoxes;
                        const boxesToProcess = Math.min(CHUNK_SIZE, remainingBoxes);

                        for (let i = 0; i < boxesToProcess; i++) {
                            const isLastBox = processedBoxes + i === boxCount - 1;
                            let itemCount = isLastBox && countPerBox * boxCount !== totalQuantity
                                ? totalQuantity - (countPerBox * (boxCount - 1))
                                : countPerBox;
                            itemCount = truncateNumber(itemCount);
                            newItems.push({
                                count: itemCount,
                                UOM: defaultUOMValue || "",
                                mappingArea: mappingLocations?.find(dl => dl?.isDefault)?.value || mappingLocations?.at(0)?.value || process.env.REACT_APP_DEFAULT_AREA_NAME
                            });
                        }

                        processedBoxes += boxesToProcess;

                        if (processedBoxes < boxCount) {
                            setTimeout(processChunk, 0); // Process next chunk
                        } else {
                            // Ensure loader is visible for at least one frame
                            setTimeout(() => {
                                setItems(newItems);
                                setTotalItemsLoader(false); // Stop loader after all chunks are processed
                            }, 100); // Delay ensures the UI updates
                        }
                    };

                    setTimeout(processChunk, 50); // Ensures UI renders loader before chunking starts
                } else {
                    setTimeout(() => {
                        setItems([{ count: totalQuantity }]);
                        setTotalItemsLoader(false); // Stop loader for non-chunk cases
                    }, 100);
                }
            } else {
                setItems([]);
                setTotalItemsLoader(false); // Stop loader if no items
            }
        }
    }, [totalQuantity], 300);

    useEffect(() => {
        if (isScannedOn && scannedRef.current) {
            scannedRef.current.focus();
        }
    }, [isScannedOn])

    useEffect(() => {
        const refToFocus = isAutoScanning ? scannedRef.current : usnTypedRef?.current;
        refToFocus?.focus();
    }, [isAutoScanning]);

    // Handle scanned input changes
    useEffect(() => {
        if (scannedInput.trim() !== "" && isAutoScanning) {
            debouncedScan(scannedInput, items);
        }
    }, [scannedInput]);

    return (
        <Paper elevation={1}>
            <Grid container item sm={12} xs={12} direction="row" sx={{ backgroundColor: theme.themeGray, p: "10px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}>

                {/* Left Container (sm=8) */}
                <Box component={Grid} item sm={9} xs={12} display="flex" alignItems="center">
                    {/* Inner Box for Left Section */}
                    <Box display="flex" alignItems="center" width="100%">

                        {/* Item Details */}
                        <Typography sx={{ ...textClasses.boldText, mr: 2 }}>Item Details</Typography>


                        {/* Toggle Switch */}
                        {
                           showToggleScanManual?.isActive && showToggleScanManual?.isShowToggleButton &&
                            <GenericToggle
                                isEnable={isScannedOn}
                                enabledName="'Data Input By Scan'"
                                disbledName="'Data Input By Manual'"
                                name="toggle"
                                handleToggle={(event) => {
                                    setIsScannedOn(event.target.checked);
                                    setItems([]);
                                    setScannedInput("");
                                    if(event.target.checked){
                                        setIsAutoScanning(true)
                                    }
                                    if (packageDetailsFields?.length > 0) {
                                        packageDetailsFields.forEach((field) => {
                                            if(field?._id !== "packageName"){
                                                setValue1(field?._id, "");
                                            }
                                        });
                                    }
                                    if (!event.target.checked) {
                                        setValue1("count", "");
                                    }
                                }}
                                width={150}
                            />
                        }

                        
                        {/* Scanning Manual or Auto Switch */}
                        {
                            isScannedOn &&
                            <Box ml={2}>
                                <GenericToggle
                                    isEnable={isAutoScanning}
                                    enabledName="'Auto'"
                                    disbledName="'Manual'"
                                    name="toggle"
                                    handleToggle={(event) => {
                                        setIsAutoScanning(event.target.checked);
                                        setErrors2([])
                                        setScannedInput("");
                                        setUsnValueInput("");

                                        // if (packageDetailsFields?.length > 0) {
                                        //     packageDetailsFields.forEach((field) => {
                                        //         if (field?._id !== "packageName") {
                                        //             setValue1(field?._id, "");
                                        //         }
                                        //     });
                                        // }
                                        if (!event.target.checked) {
                                            setValue1("count", "");
                                        }
                                    }}
                                    width={80}
                                />
                            </Box>
                        }

                        {/* Scanned Input Field */}
                        {isScannedOn && isAutoScanning && (
                            <Box ml={2}>
                                <TextField
                                    data-testid="input-email"
                                    inputRef={scannedRef}
                                    sx={{
                                        ...inputClasses.filterField,
                                        ...selectStylesOverride,
                                        ".MuiInputBase-input": { fontSize: "15px", color: "#455A64" },
                                        width: "284px",
                                        ".MuiOutlinedInput-root": { backgroundColor: theme.themeWhite },
                                    }}
                                    size="small"
                                    label="Scanned Input"
                                    variant="outlined"
                                    disabled={totalItemsLoader}
                                    onChange={(e) => setScannedInput(e.target.value)}
                                    value={scannedInput}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end">
                                                    <KeyboardDoubleArrowRightIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                        )}

                        {isScannedOn && !isAutoScanning && (
                            <Box ml={2}>
                                <TextField
                                    data-testid="input-email"
                                    inputRef={usnTypedRef}
                                    sx={{
                                        ...inputClasses.filterField,
                                        ...selectStylesOverride,
                                        ".MuiInputBase-input": { fontSize: "15px", color: "#455A64" },
                                        width: "284px",
                                        ".MuiOutlinedInput-root": { backgroundColor: theme.themeWhite },
                                    }}
                                    size="small"
                                    label="Enter Value"
                                    variant="outlined"
                                    // disabled={totalItemsLoader}
                                    onChange={(e) => setUsnValueInput(e.target.value)}
                                    value={usnValueInput}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && !isAutoScanning && isScannedOn) {
                                            handleManualAddScannedItem();
                                        }
                                    }}
                                    // InputProps={{
                                    //     endAdornment: (
                                    //         <InputAdornment position="end">
                                    //             <IconButton edge="end">
                                    //                 <KeyboardDoubleArrowRightIcon />
                                    //             </IconButton>
                                    //         </InputAdornment>
                                    //     ),
                                    // }}
                                />
                            </Box>
                        )}

                        {/* Manual Scanning Button */}
                        {!isAutoScanning && isScannedOn &&
                            <Button
                                variant="outlined"
                                data-testid="cancelButton"
                                disabled={!isAutoScanning && !usnValueInput}
                                sx={{
                                    ...buttonClasses.lynkitBlackFill, minHeight: "30px",
                                    backgroundColor: theme.themeBlack, ml: 0.5,
                                    // "&:disabled": { cursor: "not-allowed !important", backgroundColor: "rgba(0, 0, 0, 0.12)", color: "rgba(0, 0, 0, 0.26)", border: "none" }
                                }}
                                onClick={handleManualAddScannedItem}
                            >
                                Add
                            </Button>
                        }
                    </Box>
                </Box>

                {/* Right Container (sm=4) */}
                <Box component={Grid} item sm={3} xs={12} display="flex" alignItems="center" justifyContent="flex-end">
                    {isScannedOn && (
                        <Typography
                            sx={{
                                ...textClasses.boldText,
                                fontSize: "18px",
                            }}
                        >
                            Scanned Item(s): {truncateNumber(Number(countTillNow || 0))}
                        </Typography>
                    )}
                </Box>

            </Grid>
            <Grid container sx={{ width: 'inherit', padding: "12px", overflow: "auto", maxHeight: '60vh' }}>
                <Grid container>
                    {totalItemsLoader ?
                        <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15, pt: 10, pb: 10 }}>
                            <Grid item><GenericLoader /></Grid>
                        </Grid> :
                        <Table2
                            header={header}
                            rows={rows}
                            remainingItems={remainingItems}
                            handleRemoveRow={isScannedOn && handleRemoveRow}
                            isPalletEntry={isScannedOn && true}
                        />}
                </Grid>

                <Typography
                    sx={{
                        ...textClasses.boldText,
                        color: theme.themeOrange,
                        mt: 2,
                        ml: 2,
                    }}
                >
                    Total Order Quantity: {truncateNumber(Number(countTillNow || 0) || 0)}
                </Typography>
            </Grid>
        </Paper>
    );
};

export default ItemSection;