import React, { useState, useEffect } from "react";
import {
    Autocomplete,
    Box,
    Breadcrumbs,
    Button,
    Tab,
    Tabs,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    Divider,
    Grid,
    IconButton,
    Avatar,
    MenuItem,
    Paper,
    Select,
    Tooltip,
    Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import theme, {
    textClasses,
    cardClasses,
    inputClasses,
    buttonClasses,
} from "../../../../static/styles/theme";
import { getDraggableWidgets } from "../../../../redux/actions/CustomDashboard";
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import StandardWidgets from "../CreateDashboard/StandardWidgets";
import InputField from "../../../Components/TemplateInputConfiguration/InputComponent/InputField";
import DropWidgetsArea from "./DropWidgetsArea";
import { useDispatch, useSelector } from 'react-redux'
import history from "../../../../services/history";
import { showToast } from "../../../../services/functions";
import PublishCustomDashboardModal from "./PublishCustomDashboardModal";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

// const widgets?.standardWidgets = [
//     {
//         "_id": "Alert",
//         "elements": [
//         {
//             id: "welcomeNote",
//             label: "User Welcome Note",
//             icon:"sticky_note_2",
//             type: "alert",
//             title: "Welcome Note",
//             description: "This widget shows a welcome note to the user.",
//             footer: "Last updated: Today",
//             minW: 4,
//             maxW: 6,
//             minH: 2,
//             maxH: 6,
//         },
//         {
//             id: "scheduledEvents",
//             label: "Scheduled Events",
//             icon:"event_available",
//             type: "alert",
//             title: "Scheduled Events",
//             description: "Displays upcoming events scheduled for the user.",
//             footer: "Next event: Tomorrow",
//             minW: 5,
//             maxW: 6,
//             minH: 2,
//             maxH: 6,
//         },
//     ]
//     },
//     {
//         "_id": "Count",
//         "elements": [
//         {
//             id: "inventoryCount",
//             label: "Inventory Count",
//             icon:"inventory_2",
//             type: "count",
//             title: "Inventory Count",
//             description: "Shows the current inventory count.",
//             footer: "Inventory refreshed: 2 hours ago",
//             minW: 5,
//             maxW: 6,
//             minH: 2,
//             maxH: 6,
//             detail: [
//                 {label:'In Stock Units',value:1312},
//                 {label:'To Be Received',value:1515},
//                 {label:'Gate In Count',value:213},
//                 {label:'Gate Out Count',value:788},
//             ]
//         },
//         {
//             id: "operations",
//             label: "Operations",
//             icon:"work_history",
//             type: "count",
//             title: "Operations",
//             description: "Shows the current operations count.",
//             footer: "Inventory refreshed: 2 hours ago",
//             minW: 4,
//             maxW: 6,
//             minH: 2,
//             maxH: 6,
//             detail: [
//                 {label:'Total Inward',value:1312},
//                 {label:'Total Outward',value:1515},
//             ]
//         },
//         {
//             id: "myWarehouse",
//             label: "My Warehouse",
//             icon: "warehouse",
//             type: "count",
//             title: "My Warehouse",
//             description: "Shows the current my warehouse data",
//             footer: "Inventory refreshed: 2 hours ago",
//             minW: 5,
//             maxW: 6,
//             minH: 2,
//             maxH: 6,
//             detail: [
//                 {label:'All Active',value:1312},
//                 {label:'All Blocked',value:1515},
//             ]
//         },
//         {
//             id: "inventoryWeight",
//             label: "Inventory Weight",
//             icon:"monitor_weight",
//             type: "count",
//             title: "Inventory Weight",
//             description: "Shows the current inventory weights.",
//             footer: "Inventory refreshed: 2 hours ago",
//             minW: 5,
//             maxW: 6,
//             minH: 2,
//             maxH: 6,
//             detail: [
//                 {label:'In Stock Weight',value:1312},
//                 {label:'To Be Received Weight',value:1515},
//                 {label:'Gate Out Weight',value:788.31},
//                 {label:'Gate In Weight',value:213.12},
//             ]
//         },
//     ]
//     },
//     {
//         "_id": "Analytics",
//         "elements": [
//         {
//             id: "areaUtilization",
//             label: "Area Utilization Pi Chart",
//             icon: "pie_chart",
//             type: "analytics",
//             title: "Area Utilization",
//             description: "Displays a pie chart of area utilization metrics.",
//             footer: "Data updated: Last week",
//             minW: 5,
//             maxW: 12,
//             minH: 2,
//             maxH: 12,
//         },
//         {
//             id: "barCalculation",
//             label: "Bar Calculation Chart",
//             icon: "bar_chart_4_bars",
//             type: "analytics",
//             title: "Bar Calculation Chart",
//             description: "Displays a pie chart of area utilization metrics.",
//             footer: "Data updated: Last week",
//             minW: 5,
//             maxW: 12,
//             minH: 2,
//             maxH: 12,
//         },
//     ]
//     }
// ];

const CreateCustomDashboard = () => {
    const dispatch = useDispatch();

    const [sectionStep, setSectionStep] = useState(1);
    const [tabIndex, setTabIndex] = useState(1);
    const [layout, setLayout] = useState([]);
    const [viewModal,setViewModal] = useState({type:'',action:{}})
    const [widgets, setWidgets] = useState({
        standardWidgets: [],
        analyticsWidgets: []
    })
    
    useEffect(() => {
         dispatch(getDraggableWidgets({},(res)=>{
            setWidgets({...widgets,standardWidgets:res?.data?.standardWidgets || [],analyticsWidgets:res?.data?.analyticsWidgets || []})
         }));
     }, [])
    const getTabStyles = (tIndex) => {
        return {
            backgroundColor: tabIndex == tIndex ? theme.themeOrange : "white",
            color: tabIndex == tIndex ? "white" : theme.themeOrange,
            border: "1px solid",
            boxShadow: "0px 2px 6px gray",
            borderColor: theme.themeOrange,
            p: 0,
            pt: "10px",
            pb: "10px",
            fontSize: "14px",
            lineHeight: "19px",
            fontWeight: "400",
            textTransform: "none",
        }
    }


    const getSectionStep = () => {
        if (sectionStep == 1) {
            // const dotStyle = {
            //     width: "5px",
            //     height: "5px",
            //     borderRadius: "50%",
            //     backgroundColor: theme.themeOrange,
            //     display: "inline-block",
            //     margin: "8px",
            // };

            // const gridContainerStyle = {
            //     display: "grid",
            //     gridTemplateColumns: "repeat(12, 1fr)", // 12 dots per row
            //     gap: "16px", // Space between dots
            //     justifyContent: "center",
            //     padding: "16px",
            //     height: "100%", // Ensure it spans to the end of the page
            //     overflowY: "auto",
            // };

            return (
                <DropWidgetsArea layout={layout} setLayout={setLayout}/>
                // <div style={gridContainerStyle}>
                //     {Array.from({ length: 120 }).map((_, index) => (
                //         <div key={index} style={dotStyle}></div>
                //     ))}
                // </div>
            );
        }

        // else if (sectionStep == 2) {
        //     return <ComponentCanvas
        //         components={components}
        //         setComponents={null}
        //         selectedSubTemplate={selectedSubTemplate}
        //         handleSaveTemplate={handleSaveTemplate}
        //         handleTemplateChange={handleTemplateChange}
        //     />
        // }
        return <div>Coming Soon..</div>
    }


    const handleTabChange = (event, value) => {
        ////("Changing " + value);
        // setTabIndex(value)  // we dont want to have click on tab to change the tab index
    }

    
      const handleClear = () => {
      setLayout([]);
      };
    
    const handleSaveWidgets = () =>{

        setViewModal({...viewModal,type:"publishCustomDashboardModal",action:{layout:layout || []}})
    // let payload={
    //     // name:fieldValues?.dashboardName || "",
    //     attributes:layout || []
    // }
    // isDataReceived(true)
    // dispatch(
    //     saveCustomDashboardWidgets(payload, (response) => {
    //         showToast(response.message, !response.success);
    //         isDataReceived(false)
    //         // isDataReceived(false)
    //     //   setApiCalled(false)
    //         if (response?.success) {
    //             history.push("/dashboard/custom");
    //         }
    //     })
    // )
    }
    return (
        <>
            <>
                <DndProvider backend={HTML5Backend}>
                    <Typography sx={{ ...textClasses.cardTitle }}>Create Custom Dashboard</Typography>
                    <Breadcrumbs sx={{ mb: 1 }}>
                        <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }}>
                            <Link style={{ color: theme.themeOrange, textDecoration: "none" }} to="/dashboard/view-custom-dashboard">
                                My Dashboard
                            </Link>
                        </Typography>
                        <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>Create Dashboard</Typography>
                    </Breadcrumbs>
                    <Grid container spacing={2}>
                        <Grid item sm={8} xs={10}>
                            <Card sx={{ borderRadius: "8px" }}>
                                <CardHeader
                                    sx={
                                        {
                                            // pt: 1,
                                            pl: 2,
                                            pb: 1,
                                            borderRadius: "8px 8px 0px 0px",
                                            backgroundColor: theme.themeOrange,
                                            color: "white",
                                            boxShadow: "0px 2px 6px gray"
                                        }
                                    }
                                    // action={
                                    //     // sectionStep > 1 && 
                                    //     <InputField
                                    //         component={dashboardNameComponent}
                                    //         componentValues={fieldValues} // Pass the shared state
                                    //         disabled={false} // Field remains enabled
                                    //         handleChange={handleChangeFieldCondtionalValue}
                                    //         errors={{}} // Pass errors if required
                                    //         extraCSS={{
                                    //             backgroundColor: theme.themeWhite, ".MuiInputBase-input": {
                                    //                 fontSize: "15px",
                                    //                 color: "#455A64",
                                    //             },
                                    //             "& label.Mui-focused": {
                                    //                 color: "#000",
                                    //             }
                                    //         }}
                                    //     />
                                    // }
                                    title={
                                        <Typography variant='h6'>Page View - Drop Section</Typography>
                                    }
                                />
                                <CardContent sx={{ zIndex: 100, overflowY: "auto", maxHeight: '78vh', borderRadius: "8px" }}>
                                    <Grid container sx={{ mb: 1 }}>
                                        <Grid container item sm={2} direction="column" alignItems="center" justifyContent={"center"}>
                                            <Avatar sx={{ width: 32, height: 32, backgroundColor: sectionStep == 1 ? theme.themeOrange : null }}>1</Avatar>
                                            <Typography variant='caption' sx={{ mt: 1, fontSize: "12px" }}> choose widgets</Typography>
                                        </Grid>
                                        <Grid item sm={8} justifyContent="center" direction={"row"}>
                                            <Divider sx={{ mt: 2, borderBottomWidth: "3px", backgroundColor: sectionStep == 1 ? theme.themeOrange : null }} />


                                        </Grid>
                                        <Grid container item sm={2} direction="column" alignItems="center" justifyContent={"center"}>
                                            <Avatar sx={{ width: 32, height: 32, backgroundColor: sectionStep == 2 ? theme.themeOrange : null }}>2</Avatar>
                                            <Typography variant='caption' sx={{ mt: 1, fontSize: "12px" }}>preview & publish</Typography>
                                        </Grid>
                                        {/* <Grid item sm={3}>
                                        <Divider sx={{mt: 2, borderBottomWidth:"3px" , backgroundColor: sectionStep==2? theme.themeOrange : null}}/>
                                    </Grid>
                                    <Grid container item sm={2} direction="column" alignItems="center" justifyContent={"center"}>
                                        <Avatar sx={{width: 32, height: 32, backgroundColor: sectionStep == 3 ? theme.themeOrange : null}}>3</Avatar>
                                        <Typography variant='caption' sx={{mt: 1, fontSize: "12px"}}>preview & submit form</Typography>
                                    </Grid> */}
                                    </Grid>

                                    {
                                        getSectionStep()
                                    }
                                    <Grid container sx={{ mt: 2 }}>
                                        {
                                            // !template &&
                                            <Button variant='outlined' size='small' sx={{ ml: 1, ...buttonClasses.lynkitBlackEmpty, minWidth: "180px", }}
                                            onClick={handleClear}
                                            //  disabled={isCanvasDefault}
                                            >
                                                Clear All
                                            </Button>

                                        }

                                        {/* <Button variant='outlined' size='small' sx={{ ml: 1, ...buttonClasses.lynkitOrangeEmpty, minWidth: "180px" }}
                                        // onClick={() => handleOpenModal("drafted",template)} disabled={isCanvasEmpty}
                                        disabled={ !layout?.length}

                                        >
                                            Preview
                                        </Button> */}
                                        <Button variant='contained' size='small' sx={{ ml: 1, ...buttonClasses.lynkitOrangeFill, minWidth: "180px", }}
                                        onClick={handleSaveWidgets}
                                        disabled={ !layout?.length }
                                        // onClick={() => handleOpenModal("published",template)} disabled={isCanvasEmpty}
                                        >
                                            Publish
                                        </Button>
                                    </Grid>

                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item sm={4} xs={2}>
                            <Paper sx={{ maxHeight: '78vh' }}>
                                <Tabs value={tabIndex} aria-label="disabled tabs example" variant='fullWidth' onChange={handleTabChange} sx={{ '& .MuiTabs-indicator': { display: 'none' }, "& .MuiTab-root.Mui-selected": { color: '#FFFFFF' } }}>
                                    {/* {
                                    previousExists &&
                                    <Tab disabled={!previousExists} label="Previously Used Components"  {...a11yProps(0)} sx={{...getTabStyles(0), backgroundColor: previousExists ? (tabIndex == 0 ? theme.themeOrange: "white") : "#D7D7D7", marginRight: "1px", borderTopLeftRadius: "8px"}} />
                                } */}
                                    <Tab label="Standard Form Components" {...a11yProps(1)} sx={{ ...getTabStyles(1), borderTopRightRadius: "8px" }} />
                                </Tabs>
                                {/* {
                                previousExists &&
                                <TabPanel value={tabIndex} index={0} style={{backgroundColor: "#EEEEEE"}} >
                                    <PreviousUsedComponents usedComponents={usedComponents} />
                                </TabPanel>
                            }
                             */}
                                <TabPanel value={tabIndex} index={1} style={{ backgroundColor: "#EEEEEE" }}>
                                    <StandardWidgets stdWidgets={widgets?.standardWidgets}
                                        atcsWidgets={widgets?.analyticsWidgets}
                                    />
                                </TabPanel>
                            </Paper>
                        </Grid>
                    </Grid>
                </DndProvider>
                {viewModal?.type=="publishCustomDashboardModal" &&
                <PublishCustomDashboardModal 
                viewModal={viewModal}
                setViewModal={setViewModal}
                />
                }
            </>

        </>
    )
}

export default CreateCustomDashboard;





