export const initialFieldValues = {
    dashboardName: "", 
    dashboardDescription: "",
    dashboardVisibilityScope: null,
  }

export const dashboardNameComponent = {
    _id: "dashboardName",
    label: "Dashboard Name",
    required: true,
    placeholder: "Type Dashboard Name...",
}

export const dashboardDescriptionComponent ={
    _id: "dashboardDescription",
    label: "Dashboard Description",
    required: false,
    placeholder: "Write Dashboard Description...",
}

export const dashboardVisibilityComponent ={
    _id: "dashboardVisibilityScope",
    label: "Dashboard Visibility Scope",
    required: true,
    placeholder: "Dashboard Visibility Scope...",
    type:"radio",
    values:[
        {label:"Public",value:"public"},
        {label:"Private",value:"private"},
    ]
}