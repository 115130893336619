import { cardClasses,Box, CardHeader, Container, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, Typography } from '@mui/material';
import React, { useEffect, useCallback, useState, useMemo,Suspense } from 'react'
import theme, { textClasses } from '../../../../../static/styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { fetchManageControlSettings } from '../../../../../redux/actions';
import MaterialImage from "../../../../../static/images/data_entry/materialControls.svg"

// import AsnCreationControls from './AsnCreationControls';
// import AsnReceivingControls from './AsnReceivingControls';
// import AutoJobCreation from './AutoJobCreation';
// import AutoJobCompletion from './AutoJobCompletion';
// import GenericLoader from '../../../../Components/Generic/GenericLoader';
// import InventoryUpdateJob from './InventoryUpdateJob';
// import SalesOrderStockAllocation from './SalesOrderStockAllocation';
const GenericLoader = React.lazy(() => import("../../../../Components/Generic/GenericLoader"));
const AsnCreationControls = React.lazy(() => import("./AsnCreationControls"));
const AsnReceivingControls = React.lazy(() => import("./AsnReceivingControls"));
const AutoJobCreation = React.lazy(() => import("./AutoJobCreation"));
const AutoJobCompletion = React.lazy(() => import("./AutoJobCompletion"));
const InventoryUpdateJob = React.lazy(() => import("./InventoryUpdateJob"));
const SalesOrderStockAllocation = React.lazy(() => import("./SalesOrderStockAllocation"));






const Index = () => {
    const dispatch = useDispatch();
    const manageControls = useSelector(state => state?.inventory?.manageControlSetting || []);
    const [isLoader, setLoader] = useState(true)
    useEffect(() => {
        setLoader(true);
        dispatch(
            fetchManageControlSettings({}, () => {
                setLoader(false)
            })
        )
    }, [])

    return (<Suspense fallback={<Box>Loading...</Box>}>
        <Typography
            sx={{ ...textClasses.cardTitle }}>Material Movements Control
        </Typography>
        <Typography
            sx={{ ...textClasses.normalText }}>Define how you want to process your inventories inside the warehouse.
        </Typography>
        {
            !isLoader ? (
                <Grid container  spacing={2} sx={{ mt: 1 }} direction="row" alignItems={"stretch"}>
                    <Grid item sm={9} xs={10}>
                        <Grid
                            item
                            sx={{
                                borderRadius: "8px",
                                border: `1px solid ${theme.themeOrange}`,
                                overflowY:'auto',
                                maxHeight:'80vh'
                            }}
                        >
                            {Array.isArray(manageControls) && manageControls.length > 0 ? (

                                manageControls.map((item) => (
                                    <>
                                        {item.configType === "asnCreation" && item?.configDetail?.some(elem => !elem.isAdmin) &&(
                                            <AsnCreationControls data-testId='asnCreation' item={item} ></AsnCreationControls>
                                        )}

                                        {item.configType === "asnReceiving" && item?.configDetail?.some(elem => !elem.isAdmin) &&(
                                            <AsnReceivingControls data-testId='asnReceiving' item={item}></AsnReceivingControls>
                                        )}
                                        {item.configType === "palletDataEntry" && item?.configDetail?.some(elem => !elem.isAdmin) &&(
                                            <AsnReceivingControls data-testId='palletDataEntry' item={item}></AsnReceivingControls>
                                        )}

                                        {item.configType === 'autoJobCreation' && item?.configDetail?.some(elem => !elem.isAdmin) &&(
                                            <AutoJobCreation data-testId='autoJobCreation' item={item}></AutoJobCreation>
                                        )}

                                        {item.configType === 'autoJobCompletion' && item?.configDetail?.some(elem => !elem.isAdmin) && (
                                                <AutoJobCompletion data-testId='autoJobCompletion' item={item}></AutoJobCompletion>
                                        )}
                                        {item.configType === 'inventoryUpdateWithJob' && item?.configDetail?.some(elem => !elem.isAdmin) &&(
                                                <InventoryUpdateJob data-testId='inventoryUpdateWithJob' item={item}></InventoryUpdateJob>
                                        )}

                                        {item.configType == "salesOrderStockAllocation" && item?.configDetail?.some(elem => !elem.isAdmin) &&(
                                                <SalesOrderStockAllocation ata-testId='salesOrderStockAllocation' item={item}></SalesOrderStockAllocation>
                                        )}
                                    </>
                                ))
                            ) : null}
                        </Grid>
                    </Grid>
                    <Grid item sm={3} xs={2}>
                        <Paper sx={{ ...cardClasses.basic, height:"80vh", border: `1px solid ${theme.themeOrange}`, borderRadius: "8px", backgroundColor: theme.themeLightOrange, p: 2, pb: 8,overflow:"auto" }}>
                            <Grid container sx={{ mb: 18 }} >
                                <Grid container item justifyContent={"center"} alignItems="center">
                                    <img src={MaterialImage} height="250" style={{ marginTop: "30px" }} />
                                    <Typography sx={{ fontSize: "30px", fontWeight: "700", lineHeight: "41px", color: theme.themeOrange, textAlign: "center", mt: 5 }}>
                                        Get Control Over Inventory Moves !
                                    </Typography>
                                    <Typography sx={{ fontSize: "16px", fontWeight: "600", lineHeight: "22px", color: theme.themeOrange, textAlign: "center", mt: 4 }}>
                                        Efficient picking and receiving methods are essential to streamline warehouse operations, reduce errors, and optimize space and productivity.Without proper control, stock movements can lead to delays, inaccurate placements, and wastage, especially when handling FIFO, LIFO, or FEFO rules.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            ) : <Grid data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                <Grid item><GenericLoader /></Grid>
            </Grid>
        }

    </Suspense>)
}


export default Index;