import React, { useEffect } from "react";
import { useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  Autocomplete,
} from "@mui/material";
import {
  Add,
  Cancel,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  approveRejectInhousePalletStatus,
  getCurrentKitCount,
  getJobProcessConfig,
  getInHouseCheckPickingReadyPallet,
  approveRejectInhouseDePalletStatus
} from "../../../../redux/actions";
import theme, {
  buttonClasses,
  inputClasses,
  textClasses,
} from "../../../../static/styles/theme"
import {
  showToast,
} from "../../../../services/functions";
// import GenericSingleRangeComponent from "../../../Components/Generic/GenericSingleRangeComponent";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import { removeUntilE } from "../../../../utils";

const ApproveRejectConfirmationDialog = ({
  status,
  handleClose,
  selectedEntries,
  setSelectedEntries,
  refreshTableData,
  handleRemoveSelection,
  cardType,
}) => {
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState(false);
  const [dataReceived, isDataReceived] = useState(false)
  const [approvalStep, setApprovalStep] = useState(1)
  const size = selectedEntries.length;
  const [reason, setReason] = useState("");
  const [loader, setLoader] = useState(false);
  const { AllUserData } = useSelector((state) => state.users);
  const [worker, setWorker] = useState("");
  const [maxPickingCount, setMaxPickingCount] = useState(0)
  const [pickingCount, setPickingCount] = useState(0);
  const [isWorkerMandatory, setIsWorkerMandatory] = useState(false)
  const [readyToPickPallet, setReadyToPickPallet] = useState([]);

  const callBack = (data) => {
    showToast(data.message, !data.success);
    isDataReceived(false);
    if (data.success) {
      refreshTableData();
      handleClose();
      if (setSelectedEntries) {
        setSelectedEntries([]);
      }
    }
  };

  const handleCheckPickingReadyPallet = () => {
    let payload = {
      Pallet_ID: selectedEntries.map((e) => e.Pallet_ID),
      status: status
    }
    dispatch(
      getInHouseCheckPickingReadyPallet(payload, (data) => {
        showToast(data.message, !data.success);
        if (data?.success) {
          setApprovalStep(2);
          if (data?.readyToPicking) {
            setReadyToPickPallet(data.readyToPicking);
          }
        }
      })
    );
  }
  const handleStatusChange = () => {
    isDataReceived(true)
    let payload = {}
    if (status == "rejected") {
      if (cardType == "palletization") {
        payload = {
          Pallet_ID: selectedEntries.map((e) => e.Pallet_ID),
          status: status,
          reason: reason,
        };
      }
      else {
        payload = {
          componentID: selectedEntries.map((e) => e._id),
          status: status,
          reason: reason,
        }
      }
    }
    else {    // status is approved
      if (cardType == "palletization") {
        payload = {
          Pallet_ID: selectedEntries.map((e) => e.Pallet_ID),
          status: status,
          // assignee_id: worker,
          // kitCount: pickingCount
        };
      } else {
        payload = {
          componentID:
            // selectedEntries.length == 1
            //   ? selectedEntries[0]?._id: 
            selectedEntries.map((e) => e._id),
          status: status,
        }
      }
    }
    if (cardType == "palletization") {
      dispatch(
        approveRejectInhousePalletStatus(payload, (data) => {
          if (data) {
            callBack(data);
          }
        })
      );

      // validate vaali
      dispatch(
        getInHouseCheckPickingReadyPallet(payload, (data) => {
          // if (data) {
          //   callBack(data);
          // }
        })
      );
    }
    else {
      dispatch(
        approveRejectInhouseDePalletStatus(payload, (data) => {
          if (data) {
            callBack(data);
          }
        })
      );
    }
  };

  const countChangeHandler = (count) => {
    setPickingCount(count)
  }


  useEffect(() => {
    if (cardType == "palletization" && status == "approved") { // if status is approved and cardType is palletization
      setLoader(true)
      setApprovalStep(1)
      setReadyToPickPallet([])
      dispatch(getJobProcessConfig({ projections: { requiredFields: 1 }, approved: true }, (data) => {
        setIsWorkerMandatory(data?.data?.requiredFields?.userRequired)
      }))
      dispatch(getCurrentKitCount({ KIT_ID: selectedEntries[0]?.KIT_ID }, (data = {}) => {
        setLoader(false)
        setMaxPickingCount(data?.count || 0)
        setPickingCount(data?.count || 0)
      }))
    }
    else {   // if status is rejected
      setReason("");
      setReadyToPickPallet([])
    }
  }, [status]);


  const workerOptions = [
    // { label: "--select--", value: "" },
    ...(AllUserData?.data || [])?.filter(dl => dl.userType == "worker")?.map((_) => {
      return {
        label: _.name,
        value: _._id,
      };
    }),
  ];

  const getDisableCondition = () => {
    if (dataReceived) return true;
    if (cardType == "palletization") {
      if (status == "approved") {
        if (!selectedEntries.every(entry =>
          readyToPickPallet.includes(entry.Pallet_ID)
        )) return true;        // if (!pickingCount || (isWorkerMandatory && !worker)) return true;
        // return false;
      }
      else {  // status is rejected
        if (!(reason || "").trim()) return true;
      }

    } else {   // op Type = Dekit
      if (status == "approved") {
        return false;
      }
      else {  // status is rejected
        if (!(reason || "").trim()) return true;
      }
    }
  }
  const handleWorkerSelect = (e, option) => {
    if (option) {
      // ////(option);
      const { value, label } = option; //e.target;
      setWorker(value);
    } else {
      setWorker("");
    }
  };
  return (
    <Dialog
      open={status != null}
      onClose={handleClose}
      scroll={"paper"}
      PaperProps={{ sx: { width: "100%" } }}
      maxWidth={"md"}
    >
      {loader ? (
        <Grid
          data-testid="loader"
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems="center"
          sx={{ p: 10 }}
        >
          <Grid item>
            <GenericLoader />
          </Grid>
        </Grid>
      ) : (
        <DialogContent>
          <Grid container justifyContent={"center"} alignItems="center">
            {status == "approved" ? cardType == "palletization" ? <><Typography sx={{ ...textClasses.cardTitle }}>
              Approve the Pallet preparation operation(s)
            </Typography>
              <Typography sx={{ ...textClasses.t15n, textAlign: 'center' }}>
                Approve the pallet Preparation and start with pallet component picking job. On pallet Approval A Component picking Job auto created.
              </Typography></> : <> <Typography sx={{ ...textClasses.cardTitle }}>
                Approve the de-pallet request operation(s)
              </Typography>
              <Typography sx={{ ...textClasses.t15n, textAlign: 'center' }}>
                Approve the de-pallet process and start with pallet component put-away process to add-on to stock.
              </Typography></>
              : <Typography sx={{ ...textClasses.cardTitle }}>
                Are you Sure ?
              </Typography>}
          </Grid>
          <Grid container justifyContent={"center"} alignItems="center">
            {status == "rejected" ? <Typography sx={{ ...textClasses.cardTitle }}>
              {cardType == "palletization" ? `You want to ${removeUntilE(status)} the PALLET` : `You want to ${removeUntilE(status)} the de-pallet Request(s).`}
            </Typography> : <></>}
          </Grid>
          <Grid container sx={{ mt: 1 }} spacing={1}>
            {selectedEntries.slice(0, showAll ? size : 5).map((st, i) => {
              return (
                <Grid item sm={4} xs={6} key={i}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent="center"
                    alignItems={"center"}
                    sx={{
                      backgroundColor: readyToPickPallet.includes(st?.Pallet_ID)
                        ? theme.themeLightGreen
                        : theme.themeLightOrange,
                      border: `1px solid ${theme.themeOrange}`,
                      borderRadius: "12px",
                      pl: 1,
                      pr: 1,
                      minHeight: "40px",
                    }}
                  >
                    <Grid item sm={10} xs={8}>
                      <Typography
                        sx={{
                          ...textClasses.normalText,
                          color: theme.themeOrange,
                          textAlign: "start",
                        }}
                      >{cardType == "palletization" ?
                        st?.Pallet_ID && st?.Pallet_ID?.length > 19
                          ? st?.Pallet_ID?.substring(0, 19) + "..."
                          : st?.Pallet_ID :
                        st?.packageId && st?.packageId?.length > 17
                          ? st?.packageId?.substring(0, 17) + "..."
                          : st?.packageId}
                      </Typography>
                    </Grid>
                    {handleRemoveSelection && (
                      <Grid item sm={2} xs={4}>
                        <IconButton
                          sx={{ alignContent: "right" }}
                          onClick={() => handleRemoveSelection(st._id)}
                        >
                          <Cancel sx={{ color: theme.themeOrange }} />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              );
            })}
            {size > 5 && (
              <>
                <Grid
                  container
                  item
                  sm={4}
                  xs={6}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      ...textClasses.boldText,
                      color: theme.themeOrange,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowAll(!showAll)}
                  >
                    {showAll ? <>Collapse</> : <>View All ({size})</>}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          {/* {
            cardType == "palletization" && status == "approved" ?
              <Grid item xs={6} sm={5} sx={{ mt: 2, mb: 2 }}>
                <Grid item sm={12}>
                  <Typography sx={{ ...textClasses.t15n, color: theme.themeOrange }}>
                    As per available components Stock, you can {maxPickingCount < 1 ? "not make any kit." : `initiate component picking for ${maxPickingCount} kits only.`}
                  </Typography>
                </Grid>
                {maxPickingCount > 0 ?
                  <Grid item sm={12} sx={{ display: 'inline-flex', alignItems: 'center', mt: 1 }}>
                    <Typography sx={{ ...textClasses.t15n, mr: 2 }}>
                      Enter No. of Kit(s), for which you want to start component picking :
                    </Typography>
                    <GenericSingleRangeComponent
                      data-testid="range-input"
                      startRange={pickingCount}
                      endRange={maxPickingCount}
                      getOnChange={(count) => countChangeHandler(count)}
                      valueType={"integer"}
                    />
                  </Grid> : ""}
              </Grid> : ""
          }
          {
            cardType == "palletization" && status == "approved" && maxPickingCount > 0 ?
              <Grid item xs={6} sm={5} >
                <Autocomplete
                  id="assign-user-label"
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`Assign User ${isWorkerMandatory ? '*' : '(optional)'}`}
                    //   sx={{
                    //     ...selectStylesOverride,
                    //   }}
                    />
                  )}
                  sx={{ mt: 2, ...inputClasses.filterField, width: '50%' }}
                  //   disabled={!jobType?.value}
                  //   data-testid="user-select"
                  //   sx={{ flexBasis: "50%", mb: 1 }}
                  options={workerOptions}
                  onChange={handleWorkerSelect}
                  value={
                    workerOptions.find((_) => _.value === worker)?.label ||
                    ""
                  }
                />
              </Grid> : ""
          } */}
          {
            status == "rejected" ?
              <Grid container direction={"row"} sx={{ mt: 1 }}>
                <Typography sx={{ ...textClasses.boldText }}>Reason*</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </Grid>
              :
              ""
          }

          {
            (status === "rejected" || (cardType == "dePalletization" && status === "approved") || (cardType == "palletization" && status === "approved" && approvalStep === 2)) &&
            // cardType == "palletization" && status == "approved" && approvalStep == 2 &&
            <Grid container sx={{ p: 1, mt: 3 }}>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  ...buttonClasses.lynkitBlackFill,
                  height: "40px",
                  minWidth: "150px",
                }}
                disabled={dataReceived}
                onClick={handleClose}
              >
                No, Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{
                  ml: 1,
                  ...buttonClasses.small,
                  backgroundColor: theme.themeOrange,
                  minWidth: "150px",
                }}
                disabled={getDisableCondition()}
                onClick={handleStatusChange}
              >
                Yes, {removeUntilE(status)}
              </Button>
            </Grid>
          }
          {
            cardType == "palletization" && status == "approved" && approvalStep == 1 &&
            <Grid container sx={{ p: 1, mt: 3 }}>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  ...buttonClasses.lynkitBlackFill,
                  height: "40px",
                  minWidth: "150px",
                }}
                disabled={dataReceived}
                onClick={handleClose}
              >
                No, Cancel
              </Button>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  ...buttonClasses.lynkitOrangeEmpty,
                  height: "40px",
                  minWidth: "150px",
                  ml: 1
                }}
                // disabled={getDisableCondition()}
                onClick={handleCheckPickingReadyPallet}
              >
                Validate
              </Button>
            </Grid>
          }
        </DialogContent>
      )}
    </Dialog>

  );
};

export default ApproveRejectConfirmationDialog;
