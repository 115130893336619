import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { RESET_STORE } from '../types/index';
import Auth from "./Auth";
import SuperAdmin from "./SuperAdmin";
import warehouseReducer from "./Warehouse";
import Common from "./Common";
import areaMappingReducer from "./AreaMapping";
import Templates from "./Templates";
import Inventory from "./Inventory";
import Canvas from "./Canvas";
import UI from "./UI"
import InwardEntry from "./InwardEntry";
import jobReducer from "./Job";
import RFIDReducer from "./RFID";
import mainDashboardReducer from "./MainDashboard";
import ManageTeamReducer from "./ManageTeam";
import UserRoleReducer from "./UserRole";
import PermissionReducer from "./PermissionRed";
import userPermissionReducer from "./UserPermission"
import ItemMasterReducer from "./GetItemMasterRed"
import InventoryAdjustment from "./InventoryAdjustment"
import AsnEntry from "./AsnEntry";
import Uom from "./Uom";
import TableCustomise from "./TableCustomise";
import ReturnProcess from "./ReturnProcess";
import SalesProcess from "./SalesOrder";
import Kit from "./Kit";
import ExternalForm from "./ExternalForm";
import GetKitMaster from "./GetKitMasterData";
import Dekit from "./Dekit";
import DynamicPageConfig from "./DynamicPageConfig";
import InventoryJourney from "./InventoryJourney";
import Workflow from "./Workflow"
import AutomationRule from "./AutomationRule";
import CustomDashboard from "./CustomDashboard";
import InHousePalletization from "./InHousePalletization";
const appReducer = combineReducers({
  routing: routerReducer,
  auth: Auth,
  superadmin: SuperAdmin,
  warehouseData: warehouseReducer,
  templates: Templates,
  common: Common,
  areaMappingData: areaMappingReducer,
  RFIDData: RFIDReducer,
  canvas: Canvas,
  ui: UI,
  inward: InwardEntry,
  inventory: Inventory,
  job: jobReducer,
  mainDashboard: mainDashboardReducer,
  users: ManageTeamReducer,
  UserRoles: UserRoleReducer,
  permissions: PermissionReducer,
  userPermission: userPermissionReducer,
  itemMaster: ItemMasterReducer,
  inventoryAdjustment: InventoryAdjustment,
  asnEntry: AsnEntry,
  uom: Uom,
  customHeader: TableCustomise,
  returnProcess: ReturnProcess,
  SalesProcess: SalesProcess,
  Kit:Kit,
  ExternalForm: ExternalForm,
  GetKitMaster:GetKitMaster,
  Dekit:Dekit,
  DynamicPageConfig:DynamicPageConfig,
  InventoryJourney:InventoryJourney,
  Workflow:Workflow,
  AutomationRule:AutomationRule,
  CustomDashboard:CustomDashboard,
  InHousePalletization:InHousePalletization,
});

const rootReducer = (state, action) => {
  // console.log("action",action); // Log the action to see if RESET_STORE comes through
  // if (action.type === RESET_STORE) {
  //   return appReducer(undefined, action);
  // }

  return appReducer(state, action);
};

export default rootReducer;
