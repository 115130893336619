import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCustomDashboardDetail, updateDashboardStatus } from "../../../../redux/actions/CustomDashboard";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { Breadcrumbs, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import theme, { buttonClasses, textClasses } from "../../../../static/styles/theme";
import CountTypeWidget from "../WidgetComponents/Count";
import AnalyticsTypeWidget from "../WidgetComponents/Analytics";
import AlertTypeWidget from "../WidgetComponents/Alert";
import { showToast } from "../../../../services/functions";
import TableTypeWidget from "../WidgetComponents/Table";


export default function PreviewCustomDashboard({ isDefaultDashboard = false }) {
  const { dashboardID } = useParams();
  const dispatch = useDispatch();
  const { userPermissions } = useSelector((state) => state.userPermission);
  let permissions = userPermissions || {};
  const [loader, setLoader] = useState(true);
  const [tableData, setTableData] = useState({})
  const [isDefault, setDefault] = useState(false)
  const onFilterChange = (value, customRange) => {
  }
  function handleSave() {
    let payload = {
      dashboardId: dashboardID,
      isDefaultDashboard: true,
      updateType: "setDefaultDashboard"
    }
    setDefault(true)
    dispatch(updateDashboardStatus(payload, (response) => {
      showToast(response.message, !response.success);
      setDefault(false)
      if (response.success) {
        setTableData({ ...tableData, isDefaultDashboard: true })
      }
    }))
  }
  useEffect(() => {

    dispatch(getCustomDashboardDetail({
      dashboardID: dashboardID
    }, (data) => {
      if (data && data?.success) {
        setTableData(data?.data)

        setLoader(false);
      }
    }))
  }, [])
  return (<>
    {
      !loader ? <>
        <Grid container md={12} display={"flex"}>
          <Grid item sm={6} xs={6}  >

            <Typography sx={{ ...textClasses.cardTitle }}>View Custom Dashboard</Typography>
            <Breadcrumbs sx={{ mb: 1 }}>
              <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }}>
                <Link style={{ color: theme.themeOrange, textDecoration: "none" }} to="/dashboard/view-custom-dashboard">
                  My Dashboard
                </Link>
              </Typography>
              <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>View Dashboard</Typography>
            </Breadcrumbs>

          </Grid>
      {
        (permissions.permissions || []).some(
          (item) => item.permissionId === "changeCustomDashboardStatus"
        ) ? <Grid container item sm={6} xs={6} justifyContent="end" sx={{}} alignItems="center">
      
        <Button
          data-testid="add"
          variant="outlined"
          size="medium"
          disabled={
            
             isDefault || tableData?.isDefaultDashboard
                          }
          sx={{ ...buttonClasses.approvedButton }}
          onClick={handleSave}
        >
          Mark As Default
        </Button>



      </Grid> :null
      }
         
        </Grid>
        <Grid container >
          <WidgetComponent tableData={tableData} onFilterChange={onFilterChange} />
        </Grid>
      </>
        : <Grid container direction={"column"} justifyContent={"center"} alignItems="center" sx={{ p: 5 }}>
          <Grid item >
            <GenericLoader />
          </Grid>
        </Grid>
    }
  </>)
}


const WidgetComponent = ({ tableData, onFilterChange }) => {


  const Theme = useTheme();
  const styleViewObject = { 'border': `1px solid ${theme.themeGray} ` }
  const renderWidgetComponent = (widget) => {
    switch (widget?.elementID) {
      case 'welcomeNote':
        return <AlertTypeWidget styleView={styleViewObject} item={widget} />;
      case 'scheduledEvents':
        return <AlertTypeWidget styleView={styleViewObject} item={widget} />;
      case 'inventoryWeight':
      case 'operations':
      case 'inventoryCount':
      case 'myWarehouse':
        return <CountTypeWidget item={widget} styleView={styleViewObject} onFilterChange={onFilterChange} />;
      case 'jobCreationAndCompletion':
      case 'stockVisibility':
      case 'areaUtilization':
        return <AnalyticsTypeWidget
          styleView={styleViewObject}
          item={widget}
        />;
      case 'jobTable':
        return <TableTypeWidget
          styleView={styleViewObject}
          item={widget}
        />;
      default:
        return null;
    }
  };
  const isXs = useMediaQuery(Theme.breakpoints.down("sm"));
  const calculateSize = (totalSize, span, unit = "px") => {
    if (!totalSize || !span) return "auto";

    return unit === "%" ? `calc((100% / ${totalSize}) * ${span})` : `${(totalSize / totalSize) * span}px`;
  };
  useEffect(() => {
  }, [tableData, isXs]);

  return (
    <Grid container direction="column" alignItems={"stretch"}>
      {isXs ? (
        // Grid Layout for Mobile View
        <Grid container component={Paper}  >
          {tableData?.attributes?.map((widget, index) => (
            <Grid item xs={12} key={index}>
              <Box
                sx={{
                  padding: 1,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {renderWidgetComponent(widget)}
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        // Table Layout for Larger Screens
        <TableContainer component={Paper} sx={{ width: "100%" }}>
          <Table>
            <TableBody
              sx={{
                border: `1px solid ${theme.themeGray}`,
                width: "100%",
                height: "100%",

              }}
            >
              {[...Array(tableData?.coveredRows)].map((_, rowIndex) => (
                <TableRow key={rowIndex} sx={{ width: "100%", height: '100%' }}>
                  {[...Array(tableData?.coveredCols)].map((_, colIndex) => {
                    const widget = tableData?.attributes?.find(
                      (w) => w.x === colIndex && w.y === rowIndex
                    );

                    return widget ? (
                      <TableCell
                        sx={{
                          border: 0,
                          p: 0.5,
                          width: `${calculateSize(tableData?.coveredCols, widget.w, "px")}`,
                          height: `${calculateSize(tableData?.coveredRows, widget.h, "px")}`
                        }}
                        key={colIndex}
                        colSpan={widget.w}
                        rowSpan={widget.h}
                      >
                        <Box sx={{ width: "100%", height: "100%" }}>
                          {renderWidgetComponent(widget)}
                        </Box>
                      </TableCell>

                    ) : "";
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
};
