import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Grid, Tab, Tabs, Box,
} from "@mui/material";
import colors, { buttonClasses, textClasses, inputClasses } from '../../../../static/styles/theme';
import CustomModel from '../../../Components/CustomModels/CustomModel1';

import {
	getJobPendencyDetail
} from "../../../../redux/actions/Job";

import theme from "../../../../static/styles/theme";

import {truncateNumber } from "../../../../services/functions";
import GenericTable from "../../../Components/CustomTable/GenericTable"
import GenericLoader from "../../../Components/Generic/GenericLoader";


function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export default function PendencyDetailView(props) {
	let dispatch = useDispatch();
	const { open, hide,
		pendencyDetail: { items = [], pendencyType = "shipmentWise", pendencyColumn = "Shipment Id", title = "Shipment Wise Pendency" },
	} = props
	const [loader, setLoader] = useState(false)
	const [condition, setCondition] = useState(true)
	const [detail, setDetail] = useState({ data: [], count: 0 })
	const [filter, setFilter] = useState({ page: 1, limit: 10 })
	const [tabIndex, setTabIndex] = useState(0)

	const getPendency = (index = tabIndex) => {
		setLoader(true)
		setDetail({ data: [], count: 0 })
		setTabIndex(index)
		dispatch(getJobPendencyDetail({ jobComponentId: items?.at(index)?._id || "", pendencyType, page: filter.page, limit: filter.limit }, ({ success = false, message, data = [], count = 0 }) => {
			setLoader(false)
			setDetail({ data, count })
		}));
	}

	let header = [
		"S.No",
		`${pendencyColumn}`,
		"Total QTY",
		"Scanned QTY",
		"Remaining QTY"
	]
	if (items?.at(tabIndex)?.jobType == "inventoryAudit") {
		header.pop()
	}

	const getRows = detail?.data && detail?.data.map((element, index) => {
		let row = []
		let { count = 0, scannedCount = 0, shipmentId, packageName = "", SKU_Number = "", uniqueItemCount = 0,
			uniquePackageCount = 0 } = element

		row.push(((filter.page - 1) * (filter.limit)) + index + 1, count, scannedCount, truncateNumber(Number(count) - Number(scannedCount)))

		if (items?.at(tabIndex)?.jobType == "inventoryAudit") {
			row.pop()
		}
		if (pendencyType == "shipmentWise") {
			row.splice(1, 0, shipmentId)
		}
		else if (pendencyType == "packageWise") {
			row.splice(1, 0, `${packageName} (${uniquePackageCount} Package)`)
		}
		else {
			row.splice(1, 0, `${SKU_Number} (${uniqueItemCount} Item)`)
		}
		return row
	})

	useEffect(() => {
		if (items?.length) getPendency(0)
	}, [items])

	useEffect(() => {
		if (!condition) {
			getPendency(tabIndex)
		}
		setCondition(false)
	}, [filter.page, filter.limit]);

	return (
		<>
			<CustomModel
				autoClose={false}
				data-testid={open}
				show={open || false} onClose={() => hide()}
				minWidth={200} maxWidth={800}
				pt={1} pb={3} pl={3} pr={3}
				maxHeight={"90vh"} viewCloseIcon={true}
				childrenMaxHeight={"inherit"}
				// childrenMaxHeight={"85vh"}
				Title={title}
			>
				<Box>
					<Tabs
						value={tabIndex}
						aria-label="disabled tabs example"
						onChange={(event, value) => getPendency(value)}
						style={{ height: "20px" }}
						sx={{
							"& .MuiTabs-indicator": {
								backgroundColor: theme.themeOrange,
							},
							"& .MuiTab-root.Mui-selected": {
								color: theme.themeOrange,
							},
						}}
						data-testid="job-tabs"
					>
						{
							items.map((dl, index) => (
								<Tab
									label={dl.actionName || ''}
									{...a11yProps(index)}
									sx={{ ...textClasses.normalText, textTransform: "none" }}
								/>
							))
						}

					</Tabs>
					{loader
						? <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>

							<Grid item><GenericLoader /></Grid>
						</Grid>
						:
						<Box sx={{ width: "inherit" }}>
							<GenericTable
								data-testid="pendency-table"
								header={header}
								rows={getRows}
								pageCount={detail.count || 0}
								pageNumber={filter.page - 1}
								handleChangePage={(event, pagevalue) => {
									setFilter((f) => ({
										...f,
										page: pagevalue + 1,
									}));
								}}
								handleChangeRowsPerPage={(event) => {
									setFilter((f) => ({
										...f,
										limit: +event.target.value,
									}));
								}}
								rowsPerPage={filter.limit}
							/>
						</Box>
					}
				</Box>

			</CustomModel>
		</>
	)
}