import * as XLSX from 'xlsx';
import Cookies from 'js-cookie';

const parseJwt = (token)=>{
    if(token){
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    }
    else {
        return {};
    }
}

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

const getExcelStarterData=(columnsLength=0,numberOfRows=7)=>{
    const rows=Array.from({ length: numberOfRows || 7 }, () =>
        Array.from({ length: columnsLength || 0 }, () => ({ value: '' }))
    )
    return rows
}

// Convert spreadsheet data to an array of objects
const exportToJsonArray = (columns=[], data = []) => {
    const headers = columns; // First row contains headers

    const jsonArray = data.map((row) => {
        return row.reduce((acc, cell, colIndex) => {
            const key = headers[colIndex];
            const value = typeof cell=="object" && cell?.value || "";

            // Only sanitize if the value is a string
            acc[key] = typeof value === 'string' ? value.replace(/[\r\n]+/g, '').trim() : value;
            return acc;
        }, {});
    })
    // Filter out rows where all values are empty
    .filter(row => Object.values(row).some(value => value !== "")); 
    // console.log(jsonArray); // This will log the JSON array
    return jsonArray;
};

// Export data to Excel buffer
const exportToExcelBuffer = (columns=[], data = []) => {
    const sheetData = [
        columns, // Headers
        ...data.map(row => row.map(cell => typeof cell=="object" && cell?.value || "")) // Row data
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate the workbook as a binary string
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    // Now you have `excelBuffer` as an ArrayBuffer, which you can convert to JSON or send elsewhere

    return excelBuffer;
};

const createExcelFileAndReturn = (columns, data) => {
    // Prepare sheet data with headers and row data
    const sheetData = [
        columns,
        ...data.map(row => row.map(cell =>typeof cell=="object" && cell?.value || ""))
    ];

    // console.log(data.map(row => row.map(cell =>typeof cell=="object" && cell?.value || "")),"data",sheetData)
    // Generate worksheet and workbook
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Create binary buffer in memory
    const workbookBinary = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Convert binary to a File object to use in FormData
    const excelFile = new File([workbookBinary], 'SpreadsheetData.xlsx', {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    return excelFile;
};
  
const localStorageLogoutConditions = ()=>{
      // Get the token from localStorage before clearing it
      const partialAccessToken = localStorage.getItem('passToken');
      const eSalesSlotData = localStorage.getItem('e-slotsBooked');
    
      // Clear everything except the token
      localStorage.clear();
      Cookies.remove("centralTheming", { path: "/" });
      // Restore the token
      if (partialAccessToken) {
          localStorage.setItem('passToken', partialAccessToken);
          localStorage.setItem('e-slotsBooked', eSalesSlotData);

      }
  
      // Optionally, redirect user to the login page
    //   history.push("/")
    }
export  {parseJwt,b64toBlob,getExcelStarterData,exportToJsonArray,createExcelFileAndReturn,exportToExcelBuffer,localStorageLogoutConditions};