import React,{ useEffect, useRef, useState } from 'react'
import { Autocomplete, Breadcrumbs, Button, Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import theme, { textClasses, cardClasses, inputClasses, buttonClasses } from "../../../../static/styles/theme";
import { getCamelCaseText, showToast, truncateNumber } from "../../../../services/functions";
import { useDispatch, useSelector } from "react-redux";
import { getAllTemplates, getSelectedTemplate, saveKitEntry } from "../../../../redux/actions";
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import KitAndBundleDetailSection from "./KitAndBundleSectionDetail";
import ComponentSection from "./ComponentSection";
import SelectImage from "../../../../../src/static/images/data_entry/select_location.svg";
import { getCompany } from "../../../../redux/actions";
import history from "../../../../services/history";
import { handleDynamicChange, } from "../../../../services/formFunction";
import { deepCopy_Object } from '../../../../services/functions'; 

const AddKit = () => {
    const dispatch = useDispatch()
    const template = useSelector(state => state.inward.selectedTemplate);
    const allTemplates = useSelector(state => state.templates.allTemplates);
    const { data: allWarehouses } = useSelector((state) => state?.superadmin?.warehouseDetail) || {};
    const fileInputRef = useRef();
    const [items, setItems] = useState([])
    const { register, handleSubmit, watch, getValues, setValue, control, reset, formState: { errors } } = useForm();
    // const {register, control, setValue, errors} = formHooks;
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [warehouseFilter, setWarehouseFilter] = useState(allWarehouses?.length==1?allWarehouses[0]:null);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const kitQuantity = watch("KIT_Quantity");
    const [errors2, setErrors2] = useState({})
    const allFields = (template && template.otherTemplate && template.otherTemplate.length > 0 && template.otherTemplate[0].attributes) ? template.otherTemplate[0].attributes : [];
    const [dataRecieved, isDataRecieved] = useState(false);
    const validateFields = () => {

        let newErrors = [];

        for (let j = 0; j < items.length; j++) {
            const row = items[j];
            let rowErrors = {};
            const keys = Object.keys(row);
            for (let i = 0; i < keys.length; i++) {
                const fieldId = keys[i];
                const element = row[fieldId];
                const targetField = allFields.filter(f => f._id == fieldId)[0];
                if (targetField) {
                    if (targetField.required && (!element || element == "")) {
                        // //(`${targetField.label} failing required test with value ${element}`);

                        rowErrors[fieldId] = {
                            name: targetField.label,
                            message: `${targetField.label} is required`
                        }
                    }
                    else if (targetField.pattern && !(new RegExp(targetField.pattern)).test(element)) {
                        // //(`${targetField.label} failing pattern test ${targetField.pattern} with value ${element}`);
                        rowErrors[fieldId] = {
                            name: targetField.label,
                            message: `Invalid value for ${targetField.label}`
                        }
                    }
                }
            }
            newErrors.push(rowErrors)
        }

        return newErrors;
    }
 
    const checkItemSubmit = () => {
        let zeroCount = items.filter(it => !it.quantity);
        if (zeroCount.length > 0) {
            isDataRecieved(false)
            showToast("Item count cannot be 0", true)
            return false;
        }

        let newErrors = validateFields();
        setErrors2(newErrors);

        if (newErrors.filter(row => Object.keys(row).length != 0).length != 0) {
            isDataRecieved(false)
            showToast("Form Error: Please check for errors", true);
            return false;
        }
       
        return true;
    }

    const handleClear = () => {

        //  setSelectedTemplate(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = ""
        }
        let resetD = { ...getValues() };
        let rKeys = Object.keys(resetD);
        // let keys = Object.keys(getValues())
        for (let i = 0; i < rKeys.length; i++) {
            // setValue(keys[i], "");
            resetD[rKeys[i]] = ""

        };
        reset({
            ...resetD
        });
        // setConnectedCust_Sup_Detail({});
        setItems([]);

    }


    const handleUpcoming = (data) => {
        handleSave({ ...data, drafted: false })
    }

    const handleSave = (data) => {
        isDataRecieved(true);

        let payload = {
            templateId: template?._id,
            warehouseId: warehouseFilter?._id,
            kitDetailsData: data,
            drafted: data.drafted,

        }

        const itemValidation = checkItemSubmit();
        if (itemValidation) {
            if (template.connectedMaster && template.connectedMaster.itemMaster) {
                let nonSearched = items.filter(i => i.itemDetail == null)
                if (nonSearched.length > 0) {
                    isDataRecieved(false)
                    showToast(`Please finish search of Product Id ${nonSearched[0].product_ID}`, true)
                    return;
                }
            }
            payload['componentDetailsData'] = items
            // //(payload);
            dispatch(
                saveKitEntry(payload, (data) => {
                    if (data) {
                        history.push("/dashboard/inventory/kit");
                    }
                    else{
                        isDataRecieved(false)
                    }
                })
            )
        }

    }

    const onKeyPressEnterTrigger = () => {
        handleSubmit(handleUpcoming)();
    };

    useEffect(() => {
        dispatch(
            getAllTemplates({ type: "wms", templateType: "kittingTemplate", status: "published" }, (data) => {
                if (data && data.length == 1) {
                    setSelectedTemplate(data[0])
                }
            })
        )
        dispatch(getCompany({ type: "warehouse", status: "active" }));

    }, []);


    useEffect(() => {
        // setPackages([]);
        //  handleClear()
        if (selectedTemplate != null) {

            dispatch(
                getSelectedTemplate({ template_id: selectedTemplate._id })
            )
        }

    }, [selectedTemplate])

    useEffect(() => {
        let tempItem = [...items]
        tempItem.map((element) => {
            element["totalQuantity"] = truncateNumber(Number(element?.quantity || 0) * Number(kitQuantity || 1));
        })
        setItems(tempItem)

    }, [watch("KIT_Quantity")])

    const handleAutofill = async (KIT_ID) => {
        let details = {}
        let targetField = template?.attributes?.find((dl) => dl?._id == "KIT_ID")
        await handleDynamicChange(details, targetField, { value: KIT_ID || "" }, "itemDetail")
        let componentDetail = deepCopy_Object(details?.itemDetail?.componentsData || [])
        details = details?.itemDetail?.formData || {}
        for (let info of template?.attributes) {
            let readKey = info?._id
            let insertKey = info?._id
            if (readKey == "KIT_Quantity") continue
            if (readKey == "KIT_ID") {
                readKey = "SKU_ID"
            }
            else if (readKey == "KIT_Name") {
                readKey = "SKU_Detail"
            }
            setValue(insertKey, details[readKey] || "")
        }
        let tempArray = componentDetail?.map((dl) => {
            let tempObj = { ...(dl.formData || {}), "disabled": [] }
            template?.otherTemplate?.at(0)?.attributes?.forEach(attr => {
                let readKey = attr._id;
                let insertKey = attr._id;

                if (readKey === "quantity") {
                    tempObj[insertKey] = Number(dl?.quantity || 0);
                } else {
                    tempObj[insertKey] = dl?.formData?.[readKey] || "";
                }

                if (readKey === "product_ID") {
                    tempObj['product_ID'] = tempObj["SKU_ID"];
                }
                if (readKey === "SKU_ID") {
                    tempObj['product_ID'] = tempObj["SKU_ID"];
                } else if (readKey === "totalQuantity") {
                    tempObj['totalQuantity'] = truncateNumber(((tempObj['quantity'] || 0) * Number(kitQuantity || 0)),3,true);
                    tempObj["disabled"].push("totalQuantity");
                }
            });
            tempObj['itemDetail'] = { formData: dl?.formData, template_id: dl?.template_id };
            tempObj["disabled"].push("product_ID");
            return tempObj;
        });
        setItems(tempArray)
    }

    useEffect(() => {
        if(template?.attributes?.length>0){
            handleAutofill(watch("KIT_ID"))
        }

    }, [watch("KIT_ID")])

    return (<>
        <Typography sx={{ ...textClasses.cardTitle }}>Create A New Kit & Bundle It</Typography>

        <Breadcrumbs sx={{ mb: 1 }} data-testid="breadcrumb-create-new-kit">
            <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }}>
                <Link style={{ color: theme.themeOrange, textDecoration: "none" }} to="/dashboard/inventory/kit">
                Manage Kitting
                </Link>
            </Typography>

            <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>Create New Kitting</Typography>
        </Breadcrumbs>

        <Paper elevation={1} sx={{ ...cardClasses.basic, borderRadius: "8px", p: 2, width: "100%" }}>
            <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Grid container item sm={5} xs={4} alignContent="center">
                    <Typography sx={{ ...textClasses.t20n, mt: "5px" }}>Select Template: </Typography>
                    <Autocomplete
                        data-testid="template-dropdown"
                        value={selectedTemplate}
                        onChange={(e, option) => {
                            setSelectedTemplate(option);
                        }}
                        getOptionLabel={(option) => getCamelCaseText(option.name)}
                        size='small'
                        options={allTemplates}
                        sx={{
                            ...inputClasses.filterField,
                            minWidth: "220px",
                            m: 0,
                            ml: 2,
                            ".MuiInputBase-input": {
                                fontSize: "14px",
                                color: "#455A64"
                            }
                        }}
                        renderInput={(params) => <TextField sx={{ color: "#455A64", ...textClasses.normalText }} {...params} label="Select Template" />}
                    />
                </Grid>
                <Grid container item sm={5} xs={4} alignContent="center" justifyContent="flex-end" sx={{ marginRight: '30px' }}>
                    <Typography sx={{ ...textClasses.t20n, mt: "5px" }}>Select Warehouse: </Typography>
                    <Autocomplete
                    data-testid="warehouse-dropdown"
                        value={warehouseFilter}
                        onChange={(e, option) => {
                            setWarehouseFilter(option);
                            setPage(0);
                            setLimit(10);
                        }}
                        getOptionLabel={(option) => option.name}
                        size="small"
                        options={allWarehouses}
                        sx={{
                            ...inputClasses.filterField,
                            minWidth: "220px",
                            m: 0,
                            ml: 2,
                            ".MuiInputBase-input": {
                                fontSize: "14px",
                                color: "#455A64",
                            },
                        }}
                        renderInput={(params) => (
                            <TextField
                                sx={{ color: "#455A64", ...textClasses.normalText }}
                                {...params}
                                label="Select Warehouse*"
                            />
                        )}
                    />
                </Grid>
            </Grid>

        <Divider sx={{ m: "0 5px 0 5px", mb: 2, mt: 2 }} />
            {
                selectedTemplate != null && template && warehouseFilter
                    ?
                    <>
                        <KitAndBundleDetailSection
                            template={template}
                            formHooks={{ register: register, control: control, setValue: setValue, errors: errors }}
                            onKeyPressEnterTrigger={onKeyPressEnterTrigger}
                        />
                        <ComponentSection
                            template={template}
                            items={items}
                            setItems={setItems}
                            kitQuantity={kitQuantity}
                            AddRow={true}
                            errors={errors2}
                            onKeyPressEnterTrigger={onKeyPressEnterTrigger}
                        />
                        <Grid container direction={"row"} sx={{ mt: 2 }}>
                            <Button data-testid="clearall-btn" variant='outlined' size='small' sx={{ ...buttonClasses.outlined, color: theme.themeOrange, minWidth: "150px" }} onClick={handleClear}
                                disabled={dataRecieved}
                            >
                                Clear All
                            </Button>
                                <Button data-testid="submit-btn" variant='contained' size='small' sx={{ ...buttonClasses.small, minHeight: "40px", backgroundColor: theme.themeOrange, minWidth: "150px", ml: 2 }} onClick={handleSubmit(handleUpcoming)}
                                    disabled={dataRecieved }
                                >
                                    Submit
                                </Button>
                                
                        </Grid>
                    </>
                    :

                    <Grid container direction={"column"} justifyContent="center" alignItems="center" sx={{ p: 4 }}>
                        <img src={SelectImage} />
                        <Typography sx={{ ...textClasses.cardTitle, mt: 2 }}>select a template and warehouse first...</Typography>
                        <Typography sx={{ ...textClasses.t12n, textAlign: "center", maxWidth: "500px", mt: 2 }}>
                        Hey, Please select a previously created Kitting template, to initiate Kitting operation.
                        </Typography>
                    </Grid>
            }


        </Paper>
    </>)
}

export default AddKit;