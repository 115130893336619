import { Delete, DeleteOutline, Edit, SwapVert } from '@mui/icons-material';
import {
    Box, Typography, Paper, Grid,
    Card, CardHeader, CardContent, Button, TextField, ButtonGroup, IconButton,
    Modal, FormControl, FormControlLabel, FormLabel, InputLabel, Select, MenuItem
} from '@mui/material';
import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import history from '../../../services/history';
import theme, { buttonClasses, inputClasses, textClasses } from '../../../static/styles/theme';
import GenericInputSection from '../../Components/Generic/GenericInputSection';
import EditFieldModal from './EditFieldModal';
import { getSectionHelperText, getTemplateTypeName } from '../../../services/constants';
import {getRegexList, initCanvas } from '../../../redux/actions';
import SuccessDialog from './SuccessDialog';
import { showToast } from '../../../services/functions';
import { keyPressActions } from '../../../services/constants';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40%",
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 3,
};

const PublishDraftModal = ({ 
    status, showModal, handleCloseModal, selectedSubTemplate, register, control, setValue, errors,
     handleSubmit, handlePublish, getTemplateTypeName, template,edit=false ,dataEntryState=""
    }) => {
    ////("Template", template);
    useEffect(() => {
        if (template) {
            setValue("name", template.name);
            setValue("description", template.description);      

        }
    }, [template])
    return (
        <Modal
            open={showModal}
            onClose={handleCloseModal}
        >
            <Card sx={style}>

                <Typography sx={{ ...textClasses.cardTitle }}>{status && status.substring(0, 1).toUpperCase() + status.substring(1, status.length)} {getTemplateTypeName(template?.dataEntryType?.length>0?template?.dataEntryType:selectedSubTemplate)} Template</Typography>
                <Typography sx={{ ...textClasses.normalText, color: "#455A64", mt: 1, mb: 1 }}>
                    To save template fill the below details
                </Typography>
                <Grid container>
                    <TextField variant='outlined' size='small' required fullWidth label="Template name" sx={{ mt: 1, mb: 1 }} {...register("name", { required: true })} 
                onKeyDown={(e) => {
                    if (e.key === keyPressActions["submit"]) {
                      e.preventDefault(); // Prevent default form submission
                      handleSubmit(handlePublish)(); // Trigger the submit action
                    }
                  }}
                    />
                    <Typography sx={{...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mb: 2}}>
                        { errors && errors.name ? "Template name is required" : ""}
                    </Typography>
                    <TextField variant='outlined' size='small' fullWidth label="Description" multiline rows={2} sx={{mt: 1, mb: 2 }} {...register("description", { required: false })} 
                     onKeyDown={(e) => {
                        if (e.key === keyPressActions["submit"]) {
                          e.preventDefault();
                          handleSubmit(handlePublish)();
                        }
                      }}
                    />

                </Grid>
                <Grid container spacing={5}>
                    <Grid container item sm={6} xs={6} justifyContent="center" alignItems="center">
                        <Button variant='outlined' fullWidth size='small' sx={{ ...buttonClasses.outlined, color: theme.themeOrange }} onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid container item sm={6} xs={6} justifyContent="center" alignItems="center">
                        <Button variant='contained' fullWidth size='small' sx={{ ...buttonClasses.small, minHeight: "40px", backgroundColor: theme.themeOrange }} onClick={handleSubmit(handlePublish)}>
                            Publish
                        </Button>
                    </Grid>

                </Grid>
            </Card>
        </Modal>
    )
}

const useSpiType = (template) => {
    const [spiType, setSpiType] = useState(null);

    useEffect(() => {
        if (template) {
            let type;
            if (!template.otherTemplate || template.otherTemplate.length == 0) {
                type = "shipmentOnly";
            }
            else if (template.otherTemplate.length == 2) {
                type = "SPI";
            }
            else if (template.otherTemplate.length == 1 && template.otherTemplate[0].templateType == "packageDetailTemplate") {
                type = "shipmentPackageOnly";
            }
            else {
                type = "shipmentItemOnly";
            }

            setSpiType(type);

        }
    }, [template])

    return spiType;
}

export default function ComponentCanvas({ components, handleSaveTemplate,template,handleTemplateChange,edit=false }) {
    const editField = useSelector(state => state.canvas.editField)
    const selectedSubTemplate = useSelector(state => state.canvas.selectedSubTemplate)
    const selectedSupplierMaster = useSelector(state => state.canvas.selectedSupplierMaster);
    const selectedCustomerMaster = useSelector(state => state.canvas.selectedCustomerMaster);
    const selectedItemMaster = useSelector(state => state.canvas.selectedItemMaster);
    const selectedSubTypeMaster = useSelector(state=> state.canvas.subMasterTypeKey)
    const allRegex = useSelector(state => state.templates.allRegex)
    const [showModal, setShowModal] = useState(false);
    const [status, setStatus] = useState(null);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [succData, setSuccData] = useState(null);
    
    const isCanvasDefault = (() => {
        return true;
    })();
    
    const isCanvasEmpty = (() => {
        return false;
        let attributes = [...components.attributes.mandatory.filter(f => f.templateType == selectedSubTemplate), ...components.attributes.other];
        return attributes.length == 0;
    })();
    
    const spiType = useSpiType(template);
    
    ////("Template id Canvas", template);
    
    const dispatch = useDispatch();
    
    const handleOpenModal = (status,temp_info) => {
        if(selectedSubTemplate && ["salesOrderTemplate"].includes(selectedSubTemplate) && (!selectedItemMaster  || selectedItemMaster=="-")){
            showToast("Item Master selection is required!",true)
            return;
        }
        setShowModal(true);
        setStatus(status) 
    }
    const handleCloseModal = () => {
        setShowModal(false);
        setStatus(null);
    }
    
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm();

    const handlePublish = (data) => {
        // setOpenSuccess(true);
        // console.log("HANDLE PUBLISH", data);
        handleSaveTemplate({ ...data, status: status }, (data) => {
            handleCloseModal();
            if (data) {
                setOpenSuccess(true);
                setSuccData(data);
            }
            // history.push("/dashboard/templates")
        });

    }
    
    
    
    const handleClear = () => {
        dispatch(initCanvas());
    }
    
    const handleSucccessModel=()=>{
        setOpenSuccess(false);
        handleCloseModal()
        handleTemplateChange({target:{value:"-"}})
    }
    
    useEffect(() => {
        setOpenSuccess(false);
        setSuccData(null);
        dispatch(getRegexList({type:"wms"}));
    }, [])

    const getSectionName = (subTemplateType)=>{
        if(subTemplateType === "kittingTemplate"){
            return "Kit Details"
        } else if(subTemplateType === "salesOrderTemplate"){
            return "Sales Order"
        } else if(subTemplateType === "palletizationTemplate"){
            return "Pallet Details"
        }
        return "Shipment Details"
    }

    return (
        <Grid container alignItems={"center"} direction="column" justifyContent="center" sx={{ p: 0, mt: 2 }}>
            <Grid container item sm={12} xs={12}>
                {
                    (template && ["inwardDataEntry", "ASNTemplate", "kittingTemplate", "salesOrderTemplate","palletizationTemplate"].indexOf(template.templateType) >= 0) || (["inwardDataEntry", "ASNTemplate", "kittingTemplate", "salesOrderTemplate","palletizationTemplate"].indexOf(selectedSubTemplate) >= 0)
                        ?
                        <>
                            <GenericInputSection
                                components={components}
                                edit={edit}
                                sectionId={"attributes"}
                                sectionName={getSectionName(selectedSubTemplate)}
                                helperText={getSectionHelperText(selectedSubTemplate === "kittingTemplate" ? "kittingTemplate" :  "shipmentTemplate")}               
                            />
                            {
                                ((!template && selectedSubTemplate == "inwardDataEntry") || (template && template.templateType == "inwardDataEntry" && (["SPI", "shipmentPackageOnly"].indexOf(spiType) >= 0))) &&
                                <GenericInputSection
                                    components={components}
                                    edit={edit}
                                    sectionId={"packageTemplate"}
                                    sectionName={"Package Details"}
                                    removable={template == null ? true : (template.frequency <= 0)}
                                    sx={{ mt: 1 }}
                                    helperText={getSectionHelperText("packageTemplate")}
                                />
                            }

                            {
                                ((!template) || (template && ((["SPI", "shipmentItemOnly"].indexOf(spiType) >= 0)) || ["inwardDataEntry", "ASNTemplate", "kittingTemplate", "salesOrderTemplate"].includes(template.templateType))) &&
                                <GenericInputSection
                                    components={components}
                                    edit={edit}
                                    sectionId={"itemTemplate"}
                                    sectionName={
                                        !template
                                            ?
                                            (
                                                selectedSubTemplate == "inwardDataEntry" ? "Product/Items Details" : ["palletizationTemplate", "kittingTemplate"].includes(selectedSubTemplate) ? "Component Details" : "Order Details"
                                            )
                                            :
                                            (
                                                template.templateType == "inwardDataEntry" ? "Product/Items Details" : ["palletizationTemplate", "kittingTemplate"].includes(selectedSubTemplate) ? "Component Details" : "Order Details"
                                            )

                                    }
                                    removable={template == null ? true : (template.frequency <= 0)}
                                    sx={{ mt: 1 }}
                                    helperText={getSectionHelperText(["palletizationTemplate", "kittingTemplate"].includes(selectedSubTemplate) ? "componentDetail" : "itemTemplate")}
                                />
                            }

                            {/* <GenericInputSection
                                components={components}
                                sectionId={"packageTemplate"}
                                sectionName={"Package Details"}
                                removable
                                sx={{mt: 1}}
                                helperText={getSectionHelperText("packageTemplate")}
                            />
                            <GenericInputSection
                                components={components}
                                sectionId={"itemTemplate"}
                                sectionName={"Product/Items Details"}
                                removable
                                sx={{mt: 1}}
                                helperText={getSectionHelperText("itemTemplate")}
                            /> */}
                        </>
                        :
                        <GenericInputSection
                            components={components}
                            edit={edit}
                            sectionId={"attributes"}
                            sectionName={`${["palletizationTemplate", "kittingTemplate"].includes(selectedSubTemplate) ? "Component Details" : selectedSubTemplate} Details`}
                            helperText={getSectionHelperText(selectedSubTemplate)}
                        />

                }

            </Grid>
            <Grid container sx={{ mt: 2 }}>
                {
                    !template &&
                    <Button variant='outlined' size='small' sx={{ ml: 1, ...buttonClasses.outlined, minWidth: "180px", borderColor: theme.themeOrange, color: theme.themeOrange }} onClick={handleClear} disabled={isCanvasDefault}>
                        Clear All
                    </Button>

                }

                <Button variant='outlined' size='small' sx={{ ml: 1, ...buttonClasses.outlined, minWidth: "180px", borderColor: theme.themeOrange, color: theme.themeOrange }} onClick={() => handleOpenModal("drafted",template)} disabled={isCanvasEmpty}>
                    Save Draft
                </Button>
                <Button variant='contained' size='small' sx={{ ml: 1, ...buttonClasses.small, minWidth: "180px", backgroundColor: theme.themeOrange }} onClick={() => handleOpenModal("published",template)} disabled={isCanvasEmpty}>
                    Publish
                </Button>
            </Grid>
           { showModal && <PublishDraftModal
                status={status}
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                selectedSubTemplate={selectedSubTemplate}
                register={register}
                control={control}
                setValue={setValue}
                errors={errors}
                handleSubmit={handleSubmit}
                handlePublish={handlePublish}
                getTemplateTypeName={getTemplateTypeName}
                template={template}
                edit={edit}
                dataEntryState={template?.dataEntryType}
            />}
           {editField && <EditFieldModal editField={editField} allRegex={allRegex} />}
            {openSuccess && <SuccessDialog open={openSuccess} data={succData} handleSucccessModel={handleSucccessModel}/>}
        </Grid>
    )
}
