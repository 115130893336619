/* eslint-disable */

import React from 'react'
import { Grid, ButtonGroup, TextField, IconButton, Input, FormControl, FormLabel, FormGroup, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, RadioGroup, Radio, Button } from '@mui/material'
import { DeleteOutline, Edit, SwapVert } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { setEditField } from '../../../redux/actions';
import { DatePicker } from '@mui/x-date-pickers';
import { inputClasses } from '../../../static/styles/theme';
export default function GenericInputField({component, sectionId, type, index, removable, editable, movable, handleRemove}) {

    const dispatch = useDispatch();

    const getInputField = () => {
        switch(component.type){
            case "input": return (
                <TextField required={component.required} size='small' fullWidth label={component.label} placeholder={component.placeholder} sx={inputClasses.shadowField} />
            )
            case "number": return (
                <TextField type="number" required={component.required} size='small' fullWidth label={component.label} placeholder={component.placeholder} sx={inputClasses.shadowField} />
            )
            case "date": return (
                // <TextField InputLabelProps={{shrink: true}} type="date" required size='small' fullWidth label={component.label} />
                <DatePicker
                    required={component.required}
                    label={component.label}
                    format="DD/MM/YYYY"
                    slotProps={{textField: {size: "small", fullWidth: true}}}
                    sx={inputClasses.shadowField}
                />
            )
            case "textarea": return (
                <TextField multiline rows={2} required={component.required} size='small' fullWidth label={component.label} placeholder={component.placeholder} sx={inputClasses.shadowField} />
            )
            case "searchTags": return (
                <TextField required={component.required} size='small' variant='outlined' fullWidth label={component.label} placeholder={component.placeholder} sx={inputClasses.shadowField} />
            )
            case "file": return (
                // <input type={"file"} />
                <TextField type={"file"} required={component.required} size='small' focused fullWidth label={component.label} sx={inputClasses.shadowField} />
            )
            case "select": return (
                <FormControl fullWidth size='small' required={component.required} sx={inputClasses.shadowField}>
                    <InputLabel>{component.label}</InputLabel>
                    <Select
                        label={component.label}
                    > 
                        {
                            component.values && component.values.map((op, i) => {
                                return <MenuItem value={op.value} key={i}>{op.label}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            )
            case "checkbox": return (
                <div style={{border: "1px solid gray", padding: "10px", borderRadius: "5px"}}>
                    <FormLabel component="legend" required={component.required}>{component.label}</FormLabel>
                    {
                        component.values.map((op, i) => {
                            return <FormControlLabel control={<Checkbox value={op.value} />} label={op.label} />
                        })
                    }
                
                </div>
            )
            case "radio": return (
                <div style={{border: "1px solid gray", padding: "10px", borderRadius: "5px"}}>
                    <FormControl>
                        <FormLabel required={component.required} id="demo-row-radio-buttons-group-label">{component.label}</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            {
                                component.values.map((op, i) => {
                                    return <FormControlLabel control={<Radio value={op.value} />} label={op.label} />
                                })
                            }
                        </RadioGroup>
                    </FormControl>
                </div>
            )
            default:
                <TextField required={component.required} size='small' fullWidth label={component.label} placeholder={component.placeholder} />
        }
        
    }
    return (
        <Grid container  spacing={1} sx={{mt: 1}}>
            <Grid item xs={8} sm={10}>
                {
                    getInputField()
                }
            </Grid>
            <Grid container item xs={4} sm={2} justifyContent="center" alignContent={"center"}>
                <Grid item direction={"row"}>
                    <Button color='inherit' variant="outlined" sx={{ p: 1, minWidth: "30px"}} disabled={!removable} onClick={()=> handleRemove(index, type)}>
                        <DeleteOutline/>
                    </Button>
                    <Button color='inherit' variant="outlined" sx={{ p: 1, minWidth: "30px", ml: 1}} disabled={!editable} onClick={()=> dispatch(setEditField(sectionId, type, index))}>
                        <Edit/>
                    </Button>
                    {/* <Button color='inherit' variant="outlined" sx={{ p: 1, minWidth: "30px", ml: 1}} disabled={true}>
                        <SwapVert/>
                    </Button> */}
                </Grid>
                {/* <ButtonGroup variant='outline'>
                    <IconButton size='small' disabled={!removable} onClick={()=> handleRemove(index)} sx={{border: "1px solid black"}}>
                        <DeleteOutline/>
                    </IconButton >
                    <IconButton size='small' disabled={!editable} onClick={()=> dispatch(setEditField(sectionId, type, index))} >
                        <Edit/>
                    </IconButton>
                    <IconButton size='small' disabled={!movable}>
                        <SwapVert/>
                    </IconButton>
                </ButtonGroup> */}
            </Grid>
        </Grid>
    )
}
//change