
import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import {
    TextField, Typography, Grid, Button, DialogContent, Dialog, Breadcrumbs, Paper, Card, CardHeader, CardContent, ButtonGroup,
    InputAdornment, IconButton, Autocomplete, Divider, CircularProgress, Tooltip, Box, Switch, FormLabel, FormControlLabel
} from '@mui/material'
import theme, { buttonClasses, cardClasses, inputClasses, textClasses } from '../../../../static/styles/theme'
import { Link } from 'react-router-dom'
import { Search } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import {
    clearConnectedMasterDetail, getAllTemplates, getSelectedTemplate, getCommanConfig,
    getShipmentConnectedMasterDetail, saveAsnEntry, generateUniqueSerialNumber, saveAsnEntryWithExcel, saveMultipleAsnBulkUpload
} from '../../../../redux/actions'
import { getCamelCaseText, showToast, dateTimeFormater, showErrorDynamicField, deepCopy_Object } from '../../../../services/functions'
import SelectImage from "../../../../../src/static/images/data_entry/select_location.svg"
import { getFieldsLayoutForEntry, getInputFieldForTableRow,GetInputFieldForTableRowComponent } from '../../../Components/TemplateInputConfiguration'
import { useForm } from 'react-hook-form'
import history from '../../../../services/history'
import { FETCH_ORDER_CONNECTED_MASTER_DETAIL, FETCH_SHIPMENT_CONNECTED_MASTER_DETAIL } from '../../../../redux/types'
import { handleDynamicChange, calculateTarget } from '../../../../services/formFunction'
import GenericLoader from '../../../Components/Generic/GenericLoader'
import CutomerSupplierAdd from '../../TMS/Addressbook/cutomerSupplierAdd'
import Table2 from './Table2'
import DetailDialog from './DetailDialog'
import GenericFileUpload from '../../../Components/Generic/GenericFileUpload'
import GenericTable from '../../../Components/CustomTable/GenericTable'
import GenericExcelLikeUI from '../../../Components/Generic/GenericExcelLikeUI'
import { getExcelStarterData, createExcelFileAndReturn } from '../../../../utils/services'
import { validateFieldsSingle, validateFieldsMultiple, convertExcelToArray, convertInExcel,truncateNumber,calculateFormulaHelper } from '../../../../services/functions'
import GenericSingleRangeComponent from '../../../Components/Generic/GenericSingleRangeComponent'
import { keyPressActions } from '../../../../services/constants'

const initialRows = 5;
export default function Asn() {

    const dispatch = useDispatch();
    const { userPermissions } = useSelector((state) => state.userPermission);
    const template = useSelector(state => state.inward.selectedTemplate);
    const allTemplates = useSelector(state => state.templates.allTemplates);

    // //("TEMPLATE", template);
    // 

    const [configDetail, setConfigDetail] = useState({});
    const [items, setItems] = useState([]);
    const [connectedCust_Sup_Detail, setConnectedCust_Sup_Detail] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [isDataSubmit, setIsDataSubmit] = useState(false);
    const [excelDataEntry, setExcelDataEntry] = useState({ action: false, file: "", viewTable: false });
    const [isUploadAsn, setIsUploadAsn] = useState(false)
    const { register, handleSubmit, watch, getValues, setValue, control, reset, formState: { errors } } = useForm();

    const isQuantityApplicable = template?.attributes?.some((entry) => entry?._id == "quantity")
    const totalQuantity = watch("quantity");
    const countTillNow = items.map((i) => Number(i.quantity)).reduce((a, b) => a + b, 0);
    const remainingItems = totalQuantity - countTillNow;

    const [errors2, setErrors2] = useState([])


    const allFields = (template && template.otherTemplate && template.otherTemplate.length > 0 && template.otherTemplate[0].attributes) ? template.otherTemplate[0].attributes : [];
    const validateFields = () => {
        if (excelDataEntry?.action) {
            if (!excelDataEntry?.file || excelDataEntry?.file?.length == 0) {
                return [{
                    "OrderDetail": {
                        name: "Order Detail Excel",
                        message: `Order Excel Detail is required`
                    }
                }]
            }
            else {
                return []
            }
        }
        else {
            return validateFieldsMultiple(allFields || [], items || [])
        }
    }

    const validateFieldsShipmentField = () => {

        let rowErrors = {};
        let row = getValues() || {}
        if (excelDataEntry?.action) {
            if (!excelDataEntry?.file || excelDataEntry?.file?.length == 0) {
                rowErrors["OrderDetail"] = {
                    name: "Order Detail Excel",
                    message: `Order Excel Detail is required`
                }
            }
        }
        else {
            if (!items || items.length == 0) {
                rowErrors["OrderDetail"] = {
                    name: "Order Detail",
                    message: `Order Detail is required`
                }
            }
        }
        let tempErrors = validateFieldsSingle(template?.attributes || [], row || {}) || {}
        rowErrors = { ...rowErrors, ...tempErrors }
        return rowErrors;
    }

    const excelHeaders = useMemo(() => {
        return [
            ...(template?.otherTemplate?.at(0)?.attributes || []),
            ...(template?.attributes || []).filter((field) => field._id !== "quantity"),
            ...(template?.connectedWithAddressbook || [])
        ].map((field) => field.label);
    }, [isUploadAsn, template]);

    const mandatoryExcelHeaders = useMemo(() => {
        return [
            ...(template?.otherTemplate?.at(0)?.attributes || [])?.filter(dl => dl?.required === true),
            ...(template?.attributes || [])?.filter(field => field?.required === true && field._id !== "quantity"),
            ...(template?.connectedWithAddressbook || [])?.filter(dl => dl?.required === true)
        ].map((field) => field.label);
    }, [isUploadAsn, template]);


    const checkItemSubmit = () => {
        let zeroCount = items.filter(it => !it.quantity);
        if (zeroCount.length > 0) {
            showToast("Item count cannot be 0", true)
            return false;
        }

        let newErrors = validateFields();
        setErrors2(newErrors);

        if (newErrors.filter(row => Object.keys(row).length != 0).length != 0) {
            showToast("Form Error: Please check for errors", true);
            return false;
        } else if (remainingItems != 0 && isQuantityApplicable) {
            showToast(`Form Error: Total items should be equal to ${countTillNow + remainingItems}, currently = ${countTillNow}`, true)
            return false;
        }
        return true;
    }

    const handleDraft = (data) => {
        handleSave({ ...data, drafted: true })
    }

    const handleUpcoming = (data) => {
        handleSave({ ...data, drafted: false },)
    }

    const searchMaster = async (value, option = {}) => {
        let tempMaster = option?.value && option || {};
        setConnectedCust_Sup_Detail(tempMaster);
    };



    const handleSave = (data) => {

        if (isUploadAsn) {
            const formData = new FormData();
            formData.append('file', submitExcelData());
            formData.append('template_id', template._id);
            formData.append('drafted', data?.drafted || false);
            setIsDataSubmit(true)
            dispatch(
                saveMultipleAsnBulkUpload(formData, (response) => {
                    setIsDataSubmit(false)
                    if (response) {
                        history.push("/dashboard/inventory/asn");
                    }
                })
            )
        }
        else {
            const shipmentValidation = validateFieldsShipmentField();

            if (Object.keys(shipmentValidation).length > 0) {
                showToast(shipmentValidation[0]?.message, true);
                return
            }

            if (excelDataEntry?.action) {

                if (!excelDataEntry?.file || excelDataEntry?.file?.length == 0) {
                    showToast(`Please Upload order detail excel file`, true)
                    return;
                }
                const formData = new FormData();
                formData.append('file', excelDataEntry?.file[0]);
                formData.append('template_id', template._id);
                formData.append('drafted', data?.drafted || false);
                formData.append('uploadType', "save");
                formData.append('shipmentData', JSON.stringify({ ...data }));
                if (connectedCust_Sup_Detail?.value) {
                    formData.append('supplierCustomerId', connectedCust_Sup_Detail?.value);
                }
                setIsDataSubmit(true)
                dispatch(
                    saveAsnEntryWithExcel(formData, (response) => {
                        setIsDataSubmit(false)
                        if (response) {
                            history.push("/dashboard/inventory/asn");
                        }
                    })
                )
            }
            else {
                let payload = {
                    template_id: template._id,
                    shipmentData: data,
                    drafted: data.drafted,
                }
                if (connectedCust_Sup_Detail?.value) {
                    payload["supplierCustomerId"] = connectedCust_Sup_Detail?.value;
                }

                const itemValidation = checkItemSubmit();
                if (itemValidation) {
                    if (template.connectedMaster && template.connectedMaster.itemMaster) {
                        let nonSearched = items.filter(i => i.itemDetail == null)
                        if (nonSearched.length > 0) {
                            showToast(`Please finish search of Product Id ${nonSearched[0].product_ID}`, true)
                            return;
                        }
                    }
                    payload['itemData'] = items
                    setIsDataSubmit(true)
                    // //(payload);
                    dispatch(
                        saveAsnEntry(payload, (response) => {
                            setIsDataSubmit(false)
                            if (response) {
                                history.push("/dashboard/inventory/asn");
                            }
                        })
                    )
                }
            }

        }
    }

    const handleClear = () => {
        if (isUploadAsn) {
            resetExcelData();
        } else {
            // setSelectedTemplate(null);
            let resetD = { ...getValues() };
            let rKeys = Object.keys(resetD);
            // let keys = Object.keys(getValues())
            for (let i = 0; i < rKeys.length; i++) {
                // setValue(keys[i], "");
                resetD[rKeys[i]] = ""

            };
            reset({
                ...resetD
            });
            setConnectedCust_Sup_Detail({});
            setItems([]);
            dispatch(
                clearConnectedMasterDetail()
            );
            setExcelDataEntry({ ...excelDataEntry, file: "", viewTable: false });
        }
    }
    
    const onKeyPressEnterTrigger = (e) => {
        if (e.key === keyPressActions["submit"]) {
            handleSubmit(handleUpcoming, showErrorDynamicField)();
        }
    };
    
    const resetExcelData = () => {
        let rows = getExcelStarterData(excelHeaders?.length || 0, initialRows)
        setItems(rows)
    }


    const submitExcelData = () => {
        let finalExcelFile = createExcelFileAndReturn(excelHeaders, items || [])
        return finalExcelFile;
    }



    useEffect(() => {
        dispatch(
            getAllTemplates({ type: "wms", templateType: "ASNTemplate", status: "published" }, (data) => {
                if (data && data.length == 1) {
                    setSelectedTemplate(data[0])
                }
            })
        )
        dispatch(getCommanConfig({ configType: "asnCreation" }, (data) => {
            setConfigDetail(data?.configDetail || {})
        }))
    }, []);

    useEffect(() => {
        // setPackages([]);
        handleClear()
        if (selectedTemplate != null) {

            dispatch(
                getSelectedTemplate({ template_id: selectedTemplate._id, isFormSubmission: true })
            )
            dispatch({ type: FETCH_SHIPMENT_CONNECTED_MASTER_DETAIL, payload: null })
            dispatch({ type: FETCH_ORDER_CONNECTED_MASTER_DETAIL, payload: null })
        }
    }, [selectedTemplate])

    return (
        <>
            <Typography sx={{ ...textClasses.cardTitle }}>Advance shipment Notice</Typography>

            <Breadcrumbs sx={{ mb: 1 }}>
                <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }}>
                    <Link style={{ color: theme.themeOrange, textDecoration: "none" }} to="/dashboard/inventory/asn">
                        Advance Shipment Notice ASN
                    </Link>
                </Typography>

                <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>Add New ASN</Typography>
            </Breadcrumbs>

            <Paper elevation={1} sx={{ ...cardClasses.basic, borderRadius: "8px", p: 2, width: "100%" }}>
                <Grid container>
                    <Grid container item sm={6} xs={6} alignContent="center">
                        <Typography sx={{ ...textClasses.t20n, mt: "5px" }}>Template: </Typography>
                        <Autocomplete
                            value={selectedTemplate}
                            onChange={(e, option) => {
                                setSelectedTemplate(option);
                                setExcelDataEntry({ action: false, file: "" })
                                handleClear()
                                setItems([])
                                setIsUploadAsn(false)
                            }}
                            getOptionLabel={(option) => getCamelCaseText(option.name)}
                            size='small'
                            options={allTemplates}
                            sx={{
                                ...inputClasses.shadowField,
                                minWidth: "220px",
                                m: 0,
                                ml: 2,
                                ".MuiInputBase-input": {
                                    fontSize: "14px",
                                    color: "#455A64"
                                }
                            }}
                            renderInput={(params) => <TextField sx={{ color: "#455A64", ...textClasses.normalText }} {...params} label="Select Template" />}
                        />
                    </Grid>
                    {
                        userPermissions?.permissions?.find(dl => dl.permissionId == "bulkASNUpload") &&
                        selectedTemplate != null &&
                        <Grid container item sm={6} xs={6} alignContent="center" justifyContent={"end"}>
                            <FormControlLabel
                                control={
                                    <>
                                        <Switch
                                            color="warning"
                                            checked={isUploadAsn == true}
                                            onChange={(event) => {
                                                setIsUploadAsn(event.target.checked)
                                                if (event.target.checked) {
                                                    resetExcelData();
                                                } else {
                                                    setItems([])
                                                }
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        <FormLabel sx={{ color: theme.themeOrange }}>Upload ASN</FormLabel>
                                    </>
                                }
                            />
                        </Grid>
                    }
                </Grid>
                <Divider sx={{ m: "0 5px 0 5px", mb: 2, mt: 2 }} />
                {
                    selectedTemplate != null && template
                        ?
                        <>
                            {
                                isUploadAsn == true ?
                                    <Grid container xs={12} justifyContent="center" alignItems="start"
                                        sx={{ p: 2 }}
                                    >
                                        <Box border={1} sx={{ padding: "10px", borderColor: 'grey.500', borderRadius: "4px", maxWidth: "100%" }}>
                                            <GenericExcelLikeUI
                                                headers={excelHeaders}
                                                mandatoryHeaders={mandatoryExcelHeaders}
                                                initialRows={initialRows}
                                                data={items || []}
                                                setData={setItems}
                                            />
                                        </Box>
                                    </Grid> : <>
                                        <ShipmentSection
                                            configDetail={configDetail}
                                            template={template}
                                            connectedCust_Sup_Detail={connectedCust_Sup_Detail}
                                            searchMaster={searchMaster}
                                            formHooks={{ register: register, control: control, setValue: setValue, errors: errors }}
                                            onKeyPressEnterTrigger={onKeyPressEnterTrigger}
                                            />
                                        <OrderSection
                                            template={template}
                                            items={items}
                                            setItems={setItems}
                                            totalQuantity={totalQuantity}
                                            countTillNow={countTillNow}
                                            remainingItems={remainingItems}
                                            errors={errors2}
                                            setExcelDataEntry={setExcelDataEntry}
                                            excelDataEntry={excelDataEntry}
                                            onKeyPressEnterTrigger={onKeyPressEnterTrigger}
                                            isQuantityApplicable={isQuantityApplicable}
                                        />
                                    </>
                            }

                            <Grid container direction={"row"} sx={{ mt: 2 }}>
                                <Button variant='outlined' size='small' sx={{ ...buttonClasses.outlined, color: theme.themeOrange, minWidth: "150px" }}
                                    disabled={isDataSubmit}
                                    onClick={() => handleClear()}
                                >
                                    Clear All
                                </Button>
                                <Button variant='contained' size='small' sx={{
                                    ...buttonClasses.small, minHeight: "40px",
                                    backgroundColor: theme.themeOrange, minWidth: "150px", ml: 2
                                }}
                                    disabled={isUploadAsn && !isDataSubmit ? false : (isDataSubmit || validateFields()?.length > 0 || (excelDataEntry?.action ? false : items?.length == 0))}
                                    onClick={handleSubmit(handleDraft, showErrorDynamicField)}
                                >
                                    Draft
                                </Button>
                                <Button variant='contained' size='small' sx={{
                                    ...buttonClasses.small, minHeight: "40px",
                                    backgroundColor: theme.themeOrange, minWidth: "150px", ml: 2
                                }}
                                    disabled={isUploadAsn && !isDataSubmit ? false : (isDataSubmit || validateFields()?.length > 0 || (excelDataEntry?.action ? false : items?.length == 0))}
                                    onClick={handleSubmit(handleUpcoming, showErrorDynamicField)}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </>
                        :
                        <Grid container direction={"column"} justifyContent="center" alignItems="center" sx={{ p: 4 }}>
                            <img src={SelectImage} />
                            <Typography sx={{ ...textClasses.cardTitle, mt: 2 }}>select a template first..</Typography>
                            <Typography sx={{ ...textClasses.t12n, textAlign: "center", maxWidth: "500px", mt: 2 }}>
                                Hey, you need to select a previously ASN template, to enter details for upcoming shipment.
                            </Typography>
                        </Grid>
                }


            </Paper>
        </>

    )
}


const ShipmentSection = ({
    template, connectedCust_Sup_Detail = {},
    searchMaster, formHooks, dimensions, configDetail = {},onKeyPressEnterTrigger,
}) => {

    const dispatch = useDispatch();
    const { userPermissions } = useSelector((state) => state.userPermission);

    const { register, control, setValue, errors } = formHooks;
    const [loader, setLoader] = useState("");
    const [templateHeader, setTemplateHeader] = useState(null);
    const [poGenerated, setPOGenerated] = useState(false);
    // //(" Template Header", templateHeader);
    const allFields = template?.attributes?.map((field, i) => {
        if (field._id == "purchaseOrderID" && poGenerated) {
            field.disabled = true
        }
        // Dynamically assign handleSubmit function to the keyPressEnter property
        field.keyPressEnter=onKeyPressEnterTrigger;
        return field;
    }) || [];
    const mandatoryFields = useSelector(state => state.canvas.components.attributes.mandatory).filter(
        (f) => f.templateType == template.templateType
    );
    const otherFields = allFields.filter(f => mandatoryFields.map(m => m._id).indexOf(f._id) < 0)


    const autoFillOnFocus = () => {
        const socketInputs = ["weight", "length", "breadth", "height"]
        if (dimensions) {
            for (let i = 0; i < otherFields.length; i++) {
                let compId = otherFields[i]._id
                if (socketInputs.indexOf(compId) >= 0 && dimensions[compId]) {
                    setValue(compId, dimensions[compId]);
                }
            }
        }
    }

    const layout = getFieldsLayoutForEntry(allFields, register, control, autoFillOnFocus, errors);
    const dispatchOrderNoGenerator = () => {
        setLoader("fetchPONumber");
        dispatch(generateUniqueSerialNumber({ templateId: template?._id || "",serialNumberType:"purchaseOrder"  }, ({ success = false, message, data = "" }) => {
            showToast(message, !success)
            if (success) {
                formHooks.setValue("purchaseOrderID", data);
                // setPOGenerated(true);
                setLoader("");
            }
        }))
    }

    return (
        <Grid container>
            <Grid item sm={12} xs={10}>
                <Card sx={{ borderRadius: "8px" }}>
                    <CardHeader
                        sx={
                            {
                                padding: "5px 15px",
                                borderRadius: "8px 8px 0px 0px",
                                backgroundColor: theme.themeGray,
                                color: "black",
                                boxShadow: "0px 1px 10px gray"
                            }
                        }

                        title={
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography sx={{ ...textClasses.normalText }}>1. Shipment Details</Typography>
                                {template?.attributes?.find(dl => dl?._id == "purchaseOrderID") &&
                                    <Button style={{ ...buttonClasses.lynkitOrangeEmpty }} onClick={() => dispatchOrderNoGenerator()} disabled={loader == "PO_Number" || poGenerated}>
                                        Generate {template?.attributes?.find(dl => dl?._id == "purchaseOrderID")?.label || "PO Number"}
                                    </Button>
                                }
                            </Box>
                        }
                    />
                    <CardContent>
                        <Grid container sx={{ mb: 1 }} spacing={2}>
                            {
                                layout.single.map((field, i) => {
                                    // if(field._id=="purchaseOrderID" && poGenerated){
                                    //     field.disabled = true
                                    // }
                                    return (
                                        <Grid container item xs={6} sm={4} justifyContent={"center"} alignItems="center" key={i}>
                                            {
                                                field
                                            }
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>

                        <Grid container spacing={2} sx={{ pt: 1 }}>
                            {
                                layout.multi.map((field, i) => {
                                    return (
                                        <Grid container item xs={6} sm={4} justifyContent={"center"} alignItems="center" key={i}>
                                            {
                                                field
                                            }
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>

                        {/* Customer detail section */}
                        {userPermissions?.permissions?.find(dl => dl.permissionId == "get_business_partners") && template?.connectedWithAddressbook?.at(0) &&
                            <CutomerSupplierAdd
                                connectedCust_Sup_Detail={connectedCust_Sup_Detail}
                                searchMaster={searchMaster}
                                type={"Customer"}
                                filter={configDetail?.filter || []}
                                header={configDetail?.header || []}
                                connectedWithAddressBook={template?.connectedWithAddressbook}
                                heading={configDetail?.heading || "Vender Detail"}
                            />}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

const OrderSection = ({
    template, errors, dimensions, items, setItems, totalQuantity, countTillNow, remainingItems,
    setExcelDataEntry, excelDataEntry, onKeyPressEnterTrigger, isQuantityApplicable
}) => {
    const dispatch = useDispatch();
    const { reset } = useForm();
    const { userPermissions } = useSelector((state) => state.userPermission);
    const mandatoryFieldsId = useSelector(state => state.canvas.components.itemTemplate.mandatory).filter(
        (f) => f.templateType == template.templateType
    ).map(f => f._id);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(7);
    const [modelTableData, setModelTableData] = useState([])
    const [dialogData, setDialogData] = useState(null);

    let allFields = template?.otherTemplate && template?.otherTemplate?.length ? template.otherTemplate?.at(0)?.attributes : [];
    const otherFields = allFields.filter(f => mandatoryFieldsId.indexOf(f._id) < 0)
    const modelTableRows = (modelTableData || [])?.map((row, i) => {
        let items = [];
        Object.keys(row || {}).map((key, j) => (
            items.push(
                <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: theme.themeOrange }}>
                    {row[key]}
                </Typography>
            )
        ));
        return items;
    });

    const viewExcel = async () => {
        try {
            const file = excelDataEntry.file ? excelDataEntry.file[0] : null;
            // console.log("file",file)
            if (!file) {
                setModelTableData([]);
                return;
            }

            let tempData = await convertExcelToArray(file)
            // console.log("tempData", tempData)
            setModelTableData(tempData)
        }
        catch (error) {
            setModelTableData([])
            // console.error("An error occurred:", error);
        }
    }

    const isDisableUpload = useMemo(() => {
        return !excelDataEntry?.action || !excelDataEntry?.file
    })

    const handleDeleteFile = () => {
        setExcelDataEntry({ ...excelDataEntry, file: "" })
        setModelTableData([])
    }


    const handleFileChange = useCallback((file) => {
        setExcelDataEntry(prev => ({
            ...prev,
            file: file.target.files,
        }))
    }, [setExcelDataEntry])

    const masterType = (() => {
        if (template) {
            if (template.connectedMaster && template.connectedMaster.itemMaster) {
                return "itemMaster";
            }
        }
        return null;
    })();


    const autoFillOnFocus = (index) => {
        if (dimensions) {
            let oldItems = [...items];
            for (let i = 0; i < otherFields.length; i++) {
                let compId = otherFields[i]._id
                if (["weight", "length", "breadth", "height"].indexOf(compId) >= 0 && dimensions[compId]) {
                    oldItems[index][compId] = dimensions[compId];
                }
            }
            setItems(oldItems)
        }
    }

    const convertDocsBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }
                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }

    const handleGenericChange = async (event, i, fieldId, option = {}) => {
        const targetField = allFields.find(f => f._id == fieldId);
        let oldItems = [...items];
        let oldObject = { ...oldItems[i] };
        if (targetField.type == "file") {
            const file = await convertDocsBase64(event.target.files[0]);
            oldObject[fieldId] = file;
        }
        else if (targetField.type == "checkbox") {
            // let oldValues = oldObject[fieldId] ? oldObject[fieldId] : [];
            // if (event.checked) {
            //     oldValues.push(event.value);
            // }
            // else {
            //     let pos = oldValues.indexOf(event.value);
            //     oldValues.splice(pos, 1)
            // }
            // oldObject[fieldId] = [...oldValues];
            oldObject[fieldId] = event?.target?.value || [];
        }
        else {
            oldObject[fieldId] = event.target.value;
        }
        if (targetField?.extraValue?.length) {
            const tempOptions= option && typeof option == "object" ? option : {};
            targetField.extraValue.map(dl => {
                if (typeof dl == "string") {
                    oldObject[dl] = tempOptions[dl] || ""
                }
                else if (typeof dl == "object" && dl?.label) {
                    oldObject[dl.label] = tempOptions[dl?.label] || ""
                }
            })
        }

        // console.log("targetField",targetField);
        if (["select"].includes(targetField.type) && targetField.setTargetValue) {
            oldObject["itemDetail"] = null
            if (event.target.value) {
                changeLoader(i, true)
                await handleDynamicChange(oldObject, targetField, { value: event.target.value || "" }, "itemDetail")
                if (!oldObject["itemDetail"] || Object.keys(oldObject["itemDetail"]).length == 0) {
                    oldObject["itemDetail"] = null
                }
                oldObject["loader"] = false
                // console.log(oldObject,"oldObject")
                for (let fields of allFields) {
                    let isValue = oldObject[fields?._id]
                    if (fields?.type == "checkbox") {
                        isValue = isValue?.length > 0
                    }
                    else {
                        isValue = !!isValue
                    }
                    if (!isValue) {
                        let tempValue = ""
                        if (fields?.setDefaultValue == true && fields?.defaultValueFunction && !fields?.renderFunction) {
                            tempValue = eval(fields?.defaultValueFunction || "")
                            if (fields?.type == "date") {
                                tempValue = dateTimeFormater(tempValue, fields?.format || "YYYY-MM-DD")
                            }
                        }

                        if (fields?.renderFunction) {
                            tempValue = eval(fields.renderFunction + "('" + JSON.stringify(oldObject) + "')")
                            if (fields?.type == "date") {
                                tempValue = dateTimeFormater(tempValue, fields?.format || "YYYY-MM-DD")
                            }
                        }
                    }
                }
            }
            else {
                calculateTarget(oldObject, targetField, {})
            }
        }
        oldItems[i] = { ...oldObject };
        setItems([...oldItems]);
    }

    const getTableHeader = (headerType) => {
        let base = ["Quantity", <Box sx={{ minWidth: "200px" }}>Item Details</Box>]
        if (masterType == "itemMaster") {
            base.push("Detail", "Current Stock")
        }
        let otherHeader = allFields.filter(f => ["product_ID", "quantity"].indexOf(f._id) < 0).map((of) => {
            let label = of.label;
            if (headerType == "excel" && ["select", "radio", "checkbox"].indexOf(of.type) >= 0) {
                label = `${label} (${of.values.map(o => o.value).join(",")})`
            }
            return label
        })
        return [...base, ...otherHeader];
    }

    const getItemRow = (i) => {
        let item = items[i];
        // console.log("item",item)

        const adornment = (
            <InputAdornment position='end'>
                <IconButton
                    onClick={() => handleSearchButton(i)}
                    disabled={item.product_ID ? item.product_ID.length < 3 : "" || item.itemDetail != null}
                >
                    <Search />
                </IconButton>
            </InputAdornment>
        )

        let base = [
            (
                <GenericSingleRangeComponent
                    startRange={item.quantity || null}
                    endRange={Number(remainingItems || 0) + Number(item?.quantity || 0)}
                    getOnChange={(countValue) => handleCountChange(countValue, i)}
                    endRangeInfinity={!isQuantityApplicable ? true : false}
                    valueType={"decimal"}
                // valueType={item?.precisionType == "decimal" ? "decimal" : "integer"}
                />
            ),
            <GetInputFieldForTableRowComponent
                component={{...(allFields.find(f => f._id == "product_ID") || {}),keyPressEnter:onKeyPressEnterTrigger}}
                items={items}
                setItems={setItems}
                index={i}
                handleChange={handleGenericChange}
                handleFocus={autoFillOnFocus}
                errors={errors}
                showLabelForMulti={false}
                inputProps={masterType == "itemMaster" ? adornment : null}
                disabled={(item && !!item.itemDetail)}
            />
            // getInputFieldForTableRow(allFields.find(f => f._id == "product_ID") || {}, items, i, handleGenericChange, autoFillOnFocus, errors, false, masterType == "itemMaster" ? adornment : null, (item && !!item.itemDetail))
        ]
        if (masterType == "itemMaster") {
            base.push(
                item.loader
                    ?
                    <GenericLoader />
                    :
                    <Typography sx={{ ...textClasses.boldText, color: theme.themeOrange, cursor: "pointer" }} onClick={() => handleOpenDetail(i)}>
                        {
                            item.itemDetail != null
                                ?
                                "View Details"
                                :
                                "No data yet"
                        }
                    </Typography>
            )
            base.push(
                item.loader
                    ?
                    <GenericLoader />
                    :
                    <Typography sx={{ ...textClasses.boldText, color: theme.themeOrange }}>
                        {
                            item.itemDetail != null
                                ?
                                <Button
                                    sx={{
                                        p: 0.3,
                                        cursor: "text !important",
                                        ...buttonClasses.small,
                                        color: theme.themeGreen,
                                        backgroundColor: theme.themeLightGreen,
                                        border: `1px solid ${theme.themeGreen}`,
                                        "&:hover": { backgroundColor: theme.themeLightGreen },
                                    }}
                                >
                                    {truncateNumber(item?.itemDetail?.currentStock) || 0} {item?.itemDetail?.formData?.UOM || ""}
                                </Button>
                                :
                                "No data yet"
                        }

                    </Typography>
            )
        }
        const others = allFields.filter(f => ["product_ID", "quantity"].indexOf(f._id) < 0).map((of) => {
            let comp = { ...of };

            if (comp._id == "UOM" && masterType != null && items[i][comp._id] != null && items[i][comp._id] != "") {
                comp.disabled = true;
            }
            else {
                if (item?.disabled?.length > 0 && item?.disabled?.includes(of._id)) {
                    comp.disabled = true
                }
                else {
                    comp.disabled = false;
                }
            }
            return (
                <GetInputFieldForTableRowComponent
                    component={{...comp,keyPressEnter:onKeyPressEnterTrigger}}
                    items={items}
                    setItems={setItems}
                    index={i}
                    handleChange={handleGenericChange}
                    handleFocus={autoFillOnFocus}
                    errors={errors}
                    showLabelForMulti={false}
                    inputProps={null}
                    disabled={comp.disabled==true}
                />
                // getInputFieldForTableRow(comp,items, i, handleGenericChange, autoFillOnFocus, errors, false)
            )
        })

        return [...base, ...others]
    }

    const header = getTableHeader();
    const rows = items.map((item, i) => {
        return getItemRow(i);
    })

    // const layout = getFieldsLayoutForEntry(otherFields, register, control, autoFillOnFocus, errors);

    const handleAddRow = () => {
        // let row = {
        //     itemId: "",
        //     itemDetail: null,
        //     quantity: 1,
        //     loader: false
        // }
        // setItems([...items, row])

        let fieldsData = {}
        let fields = allFields.filter(f => f._id != "quantity")
        for (let i = 0; i < fields.length; i++) {
            if (fields[i]?.type == "checkbox") {
                fieldsData[fields[i]._id] = []
            }
            else if (fields[i]?.type == "file") {
                fieldsData[fields[i]._id] = null;
            }
            else {
                fieldsData[fields[i]._id] = ""
            }
        }
        let newRow = {
            quantity: isQuantityApplicable ? remainingItems : 1,
            itemDetail: null,
            loader: false,
            ...fieldsData
        }
        setItems([...items, newRow]);
        reset()
    }

    const handleRemoveRow = (i) => {
        let oldItems = [...items];
        oldItems.splice(i, 1)
        setItems(oldItems);
    }

    const handleEditRow = (i) => {
        let oldItems = [...items];
        oldItems[i] = {
            ...oldItems[i],
            itemDetail: null
        }
        setItems(oldItems)
    }

    const handleCountChange = (countValue, i) => {
        let oldItems = [...items];
        let oldObject = { ...(oldItems[i] || {}) }
        if (countValue <= 0) {
            oldObject.quantity = 0
        }
        else {
            if (!isQuantityApplicable) {
                oldObject.quantity = truncateNumber(countValue, 3, true);
            } else {
                let maxCap = truncateNumber(totalQuantity - (countTillNow - Number(oldObject.quantity)), 3, true,);
                oldObject.quantity = Math.min(maxCap, countValue)
            }
        }
        let formulaFields = allFields?.find(dl => dl?._id == "quantity")?.formulaFields || []
        if (formulaFields?.length > 0) {
            calculateFormulaHelper(oldObject || {}, formulaFields, oldObject, true)
        }
        oldItems[i] = oldObject
        setItems(oldItems);
    }
    const changeLoader = (i, status) => {
        let oldItems = [...items]
        oldItems[i] = {
            ...oldItems[i],
            loader: status
        }
        setItems(oldItems);
    }

    const handleSearchButton = (i) => {
        let id = items[i].product_ID;
        let payload = {
            template_id: masterType ? template.connectedMaster.itemMaster : "",
        }
        if (masterType) {
            payload["SKU_ID"] = id;
        }

        changeLoader(i, true)
        dispatch(
            getShipmentConnectedMasterDetail(masterType, payload, (data) => {
                if (data) {
                    let oldItems = [...items]
                    oldItems[i] = {
                        ...oldItems[i],
                        itemDetail: data,
                        loader: false,
                        UOM: data.formData.UOM
                    }
                    setItems(oldItems);
                }
                else {
                    let oldItems = [...items]
                    oldItems[i] = {
                        ...oldItems[i],
                        itemDetail: null,
                        loader: false
                    }
                    setItems(oldItems);
                }
            })
        )
    }

    const handleOpenDetail = (i) => {
        setDialogData(items[i].itemDetail)
    }

    const handleCloseDetail = () => {
        setDialogData(null)
    }

    useEffect(() => {
        if (allFields?.length && !isQuantityApplicable) {
            handleAddRow();
        }
    }, [allFields])

    return (
        <Grid container sx={{ mt: 2 }}>
            <Grid item sm={12} xs={10}>
                <Card sx={{ borderRadius: "8px" }}>
                    <CardHeader
                        sx={
                            {
                                padding: "5px 15px",
                                borderRadius: "8px 8px 0px 0px",
                                backgroundColor: theme.themeGray,
                                color: "black",
                                boxShadow: "0px 1px 10px gray"
                            }
                        }

                        title={
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 2 }}>
                                <Typography sx={{ ...textClasses.normalText }}>2. Order Details</Typography>
                                {userPermissions?.permissions?.find(dl => dl.permissionId == "bulkASNItemUpload") && template?._id
                                    ?
                                    <Grid container item sm={6} xs={6} alignContent="center" justifyContent={"end"}>
                                        <FormControlLabel
                                            control={
                                                <>
                                                    <Switch
                                                        color="warning"
                                                        checked={excelDataEntry?.action == true}
                                                        onChange={(event) => {
                                                            setExcelDataEntry({ action: event.target.checked, file: "" })
                                                            setItems([])
                                                            setModelTableData([])
                                                        }}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                    <FormLabel sx={{ color: theme.themeOrange }}>Upload Order Detail Excel</FormLabel>
                                                </>
                                            }
                                        />
                                    </Grid>
                                    : ""
                                }

                            </Box>
                        }
                    />
                    <CardContent>
                        {
                            excelDataEntry?.action
                                ?
                                <>
                                    <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mb: -1 }}>
                                        <GenericFileUpload
                                            payload={excelDataEntry}
                                            previewAvailable={true}
                                            onChange={handleFileChange}
                                            onUpload={false}
                                            onDelete={handleDeleteFile}
                                            handleDownload={() => convertInExcel(allFields || [], 'ASN_Sample_File')}
                                            isDisableUpload={isDisableUpload}
                                            viewExcel={viewExcel}
                                        />
                                    </Grid>
                                    {modelTableRows?.length > 0 &&
                                        <Box sx={{ mt: 3 }}>
                                            <GenericTable
                                                header={template?.otherTemplate?.at(0)?.attributes?.map(dl => dl.label) || []}
                                                rows={modelTableRows?.length > 0 && modelTableRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || []}
                                                pageCount={modelTableRows.length}
                                                pageNumber={page}
                                                handleChangePage={(event, newPage) => {
                                                    setPage(newPage);
                                                }}
                                                handleChangeRowsPerPage={(event) => {
                                                    setRowsPerPage(+event.target.value);
                                                    setPage(0);
                                                }}
                                                rowsPerPage={rowsPerPage}
                                            />
                                        </Box>
                                    }
                                </>
                                :
                                <>
                                    <Grid container>
                                        <Table2
                                            header={header}
                                            rows={rows}
                                            remainingItems={remainingItems}
                                            handleAddRow={handleAddRow}
                                            handleRemoveRow={handleRemoveRow}
                                            handleEditRow={handleEditRow}
                                            isQuantityApplicable={isQuantityApplicable}

                                        />
                                    </Grid>
                                    <Typography sx={{ ...textClasses.boldText, color: theme.themeOrange, mt: 2, ml: 2 }}>
                                        Total Order Quantity: {truncateNumber(Number(countTillNow || 0) || 0)}
                                    </Typography>
                                    {dialogData && <DetailDialog
                                        data={dialogData}
                                        onClose={handleCloseDetail}
                                    />}
                                </>
                        }
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )

}