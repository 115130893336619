import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import {Grid, Typography,Box,} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import Navbar from "../Navbar"
import DrawerItemList from './DrawerItems';
import Cookies from 'js-cookie';
import {
  GridViewOutlined, CategoryOutlined, Inventory2Outlined, BluetoothOutlined, DnsOutlined,HomeOutlined,
  ListAltOutlined, Grid3x3Outlined, SellOutlined, ManageAccountsOutlined, Settings,Summarize,AirlineStopsOutlined,
  HomeTwoTone,BusinessRounded,MenuOpenOutlined
} from '@mui/icons-material'
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer, openDrawer, openCloseGridHeatmap } from '../../../redux/actions';
import theme, { textClasses } from '../../../static/styles/theme';
import MiniLogo from "../../../static/images/drawer/mini_logo.svg"
import Logo from "../../../static/images/login/logo-white.svg"

const drawerWidth = 200;

let tempIcon={
  GridViewOutlined,CategoryOutlined,Inventory2Outlined,BluetoothOutlined,Summarize,AddShoppingCartOutlinedIcon,
  DnsOutlined,ListAltOutlined,Grid3x3Outlined,SellOutlined,ManageAccountsOutlined,Settings,AirlineStopsOutlined,
  HomeOutlined,HomeTwoTone,BusinessRounded,AssistantDirectionIcon
}
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});


const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(4)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(6)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


export default function MiniDrawer(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { userPermissions,sidebarList=[],configDetail={} } = useSelector((state) => state.userPermission);
  const [filteredSidebarItems, setFilteredSidebarItems] = useState([])
  const dispatch = useDispatch();

  const [expandList, setExpandList] = useState(null);
  const centralTheme = Cookies.get("centralTheming") ? JSON.parse(Cookies.get("centralTheming")) : {};

  const handleExpandList = (type, title) => {
    // //(type, title);
    setExpandList(type == 1 ? title : null);
  }

  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch(openDrawer());
    dispatch(openCloseGridHeatmap({close:true}));
    
  };

  const handleDrawerClose = () => {
    setOpen(false);
    handleExpandList(0)
    dispatch(closeDrawer())
    dispatch(openCloseGridHeatmap({close:true}));

  };

  const generateSideBar=(list=[])=>{
    // //("list",list);
    return list.map((dl,index)=>{
        let obj={...dl}
        const IconComponent = tempIcon[obj["icon"]]
        if(IconComponent){
          obj["icon"]=<IconComponent key={index} fontSize={dl.fontSize || "small"}/>
        }
        else{
          obj["icon"]=null
        }
        const ActiveIconComponent = tempIcon[obj["activeIcon"]]
        if(ActiveIconComponent){
          obj["activeIcon"]=<ActiveIconComponent key={index} fontSize={dl.fontSize || "small"}/>
        }
        else{
          obj["activeIcon"]=null
        }
        
        return obj
    })
  }

  useEffect(() => {
    if (userPermissions?.permissions?.length > 0) {
      let output=generateSideBar(sidebarList)
      // let output=controlSidebar(userPermissions?.permissions || [])
      setFilteredSidebarItems(output)
    }
    else {
      setFilteredSidebarItems([])
    }
  }, [userPermissions,sidebarList])


  // console.log("configDetail",configDetail);
  return (
    <>
      <CssBaseline />
     { (configDetail?.sidebar?.hamsburger) && 
        <Typography
          style={{ zIndex: 10000, cursor: "pointer", position: "fixed", top: "6px", left: "6px",transitions:"0.3s all"}}
          onClick={() => setOpen(!open)}
        >
          <MenuOpenOutlined/>
        </Typography>}
     {((configDetail?.sidebar?.visible) || open) && 
     <Drawer
        sx={{
           
          width: theme.spacing(7) * 7,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: theme.spacing(7) * 7,
            boxSizing: 'border-box',
            
          },
          transition: '0.3s ease-in-out', // Transition for smooth opening and closing
          zIndex:1305
        }}
        variant="permanent"
        open={open}
        {...(!configDetail?.sidebar?.hamsburger?{
            onMouseEnter: handleDrawerOpen,
            onMouseLeave:handleDrawerClose
        }:{onMouseLeave:handleDrawerClose})}
        
      >
          {(configDetail?.sidebar?.hamsburger) ?"":
            <DrawerHeader sx={{ padding: "0!important" }}>
              <IconButton onClick={handleDrawerClose}>
                {!open ?
                  <ChevronRightIcon />
                  :
                  <ChevronLeftIcon />
                }
              </IconButton>
            </DrawerHeader>
          }
          <List sx={{
            overflowY: "auto", overflowX: "hidden", ...(configDetail?.sidebar?.hamsburger ? {
              position: "relative",
              top: "60px",
            } : {}),
          }}
         
          >
          {/* Add your drawer items here */}
              <DrawerItemList
                items={filteredSidebarItems}
                open={open}
                expandList={expandList}
                handleExpandList={handleExpandList}
                setOpen={setOpen} {...props}
              />
        </List>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ textAlign: 'center', p: 1 }}>
          {open ? (
             <Grid container item alignItems={"center"} justifyContent={"center"}>
             <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: "#827878" ,mr:1}}>Powered by </Typography>
             <img src={centralTheme?.sidebarLogoUrl || Logo} height="29" />
           </Grid>
            // Display logo or text
          ) : (
            <Grid container item alignItems={"center"} justifyContent={"center"}>
            <img src={centralTheme?.sidebarShortLogoUrl || MiniLogo} height="29" />
          </Grid>
            // Display minimized logo or text
          )}
        </Box>
      </Drawer>}
      {/* Navbar content here */}
     {(configDetail?.header?.visible) &&  <Navbar open={open} handleDrawerOpen={handleDrawerOpen} drawerWidth={drawerWidth} />}
    </>
  );
}