import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { multiDateToISOConvertor } from "../../../Components/Generic/GenericMultiDateRange";
import {  getWarehouseOperationCustomDashboard } from "../../../../redux/actions/CustomDashboard";
import { Box, Grid } from "@mui/material"
import { component } from "../../MainDashboard/utils"
import TypeAndSearch from "../../../Components/TypeAndSearch"
import DateRangeFilter from "../WidgetComponents/DateRangeFilter";
import AnalyticsTypeWidget from "../WidgetComponents/Analytics";
import GenericLoader from "../../../Components/Generic/GenericLoader";



const convertToDateRange = (value="7d") => {
    const now = new Date(); // Current date
    let dateFrom = new Date(now); // Start date
    let dateTo = new Date(now); // End date

    switch (value) {
        case "1d":
            dateFrom.setDate(now.getDate() - 1);
            break;
        case "7d":
            dateFrom.setDate(now.getDate() - 7);
            break;
        case "1m":
            dateFrom.setMonth(now.getMonth() - 1);
            break;
        case "1y":
            dateFrom.setFullYear(now.getFullYear() - 1);
            break;
        default:
            throw new Error("Invalid value");
    }

    return {
        dateFrom: dateFrom.toISOString(),
        dateTo: dateTo.toISOString()
    };
};

const WarehouseJobComponent = ({ item, styleView ,warehouseDetails }) => {
    const dispatch = useDispatch()
    const [selectedRange, setSelectedRange] = useState("7d");
    const [dateFilter, setDateFilter] = useState([]);
    const [updatedItem, setUpdatedItem] = useState(item || []);
    const [loader, setLoader] = useState(true);
    const [dataLoader,setDataLoader]=useState(true)
    const [nameFilter, setNameFilter] = useState({});
    const handleNameChange = (e, option) => {
        setNameFilter(option || {});
        getDataDateRangeWise("","",option?._id)
    };

    const handleSelection = (value) => {
        setSelectedRange(value);
        let dates
        if (value !== 'dateRange') {
            dates = convertToDateRange(value)
        }
        setDateFilter([])
        getDataDateRangeWise(dates?.dateFrom, dates?.dateTo)
    };

    const handleDateChange = (newDates) => {
        setDateFilter(newDates);
        let datarry;

        if (newDates.length >= 2) {
            datarry= multiDateToISOConvertor(newDates)
        }
        if (datarry?.length >= 2) {
            getDataDateRangeWise(datarry[0], datarry[1])
        }

    };
    const getDataDateRangeWise = (dateFrom = '', dateTo = '',jobTypeId="") => {
        setDataLoader(true)
        let warehouseIds= warehouseDetails?.map(item => item._id)
        let payload = {
            wareHouse:warehouseIds || [],
            dateFrom: dateFrom,
            dateTo: dateTo
        }
        
        payload.jobTypeId = jobTypeId ?? nameFilter?._id

        dispatch(getWarehouseOperationCustomDashboard(payload, (data) => {
            const transformedArray = data?.data?.map(obj => ({
                read: false,
                category: obj?.date,
                created: obj?.created,
                completed: obj?.completed
            }));
            setUpdatedItem((prev) => ({
                ...prev,
                values: transformedArray
            }))
            setLoader(false)
            setDataLoader(false)
        }))
    }
    useEffect(() => {
        getDataDateRangeWise()
        setNameFilter({})
    }, [warehouseDetails, dispatch])
    return <>
    {
      !loader ? (
        <Box>
          <AnalyticsTypeWidget item={updatedItem} isDefaultDashboard={true} styleView={styleView} dataLoader={dataLoader}>
            <TypeAndSearch
              data-testid="jobNameFilter"
              component={component({ type: "jobName" })}
              handleChange={(e, option) => {
                handleNameChange(e, option);
              }}
              label={nameFilter?.label || ""}
              value={nameFilter?.value || ""}
              sx={{ width: "100%" }}
            />
            
            <DateRangeFilter
              dateFilterOption={item?.dateFilterOption}
              selectedRange={selectedRange}
              dateFilter={dateFilter}
              handleSelection={handleSelection}
              handleDateChange={handleDateChange}
            />
          </AnalyticsTypeWidget>
        </Box>
      ) : (
        <Grid item>
          <GenericLoader />
        </Grid>
      )
    }
  </>
  
}

export default WarehouseJobComponent;