import React, { useEffect, useRef, useState } from 'react'
import { Grid, Paper, Typography, Divider, TextField, Button, IconButton, Modal, Card, Select, FormControlLabel, Checkbox, ButtonGroup, CircularProgress, Tooltip, ImageList, ImageListItem, Dialog, DialogContent, Box, FormControl } from '@mui/material'
import theme, { textClasses, cardClasses, inputClasses, buttonClasses  } from '../../../../../static/styles/theme'
import { getInputFieldForTableRow } from '../../../../Components/TemplateInputConfiguration';
import {  showToast } from '../../../../../services/functions';
import { dataReadFunction } from '../../../../../services/functions';

export default function DetailDialog({asn, spiType, sectionKey, allFields, objects, setObjects, oIndex, totalObjectCount, mappingLocations, handleCloseModal, handleFocus, bundleType}){

    let completeFields = allFields;
    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});

    const fetchedFromItemMaster = objects && objects.length > 0 && oIndex != null ?(objects?.at(oIndex)?.fetchedFromItemMaster  || false): false
    const itemMasterDetail = objects && objects.length > 0 && oIndex != null ? objects?.at(oIndex)?.itemMasterDetail || {} : {}
    let formData=itemMasterDetail?.formData || {}
    let tableHeader=itemMasterDetail?.tableHeader || {}
    const disabledElement = objects && objects.length > 0 && oIndex != null ?(objects?.at(oIndex)?.disabled  || []): []


    const handleGenericChange = (event, i, fieldId,option={}) => {
        const targetField = completeFields.find(f => f._id == fieldId) || {};
        let oldObject = {...fields}
        if(targetField.type == "file"){
            oldObject[fieldId] = event.target.files;
        }
        else if(targetField.type == "checkbox") {
            // let oldValues = oldObject[fieldId] ? oldObject[fieldId] : [];
            // if(event.checked){
            //     oldValues.push(event.value);
            // }
            // else{
            //     let pos = oldValues.indexOf(event.value);
            //     oldValues.splice(pos, 1)
            // }
            // oldObject[fieldId] = [...oldValues];
            oldObject[fieldId] = event?.target?.value || [];
        }
        else{
            
            oldObject[fieldId] = event.target.value;
        }
        if (targetField?.extraValue?.length) {
            const tempOptions= option && typeof option == "object" ? option : {};
            targetField.extraValue.map(dl => {
                if (typeof dl == "string") {
                    oldObject[dl] = tempOptions[dl] || ""
                }
                else if (typeof dl == "object" && dl.label) {
                    oldObject[dl.label] = tempOptions[dl.label] || ""
                }
            })
        }
        setFields(oldObject);
    }

    const validateFields = () => {
        let newErrors = {};
        const keys = Object.keys(fields);
        //// //('keys', keys);
        for (let i = 0; i < keys.length; i++) {
            const fieldId = keys[i];
            const element = fields[fieldId];
            const targetField = completeFields.find(f => f._id == fieldId) || {};
            if(targetField){
                if(targetField.required && (!element || element == "")){
                    // //// //(`${targetField.label} failing required test with value ${element}`);

                    newErrors[fieldId] = {
                        name: targetField.label,
                        message: `${targetField.label} is required`
                    }
                }
                else if(targetField.pattern && !(new RegExp(targetField.pattern)).test(element)){
                    // //// //(`${targetField.label} failing pattern test ${targetField.pattern} with value ${element}`);
                    newErrors[fieldId] = {
                        name: targetField.label,
                        message: `Invalid value for ${targetField.label}`
                    }
                }
            }
        }
        return newErrors;
    }

    const handleSave = () => {
        let newErrors = validateFields();
        if(Object.keys(newErrors).length == 0){
            let oldObjects = [...objects];
            oldObjects[oIndex] = {...fields};
            let newTotalCount;
            if((spiType == "SPI" || spiType == "shipmentPackageOnly") && bundleType == "packageToItem"){
                newTotalCount = oldObjects.length;
            }
            else{
                newTotalCount = oldObjects.map((i) => Number(i.count)).reduce((a, b) => a + b, 0);
            }
            if(newTotalCount > totalObjectCount){
                showToast("Total count cannot exceed Shipment quantity " + totalObjectCount, true);
            }
            else{
                setObjects(oldObjects);
                handleCloseModal();
            }
        }
        else{
            showToast("Form Error: Please check for errors", true);
        }
        setErrors(newErrors);
    }

    useEffect(() => {
        if(oIndex != null){
            setFields(objects[oIndex]);

            if(spiType == "shipmentPackageOnly" || spiType == "shipmentItemOnly") {
                if(completeFields.map(f => f._id).indexOf("mappingArea") < 0){
                    let field = {
                        type: "select",
                        label: "Mapping Area",
                        required: true,
                        _id: "mappingArea",
                        values: mappingLocations || [],
                        defaultValue: "PUT IT ANY WHERE"
                    }
                    completeFields.push(field);
                }
                
            }
        }
    }, [oIndex])
    return (
        <Dialog 
            open={oIndex != null ? true : false}
            onClose={handleCloseModal}
            scroll={"paper"}
            PaperProps={{sx: {width: "100%"}}}
            maxWidth="md"
        >
            {
                oIndex != null && 
                <DialogContent dividers>
                    <Typography sx={{ ...textClasses.cardTitle }}>
                        Edit {sectionKey} details
                    </Typography>
                    <Grid container spacing={2} sx={{mt: 1}}>
                        {
                            completeFields.map((f, i) => {
                                let of = {...f};
                                //// //("Fields", fields);
                                if(of._id == "USN_Value"){
                                    of.disabled = fields.count > 1
                                }
                                else if(asn && ["SKU_Number", "UOM", "count", "packageName"].indexOf(of._id) >= 0 && fields[of._id] != null && fields[of._id] != ""){
                                    of.disabled = true;
                                }
                                else if(asn && ["SKU_Name", "packageDescription"].indexOf(of._id) >= 0 && fields.masterType && fields[of._id] != null && fields[of._id] != ""){
                                    of.disabled = true;
                                }
                                else if(fetchedFromItemMaster && disabledElement.indexOf(of._id) >= 0){
                                    of.disabled = true;
                                }
                                else{
                                    of.disabled = f.disabled || false;
                                }

                                // let isVisible=true
                                
                                // console.log(fetchedFromItemMaster,disabledElement)
                                if(of._id=="mappingArea"){
                                    if(fields?.masterType && fields?.restrictedMappingArea?.length>0){
                                        //(component?.values?.find(dl=>dl.isDefault) || {}
                                        let restrict=fields?.restrictedMappingArea?.map(dl=>({label:dl,value:dl}))
                                        of.values=[...restrict]
                                        of.label=of.label + " (Restricted)"
                                        // isVisible=false
                                    }
                                }
                               
                                return (
                                    <Grid item sm={6} xs={12}>
                                        {
                                            getInputFieldForTableRow(of, [fields], 0, handleGenericChange, handleFocus, [errors], true)
                                        }
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    {
                        !asn && fetchedFromItemMaster &&
                        <Paper elevation={1} sx={{p: 2, m: 2}}>
                            <Grid container>
                                { tableHeader?.length>0 && tableHeader?.filter(f => (typeof dataReadFunction(f,formData) == "string") && dataReadFunction(f,formData).toString().length < 25).map((f, i) => {
                                        return (
                                            <Grid container item sm={6} xs={6} alignItems="center" key={i} sx={{mb: 2}}>
                                                <Grid container>
                                                    <Grid container item sm={4} xs={6}>
                                                        <Typography sx={{...textClasses.normalText, color: "#827878"}}>{f.label} : </Typography>
                                                    </Grid>
                                                    <Grid container item sm={8} xs={6}>
                                                        <Typography sx={{...textClasses.boldText, ml: 2}}>{dataReadFunction(f,formData)}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                            <Grid container>
                                {
                                    tableHeader?.length>0 && tableHeader?.filter(f => (typeof dataReadFunction(f,formData)!= "string") || (dataReadFunction(f,formData) && dataReadFunction(f,formData).toString().length > 25)).map((f, i) => {
                                        return (
                                            <Grid container item sm={6} xs={6} alignItems="center" key={i} sx={{mb: 2}}>
                                                <Grid container>
                                                    <Grid container item sm={4} xs={6}>
                                                        <Typography sx={{...textClasses.normalText, color: "#827878"}}>{f.label} : </Typography>
                                                    </Grid>
                                                    <Grid container item sm={8} xs={6}>
                                                        <Tooltip title={dataReadFunction(f,formData)}>
                                                            <Typography sx={{...textClasses.boldText, ml: 2}}>{dataReadFunction(f,formData).toString().substring(0, 100)}</Typography>
                                                        </Tooltip>
                                                        
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Paper>
                    }
                    <Grid container direction={"row"} sm={12} xs={12} sx={{mt: 2}}>
                        <Button variant='outlined' size='small' sx={{...buttonClasses.outlined, color: theme.themeOrange, minWidth: "150px"}} onClick={handleCloseModal}>
                            Close
                        </Button>
                        <Button variant='contained' size='small' sx={{...buttonClasses.small, minHeight: "40px", backgroundColor: theme.themeOrange, minWidth: "150px", ml: 2}} onClick={() => handleSave()}>
                            Submit
                        </Button>
                    </Grid>
                </DialogContent>
            }
        </Dialog>
    )
}