import React from 'react';
import Login from "../../views/Landing/Login"


export default function App() {

  return (
      <div>
        {/* <Navbar logo={logo}/> */}
        <Login />
        {/* <Footer/> */}
      </div>

  )
}
