import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDefaultCustomDashboard } from "../../../../redux/actions/CustomDashboard";
import { Typography, Grid, TableContainer, Table, TableBody, TableRow, TableCell, Paper, Breadcrumbs, Button } from "@mui/material";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import SelectImage from "../../../../static/images/no_data_found.svg";
import theme, { buttonClasses, textClasses } from "../../../../static/styles/theme";
import OperationCountComponent from "./OperationCountComponent";
import InventoryWeightComponent from "./InventoryWeightComponent";
import WelcomeComponent from "./WelcomeComponent";
import InventoryCountComponent from "./InventoryCountComponent";
import WarehouseCountComponent from "./WarehouseCountComponent";
import WarehouseJobComponent from "./WarehouseJobComponent";
import WarehouseStockCount from "./WarehouseStockCount";
import JobStatusTable from "./JobStatusTable";
import AreaUtilizationComponent from "./AreaUtilizationComponent";
import MultiSelectAll from "../../MainDashboard/MultiSelect";
import { getCompany } from "../../../../redux/actions";
import history from "../../../../services/history";



export default function CustomDashboardLanding({ isDefaultDashboard = false }) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [tableData, setTableData] = useState({})
  const [condition, setCondition] = useState(true);
  const { userPermissions } = useSelector((state) => state.userPermission);
  let permissions = userPermissions || {};

  const getTextBoxInputValue = useCallback((input) =>
    input.map((itm) => itm._id)
  );
  const [currentSelection, setCurrentSelection] = useState(
    getTextBoxInputValue([])
  );


  const warehouseDetail = useSelector(
    (state) => state.superadmin.warehouseDetail
  );


  const handleSelectionChange = (result) => {
    setCurrentSelection(result);
  };
  

  useEffect(() => {
    const payload = { type: "warehouse", status: "active" };
    dispatch(getCompany(payload));
    if (condition) {
      setCondition(false);
    }

    dispatch(getDefaultCustomDashboard({
    }, (data) => {
      
      if (data && data?.success) {
        setTableData(data?.data)
        setLoader(false);
      }
      if (!data?.success) {
        setLoader(false)
      }
    }))
  }, [])
  return (<>
   {
  loader ? (
    <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ p: 5 }}>
      <Grid item>
        <GenericLoader />
      </Grid>
    </Grid>
  ) : !tableData?.attributes ? (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <img src={SelectImage} width="430" height="300" />
      <Typography sx={{ ...textClasses.cardTitle, mt: 2 }}>
        Create your first custom dashboard(s)
      </Typography>
      <Typography sx={{ ...textClasses.t12n, textAlign: "center", maxWidth: "500px", mt: 2 }}>
        Hey, you need to create a customized dashboard by publishing the form.
      </Typography>
      {(permissions.permissions || []).some((item) => item.permissionId === "createCustomDashboard") && (
        <Button
          variant="contained"
          data-testid="create-group"
          spacing={1}
          onClick={() => history.push("/dashboard/create-custom-dashboard")}
          sx={{
            ...buttonClasses.lynkitBlackFill,
            mt: 2,
            ...textClasses.t14n,
          }}
        >
          Create Custom Dashboard
        </Button>
      )}
    </Grid>
  ) : (
    <>
      <Grid container spacing={2} sx={{ marginBottom: "10px" }} alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={6} md={6}>
          <Typography sx={{ ...textClasses.cardTitle }}>My Dashboard</Typography>
        </Grid>

        <Grid item xs={12} sm={4} md={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ minWidth: "400px", minHeight: "50px" }}>
          <MultiSelectAll
            sx={{ width: "100%"}}
            items={warehouseDetail?.data || []}
            selectAllLabel="Select All"
            value={currentSelection}
            onChange={handleSelectionChange}
            label="Select Warehouse"
          />
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <WidgetComponent warehouseDetails={currentSelection} tableData={tableData} />
      </Grid>
    </>
  )
}
  </>)
}
const WidgetComponent = ({ tableData, warehouseDetails }) => {
  const Theme = useTheme();

  const styleViewObject = { 'border': `1px solid ${theme.themeGray} ` }

  const renderWidgetComponent = (widget) => {
    switch (widget?.elementID) {
      case "welcomeNote":
      case "scheduledEvents":
        return <WelcomeComponent item={widget} styleView={styleViewObject} />
      case 'myWarehouse':
        return <WarehouseCountComponent warehouseDetails={warehouseDetails} item={widget} styleView={styleViewObject} />
      case 'operations':
        return <OperationCountComponent warehouseDetails={warehouseDetails} item={widget} styleView={styleViewObject} />
      case 'inventoryCount':
        return <InventoryCountComponent warehouseDetails={warehouseDetails} item={widget} styleView={styleViewObject} />
      case 'inventoryWeight':
        return <InventoryWeightComponent warehouseDetails={warehouseDetails} item={widget} styleView={styleViewObject} />
      case 'jobCreationAndCompletion':
        return <WarehouseJobComponent warehouseDetails={warehouseDetails} item={widget} styleView={styleViewObject} />
      case 'stockVisibility':
        return <WarehouseStockCount warehouseDetails={warehouseDetails} item={widget} styleView={styleViewObject} />
      case 'jobTable':
        return <JobStatusTable item={widget} styleView={styleViewObject} />
      case 'areaUtilization':
        return <AreaUtilizationComponent item={widget} styleView={styleViewObject} />
      default:
        return null;
    }
  }
  const isXs = useMediaQuery(Theme.breakpoints.down("sm"));
  const calculateSize = (totalSize, span, unit = "px") => {
    if (!totalSize || !span) return "auto";

    return unit === "%" ? `calc((100% / ${totalSize}) * ${span})` : `${(totalSize / totalSize) * span}px`;
  };
  return (
    <Grid container direction="column" alignItems={"stretch"}>
      {isXs ? (
        <Grid container component={Paper} >
          {tableData?.attributes?.map((widget, index) => (
            <Grid item xs={12} key={index}>
              <Box
                sx={{
                  padding: 1,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {renderWidgetComponent(widget)}
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        // Table Layout for Larger Screens
        <TableContainer  sx={{ width: "100%" }}>
          <Table>
            <TableBody
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {[...Array(tableData?.coveredRows)].map((_, rowIndex) => (
                <TableRow key={rowIndex} sx={{ width: "100%", height: '100%' }}>
                  {[...Array(tableData?.coveredCols)].map((_, colIndex) => {
                    const widget = tableData?.attributes?.find(
                      (w) => w.x === colIndex && w.y === rowIndex
                    );
                    return widget ? (
                      <TableCell
                        sx={{
                          border: 0,
                          p: 0.5,
                          width: `${calculateSize(tableData?.coveredCols, widget.w, "px")}`,
                          height: `${calculateSize(tableData?.coveredRows, widget.h, "px")}`
                        }}
                        key={colIndex}
                        colSpan={widget.w}
                        rowSpan={widget.h}
                      >
                        <Box sx={{ width: "100%", height: "100%" }}>
                          {renderWidgetComponent(widget)}
                        </Box>
                      </TableCell>
                    ) : "";
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
}
