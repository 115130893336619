
import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Card, CardContent, CardHeader, Avatar, IconButton, Grid, Paper } from "@mui/material";
import GridLayout from "react-grid-layout";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { showToast } from "../../../../services/functions";
import { textCapitalize } from "../../../../services/functions";
import ClearIcon from '@mui/icons-material/Clear';
import theme from "../../../../static/styles/theme";
import AlertTypeWidget from "../WidgetComponents/Alert";
import CountTypeWidget from "../WidgetComponents/Count";
import AnalyticsTypeWidget from "../WidgetComponents/Analytics";
import TableTypeWidget from "../WidgetComponents/Table";

const DropArea = ({ layout, setLayout }) => {
    const containerRef = useRef(null);
    const [gridWidth, setGridWidth] = useState(800);

    // const getDroppedZoneLayoutView = (widgetType, item) => {
    //     console.log("coming", widgetType, item);
    //     return widgetType === "alert" ? (
    //         <AlertTypeWidget item={item} setLayout={setLayout}/>
    //     ) : widgetType === "count" ? (
    //         <CountTypeWidget item={item} setLayout={setLayout}/>
    //     ) : (
    //         <AnalyticsTypeWidget item={item} setLayout={setLayout}/> 
    //     );

    // };

      // return (
        //     <Card
        //         key={item.i}
        //         sx={{
        //             border: `1px solid ${theme.themeOrange}`,
        //             borderRadius: "8px",
        //             boxShadow: "none",
        //             width: "fit-content",
        //             display: "flex",
        //             flexDirection: "column",
        //             position: "relative", // Ensures IconButton stays inside Card
        //         }}
        //     >
        //         {/* Delete Button Positioned at the Top Right (Only for non-count widgets) */}
        //         <IconButton
        //             size="small"
        //             sx={{
        //                 position: "absolute",
        //                 top: "5px",
        //                 right: "5px",
        //                 color: theme.themeOrange,
        //             }}
        //             onClick={() => {
        //                 console.log("ev", item);
        //                 setLayout((prev) => prev.filter((l) => l.i !== item.i));
        //             }}
        //         >
        //             <ClearIcon fontSize="small" />
        //         </IconButton>

        //         {/* Card Header */}
        //         <CardHeader
        //             className="pt-2 pb-2"
        //             avatar={
        //                 <Avatar className="br-1" variant="square">
        //                     {item?.icon ? (
        //                         <span className="material-symbols-outlined" style={{ fontSize: "40px" }}>
        //                             {item.icon}
        //                         </span>
        //                     ) : (
        //                         <span className="material-symbols-outlined" style={{ fontSize: "40px" }}>
        //                             text_fields
        //                         </span>
        //                     )}
        //                 </Avatar>
        //             }
        //             title={
        //                 <Typography sx={{ textAlign: "left", fontWeight: "bold" }} variant="h6" gutterBottom>
        //                     {item?.title}
        //                 </Typography>
        //             }
        //         />

        //         {/* Card Content with Conditional Rendering */}
        //         <CardContent
        //             className="pt-0 pb-2"
        //             sx={{
        //                 flexGrow: 1,
        //                 maxHeight: "150px",
        //                 overflowY: "auto",
        //             }}
        //         >
        //             {widgetType === "count" ? (
        //                 item?.values?.map((dl, index) => (
        //                     <Typography key={index} sx={{ textAlign: "left" }} variant="body2">
        //                         {dl.label}: {dl.value}
        //                     </Typography>
        //                 ))
        //             ) : (
        //                 <Typography variant="body2">
        //                     {item.description || "No description provided"}
        //                 </Typography>
        //             )}
        //         </CardContent>
        //     </Card>
        // );
    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setGridWidth(containerRef.current.clientWidth);
            }
        };

        window.addEventListener("resize", updateWidth);
        updateWidth();

        return () => {
            window.removeEventListener("resize", updateWidth);
        };
    }, []);
    
    const [, dropRef] = useDrop(() => ({
        accept: "component",
        drop: (item) => addToLayout(item),
    }));
    
    const addToLayout = (component) => {
       
        setLayout((prev) => {
            let isExist = prev?.find((dl) => dl?.i === component?.elementID);
            if (isExist) {
                showToast("Component already added", true);
                return prev;
            }
            return ([
                ...prev,
                {
                    ...component,
                    i: component.elementID,
                    x: (prev.length * 2) % 12,
                    y: Infinity,
                    w: component.minW,
                    h: component.minH,
                    description: component.description || "Default description", 
                    footer: component.footer || "Default footer", 
                },
            ])
        });
    };

    const handleLayoutChange = (newLayout) => {
        setLayout((prevLayout) =>
            newLayout.map((layoutItem) => {
                const existingItem = prevLayout.find((item) => item.i === layoutItem.i);
                return existingItem ? { ...existingItem, ...layoutItem } : layoutItem;
            })
        );
    };

    return (
        <Box
            ref={dropRef}
            sx={{
                height: layout?.length?"100%" :'30vh',
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // border:`1px solid ${theme.themeOrange}`
            }}

        >
            <div ref={containerRef} style={{ width: "100%", height: "100%" }}>
                <GridLayout
                    className="layout"
                    style={{border:`1px dotted ${theme.themeOrange}`,minHeight:'30vh'}}
                    layout={layout}
                    cols={12}
                    rowHeight={70}
                    width={gridWidth}
                    isResizable
                    isDraggable
                    onLayoutChange={handleLayoutChange}
                >
                    {/* {layout.map((item) => (
                   
                            getDroppedZoneLayoutView(item?.type, item)
                      
                    ))} */}

                    {layout.map((item, ind) => {
                        return <Paper key={item.i} sx={{
                            // border: `1px solid ${theme.themeOrange}`,
                            // borderRadius: "8px",
                            boxShadow: "none",
                            width: "fit-content",
                            display: "flex",
                            flexDirection: "column",
                            position: "relative", // Ensures IconButton stays inside Card
                        }}>
                            {item?.type === "alert" ? (
                                <AlertTypeWidget item={item} setLayout={setLayout} />
                            ) : item?.type === "count" ? (
                                <CountTypeWidget item={item} setLayout={setLayout} />
                            ) : item?.type === "analytics" ? (
                                <AnalyticsTypeWidget item={item} setLayout={setLayout} />
                            ) :
                                <TableTypeWidget item={item} setLayout={setLayout} />}
                        </Paper>
                    })}

                </GridLayout>

            </div>
        </Box>
    );
};



const DropWidgetsArea = ({layout,setLayout}) => {
    // const [layout, setLayout] = useState([]);
    //    const dotStyle = {
    //             width: "5px",
    //             height: "5px",
    //             borderRadius: "50%",
    //             backgroundColor: theme.themeOrange,
    //             display: "inline-block",
    //             margin: "8px",
    //         };

    //         const gridContainerStyle = {
    //             display: "grid",
    //             gridTemplateColumns: "repeat(12, 1fr)", // 12 dots per row
    //             gap: "16px", // Space between dots
    //             justifyContent: "center",
    //             padding: "16px",
    //             height: "100%", // Ensure it spans to the end of the page
    //             overflowY: "auto",
    //         };

    
    // const gridContainerStyle = {
    //     display: "grid",
    //     gridTemplateColumns: "repeat(12, 1fr)", // 12 dots per row
    //     gap: "16px",
    //     justifyContent: "center",
    //     padding: "16px",
    //     height: "100%", 
    //     overflowY: "auto",
    //     backgroundImage: "url('/path-to-your-image.jpg')", // Replace with your actual image path
    //     backgroundSize: "cover", // Adjust to fit the container
    //     backgroundPosition: "center",
    //     backgroundRepeat: "no-repeat",
    // };
    return (
        <DndProvider backend={HTML5Backend}>
            {/* <div style={gridContainerStyle}>
                     {Array.from({ length: 120 }).map((_, index) => (
                       <div key={index} style={dotStyle}>

                       </div>
                   ))}
                                                       <DropArea layout={layout} setLayout={setLayout} />

                 </div> */}
            {/* <Box sx={{
                backgroundColor: "red", display: 'flex', height: '80vh', maxHeight: '100vh',
                overflowY: "auto",p:''
            }}> */}
                <DropArea layout={layout} setLayout={setLayout} />
            {/* </Box> */}
        </DndProvider>
    );
};

export default DropWidgetsArea;
