import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,Grid
} from "@mui/material";
import "../Style/GridView.css";
import BinModel from "./BinModel";
import CustomizedBadge from "../../Generic/GenericBadge";
import theme from "../../../../static/styles/theme";

let blockHeight=30
let blockWidth=45

function GridViewLayout(props) {
  const { allocationType, areaName = "", setVisibleCard ,analyticsBlockDetail,utilizationTypeValue,floorInfo,isViewEnabled={},heatMapAnalytics={}} = props;
  // console.log("propssss",allocationType, areaName, setVisibleCard ,analyticsBlockDetail,utilizationTypeValue,floorInfo,isViewEnabled,heatMapAnalytics)
  const [selectedRack, setSelectedRack] = useState([]);
  const [areaArray, setAreaArray] = useState([]);
  const [showBinModel, setShowBinModel] = useState(false);
  const [selectedCell, setSelectedCell] = useState({});
  const colorHanlder = (cell_Data) => {
    // console.log(cell_Data, "cell_Data");
    if (cell_Data) {
      if (areaName && !cell_Data.subProductType.includes(areaName)) {
        return "grey";
      }
      if (cell_Data.status == "full") {
        return "red";
      } 
      else if (cell_Data.status == "partial") {
        let utilizationInfo = analyticsBlockDetail?.find(dl => dl?.cellId === cell_Data?.cellId) || {};
        let percentage = 0;
        if (utilizationInfo[utilizationTypeValue] > 0) {
          percentage = (utilizationInfo[utilizationTypeValue] || 0)
        }
        percentage = Number(percentage || 0)
        if (percentage >= 100) {
          return "red";
        }
        return "yellow";
      } else if (cell_Data.status == "available") {
        return "green";
      } else {
        return "green";
      }
    } 
    else {
      return "green";
    }
  };

  const colorAreaMappingHanlder = useCallback((cell_Data) => {
    if (!cell_Data) return "green";
    if (cell_Data.disable) return "white";

    const isSelected = selectedRack.some((dl) => dl.cellId === cell_Data.cellId);

    if (allocationType === "dockMapping") {
      if (cell_Data.isMappedWithLocation && !isSelected) return "yellow";
      return isSelected ? "grey" : "green";
    }

    if (cell_Data.subProductType?.length > 0 && !isSelected) return "yellow";
    return isSelected ? "grey" : "green";
  }, [selectedRack])

  const getBlockByIndex = (rowIndex, colIndex) => {
    if (props.gridCellDetails && props.gridCellDetails.length) {
      const result = props.gridCellDetails.find(
        (el) => el.rowIndex == rowIndex && el.colIndex == colIndex
      );
      // //('data1', result)
      return props.gridCellDetails.find(
        (el) => el.rowIndex == rowIndex && el.colIndex == colIndex
      );
    }
  };

  const getStyleForBlock = (i, j, data) => {
    let tempObj = {
      color: data.fontColor ? data.fontColor : "#000",
      textAlign: "center",
      verticalAlign: "middle",
      border: "1px solid black"
    };
    // if (i === props.floorInfo.row) {
    //   tempObj["borderBottomWidth"] = "0px";
    // }
    // if (j === props.floorInfo.col) {
    //   tempObj["borderRightWidth"] = "0px";
    // }
    // else {
    //   tempObj["cursor"] = "pointer";
    // }
    tempObj["backgroundColor"] = "#FFF";
    tempObj["padding"] = "4px";
    // tempObj["padding"] = "10px 1px";
    tempObj["overflow"] = "hidden";
    // tempObj["textOverflow"] = "ellipsis";
    let allType = ["space", "dock"]
    if (allocationType) {
      if (allocationType == "areaMapping") {
        allType.push("stagingArea")
      }
      else {
        allType = ["space","block"]
      }
    }
    
    if (!allType.includes(data.type)) {
      if (!props.area || props.area.length == 0 || allocationType == "dockMapping") {
        tempObj["backgroundColor"] = allocationType ? colorAreaMappingHanlder(data) : colorHanlder(data);
        tempObj["cursor"] = allocationType
          ? "pointer"
          : (!data.isRackExist && data.status == "available" && data?.subProductType?.length > 0 && "not-allowed") || "pointer";
      }
      else {
        let bgColor = "grey";
        if (
          props.area &&
          props.area.length &&
          props.area.includes(data.cellId)
        ) {
          bgColor = colorHanlder(data);
        }
        tempObj["backgroundColor"] = bgColor;
      }
    }

    if (data.type == "space") tempObj["border"] = "none";
    if (allType.includes(data.type)) {
      tempObj["backgroundColor"] = data.bgColor ? data.bgColor : "white";
      tempObj["cursor"] = "not-allowed";
    }



    if(data?.mergeColumn && Number(data?.mergeColumn) > 0){
      tempObj["width"] =(blockWidth*Number(data?.mergeColumn))+"px";
    }
    else{
      tempObj["width"] = blockWidth+"px";
      
    }
    if(data?.mergeRows && Number(data?.mergeRows) > 0){
      tempObj["height"] =(blockHeight*Number(data?.mergeRows))+"px";
    }
    else{
      tempObj["height"] = blockHeight+"px";
    }
    
    // console.log(tempObj,"tempObj")
    return tempObj;
  };

  const onhandleRack = (gridBlock) => {
    setSelectedCell(gridBlock);
    if (gridBlock.disable || gridBlock.type == "space") {
      if (setVisibleCard) setVisibleCard("");
      return;
    }
     else if (
      !gridBlock.isRackExist &&
      gridBlock.status == "available" &&
      gridBlock?.subProductType?.length > 0
    ) {
      if (setVisibleCard) setVisibleCard("");
      return;
    }

    if (gridBlock.isRackExist) {
      props.showWarehouseModal(gridBlock.cellId);
    } else {
      if (gridBlock.type=="stagingArea" || gridBlock.status == "full" || gridBlock.status == "partial") {
        setShowBinModel(true);
      } 
      else {
        props.showWarehouseModal(gridBlock.cellId);
      }
    }
  };

  const onhandleViewAllocation = (gridBlock) => {
    let tempArray = [...selectedRack]; // Clone to avoid direct mutation
    let areaArr = [...areaArray]; // Clone to avoid direct mutation
    let blockObj = { [gridBlock.cellId]: gridBlock.col * gridBlock.row };
    let obj={}
    if(allocationType == "dockMapping") {
      obj = {
        cellId: gridBlock.cellId,
        renderId: Date.now(),
        selectType:  "block",
        actionType: gridBlock.disable || gridBlock.subProductType.length > 0 ? "update" : "addNew",
        name: gridBlock.name,
      };
    }
    else {
       obj = {
        cellId: gridBlock.cellId,
        renderId: Date.now(),
        selectType: gridBlock.isRackExist ? "rack" : "block",
        data: [{
          materialType: "",
          rackId: [],
        }],
        actionType: gridBlock.disable || gridBlock.subProductType.length > 0 ? "update" : "addNew",
        name: gridBlock.name,
        isRackExist: gridBlock.isRackExist,
        oldMaterialType: gridBlock?.subProductType ?? [],
      };

    }

    const existingIndex = tempArray.findIndex(item => item.cellId === gridBlock.cellId);

    if (existingIndex === -1) {
      tempArray.push(obj);
      areaArr.push(blockObj);
    } else {
      tempArray.splice(existingIndex, 1);
      const areaIndex = areaArr.findIndex(item => item.cellId === gridBlock.cellId);
      if (areaIndex !== -1) {
        areaArr.splice(areaIndex, 1);
      }
    }

    setSelectedRack(tempArray);
    setAreaArray(areaArr);

    const finalObj = {
      type: "wms",
      areaTypeData: tempArray,
      floorId: gridBlock.floorId,
      warehouseId: gridBlock.warehouseId,
      blockarea: areaArr,
    };
    // console.log("finalObj", finalObj);
    props.addSelectedRack(finalObj);
  };
  const calculateCellUtilization = (cellId) => {
    let utilizationInfo = analyticsBlockDetail?.find(dl => dl?.cellId === cellId);
    if (!utilizationInfo) {
      return ""
    }
    let percentage = 0
    if (utilizationInfo[utilizationTypeValue] > 0) {
      percentage = (utilizationInfo[utilizationTypeValue] || 0)
    }
    percentage = (percentage || 0)+""
    if(percentage.split(".").length>1 && percentage.split(".")[1]==0){
      percentage=percentage.split(".")[0]
    }
    return <Typography>
      : {percentage}% 
    </Typography>
  }

  useEffect(() => {
    setSelectedRack(props.selectedRackArr);
  }, [props.selectedRackArr]);


  return (
    <>
      {/* <Box data-testid="gridView" sx={{width:"100vw"}}> */}
        <Grid
          // {...isGridExist ? {} : { sm: 9, md: 9.5 }}
          xs={12}
          order={{ xs: 2, sm: 1, md: 1 }}
          sx={{ p: 2,}}
          item
          container
        >
          <Grid sx={{
            p: 1, display: "flex", justifyContent: "center", alignItems: "center",
            border: `1px solid ${theme.themeGray}`, borderRadius: "5px",
            minHeight: "30vh", maxHeight: "70vh", overflowY: "auto",
          }} item
            container>
            <Grid className=""
              sx={{
                display: "block",
                overflowX: "auto",
                // whiteSpace: "nowrap",
                p: 1,
              }}
              item
            >

              <table style={{
                borderCollapse: "collapse",
                border: "1px solid #0000002B",
                tableLayout: "fixed",
                width:"100%",

              }}>
                {Array.from(
                  { length: props.floorInfo.row ? props.floorInfo.row : 0 },
                  (_, rowIndex) => {
                    return (
                      <tr className="gridRow" key={"blr-" + rowIndex}>
                        {Array.from(
                          {
                            length: props.floorInfo.col ? props.floorInfo.col : 0,
                          },
                          (_, colIndex) => {
                            let gridBlock = getBlockByIndex(rowIndex, colIndex);
                            let isHeatMapEnabled = gridBlock?.type == "block" && isViewEnabled.view == "heatMap"
                            let isHeatMapValue = heatMapAnalytics && gridBlock?.cellId && heatMapAnalytics[gridBlock?.cellId] || {}
                            if (isHeatMapValue?.movementStatus) {
                              // isHeatMapValue = `${textCapitalize(isHeatMapValue?.movementStatus)} (${isHeatMapValue?.count || 0})`
                              isHeatMapValue = `${isHeatMapValue?.count || 0}`
                            }
                            else {
                              isHeatMapValue = "0"
                              // isHeatMapValue = "No Moving(0)"
                            }

                            if (gridBlock && !gridBlock.disable) {
                              // Proportional width calculation
                              const colSpan =( Number(gridBlock.mergeColumn) || 1)*blockWidth;
                              const proportionalWidth = (colSpan / props.floorInfo.col) * 100

                              return (
                                <td
                                  className={`gridItem ${gridBlock.type == "space" ? "space_area" : ""
                                    } `}
                                  key={"blc-" + colIndex}
                                  onClick={() => {
                                    if (setVisibleCard) setVisibleCard("")
                                    if (["block", "stagingArea","dock"].includes(gridBlock?.type)) {
                                      if (!allocationType) {
                                        onhandleRack(gridBlock)
                                      }
                                      else if (allocationType=="areaMapping" &&  ["block"].includes(gridBlock?.type)) {
                                        onhandleViewAllocation(gridBlock)
                                      }
                                      else if (allocationType=="dockMapping" &&  ["dock"].includes(gridBlock?.type)) {
                                        onhandleViewAllocation(gridBlock)
                                      }
  
                                    }
                                  }
                                  }
                                  rowSpan={
                                    gridBlock.mergeRows
                                      ? Number(gridBlock.mergeRows)
                                      : ""
                                  }
                                  colSpan={
                                    gridBlock.mergeColumn
                                      ? Number(gridBlock.mergeColumn)
                                      : ""
                                  }
                                  style={{
                                    ...getStyleForBlock(rowIndex, colIndex, gridBlock),
                                    width: `${proportionalWidth}%`, // Apply proportional width
                                    padding:'12px'
                                  }}
                                >
                                  <CustomizedBadge
                                    title={isHeatMapValue}
                                    backgroundColor={theme.themeRoyalBlue}
                                    color={theme.themeWhite}
                                    hideValue={!isHeatMapEnabled}
                                  >
                                    {gridBlock.name || ""}
                                    {gridBlock?.type == "block" &&
                                      floorInfo?.isCapacityDefined == true &&
                                      utilizationTypeValue
                                      ? calculateCellUtilization(gridBlock.cellId)
                                      : ""}
                                  </CustomizedBadge>
                                </td>
                              );
                            } else {
                              return "";
                            }
                          }
                        )}
                      </tr>
                    );
                  }
                )}
              </table>

            </Grid>
          </Grid>
        </Grid>
        {showBinModel && (
          <BinModel
            showBinModel={showBinModel}
            setShowBinModel={setShowBinModel}
            selectedCell={selectedCell}
          />
        )}
      {/* </Box> */}
    </>
  );
}

export default GridViewLayout;
