import {FETCH_INHOUSE_PALLETIZATION_TABLE_DATA, FETCH_INHOUSE_PALLET_DETAIL,
     FETCH_INHOUSE_PALLET_PICKING_READY, FETCH_INHOUSE_PALLET_STATUS_COUNT, 
     FETCH_INHOUSE_DEPALLETIZATION_TABLE_DATA, FETCH_INHOUSE_DEPALLET_DETAIL,
     FETCH_INHOUSE_DEPALLET_STATUS_COUNT, FETCH_INHOUSE_PALLET_CARDTYPEWISE_COUNT} from "../types"

const INIT_STATE = {
    inHousePalletizationTableData: [],
    totalPalletTableDataRows: 0,
    inHousePalletDetail: null,
    inHousePalletDetailItemCount: 0,
    inHouseDePalletizationCardTypeWiseCount:null,
    // skuGroupDetail: null,
    inHousePalletizationStatusWiseCount: null,
    inHouseDePalletizationStatusWiseCount: null,
    inHousePickingReadyPallets: null,
    inHouseDePalletizationTableData: [],
    totalDePalletTableDataRows: 0,
    // automationRuleTableData: [],
    // automationRuleTableRows: 0,
    // automationTaskFields: null,
    // jobTypeListsByWorkflow: []


}
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_INHOUSE_PALLETIZATION_TABLE_DATA: return { ...state, inHousePalletizationTableData: action?.payload?.data || [], totalPalletTableDataRows: action?.payload?.count || 0 }
        case FETCH_INHOUSE_PALLET_DETAIL: return {...state, inHousePalletDetail: action?.payload?.data, inHousePalletDetailItemCount: action?.payload?.count || 0}
        case FETCH_INHOUSE_PALLET_STATUS_COUNT: return { ...state, inHousePalletizationStatusWiseCount: action?.payload}
        case FETCH_INHOUSE_PALLET_PICKING_READY: return { ...state, inHousePickingReadyPallets: action?.payload}
        case FETCH_INHOUSE_DEPALLETIZATION_TABLE_DATA: return { ...state, inHouseDePalletizationTableData: action?.payload?.data || [], totalDePalletTableDataRows: action?.payload?.count || 0 }
        case FETCH_INHOUSE_DEPALLET_STATUS_COUNT: return { ...state, inHouseDePalletizationStatusWiseCount: action?.payload}
        case FETCH_INHOUSE_PALLET_CARDTYPEWISE_COUNT: return { ...state, inHouseDePalletizationCardTypeWiseCount: action?.payload}
        // case FETCH_SKU_GROUP_DETAIL: return { ...state, skuGroupDetail: action?.payload?.data || {} }
        // case FETCH_AUTOMATION_TASK_FIELDS: return { ...state, automationTaskFields: action?.payload?.data || {} }
        // case FETCH_JOB_TYPES_BY_WORKFLOW: return { ...state, jobTypeListsByWorkflow: action?.payload?.data || [] }

        default: return state
    }
}