import React, {
    useState,
    useCallback,
    useMemo,
  } from "react";
  import GenericSingleRangeComponent from "../../../Components/Generic/GenericSingleRangeComponent";
  import {
    Grid,
    Typography,
    Box,
    FormControlLabel,
    IconButton,
    Paper,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Table,
    RadioGroup,
    Radio,
  } from "@mui/material";
  import { Delete, } from "@mui/icons-material";
  import theme, {buttonClasses} from "../../../../static/styles/theme";
  import TypeAndSearch from "../../../Components/TypeAndSearch";
  import {
    deepCopy_Object,
  } from "../../../../services/functions";

  import SelectSalesItems from "./SelectSalesItems"
  
  export default function QuantityWiseElement({
    scanningInput,
    setAddedItems,
    addedItems,
    warehouseId,
    dataFilter,
    filterValue,
    inventoryType,
    setInventoryType,
    salesDetail
  }){
    const initial = {
      SKU_ID: "",
      count: "",
      warehouseId: "",
      inventoryType: inventoryType,
      floorId: "",
      availableCount: "",
      resetField: false,
    };
    const [selectedData, setSelectedData] = useState({...initial});
    const [alreadySelectedItem, setAlreadySelectedItem] = useState("");
  
    const [showSalesItems, setShowSalesItems] = useState(salesDetail && salesDetail.length);
    const [selectedItems, setSelectedItems] = useState([]);
  
    const handleClose = useCallback(() => {
      setShowSalesItems(false);
      setSelectedItems([]);
    }, []);
  
    const handleSubmit = useCallback(() => {
      let newItems = selectedItems.map((it) => {
        let res = {
          "_id": it._id,
          "SKU_ID": it.product_ID,
          "count": it.filledCount,
          "warehouseId": "",
          "inventoryType": it.dataEntryType,
          "floorId": "",
          "availableCount": it.availableCount,
          "resetField": false,
          "Sales_Id": it.Sales_Id
        }
        return res
      })
      setAddedItems((prev) => [...newItems, ...prev]);
      setShowSalesItems(false);
    }, [selectedItems]);
  
    const handleChangeSKU = useCallback(
      (option, newValue) => {
        setSelectedData((prev) => ({
          ...prev,
          SKU_ID: option,
          availableCount: newValue?.count,
          count: newValue.count,
        }));
      },
      [setSelectedData]
    );
  
    let header;
    if (salesDetail && salesDetail.length > 0) {
      header = ["Sr. No", "SKU", "Inventory Type", "Sales Id", "Count", "Delete"];
    }
    else {
      header = ["Sr. No", "SKU", "Inventory Type", "Count", "Delete"];
    }
    // const header = ["Sr. No", "SKU", "Inventory Type", "Count", "Delete"];
  
    const handleRowDelete = (indx) => {
      setAddedItems((o) => o.filter((_, i) => i !== indx));
    };
  
    const countHandler = (countValue = "", i, available_count = 0) => {
      let temp_added = [...addedItems];
      if (countValue <= 0) {
        temp_added[i].count = 0
      }
      else {
        temp_added[i].count = Math.min(available_count, countValue)
      }
      setAddedItems(temp_added);
    };
  
    const rows = addedItems.map((data, indx) => {
      let row = [];
      const { cellId, SKU_ID, inventoryType, count, availableCount, Sales_Id } = data;
  
      row.push(indx + 1, SKU_ID, inventoryType)
  
      if (salesDetail && salesDetail.length > 0) {
        row.push(Sales_Id)
      }
      row.push(count, [
        <IconButton
          aria-label="delete"
          key={indx}
          onClick={() => handleRowDelete(indx)}
        >
          <Delete sx={{ color: "red" }} />
        </IconButton>,
      ]);
  
  
      if (availableCount >= count) {
        row.splice(
          (salesDetail && salesDetail.length > 0) ? 4 : 3,
          1,
          <GenericSingleRangeComponent
            startRange={count || null}
            endRange={availableCount || 0}
            getOnChange={(countValue) => countHandler(countValue,indx, availableCount)}
            endRangeInfinity={false}
            disabled={!availableCount}
            valueType={"decimal"}
          />,
        );
      }
  
      return row;
    });
  
    const component = {
      _id: "categoryKey",
      type: "select",
      disabled: false,
      label: "Select SKU",
      placeholder: "Select SKU",
      description: "Select SKU",
      required: true,
      dynamic: true,
      auth: true,
      apiDomain: "/v1/",
      apiEndPoint: "inventory/getDistinctInventorySKU",
      apiMethod: "post",
      fetched: false,
      name: "Item Master List",
      apiDataReturnKey: "data",
      multipleAPICall: true,
      dontFetch:false,
      apiParameters: [
        {
          label: "type",
          value: `${selectedData?.inventoryType}`,
        },
        {
          label: "searchValue",
          value: "$self",
        },
        {
          label: "page",
          value: 1,
        },
        {
          label: "limit",
          value: 25,
        },
        {
          label: "warehouseId",
          value: warehouseId,
        },
        dataFilter && {
          label: "filterType",
          value: dataFilter?.value,
        },
        dataFilter &&
        filterValue && {
          label: "filterValue",
          value: filterValue.every((value) => value?.isValid()) && filterValue?.map(value=>value?.$d?.toISOString()) || [],
          // value: filterValue.some((value) => value?.isValid()) && filterValue?.filter(value=>value)?.map(value=>value.$d.toISOString()) || [],
        },
      ],
      apiBindingKey: "SKU_ID",
      apiBindingValue: "SKU_ID",
      suffix: "",
      extraValue: [
        { label: "count", value: "count" },
        { label: "SKU_Detail", value: "SKU_Detail" },
      ],
      "renderOption": [
        {
          "label": "",
          "multiple": false,
          "prefix": "",
          "postfix": "",
          "key": "SKU_ID",
          "keys": [
  
          ]
        },
        {
          "label": "",
          "multiple": false,
          "prefix": " (",
          "postfix": ")",
          "key": "SKU_Detail"
        }
      ],
      subtype: "single",
      forType: "all",
      elementType: "Selection Type",
      sortType: 2,
    };
  
    const handleCountChange = (countValue,limit) => {
      let oldSelectedData = {...selectedData};
      if (countValue <= 0) {
        oldSelectedData.count = 0
      }
      else {
        oldSelectedData.count = Math.min(limit || 0, countValue)
      }
      setSelectedData(oldSelectedData);
    };
  
    const handleItemAdd = useCallback(() => {
      setAddedItems((prev) => [selectedData, ...prev]);
      const initialData = { ...initial, SKU_ID: "" };
      setSelectedData(initialData);
    }, [selectedData, setAddedItems, initial]);
  
    const isTypeAndSearchDisabled = useMemo(() => {
      return dataFilter
        ? filterValue.some((value) => value === null || !value?.isValid())
        : false;
    },[dataFilter,filterValue]);
  
    const handleEntityTypeChange = useCallback(
      (value) => {
        setSelectedData((prev) => ({ ...prev, inventoryType: value,count:0,availableCount:0 }));
        setInventoryType(value);
      },
      [setInventoryType, setSelectedData]
    );
  
    return (
      <>
        <Grid mt={3}>
          <Box>
            <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
              Inventory Details
            </Typography>
          </Box>
          {
            (salesDetail && salesDetail.length > 0)
              ?
              <>
                <SelectSalesItems
                  open={showSalesItems}
                  onClose={handleClose}
                  setSelectedItems={setSelectedItems}
                  selectedItems={selectedItems}
                  onSubmit={handleSubmit}
                  salesDetail={salesDetail}
                  addedItems={addedItems}
                  warehouseId={warehouseId}
                />
                <Box
                  sx={{ display: "flex", flexDirection: "row", mb: 1, gap: "0.5rem" }}
                >
                  <Button
                    disabled={!scanningInput}
                    variant="contained"
                    size="small"
                    sx={{
                      ...buttonClasses.lynkitBlackFill,
                      mt: 1,
                    }}
                    onClick={() => setShowSalesItems(true)}
                  >
                    Select Inventory & Allocate Stock
                  </Button>
                </Box>
              </>
              :
              <>
  
                <Box
                  sx={{ display: "flex", flexDirection: "row", mb: 1, gap: "0.5rem" }}
                >
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: 500, alignSelf: "center" }}
                  >
                    Inventory Type :
                  </Typography>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={selectedData?.inventoryType}
                    onChange={(e) => {
                      handleEntityTypeChange(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        color: "rgb(128, 128, 128)",
                        "& .MuiFormControlLabel-label": {
                          fontSize: "13px",
                        },
                      }}
                      value="package"
                      label="Package"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            "&, &.Mui-checked": {
                              color: "#FF7200",
                            },
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      sx={{
                        color: "rgb(128, 128, 128)",
                        "& .MuiFormControlLabel-label": {
                          fontSize: "13px",
                        },
                      }}
                      value="item"
                      label="Item"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            "&, &.Mui-checked": {
                              color: "#FF7200",
                            },
                          }}
                        />
                      }
                    />
                  </RadioGroup>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                  <Grid xs={4}>
                    <TypeAndSearch
                      component={deepCopy_Object(component)}
                      disabled={isTypeAndSearchDisabled}
                      // value={selectedData?.SKU_ID || ''}
                      label={selectedData?.SKU_ID || ''}
                      setEmptyOnChange={false}
                      handleChange={(option, newValue) => {
                        setAlreadySelectedItem("");
                        if (!option.target.value) {
                          setSelectedData((prev) => ({
                            ...prev,
                            count: "",
                            SKU_ID: "",
                            availableCount: "",
                          }));
                        }
                        const isAlreadyExist = addedItems?.find(
                          (data) => data?.SKU_ID === option?.target.value
                        );
                        if (isAlreadyExist) {
                          setAlreadySelectedItem(option.target.value);
                        }
                        if (option.target.value && !isAlreadyExist) {
                          handleChangeSKU(option?.target.value, newValue);
                        }
                      }}
                    />
                  </Grid>
                  {/* <Grid xs={3}> */}
                  {/* <Box> */}
                  <GenericSingleRangeComponent
                    startRange={selectedData?.count || null}
                    endRange={selectedData?.availableCount || 0}
                    getOnChange={(countValue) => handleCountChange(countValue,selectedData?.availableCount)}
                    endRangeInfinity={false}
                    disabled={!selectedData || !selectedData.availableCount}
                    valueType={"decimal"}
                  />
  
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      ml: 2,
                      backgroundColor: theme.themeOrange,
                      "&:hover": {
                        backgroundColor: theme.themeOrange,
                      },
                    }}
                    onClick={handleItemAdd}
                    disabled={
                      !selectedData || !selectedData.SKU_ID || !selectedData.count
                    }
                  >
                    Add
                  </Button>
  
                  {/* </Grid> */}
                </Box>
                {!!alreadySelectedItem && (
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        alignSelf: "center",
                        fontSize: "12px",
                        mt: "0.5rem",
                        color: "#d11b1b",
                      }}
                    >
                      {`${alreadySelectedItem} has already been selected`}
                    </Typography>
                  </Box>
                )}
                {(selectedData?.availableCount>0) && (
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        alignSelf: "center",
                        fontSize: "14px",
                        mt: "0.5rem",
                        color: theme.themeOrange,
                      }}
                    >
                      Available : {selectedData?.availableCount}
                    </Typography>
                  </Box>
                )}
              </>
          }
  
          {addedItems.length>0 ? (
            <TableContainer
              component={Paper}
              sx={{
                marginTop: "2em",
                maxWidth: "100%",
                // width: "60%",
                overflowX: "auto",
              }}
            >
              <Table aria-label="simple table">
                <TableHead sx={{ backgroungColor: theme.themeGray }}>
                  <TableRow sx={{ backgroundColor: theme.themeGray }}>
                    {header.map((head, i) => {
                      return (
                        <TableCell
                          sx={{
                            fontSize: "13px",
                            textAlign: "center",
                            fontWeight: 600,
                          }}
                          key={i}
                        >
                          {head}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.length > 0
                    ? rows.map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          "& .MuiTableCell-root": {
                            // borderLeft:
                            // "1px solid rgba(224, 224, 224, 1)",
                          },
                        }}
                      >
                        {row.map((col, j) => {
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                fontSize: "12px",
                                textAlign: "center",
                                overflowWrap: "break-word",
                              }}
                              key={j}
                            >
                              {col}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </Grid>
      </>
    );
  };
  