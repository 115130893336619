import React, { useEffect, useMemo, useState } from 'react'
import StaticFormRender from '../../../Components/DynamicFormRender/staticRendererForm'
import { Grid, Paper, Typography, Tooltip, Button } from '@mui/material'
import theme, { buttonClasses, textClasses } from '../../../../static/styles/theme'
import { useDispatch } from 'react-redux'
import { generateUniqueSerialNumber } from '../../../../redux/actions'
import { showToast } from '../../../../services/functions'
import Cookies from 'js-cookie';




const PackageSection = ({ template, watch, formHooks, onKeyPressEnterTrigger, isScanned, generatePackageNameBtnData, packageNameLoader, setPackageNameLoader, isPackageNameDisabled, setIsPackageNameDisabled }) => {
    const dispatch = useDispatch();
    const palletPackageName = Cookies.get("palletPackageName") ? JSON.parse(Cookies.get("palletPackageName")) : "";

    const getFilteredAttributes = useMemo(
        () => {
            let tempAttr = template?.otherTemplate?.find(dl => dl?.templateType == "packageDetailTemplate")?.attributes || []
            if (isScanned) {
                tempAttr = tempAttr?.filter(dl => dl?._id != "count")
            }
            return tempAttr;
        }, [isScanned, template]
    )
    const dispatchPackageNameGenerator = () => {
        if(getFilteredAttributes.some(el=>el?._id=="packageName")){
            setPackageNameLoader("packageName");
            dispatch(
                generateUniqueSerialNumber(
                    { templateId: template?._id || "", serialNumberType: "packageName" },
                    ({ success = false, message, data = "" }) => {
                        showToast(message, !success);
                        setTimeout(() => {
                            setPackageNameLoader("");
                        }, 500)
                        if (success) {
                            Cookies.set("palletPackageName", JSON.stringify(data), { expires: 7 });
                            formHooks.setValue("packageName", data);  
                            setIsPackageNameDisabled(true);
                            // setPOGenerated(true);
                        }
                    }
                )
            );
        }
    };


    // useEffect(() => {
    //     if (generatePackageNameBtnData?.isActive) {
    //         if (!palletPackageName && generatePackageNameBtnData?.isAutoGenerate) {
    //             dispatchPackageNameGenerator()
    //         }
    //         else {
    //             formHooks.setValue("packageName", palletPackageName);
    //             setPackageNameLoader("");
    //             if (palletPackageName) {
    //                 setIsPackageNameDisabled(true);
    //             }
    //         }
    //     }
    // }, [])

    return (
        <Paper elevation={1}>
            <Grid container sx={{ backgroundColor: theme.themeGray, p: "12px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px", display: "flex", justifyContent: "space-between" }}>
                <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>


                    <Typography sx={{ ...textClasses.boldText }}>Package Details</Typography>
                </Grid>
                {
                    getFilteredAttributes.some(el=>el?._id=="packageName") && generatePackageNameBtnData?.isActive && generatePackageNameBtnData?.isShowButton && !generatePackageNameBtnData?.isAutoGenerate &&
                    <Grid item xs={6} sm={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            style={{ ...buttonClasses.lynkitOrangeEmpty }}
                            onClick={() => dispatchPackageNameGenerator()}
                            disabled={packageNameLoader == "packageName"}
                        >
                            Generate{" "}
                            {getFilteredAttributes.find((elem) => elem._id == "packageName")?.label || "Package Name"}
                        </Button>
                    </Grid>
                }
            </Grid>
            <Grid container sx={{ mt: 3, mb: 3, width: 'inherit', padding: "12px" }}>
                <StaticFormRender
                    formHooks={formHooks}
                    tempAttr={getFilteredAttributes}
                    disabled={isPackageNameDisabled && ["packageName"] || []}
                    onKeyPressEnterTrigger={onKeyPressEnterTrigger}
                />
            </Grid>
        </Paper>
    )
}

export default PackageSection
