import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { multiDateToISOConvertor } from "../../../Components/Generic/GenericMultiDateRange";
import { Box, Grid, Skeleton } from "@mui/material";
import DateRangeFilter from "../WidgetComponents/DateRangeFilter";
import { getAllJobs } from "../../../../redux/actions";
import TableTypeWidget from "../WidgetComponents/Table";
import GenericLoader from "../../../Components/Generic/GenericLoader";



const convertToDateRange = (value) => {
    const now = new Date(); // Current date
    let dateFrom = new Date(now); // Start date
    let dateTo = new Date(now); // End date

    switch (value) {
        case "1d":
            dateFrom.setDate(now.getDate() - 1);
            break;
        case "7d":
            dateFrom.setDate(now.getDate() - 7);
            break;
        case "1m":
            dateFrom.setMonth(now.getMonth() - 1);
            break;
        case "1y":
            dateFrom.setFullYear(now.getFullYear() - 1);
            break;
        default:
            throw new Error("Invalid value");
    }

    return {
        dateFrom: dateFrom.toISOString(),
        dateTo: dateTo.toISOString()
    };
};

const JobStatusTable = ({ item, styleView }) => {
    const dispatch = useDispatch()
    const [selectedRange, setSelectedRange] = useState("7d");
    const [dateFilter, setDateFilter] = useState([]);
    const [updatedItem, setUpdatedItem] = useState(item || []);
    const [dataLoader, setDataLoader] = useState(true)
    const handleSelection = (value) => {
        setSelectedRange(value);
        let dates
        if (value !== 'dateRange') {
            dates = convertToDateRange(value)
        }
        setDateFilter([])
        getDataDateRangeWise(dates?.dateFrom, dates?.dateTo)
    };

    const handleDateChange = (newDates) => {
        setDateFilter(newDates);
        let datarry;

        if (newDates.length >= 2) {
            datarry = multiDateToISOConvertor(newDates)
        }
        if (datarry?.length >= 2) {
            getDataDateRangeWise(datarry[0], datarry[1])
        }

    };
    const getDataDateRangeWise = (dateFrom = '', dateTo = '') => {
        let payload = {
            page: 1, limit: 10,
            dateFrom: dateFrom,
            dateTo: dateTo

        }
        setDataLoader(true)
        dispatch(getAllJobs(payload, (data) => {
            const transformedArray = data?.data?.map(obj => ({
                "jobName": obj?.commonDetails?.jobName,
                "status": obj?.commonDetails?.status,
                "warehouseName": obj?.commonDetails?.shipmentDetail[0]?.warehouseName,
                "createdOn": obj?.commonDetails?.jobAllocationDate,
                "assignedTo": "",
            }));
            setUpdatedItem((prev) => ({
                ...prev,
                values: transformedArray
            }))
            setDataLoader(false)
        }))
    }
    useEffect(() => {
        getDataDateRangeWise()
    }, [])
    return (<>
        < >

            <TableTypeWidget item={updatedItem} isDefaultDashboard={true} styleView={styleView} dataLoader={dataLoader}>
                <DateRangeFilter dateFilterOption={item?.dateFilterOption}
                    selectedRange={selectedRange}
                    dateFilter={dateFilter}
                    handleSelection={handleSelection}
                    handleDateChange={handleDateChange} />
            </TableTypeWidget>
        </>
    </>)
}

export default JobStatusTable;