import { Breadcrumbs, Grid, Paper, Typography, Button, IconButton, Divider, Collapse, DialogContent, Dialog, TextField, FormLabel, FormControl, FormControlLabel, Radio, Autocomplete, TablePagination, Box, Checkbox } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { ExpandMoreOutlined, Cancel } from '@mui/icons-material'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import theme, { textClasses, buttonClasses, inputClasses } from '../../../../../static/styles/theme'
// import { getKitDetails } from "../../../../../redux/actions/Kit";
import { getAllTemplates, getAllUsers, getTemplateHeader, fetchFile, approveRejectCompleteSales, getInHousePalletDetails } from '../../../../../redux/actions'
import { capitalizeSentence, getCamelCaseText, showToast, dataReadFunction } from '../../../../../services/functions'
import history from '../../../../../services/history'
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { customerMasterVisibilityFields } from "../../../TMS/Addressbook/utils";
import FileModal from "../../../EntityMaster/FileModal";
import moment from 'moment'
import GenericLoader from '../../../../Components/Generic/GenericLoader'
import { removeUntilE } from '../../../../../utils'
import { dateTimeFormater } from '../../../../../services/functions'
// import BundlingModal from "./PackagingModal";
import ApproveRejectConfirmationDialog from '../ApproveRejectConfirmationDialog'

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}))

export default function ViewPallet({ boosterKitId }) {

    let { palletId } = useParams();
    if (boosterKitId) {
        palletId = boosterKitId
    }
    const dispatch = useDispatch();
    const { AllUserData } = useSelector((state) => state.users);
    const templateHeader = useSelector(state => state?.inventory?.templateHeader) || {};
    const palletDetail = useSelector(state => state.InHousePalletization.inHousePalletDetail)
    const palletDetailItemsCount = useSelector(state => state.InHousePalletization.inHousePalletDetailItemCount)
    const { userPermissions } = useSelector((state) => state.userPermission);
    const [loader, setLoader] = useState(false);
    const [limit, setLimit] = useState(5);
    const [page, setPage] = useState(0);
    const [showHistory, setShowHistory] = useState(false);
    const [confirmModal, setConfirmModal] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(null);
    const [openModal, setOpenModal] = React.useState(false);
    const [selectedProducts, setSelectedProducts] = useState([])
    const [viewModal, setViewModal] = useState({ type: '', action: '' })
    const [displayUrl, setDisplayUrl] = useState("");
    const historySize = palletDetail?.shipmentDetail?.received_by?.length;
    const handleRemoveSelection = useCallback((id) => {
        const ids = selectedProducts;
        const updatedIds = ids.filter((itemId) => {
            return itemId !== id
        })
        setSelectedProducts(updatedIds)
    }, [selectedProducts, setSelectedProducts])

    const handleCloseModal = () => {
        setConfirmModal(null);
    }
    const displayFile = (fileUrl, fileKey) => {
        const imageUrlRegex = /\.(jpg|jpeg|png|gif|bmp|tiff)$/i;
        const isValidImageUrl = imageUrlRegex.test(fileKey);
        if (isValidImageUrl) {
            setOpenModal(true);
            setDisplayUrl(fileUrl);
        } else {
            window.open(fileUrl, "_blank");
        }
    };

    const handleFile = (fileKey) => {
        setOpenModal(false);
        setDisplayUrl('');
        dispatch(fetchFile({ fileKey }, displayFile));
    };

    const handleOpenDialog = (status) => {
        setDialogStatus(status)
    }

    const handleCloseDialog = () => {
        setDialogStatus(null);
    }

    let createrUserInfo = (palletDetail && AllUserData && AllUserData.data) ? AllUserData.data.filter(u => {
        if (palletDetail?.shipmentDetail?.created_by && palletDetail?.shipmentDetail?.created_by?._id) {
            return u._id == palletDetail?.shipmentDetail?.created_by?._id
        }
        return false;
    }) : []
    createrUserInfo = createrUserInfo.length > 0 ? createrUserInfo[0] : null
    let userInfo = (palletDetail && AllUserData && AllUserData.data) ? AllUserData.data.filter(u => {
        if (palletDetail?.shipmentDetail?.rejected_by && palletDetail?.shipmentDetail?.rejected_by?._id) {
            return u._id == palletDetail.shipmentDetail.rejected_by._id
        }
        return false;
    }) : []
    userInfo = userInfo.length > 0 ? userInfo[0] : null


    const refreshContent = () => {
        setLoader(true);
        dispatch(
            getInHousePalletDetails({ Pallet_ID: palletId, page: page + 1, limit: limit }, ({ data, count = 0 }) => {
                if (data) {
                    dispatch(
                        getTemplateHeader({ template_id: data?.shipmentDetail?.templateId || data?.shipmentDetail?.template_id }, () => { })
                    )
                    setLoader(false);
                }
                else {
                    setLoader(false);
                }

            })
        )
    }

    useEffect(() => {
        if (palletId || page || limit) {
            refreshContent();
            dispatch(getAllUsers({ "isAdminIncluded": true }))
        }
    }, [palletId, page, limit])

    function kittingHistoryDetails(history) {
        let message = ""
        let date = ""
        if (history?.jobStatus == "inprogress") {
            message = `${history?.jobType == "kitItemPicking" ? "Picking" : "Bundling"} (${history?.jobId}) initiated  for ${history?.totalNumberOfKits || 0} Kit (${history?.totalItemUnitsInAKit || 0} units) by ${AllUserData?.data?.find(dl => dl?.id === history?._id)?.name} at `
            date = dateTimeFormater(history?.date)
        } else if (history?.jobStatus == "") {
            // other job status code will come here
        } else {
            message = `${history?.jobType == "kitItemPicking" ? "Picking" : "Bundling"} (${history?.jobId}) initiated  for ${history?.totalNumberOfKits || 0} Kit (${history?.totalItemUnitsInAKit || 0} units) by ${AllUserData?.data?.find(dl => dl?.id === history?._id)?.name} at `
            date = dateTimeFormater(history?.date)
        }
        return (
            <Typography sx={{ ...textClasses.normalText, mb: 2, p: 1, color: theme.themeOrange, backgroundColor: theme.themeLightOrange, border: `1px solid ${theme.themeOrange}`, borderRadius: "12px" }}>
                {message} <strong>{date}</strong>
            </Typography>)
    }

    function TablePaginationActions(props) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };
        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === "rtl" ? (
                        <LastPageIcon />
                    ) : (
                        <FirstPageIcon />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === "rtl" ? (
                        <FirstPageIcon />
                    ) : (
                        <LastPageIcon />
                    )}
                </IconButton>
            </Box>
        );
    }
    return (<>
        <Typography sx={{ ...textClasses.cardTitle }}>Manage Pallet(s)</Typography>
        <Grid container>
            <Grid container item sm={9} xs={6}>
                <Breadcrumbs sx={{ mb: 1 }} data-testid="breadcrumb-viewkit">
                    <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }}>
                        <Link style={{ color: theme.themeOrange, textDecoration: "none" }} to="/dashboard/inventory/inhouse-palletization">
                            Manage Pallet
                        </Link>
                    </Typography>

                    <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>view details - {palletId}</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid container item sm={3} xs={6} justifyContent="end">
                {
                    (palletDetail?.shipmentDetail?.status && (["pending"].includes(palletDetail.shipmentDetail?.status))) &&
                    userPermissions?.permissions?.some((item) => item.permissionId === "editInhousePallet") &&
                    <Button variant="outlined" sx={{ ...buttonClasses.lynkitOrangeEmpty }} onClick={() => history.push(`/dashboard/data/edit-pallet/${palletDetail && palletDetail?.shipmentDetail?._id}`)}>Edit</Button>
                }
                {palletDetail?.shipmentDetail?.status && ["pending", "partialApproved"].includes(palletDetail?.shipmentDetail?.status) && userPermissions?.permissions?.some((item) =>
                    ["palletizationApproval", "dismantlePalletApproval"].includes(item.permissionId)
                ) &&
                    <>
                        <Button data-testid="approve-btn" variant="outlined" sx={{ ...buttonClasses.approvedButton, ml: 1 }} onClick={() => setConfirmModal("approved")}>Mark As Approved</Button>
                    </>
                }
                {
                    palletDetail?.shipmentDetail?.status && palletDetail?.shipmentDetail?.status === "pending" && userPermissions?.permissions?.some((item) =>
                        ["palletizationApproval", "dismantlePalletApproval"].includes(item.permissionId)
                    ) &&
                    <>
                        <Button data-testid="reject-btn" variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, backgroundColor: "#E9001A", border: "1px solid #E9001A", ml: 1, "&:hover": { backgroundColor: "#E9001A", border: "1px solid #E9001A", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", } }} onClick={() => setConfirmModal("rejected")}>Reject</Button>
                    </>
                }
                {
                    palletDetail?.shipmentDetail?.status && palletDetail?.shipmentDetail?.status == "rejected" &&
                    <>
                        <Button data-testid="rejected-btn" variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, color: '#E9001A', backgroundColor: "white", border: "1px solid #E9001A", ml: 1, "&:hover": { backgroundColor: 'white', border: "1px solid #E9001A", cursor: "default" } }} >Rejected</Button>
                    </>

                }
                {
                    palletDetail?.shipmentDetail?.status && palletDetail?.shipmentDetail?.status == "approved" &&
                    <>
                        <Button data-testid="approved-btn" variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, color: '#3E621E', backgroundColor: "white", border: "1px solid #3E621E", ml: 1, "&:hover": { backgroundColor: 'white', border: "1px solid #3E621E", cursor: "default" } }} >Approved</Button>
                    </>

                }
                {
                    palletDetail?.shipmentDetail?.status && palletDetail?.shipmentDetail?.status == "completed" &&
                    <>
                        <Button data-testid="completed-btn" variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, color: '#3E621E', backgroundColor: "white", border: "1px solid #3E621E", ml: 1, "&:hover": { backgroundColor: 'white', border: "1px solid #3E621E", cursor: "default" } }} >Completed</Button>
                    </>

                }
                {
                    palletDetail?.shipmentDetail?.status && (palletDetail?.shipmentDetail?.status == "approved" && !["completed"].includes(palletDetail?.shipmentDetail?.jobStatus)) && palletDetail?.shipmentDetail?.availableForBundeling ?
                        <>
                            <Button data-testid="start-bundling-btn" variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, ml: 1 }} onClick={() => { setViewModal({ ...viewModal, type: "startBundling", action: palletDetail?.shipmentDetail }) }}>Start Bundling</Button>
                        </> : ""

                }

            </Grid>
        </Grid>
        {/* <ConfirmDialog asn={palletDetail} status={dialogStatus} templateHeader={templateHeader} handleClose={handleCloseDialog} refreshContent={refreshContent} /> */}
        {/* <ConfirmDialog status={confirmModal} handleClose={handleCloseModal} selectedEntries={salesOrderDetail ? [salesOrderDetail] : []} refreshTableData={refreshContent} /> */}
        {viewModal?.type == "startBundling" &&
            <BundlingModal
                viewModal={viewModal}
                setViewModal={setViewModal}
                refreshTableData={refreshContent}
            />
        }
        {
            loader
                ?
                <>
                    <Grid container justifyContent={"center"} alignItems="center">
                        <GenericLoader data-testid="genericloader" />
                    </Grid>
                </>
                :
                <>
                    {
                        palletDetail &&
                        <>
                            <Grid container>
                                <Grid container item sm={9} xs={6}></Grid>
                                <Grid container item sm={3} xs={6} justifyContent={"end"}>
                                    <ApproveRejectConfirmationDialog
                                        status={confirmModal}
                                        handleClose={handleCloseModal}
                                        selectedEntries={palletDetail ? [palletDetail] : []}
                                        // setSelectedEntries={setSelectedEntries}
                                        refreshTableData={refreshContent}
                                        handleRemoveSelection={handleRemoveSelection}
                                        cardType={"palletization"}
                                    />
                                    {/* <ConfirmDialog status={confirmModal} handleClose={handleCloseModal} selectedEntries={palletDetail ? [palletDetail] : []} refreshTableData={refreshContent} /> */}
                                    {/* <DispatchDialog status={dispatchModal} handleClose={handleCloseModal} entry={salesOrderDetail ? [salesOrderDetail] : []}/> */}

                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 2 }}>
                                <Grid container item sm={8} xs={8}>
                                    <Paper sx={{ width: "100%", p: 2, height: "100%" }} alignItems={"center"}>
                                        <Grid container sx={{ mb: 2 }} alignItems={"center"}>
                                            <Grid container item sm={4} xs={4} justifyContent={"start"} alignItems={"center"}>
                                                <Typography sx={{ ...textClasses.boldText, fontSize: "18px" }}>Components detail</Typography>
                                            </Grid>
                                            <Grid container item sm={7} xs={4} justifyContent={"end"}>
                                                <Typography sx={{ ...textClasses.boldText, fontSize: "18px", color: theme.themeOrange }}>
                                                    Required Quantity :

                                                </Typography>
                                                <Typography sx={{ ...textClasses.boldText, fontSize: "18px", color: theme.themeOrange, ml: 1 }}>
                                                    {
                                                        (palletDetail?.shipmentDetail?.totalQuantity ? palletDetail?.shipmentDetail?.totalQuantity : 0)
                                                    }
                                                </Typography>

                                            </Grid>
                                            {/* <Grid container item sm={4} xs={4} justifyContent={"end"}>
                                                <Typography sx={{...textClasses.boldText, fontSize: "18px", color: theme.themeOrange}}>
                                                    Remaining Quantity :
                                                    
                                                </Typography>
                                                <Typography sx={{...textClasses.boldText, fontSize: "18px", color: theme.themeOrange, ml: 1}}>
                                                {
                                                    (palletDetail.kitDetails.totalQuantity ? palletDetail.kitDetails.totalQuantity : 0) - ((palletDetail.kitDetails.receivedQuantity ?? 0)-(palletDetail.kitDetails.exceedQuantity ?? 0)) - (palletDetail.kitDetails.rejectedQuantity ?? 0) - (palletDetail.kitDetails.closedQuantity ?? 0)
                                                }   
                                                </Typography>
                                                
                                            </Grid> */}
                                        </Grid>
                                        <Grid container sx={{ maxHeight: "800px", overflowY: "scroll" }}>
                                            {
                                                palletDetail && palletDetail?.itemDetail?.map((order, i) => {
                                                    return (
                                                        <OrderCollapsableCard index={i} order={order} key={i}
                                                            // isPartialReceived={isPartialReceived}
                                                            selectedProducts={selectedProducts}
                                                            setSelectedProducts={setSelectedProducts}
                                                            templateHeader={templateHeader}
                                                            handleFile={handleFile}
                                                        />
                                                    )
                                                })
                                            }



                                        </Grid>

                                        {
                                            palletDetail && palletDetail?.itemDetail?.length && (<Grid container alignItems={"center"} justifyContent="center">
                                                <TablePagination
                                                    component="div"
                                                    count={palletDetailItemsCount}
                                                    page={page}
                                                    rowsPerPage={limit}
                                                    rowsPerPageOptions={palletDetailItemsCount > 5 ? [5, 10, 25, 50, 100] : [5]}
                                                    onPageChange={(e, newPage) => {
                                                        setPage(newPage)

                                                    }}
                                                    onRowsPerPageChange={(e) => {
                                                        setLimit(e.target.value);
                                                        setPage(0);
                                                    }}
                                                    ActionsComponent={TablePaginationActions}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        mt: "0.5em",
                                                        "& .MuiTablePagination-selectLabel ": {
                                                            margin: '0'
                                                        },
                                                        "& .MuiTablePagination-displayedRows": {
                                                            margin: '0'
                                                        }
                                                    }}
                                                />
                                            </Grid>)
                                        }
                                    </Paper>
                                </Grid>
                                <Grid container
                                    item
                                    sm={12}
                                    md={4}
                                    order={{ xs: 1, sm: 1, md: 2 }}
                                    sx={{ height: "100%", overflowY: "auto" }}
                                    padding={0}>
                                    <Paper sx={{
                                        width: "100%",
                                        p: 2,
                                        mb: 2,
                                        minHeight: "50%",
                                        maxHeight: "80%",
                                    }}>
                                        <Typography sx={{ ...textClasses.boldText, fontSize: "18px", mb: 2 }}>Shipment details</Typography>
                                        <Grid container>
                                            {
                                                templateHeader && templateHeader?.shipmentHeader && templateHeader?.shipmentHeader?.map((head, i) => {

                                                    let formData = palletDetail?.shipmentDetail?.formData || {}
                                                    let value = dataReadFunction(head, formData)
                                                    if (head?.renderOption?.length) {
                                                        value = renderOptionHandler(formData, head?.renderOption) || ""
                                                    }
                                                    return (
                                                        <Grid container item alignItems={"center"} sx={{ mb: 1 }}>

                                                            <Grid container>
                                                                <Grid container item sm={4} xs={6}>
                                                                    <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>{head.label}: </Typography>
                                                                </Grid>
                                                                <Grid container item sm={8} xs={6}>
                                                                    <Typography sx={{ ...textClasses.normalText }}>
                                                                        {formData[head.name] ?
                                                                            head.type === "file" ? (
                                                                                <div
                                                                                    onClick={() =>
                                                                                        handleFile(value)
                                                                                    }
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            ...buttonClasses.viewFile,
                                                                                            cursor: 'pointer'
                                                                                        }}
                                                                                    >
                                                                                        View File
                                                                                    </span>
                                                                                </div>
                                                                            ) :

                                                                                head.type == "date"
                                                                                    ? value && dateTimeFormater(value, head?.format || "DD MMM YYYY") || "-"
                                                                                    : Array.isArray(value)
                                                                                        ? value.join(", ") || "-"
                                                                                        : value || "-"
                                                                            :
                                                                            "-"}
                                                                    </Typography>
                                                                </Grid>
                                                                <FileModal
                                                                    openModal={openModal}
                                                                    displayUrl={displayUrl}
                                                                    setOpenModal={setOpenModal}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                            }

                                            <Grid container item alignItems={"center"} sx={{ mb: 1 }}>
                                                <Grid container>
                                                    <Grid container item sm={4} xs={6}>
                                                        <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>Pallet Create at: </Typography>
                                                    </Grid>
                                                    <Grid container item sm={8} xs={6}>
                                                        <Typography sx={{ ...textClasses.normalText }}>{palletDetail?.shipmentDetail?.createdAt ? moment(palletDetail?.shipmentDetail?.createdAt).format("DD MMM YYYY hh:mm A") : "-"}</Typography>
                                                    </Grid>
                                                </Grid>


                                            </Grid>
                                            <Grid container item alignItems={"center"} sx={{ mb: 1 }}>
                                                <Grid container>
                                                    <Grid container item sm={4} xs={6}>
                                                        <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>Pallet Created by:</Typography>
                                                    </Grid>
                                                    <Grid container item sm={8} xs={6}>
                                                        <Typography sx={{ ...textClasses.normalText }}>{createrUserInfo && createrUserInfo.name} , {createrUserInfo && createrUserInfo.designation}</Typography>
                                                    </Grid>
                                                </Grid>


                                            </Grid>
                                        </Grid>
                                    </Paper>

                                    {userPermissions?.permissions?.find(dl => dl.permissionId == "get_business_partners") &&
                                        customerMasterVisibilityFields?.length > 0 && palletDetail?.shipmentDetail?.customerDetail?.blink_id &&
                                        <Paper
                                            sx={{
                                                width: "100%",
                                                p: 2,
                                                mb: 2,
                                                minHeight: "50%",
                                                maxHeight: "80%",
                                            }}
                                        >
                                            <Typography
                                                sx={{ ...textClasses.boldText, fontSize: "18px", mb: 2 }}
                                            >
                                                Customer details
                                            </Typography>
                                            <Grid container>
                                                {customerMasterVisibilityFields?.map((head, i) => {
                                                    return (
                                                        <Grid container item alignItems={"center"} sx={{ mb: 1 }}>
                                                            <Grid container>
                                                                <Grid container item sm={4} xs={6}>
                                                                    <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>
                                                                        {head.label}:
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid container item sm={8} xs={6}>
                                                                    <Typography sx={{ ...textClasses.normalText }}>
                                                                        {
                                                                            head.type == "date"
                                                                                ?
                                                                                dataReadFunction(head, palletDetail?.shipmentDetail?.customerDetail) && dateTimeFormater(dataReadFunction(head, palletDetail?.shipmentDetail?.customerDetail)) || "-"
                                                                                :
                                                                                head?.renderOption?.length > 0
                                                                                    ? renderOptionHandler(palletDetail?.shipmentDetail?.customerDetail, head?.renderOption) || "-"
                                                                                    : dataReadFunction(head, palletDetail?.shipmentDetail?.customerDetail) || "-"
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </Paper>
                                    }
                                    {
                                        palletDetail && palletDetail?.shipmentDetail?.rejectedReason &&
                                        <Paper sx={{ width: "100%", p: 2, mt: 2 }}>
                                            <Grid container>
                                                <Grid container item sm={6} xs={6}>
                                                    <Typography sx={{ ...textClasses.boldText, fontSize: "18px", mb: 2 }}>Rejection Reason</Typography>
                                                </Grid>
                                                <Grid container item sm={6} xs={6} justifyContent="end">
                                                    <Typography sx={{ ...textClasses.normalText, mb: 2 }}>{
                                                        palletDetail?.shipmentDetail?.rejected_by?.date
                                                            ? moment(palletDetail?.shipmentDetail?.rejected_by?.date).format("DD MMM YYYY hh:mm A")
                                                            : "-"
                                                    }</Typography>
                                                </Grid>
                                            </Grid>
                                            <Typography sx={{ ...textClasses.normalText, mb: 2 }}>{userInfo && userInfo.name}, {userInfo && userInfo.designation}</Typography>
                                            <Typography sx={{ ...textClasses.normalText, mb: 2 }}>
                                                {palletDetail?.shipmentDetail?.rejectedReason ? palletDetail.shipmentDetail.rejectedReason : "-"}
                                            </Typography>
                                        </Paper>
                                    }
                                    {
                                        palletDetail && palletDetail?.shipmentDetail?.received_by?.length > 0 &&
                                        <Paper sx={{ width: "100%", p: 2, mt: 2 }}>
                                            <Grid container>
                                                <Grid container item sm={6} xs={6}>
                                                    <Typography sx={{ ...textClasses.boldText, fontSize: "18px", mb: 2 }}>Receiving History</Typography>
                                                </Grid>
                                            </Grid>

                                            <Box sx={{ maxHeight: "50vh", overflowY: "auto" }}>
                                                {palletDetail?.shipmentDetail?.received_by && sortDataByDate(palletDetail?.shipmentDetail?.received_by || [])?.slice(0, showHistory ? historySize : 4)
                                                    .map((history) => {
                                                        return (
                                                            <GenericSummaryView
                                                                isSummary={true}
                                                                headingText={historySummary(history || {})}
                                                                detailChilden={historyDetails(history || {})}
                                                            />
                                                        );
                                                    })}
                                            </Box>
                                            {historySize > 5 && (
                                                <>
                                                    <Grid
                                                        container
                                                        item
                                                        justifyContent="center"
                                                        alignItems={"center"}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                ...textClasses.boldText,
                                                                color: theme.themeOrange,
                                                                textDecoration: "underline",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => setShowHistory(!showHistory)}
                                                        >
                                                            {showHistory ? (
                                                                <>Collapse</>
                                                            ) : (
                                                                <>View All ({historySize})</>
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            )}
                                            {/* {
                                                        palletDetail?.shipmentDetail?.received_by?.slice(0, showHistory ? historySize : 5)?.map((history) => {
                                                    return <Typography sx={{ ...textClasses.normalText, mb: 2, p: 1, color: theme.themeOrange, backgroundColor: theme.themeLightOrange, border: `1px solid ${theme.themeOrange}`, borderRadius: "12px" }}>
                                                        Received <strong>{history.quantity} pcs</strong> by {history.name} at <strong>{moment(history.date).format(
                                                            "DD MMM YYYY hh:mm A"
                                                        )}</strong>
                                                    </Typography>
                                                }) */}
                                            {/* } */}
                                            {/* {
                                                historySize > 5 &&
                                                <>
                                                    <Grid container item justifyContent="center" alignItems={"center"}>
                                                        <Typography sx={{ ...textClasses.boldText, color: theme.themeOrange, textDecoration: "underline", "cursor": "pointer" }} onClick={() => setShowHistory(!showHistory)}>
                                                            {
                                                                showHistory
                                                                    ?
                                                                    <>Collapse</>
                                                                    :
                                                                    <>View All ({historySize})</>
                                                            }
                                                        </Typography>
                                                    </Grid>

                                                </>
                                            } */}
                                        </Paper>
                                    }

                                </Grid>
                            </Grid>
                        </>
                    }

                </>
        }
    </>)
}


const OrderCollapsableCard = ({ index, order, isPartialReceived, templateHeader, handleFile, selectedProducts, setSelectedProducts }) => {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(index == 0 ? true : false);
    const fields = Object.keys(order.formData);
    const masterType = order.itemMasterDetail ? "itemMaster" : "";
    const statusColor = {
        approved: "#3E621E",
        completed: "#3E621E",
        rejected: "#E9001A",
        drafted: "#FF0101",
        pending: "#FF7200",
        default: "#000"
    }
    const statusText = {
        approved: "Approved",
        completed: "Completed",
        rejected: "Rejected",
        drafted: "Drafted",
        pending: "Pending"

    }

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    const ReceivingTypes = [{ label: "Available Quantity", value: "availableQuantity" }, { label: "Less Quantity", value: "lessQuantity" }, { label: "Extra Quantity", value: "exceedQuantity" }]

    return (
        <Paper sx={{ width: "100%", p: 2, border: `1px solid ${theme.themeOrange}`, mb: 2, overflowY: "scroll" }}>
            <Grid container>
                {/* {
                    // isPartialReceived && (order.status === "partialReceived" || order.status === "incoming")
                    order.status === "pending"
                    // true
                    && <Checkbox
                        onChange={(e) => {
                            if (e.target.checked) {
                                setSelectedProducts((prev) => [...prev, order.product_ID])
                            } else {
                                const ids = selectedProducts.filter((product) => product !== order.product_ID)
                                setSelectedProducts(ids)
                            }
                        }}
                        checked={selectedProducts?.includes(order.product_ID)
                        }
                    />} */}
                <Grid container item sm={4} xs={3} justifyContent="start" alignItems="center">
                    {
                        templateHeader && templateHeader?.itemHeader?.map((h, i) => {
                            return (

                                h.name == "product_ID" ?
                                    <>
                                        <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>{h.label}:</Typography>
                                        <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, ml: 1 }}>{order.product_ID}</Typography>
                                    </>
                                    :
                                    <></>

                            )
                        })
                    }

                </Grid>
                <Grid container item justifyContent="end" alignItems="center" width={"auto"}>
                    {templateHeader && templateHeader?.itemHeader?.map((f, i) => {
                        if (f.name === "UOM") {
                            return (
                                <>
                                    <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>Required Quantity: </Typography>
                                    <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, ml: 2 }}>{order.formData.totalQuantity} {order.formData[f.name]}</Typography>
                                </>
                            )
                        }
                    })}
                    {
                        order.status == "approved"
                            ?
                            <Button disabled size='small' sx={{ ml: 2, borderRadius: "16px", backgroundColor: statusColor[order.status], color: "white !important", textTransform: "none" }}>{getCamelCaseText(statusText[order.status])} </Button>
                            :
                            <></>

                    }
                    {
                        order.status == "completed" || order.status == "pending"
                            ?
                            <Button disabled size='small' sx={{ ml: 2, borderRadius: "16px", backgroundColor: statusColor[order.status], color: "white !important", textTransform: "none" }}>{getCamelCaseText(statusText[order.status])}</Button>

                            :
                            <></>
                    }
                    {
                        order.status == "drafted" || order.status == "rejected"
                            ?
                            <Button disabled size='small' sx={{ ml: 2, borderRadius: "16px", backgroundColor: statusColor[order.status], color: "white !important", textTransform: "none" }}>{getCamelCaseText(statusText[order.status])}</Button>
                            :
                            <></>

                    }
                    {/* <Button disabled size='small' sx={{ml: 2, borderRadius: "16px", backgroundColor: statusColor[order.status], color: "white !important", textTransform: "none"}}>{getCamelCaseText(order.status)} ({order.totalQuantity})</Button> */}
                    <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, textDecoration: "underline", ml: 2 }}>
                        {
                            expanded ? "Hide Details" : "Show Details"
                        }
                        <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreOutlined sx={{ color: theme.themeOrange }} />
                        </ExpandMore>
                    </Typography>

                </Grid>
            </Grid>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Divider />
                <Grid container sx={{ pt: 2 }}>
                    {
                        templateHeader && templateHeader?.itemHeader?.map((f, i) => {
                            return (
                                <Grid container item sm={6} alignItems={"center"} sx={{ mb: 0 }}>
                                    <Grid container>
                                        <Grid container item sm={4} xs={6}>
                                            <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>{f.label}:</Typography>
                                        </Grid>
                                        <Grid container item sm={8} xs={6}>
                                            <Typography sx={{ ...textClasses.normalText, ml: 1 }}>
                                                {order?.formData[f.name] ?
                                                    f.type === "file" ? (
                                                        <div onClick={() => handleFile(order?.formData[f.name])}>
                                                            <span
                                                                style={{
                                                                    ...buttonClasses.viewFile,
                                                                    cursor: "pointer"
                                                                }}
                                                            >
                                                                View File
                                                            </span>
                                                        </div>
                                                    ) : order?.formData[f.name] : "-"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                    {
                        order?.itemMasterHeaders?.filter(h => ["SKU_ID", "UOM"].indexOf(h.name) < 0).map((head, i) => {
                            return (
                                <Grid container item sm={6} alignItems={"center"} sx={{ mb: 0 }}>
                                    <Grid container>
                                        <Grid container item sm={4} xs={6}>
                                            <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>{head.label}:</Typography>

                                        </Grid>
                                        <Grid container item sm={8} xs={6}>
                                            <Typography sx={{ ...textClasses.normalText, ml: 1 }}>
                                                {/* {order.itemMasterDetail[head.name]? order.itemMasterDetail[head.name] : "-"} */}
                                                {order.itemMasterDetail[head.name] ?
                                                    head.type === "file" ? (
                                                        <div onClick={() => handleFile(order.itemMasterDetail[head.name])}>
                                                            <span
                                                                style={{
                                                                    ...buttonClasses.viewFile,
                                                                    cursor: "pointer"
                                                                }}
                                                            >
                                                                View File
                                                            </span>
                                                        </div>
                                                    ) :
                                                        <span style={{ marginLeft: '12px' }}>
                                                            {order.itemMasterDetail[head.name]}
                                                        </span>
                                                    : "-"
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            )
                        })
                    }
                    {order.receivingType && <Grid container item sm={6} alignItems={"center"} sx={{ mb: 0 }}>
                        <Grid container>
                            <Grid container item sm={4} xs={6}>
                                <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>Receiving Type:</Typography>

                            </Grid>
                            <Grid container item sm={8} xs={6}>
                                <Typography sx={{ ...textClasses.normalText, ml: 1 }}>

                                    {ReceivingTypes.find(type => type.value === order.receivingType)?.label ?? "-"}
                                </Typography>
                            </Grid>
                        </Grid>

                    </Grid>}

                </Grid>
            </Collapse>

        </Paper>
    )
}

