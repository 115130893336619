import React, { useState, useEffect } from 'react';
import { TextField, Popper, Box, Typography, Button, Grid } from '@mui/material';
import { StaticDatePicker  } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import theme,{baseButton,buttonClasses,inputClasses,textClasses} from '../../../static/styles/theme';
import { selectStylesOverride } from '../../../utils/util';
import {ClickAwayListener} from '@mui/base';
import { showToast } from '../../../services/functions';

export default function GenericDateRange({fromDate=null,toDate=null,applyDateFiter}) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true); // Open popper when input is clicked
  };

  const handleClose = () => {
    setOpen(false); // Close popper on clicking Apply or outside
    setStartDate(null)
    setEndDate(null)
  };

    const handleDateChange = (date, setDate, currentDate) => {
        if (currentDate && date.isSame(currentDate)) {
            setDate(null); // Clear the date if the same date is clicked
        } else {
            setDate(date);
        }
    };


  // Close the Popper when both dates are selected
//   useEffect(() => {
//     if (startDate && endDate) {
//       setOpen(false);
//     }
//   }, [startDate, endDate]);

  useEffect(() => {
    if (fromDate && toDate) {
        setStartDate(fromDate)
        setEndDate(toDate)
    }
  }, [fromDate, toDate]);


  const formatRangeText = (start, end) => {
    if (!start && !end) return '';
    if (start && end) return `${start.format('DD/MM/YYYY')} - ${end.format('DD/MM/YYYY')}`;
    return start ? `${start.format('DD/MM/YYYY')} -` : `- ${end.format('DD/MM/YYYY')}`;
  };

//   console.log({startDate,endDate})
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ position: 'relative',}}>
        <TextField
          label="Date Range"
          value={formatRangeText(fromDate, toDate)}
          onClick={handleClick}
          readOnly
          size="small"
          sx={{
            ...selectStylesOverride,
          }}
        />
              <Popper className="date-picker" open={open} anchorEl={anchorEl} placement="bottom-start" sx={{ border: '1px solid #ccc', backgroundColor: 'white', zIndex: 1300 }} disablePortal>
                  <ClickAwayListener onClickAway={(event) => {
                      if (anchorEl && anchorEl.contains(event.target)) {
                          // Do not close the popper if the user clicks inside the TextField
                          return;
                      }
                      handleClose();
                  }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Box sx={{ display: 'flex', gap: 1 }}>
                              {/* Start Date Picker */}
                              <Box>
                                  <StaticDatePicker
                                      displayStaticWrapperAs="desktop"
                                      value={startDate}
                                      onChange={(newValue) => handleDateChange(newValue, setStartDate, startDate)}
                                      //   renderInput={(params) => <TextField {...params} />}
                                      slotProps={{ textField: { size: "small" } }}

                                  />
                              </Box>
                              {/* End Date Picker */}
                              <Box>
                                  <StaticDatePicker
                                      displayStaticWrapperAs="desktop"
                                      value={endDate}
                                      onChange={(newValue) => handleDateChange(newValue, setEndDate, endDate)}
                                      minDate={startDate}
                                      //   renderInput={(params) => <TextField {...params} />}
                                      slotProps={{ textField: { size: "small" } }}
                                  />
                              </Box>
                          </Box>
                          {/* Apply Button */}
                          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                              <Button
                                  variant="contained"
                                  onClick={() => {
                                      if (new Date(endDate).getTime() <= new Date(startDate)) {
                                          showToast("End date must be greater than start date!", true)
                                          return
                                      }
                                      applyDateFiter(startDate, endDate, () => {
                                          handleClose()
                                      })
                                  }}
                                  sx={{
                                      // ...buttonClasses.lynkitOrangeFill,
                                      // color:"#1976d2",
                                      textTransform: "none",
                                      textAlign: "start",
                                      ...textClasses.t12n,
                                      padding: "10px 10px",
                                      mb: 1, mr: 1
                                  }}
                              >
                                  Apply
                              </Button>
                              <Button
                                  variant="contained"
                                  onClick={handleClose}
                                  sx={{
                                      // ...buttonClasses.lynkitOrangeEmpty,
                                      textTransform: "none",
                                      textAlign: "start",
                                      ...textClasses.t12n,
                                      padding: "10px 10px",
                                      mb: 1, mr: 1
                                  }}
                              >
                                  Cancel
                              </Button>
                          </Box>
                      </Box>
                  </ClickAwayListener>
              </Popper>
          </Box>
      </LocalizationProvider>
  );
}
