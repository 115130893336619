/* eslint-disable */

import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import {
    Modal, Typography, Button, Grid, Paper, FormControl, IconButton, FormControlLabel, Card, Select,
    MenuItem, FormLabel, RadioGroup, Radio, ButtonGroup, Box, Dialog, DialogContent, Switch, Checkbox, CircularProgress,
    Autocomplete, TextField, FormGroup, DialogTitle, DialogActions, Slider

} from '@mui/material'
import { Remove, Add, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { selectStylesOverride } from '../../../../utils/util';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import theme, { textClasses, buttonClasses, inputClasses } from '../../../../static/styles/theme';
import { styled } from '@mui/material/styles';
import ShipmentOnlyIcon from "../../../../static/images/labels/shipmentOnly.svg"
import PackageOnlyIcon from "../../../../static/images/labels/packageOnly.svg"
import PackageAndEveryItemIcon from "../../../../static/images/labels/packageAndEveryItem.svg"
import EveryItemIcon from "../../../../static/images/labels/everyItem.svg"
import PackageAndSingleItemIcon from "../../../../static/images/labels/packageAndSingleItem.svg"
import SingleItemIcon from "../../../../static/images/labels/singleItemOnly.svg"
import Barcode from 'react-barcode';
import Draggable from 'react-draggable';
import { Resizable } from 're-resizable';
import QRCode from 'qrcode.react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getInventoryForLabel, getTemplateHeader, getShipmentMasterHeader,
    getLabelConfigration, uploadLabelLogo, getLabelConfigInventoryOption, 
    getCommanConfig
} from '../../../../redux/actions';;
import html2canvas from 'html2canvas';
import { Document, Page, Text, Image, View, StyleSheet, PDFViewer, BlobProvider } from '@react-pdf/renderer';
import { showToast, convertDocsBase64, isFileValid, dateTimeFormater,dataReadFunction } from '../../../../services/functions';
import ReactToPrint from 'react-to-print';
import { FETCH_ORDER_CONNECTED_MASTER_DETAIL, FETCH_SHIPMENT_TEMPLATE_HEADER } from '../../../../redux/types';
import SignatureCanvas from 'react-signature-canvas';
import GenericLoader from '../../../Components/Generic/GenericLoader';
import { getFormLabelValueDetails } from '../../../../services/formFunction';
import GenericLabelTable from '../../../Components/CustomTable/GenericLabelTable';
import GenericToggle from '../../../Components/GenericToggle';
// const inchToPixel = (inches) => {
//     return parseInt(inches * 96);
// }

const allLabelSvg = {
    ShipmentOnlyIcon: ShipmentOnlyIcon,
    PackageOnlyIcon: PackageOnlyIcon,
    PackageAndEveryItemIcon: PackageAndEveryItemIcon,
    EveryItemIcon: EveryItemIcon,
    PackageAndSingleItemIcon: PackageAndSingleItemIcon,
    SingleItemIcon: SingleItemIcon,
    
}

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

const SelectLabel = ({ spiType, labelType, setLabelType,labelTypes=[]  }) => {
    return (
        <>
            <Grid container sx={{ mt: 1 }}>
                {
                    labelTypes?.filter(lt => lt.allowed.indexOf(spiType) >= 0).map((label, i) => {
                        return (
                            <Grid sm={4} xs={6} sx={{ p: 1 }}>
                                <Paper elevation={2} sx={{ p: 1, height: "100%", cursor: "pointer", "&:hover": { boxShadow: "0px 4px 4px 0px #FF7200" } }} onClick={() => setLabelType(label)}>
                                    <Grid container>
                                        <Radio checked={labelType?.value == label.value} sx={{ color: theme.themeOrange, "&, &.Mui-checked": { color: theme.themeOrange } }} />
                                    </Grid>
                                    <Grid container direction={"column"} alignItems='center'>
                                        <img src={allLabelSvg[label?.icon]} width={140} height={140} />
                                        <Typography sx={{ ...textClasses.boldText, fontSize: "18px", fontWeight: "600", color: "#827878", textAlign: "center", mt: 2 }}>
                                            {label.label}
                                        </Typography>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}

const ConfigFieldSection = ({ sectionName, header, headerSection, handleChange, sectionType, configType }) => {
    // //("header",header);
    return (
        <>
            <fieldset
                style={{
                    border: `1px solid ${theme.themeGray}`,
                    padding: '8px',
                    borderRadius: "8px",
                    margin: '10px',
                    position: 'relative',
                    width: '97%',
                }}
            >
                <legend
                    style={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                        width: 'auto',
                        color: '#FF7200',
                        backgroundColor: 'white',
                        padding: '2px 8px',
                        position: 'absolute',
                        top: '-10px',
                    }}
                >
                    {sectionName || "Shipment"}
                </legend>
                <Grid container spacing={2}>
                    {header?.map((h, i) => (
                        <Grid item sm={4} xs={4} key={i}>
                            {configType === "label" ? (
                                <FormControlLabel
                                    value={h.showInLabel || false}
                                    control={
                                        <Checkbox
                                            checked={h.showInLabel || false}
                                            onChange={() => handleChange(sectionType, headerSection, h.name)}
                                        />
                                    }
                                    label={h.label}
                                />
                            ) : (
                                <FormControlLabel
                                    value={h.encodeConfig || false}
                                    control={
                                        <Checkbox
                                            checked={h.encodeConfig || false}
                                            onChange={() => handleChange(sectionType, headerSection, h.name)}
                                        />
                                    }
                                    label={h.label}
                                />
                            )}
                        </Grid>
                    ))}
                </Grid>
            </fieldset>
        </>
    )
}

const ConfigureLabel = ({ invType, labelType, spiType, labelConfig, encodeConfig, templateHeader, asnHeader, showModal, handleCloseModal, saveConfig }) => {
    const [config, setConfig] = useState(labelConfig);
    const [encode, setEncode] = useState(encodeConfig);
    const [configType, setConfigType] = useState("label");

    // console.log("headers coming",templateHeader,asnHeader);
    useEffect(() => {
        setConfig(labelConfig);
        setEncode(encodeConfig);
    }, [showModal]);

    const configOptions = configType == "label" ? config : encode;

    // ////("Config Type ", configType);
    // ////("Config", config);
    // ////("Encode", encode);
    ////("Config Options ", configOptions);
    ////("HOOLHA", labelType, spiType);
    const handleChange = (sectionType, section, id) => {
        ////("Changing ID", id);
        if (configType == "label") {
            let oldConfig = { ...config };

            if (sectionType == "scanned") {
                const i = oldConfig[sectionType].map(f => f.name).indexOf(id);
                oldConfig[sectionType][i].showInLabel = oldConfig[sectionType][i].showInLabel ? !oldConfig[sectionType][i].showInLabel : true;
            }
            else {
                const i = oldConfig[sectionType][section].map(f => f.name).indexOf(id);
                oldConfig[sectionType][section][i].showInLabel = oldConfig[sectionType][section][i].showInLabel ? !oldConfig[sectionType][section][i].showInLabel : true;
            }
            setConfig(oldConfig)
        }
        else {
            let oldConfig = { ...encode };
            const i = oldConfig[sectionType][section].map(f => f.name).indexOf(id);
            oldConfig[sectionType][section][i].encodeConfig = oldConfig[sectionType][section][i].encodeConfig ? !oldConfig[sectionType][section][i].encodeConfig : true;
            setEncode(oldConfig)
        }

    }

    const handleSave = () => {
        saveConfig(configType, config);
        handleCloseModal();
    }

    const isShipmentSection = (() => {
        let boxes = [];
        if (configOptions.inv && configOptions.inv.shipmentHeader) {
            boxes = [...boxes, ...configOptions.inv.shipmentHeader]
        }
        if (configOptions.asn && configOptions.asn.shipmentHeader) {
            boxes = [...boxes, ...configOptions.asn.shipmentHeader]
        }
        if (configOptions.asn && configOptions.asn.supplierMaster) {
            boxes = [...boxes, ...configOptions.asn.supplierMaster]
        }
        if (configOptions.asn && configOptions.asn.customerMaster) {
            boxes = [...boxes, ...configOptions.asn.customerMaster]
        }
        return boxes.length > 0;
    })();

    const isPackageSection = (() => {
        let boxes = [];
        if (configOptions.inv && configOptions.inv.packageHeader) {
            boxes = [...boxes, ...configOptions.inv.packageHeader]
        }
        if (invType == "Package" && configOptions.asn && configOptions.asn.itemHeader) {
            boxes = [...boxes, ...configOptions.asn.itemHeader]
        }
        if (invType == "Package" && configOptions.asn && configOptions.asn.itemMaster) {
            boxes = [...boxes, ...configOptions.asn.itemMaster]
        }
        if (configOptions.scanned && configOptions.scanned?.itemMaster) {
            boxes = [...boxes, ...configOptions.scanned?.itemMaster]
        }
        return boxes.length > 0;
    })();

    const isItemSection = (() => {
        let boxes = [];
        if (configOptions.inv && configOptions.inv.itemHeader) {
            boxes = [...boxes, ...configOptions.inv.itemHeader]
        }
        if (["Item", "Kit"].includes(invType) && configOptions.asn && configOptions.asn.itemHeader) {
            boxes = [...boxes, ...configOptions.asn.itemHeader]
        }
        if (["Item", "Kit"].includes(invType) && configOptions.asn && configOptions.asn.itemMaster) {
            boxes = [...boxes, ...configOptions.asn.itemMaster]
        }
        if (configOptions.scanned && configOptions.scanned?.itemMaster) {
            boxes = [...boxes, ...configOptions.scanned?.itemMaster]
        }
        ////("IsItemSection", boxes);
        return boxes.length > 0;
    })();

    const renderASNHeader = () => {
        return <>
            {
                configOptions?.asn && configOptions?.asn?.itemHeader &&
                <ConfigFieldSection
                    configType={configType}
                    sectionName={"Asn Item Details"}
                    headerSection={"itemHeader"}
                    // header={configOptions.asn.itemHeader.filter(h => [
                    //     "product_ID", "SKU_Detail", "UOM", "quantity",
                    //     ...(configOptions?.inv?.itemHeader?.map(el => el.name) || [])
                    // ].indexOf(h.name) < 0)}
                    header={configOptions?.asn?.itemHeader|| []}
                    sectionType={"asn"}
                    handleChange={handleChange}
                />
            }
            {
                configOptions?.asn && configOptions?.asn?.itemMaster &&
                <ConfigFieldSection
                    configType={configType}
                    sectionName={"Asn Item Master"}
                    headerSection={"itemMaster"}
                    header={configOptions.asn.itemMaster.filter(h => [
                        "SKU_ID", "SKU_Detail", "UOM", "quantity",
                        ...(configOptions?.asn?.itemHeader?.map(el => el.name) || []),
                        ...(configOptions?.inv?.itemHeader?.map(el => el.name) || [])
                    ].indexOf(h.name) < 0)}
                    sectionType={"asn"}
                    handleChange={handleChange}
                />
            }
        </>
    }


    return (
        <Dialog
            open={showModal}
            onClose={handleCloseModal}
            scroll={"paper"}
            // fullScreen={fullScreen}
            PaperProps={{ sx: { width: "60%" } }}
            maxWidth="50%"
        >
            <DialogTitle id="responsive-dialog-title">
                <Typography sx={{ ...textClasses.cardTitle }}>
                    Configure Label Details
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                {/*                 
                <Grid container sx={{mb: 2}}>
                    <Grid container item xs={12} sm={12} alignItems="center">
                        <RadioGroup
                            row
                            value={configType}
                            onChange={(e) => setConfigType(e.target.value)}
                        >
                            <FormControlLabel value="label" control={<Radio size="small" sx={{ color: theme.themeOrange, "&, &.Mui-checked": { color: theme.themeOrange } }} />} label="Label Information" />
                            <FormControlLabel value="encode" control={<Radio size="small" sx={{ color: theme.themeOrange, "&, &.Mui-checked": { color: theme.themeOrange } }} />} label="QR Information" />
                        </RadioGroup>
                    </Grid>
                </Grid> */}
                {
                    isShipmentSection &&
                    <Grid container sx={{ mt: 1, p: 1 ,border: "1px solid #E5E5E5", borderRadius: "8px"}}>
                        <Typography sx={{ ...textClasses.boldText, fontSize: "16px", color:theme.themeBlack  }}>Shipment Details</Typography>
                        <Grid container>
                            {
                                configOptions?.inv && configOptions?.inv?.shipmentHeader?.length>0 &&
                                <ConfigFieldSection
                                    configType={configType}
                                    sectionName={"Shipment Details"}
                                    headerSection={"shipmentHeader"}
                                    header={configOptions?.inv.shipmentHeader}
                                    sectionType={"inv"}
                                    handleChange={handleChange}
                                />
                            }
                            {
                                configOptions?.asn && configOptions?.asn?.shipmentHeader?.length > 0 &&
                                <ConfigFieldSection
                                    configType={configType}
                                    sectionName={"Asn Shipment Details"}
                                    headerSection={"shipmentHeader"}
                                    header={configOptions.asn.shipmentHeader}
                                    sectionType={"asn"}
                                    handleChange={handleChange}
                                />
                            }
                            {/* {
                                configOptions.asn && (configOptions.asn.supplierMaster || configOptions.asn.customerMaster) && 
                                <ConfigFieldSection
                                    configType={configType}
                                    sectionName={"Connected Master"}
                                    headerSection={configOptions.asn.supplierMaster ? "supplierMaster" : "customerMaster"}
                                    header={configOptions.asn.supplierMaster || configOptions.asn.customerMaster}
                                    sectionType={"asn"}
                                    handleChange={handleChange}
                                />
                            } */}
                        </Grid>
                    </Grid>
                }
                {
                    isPackageSection && configOptions?.inv && configOptions?.inv?.packageHeader?.length>0 &&
                    <Grid container sx={{ mt: 1, p: 1,border: "1px solid #E5E5E5", borderRadius: "8px" }}>
                        <Typography sx={{ ...textClasses.boldText, fontSize: "16px", color:theme.themeBlack  }}>Package Details</Typography>
                        <Grid container>
                            <ConfigFieldSection
                                configType={configType}
                                sectionName={"Package Details"}
                                headerSection={"packageHeader"}
                                header={isItemSection ? configOptions?.inv?.packageHeader?.filter(dl => !["UOM", "standardWeight", "weight"].includes(dl.name)) : configOptions.inv.packageHeader}
                                sectionType={"inv"}
                                handleChange={handleChange}
                            />

                            {
                                spiType == "shipmentPackageOnly" &&
                                <>
                                    {renderASNHeader()}
                                    {
                                        configOptions.scanned && configOptions.scanned &&
                                        <ConfigFieldSection
                                            configType={configType}
                                            sectionName={"Item Details"}
                                            headerSection={"scanned"}
                                            header={configOptions?.scanned?.itemMaster?.filter(h => [
                                                "SKU_ID", "SKU_Detail", "UOM", "quantity",
                                                ...(configOptions?.asn?.itemHeader?.map(el => el.name) || []),
                                                ...(configOptions?.inv?.itemHeader?.map(el => el.name) || [])
                                            ].indexOf(h.name) < 0)}
                                            sectionType={"scanned"}
                                            handleChange={handleChange}
                                        />
                                    }
                                </>
                            }
                        </Grid>
                    </Grid>
                }
                {
                    isItemSection && configOptions.inv && configOptions.inv.itemHeader &&

                    <Grid container sx={{ mt: 1, p: 1,border: "1px solid #E5E5E5", borderRadius: "8px" }}>
                        <Typography sx={{ ...textClasses.boldText, fontSize: "16px", color:theme.themeBlack }}>Item Details</Typography>
                        <Grid container>
                            <ConfigFieldSection
                                configType={configType}
                                sectionName={"Item Details"}
                                headerSection={"itemHeader"}
                                header={configOptions.inv.itemHeader}
                                sectionType={"inv"}
                                handleChange={handleChange}
                            />
                            {renderASNHeader()}
                            {
                                configOptions?.scanned &&
                                <ConfigFieldSection
                                    configType={configType}
                                    sectionName={"Item Details"}
                                    headerSection={"scanned"}
                                    header={configOptions.scanned?.itemMaster?.filter(h => [
                                        "SKU_ID", "SKU_Detail", "UOM", "quantity",
                                        ...(configOptions?.asn?.itemHeader?.map(el => el.name) || []),
                                        ...(configOptions?.inv?.itemHeader?.map(el => el.name) || [])
                                    ].indexOf(h.name) < 0)}
                                    sectionType={"scanned"}
                                    handleChange={handleChange}
                                />
                            }
                        </Grid>
                    </Grid>
                }
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' size='small' sx={{ ...buttonClasses.lynkitOrangeEmpty, minWidth: "120px" }} onClick={handleCloseModal}>
                    Close
                </Button>
                <Button variant='outlined' size='small' sx={{ ml: 1, ...buttonClasses.lynkitOrangeFill, minWidth: "120px" }} onClick={() => handleSave()}>
                    Submit
                </Button>
                {/* <Button variant='contained' size='small' sx={{ ml: 1, ...buttonClasses.small, fontSize: "14px", backgroundColor: theme.themeOrange, minWidth: "120px" }} onClick={() => handleSave()}>
                        Save for future
                    </Button> */}
            </DialogActions>

        </Dialog>
    )
}


const ScannerCode = React.forwardRef(({
    invType, data, labelConfig, isAsn, isScanned, connectedMaster, labelDetail = {}, setLabelDetail, labelType = {}, includeItemList = false
}, ref) => {
    const companyName = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))?.company || ""

    const getEncodingData = (qrType = "qrCode", dynamicDetail = false) => {

        let returnData = "", isDynamic = false
        if (dynamicDetail && qrType != "barCode" && labelConfig.templateCode && invType && labelConfig.inv && labelConfig.inv[`${invType.toLowerCase()}Header`]) {
            isDynamic = labelConfig.inv[`${invType.toLowerCase()}Header`].find(dl => dl.isForBarcode == true) || false
        }
        if (isDynamic) {
            returnData = window.location.origin + "/getInvDetail?" + "templateCode=" + labelConfig.templateCode + "&" + "inv=" + `${invType.toLowerCase()}` + "&" + "ID=" + data[`${invType.toLowerCase()}Id`]
        }
        else {
            if (["Item", "Kit"].includes(invType)) {
                returnData = data["itemId"];
            }
            else if (invType == "Package") {
                returnData = data["packageId"];
            }
            else if (invType == "Shipment") {
                returnData = data["shipmentId"];
            }
        }

        return returnData;
    }

    const renderElement = (element, index) => {
        switch (element.type) {
            case 'logo':
                return (
                    <Draggable
                        key={index}
                        defaultPosition={{ x: element.x, y: element.y }}
                        onDrag={(e, { x, y }) => setLabelDetail((prevElements) => {
                            const newElements = { ...prevElements };
                            newElements["allFields"][index] = {
                                ...element,
                                x: x,
                                y: y,
                            };
                            return newElements;
                        })}
                    >
                        <Resizable
                            style={{ border: labelDetail.showBorder && "1px solid #FF2700" || "", padding: element.padding || "" }}
                            size={{ width: element.width, height: element.height }}
                            onResizeStop={(e, direction, ref, d) => {
                                setLabelDetail((prevElements) => {
                                    const newElements = { ...prevElements };
                                    newElements["allFields"][index] = {
                                        ...element,
                                        width: element.width + d.width,
                                        height: element.height + d.height,
                                    };
                                    return newElements;
                                });
                            }}
                        >
                            <Box className="logo">
                                {element.url
                                    ? <img
                                        src={element.url}
                                        width={element?.style?.width || "inherit"}
                                        height={element?.style?.height || "inherit"}
                                        alt="Logo"
                                    // sx={element.style || {
                                    //     width: "inherit",
                                    //     height: "inherit"
                                    // }}
                                    />
                                    : <Typography sx={element.style || {
                                        ...textClasses.boldText,
                                        fontSize: "18px",
                                        color: "#000000"
                                    }}>+ Add Logo</Typography>
                                }
                            </Box>
                        </Resizable>
                    </Draggable>
                );

            case 'name':
                return (
                    <Draggable
                        key={index}
                        defaultPosition={{ x: element.x, y: element.y }}
                        onDrag={(e, { x, y }) => setLabelDetail((prevElements) => {
                            const newElements = { ...prevElements };
                            newElements["allFields"][index] = {
                                ...element,
                                x: x,
                                y: y,
                            };
                            return newElements;
                        })}
                    >
                        <Resizable
                            style={{
                                border: labelDetail.showBorder && "1px solid #FF2700" || "",
                                margin: (labelDetail["allFields"].find(dl => dl.type == "logo") || {})?.disabled
                                    ? "0 0 0 92px"
                                    : "0",
                                padding: element.padding || ""
                            }}
                            size={{ width: element.width, height: element.height }}
                            onResizeStop={(e, direction, ref, d) => {
                                setLabelDetail((prevElements) => {
                                    const newElements = { ...prevElements };
                                    newElements["allFields"][index] = {
                                        ...element,
                                        width: element.width + d.width,
                                        height: element.height + d.height,
                                    };
                                    return newElements;
                                });
                            }}
                        >
                            <Box className="name">
                                <Typography sx={element.style || {
                                    ...textClasses.boldText,
                                    fontSize: "18px",
                                    color: "#000000"
                                }}>{companyName}</Typography>
                            </Box>
                        </Resizable>
                    </Draggable>
                );

            case 'itemDetail':
                // if (element.multiline) {
                //   return (
                //     <Box style={element.multilineCSS}>
                //       {driveDetail().map((ele, eleIndex) => {
                //         return (
                //           <Draggable
                //             key={index + '/' + eleIndex}
                //             defaultPosition={{ x: ele.x, y: ele.y }}
                //             onDrag={(e, { x, y })=>setLabelDetail((prevElements) => {
                //                 const newElements = {...prevElements};
                //                 newElements["allFields"][index] = {
                //                   ...element,
                //                   x: x,
                //                   y: y,
                //                 };
                //                 return newElements;
                //               })}
                //           >
                //             <Resizable
                //               style={{ border: labelDetail.showBorder && "1px solid #FF2700" || "", padding: ele.padding }}
                //               size={{
                //                 width: ele.width,
                //                 height: ele.height,
                //               }}
                //               onResizeStop={(e, direction, ref, d) => {
                //                 setLabelDetail((prevElements) => {
                //                   const newElements = {...prevElements};
                //                   if (
                //                     prevElements["allFields"][index].type == 'itemDetail' &&
                //                     prevElements["allFields"][index].subItems &&
                //                     prevElements["allFields"][index].subItems.length > 0
                //                   ) {
                //                     newElements["allFields"][index]['subItems'][eleIndex] = {
                //                       ...ele,
                //                       width: ele.width + d.width,
                //                       height: ele.height + d.height,
                //                     };
                //                   } else {
                //                     newElements["allFields"][index] = {
                //                       ...element,
                //                       width: element.width + d.width,
                //                       height: element.height + d.height,
                //                     };
                //                   }
                //                   return newElements;
                //                 });
                //               }}
                //             >
                //               <Box className="item-detail">
                //                     <Typography key={eleIndex}>
                //                         <span style={element.labelStyle}>{ele.label} : </span>
                //                         <span style={element.valueStyle}>{ele.value || "-"}</span>
                //                     </Typography>
                //               </Box>
                //             </Resizable>
                //           </Draggable>
                //         );
                //       })}
                //     </Box>
                //   );
                // }
                //  else {
                // }
                return (
                    <Draggable
                        key={index}
                        defaultPosition={{ x: element.x, y: element.y }}
                        onDrag={(e, { x, y }) => setLabelDetail((prevElements) => {
                            const newElements = { ...prevElements };
                            newElements["allFields"][index] = {
                                ...element,
                                x: x,
                                y: y,
                            };
                            return newElements;
                        })}
                    >
                        <Resizable
                            style={{ border: labelDetail.showBorder && "1px solid #FF2700" || "", padding: element.padding || "" }}
                            size={{
                                width: element.width,
                                height: element.height,
                                // height: "auto",
                                // minHeight: element.height,
                            }}
                            onResizeStop={(e, direction, ref, d) => {
                                setLabelDetail((prevElements) => {
                                    const newElements = { ...prevElements };
                                    newElements["allFields"][index] = {
                                        ...element,
                                        width: element.width + d.width,
                                        height: element.height + d.height,
                                        // height: "auto",
                                    };
                                    return newElements;
                                });
                            }}
                        >
                            <Box className="item-detail">
                                {
                                    driveDetail().map((detailElement, detaiIndex) => {
                                        return (
                                            <Typography key={detaiIndex}>
                                                <span style={element.labelStyle}>{detailElement.label} : </span>
                                                <span style={element.valueStyle}>{detailElement.value || "-"}</span>
                                            </Typography>
                                        )
                                    })
                                }
                                {/* {
                                    showConnectedASNTable()
                                } */}
                            </Box>
                        </Resizable>
                    </Draggable>
                );
            case 'qr':
                return (
                    <Draggable
                        key={index}
                        defaultPosition={{ x: element.x, y: element.y }}
                        onDrag={(e, { x, y }) => setLabelDetail((prevElements) => {
                            const newElements = { ...prevElements };
                            newElements["allFields"][index] = {
                                ...element,
                                x: x,
                                y: y,
                            };
                            return newElements;
                        })}
                    >
                        <Resizable
                            style={{
                                border: labelDetail.showBorder && "1px solid #FF2700" || "",
                                margin: (labelDetail["allFields"].find(dl => dl.type == "itemDetail") || {})?.disabled
                                    ? "0 0 28px 28px"
                                    : driveDetail().length < 3
                                        ? "0 0 28px 0"
                                        : "0",
                                padding: element.padding || ""
                            }}
                            size={{ width: element.width, height: element.height }}
                            onResizeStop={(e, direction, ref, d) => {
                                setLabelDetail((prevElements) => {
                                    const newElements = { ...prevElements };
                                    newElements["allFields"][index] = {
                                        ...element,
                                        width: element.width + d.width,
                                        height: element.height + d.height,
                                    };
                                    return newElements;
                                });
                            }}
                        >
                            <Box className="qr">
                                {
                                    element?.qrType == "qrCode"
                                        ?
                                        <>
                                            <QRCode
                                                value={getEncodingData("qrCode", element.dynamicDetail || false)}
                                                style={{
                                                    width: element.width,
                                                    height: element.height,
                                                }}
                                            />
                                            {element?.options?.some(el => el?.value == "qrCode" && el?.displayValue == true) &&
                                             element?.options?.find(el => el?.value == "qrCode") &&
                                                <Typography sx={{
                                                    ...(
                                                        element?.options?.find(el => el?.value == "qrCode")?.style
                                                            ? element?.options?.find(el => el?.value == "qrCode")?.style
                                                            : { marginTop: "-7px", fontSize: "14px", fontWeight: "bold", textAlign: "center" })
                                                }}>
                                                    {element?.qrDisplayValueConfig && element?.qrDisplayValueConfig[invType]
                                                        ? dataReadFunction(element?.qrDisplayValueConfig[invType], data, "name")
                                                        : getEncodingData("qrCode", false)
                                                    }
                                                </Typography>}
                                        </>
                                        :
                                        <Box

                                        >
                                            <Barcode
                                                value={getEncodingData("barCode", element.dynamicDetail || false)}
                                                width={1}
                                                height={element.height}
                                                displayValue={element?.options?.some(el=>el?.value=="barCode" && el?.displayValue==true)}
                                            />
                                        </Box>
                                }

                            </Box>
                        </Resizable>
                    </Draggable>
                );

            case 'signature':
                return (
                    <Draggable
                        key={index}
                        defaultPosition={{ x: element.x, y: element.y }}
                        onDrag={(e, { x, y }) => setLabelDetail((prevElements) => {
                            const newElements = { ...prevElements };
                            newElements["allFields"][index] = {
                                ...element,
                                x: x,
                                y: y,
                            };
                            return newElements;
                        })}
                    >
                        <Resizable
                            style={{ border: labelDetail.showBorder && "1px solid #FF2700" || "", padding: element.padding || "" }}
                            size={{
                                width: element.width,
                                //  height: element.height,
                                minHeight: element.heightF
                            }}
                            onResizeStop={(e, direction, ref, d) => {
                                setLabelDetail((prevElements) => {
                                    const newElements = { ...prevElements };
                                    newElements["allFields"][index] = {
                                        ...element,
                                        width: element.width + d.width,
                                        height: element.height + d.height,
                                    };
                                    return newElements;
                                });
                            }}
                        >
                            <Box className="signature">
                                {element.url
                                    ? <img
                                        src={element.url}
                                        alt="Logo" sx={{
                                            width: "inherit",
                                            height: "inherit"
                                        }} />
                                    : <Typography sx={element.style || {
                                        ...textClasses.boldText,
                                        fontSize: "18px",
                                        color: "#000000"
                                    }}>+ Add Signature</Typography>
                                }
                            </Box>
                        </Resizable>
                    </Draggable>
                );

            default:
                return "Invalid Type"
        }
    };

    const showConnectedASNTable = () => {

        if (!isAsn) return;
        const asnHeader = labelConfig?.asn?.[invType.toLowerCase() === "shipment" ? "shipmentHeader" : "itemHeader"] || [];
        const tableHeaders = asnHeader?.filter(head => head.showInLabel)?.slice(0, 4);
        // console.log("headers",tableHeaders)
        if (!tableHeaders || tableHeaders?.length == 0) return;

        let tempRows = []

        data?.ASN_Detail?.forEach((eachASN, index) => {
            let eachRow = []
            getFormLabelValueDetails(eachRow, tableHeaders, eachASN?.formData || {});
            tempRows.push([...(eachRow.map(option => option.value) || [])])
        });

        return <GenericLabelTable
            headers={tableHeaders?.map(dl => dl?.label)}
            rows={tempRows || []}
            headerValueStyle={
                {
                    "fontSize": "12px",
                    "fontWeight": "800",
                    "lineHeight": "10px",
                    "whiteSpace": "nowrap"
                }
            }
            rowValueStyle={
                {
                    "fontSize": "12px",
                    "fontWeight": "000",
                    "lineHeight": "10px",
                    "padding": "4px 8px"
                }
            }
        />
    };


    const driveDetail = () => {
        const detailArr = [];

        // General invoice details
        const invoiceHeader = labelConfig?.inv?.[`${invType.toLowerCase()}Header`] || [];
        getFormLabelValueDetails(detailArr, invoiceHeader?.filter(head => head.showInLabel), data?.[invType === "Shipment" ? "formData" : "dynamicData"] || {});

        // ASN details
        if (isAsn) {
            const asnHeader = labelConfig?.asn?.[invType.toLowerCase() === "shipment" ? "shipmentHeader" : "itemHeader"] || [];
            // getFormLabelValueDetails(detailArr, asnHeader?.filter(head => head.showInLabel), invType === "Shipment"
            //     ? data?.ASN_Detail?.formData || {}
            //     : data?.ASN_Detail?.ASN_ItemDetail?.formData || {},
            //     ["product_ID", "UOM"]
            // );

            // Connected Master ASN details
            if (invType === "Shipment" && connectedMaster) {
                const masterHeader = labelConfig?.asn?.[`${connectedMaster}Master`] || [];
                const masterData = data?.ASN_Detail?.[`${connectedMaster}Detail`]?.[`${connectedMaster}Data`] || {};
                getFormLabelValueDetails(detailArr, masterHeader?.filter(head => head.showInLabel), masterData);
            }

            // Item Master details
            if (["Item", "Package"].includes(invType)) {
                const itemMasterHeader = labelConfig?.asn?.itemMaster || [];
                const itemMasterData = data?.ItemMaster_Detail?.itemData || data?.ItemMaster_Detail?.formData || {};
                getFormLabelValueDetails(detailArr, itemMasterHeader?.filter(head => head.showInLabel), itemMasterData, ["SKU_ID", "SKU_Detail", "UOM"]);
            }
        }

        // Scanned details
        if (isScanned && ["Item", "Package"].includes(invType)) {
            const scannedHeader = labelConfig?.scanned?.itemMaster || [];
            const scannedData = data?.ItemMaster_Detail?.itemData || data?.ItemMaster_Detail?.formData || {};
            getFormLabelValueDetails(detailArr, scannedHeader?.filter(head => head.showInLabel), scannedData, ["SKU_ID", "SKU_Detail", "UOM"]);
        }

        return detailArr;
    };


    // useEffect(() => {
    //     const handleQRCodeScan = () => {
    //         window.location.href = 'https://www.example.com';
    //     };

    //     const qrCodeScanner = new window.QRCodeScanner();
    //     qrCodeScanner.addEventListener('scan', handleQRCodeScan);

    //     return () => {
    //         qrCodeScanner.removeEventListener('scan', handleQRCodeScan);
    //     };
    // }, []);
    // useEffect(() => {
    //     //("labelDetail",labelDetail);
    // }, [labelDetail])

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        // { field: 'name', headerName: 'Name', width: 150 },
        // { field: 'age', headerName: 'Age', width: 100 },
        // { field: 'city', headerName: 'City', width: 150 },
        {
            field: 'info',
            headerName: 'Details',
            width: 200,
            type: 'multiline',
            lines: [
                { field: 'email', variant: 'body2', color: 'textSecondary' },
                { field: 'phone', variant: 'body1', color: 'primary' },
            ],
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            type: 'button',
            buttonLabel: 'View',
            buttonVariant: 'outlined',
            buttonColor: 'success',
            buttonSize: 'small',
            onClick: (row) => alert(`Button clicked for ID: ${row.id}`),
        },
    ];

    // const rows = [
    //     {
    //         id: 1,
    //         name: 'John Doe',
    //         age: 30,
    //         city: 'New York',
    //         email: 'john.doe@example.com',
    //         phone: '123-456-7890',
    //     },
    //     {
    //         id: 2,
    //         name: 'Jane Smith',
    //         age: 25,
    //         city: 'San Francisco',
    //         email: 'jane.smith@example.com',
    //         phone: '987-654-3210',
    //     },
    // ];

    const rowHeaderForTable = useMemo(() => {
        return includeItemList && (labelConfig?.inv?.itemHeader || [])?.filter(head => head?.showInLabel) || []
    }, [labelConfig, labelType, includeItemList]).slice(0, 3)


    const rows = useMemo(() => {
        let tempRows = []
        if (includeItemList && data?.items?.length) {
            data?.items?.forEach((ele, index) => {
                let eachRow = []
                getFormLabelValueDetails(eachRow, rowHeaderForTable, ele?.dynamicData || {});
                const barCodeElement = <Box>

                    <Barcode
                        value={ele?.itemId}
                        width={0.6}
                        // height={element.height}
                        height={20}         // Barcode height
                        fontSize={"12px"}       // Adjust this value to change the font size
                        displayValue={false} // Make sure the text is displayed
                        margin={"0px"}            // Remove outer white margin
                    />
                </Box>

                tempRows.push([index + 1, ...(eachRow.map(option => option.value) || []), barCodeElement])
            })
        }
        return tempRows
    }, [labelType, rowHeaderForTable, includeItemList])


    return (
        <Box className="single-check" ref={ref} >
            <Resizable
                style={{ border: labelDetail.showBorder && "1px solid #FF2700" || "", ...(labelDetail.style || {}) }}
                size={{
                    width: labelDetail.width || 500,
                    height: labelDetail.height || 'auto',
                }}
                onResizeStop={(e, direction, ref, d) => {
                    setLabelDetail((prevElements) => {
                        let newElements = { ...prevElements };
                        newElements = {
                            ...newElements,
                            width: newElements.width + d.width,
                            // height: newElements.height + d.height,
                        };
                        return newElements;
                    });
                }}

            >
                <Box className="top" style={labelDetail?.fieldsConfig?.top?.style || {}}>
                    {labelDetail?.allFields?.map((element, index) => {
                        if (!element.disabled && (element.type === 'logo' || element.type === 'name')) {
                            return renderElement(element, index);
                        }
                    })}
                    <Box></Box>
                </Box>
                <Box className="content" style={labelDetail?.fieldsConfig?.content?.style || {}}>
                    {labelDetail?.allFields?.map((element, index) => {
                        if (!element.disabled && (element.type === 'qr' || element.type === 'itemDetail')) {
                            return renderElement(element, index);
                        }
                    })}
                </Box>
                <Box className="footer">
                    {labelDetail?.allFields?.map((element, index) => {
                        if (!element.disabled && element.type === 'signature') {
                            return renderElement(element, index);
                        }
                    })}
                </Box>
                {["packageAndEveryItem"].includes(labelType?.value) && includeItemList && data?.items?.length > 0 &&  

                    <GenericLabelTable
                        headers={["S.No", ...(rowHeaderForTable.map(head => head?.label) || []), "Barcode/QR Code"]}
                        rows={rows}
                        headerValueStyle={
                            {
                                "fontSize": "12px",
                                "fontWeight": "800",
                                "lineHeight": "10px",
                                "whiteSpace": "nowrap"
                            }
                        }
                        rowValueStyle={
                            {
                                "fontSize": "12px",
                                "fontWeight": "000",
                                "lineHeight": "10px",
                                "padding": "4px 8px"
                            }
                        }
                    />
                }
                {showConnectedASNTable()}
            </Resizable>
        </Box>
    )
})

const Generate = ({
    spiType, templateHeader, inventories, labelType, switch_active, setSwitch_active, isAsn, isScanned,
    asnHeader, labelDetail, setLabelDetail, count, setCount, subStep, setSubStep, setPdfContent, componentRef,
    scannerLoader, isPalletEntry=false, itemMasterHeader=[]
}) => {
    //    console.log(inventories,spiType,labelType)
    let dispatch = useDispatch()
    const [retries, setRetries] = useState(0);
    const [itemIndex, setItemIndex] = useState(0);
    const [includeItemList, setIncludeItemList] = useState(false);
    const [configureModal, setConfigureModal] = useState(false);
    const [labelConfig, setLabelConfig] = useState({});
    const [encodeConfig, setEncodeConfig] = useState(templateHeader);
    const [loader, setLoader] = useState({ logo: false, logoFile: {}, disabled: true });
    const [indexJump, setIndexJump] = useState([null, null]);

    // const [value1, setValue1] = React.useState([1, 100]);

    const handleChange1 = () => {
        // //(indexJump,"count",count);
        let first = indexJump[0] || ""
        first = Math.min(first, count[2])
        let second = indexJump[1] || ""
        second = Math.min(second, count[2])


        setCount([Math.min(first, count[1]), Math.max(second, count[0]), count[2]]);
        setItemIndex(0);
        // setIndexJump([Math.min(first, count[1]), Math.max(second, count[0])])
    };
    // const handleChange1 = (newValue, activeThumb) => {
    //     //(newValue,"count",count);

    //     if (activeThumb === 0) {
    //         setCount([Math.min(newValue[0], count[1]), count[1],count[2]]);
    //     } else {
    //         setCount([count[0], Math.max(newValue[1], count[0]),count[2]]);
    //     }
    //     setItemIndex(0);
    // };

    const signatureRef = useRef();

    const handleClear = () => {
        signatureRef.current.clear();
    };

    const handleSave = () => {
        const signatureData = signatureRef.current.toDataURL();
        let tempLabelDetail = { ...labelDetail }
        // Use the signature data as needed (e.g., save it to a database, send it to a server, etc.)
        if (!tempLabelDetail || !tempLabelDetail.allFields) {
            return ""
        }
        let barCodeElementIndex = tempLabelDetail?.allFields?.findIndex(dl => dl.type == "signature")
        // //(barCodeElementIndex,"labelDetail?.allFields",labelDetail?.allFields);
        if (barCodeElementIndex < 0) {
            return "";
        }

        tempLabelDetail["allFields"][barCodeElementIndex]["url"] = signatureData
        tempLabelDetail = {
            ...tempLabelDetail,
            showSignCanvas: false

        }
        // tempLabelDetail["showSignCanvas"]=false
        setLabelDetail(tempLabelDetail)
        // //(signatureData);
    };

    const connectedMaster = (() => {
        if (labelConfig && labelConfig.asn && labelConfig.asn.supplierMaster) {
            return "supplier";
        }
        if (labelConfig && labelConfig.asn && labelConfig.asn.customerMaster) {
            return "customer";
        }
        return null;
    })();


    const saveConfig = (type, config) => {
        if (type == "label") {
            setLabelConfig({ ...config });
        }
        else {
            setEncodeConfig({ ...config });
        }
    }

    const handleCloseConfigureModal = () => {
        setConfigureModal(false);
    }

    const showConfigureModal = () => {
        setConfigureModal(true);
    }


    const invType = (() => {
        if (labelType?.value == "shipmentOnly") {
            return "Shipment";
        }
        else if (labelType?.value == "packageOnly") {
            return "Package";
        }
        else if (labelType?.value == "packageAndEveryItem") {
            if (switch_active == "item") {
                return "Item";
            }
            else {
                return "Package";
            }
        }
        else if (labelType?.value == "packageAndSingleItem") {
            return "Package";
        }
        return "Item"
    })()

    useEffect(() => {

        let obj = {};
        if (invType) {
            ////("Yes here 1");
            if (templateHeader) {
                ////("Yes here 2");
                let newInv = { ...templateHeader };
                if (templateHeader?.shipmentHeader?.length > 0) {
                    obj["templateCode"] = templateHeader["shipmentHeader"][0].templateCode || ""
                }
                if (newInv[`${invType.toLowerCase()}Header`]?.length) {
                    // newInv[`${invType.toLowerCase()}Header`][0].labelConfig = true;
                    if (!(newInv[`${invType.toLowerCase()}Header`] || []).find(dl => dl.showInLabel)) {
                        newInv[`${invType.toLowerCase()}Header`][0].showInLabel = true;
                    }
                    ////("New Inv", newInv);
                    obj = { ...obj, inv: newInv }
                }
                if ((labelType?.value == "packageAndEveryItem" || labelType?.value== "packageAndSingleItem") && newInv?.itemHeader?.length) {
                    // newInv.itemHeader[0].labelConfig = true;
                    if (!(newInv.itemHeader || []).find(dl => dl.showInLabel)) {
                        newInv.itemHeader[0].showInLabel = true;
                    }
                    obj = { ...obj, inv: newInv }
                }
            }
            if (isAsn) {
                obj = { ...obj, asn: asnHeader }
            }
            else if (isScanned) {
                obj = { ...obj, scanned: itemMasterHeader }
            }
        }
        setLabelConfig({ ...obj })
    }, [invType,asnHeader,templateHeader])

    // const handleCountChange = (direction) => {
    //     if (direction < 0) {
    //         setCount(count > 1 ? count - 1 : 1)
    //     }
    //     else {
    //         setCount(count < (inventories ? inventories.length : 0) ? count + 1 : count)
    //     }
    //     setItemIndex(0);
    // }

    const handleIndexChange = (direction) => {
        if (direction < 0) {
            // //("fff");
            setItemIndex(itemIndex > 0 ? itemIndex - 1 : itemIndex);
        }
        else {
            setItemIndex(itemIndex < (count?.at(1) - count?.at(0)) ? itemIndex + 1 : itemIndex)
        }
        // const handleIndexChange = (direction) => {
        //     if (direction < 0) {
        //         setItemIndex(itemIndex > 0 ? itemIndex - 1 : itemIndex);
        //     }
        //     else {
        //         setItemIndex(itemIndex < count - 1 ? itemIndex + 1 : itemIndex)
        //     }
    }

    const exportAsImage = async (element) => {
        const canvas = await html2canvas(element);
        const image = canvas.toDataURL("image/png", 1.0)
        return image;
    }

    const generateLabelPdf = async () => {
        try {

            ////(componentRef.current);
            const children = componentRef.current.children;
            let labelImages = [];
            let height = 0;
            for (let i = 0; i < children.length; i++) {
                let child = children[i]
                // child.style.display = "block";
                ////("Child height ", child.offsetHeight);
                if (child.offsetHeight > height) {
                    height = child.offsetHeight;
                }

                labelImages.push(await exportAsImage(child));
                // child.style.display = "none";
            }
            // Compute Labels per page
            // let height = labelImages[0].height;
            // let height = ref.current.children[0].offsetHeight;
            let labelsPerPage = 840 / height;
            // ////(`Height ${height} and labels per page ${labelsPerPage}`);
            if (labelsPerPage < 1) {
                showToast("Label is too big for A4 size sheet", true)
            }
            else {
                const chunks = [];
                const chunksToPrint = []
                for (let i = 0; i < labelImages.length; i += Number(labelsPerPage)) {
                    const chunk = labelImages.slice(i, i + Number(labelsPerPage));
                    const chunkToPrint = inventories.slice((count?.at(0) - 1), (count?.at(1))).slice(i, i + Number(labelsPerPage));
                    chunks.push(chunk);
                    chunksToPrint.push(chunkToPrint);
                }
                const document = (
                    <Document>
                        {
                            chunks.map((chunk, i) => {
                                return (
                                    <Page>
                                        <View>
                                            {
                                                chunk.map((image, j) => {
                                                    return (
                                                        <Image src={image} />
                                                    )
                                                })
                                            }
                                        </View>
                                    </Page>
                                )
                            })

                        }
                    </Document>
                )
                setPdfContent(document);
            }
        }
        catch (e) {
            ////(" I came in catch", e);
            setRetries(retries + 1);
        }

    }

    const renderBarCodeOptions = () => {
        if (!labelDetail || !labelDetail.allFields) {
            return ""
        }
        let barCodeElementIndex = labelDetail?.allFields?.findIndex(dl => dl.type == "qr")
        // //(barCodeElementIndex,"labelDetail?.allFields",labelDetail?.allFields);
        if (barCodeElementIndex < 0) {
            return "";
        }
        let barCodeElement = labelDetail.allFields[barCodeElementIndex]
        if (!barCodeElement || !barCodeElement.options || barCodeElement.options.length == 0) {
            return "";
        }
        let allOption = barCodeElement.options
        return <Grid container>
            <Grid container item xs={12} sm={2.5} alignItems="center">
                <FormLabel required sx={{ ...textClasses.boldText, color: theme.themeOrange }}>
                    {barCodeElement.fieldName || "Label Type"}
                </FormLabel>
            </Grid>
            <Grid container item xs={12} sm={9.5} alignItems="center">
                <RadioGroup
                    row
                    value={barCodeElement?.qrType}
                    onChange={(e) =>
                        setLabelDetail((prevElements) => {
                            const newElements = { ...prevElements };
                            newElements["allFields"][barCodeElementIndex] = {
                                ...barCodeElement,
                                qrType: e.target.value
                            };
                            return newElements;
                        })
                    }
                >
                    {
                        allOption.map((ele, index) => (
                            <FormControlLabel
                                key={index} value={ele.value}
                                control={
                                    <Radio size="small"
                                        sx={{ color: theme.themeOrange, "&, &.Mui-checked": { color: theme.themeOrange } }} />
                                } label={ele.label} />
                        ))
                    }
                </RadioGroup>
            </Grid>
        </Grid>
    }

    const handleFileChange = useCallback((event) => {
        if (event) {
            let file = event.target.files[0]

            // //("file",file);
            if (!isFileValid(file, ['jpg', 'jpeg', 'png', "svg"])) {
                showToast("Invalid file format. Only JPG, JPEG, and PNG files are allowed.", true)
                return;
            }
            setLoader((prevLoader) => ({
                ...prevLoader,
                logoFile: file,
                disabled: false
            }));
        }
        else {
            setLoader((prevLoader) => ({
                ...prevLoader,
                logoFile: {},
                disabled: true
            }));
        }
    }, []);


    const uploadLogoHandler = async () => {
        const base64 = await convertDocsBase64(loader.logoFile)
        if (!base64) {
            showToast("No file", true)
            return;
        }
        // //("loader",loader);
        setLoader({ ...loader, logo: true })
        dispatch(uploadLabelLogo({ label_ID: labelDetail._id, base64String: base64, update_for: "logo" }, ({ data = "", success, message }) => {
            showToast(message, !success)
            if (success) {
                let tempLabelDetail = { ...labelDetail }
                let logoElementIndex = -1
                logoElementIndex = tempLabelDetail?.allFields?.findIndex(dl => dl.type == "logo")
                if (logoElementIndex < 0) {
                    return "";
                }
                tempLabelDetail.allFields[logoElementIndex]["url"] = data
                tempLabelDetail["showLogoUpload"] = false
                setLabelDetail(tempLabelDetail)
                setLoader({ logo: false, logoFile: {}, disabled: false })
            }
            else {
                setLoader({ ...loader, disabled: false })
            }
        }))
    }


    useEffect(() => {
        if (subStep == 1) {
            setTimeout(() => {
                if (retries < 3) {
                    // generateLabelPdf();
                }
                else {
                    // Go Back
                    setSubStep(0);
                }
            }, 3000)
        }
        else {
            setRetries(0);
            setPdfContent(null);
            // setPdfPrintContent(null);
        }
    }, [subStep])



    useEffect(() => {
        setPdfContent(null);
    }, [invType, labelType, switch_active, count, labelConfig, encodeConfig])

    useEffect(() => {
        // setCount([1,1,1]);
        setItemIndex(0);
        setIncludeItemList(false)
    }, [labelType, switch_active])
    useEffect(() => {
        // setCount([1,1,1]);
        setIndexJump([count[0] || null, count[1] || null])
    }, [count])

    useEffect(() => {
        if (isPalletEntry) {
            setIncludeItemList(true)
        }
        else {
            if (labelType?.itemView) {
                setIncludeItemList(false)
                setSwitch_active("item")
            } else {
                setIncludeItemList(labelType?.includeItemList || false)
                setSwitch_active("package")
            }

        }
    }, [labelType])
    // //("count",count,inventories);


    return (
        <>

            <div style={{ padding: "20px" }}>
                {
                    subStep == 0
                        // && inventories && (Array.isArray(inventories))
                        ?
                        <>
                            {renderBarCodeOptions()}
                            {
                                labelDetail?.allFields?.some(dl => dl.type != "qr")
                                    ?
                                    <Grid container>
                                        <Grid container item xs={12} sm={2.5} alignItems="center">
                                            <FormLabel required sx={{ ...textClasses.boldText, color: theme.themeOrange }}>Other</FormLabel>
                                        </Grid>
                                        <Grid container item xs={12} sm={9.5} alignItems="flex-start">
                                            <FormGroup sx={{ display: "flex", flexDirection: "row" }}  >
                                                {
                                                    labelDetail?.allFields?.map((element, index) => {
                                                        if (element.type != "qr") {
                                                            return (
                                                                <FormControlLabel
                                                                    key={index}
                                                                    control={
                                                                        <Checkbox checked={!element.disabled}
                                                                            onChange={(event) => setLabelDetail((prevElements) => {
                                                                                const newElements = { ...prevElements };
                                                                                newElements["allFields"][index] = {
                                                                                    ...element,
                                                                                    disabled: !event.target.checked
                                                                                };
                                                                                return newElements;
                                                                            })
                                                                            }
                                                                        />
                                                                    }
                                                                    label={element.fieldName || ""}
                                                                />
                                                            )
                                                        }
                                                    })

                                                }
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    : ""
                            }
                            {
                                ["shipmentOnly", "singleItemOnly"].indexOf(labelType?.value) < 0 && count?.at(2) && count?.at(2) > 0
                                    ? <Grid container sx={{ mt: 1 }}>
                                        <Grid container item xs={12} sm={2.5} alignItems="center">
                                            <FormLabel required sx={{ ...textClasses.boldText, color: theme.themeOrange }}>Label Count</FormLabel>
                                        </Grid>
                                        <Grid container item xs={12} sm={9.5} alignItems="center">
                                            <Box style={{
                                                display: "flex",
                                                color: "#FF7200",
                                                height: "20px",
                                                alignItems: "center",

                                            }}>
                                                <input
                                                    type="number"
                                                    max={count?.at(2) || 1}
                                                    disabled={count?.at(2) < 2}
                                                    style={{ border: "1px solid #D9D9D9", maxWidth: "60px", textAlign: "center", fontSize: "14px", marginRight: "10px" }}
                                                    value={indexJump?.at(0) || ""}
                                                    onChange={(e) => setIndexJump([e.target.value, (indexJump?.at(1) || null)])}
                                                    onWheel={(event) => event.target.blur()}
                                                    onKeyDown={(e) => {
                                                        if (['e', 'E', '+', '-'].includes(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                        else if (e.key == '0' && (e.target.value == "" || Number(e.target.value) == 0)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                                to
                                                <input
                                                    type="number"
                                                    style={{ border: "1px solid #D9D9D9", maxWidth: "60px", textAlign: "center", fontSize: "14px", marginLeft: "10px", }}
                                                    max={count?.at(2) || 1}
                                                    value={indexJump?.at(1) || ""}
                                                    disabled={count?.at(2) < 2}
                                                    onChange={(e) => setIndexJump([(indexJump?.at(0) || null), e.target.value])}
                                                    onWheel={(event) => event.target.blur()}
                                                    onKeyDown={(e) => {
                                                        if (['e', 'E', '+', '-'].includes(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                        else if (e.key == '0' && (e.target.value == "" || Number(e.target.value) == 0)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                                <Button variant='outlined' size='small'
                                                    disabled={!indexJump?.at(0) || !indexJump?.at(1) || count?.at(2) < 2}
                                                    sx={{ ...buttonClasses.lynkitOrangeFill, ml: 1 }}
                                                    onClick={handleChange1}
                                                >
                                                    Jump
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    : ""
                            }
                            <Grid container sx={{ mt: (["shipmentOnly", "singleItemOnly"].indexOf(labelType?.value) < 0 && count?.at(2) && count?.at(2) > 0) ?2 :1}}>
                                <Grid container item xs={6} sm={2.5} alignItems="center">
                                    <Button size='small' sx={{ ...buttonClasses.small, backgroundColor: theme.themeBlack, color: "white" }} onClick={showConfigureModal}>
                                        Configure Label Details
                                    </Button>
                                    {configureModal && <ConfigureLabel
                                        invType={invType}
                                        spiType={spiType}
                                        labelType={labelType}
                                        labelConfig={labelConfig}
                                        encodeConfig={encodeConfig}
                                        templateHeader={templateHeader}
                                        asnHeader={asnHeader}
                                        connectedMaster={connectedMaster}
                                        saveConfig={saveConfig}
                                        showModal={configureModal}
                                        handleCloseModal={handleCloseConfigureModal}
                                    />}
                                </Grid>
                                <Grid container item xs={12} sm={9.5} alignItems="center">
                                    {labelType?.value == "packageAndEveryItem" ?
                                        <>
                                            <Grid container item xs={"auto"} sx={{ ml: 0 }} alignItems="center">
                                                <GenericToggle
                                                    disbledName="'Item View'"
                                                    enabledName="'Package View'"
                                                    isEnable={switch_active == "item" || false}
                                                    handleToggle={(event) => {
                                                        setSwitch_active(event.target.checked && "item" || "package")
                                                        setIncludeItemList(false)
                                                    }}
                                                    name="packageItemChange"
                                                    width={118}
                                                    isActionEnabledSwitch={true}
                                                />
                                            </Grid>
                                            {
                                                // switch_active == "package" && 
                                                <Grid container item xs={"auto"} sx={{ ml: 1 }} alignItems="center">
                                                    <GenericToggle
                                                        disbledName="'Include Item List'"
                                                        enabledName="'Hide Item List'"
                                                        isEnable={includeItemList === true}
                                                        handleToggle={(event) => setIncludeItemList(event.target.checked === true)}
                                                        name="hideItemList"
                                                        width={118}
                                                        isActionEnabledSwitch={switch_active == "package"}
                                                    />
                                                </Grid>
                                            }
                                        </>
                                        : ""
                                    }
                                    <Grid container item xs={"auto"} sx={{ ml: 1 }} alignItems="center">
                                        <GenericToggle
                                            disbledName="'Show Border'"
                                            enabledName="'Hide Border'"
                                            isEnable={labelDetail.showBorder}
                                            handleToggle={(event) => setLabelDetail({ ...labelDetail, showBorder: event.target.checked })}
                                            name="border"
                                            width={110}
                                            isActionEnabledSwitch={!(labelDetail.showLogoUpload || labelDetail.showSignCanvas)}
                                        />
                                        {/* <FormControlLabel
                                            control={<Android12Switch
                                                disabled={labelDetail.showLogoUpload || labelDetail.showSignCanvas}
                                                checked={labelDetail.showBorder}
                                                onChange={(event) => setLabelDetail({ ...labelDetail, showBorder: event.target.checked })}
                                            />}
                                            label="Show Border"
                                        /> */}

                                    </Grid>
                                    {labelDetail?.allFields?.find(dl => dl.type == "logo") &&
                                        <Grid container item xs={"auto"} sx={{ ml: 1 }} alignItems="center">
                                            <GenericToggle
                                                disbledName="'Upload Logo'"
                                                enabledName="'Hide Logo'"
                                                isEnable={labelDetail.showLogoUpload || false}
                                                handleToggle={(event) => setLabelDetail({ ...labelDetail, showLogoUpload: event.target.checked })}
                                                name="logo"
                                                width={110}
                                                isActionEnabledSwitch={!(labelDetail.showSignCanvas)}
                                            />
                                            {/* <FormControlLabel
                                                control={<Android12Switch
                                                    disabled={labelDetail.showSignCanvas}
                                                    checked={labelDetail.showLogoUpload || false}
                                                    onChange={(event) => setLabelDetail({ ...labelDetail, showLogoUpload: event.target.checked })}
                                                />}
                                                label="Upload Logo"
                                            /> */}

                                        </Grid>}
                                    {labelDetail?.allFields?.find(dl => dl.type == "signature") &&
                                        <Grid container item xs={"auto"} sx={{ ml: 1 }} alignItems="center">
                                            <GenericToggle
                                                disbledName="'Create Signature'"
                                                enabledName="'Hide Signature'"
                                                isEnable={labelDetail.showSignCanvas || false}
                                                handleToggle={(event) => setLabelDetail({ ...labelDetail, showSignCanvas: event.target.checked })}
                                                name="packageItemChange"
                                                width={120}
                                                isActionEnabledSwitch={!(labelDetail.showLogoUpload)}
                                            />
                                            {/* <FormControlLabel
                                                control={<Android12Switch
                                                    disabled={labelDetail.showLogoUpload}
                                                    checked={labelDetail.showSignCanvas || false}
                                                    onChange={(event) => setLabelDetail({ ...labelDetail, showSignCanvas: event.target.checked })}
                                                />}
                                                label="Create Signature"
                                            /> */}

                                        </Grid>}
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container justifyContent={"center"} alignItems="center">
                                {
                                    labelDetail.showSignCanvas ?
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center"
                                        }}>
                                            <Box sx={{ backgroundColor: "grey", mr: 1 }}>
                                                <SignatureCanvas
                                                    ref={signatureRef}
                                                    canvasProps={{
                                                        width: 400,
                                                        height: 150,
                                                        className: 'signature-canvas',
                                                    }}
                                                />
                                            </Box>
                                            <Box sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                ml: 1
                                            }}>
                                                <Button variant='outlined' size='small'
                                                    sx={{ m: 1, ...buttonClasses.lynkitOrangeEmpty, minWidth: "100px" }}
                                                    onClick={() => handleClear()}
                                                >
                                                    clear
                                                </Button>
                                                <Button variant='outlined' size='small'
                                                    sx={{ m: 1, ...buttonClasses.lynkitOrangeFill, minWidth: "100px" }}
                                                    onClick={() => handleSave()}>
                                                    Save
                                                </Button>
                                            </Box>
                                        </Box>
                                        :
                                        labelDetail.showLogoUpload
                                            ? <Box sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center"
                                            }}>
                                                <Box sx={{ mr: 1 }}>
                                                    <FormControl >
                                                        <TextField
                                                            type='file'
                                                            sx={{
                                                                ...selectStylesOverride,
                                                            }}
                                                            onChange={handleFileChange}
                                                        />
                                                    </FormControl>
                                                </Box>
                                                <Box sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    ml: 1
                                                }}>
                                                    <Button variant='outlined' size='small'
                                                        disabled={loader.disabled}
                                                        sx={{ m: 1, ...buttonClasses.lynkitOrangeEmpty, minWidth: "100px" }}
                                                        onClick={() => uploadLogoHandler()}>
                                                        Upload
                                                    </Button>
                                                </Box>
                                            </Box>
                                            :
                                            scannerLoader ?
                                                <GenericLoader />
                                                :
                                                <ScannerCode
                                                    templateHeader={templateHeader} invType={invType}
                                                    data={(inventories.slice(count?.at(0) - 1, count?.at(1)))?.at(itemIndex) || {}}
                                                    connectedMaster={connectedMaster} labelConfig={labelConfig}
                                                    encodeConfig={encodeConfig} isAsn={isAsn} isScanned={isScanned}
                                                    labelDetail={labelDetail} setLabelDetail={setLabelDetail}
                                                    labelType={labelType} includeItemList={includeItemList}
                                                />

                                    // (count?.at(0)-1), (count?.at(1))
                                }

                            </Grid>
                            {labelDetail.showSignCanvas || labelDetail.showLogoUpload
                                ? ""
                                : scannerLoader ? "" :
                                    <Grid container justifyContent="center" alignItems="center" direction={"row"} sx={{ mt: 1 }}>
                                        <IconButton size='small'
                                            sx={{ backgroundColor: theme.themeOrange, "&:hover": { backgroundColor: theme.themeOrange } }}
                                            onClick={() => handleIndexChange(-1)}
                                        >
                                            <KeyboardArrowLeft sx={{ color: "white" }} />
                                        </IconButton>
                                        <Typography sx={{ ...textClasses.boldText, color: theme.themeOrange, textAlign: "center", ml: 2, mr: 2 }}>
                                            {`${itemIndex + 1} of ${count?.at(1) - count?.at(0) + 1}`}
                                            {/* {`${itemIndex + 1} of ${count}`} */}
                                        </Typography>
                                        <IconButton size='small'
                                            sx={{ backgroundColor: theme.themeOrange, "&:hover": { backgroundColor: theme.themeOrange } }}
                                            onClick={() => handleIndexChange(1)}
                                        >
                                            <KeyboardArrowRight sx={{ color: "white" }} />
                                        </IconButton>

                                    </Grid>
                            }
                        </>
                        :
                        <>
                            <Grid containter>
                                <div ref={componentRef} id="toPrint">
                                    {

                                        inventories.slice((count?.at(0) - 1), (count?.at(1))).map((inv, i) => {
                                            return (
                                                <Grid container justifyContent={"center"} alignItems="center" id={"code" + i} sx={{ mb: 2 }}>
                                                    <ScannerCode templateHeader={templateHeader}
                                                        invType={invType} data={inv} labelConfig={labelConfig}
                                                        encodeConfig={encodeConfig} isAsn={isAsn} isScanned={isScanned}
                                                        connectedMaster={connectedMaster} labelDetail={labelDetail} setLabelDetail={setLabelDetail}
                                                        labelType={labelType} includeItemList={includeItemList}
                                                    />
                                                </Grid>
                                            )
                                        })
                                    }

                                </div>
                            </Grid>
                        </>
                }
            </div>

        </>
    )
}

function sortingData(data = []) {
    if (!data || data.length < 1) return;
    data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
}

export default function Label({ data, showModal, handleCloseModal, isPalletEntry=false }) {
    ////("Label Data", data);
    const theme = useTheme();
    const dispatch = useDispatch();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const initialLabelOption = {
        "allowed": [
            "shipmentOnly",
            "shipmentPackageOnly",
            "shipmentItemOnly",
            "SPI"
        ],
        "_id": "67a5f4745847a7258c9add49",
        "label": "Shipment Only",
        "value": "shipmentOnly",
        "icon": "ShipmentOnlyIcon"
    }
    const componentRef = useRef();

    const [step, setStep] = useState(0);
    const [loader, setLoader] = useState(false);
    const [pdfContent, setPdfContent] = useState(null)
    const [subStep, setSubStep] = useState(0);
    const [count, setCount] = useState([1, 1, 1]);//first, 2nd and max
    const [labelList, setLabelList] = useState([]);
    const [labelDetail, setLabelDetail] = useState([]);
    const [allInventory, setAllInventory] = useState([]);
    const [labelType, setLabelType] = useState({});
    const [switch_active, setSwitch_active] = useState("package");
    const [scannerLoader, setScannerLoader] = useState(false)
    const [labelTypeLoader, setLabelTypeLoader] = useState(false)
    const [labelTypes, setLabelTypes] = useState([]);

    let inventories = useSelector(state => state.inventory.labelInventories);
    let templateHeader = useSelector(state => state.inventory.templateHeader);
    const asnHeader = useSelector(state => state.asnEntry.shipmentTemplateHeader);
    const itemMasterHeader = useSelector(state => state.asnEntry.orderConnectedMasterData);
    ////("Asn Header", asnHeader);

    const isAsn = (() => {
        let obj = inventories;
        ////("Inventories for isAsn", obj);
        if (Array.isArray(inventories) && inventories.length > 0) {
            obj = inventories[0];
        }
        ////("Inventories for isAsn Obj", obj);
        return obj.ASN_Detail?.length && (obj.ASN_Detail?.at(0)?.template_id);
    })();
    ////("Is Asn", isAsn);
    const isScanned = (() => {
        let obj = inventories;
        if (Array.isArray(inventories) && inventories.length > 0) {
            obj = inventories[0];
        }
        return obj.ItemMaster_Detail && obj.ItemMaster_Detail.templateId

    })();


    const resetParams = () => {
        setStep(0);
        setAllInventory([]);
        setLabelType(initialLabelOption);
        setSwitch_active("package");
        resetSubset()
    }

    const resetSubset = () => {
        // setCount([1,1,1])
        setSubStep(0)
    }

    const updateLabelHandler = () => {
        setLoader(true)
        dispatch(uploadLabelLogo({ label_ID: labelDetail._id, labelDetail, update_for: "detail" }, ({ success, message }) => {
            showToast(message, !success)
            setLoader(false)
        }))
    }

    const spiType = (() => {
        if (templateHeader) {
            if (!templateHeader.packageHeader && !templateHeader.itemHeader) {
                return "shipmentOnly"
            }
            else if (templateHeader.packageHeader && !templateHeader.itemHeader) {
                return "shipmentPackageOnly"
            }
            else if (!templateHeader.packageHeader && templateHeader.itemHeader) {
                return "shipmentItemOnly"
            }
            else {
                return "SPI"
            }
        }
        return null;
    })();

    useEffect(() => {
        if (data) {
            if(labelType?.value){
                setAllInventory([]);
                setSwitch_active("package");
                setScannerLoader(true)
                dispatch(
                    getInventoryForLabel(labelType?.value, {
                        shipmentId: data.shipmentId, 
                        projection_for:"labelView", 
                        fetchLocation:false,
                        fetchItemMaster:true
                    }, () => { 
                        setTimeout(setScannerLoader(false), 300)
                    })
                )
                setLabelTypeLoader(true)
                dispatch(
                    getTemplateHeader({
                        template_id: data.templateId
                    }, () => {
                        setTimeout(setLabelTypeLoader(false), 300)
                    })
                )
            }
        }
        else {
            resetParams();
        }
    }, [data, labelType])

    useEffect(() => {
        if (isAsn) {
            dispatch(
                getShipmentMasterHeader({
                    template_id: isAsn
                })
            )
            // let obj = inventories;
            // if(Array.isArray(inventories) && inventories.length > 0){
            //     obj = inventories[0];
            // }
            // if(obj.ASN_Detail && obj.ASN_Detail.ASN_ItemDetail.formData){
            //     dispatch(
            //         getShipmentConnectedMasterDetail("itemMaster", {SKU_ID: obj.ASN_Detail.ASN_ItemDetail.formData.product_ID, template_id: obj.ASN_Detail.itemMasterId || obj.ASN_Detail.ASN_ItemDetail.itemMasterId })
            //     )
            // }
        }
        if (isScanned) {
            dispatch(
                getShipmentMasterHeader({
                    template_id: isScanned
                })
            )
        }
    }, [inventories])

    useEffect(() => {
        if (inventories) {
            let temp = []
            if (!Array.isArray(inventories)) {
                if (labelType?.value == "packageAndEveryItem") {
                    if (switch_active == "item") {
                        temp = inventories["items"] || []
                    }
                    else {
                        temp = inventories["packages"] || []
                    }
                }
                else {
                    temp = [inventories]
                }
            }
            else {
                if (labelType?.value == "packageAndEveryItem") {
                    if (switch_active == "item") {
                        temp = (inventories?.map(dl=>dl?.items || []) || []).flat(1)
                    }
                    else {
                        temp = inventories || []
                    }
                }
                else if (labelType?.value == "packageAndSingleItem") {
                    // temp = inventories?.reduce((accu,curr)=>{
                    //     const {items=[],...rest}=curr
                    //     if(items?.length>0){
                    //         curr?.items?.forEach(element=>{
                    //             accu.push({...rest,items:[element]})
                    //         })
                    //     }
                    //     else{
                    //         accu.push(rest)
                    //     }
                    //     return accu

                    // },[]) || []
                    temp = inventories || []
                }
                else {
                    temp = inventories || []
                }
            }
            // console.log("temp",temp);
            sortingData(temp)
            setCount([1, temp.length, temp.length])
            setAllInventory(temp)
        }
        else {
            dispatch({ type: FETCH_SHIPMENT_TEMPLATE_HEADER, payload: [] })
            dispatch({ type: FETCH_ORDER_CONNECTED_MASTER_DETAIL, payload: null })
            setCount([1, 1, 1])
            setAllInventory([])
        }
    }, [inventories, switch_active])
// console.log("allInventory",data)
    const fetchLabelConfig = async () => {

        setLabelTypeLoader(true)
        const [labelConfigData, labelConfigOption] = await Promise.all([
            new Promise((resolve) => {
                dispatch(getLabelConfigration({ labelType: "inventory" }, ({ data = [], success = false, message }) => {
                    resolve(data || [])
                }))
            }),
            new Promise((resolve) => {
                dispatch(getCommanConfig({shipmentId:data?.shipmentId,configType:"inventoryLabel"}, (data={}) => {
                    resolve(data?.configDetail || {})
                }))
                // dispatch(getLabelConfigInventoryOption({ type: "wms" , shipmentId:data?.shipmentId}, ({ data = {} }) => {
                //     resolve(data || {})
                // }))
            }),
        ]);

        setLabelTypeLoader(false)
        setLabelList(labelConfigData || [])
        if (labelConfigData?.length) {
            setLabelDetail({ ...labelConfigData[0], label: labelConfigData[0].labelName, value: labelConfigData[0]._id })
        }
        const allLabelTypeOptions=labelConfigOption?.labelOptions?.allOptions || []
        setLabelTypes(allLabelTypeOptions)

        if (isPalletEntry) {
            setLabelType(allLabelTypeOptions?.find(dl=>dl?.value=="packageAndEveryItem") || initialLabelOption);
            setStep(1);
            resetSubset()
        }
        else{
            // setLabelType(labelConfigOption?.labelOptions?.find(dl=>dl?.value==labelConfigOption?.defaultSelectedLabel) || initialLabelOption)
            const selctedOption=allLabelTypeOptions?.find(dl=>dl?.value==labelConfigOption?.labelOptions?.value)
            setLabelType(selctedOption || initialLabelOption)
            if(labelConfigOption?.isAutoRedirect?.isActive){
                setStep(1);
                resetSubset()
            }
        }
    }
    useEffect(() => {
        fetchLabelConfig();
    }, [])

    return (
        <Dialog
            open={showModal}
            onClose={handleCloseModal}
            scroll={"paper"}
            fullScreen={fullScreen}
            PaperProps={{ sx: { width: "70%" } }}
            maxWidth="50%"
        >
            <DialogTitle id="responsive-dialog-title">
                {
                    step == 0 ?
                        <Typography sx={{ ...textClasses.cardTitle }}>
                            Generate and Print Labels For Your Inventory
                        </Typography>
                        : subStep == 0
                            ?
                            <Grid container>
                                <Grid item xs={6} sm={3}>
                                    <Typography sx={{ ...textClasses.cardTitle }}>
                                        Generate Label(s):
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={4} sx={{ ml: 2 }}>
                                    <Autocomplete
                                        id="Inventory-label"
                                        size="small"
                                        fullWidth
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                label="Select Inventory Type"
                                                sx={{
                                                    ...selectStylesOverride,
                                                }}
                                            />
                                        )}
                                        disableClearable
                                        options={labelTypes.filter(lt => spiType && lt.allowed.indexOf(spiType) >= 0)}
                                        onChange={(e, option) => setLabelType(option || {})}
                                        value={ labelType?.label || ""}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4} sx={{ ml: 2 }}>
                                    <Autocomplete
                                        id="barcode-label"
                                        size="small"
                                        fullWidth
                                        disabled={labelList.length < 2}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                label="Select Label Type"
                                                sx={{
                                                    ...selectStylesOverride,
                                                }}
                                            />
                                        )}
                                        options={labelList.map(dl => ({ ...dl, label: dl.labelName, value: dl._id }))}
                                        onChange={(e, option) => setLabelDetail(option || {})}
                                        value={labelDetail.label || ""}
                                    />
                                </Grid>
                            </Grid>
                            :
                            <>
                                <Button variant='outlined' size='small' sx={{ ...buttonClasses.lynkitOrangeEmpty, minWidth: "100px" }} onClick={() => setSubStep(0)}>
                                    Back
                                </Button>
                                {/* {
                                    pdfContent
                                        ?
                                        <>
                                            <BlobProvider document={pdfContent}>
                                                {
                                                    ({ url }) => {
                                                        return (
                                                            <Button href={url} target="_blank" variant='contained' size='small' sx={{ml:1,...buttonClasses.lynkitBlackFill,minWidth: "100px"}}>
                                                                Download Labels
                                                            </Button>
                                                        )
                                                    }
                                                }
                                            </BlobProvider>
                                        </>
                                        :
                                        <CircularProgress sx={{ ml: 1 }} />
                                } */}
                                <ReactToPrint
                                    documentTitle="ShippingInvoice"
                                    onAfterPrint={() => setSubStep(1)}
                                    bodyClass="adjustContent"
                                    removeAfterPrint
                                    trigger={() => <Button variant='contained' size='small'
                                        sx={{ ml: 1, ...buttonClasses.lynkitOrangeFill, minWidth: "100px" }}
                                    >
                                        Print ({count?.at(1) - count?.at(0) + 1})
                                    </Button>}
                                    content={() => componentRef.current}
                                />
                            </>


                }
            </DialogTitle>
            <DialogContent dividers>
                {step == 0
                    ?
                    labelTypeLoader ?
                        <GenericLoader />
                        :
                        <SelectLabel
                            spiType={spiType}
                            labelType={labelType}
                            setLabelType={setLabelType}
                            labelTypes={labelTypes || []}
                        />
                    :
                    <Generate
                        spiType={spiType}
                        isAsn={isAsn}
                        labelDetail={labelDetail}
                        setLabelDetail={setLabelDetail}
                        isScanned={isScanned}
                        templateHeader={templateHeader}
                        asnHeader={asnHeader}
                        itemMasterHeader={itemMasterHeader}
                        inventories={allInventory}
                        labelType={labelType}
                        setLabelType={setLabelType}
                        setStep={setStep}
                        subStep={subStep}
                        setSubStep={setSubStep}
                        count={count}
                        setCount={setCount}
                        pdfContent={pdfContent}
                        setPdfContent={setPdfContent}
                        handleCloseModal={handleCloseModal}
                        switch_active={switch_active}
                        setSwitch_active={setSwitch_active}
                        componentRef={componentRef}
                        scannerLoader={scannerLoader}
                        isPalletEntry={isPalletEntry}
                    />
                }
            </DialogContent>
            {
                subStep < 1
                    ?
                    <DialogActions>
                        {
                            step == 0
                                ? <>
                                    <Button variant='outlined' size='small' sx={{ ...buttonClasses.lynkitOrangeEmpty, minWidth: "100px" }} onClick={handleCloseModal}>
                                        Cancel
                                    </Button>
                                    <Button variant='outlined' size='small' sx={{ ...buttonClasses.lynkitOrangeFill, minWidth: "100px" }}
                                        onClick={() => {
                                            setStep(1);
                                            resetSubset()
                                        }}>
                                        Next
                                    </Button>
                                </>
                                : subStep == 0
                                    ?
                                    <>
                                        <Button variant='outlined' size='small' sx={{ ...buttonClasses.lynkitOrangeEmpty, minWidth: "100px" }}
                                            onClick={handleCloseModal}>
                                            Cancel
                                        </Button>
                                        {!isPalletEntry &&<Button variant='outlined' size='small' sx={{ ...buttonClasses.lynkitOrangeEmpty, minWidth: "100px" }}
                                            onClick={() => { setStep(0); setPdfContent(null) }}>
                                            Back
                                        </Button>}
                                        <Button variant='outlined' size='small' sx={{ ...buttonClasses.lynkitOrangeFill, minWidth: "100px" }}
                                            onClick={() => updateLabelHandler()} disabled={loader}>
                                            Save Label Config
                                        </Button>
                                        <Button variant='outlined' size='small' sx={{ ...buttonClasses.lynkitOrangeFill, minWidth: "100px" }}
                                            onClick={() => setSubStep(1)} disabled={loader}>
                                            Generate ({count?.at(1) - count?.at(0) + 1})
                                        </Button>

                                    </>
                                    : ""
                        }

                    </DialogActions>
                    : ""
            }
        </Dialog>
    )
}

// data[invType.toLowerCase() + "Id"