import React, { useEffect } from 'react'
import { Box, Typography, Card, CardContent, CardHeader, Avatar, IconButton, Grid, Paper } from "@mui/material";
import theme, {textClasses} from '../../../../../static/styles/theme'
import ClearIcon from '@mui/icons-material/Clear';
import DateRangeFilter from '../../DateRangeFilter';
import GenericTable from '../../../../Components/Generic/GenericTable';
import { dataReadFunction, textCapitalize, dateTimeFormater } from '../../../../../services/functions';
import GenericLoader from '../../../../Components/Generic/GenericLoader';

const TableCardWidget = ({ item, setLayout, styleView = {} ,onFilterChange=()=>console.log("") ,isDefaultDashboard = false,dataLoader=false ,children }) => {
    // const onFilterChange= (value, customRange)=>{
    //     console.log('value, customRange',value, customRange);   
    // }
    const tableHeaders = item?.headers?.map(dl=>dl?.label)
    const getTableCell = (header, row, i) => {
        let value = dataReadFunction({ name: header?.name || "", renderOption: header?.renderOption || [] }, row);

        if(header?.type=="date"){
         return (
                    <Typography
                        sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", color: header?.color || theme.themeText }}
                    >
                        {value ? dateTimeFormater(value) : "-"}
                    </Typography>
                );
        }
        else{

            return (
                <Typography
                    sx={{
                        ...textClasses.normalText,
                        textAlign: "center",
                        textTransform: "capitalize",
                        color: header?.color || theme.themeText
                    }}
                >
                    {textCapitalize(value) ?? "-"}
                </Typography>
            );
        }
    };

    const tableRows = item?.values?.map((row, i) => {
        return item?.headers?.map((header, j) => {
            return getTableCell(header, row, i);
        });
    });

    return (
        <Paper elevation={3} sx={{ width: 'inherit', height: 'inherit', p: 1, ...styleView, overflowY:'auto' }} >
            <IconButton
                size="small"
                sx={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    color: theme.themeOrange,
                }}
                onClick={() => {
                    setLayout((prev) => prev.filter((l) => l.i !== item.i));
                }}
            >
                <ClearIcon fontSize="small" />
            </IconButton>

            <Box sx={{ pt: 2, pb: 2, display: "flex", alignItems: "center" }}>
                <Avatar className="br-1" variant="square">
                    {item?.icon ? (
                        <span
                            className="material-symbols-outlined"
                            style={{ fontSize: "40px" }}
                        >
                            {item.icon}
                        </span>
                    ) : (
                        <span
                            className="material-symbols-outlined"
                            style={{ fontSize: "40px" }}
                        >
                            text_fields
                        </span>
                    )}
                </Avatar>
                <Typography
                    sx={{ textAlign: "left", fontWeight: "bold", ml: 1 }}
                    variant="h6"
                    gutterBottom
                >
                    {item?.title}
                </Typography>
            </Box>

            {
                    isDefaultDashboard
                        ? <Box sx={{
                            padding: 2,display: "flex", justifyContent: "flex-end"
                        }}>
                            {children}
                        </Box>
                        : item?.isDateFilter
                            ? <Box sx={{ display: "flex", justifyContent: "flex-end", px: 2, mt: -1 }}>
                                <DateRangeFilter dateFilterOption={item?.dateFilterOption} />
                            </Box>
                            : ""
                }
            {!dataLoader?(<Box>
                <GenericTable
                    data-testid="table-widget"
                    header={tableHeaders}
                    rows={tableRows || []}
                />
            </Box>): <GenericLoader />}
            
        </Paper>
    )
}

export default TableCardWidget;