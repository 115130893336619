import React, { useEffect } from "react";
import { useState } from "react";
import {
    Grid,
    Button,
    TextField,
    FormLabel,
    Typography,
    Autocomplete,
    FormControlLabel,
    Radio,
    Box
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import {
//     getAllTemplates,
//     getTemplateHeader,
//     getCommanConfig
// } from "../../../../redux/actions";
import { getAllTemplates, getCommanConfig } from "../../../../redux/actions";
import theme, {
    buttonClasses,
    inputClasses,
    textClasses,
} from "../../../../static/styles/theme";
import {
    capitalizeSentence,
} from "../../../../services/functions";
import history from "../../../../services/history";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import AlertDialog from "../../../Components/DialogBox/dialogbox";
import TypeAndSearch from "../../../Components/TypeAndSearch";
import { ASN_Component } from "./constant";

const MultiASNReceivingDialogBox = ({ status,  handleClose }) => {
    // console.log("sta",status)
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [receivingMethod, setReceivingMethod] = useState("");
    const [sortingMethod, setSortingMethod] = useState("");
    const [selectedInwardTemplate, setSelectedInwardTemplate] = useState(null);
    const [selectedASNTemplate, setSelectedASNTemplate] = useState(null);
    const [selectedASNID, setSelectedASNID] = useState(null);
    const [allTemplates, setAllTemplates] = useState([]);
    const [configDetail, setConfigDetail] = useState({});

    const receivingMethodOptions = configDetail?.receivingMethod?.allOptions || [];
    const sortingMethodOptions = configDetail?.sortingMethod?.allOptions || [];
    const isSelectedASN_ID=configDetail?.isASNInputOption?.isActive == true && configDetail?.isASNInputOption?.value == "ASN_ID"

    const fetchTemplate = (templateLevel = "",isTempSelectedASN_ID, callback) => {
        if (templateLevel) {
            dispatch(
                getAllTemplates(
                    {
                        type: "wms",
                        templateType: ["inwardDataEntry", "ASNTemplate"],
                        status: "published",
                        fetchAttributes: false,
                        templateLevel: isTempSelectedASN_ID ? templateLevel:[...templateLevel, "SI"] || "SI",
                        projection: { _id: 1, name: 1, templateType: 1, templateLevel: 1 }
                    },
                    (data) => {
                        let tempData = data?.filter((item) => item?.templateType === "ASNTemplate" || (item?.templateType === "inwardDataEntry" && templateLevel?.includes(item?.templateLevel)))
                        setAllTemplates(tempData || [])
                        if (callback) callback(tempData)
                    }
                )
            );
        }
        else {
            setAllTemplates([])
            if (callback) callback([])
        }
    }

    const setTemplateValues=(levels,isTempSelectedASN_ID)=>{
        fetchTemplate(levels|| "",isTempSelectedASN_ID, (data) => {
            if (data && data?.length) {
                setAllTemplates(data || [])
                setSelectedInwardTemplate(data?.find(dl=>dl?.templateType == "inwardDataEntry" && levels?.includes(dl?.templateLevel)))
                if(!isTempSelectedASN_ID){
                    setSelectedASNTemplate(data?.find(dl=>dl?.templateType == "ASNTemplate"))
                }
            }
        })
    }

    useEffect(() => {
        setLoader(true)
        dispatch(getCommanConfig({ configType: "asnReceiving" }, (data) => {
            setLoader(false)
            let tempConfigData = data?.configDetail || {}
            const isTempSelectedASN_ID=data?.configDetail?.isASNInputOption?.isActive == true && data?.configDetail?.isASNInputOption?.value == "ASN_ID"
            setConfigDetail(tempConfigData)
            setSortingMethod(tempConfigData?.sortingMethod?.value)
            // setReceivingMethod(tempConfigData?.receivingMethod?.value)
            const allReceivingOptions = tempConfigData?.receivingMethod?.allOptions || []
            const selectedReceivingOption = allReceivingOptions?.find((dl) => dl?.value == tempConfigData?.receivingMethod?.value)
            let selectedReceivingMethod = ""
            if (allReceivingOptions?.length && selectedReceivingOption) {
                selectedReceivingMethod = selectedReceivingOption?.value;
            }
            else {
                selectedReceivingMethod = allReceivingOptions?.at(0)?.value || "pallets";
            }
            setReceivingMethod(selectedReceivingMethod)
            if (selectedReceivingOption?.templateType?.length) {
                setTemplateValues(selectedReceivingOption?.templateType,isTempSelectedASN_ID)
            }

        }))

    }, [status])

// console.log("values",receivingMethod,sortingMethod,selectedASNTemplate,selectedInwardTemplate)
    return (
        <AlertDialog
            viewDialog={status != null}
            handleClose={handleClose}
            viewSearchInput={false}
            showDivider={true}
            width="60%"
            actionsCSS={{ justifyContent: 'flex-start' }}
            title={
                <Typography sx={{ ...textClasses.cardTitle }}>
                    Select Inward Data Entry Template & Receiving Method
                </Typography>
            }
            body={<>
                {
                    loader ?
                        <Grid data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
                            <Grid item><GenericLoader /></Grid>
                        </Grid> :
                        status == "Receive" && (
                            <>
                                <Grid container sx={{ mt: 1 }}>
                                    <Grid container item sm={4}>
                                        <FormLabel required sx={{ mt: 1, color: "#000000" }}>
                                            Receiving Method :
                                        </FormLabel>
                                    </Grid>
                                    <Grid container item sm={8}>
                                        {receivingMethodOptions?.map((m, i) => {
                                            return (
                                                <Grid item sm={6} key={i}>
                                                    <FormControlLabel
                                                        onClick={() => {
                                                            setReceivingMethod(m.value)
                                                            setSelectedInwardTemplate(null)
                                                            setTemplateValues(m?.templateType,isSelectedASN_ID)
                                                        }}
                                                        checked={receivingMethod == m.value}
                                                        label={
                                                            <Typography sx={{ fontSize: "13px" }}>
                                                                {m.label}
                                                            </Typography>
                                                        }
                                                        control={
                                                            <Radio
                                                                value={m.value}
                                                                sx={{
                                                                    "&, &.Mui-checked": { color: theme.themeOrange },
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>

                                {!isSelectedASN_ID && <Grid container sx={{ mt: 1 }}>
                                    <Grid container item sm={4}>
                                        <FormLabel required sx={{ mt: 1, color: "#000000" }}>
                                            Sorting Method :
                                        </FormLabel>
                                    </Grid>
                                    <Grid container item sm={8}>
                                        {sortingMethodOptions?.map((m, i) => {
                                            return (
                                                <Grid item sm={6} key={i}>
                                                    <FormControlLabel
                                                        onClick={() => {
                                                            setSortingMethod(m.value)
                                                        }}
                                                        checked={sortingMethod == m.value}
                                                        label={
                                                            <Typography sx={{ fontSize: "13px" }}>
                                                                {m.label}
                                                            </Typography>
                                                        }
                                                        control={
                                                            <Radio
                                                                value={m.value}
                                                                sx={{
                                                                    "&, &.Mui-checked": { color: theme.themeOrange },
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>

                                </Grid>
                                }

                                <Grid container sx={{ mt: 1 }}>
                                    <Grid container item sm={4}>
                                        <FormLabel required sx={{ mt: 1, color: "#000000" }}>
                                            Select Inward Template :
                                        </FormLabel>
                                    </Grid>
                                    <Grid container item sm={8}>
                                        <Autocomplete
                                            value={selectedInwardTemplate}
                                            onChange={(e, option) => {
                                                setSelectedInwardTemplate(option);
                                            }}
                                            getOptionLabel={(option) =>
                                                capitalizeSentence(option.name || "")
                                            }
                                            size="small"
                                            options={allTemplates?.filter(dl => dl?.templateType == "inwardDataEntry" && receivingMethodOptions?.find((dl) => dl?.value == receivingMethod)?.templateType?.includes(dl?.templateLevel)) || []}
                                            sx={{
                                                ...inputClasses.filterField,
                                                minWidth: "250px",
                                                m: 0,
                                                ".MuiInputBase-input": {
                                                    fontSize: "14px",
                                                    color: "#455A64",
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    sx={{ color: "#455A64", ...textClasses.normalText }}
                                                    {...params}
                                                    label="Inward Template"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{ mt: 2 }}>
                                    <Grid container item sm={4}>
                                        <FormLabel required sx={{ mt: 1, color: "#000000" }}>
                                            Select ASN {isSelectedASN_ID? "": "Template"} :
                                        </FormLabel>
                                    </Grid>
                                    <Grid container item sm={8}>
                                        {isSelectedASN_ID ?
                                            <Box sx={{ minWidth: "250px", }}>
                                                <TypeAndSearch
                                                    data-testid="asnFilter"
                                                    component={ASN_Component || {}}
                                                    handleChange={(e, option) => {
                                                        setSelectedASNID(option)
                                                    }}
                                                    label={selectedASNID?.label || ""}
                                                    value={selectedASNID?.value || ""}
                                                    sx={{ width: "100%", }}
                                                    componentStyle={{
                                                        ...inputClasses.filterField,
                                                        ".MuiInputBase-input": {
                                                            fontSize: "15px",
                                                            color: "#455A64",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            : 
                                            <Box sx={{ minWidth: "250px", }}>
                                                <Autocomplete
                                                    value={selectedASNTemplate}
                                                    onChange={(e, option) => {
                                                        setSelectedASNTemplate(option);
                                                    }}
                                                    getOptionLabel={(option) => option.name}
                                                    size="small"
                                                    options={allTemplates?.filter(dl => dl?.templateType == "ASNTemplate")}
                                                    sx={{
                                                        ...inputClasses.filterField,
                                                        width: "100%",
                                                        m: 0,
                                                        ".MuiInputBase-input": {
                                                            fontSize: "14px",
                                                            color: "#455A64",
                                                        },
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            sx={{ color: "#455A64", ...textClasses.normalText }}
                                                            {...params}
                                                            label="ASN Template"
                                                        />
                                                    )}
                                                />
                                            </Box>
                                        }

                                      
                                    </Grid>
                                </Grid>
                            </>
                        )}
            </>
            }

        >
            <Button
                variant="outlined"
                size="small"
                sx={{ ...buttonClasses.lynkitBlackFill, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                onClick={handleClose}
            >
                Cancel
            </Button>
            <Button
                variant="contained"
                size="small"
                sx={{ ...buttonClasses.lynkitOrangeFill, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                disabled={!selectedInwardTemplate || !receivingMethod || !sortingMethod || (isSelectedASN_ID?!selectedASNID :!selectedASNTemplate)}
                onClick={() =>
                    history.push("/dashboard/data/multi-asn-receiving", {
                        // asnDetail: { ASN_Id: entry?.ASN_Id },
                        // asnTemplateHeader: templateHeader,
                        inwardTemplate: selectedInwardTemplate,
                        receivingMethod: receivingMethod,
                        sortingMethod: sortingMethod,
                        asnTemplate: selectedASNTemplate,
                        asnID:selectedASNID,
                    })
                }
            >
                {status}
            </Button>
        </AlertDialog>


    );
};

export default MultiASNReceivingDialogBox;