import { useEffect, useState } from "react"

import CountTypeWidget from "../WidgetComponents/Count"
import { useDispatch } from "react-redux"
import { getOpertionCountData } from "../../../../redux/actions/CustomDashboard"
import DateRangeFilter from "../WidgetComponents/DateRangeFilter"
import { multiDateToISOConvertor } from "../../../Components/Generic/GenericMultiDateRange"
import { Box, Grid, Skeleton } from "@mui/material"
import GenericLoader from "../../../Components/Generic/GenericLoader"

const convertToDateRange = (value) => {
    const now = new Date(); // Current date
    let dateFrom = new Date(now); // Start date
    let dateTo = new Date(now); // End date

    switch (value) {
        case "1d":
            dateFrom.setDate(now.getDate() - 1);
            break;
        case "7d":
            dateFrom.setDate(now.getDate() - 7);
            break;
        case "1m":
            dateFrom.setMonth(now.getMonth() - 1);
            break;
        case "1y":
            dateFrom.setFullYear(now.getFullYear() - 1);
            break;
        default:
            throw new Error("Invalid value");
    }

    return {
        dateFrom: dateFrom.toISOString(),
        dateTo: dateTo.toISOString()
    };
};

const OperationCountComponent = ({ item, styleView ,warehouseDetails }) => {
    const dispatch = useDispatch()
    const [operationCount, setOperationCount] = useState({})
    const [selectedRange, setSelectedRange] = useState("7d");
    const [dateFilter, setDateFilter] = useState([]);
    const [loader, setLoader] = useState(true);
    const [dataLoader,setDataLoader] = useState(true)

    const [updatedItem, setUpdatedItem] = useState(item || []);


    const handleSelection = (value) => {
        setSelectedRange(value);
        let dates

        if (value !== 'dateRange') {
            dates = convertToDateRange(value)
        }
        setDateFilter([])
        getDataDateRangeWise(dates?.dateFrom, dates?.dateTo)
    };

    const handleDateChange = (newDates) => {
        setDateFilter(newDates);
        let datarry;
        if (newDates.length >= 2) {
            datarry = multiDateToISOConvertor(newDates)
        }
        if (datarry?.length >= 2) {
            getDataDateRangeWise(datarry[0], datarry[1])
        }

    };
    const getDataDateRangeWise = (dateFrom = '', dateTo = '') => {
        let warehouseIds= warehouseDetails?.map(item => item._id)
        let payload = {
            wareHouse:warehouseIds || [],
            dateFrom: dateFrom,
            dateTo: dateTo
        }
        setDataLoader(true)
        dispatch(getOpertionCountData(payload, (data) => {
            const transformedArray = [
                {
                    "read": false,
                    category: "Total Inward",
                    value: data?.data?.putAwayCompletedJob
                },
                {
                    "read": false,
                    category: "Total Outward",
                    value: data?.data?.dispatchCompletedJob
                }
            ];

            setOperationCount(data)
            setUpdatedItem((prev) => ({
                ...prev,
                values: transformedArray
            }))
            setLoader(false)
            setDataLoader(false)
        }))
    }
    useEffect(() => {
        getDataDateRangeWise()
    }, [warehouseDetails, dispatch])
    return (<>
        {!loader ? (< >

            <CountTypeWidget item={updatedItem} isDefaultDashboard={true} styleView={styleView} dataLoader={dataLoader}>
                <DateRangeFilter dateFilterOption={item?.dateFilterOption}
                    selectedRange={selectedRange}
                    dateFilter={dateFilter}
                    handleSelection={handleSelection}
                    handleDateChange={handleDateChange} />
            </CountTypeWidget>
        </>) : <Grid item>
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="rectangular" width={210} height={60} />
                <Skeleton variant="rounded" width={210} height={60} />

        </Grid>}

    </>)

}

export default OperationCountComponent;