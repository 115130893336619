import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { multiDateToISOConvertor } from "../../../Components/Generic/GenericMultiDateRange";
import {  getInventoryWeightCount} from "../../../../redux/actions/CustomDashboard";
import { Box, Grid, Skeleton } from "@mui/material";
import CountTypeWidget from "../WidgetComponents/Count";
import DateRangeFilter from "../WidgetComponents/DateRangeFilter";



const convertToDateRange = (value) => {
    const now = new Date(); // Current date
    let dateFrom = new Date(now); // Start date
    let dateTo = new Date(now); // End date

    switch (value) {
        case "1d":
            dateFrom.setDate(now.getDate() - 1);
            break;
        case "7d":
            dateFrom.setDate(now.getDate() - 7);
            break;
        case "1m":
            dateFrom.setMonth(now.getMonth() - 1);
            break;
        case "1y":
            dateFrom.setFullYear(now.getFullYear() - 1);
            break;
        default:
            throw new Error("Invalid value");
    }

    return {
        dateFrom: dateFrom.toISOString(),
        dateTo: dateTo.toISOString()
    };
};

const InventoryWeightComponent = ({ item, styleView ,warehouseDetails }) => {
    const dispatch = useDispatch()
    const [selectedRange, setSelectedRange] = useState("7d");
    const [dateFilter, setDateFilter] = useState([]);
    const [updatedItem, setUpdatedItem] = useState(item || []);
    const [dataLoader,setDataLoader] = useState(true)
    const [loader , setLoader]= useState(true)


    const handleSelection = (value) => {
        setSelectedRange(value);
        let dates
        if (value !== 'dateRange') {
            dates = convertToDateRange(value)
        }
        setDateFilter([])
        getDataDateRangeWise(dates?.dateFrom, dates?.dateTo)
    };

    const handleDateChange = (newDates) => {
        setDateFilter(newDates);
        let datarry;

        if (newDates.length >= 2) {
            datarry= multiDateToISOConvertor(newDates)
        }
        if (datarry?.length >= 2) {
            getDataDateRangeWise(datarry[0], datarry[1])
        }

    };
    const getDataDateRangeWise = (dateFrom = '', dateTo = '') => {
        let warehouseIds= warehouseDetails?.map(item => item._id)
        let payload = {
            wareHouse: warehouseIds || [],
            dateFrom: dateFrom,
            dateTo: dateTo
        }
        setDataLoader(true)
        
        dispatch(getInventoryWeightCount(payload, (data) => {
            const transformedArray = [
                {
                    read : false,
                    category : "In Stock Weight",
                    value : data?.data?.inStockWeight
                },
                {
                    read : false,
                    category : "To Be Received Weight",
                    value : data?.data?.toBeReceivedWeight
                },
                {
                    read : false,
                    category : "Gate Out Weight",
                    value : data?.data?.gateOutWeight
                },
                {
                    read : false,
                    category : "Gate In Weight",
                    value : data?.data?.gateInWeight    
                }
            ];

            setUpdatedItem((prev) => ({
                ...prev,
                values: transformedArray
            }))
            setDataLoader(false)
            setLoader(false)




        }))
    }
    useEffect(() => {
        getDataDateRangeWise()
    }, [warehouseDetails, dispatch])
    return (<>
        {!loader ? (< >

<CountTypeWidget item={updatedItem} isDefaultDashboard={true} styleView={styleView} dataLoader={dataLoader}>
    <DateRangeFilter dateFilterOption={item?.dateFilterOption}
        selectedRange={selectedRange}
        dateFilter={dateFilter}
        handleSelection={handleSelection}
        handleDateChange={handleDateChange} />
</CountTypeWidget>
</>) : <Grid item>
<Skeleton variant="text" sx={{ fontSize: '1rem' }} />
    <Skeleton variant="circular" width={40} height={40} />
    <Skeleton variant="rectangular" width={210} height={60} />
    <Skeleton variant="rounded" width={210} height={60} />

</Grid>}

    </>)
}

export default InventoryWeightComponent;