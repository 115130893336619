import React, { useEffect, useMemo, useState } from 'react'
import {
    Divider, Grid, MenuItem, Paper, Select, Typography, Button, Modal, Card, Breadcrumbs,
    Autocomplete, TextField, FormControlLabel, FormLabel, Switch, Box, FormControl, IconButton, CircularProgress
} from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import theme, { buttonClasses, cardClasses, inputClasses, textClasses } from '../../../../static/styles/theme';
import {
    getAllTemplates, getSelectedTemplate, getCommanConfig,
    getCompany, getFloorMapping, saveSinglePackageEntry, multiASNReceiving
} from '../../../../redux/actions'
import { capitalizeSentence, showErrorDynamicField, showToast, validateFieldsSingle, validateFieldsMultiple } from '../../../../services/functions';
import GenericLoader from '../../../Components/Generic/GenericLoader';
import { ShipmentSection } from '../Inward/Sections';
import { useForm } from 'react-hook-form'
import GenericExcelLikeUI from '../../../Components/Generic/GenericExcelLikeUI';
import { getExcelStarterData, createExcelFileAndReturn } from '../../../../utils/services'
import { DatePicker } from "@mui/x-date-pickers";
import { selectStylesOverride } from '../../../../utils/util';
import GenericMultiDateRange,{multiDateToISOConvertor} from '../../../Components/Generic/GenericMultiDateRange';
import ShipmentAdded from '../Inward/Sections/ShipmentAdded';
import TypeAndSearch from "../../../Components/TypeAndSearch";
import { ASN_Component } from "./constant";


const initialRows = 5;
const style = (width) => {
    return {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 3,
    }
};

const MultiAsnReceiving = () => {
    const { state } = useLocation();
    const { asnTemplate, inwardTemplate, sortingMethod, receivingMethod, receivingInventory,asnID } = state ? state : {}
    const { register, handleSubmit, watch, getValues, setValue, control, reset, formState: { errors } } = useForm();
    // console.log("values", asnTemplate, inwardTemplate, sortingMethod, receivingMethod)
    ////("ASN Linked", state);
    const dispatch = useDispatch();
    const template = useSelector(state => state.inward.selectedTemplate);
    const isFromInwardPackingList = !receivingMethod ? true : false;
    const [receivingConfig, setReceivingConfig] = useState({
        "pkgMultiple": {
            "isActive": false,
        },
        "itemMultiple": {
            "isActive": false,
        },
        "receivingTypeVisibility": {
            "isActive": false
        },
        "isWarehouseInput": {
            "isActive": false,
            "isRequired": false
        },
        "isFloorInput": {
            "isActive": false,
            "isRequired": false
        }
    })
    const [templateLoader, setTemplateLoader] = useState(false);
    const [submitInProgress, setSubmitInProgress] = useState(false)
    const [rMethod, setReceivingMethod] = useState(null)
    const [sortMethod, setSortingMethod] = useState(null)
    const [selectedInwardTemplate, setSelectedInwardTemplate] = useState(null);
    const [selectedASNTemplate, setSelectedASNTemplate] = useState(null);
    const [selectedASNID, setSelectedASNID] = useState(null);
    const [allTemplateOptions, setAllTemplateOptions] = useState([]);
    const [showSuccess, setShowSuccess] = useState(null);
    const [items, setItems] = useState([]);
    const [initialRender, setInitialRender] = useState(true);
    const [dateFilter, setDateFilter] = useState({
        dateValue: null,dateRangeValue:[]
    });

    const [wfDetail, setWfDetail] = useState({ warehouseList: [], floorList: [], selectedWarehouse: {}, selectedFloor: {} });
    const receivingMethodOptions = receivingMethod 
    ? receivingConfig?.receivingMethod?.allOptions || []
    : receivingConfig?.receivingMethod?.allOptions?.filter(dl => dl?.value === "bothPackageItem") || [];
    const sortingMethodOptions = receivingConfig?.sortingMethod?.allOptions || [];
    const isSelectedASN_ID=receivingConfig?.isASNInputOption?.isActive == true && receivingConfig?.isASNInputOption?.value == "ASN_ID"


    let excelHeaders = [];
    let mandatoryExcelHeaders = [];
    
    const memoizedExcelHeaders = useMemo(() => {
        if (!template?.otherTemplate?.length) {
            return { excelHeaders: [], mandatoryExcelHeaders: [] };
        }

        const getFilteredAttributes = (templateType, excludeIds = []) =>
            template?.otherTemplate?.find(dl => dl?.templateType === templateType)?.attributes?.filter(fl => !excludeIds.includes(fl?._id)) || [];

        const itemsAttributes = getFilteredAttributes("itemDetailTemplate", ["UOM","SKU_Name"]);
        const packageAttributes = getFilteredAttributes("packageDetailTemplate", ["UOM"]);

        const getMandatoryAttributes = (attributes, excludeIds = []) =>
            attributes?.filter(dl => dl?.required && !excludeIds.includes(dl?._id))?.map(dl => dl?.label);

        const getHeaders = (extraHeaders = [], excludeIds = []) => [
            ...itemsAttributes.map(dl => dl?.label),
            ...extraHeaders,
            ...packageAttributes.filter(fl => !excludeIds.includes(fl?._id)).map(dl => dl?.label),
            "Mapping Area(PUT IT ANY WHERE)"
        ];

        const getMandatoryHeaders = (extraHeaders = [], excludeIds = []) => [
            ...getMandatoryAttributes(itemsAttributes),
            ...extraHeaders,
            ...getMandatoryAttributes(packageAttributes, excludeIds)
        ];

        const isPackagePrefixAndCounter = receivingConfig?.pickListPackageNameOption?.value === "packagePrefixAndCounter";

        if (selectedInwardTemplate?.templateLevel === "SPI") {
            return isPackagePrefixAndCounter
                ? {
                    excelHeaders: getHeaders(["No. of Box", "Box Counter", "Box Prefix"], ["packageName","count"]),
                    mandatoryExcelHeaders: getMandatoryHeaders(["No. of Box", "Box Counter", "Box Prefix"], ["packageName","count"])
                }
                : {
                    excelHeaders: getHeaders(["No. of Box"],["count"]),
                    mandatoryExcelHeaders: getMandatoryHeaders(["No. of Box"],["count"])
                };
        }

        if (selectedInwardTemplate?.templateLevel === "SP") {
            // return isPackagePrefixAndCounter
            //     ? {
            //         excelHeaders: getHeaders(["No. of Box", "Box Counter", "Box Prefix"], ["packageName","packageDescription"]),
            //         mandatoryExcelHeaders: getMandatoryHeaders(["No. of Box", "Box Counter", "Box Prefix"], ["packageName","packageDescription"])
            //     }
            //     : 
            return {
                excelHeaders: getHeaders(["No. of Box"], ["packageDescription"]),
                mandatoryExcelHeaders: getMandatoryHeaders(["No. of Box"], ["packageDescription"])
            };
        }

        return {
            excelHeaders:["No. of Item",...getHeaders([], ["SKU_Name"])],
            mandatoryExcelHeaders: getMandatoryHeaders(["No. of Item"], ["SKU_Name"])
        };
    }, [receivingConfig, selectedInwardTemplate, template]);
      
    
    excelHeaders = memoizedExcelHeaders.excelHeaders;
    mandatoryExcelHeaders = memoizedExcelHeaders.mandatoryExcelHeaders;
    

    const showWarehosueFloorHandler = (configDetail) => {
        if (configDetail?.isWarehouseInput?.isActive == true || configDetail?.isFloorInput?.isActive == true) {
            if (configDetail?.isWarehouseInput?.isActive) {
                dispatch(getCompany({ type: "warehouse", status: "active", projection: { name: 1, _id: 1 } }, (data) => {
                    let list = data?.data?.map((dl => ({ label: dl?.name, value: dl?._id }))) || []
                    if (list?.length) {
                        selectWarehouse(list?.at(0), list || [])
                    }
                    else {
                        setWfDetail({ warehouseList: list || [], floorList: [], selectedWarehouse: {}, selectedFloor: {} });
                    }
                }))
            }
            else {
                dispatch(getCompany({ type: "floor", status: "active", includeCount: false, projection: { name: 1, _id: 1, warehouseId: 1 } }, (data) => {
                    let list = data?.data?.map((dl => ({ label: dl?.name, value: dl?._id, warehouseId: dl?.warehouseId }))) || []
                    if (list?.length) {
                        selectFloor(list?.at(0) || {}, list || [])
                    }
                    else {
                        setWfDetail({ warehouseList: [], floorList: list || [], selectedWarehouse: {}, selectedFloor: {} });
                    }
                }))
            }
            if (configDetail?.isWarehouseInput?.isRequired == true || configDetail?.isFloorInput?.isRequired == true) {
                return;
            }
            else {
                fetchMappingArea()
            }

        }
        else {
            fetchMappingArea()
        }
    }
    const selectWarehouse = (selectedWarehouse = {}, dataList = []) => {
        let tempWfDetail = { warehouseList: dataList, selectedWarehouse, floorList: [], selectedFloor: {} }
        setWfDetail({ ...tempWfDetail })
        if (receivingConfig?.isFloorInput?.isActive == true && selectedWarehouse?.value) {
            dispatch(getCompany({ type: "floor", status: "active", warehouseId: selectedWarehouse?.value || "", projection: { name: 1, _id: 1, warehouseId: 1 } }, ({ data = [] }) => {
                let list = data?.map((dl => ({ label: dl?.name, value: dl?._id, warehouseId: dl?.warehouseId }))) || []
                if (list?.length) {
                    selectFloor(list?.at(0) || {}, list || [], selectedWarehouse, dataList)
                }
                else {
                    setWfDetail({ warehouseList: dataList, selectedWarehouse, floorList: list || [], selectedFloor: {} })
                    fetchMappingArea(selectedWarehouse?.value || "", "")
                }
            }))
        }
        else {
            fetchMappingArea(selectedWarehouse?.value || "", "")
        }
    }

    const selectFloor = (selectedFloor = {}, dataList = [], selectedWarehouse = {}, warehouseList = []) => {
        // console.log("tempWfDetailF",{selectedFloor, dataList,selectedWarehouse,warehouseList})
        setWfDetail({ selectedWarehouse, warehouseList, floorList: dataList, selectedFloor: selectedFloor || {} });
        fetchMappingArea(selectedWarehouse?.value || "", selectedFloor?.value || "")
    }

    const fetchMappingArea = (warehouseId = "", floorId = "") => {
        dispatch(getFloorMapping({ warehouseId, floorId }))
        // setItems([])
        // resetExcelData();
    }

    const validateFieldsShipmentField = () => {

        let rowErrors = {};
        let row = getValues() || {}
       
        let tempErrors = validateFieldsSingle(template?.attributes?.filter(dl=>dl?._id !="packageQuantity") || [], row || {}) || {}
        rowErrors = { ...rowErrors, ...tempErrors }
        return rowErrors;
    }

    const resetExcelData = () => {
        let rows = getExcelStarterData(excelHeaders?.length || 0, initialRows)
        setItems(rows)
    }


    const submitExcelData = () => {
        let finalExcelFile = createExcelFileAndReturn(excelHeaders, items || [])
        return finalExcelFile;
    }
    
    const handleOpenSuccessModal = (response) => {
        setShowSuccess(response);
    }
    const handleCloseSuccessModal = () => {
        setShowSuccess(null);
    }

    const handleAddMore = () => {
        resetExcelData();
        handleCloseSuccessModal();
    }
    const handleClearAll = () => {
        // let resetD = { ...getValues1() };
        // let rKeys = Object.keys(resetD);
        // for (let i = 0; i < rKeys.length; i++) {
        //     if (rKeys[i] == "mappingArea") {
        //         resetD[rKeys[i]] = "PUT IT ANY WHERE"
        //     }
        //     else {
        //         resetD[rKeys[i]] = resetD[rKeys[i]] && Array.isArray(resetD[rKeys[i]]) ? [] : ""
        //     }
        // }
        // reset1({
        //     ...resetD
        // });

        // setValue1("isSelectFromList", {})
        // setValue1("customShipment", "")
        // setItems([]);
        // showWarehosueFloorHandler(receivingConfig || {})
        resetExcelData();
        setTemplateLoader(false);
        setSubmitInProgress(false)
    }

    const handleSave = (data) => {

        const shipmentValidation = validateFieldsShipmentField();

        if (Object.keys(shipmentValidation).length > 0) {
            showToast(shipmentValidation[0]?.message, true);
            return
        }

        const formData = new FormData();
        formData.append('file', submitExcelData());
        formData.append('shipmentData', JSON.stringify({ ...data }));
        formData.append('template_id', template._id);
        if(isSelectedASN_ID){
            formData.append('ASN_ID', selectedASNID?.value);
        }
        else{
            formData.append('asnTemplate_id', selectedASNTemplate?._id);
        }
        formData.append('receivingMethod', rMethod?.value);
        formData.append('sortingMethod', sortMethod?.value);
        // formData.append('drafted', data?.drafted || false);
        if (wfDetail?.selectedWarehouse?.value) {
            formData.append('warehouseId', wfDetail?.selectedWarehouse?.value?.toString());
        }
        if (wfDetail?.selectedFloor?.value) {
            formData.append('floorId', wfDetail?.selectedFloor?.value?.toString());
            if (!wfDetail?.selectedWarehouse?.value) {
                formData.append('warehouseId', wfDetail?.selectedFloor?.warehouseId);
            }
        }
        if (sortMethod?.value == "date") {
            formData.append('sortingValue', (dateFilter?.dateValue).toISOString());
        }
        if (sortMethod?.value == "dateRange") {
            formData.append('sortingValue', multiDateToISOConvertor(dateFilter?.dateRangeValue));
        }

        // console.log("payload", formData)
        setSubmitInProgress(true)
        dispatch(
            multiASNReceiving(formData, (response) => {
                showToast(response.message, !response.success);
                setSubmitInProgress(false)
                // setTimeout(() => {
                //     setSubmitInProgress(false)
                // }, 500)
                if (response?.success) {
                    handleOpenSuccessModal(response);
                }
            })
        )


    }
    const fetchTemplate = (templateLevel = "",isTempSelectedASN_ID, callback) => {
        if (templateLevel) {
            dispatch(
                getAllTemplates(
                    {
                        type: "wms",
                        templateType: ["inwardDataEntry", "ASNTemplate"],
                        status: "published",
                        fetchAttributes: false,
                        templateLevel:(isTempSelectedASN_ID?templateLevel: [...templateLevel, "SI"]) || "SI",
                        projection: { _id: 1, name: 1, templateType: 1, templateLevel: 1 }
                    },
                    (data) => {
                        let tempData = data?.filter((item) => item?.templateType === "ASNTemplate" || (item?.templateType === "inwardDataEntry" && templateLevel?.includes(item?.templateLevel)))
                        setAllTemplateOptions(tempData || [])
                        if (callback) callback(tempData)
                    }
                )
            );
        }
        else {
            setAllTemplateOptions([])
            if (callback) callback([])
        }
    }

    const setTemplateValues = (levels, fetchCount = false,isTempSelectedASN_ID) => {
        fetchTemplate(levels || "",isTempSelectedASN_ID, (data) => {
            if (data && data.length) {
                setAllTemplateOptions(data);

                if (fetchCount) {
                    if (!isFromInwardPackingList && inwardTemplate?.name) {
                        setSelectedInwardTemplate(inwardTemplate);
                    } else {
                        const defaultTemplate = data.find(
                            (dl) => dl.templateType === "inwardDataEntry" && dl.templateLevel === "SPI"
                        );
                        setSelectedInwardTemplate(defaultTemplate || {});
                    }
                } else {
                    const matchedTemplate = data.find(
                        (dl) => dl.templateType === "inwardDataEntry" && levels?.includes(dl.templateLevel)
                    );
                    setSelectedInwardTemplate(matchedTemplate || {});
                }
                if (isTempSelectedASN_ID) {
                    setSelectedASNID(asnID)
                }
                else {
                    setSelectedASNTemplate(asnTemplate || data.find((dl) => dl.templateType === "ASNTemplate") || {});
                }
            }
        });
    };


    useEffect(() => {
        dispatch(
            getCommanConfig({ configType: "asnReceiving" }, (data) => {
                const receivingOptionsData = data?.configDetail?.receivingMethod?.allOptions;
                const isTempSelectedASN_ID=data?.configDetail?.isASNInputOption?.isActive == true && data?.configDetail?.isASNInputOption?.value == "ASN_ID"
                setReceivingConfig(data?.configDetail || {});
    
                let selectedReceivingMethod;
                if (isFromInwardPackingList) {
                    selectedReceivingMethod = receivingOptionsData?.find((dl) => dl.value === "bothPackageItem");
                    setSortingMethod(data?.configDetail?.sortingMethod?.allOptions?.at(0));
                } else {
                    selectedReceivingMethod = receivingOptionsData?.find((dl) => dl.value === receivingMethod) || {};
                    setSortingMethod(data?.configDetail?.sortingMethod?.allOptions?.find((dl) => dl.value === sortingMethod));
                }
                setReceivingMethod(selectedReceivingMethod);
                setTemplateValues(selectedReceivingMethod?.templateType, initialRender,isTempSelectedASN_ID);
            })
        );
    
        if (initialRender) setInitialRender(false);
    }, []);
    useEffect(() => {
        handleClearAll();
        if (selectedInwardTemplate != null) {
            setTemplateLoader(true);
            dispatch(
                getSelectedTemplate({ template_id: selectedInwardTemplate._id, fetchForInward: true, }, () => {
                    setTemplateLoader(false)
                })
            )
            showWarehosueFloorHandler(receivingConfig || {})
        }
    }, [selectedInwardTemplate])
    return (
        <>
            <Grid>
                <Typography sx={{ ...textClasses.cardTitle }}>Multi ASN Receiving</Typography>
                <Breadcrumbs sx={{ mb: 1 }} data-testid="multiASNReceiving">
                    <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }}>
                        <Link style={{ color: theme.themeOrange, textDecoration: "none" }} to={"/dashboard/inventory/asn"}>
                            Manage ASN
                        </Link>
                    </Typography>

                    <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }} >
                        Multi ASN Receiving
                    </Typography>
                </Breadcrumbs>
                <Paper sx={{ ...cardClasses.basic, mt: 1 }}>
                    <Grid container>
                        <Grid container item sm={3} xs={12} sx={{ p: 2 }} alignContent="center" justifyContent={"left"}>
                            <Autocomplete
                                value={rMethod}
                                onChange={(e, option) => {
                                    setReceivingMethod(option);
                                    setSelectedInwardTemplate(null);
                                    handleClearAll()
                                    // setSelectedInwardTemplate(null)
                                    setTemplateValues(option?.templateType,false,isSelectedASN_ID)
                                    //   setAllTemplateOptions([])
                                }}
                                getOptionLabel={(option) => capitalizeSentence(option.label || "")}
                                size='small'
                                options={receivingMethodOptions}
                                sx={{
                                    ...inputClasses.shadowField,
                                    minWidth: "220px",
                                    m: 0,
                                    ml: 2,
                                    ".MuiInputBase-input": {
                                        fontSize: "14px",
                                        color: "#455A64"
                                    }
                                }}
                                renderInput={(params) => <TextField sx={{ color: "#455A64", ...textClasses.normalText }} {...params} label="Receiving Method" />}
                            />
                        </Grid>


                        <Grid container item sm={9} xs={12} sx={{ p: 2 }} alignContent="center" justifyContent="end">
                            <Autocomplete
                                value={selectedInwardTemplate}
                                onChange={(e, option) => {
                                    setSelectedInwardTemplate(option);
                                    handleClearAll()
                                }}
                                getOptionLabel={(option) => capitalizeSentence(option.name || "")}
                                size='small'
                                options={allTemplateOptions?.filter(dl => dl?.templateType == "inwardDataEntry" && rMethod?.templateType?.includes(dl?.templateLevel))}
                                sx={{
                                    ...inputClasses.shadowField,
                                    minWidth: "220px",
                                    m: 0,
                                    ml: 2,
                                    ".MuiInputBase-input": {
                                        fontSize: "14px",
                                        color: "#455A64"
                                    }
                                }}
                                renderInput={(params) => <TextField sx={{ color: "#455A64", ...textClasses.normalText }} {...params} label="Inward Template" />}
                            />
                            {receivingConfig?.isWarehouseInput?.isActive == true && selectedInwardTemplate &&
                                <Autocomplete
                                    id="warehouse"
                                    value={wfDetail?.selectedWarehouse?.value && wfDetail?.selectedWarehouse || null}
                                    onChange={(e, option) => {
                                        selectWarehouse(option, wfDetail?.warehouseList || [])
                                        // setExcelDataEntry({ action: false, file: "" })
                                        // handleClearAll()
                                    }}
                                    size='small'
                                    options={wfDetail?.warehouseList || []}
                                    getOptionLabel={(option) => option.label || ""}  // Ensure it displays the label properly
                                    sx={{
                                        ...inputClasses.shadowField,
                                        minWidth: "220px",
                                        m: 0,
                                        ml: 2,
                                        ".MuiInputBase-input": {
                                            fontSize: "14px",
                                            color: "#455A64"
                                        }
                                    }}
                                    renderInput={(params) => <TextField
                                        sx={{ color: "#455A64", ...textClasses.normalText }}
                                        {...params}
                                        label={`Warehouse ${receivingConfig?.isWarehouseInput?.isRequired == true ? "*" : ""}`}
                                    />
                                    }
                                />
                            }
                            {receivingConfig?.isFloorInput?.isActive == true && selectedInwardTemplate &&
                                <Autocomplete
                                    id="floor"
                                    value={wfDetail?.selectedFloor?.value && wfDetail?.selectedFloor || null}
                                    onChange={(e, option) => {
                                        selectFloor(option, wfDetail?.floorList || [], wfDetail?.selectedWarehouse, wfDetail?.warehouseList || [])
                                        // setExcelDataEntry({ action: false, file: "" })
                                        // handleClearAll()
                                    }}
                                    size='small'
                                    getOptionLabel={(option) => option.label || ""}  // Ensure it displays the label properly
                                    options={wfDetail?.floorList || []}
                                    sx={{
                                        ...inputClasses.shadowField,
                                        minWidth: "220px",
                                        m: 0,
                                        ml: 2,
                                        ".MuiInputBase-input": {
                                            fontSize: "14px",
                                            color: "#455A64"
                                        }
                                    }}
                                    renderInput={(params) => <TextField
                                        sx={{ color: "#455A64", ...textClasses.normalText }}
                                        {...params}
                                        label={`Floor ${receivingConfig?.isFloorInput?.isRequired == true ? "*" : ""}`}
                                    />
                                    }
                                />
                            }
                        </Grid>

                    </Grid>
                    <Divider sx={{ m: "0 5px 0 5px" }} />

                    {
                        templateLoader &&
                        <Grid container justifyContent={"center"} sx={{ m: 2 }}>
                            <GenericLoader />
                        </Grid>
                    }
                    {
                        (!templateLoader && selectedInwardTemplate != null && template) &&
                        <>

                            <Paper elevation={1} sx={{ m: 1 }}>
                                <Grid item xs={12} sx={{ backgroundColor: theme.themeGray, p: "12px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}>
                                    <Typography sx={{ ...textClasses.boldText }}>Task Details</Typography>
                                </Grid>
                                <Grid container item xs={12} sx={{ mt: 3, mb: 1,display:'flex',  alignItems:'center' }}>
                                    <Grid item xs={3} sx={{ mb: 2 }}>
                                        {isSelectedASN_ID
                                            ? <Box sx={{ minWidth: "250px",m: 0,ml: 2}}>
                                                <TypeAndSearch
                                                    data-testid="asnFilter"
                                                    component={ASN_Component || {}}
                                                    handleChange={(e, option) => {
                                                        setSelectedASNID(option)
                                                    }}
                                                    label={selectedASNID?.label || ""}
                                                    value={selectedASNID?.value || ""}
                                                    sx={{ width: "100%", }}
                                                />
                                            </Box>
                                        :<Autocomplete
                                            value={selectedASNTemplate}
                                            onChange={(e, option) => {
                                                setSelectedASNTemplate(option);
                                                //   handleClearAll()
                                            }}
                                            getOptionLabel={(option) => capitalizeSentence(option.name || "")}
                                            size='small'
                                            options={allTemplateOptions?.filter(dl => dl?.templateType == "ASNTemplate")}
                                            sx={{
                                                ...inputClasses.shadowField,
                                                minWidth: "220px",
                                                m: 0,
                                                ml: 2,
                                                ".MuiInputBase-input": {
                                                    fontSize: "14px",
                                                    color: "#455A64"
                                                }
                                            }}
                                            renderInput={(params) => <TextField sx={{ color: "#455A64", ...textClasses.normalText }} {...params} label="ASN Template" />}
                                        />}
                                    </Grid>
                                    {!isSelectedASN_ID && <Grid item xs={3} sx={{ mb: 2 }}>
                                        <Autocomplete
                                            value={sortMethod}
                                            onChange={(e, option) => {
                                                setSortingMethod(option);
                                                setDateFilter({ dateValue: null, dateRangeValue: [] })
                                                //   setSelectedInwardTemplate(null);
                                                //   handleClearAll()
                                                //   setAllTemplateOptions([])
                                            }}
                                            getOptionLabel={(option) => capitalizeSentence(option.label || "")}
                                            size='small'
                                            options={sortingMethodOptions}
                                            sx={{
                                                ...inputClasses.shadowField,
                                                minWidth: "220px",
                                                m: 0,
                                                ml: 2,
                                                ".MuiInputBase-input": {
                                                    fontSize: "14px",
                                                    color: "#455A64"
                                                }
                                            }}
                                            renderInput={(params) => <TextField sx={{ color: "#455A64", ...textClasses.normalText }} {...params} label="Sorting Method" />}
                                        />
                                    </Grid>
                                    }
                                    {(sortMethod?.value === "date" ||
                                            sortMethod?.value === "dateRange") && (
                                                <>
                                                   
                                                    {sortMethod?.value === "date" && (
                                                        <Grid item xs={12} sm={6} md={3} sx={{ml:2,mb: 2}}>
                                                            <DatePicker
                                                                fullWidth
                                                                format="DD/MM/YYYY"
                                                                data-testid="receivedAt"
                                                                disableFuture
                                                                value={dateFilter?.dateValue}
                                                                onChange={(d) =>{ 
                                                                    setDateFilter({dateRangeValue:[],dateValue:d})}}
                                                                label="Date"
                                                                sx={{ ...inputClasses.textField, width: "100%" }}
                                                                slotProps={{ textField: { size: "small" } }}
                                                            />
                                                        </Grid>
                                                    )}
                                                    {sortMethod?.value === "dateRange" && (
                                                        <>
                                                               <Grid item xs={3} sx={{ml:2, mb: 2 }}>
                                                                <FormControl sx={{ ...selectStylesOverride,  }} fullWidth>
                                                                  <GenericMultiDateRange
                                                                    label={"Date"}
                                                                    getOnChange={(d) => setDateFilter({dateValue:null,dateRangeValue:d})}
                                                                    value={dateFilter?.dateRangeValue || []}
                                                                    inputStyle={inputClasses.textField}
                                                                  />
                                                                </FormControl>
                                                              </Grid>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                </Grid>
                            </Paper>

                            <Grid container sx={{ p: 1 }}>
                                {template?.attributes?.some((dl) => dl?._id !== "packageQuantity") && <Grid item sm={12} xs={12}>
                                    <ShipmentSection
                                        template={{
                                            ...template,
                                            attributes: template?.attributes?.filter((dl) => dl?._id !== "packageQuantity"),
                                        }}
                                        spiType={"SPI"}
                                        // asn={asn}
                                        // asnTemplateHeader={asnTemplateHeader}
                                        receivingMethod={rMethod}
                                        formHooks={{ register, control, setValue, errors, getValues, reset }}
                                        isPalletEntry={true}

                                    />
                                </Grid>}

                                <Paper elevation={1} sx={{mt:2, width: "100%"}}>
                                    <Grid item sx={{ backgroundColor: theme.themeGray, p: 1, borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}>
                                        <Typography sx={{ ...textClasses.boldText }}>Package List</Typography>
                                    </Grid>
                                    
                                    <Grid item xs={12} 
                                        sx={{ p: 1 }}
                                    >
                                        <Box border={1} sx={{ padding: "10px", borderColor: 'grey.500', borderRadius: "4px", maxWidth: "100%" }}>
                                            <GenericExcelLikeUI
                                                headers={excelHeaders}
                                                mandatoryHeaders={mandatoryExcelHeaders}
                                                initialRows={initialRows}
                                                data={items || []}
                                                setData={setItems}
                                                // customStyle={{display:'flex',justifyContent:'center',flexDirection:'row'}}
                                            />
                                        </Box>
                                    </Grid>
                                </Paper>

                                {/* <Grid item sm={12} xs={12}>
                                    <PackageSection
                                        template={template}
                                        watch={watch1}
                                        formHooks={{
                                            register: register1, watch: watch1, getValues: getValues1,
                                            setValue: setValue1, control: control1, reset: reset1, errors: errors1
                                        }}
                                        onKeyPressEnterTrigger={onKeyPressEnterTrigger}

                                    />
                                </Grid> */}
                                {/* <Grid item sm={12} xs={12}>

                                    <ItemSection
                                        template={template || {}}
                                        errors={errors2}
                                        items={items}
                                        setItems={setItems}
                                        setValue1={setValue1}
                                        totalQuantity={totalQuantity}
                                        remainingItems={remainingItems}
                                        countTillNow={countTillNow}
                                        bundleType={bundleType}
                                        onKeyPressEnterTrigger={onKeyPressEnterTrigger}
                                    />
                                </Grid> */}

                                <Grid container item direction={"row"} sm={12} xs={12} sx={{ m: 2,  }}>
                                    <Button variant='outlined' size='small' sx={{ ...buttonClasses.lynkitOrangeEmpty, minWidth: "150px", }}
                                        onClick={handleClearAll}
                                    >
                                        Clear All
                                    </Button>
                                    <Button variant='contained' size='small'
                                        sx={{
                                            ...buttonClasses.lynkitOrangeFill, minWidth: "150px", ml: 2
                                        }}
                                        // onClick={() => saveEntry()}
                                        onClick={handleSubmit(handleSave, showErrorDynamicField)}
                                        disabled={
                                            // !isDataSubmittable ||
                                            submitInProgress}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                                <Modal
                                    open={showSuccess != null}
                                >
                                    <Card sx={style("50%")}>
                                        <ShipmentAdded
                                            spiType={"SPI"}
                                            templateId={showSuccess && showSuccess.templateId}
                                            shipmentId={showSuccess && showSuccess.shipmentId}
                                            packageCount={showSuccess && showSuccess.totalPackage}
                                            itemCount={showSuccess && showSuccess.totalItem}
                                            handleAddMore={handleAddMore}
                                        />
                                    </Card>
                                </Modal>
                            </Grid>

                        </>
                    }
                </Paper>
            </Grid>
        </>
    )
}

export default MultiAsnReceiving;
