import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Grid,  Pagination,
	Typography,  Box, Stack,
	Card, CardHeader, CardContent, CardActions,
	IconButton, Chip, Collapse, styled, 
} from "@mui/material";
import {
	ExpandMore as ExpandMoreIcon, Download,
	Close, 
} from "@mui/icons-material";
import FileOpenIcon from '@mui/icons-material/FileOpen';
import moment from "moment";
import {
	getJobDetailV2,  getTemplateHeaders, getJobLocationDetail, 
	getSuggestionLocation,
} from "../../../../redux/actions/Job";
import theme from "../../../../static/styles/theme";
import { 
    dateTimeFormater, capitalizeSentence, timeConversionFunction, showToast,
      truncateNumber 
    } from "../../../../services/functions";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import ViewWorkOrderList from "./viewWorkOrderList";

const statusColor = {
	ongoing: "#FF7200",
	pending: "#FCCB3E",
	failed: "#E9001A",
	completed: "green",
};


export default function JobDetail ({ isViewDetail, setIsViewDetail, allJobTypeName, user, jobProcessConfig, AllUserData, viewPageRender = false, checkedRow, setCheckedRow }) {
	const dispatch = useDispatch();
	const { jobDetail: { data = [], count = 0 } } = useSelector((state) => state.job);
	const template_headers = useSelector((state) => state.job.template_headers);
	const [downloadPdf, setDownloadPdf] = useState(false);
	const [isExpanded, setIsExpanded] = useState({
		jobDetail: true,
		dispatchDetail: false,
		locationDetail: false,
		warehouseDetail: false,
		salesOrderDetail: false,
		kitDetails: false,
		remarks: false,
		userDetail: false
	});
	// console.log(user)
	const [isActiveCard, setIsActiveCard] = useState(1);
	const [locationPage, setLocationPage] = useState({ page: 1, limit: 10 });
	const [loader, setLoader] = useState("");
	const [jobLocation, setJobLocation] = useState({});
	const formDetailHeader = {
		"stockTransfer": 'Stock Transfer Details',
		"sendToUser": "Dispatch Details",
		"picking": "Dispatch Details",
	}

	const {
		_id, jobName, status, jobType, assignedTo, assignedBy, completedBy, result, remark, jobAllocationDate, shipmentDetail, salesOrderDetail,
		jobTemplateDetail = {}, jobTypeId, jobEndDate, totalCount = 0, scannedCount = 0, jobAcceptedTime = "", isOpen = false, jobExtraDetail,
		kitDetails, jobScanningType,actionName=""
	} = data?.length && data[0] || {};
	// console.log("dataa")
	// console.log(data)
	// console.log("Job Extra Detail", jobExtraDetail)
	// console.log("jobType",jobType)

	let extraUserDetail = (() => {
		if (jobExtraDetail && jobExtraDetail.category == "internalUser" && AllUserData && AllUserData.data) {
			let filtered = AllUserData.data.filter(u => {
				return u._id == jobExtraDetail.userId
			})
			if (filtered.length > 0) {
				return filtered[0];
			}
			return null;
		}
		else if (jobExtraDetail && jobExtraDetail.category == "externalUser") {
			return jobExtraDetail;
		}
		return null;
	})();

	// console.log("Enter Detail", extraUserDetail);

	const { templateData = false, templateId = false } = jobTemplateDetail


	const assigned_to = assignedTo?._id
		? capitalizeSentence(user[assignedTo._id]?.name || "") + ", " + capitalizeSentence(user[assignedTo._id]?.designation || "")
		: "-"
	const assigned_by = assignedBy?._id
		? capitalizeSentence(user[assignedBy._id]?.name || "") + ", " + capitalizeSentence(user[assignedBy._id]?.designation || "")
		: "-"
	const completed_by = completedBy?._id
		? capitalizeSentence(user[completedBy._id]?.name || "") + ", " + capitalizeSentence(user[completedBy._id]?.designation || "")
		: "-"
	const created_at = jobAllocationDate && dateTimeFormater(jobAllocationDate) || "-"

	const fetchLocation = ({ jobComponentId, page = 1, limit = 10 }) => {
		setLoader("location")
		dispatch(getJobLocationDetail({ jobComponentId, page, limit }, (output = {}) => {
			// ////("output",output);
			setLoader(false)
			setLocationPage({ page, limit })
			setJobLocation(output)
		}));
	}
	const fetchJobDetail = ({ page = 1, limit = 1 }) => {
		setLoader("jobDetail")
		dispatch(getJobDetailV2({ jobId: isViewDetail, call_from: "web", page, limit }, () => {
			setLoader(false)
			setIsActiveCard(page)
		}));
	}

	const renderRow = (label, value) => {
		return <Box
			sx={{
				display: "flex",
				gap: "0.25em",
				fontSize: "13px",
				justifyContent:
					"space-between",
			}}
		>
			<Typography
				variant="span"
				sx={{
					color: "gray",
				}}
			>
				{label} :
			</Typography>
			<Typography
				variant="span"
				sx={{
					flexBasis:
						"60%",
				}}
			>
				{value}
			</Typography>
		</Box>
	}
	const handleGenerateExcel = () => {
		setLoader("locationSuggestion")
		let payload = {
			jobId: isViewDetail
		}
		dispatch(
			getSuggestionLocation(payload, ({ success = "", message }) => {
				showToast(message, !success);
				setLoader("")
			})
		);
	}
	useEffect(() => {
		if (templateId) {
			dispatch(getTemplateHeaders({ template_id: templateId }));
		}
		if (_id) {
			fetchLocation({ ...locationPage, jobComponentId: _id })
		}
	}, [templateId, _id]);

	useEffect(() => {
		setLoader("all")
		setIsActiveCard(1)
		setLocationPage({ page: 1, limit: 10 })
	}, [isViewDetail]);

	// const getPickListHandler = () => {
	// 	setDownloadPdf(true)
	// 	dispatch(
	// 		generateJobPickList(
	// 			{ jobComponentId: _id },
	// 			({ success, message, data }) => {
	// 				showToast(message, !success);
	// 				if (success && data) {
	// 					let blob = b64toBlob(data, "application/pdf");
	// 					let url = URL.createObjectURL(blob);
	// 					window.open(url, "_blank");
	// 					setDownloadPdf(false)
	// 				}
	// 			}
	// 		)
	// 	);
	// };

	return (
		<>
			<Grid item
				sx={viewPageRender
					? { width: isViewDetail ? "auto" : "0" }
					: { width: isViewDetail ? "0" : "auto" }}
				data-testid="job-detail-dashboard"
				md={viewPageRender ? 12 : 4} sm={12} xs={12} order={{ xs: 1, sm: 1, md: 2 }}
			>
				{/* <Grid item xs sx={{ width: isViewDetail ? "0" : "auto" }} data-testid="job-detail-dashboard"> */}
				<Grid container sx={{ ml: viewPageRender ? 0 : 2 }}>
					{
						loader == "all" ?
							<Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
								<Grid item><GenericLoader /></Grid>
							</Grid>
							:
							<Card sx={{ width: "100%" }}>
								<CardHeader
									// avatar={
									// 	true
									// 	// (["sendToUser", "pickingAndPackaging", "picking", "gateIn", "kitItemPicking", "returnToSeller","kitItemBundeling"].includes(jobType)
									// 	// 	// &&status !== "completed"
									// 	// )
									// 	&&
									// 	<Download
									// 		sx={{ "&:hover": { cursor: downloadPdf === true ? "not-allowed" : "pointer" } }}
									// 		// onClick={() => downloadPdf === false && getPickListHandler()}
									// 		onClick={() => setCheckedRow({ ...checkedRow, visible: true, type: "viewWorkOrderList" })}
									// 	/>
										
									// }
									avatar={
										<Box sx={{ display: "flex", gap: 1 }}>
											<Download
												sx={{ "&:hover": { cursor: downloadPdf === true ? "not-allowed" : "pointer" } }}
												onClick={() => setCheckedRow({ ...checkedRow, visible: true, type: "viewWorkOrderList" })}
											/>
											{
												jobType == "gateIn" &&
												<FileOpenIcon
													sx={{ "&:hover": { cursor: loader == "locationSuggestion" ? "not-allowed" : "pointer" } }}
													onClick={handleGenerateExcel}
													disabled={loader == "locationSuggestion"}
												/>
											}

										</Box>
									}
									title={
										<Typography
											data-testid="job-detail-header"
											variant="span"
											sx={{
												display: "flex-inline",
												justifyContent: "center",
												alignItems: "center",
												// gap: "0.5em",
											}}
										>
											Job ID : {isViewDetail || "-"}
											{!viewPageRender && <Chip
												label={capitalize(status || "pending")}
												sx={{
													backgroundColor: "#fff",
													marginLeft: "1em",
													color: statusColor[status || "pending"],
													height: "23px",
													fontWeight: "500",
												}}
											/>}
										</Typography>
									}
									action={
										<>
											{viewPageRender ? <Chip
												label={capitalize(status || "pending")}
												sx={{
													backgroundColor: "#fff",
													marginRight: ".7em",
													color: statusColor[status || "pending"],
													height: "23px",
													fontWeight: "500",
												}}
											/>
												:
												<IconButton
													data-testid="job-detail-action"
													sx={{
														backgroundColor: theme.themeOrange,
														color: "#fff",
													}}
													onClick={() => setIsViewDetail("")}
												>
													<Close />
												</IconButton>
											}
										</>
									}
									sx={{
										backgroundColor: theme.themeOrange,
										color: "#fff",
										padding: "7px 6px"
									}}
								/>
								<CardContent
									sx={{
										display: "flex",
										flexDirection: "column",
										gap: "0.75em",
									}}
									data-testid="job-detail-content"
								>
									{count > 1
										? <Stack spacing={2} sx={{
											display: "flex-inline",
											justifyContent: "center",
											alignItems: "center",
										}}>
											<Pagination
												count={count}
												variant="outlined"
												onChange={(event, value) => fetchJobDetail({ page: value, limit: 1 })}
												shape="rounded"
												page={isActiveCard}

											/>
										</Stack>
										: ""
									}
									{/* Job Detail */}
									<Card data-testid="job-detail-card">
										<CardHeader
											title={
												<Typography
													variant="span"
													sx={{
														color: theme.themeOrange,
													}}
												>
													Job Details
												</Typography>
											}
											action={
												<ExpandMore
													expand={isExpanded.jobDetail}
													onClick={() => {
														setIsExpanded((o) => ({
															...o,
															jobDetail: !o.jobDetail,
														}));
													}}
												>
													<ExpandMoreIcon />
												</ExpandMore>
											}
										/>
										<Collapse
											in={isExpanded.jobDetail}
											timeout="auto"
											unmountOnExit
										>
											<CardContent
												sx={{
													display: "flex",
													flexDirection: "column",
													gap: ".5em",
												}}
											>
												{
													loader == "jobDetail"
														? <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
															<Grid item><GenericLoader /></Grid>
														</Grid>
														:
														<>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	Job Name :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{capitalizeSentence(allJobTypeName.find(dl => dl._id == jobTypeId)?.label || "-")}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	Job Type :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{actionName || "-"}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	Scan Type :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{jobScanningType == "anyInventory" ? "Any Inventory" : "Restricted"}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	Total Count :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{truncateNumber(totalCount || "0",3,true)}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	Scanned Count :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{truncateNumber(scannedCount || "0",3,true)}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	Result :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{result || "-"}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	{" "}
																	Assigned To :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{assigned_to}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}

																>
																	{" "}
																	Job Allocate Date :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{jobAllocationDate && dateTimeFormater(jobAllocationDate) || "-"}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	{" "}
																	Job Accepted Date :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{jobAcceptedTime && dateTimeFormater(jobAcceptedTime) || "-"}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	Assigned By :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{assigned_by}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	Completed By :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{completed_by}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	Job End Date :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{jobEndDate && dateTimeFormater(jobEndDate) || "-"}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	Total Time Spent :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{status != "pending" ?
																		timeConversionFunction(`${moment(isOpen ? new Date() : jobEndDate).diff(moment(jobAcceptedTime || jobAllocationDate), 'seconds')}`)
																		: ""
																	}
																</Typography>
															</Box>
														</>
												}
											</CardContent>
										</Collapse>
									</Card>

									{/* Work Order List PDF */}
									{checkedRow?.visible == true && checkedRow?.type == "viewWorkOrderList" &&
										<ViewWorkOrderList
											checkedRow={checkedRow}
											setCheckedRow={setCheckedRow}
											_id={_id}
										/>
									}


									{/* Form Detail */}
									{templateId && Object.keys(templateData).length ? (
										<Card data-testid="job-templateData-card">
											<CardHeader
												title={
													<Typography
														variant="span"
														sx={{
															color: theme.themeOrange,
														}}
													>
														{formDetailHeader[jobType] || "Dispatch Details"}
													</Typography>
												}
												action={
													<ExpandMore
														expand={
															isExpanded.dispatchDetail
														}
														onClick={() => {
															setIsExpanded((o) => ({
																...o,
																dispatchDetail:
																	!o.dispatchDetail,
															}));
														}}
													>
														<ExpandMoreIcon />
													</ExpandMore>
												}
											/>
											<Collapse
												in={isExpanded.dispatchDetail}
												timeout="auto"
												unmountOnExit
											>
												<CardContent
													sx={{
														display: "flex",
														flexDirection: "column",
														gap: ".5em",
													}}
												>
													<RenderFormData
														templateHeader={template_headers}
														templateData={templateData}
													/>
												</CardContent>
											</Collapse>
										</Card>
									) : null}

									{/* Location Detail */}
									{jobLocation?.data?.length ? <Card

										data-testid="job-location-card"
									>
										<CardHeader
											title={
												<Typography
													variant="span"
													sx={{
														color: theme.themeOrange,
														display: "flex-inline",
														justifyContent: "center",
														alignItems: "center",
														// gap: "0.5em",
													}}
												>
													Location Details

												</Typography>
											}
											action={
												<ExpandMore
													expand={isExpanded.locationDetail}
													onClick={() => {
														setIsExpanded((o) => ({
															...o,
															locationDetail:
																!o.locationDetail,
														}));
													}}
												>
													<ExpandMoreIcon />
												</ExpandMore>
											}
										/>
										<Collapse
											in={isExpanded.locationDetail}
											timeout="auto"
											unmountOnExit
										>
											{jobLocation?.count > 3 ?
												<Stack spacing={2} sx={{
													display: "flex-inline",
													justifyContent: "center",
													alignItems: "center",
												}}>
													<Pagination
														count={Math.ceil(jobLocation?.count / locationPage?.limit)}
														// count={jobLocation?.count || 1}
														// count={10}
														variant="outlined"
														onChange={(event, value) => fetchLocation({ jobComponentId: _id, page: value, limit: locationPage?.limit })}
														shape="rounded"
														// rowsPerPage={locationPage?.limit || 3}
														page={locationPage?.page || 1}
													/>
												</Stack>
												: null
											}
											<Box sx={viewPageRender ? { overflowY: "auto" } : { maxHeight: "500px", overflowY: "auto" }}>
												{
													loader == "location"
														? <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
															<Grid item><GenericLoader /></Grid>
														</Grid>
														:
														<RenderLocationData
															location={jobLocation.data || []}
															locationPage={locationPage}
															cellOnly={jobType == "inventoryCheck"}
															jobType={jobType}
															jobProcessConfig={jobProcessConfig}
														/>
												}
											</Box>

										</Collapse>
									</Card> : null}

									{/* Warehouse Detail */}
									<Card data-testid="job-warehouse-card">
										<CardHeader
											title={
												<Typography
													variant="span"
													sx={{
														color: theme.themeOrange,
													}}
												>
													Warehouse Details
												</Typography>
											}
											action={
												<ExpandMore
													expand={isExpanded.warehouseDetail}
													onClick={() => {
														setIsExpanded((o) => ({
															...o,
															warehouseDetail:
																!o.warehouseDetail,
														}));
													}}
												>
													<ExpandMoreIcon />
												</ExpandMore>
											}
										/>
										<Collapse
											in={isExpanded.warehouseDetail}
											timeout="auto"
											unmountOnExit
										>
											{shipmentDetail?.map((shipment, index) => {
												const {
													warehouseName,
													shipmentId,
													floorName,
												} = shipment;

												return (
													<>
														<CardContent
															sx={{
																display: "flex",
																flexDirection: "column",
																gap: ".5em",
																border: "1px solid #ccc",
																margin: ".5em",
																borderRadius: "12px",
															}}
															key={index}
														>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent:
																		"space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{
																		color: "gray",
																	}}
																>
																	Shipment ID:
																</Typography>
																<Typography
																	variant="span"
																	sx={{
																		flexBasis:
																			"60%",
																	}}
																>
																	{shipmentId || "-"}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent:
																		"space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{
																		color: "gray",
																	}}
																>
																	Warehouse :
																</Typography>
																<Typography
																	variant="span"
																	sx={{
																		flexBasis:
																			"60%",
																	}}
																>
																	{warehouseName}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent:
																		"space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{
																		color: "gray",
																	}}
																>
																	Floor :
																</Typography>
																<Typography
																	variant="span"
																	sx={{
																		flexBasis:
																			"60%",
																	}}
																>
																	{floorName || "-"}
																</Typography>
															</Box>
														</CardContent>
													</>
												);
											})}
										</Collapse>
									</Card>

									{
										extraUserDetail &&

										<Card data-testid="job-user-card">
											<CardHeader
												title={
													<Typography
														variant="span"
														sx={{
															color: theme.themeOrange,
														}}
													>
														Stock Transfer Details
													</Typography>
												}
												action={
													<ExpandMore
														expand={isExpanded.userDetail}
														onClick={() => {
															setIsExpanded((o) => ({
																...o,
																userDetail:
																	!o.userDetail,
															}));
														}}
													>
														<ExpandMoreIcon />
													</ExpandMore>
												}
											/>
											<Collapse
												in={isExpanded.userDetail}
												timeout="auto"
												unmountOnExit
											>
												<CardContent
													sx={{
														display: "flex",
														flexDirection: "column",
														gap: ".5em",
														border: "1px solid #ccc",
														margin: ".5em",
														borderRadius: "12px",
													}}
												>
													<Box
														sx={{
															display: "flex",
															gap: "0.25em",
															fontSize: "13px",
															justifyContent:
																"space-between",
														}}
													>
														<Typography
															variant="span"
															sx={{
																color: "gray",
															}}
														>
															Name :
														</Typography>
														<Typography
															variant="span"
															sx={{
																flexBasis:
																	"60%",
															}}
														>
															{extraUserDetail.name}
														</Typography>
													</Box>
													<Box
														sx={{
															display: "flex",
															gap: "0.25em",
															fontSize: "13px",
															justifyContent:
																"space-between",
														}}
													>
														<Typography
															variant="span"
															sx={{
																color: "gray",
															}}
														>
															Mobile :
														</Typography>
														<Typography
															variant="span"
															sx={{
																flexBasis:
																	"60%",
															}}
														>
															{extraUserDetail.mobile}
														</Typography>

													</Box>
													<Box
														sx={{
															display: "flex",
															gap: "0.25em",
															fontSize: "13px",
															justifyContent:
																"space-between",
														}}
													>
														<Typography
															variant="span"
															sx={{
																color: "gray",
															}}
														>
															Email :
														</Typography>
														<Typography
															variant="span"
															sx={{
																flexBasis:
																	"60%",
															}}
														>
															{extraUserDetail.email}
														</Typography>

													</Box>
													<Box
														sx={{
															display: "flex",
															gap: "0.25em",
															fontSize: "13px",
															justifyContent:
																"space-between",
														}}
													>
														<Typography
															variant="span"
															sx={{
																color: "gray",
															}}
														>
															Address :
														</Typography>
														<Typography
															variant="span"
															sx={{
																flexBasis:
																	"60%",
															}}
														>
															{extraUserDetail.address ? extraUserDetail.address : "-"}
														</Typography>

													</Box>
													<Box
														sx={{
															display: "flex",
															gap: "0.25em",
															fontSize: "13px",
															justifyContent:
																"space-between",
														}}
													>
														<Typography
															variant="span"
															sx={{
																color: "gray",
															}}
														>
															Designation :
														</Typography>
														<Typography
															variant="span"
															sx={{
																flexBasis:
																	"60%",
															}}
														>
															{extraUserDetail.userType ? extraUserDetail.userType : "-"}
														</Typography>

													</Box>
												</CardContent>
											</Collapse>
										</Card>
									}

									{/* Sales Order Detail */}
									{
										salesOrderDetail?.length ?
											<>
												<Card data-testid="sales-detail-card">
													<CardHeader
														title={
															<Typography
																variant="span"
																sx={{
																	color: theme.themeOrange,
																}}
															>
																Sales Order Detail
															</Typography>
														}
														action={
															<ExpandMore
																expand={isExpanded.salesOrderDetail}
																onClick={() => {
																	setIsExpanded((o) => ({
																		...o,
																		salesOrderDetail: !o.salesOrderDetail,
																	}));
																}}
															>
																<ExpandMoreIcon />
															</ExpandMore>
														}
													/>
													<Collapse
														in={isExpanded.salesOrderDetail}
														timeout="auto"
														unmountOnExit
													>
														{salesOrderDetail?.map((sales, index) => {
															const {
																sales_Id,
																salesDetailID,
																count,
															} = sales;

															return (
																<>
																	<CardContent
																		sx={{
																			display: "flex",
																			flexDirection: "column",
																			gap: ".5em",
																			border: "1px solid #ccc",
																			margin: ".5em",
																			borderRadius: "12px",
																		}}
																		key={index}
																	>
																		{renderRow("Sales ID", sales_Id ? sales_Id : "-")}
																		{renderRow("Sales Item ID", salesDetailID ? salesDetailID : "-")}
																		{renderRow("Count", count ? count : "0")}
																	</CardContent>
																</>
															);
														})}
													</Collapse>
												</Card>
											</>
											:
											""
									}
									{/* kitDetails */}
									{
										kitDetails?.length ?
											<>
												<Card data-testid="kit-detail-card">
													<CardHeader
														title={<Typography variant="span" sx={{ color: theme.themeOrange, }}>Kit Detail</Typography>}
														action={<ExpandMore expand={isExpanded.kitDetails}
															onClick={() => {
																setIsExpanded((o) => ({
																	...o,
																	kitDetails: !o.kitDetails,
																}));
															}}
														>
															<ExpandMoreIcon />
														</ExpandMore>
														}
													/>
													<Collapse
														in={isExpanded.kitDetails}
														timeout="auto"
														unmountOnExit
													>
														{kitDetails?.map((kit, index) => {
															const {
																KIT_ID,
																requiredSKUs = {},
																requiredKits = {},
																totalKits = 0,
																scannedKits = 0
															} = kit;

															return (
																<>
																	<CardContent
																		sx={{
																			display: "flex",
																			flexDirection: "column",
																			gap: ".5em",
																			border: "1px solid #ccc",
																			margin: ".5em",
																			borderRadius: "12px",
																		}}
																		key={index}
																	>
																		{renderRow("Kit ID", KIT_ID ? KIT_ID : "-")}
																		{renderRow("Total Kits", totalKits || "0")}
																		{renderRow("Scanned Kits", scannedKits || "0")}
																		{renderRow(
																			"Per Kit SKUs",
																			Object.entries(requiredSKUs || {})?.map(([SKU, perKitCount]) => `${SKU}(${perKitCount})`)?.join(", ")
																		)}
																		{renderRow(
																			"Build Kit",
																			Object.entries(requiredKits || {})?.filter(([ID, scanned]) => scanned == true)?.map(([ID, scanned]) => ID)?.join(", ")
																		)}
																	</CardContent>
																</>
															);
														})}
													</Collapse>
												</Card>
											</>
											:
											""
									}

									{/* Remark detail */}
									{remark?.length > 0 ? <Card
										data-testid="job-remark-card"
										sx={{ maxHeight: "350px", overflowY: "auto" }}
									>
										<CardHeader
											title={
												<Typography
													variant="span"
													sx={{
														color: theme.themeOrange,
														display: "flex-inline",
														justifyContent: "center",
														alignItems: "center",
														// gap: "0.5em",
													}}
												>
													Remark Details

												</Typography>
											}
											action={
												<ExpandMore
													expand={isExpanded.remarks}
													onClick={() => {
														setIsExpanded((o) => ({
															...o,
															remarks:
																!o.remarks,
														}));
													}}
												>
													<ExpandMoreIcon />
												</ExpandMore>
											}
										/>
										<Collapse
											in={isExpanded.remarks}
											timeout="auto"
											unmountOnExit
										>

											<RenderRamarkData
												user={user}
												remark={remark}
											/>
										</Collapse>
									</Card> : null}
								</CardContent>
								<CardActions></CardActions>
							</Card>
					}
				</Grid>
			</Grid>
		</>
	);
};

const RenderRamarkData = ({ remark, user }) => {


	const renderRow = (label, value) => {
		return <Box sx={{ display: "flex", gap: "0.25em", fontSize: "13px", justifyContent: "space-between" }}
		>
			<Typography variant="span" sx={{ color: "gray" }}>{label} :</Typography>
			<Typography variant="span" sx={{ flexBasis: "70%" }}>{value || "-"}</Typography>
		</Box>
	}

	return (
		<>
			{remark.map((data, index) => {
				const { remarkValue, date, _id, email, designation, name } = data;
				return (
					<CardContent
						sx={{
							display: "flex", flexDirection: "column", gap: ".5em",
							border: "1px solid #ccc", margin: ".5em", borderRadius: "12px",
						}}
						key={index}
					>
						<Box sx={{ display: "flex", fontSize: "13px", justifyContent: "end", marginBottom: "-19px" }}
						>
							<Typography variant="span"
								sx={{
									background: "#F27700",
									transform: "translate(16px, -16px)",
									borderTopRightRadius: "10px",
									padding: "5px 10px"
								}}
							>{capitalize(user[_id]?.name || name || "-")}</Typography>
						</Box>
						{renderRow("Email", user[_id]?.email || email || "")}
						{renderRow("Remark", capitalize(remarkValue || ""))}
						{date ? renderRow("Date", dateTimeFormater(date)) : null}
						{renderRow("Designation", capitalize(user[_id]?.designation || designation || ""))}

					</CardContent>
				);
			})}
		</>
	)
}
const RenderLocationData = ({ location, cellOnly = false, jobType = false, jobProcessConfig = {}, locationPage = {} }) => {

	// console.log("locationPage",locationPage)
	const renderRow = (label, value, isLocation = false) => {
		return <Box sx={{ display: "flex", gap: "0.25em", fontSize: "13px", justifyContent: "space-between" }}
		>
			<Typography variant="span" sx={{ color: "gray" }}>{label} :</Typography>
			{isLocation
				? Array.isArray(value)
					?
					<Typography variant="span" sx={{ flexBasis: "60%" }}>
						{
							value.map((dl, index) => {
								let { cellName, rackName, count = 0, scannedCount = 0 } = dl
								return <Typography variant="span" >
									{(cellName || "") + (rackName ? " - " + rackName : "") + (`[${truncateNumber(scannedCount,3,true)}/${truncateNumber(count,3,true)}]`) + (value.length > index + 1 ? `, ` : "")}
									{/* {} */}
								</Typography>
							})
						}</Typography>
					: "-"
				: <Typography variant="span" sx={{ flexBasis: "60%" }}>{value || ""}</Typography>
			}		</Box>
	}
	// (cellName || "") + (rackName ? " - " + rackName : "")
	return (
		<>
			{location.map((data, index) => {
				const {
					SKU_Number, SKU_Name, USN_Value, cellName, count = 0, shipmentId, packageId, mappingArea = "",
					itemId, packageName, rackName, floorName, warehouseName, cellDetail = [], scannedCount = 0, indicatedTo, masterPackageId
				} = data;
				return (
					<CardContent
						sx={{
							display: "flex", flexDirection: "column", gap: ".5em",
							border: "1px solid #ccc", margin: ".5em", borderRadius: "12px",
						}}
						key={index}
					>
						<Box sx={{ display: "flex", fontSize: "13px", justifyContent: "space-between", marginBottom: "-19px" }}
						>
							<Typography variant="span"
								sx={{
									background: "#F27700",
									transform: "translate(-16px, -16px)",
									borderTopLeftRadius: "10px",
									padding: "5px 10px"
								}}
							>
								{((locationPage.page - 1) * (locationPage.limit)) + index + 1}
							</Typography>
							<Typography variant="span"
								sx={{
									background: "#F27700",
									transform: "translate(16px, -16px)",
									borderTopRightRadius: "10px",
									padding: "5px 10px"
								}}
							>
								{cellOnly
									? (cellDetail?.at(0)?.cellName || "")
									: jobType == "pickingAndPackaging"
										? masterPackageId
										: shipmentId || "-"
								}</Typography>
						</Box>

						{renderRow("Warehouse", (warehouseName || "") + (floorName ? " - " + floorName : ""))}
						{cellOnly
							? cellDetail?.at(0)?.rackName && renderRow("Rack", cellDetail?.at(0)?.rackName) || null
							: !["pickingAndPackaging", "inventoryAudit"].includes(jobType)
								? cellDetail?.length > 0
									? renderRow("Location", cellDetail, true)
									: renderRow("Mapping Area", mappingArea ? mappingArea : "PUT IT TO ANYWHERE")
								: jobType == "inventoryAudit"
									? renderRow("Cell Detail", (cellName || "") + (rackName ? " - " + rackName : "")) || null
									: ""
						}
						{/* {renderRow("Shipment ID",shipmentId)} */}
						{itemId ? renderRow("Item Id", itemId) : null}
						{SKU_Number ? renderRow("SKU Number", SKU_Number) : null}
						{SKU_Name ? renderRow("SKU Name", SKU_Name) : null}
						{USN_Value ? renderRow("USN Value", USN_Value) : null}
						{packageId ? renderRow("Package ID", packageId) : null}
						{packageId && packageName ? renderRow("Package Name", packageName) : null}
						{data?.pallet_SKU_ID ? renderRow("Pallet ID", data?.pallet_SKU_ID) : null}
						{jobType == "pickingAndPackaging" && itemId && indicatedTo ? renderRow("Item Refer To", indicatedTo) : null}
						{/* {renderRow("Mapping Area",mappingArea ? mappingArea :"PUT IT TO ANYWHERE")} */}
						{renderRow("Total Qty", truncateNumber(count,3,true))}
						{renderRow("Scanned Qty", truncateNumber(scannedCount,3,true))}
					</CardContent>
				);
			})}
		</>
	)
}
const RenderFormData = ({ templateHeader, templateData }) => {

	return (
		<>
			{
				templateHeader?.length ? templateHeader.map((ele, index) => {
					const { label, type, format, name,values=[] } = ele
					let value = templateData[name] || ""
					if (type == "file") {
						value = ""
					}
					else if (type == "date" && value) {
						value = format ? moment(value).format(format) : dateTimeFormater(value)
					}
					else if (type == "checkbox") {
						value = Array.isArray(value) ? value : []
						if(value?.length){
							value=value.map(dl=>values?.find(dll=>dll?.value==dl)?.label || dl)
						}
						value = value.join(", ")
					}
					else if (type == "select" || type == "radio") {
						if(value){
							value=values?.find(dll=>dll?.value==value)?.label || value
						}
						else{value=""}
					}
					else if(Array.isArray(value)) {
						value = value.join(", ")
					}
					else{
						value = value && typeof value=="string" && value.trim() || value || ""
					}
					return (
						<Box
							sx={{
								display: "flex",
								gap: "0.25em",
								fontSize: "13px",
								justifyContent:
									"space-between",
							}}
							key={index}
						>

							<Typography
								variant="span"
								sx={{
									color: "gray",
								}}
							>
								{label} :
							</Typography>
							<Typography
								variant="span"
								sx={{ flexBasis: "60%", textTransform: "capitalize" }}
							>
								{value}
							</Typography>
						</Box>
					)

				})
					: ""
			}
		</>

	);
}

export {RenderRamarkData, RenderLocationData, RenderFormData }

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));

function capitalize(str) {
	if (!str) return;
	str = str + ""
	// ////({ str });
	return str[0].toUpperCase().concat(str.slice(1));
}
