import React from "react";
import { Grid, Typography } from "@mui/material";
import loaderGif from "../../../static/images/drawer/loader.gif";
import theme, { textClasses } from "../../../static/styles/theme";

const GenericLoader = ({ loaderText = "" }) => {
    return (
        <Grid item={true} data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
            <Grid item><img src={loaderGif} alt='' /></Grid>
            {loaderText && <Grid item>
                <Typography sx={{ ...textClasses.t16n, color: theme.themeOrange }}>
                    {loaderText}
                </Typography>
            </Grid>
            }
        </Grid>
    )

}

export default GenericLoader;