import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { customAlphabet } from 'nanoid';
const alphabet =
"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; // Excluding hyphen (-)
const nanoid = customAlphabet(alphabet, 21);
import { Grid, Box, InputLabel, Select, MenuItem, FormControl, Typography,Breadcrumbs, Checkbox, Menu } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import './Style/AreaMapping.css';
import BlankScreen from './Components/BlankScreen';
import MapTable from './Components/MapTable';
import AddIcon from '@mui/icons-material/Add';
import Upload from '@mui/icons-material/Upload';
import { useDebounce } from '../../../utils/hooks';
import { getCompany } from '../../../redux/actions';
import { getGridDetail } from '../../../redux/actions/Warehouse';
import { getAreaMappingDetail, getMappedArea, getDockMappingData, getAreaMappingStatusCount, deleteDockToStorageMappingData } from '../../../redux/actions/AreaMapping';
import GridViewLayout from '../GridView/Components/GridViewLayout';
import AllocateModel from './Components/AllocateModel';
import {
    Add,
    Cancel,
    Clear,
    KeyboardArrowDown,
    Search,
  } from "@mui/icons-material";
import DockAllocateModal from './Components/DockAllocateModal';
import EmptyPage from '../../Modules/EntityMaster/EmptyPage';
import { dataModifer } from './Components/utils';
import { dataReadFunction,renderHeadersFilter,dateTimeFormater } from '../../../services/functions';
import theme, { buttonClasses, textClasses } from '../../../static/styles/theme';
import GenericPageConfig from '../Generic/GenericPageConfig';
import GenericTable from '../CustomTable/GenericTable';
import GenericCustomiseHeader from '../Generic/GenericCustomiseHeader';
import GenericLoader from '../Generic/GenericLoader';
import { multiDateToISOConvertor } from '../Generic/GenericMultiDateRange';
import ConfirmDialogPopUp from './Components/ConfirmDialogPopUp';

let initial_state = {
    "search": {},
    "other": {},
}

function AreaMapping() {

    const dispatch = useDispatch();
    const { message, error, loading } = useSelector((state) => state.common);
    const { companies, warehouseDetail, floorDetail } = useSelector(state => state.superadmin);
    const { pageTitle = "Area Mapping" } = useSelector(state => state?.DynamicPageConfig?.areaMappingPage) || {};
    const superFloorData = useSelector(state => state.superadmin.selectedFloor || {}) // floor data got when coming from superadmin page
    const { mappedArea } = useSelector((state) => state.areaMappingData)
    const { gridCellDetails } = useSelector((state) => state.warehouseData)
    const { userPermissions,sidebarList=[] } = useSelector((state) => state.userPermission);
    let permissions = userPermissions || {};
    const tableHeader = useSelector((state) => state.customHeader["dockMappingTable"]);
    const [tabIndex, setTabIndex] = useState(-1);
    const [statusFilter, setStatusFilter] = useState({});
    const [filter, setFilter] = useState(initial_state);
    const [mappedDockData,setMappedDockData] = useState({
        tableData:[],
        totalCount:0
    })
    const [areaMappingStatusCount, setAreaMappingStatusCount] = useState({});
    const [userType, setUserType] = useState("");
    const [condition, setCondition] = useState(true);
    const [superCondition, setSuperCondition] = useState(true);
    const [companiesData, setCompaniesData] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState("") // for selecting company by the superadmin
    const [warehouseId, setWarehouseId] = useState('all')
    const [warehouseData, setWarehouseData] = useState([]);
    const [warehouseName, setWarehouseName] = useState("")
    const [cellData, setCellData] = useState([])
    const [cellNameObj, setCellNameObj] = useState({})
    const [selectedEntries, setSelectedEntries] = useState([]);
    const [floorId, setfloorId] = useState('all')
    const [floorData, setFloorData] = useState([]);
    const [floorName, setFloorName] = useState("")
    const [floorInfo, setFloorInfo] = useState();
    const [allMappedArea, setAllMappedArea] = useState([])
    const [tableLoader, setTableLoader] = useState("tableData")
    const [dockTableLoader, setDockTableLoader] = useState(true)
    const [area, setArea] = useState([]);
    const [allEmptyLocation, setAllEmptyLocation] = useState([]);
    const [cellIdObj, setCellIdObj] = useState({});
    const [withWFFetch, setWithWFFetch] = useState({ warehouse: true, floor: true, company: true, graph: true })
    const [pageNumber, setPageNumber] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [pageLimits, setPageLimits] = useState(10)
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionIndex, setActionIndex] = useState(null);
    const [confirmModal, setConfirmModal] = useState(null);
    const [actionConfirmModal, setActionConfirmModal] = useState(null);
    const debouncedFilterSearch = useDebounce(filter?.search, 500);
    const [dockTablePage,setDockTablePage] = useState(0)
    const [dockTableLimit,setDockTableLimit] = useState(10)
    const [allocationType, setAllocationType] = useState("");
    const [allocateModelVisible, setAllocateModelVisible] = useState("");
    const [selectedRack, setSelectedRack] = useState({});
    const [selectedRackArr, setSelectedRackArr] = useState([]);
    const [isEmpty, setIsEmpty] = useState(true);
    const [initialRender, setInitialRender] = useState(true)
    const [count, setCount] = useState({
        available: 0,
        mapped: 0,
        disabled: 0
    })

    const selectedCompanyHandler = (company) => {
        if (company) {
            dispatch(getCompany({
                "companyName": company,
                "type": "warehouse",
                "status": "active",
                "fetch_for":"gridView"
            }));
            setSelectedCompany(company)
        }
        else {
            setSelectedCompany('')
        }
        // setWarehouseId("")
        // setfloorId("")
        // setWarehouseData([])
        // setFloorData([])
        // setFloorInfo({})
        // setCellNameObj({})
        // setCellData([])
        // setAllMappedArea([])
        // setAllFilledLocation([])
        // setQrCalled(false)
        // setGraphData([])
    }

    const selectWarehouseHandler = (warehouse_Id = "") => {
        setTableLoader("tableData")
        if (warehouse_Id == 'all') {
            dispatch(getCompany({
                "type": "company",
                "status": "active"
            }));
            dispatch(getMappedArea({ page: pageNumber, limit: 10 }, ()=>{
                setTableLoader("")
            }))
            setfloorId('all')
        } else if (warehouse_Id) {
            dispatch(getCompany({
                "warehouseId": warehouse_Id,
                "type": "floor",
                "status": "active",
                "fetch_for":"gridView"
            }));
            dispatch(getMappedArea({ warehouseId: warehouse_Id, page: pageNumber, limit: 10 }, ()=>{
                setTableLoader("")
            }))
            setfloorId("all")
        }
        setWarehouseId(warehouse_Id)
        getWarehouseName(warehouse_Id)
        setPageNumber(1)
        setFloorData([])
        setCellNameObj({})
        setCellData([])
        setFloorInfo({})
        setAllMappedArea([])
        // setAllFilledLocation([])
    }

    const selectFloorHandler = (floor_Id = "") => {
        // //("data-floorid", floor_Id);
        if (floor_Id && warehouseId) {
            let data = floorDetail.find((ele) => ele._id == floor_Id)
            if (allocationType) {
                if(allocationType=="dockMapping"){
                    dispatch(getGridDetail({
                        "floorId": floor_Id,
                        "type": "block",
                        //["block","dock"],
                        "fetch_for": 'gridview'
                    }));
                }
                else{
                    dispatch(getGridDetail({
                        "floorId": floor_Id,
                        "type": "block",
                        "fetch_for": 'gridview'
                    }));
                }
            } else {
                setTableLoader("tableData")
                if (floor_Id == 'all') {
                    dispatch(getMappedArea({ warehouseId: warehouseId, page: pageNumber, limit: 10 }, ()=>{
                        setTableLoader("")
                    }))
                } else {
                    dispatch(getMappedArea({ warehouseId: warehouseId, floorId: floor_Id, page: pageNumber, limit: 10 }, ()=>{
                        setTableLoader("")
                    }))
                }

            }
            setFloorInfo(data)
            setfloorId(floor_Id)
            getFloorName(floor_Id)
        }
        else {
            setTableLoader("tableData")
            dispatch(getMappedArea({ warehouseId: warehouseId, page: pageNumber, limit: 10 }, ()=>{
                setTableLoader("")
            }))
            setFloorInfo({})
            setfloorId("")
            setFloorName("");
        }
        setPageNumber(1)
        // setCellNameObj({})
        // setCellData([])
        setAllMappedArea([])
        setSelectedRack({})
        // setAllFilledLocation([])
        // setQrCalled(false)
        // setGraphData([])
        // setWithWFFetch({ ...withWFFetch, graph: true })
    }

    const onFetchPage = (pageNumber, pageLimits) => {
        if (pageNumber || pageLimits) {
            setTableLoader("tableData")
            // //('warehouseIdfloorId', warehouseId, floorId)
            dispatch(getMappedArea({
                warehouseId: warehouseId != 'all' ? warehouseId : "",
                floorId: floorId != 'all' ? floorId : "",
                page: pageNumber,
                limit: pageLimits
            },()=>{
                setTableLoader("")
            }))


        }
    }

    const addSelectedRack = (data) => {
        setSelectedRack(data)
        if (data.areaTypeData?.length == 0) {
            setIsEmpty(true)
        } else {
            setIsEmpty(false)
        }
        // //('selectedrack', data)
    }

    const showAllocateModel = (allocatedType) => {
        if (allocatedType == "areaAllocationModal") {
            const updatedMappingArea = selectedRack?.areaTypeData
                ?.filter(type => type?.actionType === "update")
                ?.map(type => type.oldMaterialType).flat()
                ?.filter(Boolean) // Filters out undefined or null values

            const cellIds = selectedRack?.areaTypeData
                .filter(type => type.actionType === "update")
                .map(type => type.cellId);

            const warehouseDetails = {
                warehouseId: selectedRack?.warehouseId,
                floorId: selectedRack?.floorId,
            };
            const payload = {
                floorId: selectedRack?.floorId,
                mappingArea: updatedMappingArea,
                cellId: cellIds,
            };
            setTableLoader("areaDetail")
            dispatch(
                getAreaMappingDetail(payload, ({ success, message, data }) => {
                    setTableLoader("")
                    if (success) {
                        setSelectedRack(currentSelectedRack => {
                            const modifiedData = dataModifer({
                                warehouseId: currentSelectedRack.warehouseId,
                                floorId: currentSelectedRack.floorId,
                            }, data);
                            const newAreaTypeData = currentSelectedRack.areaTypeData
                                .filter(type => type.actionType === "addNew")
                                .concat(modifiedData.areaTypeData);
                            return { ...currentSelectedRack, areaTypeData: newAreaTypeData };
                        });
                    }
                })
            );
        }
        setAllocateModelVisible(allocatedType)
    }

    const countAvailblity = () => {
        let disable = 0;
        let available = 0;
        let mapped = 0;
        gridCellDetails.forEach((element) => {
            if(allocationType=="dockMapping"){
                if(element.type=="dock"){
                    if (element.disable) {
                        disable += 1;
                    } else if (!element.disable && element?.isMappedWithLocation) {
                        mapped += 1;
                    } else if ( !element.disable && !element?.isMappedWithLocation ) {
                        available += 1;
                    }
                }
            }else{
            if(element.type=="block"){
                if (element.disable) {
                    disable += 1;
                } else if (!element.disable && element.subProductType?.length > 0) {
                    mapped += 1;
                } else if (!element.disable && element.subProductType?.length == 0) {
                    available += 1;
                }
            }
        }
        });
        setCount({
            ...count, available: available,
            mapped: mapped,
            disabled: disable
        })

    }

    const getWarehouseName = (warehouse_id) => {
        let name = warehouseDetail.data.filter((ele) => ele._id == warehouse_id).map((item) => item.name)[0];
        setWarehouseName(name);
    }

    const getFloorName = (floor_id) => {
        // //('warehouse-name1', floorDetail, floor_id)
        let name = floorDetail.filter((ele) => ele._id == floor_id).map((item) => item.name)[0];
        setFloorName(name);
    }

    const handleRefreshTable = ()=>{
        refreshTableData()
        onFetchPage(1, 10)
        setPageNumber(1);
        setPageLimits(10)
    }
    const closeAllocateModel = () => {
        setAllocateModelVisible("")
        setSelectedRackArr([])
        setSelectedRack({})
        setIsEmpty(true)
        dispatch(getGridDetail({
            "floorId": floorId,
            "type": "block",
            "fetch_for": 'gridview'
        }));
    }
    const handleOpenAction = (event, i) => {
        setAnchorEl(event.currentTarget);
        setActionIndex(i);
    };
    const handleCloseAction = () => {
        setActionConfirmModal(null);
        if (setSelectedEntries) {
            setSelectedEntries([]);
        }
        setActionIndex(null);
        setTimeout(() => {
            setAnchorEl(null);
        }, 1);
    };
    const handleTabChange = (value, statusObject = {}) => {
        setStatusFilter(statusObject)
        let { status, ...restOther } = { ...filter["other"] || {} }
        setFilter({
          search: filter?.search || {},
          other: {
            ...restOther,
            status: statusObject?.value || ""
          }
        })
        setDockTablePage(0);
        setTabIndex(value);
        setSelectedEntries([])
        refreshTableData(1, dockTableLimit, statusObject?.value,true )
      };
      const handleRowAndViewClick = (selectedRowData) => {
        // let payload = {
        //     dashboardId: selectedRowData?._id,
        // }
        // history.push(`/dashboard/custom/view/${payload.dashboardId}`)
    }

    const handleCloseConfirmModal = () => {
        setConfirmModal(null);
    };

    const handleAddSelection = (obj) => {
        setSelectedEntries([...selectedEntries, obj]);
    };

    const handleRemoveSelection = (id) => {
        let oldEntries = [...selectedEntries];
        const i = oldEntries.map((o) => o._id).indexOf(id);
        if (i >= 0) {
            oldEntries.splice(i, 1);
            setSelectedEntries(oldEntries);
            if (oldEntries.length == 0) {
                handleCloseConfirmModal();
            }
        }
    };
    const handleCheckChange = (event, row) => {
        if (event.target.checked) {
            handleAddSelection(row);
        } else {
            handleRemoveSelection(row._id);
        }
    };
      const handleCheckAll = () => {
        if (selectedEntries.length < mappedDockData?.tableData?.length) {
            let sT = mappedDockData?.tableData?.map((t) => ({
                _id: t._id,
                ruleName: t.ruleName
            }));
            setSelectedEntries(sT);
        } else {
            setSelectedEntries([]);
        }
    };

    const isFilterApplied = (() => {
        // console.log("filter", filter,statusFilter)
        if (tabIndex == 0 && ((statusFilter?.value && statusFilter?.value!="all") || (filter?.other?.status && filter?.other?.status!="all"))) {
          return true;
        }
        let { status, ...restOther } = { ...filter["other"] || {} }
        // delete other["status"]
        if (Object.values(restOther || {})?.some(dl => dl && (typeof dl == "object" && !Array.isArray(dl) ? dl?.value : dl.length > 0)) || Object.values(filter["search"]).some(dl => dl && (typeof dl == "object" && !Array.isArray(dl) ? dl?.value : dl.length > 0))) {
          return true;
        }
        return false;
      })();
    
    const handleClearFilter = () => {
        setFilter(initial_state)
        setDockTablePage(0);
        setDockTableLimit(10);
        if (tabIndex == 0) {
            setStatusFilter(null);
        }
    };
    
      let header = tableHeader && tableHeader?.validHeader && tableHeader.validHeader.map((h) => {
        if (h.handleType === "selectRow") {
          return (
            <Checkbox
              data-testid="checkbox"
              checked={
                mappedDockData?.tableData?.length != 0 &&
                selectedEntries.length == mappedDockData?.tableData?.length
              }
              onChange={handleCheckAll}
              size="small"
            />
          )
        }
        return h.fieldName
      })
        || [];
    
    const getTableCell = (header, row, i) => {
        let value = ""
        if (header?.mappingPath) {
            value = dataReadFunction({ name: header?.mappingPath || "", renderOption: header?.renderOption || [] }, row)
        }
        if (header?.handleType) {
               if (header?.handleType === "selectRow") {
                          return (
                              <Checkbox
                                  size="small"
                                  checked={selectedEntries?.map((o) => o._id).indexOf(row._id) >= 0}
                                  onChange={(e) => handleCheckChange(e, row)}
                              />
                          );
                      }
            else if (header?.handleType === "showDetail") {
                return (
                    <Typography
                        onClick={() => handleRowAndViewClick(row)}
                        style={{
                            ...textClasses.normalText,
                            color: theme.themeOrange,
                            textAlign: "center",
                            cursor: "pointer",
                        }}
                    >
                        {value ?? "-"}
                    </Typography>
                );
            }
            else if (header?.handleType === "action") {
                return (
                    <Typography
                        data-testid="dropdown"
                        sx={{
                            ...textClasses.normalText,
                            color: header?.color || theme.themeOrange,
                            cursor: "pointer",
                        }}
                        onClick={(e) => handleOpenAction(e, i)}
                    >
                        Action <KeyboardArrowDown />
                    </Typography>
                );
            }
            else if (header?.handleType === "upload") {
                return (<Typography
                    sx={{ ...textClasses.normalText, color: theme.themeOrange }}
                >
                    <Button
                        data-testid="uploadButton"
                        variant="contained"
                        size="small"
                        sx={{
                            ...buttonClasses.lynkitBlackFill,
                            minHeight: "24px",
                            minWidth: "80px",
                            color: "white",
                        }}
                      startIcon={<Upload />}
                    //   onClick={() => {
                    //     setUploadStatus(true);
                    //     setSelectedRack(item);
                    //   }}
                    >
                        Upload File
                    </Button>
                </Typography>)
            }
        }
        else if (header?.isFromRenderFunction === true) {
            if (header?.evaluateFunction) {
                // Create a function using new Function()
                const extractNames = new Function("value", header?.evaluateFunction);
                const result = extractNames(value);
                return result
            }
            else {
                let no;
                if (dockTablePage === 0) {
                    no = i + 1;
                } else {
                    no = dockTablePage * 10 + (i + 1);
                }
                if (header?.renderFunction === "srNo") {
                    return (
                        <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: header?.color || theme.themeText }}>
                            {no}
                        </Typography>
                    );
                }
                else if (header?.renderFunction === "status") {
                    const statusColor = {
                        enabled: "#3E621E",
                        disabled: "#ff0c64",
                    };
                    const statusText = {
                        enabled: "Enabled",
                        disabled: "Disabled",
                    };
                    return (
                        <>
                            <Typography
                                sx={{
                                    ...textClasses.normalText,
                                    textTransform: "capitalize",
                                    textAlign: "center",
                                    color: statusColor[row?.approved ? "enabled" : "disabled"],
                                }}
                            >
                                {row?.approved ? statusText["enabled"] : statusText["disabled"]}
                            </Typography>
                        </>
                    );
                }

            }

        }
        else {
            if (header?.fieldType === "array") {
                return (
                    <Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "capitalize", color: header?.color || theme.themeText }}>
                        {value && Array.isArray(value) ? value.join(", ") : value || "-"}
                    </Typography>
                );
            }
            else if (header?.fieldType === "date") {
                return (
                    <Typography
                        sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", color: header?.color || theme.themeText }}
                    >
                        {value ? dateTimeFormater(value) : "-"}
                    </Typography>
                );
            }
            else {
                return (
                    <Typography
                        sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", textTransform: "capitalize", color: header?.color || theme.themeText }}
                    >
                        {value || "-"}
                    </Typography>
                );
            }
        }
    };

    const tableRows = mappedDockData?.tableData?.map((row, i) => {
        let items = [];

        const validHeaders = tableHeader?.validHeader ?? [];
        for (const header of validHeaders) {
            items.push(getTableCell(header, row, i));
        }
        return items;
    });

    const searchableFilterItems = useMemo(() => {
        if (!tableHeader || !tableHeader.validHeader) return [];
        return tableHeader.validHeader.filter((dl) => dl?.isSearchable) || [];
      }, [tableHeader]);

    const refreshTableData = (page, limit,status = "", fetchCount = false) => {
        // if(statusFilter?.value === "dockStorageMapping"){
         
        // }
        setDockTableLoader(true);
        const payload = {
            page: page,
            limit: limit,
            // type:"automation"
            // ruleType: selectedTab
        };
    let allDynamicKeys = tableHeader?.validHeader?.filter(dl => dl?.dynamic)?.map(dl => dl?.payloadKey || dl?.mappingPath)
    if (filter && Object.keys(filter || {}).length > 0) {
      for (let key in filter) {
        if (key && Object.keys(filter[key] || {}).length > 0) {
          for (let filterKey in filter[key]) {
            // if (filterKey && Object.keys(filter[key][filterKey] || {}).length > 0) {
            if (allDynamicKeys?.includes(filterKey)) {
              // Object.assign(payload, filter[key][filterKey])
              payload[filterKey] = filter[key][filterKey]?.value || ""

            } else {
              payload[filterKey] = filter[key][filterKey] || ""
            }
          }
        }
      }
      // Process date filter items
      const dateFilterItems = searchableFilterItems?.filter(
        (item) => item?.searchInputType === "date"
      );
      dateFilterItems.forEach((element) => {
        const payloadKey = element?.payloadKey || element?.mappingPath || "";
        if (payloadKey && payload[payloadKey]) {
          payload[payloadKey] = multiDateToISOConvertor(payload[payloadKey]);
        }
      });
    }

        dispatch(getDockMappingData(payload,(data={})=>{
            setDockTableLoader(false);
            if (data?.success) {
                setMappedDockData({...mappedDockData,tableData:data?.data || [], totalCount:data?.count || 0})
            }
        }))
              dispatch(getAreaMappingStatusCount({ type: "wms" }, (data) => {
                setAreaMappingStatusCount({ ...(data?.data || {}) })
              }))
            
    };
    let fetchData = (fetchCount = false) => {
        refreshTableData(dockTablePage + 1, dockTableLimit,statusFilter?.value || "", fetchCount);
        // setSelectedTemplates([]);
        // setConfirmModal(null);
        // setReceiveModal(null);
        setActionConfirmModal(null);
        setAnchorEl(null);
        setActionIndex(null);
        // setViewSkuGroupDetail(false)
    };

    useEffect(() => {
        if (statusFilter?.value === "dockStorageMapping") {
            fetchData(initialRender);
        }
        if (initialRender) {
            setInitialRender(false)
        }

    }, [dockTableLimit, dockTablePage, filter.other, debouncedFilterSearch,statusFilter?.value === "dockStorageMapping"]);


    // useEffect(()=>{

    //     if(statusFilter?.value === "dockStorageMapping"){
    //         setDockTableLoader(true);
    //         dispatch(getDockMappingData({},(data)=>{
    //             setDockTableLoader(false);
    //             console.log("dock darta",data)
    //             if(data?.data?.length==0){
    //                 setMappedDockData({tableData:[{
    //                     "_id": "67dd4d1cec4aa1548b609f7c",
    //                     "dockId": "173148436789307",
    //                     "warehouseId": "641bfde82b311227ac6d1164",
    //                     "created_by": {
    //                         "base": "Coding Ninjas",
    //                         "email": "codingninjas@gmail.com",
    //                         "mobile": "8130520061",
    //                         "name": "Admin_CN",
    //                         "userType": "admin",
    //                         "designation": "Admin",
    //                         "company": "Ninja Coding",
    //                         "companyId": "637df5eecd65a63d8585c901",
    //                         "_id": "633ff4fd65af23502ffbc0b5",
    //                         "date": "2025-03-21T11:27:10.109Z"
    //                     },
    //                     "dockName": "AREA 2",
    //                     "dockType": "outward",
    //                     "floorId": "6520f7b92536ae2cbee4f5f8",
    //                     "mappedLocation": "R2::16653979738392::rack,R3::16653979738393::rack,B8::170521632486400::block,B9::170521632583800::block"
    //                 },
    //                 {
    //                     "_id": "67dd4d1bec4aa1548b609f6f",
    //                     "dockId": "1738047392436203",
    //                     "warehouseId": "641bfde82b311227ac6d1164",
    //                     "created_by": {
    //                         "base": "Coding Ninjas",
    //                         "email": "codingninjas@gmail.com",
    //                         "mobile": "8130520061",
    //                         "name": "Admin_CN",
    //                         "userType": "admin",
    //                         "designation": "Admin",
    //                         "company": "Ninja Coding",
    //                         "companyId": "637df5eecd65a63d8585c901",
    //                         "_id": "633ff4fd65af23502ffbc0b5",
    //                         "createdAt": "2025-03-21T11:27:09.565Z"
    //                     },"dockName": "DOCCK OUT",
    //         "dockType": "outward",
    //         "floorId": "6520f7b92536ae2cbee4f5f8",
    //         "mappedLocation": "R1::R1::rack,A1::157871::block,A2::157872::block"
    //             }],totalCount:0})
    //             }else{
    //                 setMappedDockData({...mappedDockData,tableData:data?.data || [], totalCount:data?.count || 0})
    //             }
    //         }))
    //     }
    // },[statusFilter?.value])

    const viewAllocateButton = useMemo(() => {
        if (statusFilter?.value == "dockStorageMapping" && permissions?.permissions?.find(dl => dl.permissionId == "createDockMapping")) {
            return true
        }
        else if (statusFilter?.value == "materialMapping" && permissions?.permissions?.find(dl => dl.permissionId == "allocateArea")) {
            return true
        }
        else {
            return false
        }

    }, [statusFilter?.value, permissions])


    useEffect(() => {
        if (allocationType) {
            dispatch(getGridDetail({
                "floorId": floorId,
                "type": "block",
                "fetch_for": 'gridview'
            }));
        }
    }, [allocationType])

    useEffect(() => {
        // if (floorId && FilledLocationByShipment) {
        //     setAllFilledLocation(FilledLocationByShipment)
        // }
        if (gridCellDetails) {
            let tempObj = {}
            let tempCellIdObj = {}
            countAvailblity()
            for (let ele of gridCellDetails) {
                let obj = { cellId: ele.cellId, name: ele.name, disable: ele.disable, status: ele.status, type: ele.type }
                let key = `${ele.rowIndex}${ele.colIndex}`
                tempObj[key] = obj
                tempCellIdObj[ele.cellId] = ele.name
            }
            let tempArr = []
            tempArr = gridCellDetails.filter((ele) => ele.status == "available")
            setAllEmptyLocation(tempArr)
            setCellData(gridCellDetails)
            setCellNameObj(tempObj)
            setCellIdObj(tempCellIdObj)
            // //('selectedrack-20', gridCellDetails)
        }
        else {
            setAllEmptyLocation([])
            setCellData([])
            setCellNameObj({})
            setCellIdObj({})
        }



    }, [gridCellDetails,])

    useEffect(() => {
        // //('maparea', mappedArea)
        if (((!allMappedArea || allMappedArea?.length == 0) || mappedArea?.data)) {
            if (mappedArea && mappedArea.data && mappedArea.data?.length > 0) {
                setAllMappedArea(mappedArea.data);
                setPageCount(mappedArea.count)
            }
            else {
                setAllMappedArea([])
                setPageCount(0)
            }
        } else {
            if (message) {
                setAllMappedArea(mappedArea.data);
                setPageCount(mappedArea.count)
            } else {
                setAllMappedArea([])
                setPageCount(0)
            }

        }
    }, [message, mappedArea, error])

    useEffect(() => {
        let userData = localStorage.getItem('user');
        if (userData && userData.userType) {
            setUserType(userData.userType)
        }
        if (condition && userData && userData.userType == "superadmin" && (!companiesData || companiesData?.length == 0)) {
            dispatch(getCompany({
                "type": "company",
                "status": "active"
            }));
        } else {
            if (superCondition && userData && userData.userType == "superadmin") {
                if (companies && companies.data && companies.data?.length > 0) {
                    // //('data', "superadmin2")
                    setCompaniesData(companies.data)
                    if (withWFFetch && withWFFetch.company) {
                        if (!selectedCompany) {
                            selectedCompanyHandler(superFloorData.companyName || companies.data[0].companyName)
                        }
                        let tempFetch = { ...withWFFetch, company: false }
                        setWithWFFetch(tempFetch)
                    }

                }
                else {
                    setCompaniesData([])
                }
                if (superCondition) {
                    setSuperCondition(false)
                }
            } else if (condition && (!warehouseData || warehouseData?.length == 0)) {
                dispatch(getCompany({
                    "companyName": userData.company,
                    "type": "warehouse",
                    "status": "active",
                    "fetch_for":"gridView"
                }));
                setTableLoader("tableData")
                dispatch(getMappedArea({ page: pageNumber, limit: pageLimits },()=>{
                    setTableLoader("")
                }))
            } else {
                if (warehouseDetail && warehouseDetail?.data && warehouseDetail?.data?.length > 0) {
                    setWarehouseData(warehouseDetail.data)
                }
                else {
                    setWarehouseData([])
                }
                let tempFloor=(floorDetail || [])?.filter(dl=>dl.warehouseId==warehouseId)
                if (warehouseId && tempFloor && tempFloor?.length > 0) {
                    setFloorData(tempFloor)
                    // if (userData && userData.userType == "superadmin") {
                    //     dispatch(setFloorInStore(null))
                    // }
                }
                else {
                    setFloorData([])
                }

            }
        }
        if (condition) {
            setCondition(false)
        }

    }, [companies, warehouseDetail, floorDetail, withWFFetch])


    return (
        <>
            
                <Box sx={{ flexGrow: 1 }} className="AreaMap_container" data-testid="areaMapping-dashboard">
                    <Grid container spacing={2} margin="normal">
                        <Grid item xs={4}>
                        <Typography
                        sx={{ ...textClasses.cardTitle }}
                    >
                        {pageTitle}
                    </Typography>                    
                              {allocationType ?
                                <Box>
                                    <Typography variant="subtitle2" sx={{ marginTop: "-7px" }} gutterBottom>
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }} className='font-Nunito curser'  onClick={() => setAllocationType("")}>Area Mapping</Typography>
                                            <Typography color="text.primary" sx={{ fontWeight: '400',fontSize : '12px', lineHeight: "10px" }} className='font-Nunito'>{statusFilter?.value === "materialMapping" ? "Area Allocation" : "Dock Allocation"} </Typography>
                                        </Breadcrumbs>
                                    </Typography>
                                </Box> : ""}
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={2} justifyContent="end">
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <InputLabel size="small" id="select-warehouse-label">Select Warehouse</InputLabel>
                                        <Select
                                            labelId="select-warehouse-label"
                                            id="select-warehouse"
                                            label="Select Warehouse"
                                            data-testid="warehouse"
                                            size="small"
                                            sx={{ boxShadow: 1 }}
                                            onChange={(event) => selectWarehouseHandler(event.target.value)}
                                            // displayEmpty
                                            value={warehouseId}
                                        >
                                            <MenuItem value={"all"}>All</MenuItem>
                                            {warehouseData && warehouseData.map((ele, index) => {
                                                return (
                                                    <MenuItem value={ele._id} key={index}>{ele.name}</MenuItem>
                                                )
                                            })}


                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <InputLabel size="small" id="select-floor-label">Select Floor</InputLabel>
                                        <Select
                                            labelId="select-floor-label"
                                            id="select-floor"
                                            label="Select Floor"
                                            data-testid="floor"
                                            size="small"
                                            value={floorId}
                                            sx={{ boxShadow: 1 }}
                                            // disabled={!warehouseId || warehouseId == 'all' ? true : false}
                                            readOnly={!warehouseId || warehouseId == 'all' ? true : false}
                                            // displayEmpty
                                            onChange={(event) => selectFloorHandler(event.target.value)}
                                        >
                                            <MenuItem value={"all"}>All</MenuItem>
                                            {floorData && floorData.map((ele, index) => {
                                                return (
                                                    <MenuItem value={ele._id} key={index}>{ele.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    </Box>
                    {!allocationType &&
                    <GenericPageConfig
                    storedInPage={"areaMappingPage"}
                    pageName={"areaMapping"}
                    // pageTabCount={companystatusCount}
                      pageStatusCount={areaMappingStatusCount}
                    // handlePageTabChange={handleTypeChange}
                    selectedPageTab={"default"}
                    selectedStatusTab={tabIndex}
                    handleStatusTabChange={handleTabChange}
                //   disabledPageTabs={loader}
                //   disabledStatusTabs={loader}

                />}
               
     
            {(statusFilter?.value === "materialMapping" || statusFilter?.value === "dockStorageMapping") ? (
                <Box>
                    <Box sx={{ my: 2, borderRadius: '10px', border: '1px solid #D9D9D9' }}>
                        <Grid container spacing={2} padding={2}>
                            <Grid item xs={6} display={'flex'} justifyContent={'start'}>
                                <Box mt={1}>
                                    <span className='font-Nunito fs-18 fw-600'>Mapped Area Details :&nbsp;
                                        <span className='orange-color font-Nunito fs-18 fw-600'>{warehouseName ? warehouseName : "All Warehouse"} - {floorName ? floorName : "All Floor"} Layout </span></span>
                                </Box>

                            </Grid>
                            {viewAllocateButton &&
                                <Grid item xs={6} padding={0} sx={{ display: 'flex', justifyContent: 'end' }}>
                                    {allocationType ?
                                        <>
                                            <Box className='AM-label-container'>
                                                <Box className='AM-container'>
                                                    <Box className='AM-green-box'></Box>&nbsp;&nbsp;<span className='fs-12 AM-center'>Available ({count.available})</span>
                                                </Box>
                                                <Box className='AM-container'>
                                                    <Box className='AM-yellow-box'></Box>&nbsp;&nbsp;<span className='fs-12 AM-center' >Mapped ({count.mapped})</span>
                                                </Box>
                                                <Box className='AM-container'>
                                                    <Box className='AM-white-box'></Box>&nbsp;&nbsp;<span className='fs-12 AM-center'>Disabled ({count.disabled})</span>
                                                </Box>
                                            </Box>
                                        </>
                                        : <Tooltip title={!floorId || !warehouseId ? "Please select before Warehouse and Floor" : ""}>
                                            <IconButton>
                                                <Button
                                                    data-testid="allocateArea"
                                                    sx={{ ...buttonClasses.lynkitOrangeFill }}
                                                    variant="contained"
                                                    onClick={() => setAllocationType(statusFilter?.value === "materialMapping" ? "areaMapping" : "dockMapping")}
                                                    disabled={(!floorId || !warehouseId) ? true : false}
                                                >
                                                    <AddIcon sx={{ marginBottom: "2px" }} /> Allocate Area</Button>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </Grid>
                            }
                        </Grid>
                        <Box padding={2}>
                            {allocationType ?
                                <>
                                    {floorInfo && Object.keys(floorInfo)?.length > 0 && floorInfo.row && floorInfo.col
                                        ?
                                        <>
                                            <GridViewLayout
                                                floorInfo={floorInfo}
                                                gridCellDetails={gridCellDetails}
                                                area={area}
                                                allocationType={allocationType}
                                                addSelectedRack={(data) => addSelectedRack(data)}
                                                selectedRackArr={selectedRackArr}
                                            />
                                            <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '1rem' }}>
                                                <Button
                                                    sx={{ ...buttonClasses.lynkitOrangeFill }}
                                                    variant="contained"
                                                    onClick={() => showAllocateModel(statusFilter?.value === "dockStorageMapping" ? "dockAllocationModal" : "areaAllocationModal")}
                                                    disabled={isEmpty ? true : false}
                                                >
                                                    Allocate</Button>
                                            </Box>
                                        </>
                                        :
                                        <div>
                                            <EmptyPage description="No Grid View To Show - Select Warehouse And Floor First" />
                                        </div>
                                    }
                                </>
                                :
                                <>
                                    {
                                        statusFilter?.value === "dockStorageMapping" ?
                                        dockTableLoader ? (
                                            <Grid
                                                data-testid="loader"
                                                container
                                                direction={"column"}
                                                justifyContent={"center"}
                                                alignItems="center"
                                                sx={{ p: 10 }}
                                            >
                                                <Grid item>
                                                    <GenericLoader />
                                                </Grid>
                                            </Grid>
                                        ) :
                                        <>
                                            <Grid container spacing={1} xs={12} data-testid="filters" sx={{ mb: 2 }}>
                                                {renderHeadersFilter(tableHeader?.validHeader?.filter(dl => dl.isSearchable && dl?.mappingPath), filter, setFilter, setDockTablePage, setDockTableLimit, tabIndex)}
                                                {isFilterApplied && (
                                                    <Grid container item xs={"auto"} alignItems={"center"}>
                                                        <Typography
                                                            sx={{
                                                                ...textClasses.boldText,
                                                                color: theme.themeOrange,
                                                                textDecoration: "underline",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={handleClearFilter}
                                                        >
                                                            Clear Filter(s)
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                        <Grid container item sx={{ mb: 1 }} xs={"auto"} alignItems={"center"} data-testid="customise-table">
                                                            <GenericCustomiseHeader
                                                                tableName={"dockMappingTable"}
                                                                tableType={"dockMappingTable"}
                                                            />
                                                        </Grid>
                                                        {selectedEntries?.length > 0 &&
                                                            <Grid container sx={{ mt: 2, textTransform: "none" }}>
                                                                {selectedEntries?.length == 1 && (
                                                                    <>
                                                                        {permissions &&
                                                                            permissions?.permissions?.some((item) => item.permissionId === "updateDockMapping") ? (
                                                                            <Button
                                                                                size="small"
                                                                                variant="contained"
                                                                                sx={{
                                                                                    ...buttonClasses.lynkitOrangeFill,
                                                                                    mr: 1,
                                                                                    textTransform: "none",
                                                                                }}
                                                                                onClick={() => {
                                                                                    // console.log("selectedEntries", selectedEntries);
                                                                                    const obj = {
                                                                                        areaTypeData: [{
                                                                                            cellId: selectedEntries[0].dockId,
                                                                                            renderId: nanoid(),
                                                                                            selectType: "block",
                                                                                            name: selectedEntries[0].dockName,
                                                                                            actionType: "addNew",
                                                                                        }],
                                                                                        floorId: selectedEntries[0].floorId,
                                                                                        warehouseId: selectedEntries[0].warehouseId,
                                                                                        type: "wms",
                                                                                    }
                                                                                    setSelectedRack(obj);
                                                                                    showAllocateModel(statusFilter?.value === "dockStorageMapping" ? "dockAllocationModal" : "areaAllocationModal")
                                                                                }
                                                                                }
                                                                            >
                                                                                Edit
                                                                            </Button>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </>
                                                                )}
                                                                {permissions?.permissions?.some((item) => item.permissionId === "deleteDockMapping") && (
                                                                    <>
                                                                        {
                                                                            (
                                                                                <Button
                                                                                    data-testid="enable-btn"
                                                                                    size="small"
                                                                                    variant="contained"
                                                                                    sx={{
                                                                                        ...buttonClasses.lynkitOrangeFill,
                                                                                        mr: 1,
                                                                                        textTransform: "none",
                                                                                    }}
                                                                                    onClick={() => setConfirmModal("delete")}
                                                                                >
                                                                                    Delete
                                                                                </Button>
                                                                            )}
                                                                    </>
                                                                )
                                                                }
                                                    </Grid>
                                                }
                                            </Grid>
                                                    <GenericTable
                                                        data-testid="skuGroupTable"
                                                        header={header}
                                                        rows={tableRows}
                                                        pageCount={mappedDockData?.totalCount}
                                                        pageNumber={dockTablePage}
                                                        handleChangePage={(event, pagevalue) => {
                                                            setDockTablePage(pagevalue);
                                                        }}
                                                        handleChangeRowsPerPage={(event) => {
                                                            setDockTableLimit(+event.target.value);
                                                        }}
                                                        rowsPerPage={dockTableLimit}
                                                    />
                                                    <ConfirmDialogPopUp
                                                        status={confirmModal}
                                                        handleClose={handleCloseConfirmModal}
                                                        selectedEntries={selectedEntries}
                                                        setSelectedEntries={setSelectedEntries}
                                                        refreshTableData={() => {
                                                            refreshTableData(dockTablePage + 1, dockTableLimit)
                                                            // setTabIndex(0)
                                                        }}
                                                        handleRemoveSelection={handleRemoveSelection}
                                                    />
                                                    <ConfirmDialogPopUp
                                                        status={actionConfirmModal}
                                                        handleClose={handleCloseAction}
                                                        selectedEntries={
                                                            actionIndex != null ? [mappedDockData?.tableData[actionIndex]] : []
                                                        }
                                                        refreshTableData={() => {
                                                            refreshTableData(dockTablePage + 1, dockTableLimit)
                                                            // setTabIndex(0)
                                                        }}
                                                    />
                                              {actionIndex != null && (
                                                                        <Menu
                                                                            id="basic-menu"
                                                                            anchorEl={anchorEl}
                                                                            open={actionIndex != null}
                                                                            onClose={handleCloseAction}
                                                                            MenuListProps={{
                                                                                "aria-labelledby": "basic-button",
                                                                            }}
                                                                            sx={{
                                                                                display: actionIndex != null ? "block !important" : "none !important",
                                                                            }}
                                                                        >
                                                                            {permissions &&
                                                                                (permissions.permissions || []).some(
                                                                                    (item) => item.permissionId === "createDockMapping" || item.permissionId === "updateDockMapping"
                                                                                )&& (
                                                                                    <MenuItem
                                                                                        key={1}
                                                                                        sx={{ ...textClasses.normalText }}

                                                                    onClick={() => {
                                                                        const selectedEntry = mappedDockData?.tableData[actionIndex] || {};

                                                                        const obj = {
                                                                            areaTypeData: selectedEntry.dockId
                                                                                ? [{
                                                                                    cellId: selectedEntry.dockId,
                                                                                    renderId: nanoid(),
                                                                                    selectType: "block",
                                                                                    name: selectedEntry.dockName,
                                                                                    actionType: "addNew",
                                                                                }]
                                                                                : [],
                                                                            floorId: selectedEntry.floorId || null,
                                                                            warehouseId: selectedEntry.warehouseId || null,
                                                                            type: "wms",
                                                                        };
                                                                        setSelectedRack(obj);
                                                                        showAllocateModel(statusFilter?.value === "dockStorageMapping" ? "dockAllocationModal" : "areaAllocationModal")
                                                                    }}
                                                                >
                                                                                        Edit
                                                                                    </MenuItem>
                                                                                )}
                                                                            {permissions &&
                                                                                (permissions.permissions || []).some(
                                                                                    (item) => item.permissionId === "deleteDockMapping"
                                                                                ) && (
                                                                                    <MenuItem
                                                                                        key={1}
                                                                                        sx={{ ...textClasses.normalText }}
                                                                                        onClick={() => setActionConfirmModal("delete")}
                                                                                    >
                                                                                        Delete
                                                                                    </MenuItem>
                                                                                )}
                                                                        </Menu>
                                                                    )}
                                        </>
                                        : null
                                    }
                                    {statusFilter?.value === "materialMapping" &&
                                        <MapTable
                                            tableData={allMappedArea}
                                            pageCount={pageCount}
                                            tableLoader={tableLoader}
                                            setTableLoader={setTableLoader}
                                            rowsPerPage={pageLimits}
                                            pageNumber={(pageNumber - 1)}
                                            handleRefreshTable={handleRefreshTable}
                                            handleChangePage={(event, pagevalue) => {
                                                setPageNumber(pagevalue + 1);
                                                onFetchPage((pagevalue + 1), pageLimits)
                                            }}
                                            handleChangeRowsPerPage={(event) => {
                                                setPageLimits(event.target.value)
                                                onFetchPage(pageNumber, event.target.value)
                                            }}
                                        />
                                    }
                                </>
                            }
                        </Box>
                    </Box>
                </Box>) : 
                
                    <BlankScreen />
                
             }

            
            {allocateModelVisible=="areaAllocationModal" && <AllocateModel
                show={allocateModelVisible}
                onClose={() => closeAllocateModel()}
                selectedRackData={selectedRack}
                tableLoader={tableLoader}
                setTableLoader={setTableLoader}
                onSuccess={handleRefreshTable}
            />}
            {allocateModelVisible=="dockAllocationModal" && <DockAllocateModal
                show={allocateModelVisible}
                onClose={() => closeAllocateModel()}
                selectedRackData={selectedRack}
                tableLoader={tableLoader}
                setSelectedEntries={setSelectedEntries}
                setTableLoader={setTableLoader}
                setActionIndex={setActionIndex}
                onSuccess={handleRefreshTable}
            />}

        </>
    )
}
export default AreaMapping;
