import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import {
  Grid,
  Button,
  TextField,
  MenuItem,
  Typography,
  Checkbox,
  Paper,
  Menu,
  Autocomplete,
} from "@mui/material";
import {
  Add,
  KeyboardArrowDown,
} from "@mui/icons-material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsers,
  getAllInHousePalletTableData,
  getInHousePalletizationStatusWiseCount,
  getInHouseDePalletizationStatusWiseCount,
  getDeKitDetails,
  getAllInHouseDePalletTableData,
  getAllWarehouses,
  getInHousePalletizationCardTypeWiseCount,
} from "../../../../redux/actions";
import theme, {
  buttonClasses,
  inputClasses,
  textClasses,
  cardClasses,
} from "../../../../static/styles/theme"
import { Link } from "react-router-dom";
// import {
//   capitalizeSentence,
//   getCamelCaseText,
//   getShortText,
//   showToast,
// } from "../../../../services/functions";
import GenericPageConfig from "../../../Components/Generic/GenericPageConfig";
import { useForm } from "react-hook-form";
import history from "../../../../services/history";
import { renderHeadersFilter } from "../../../../services/functions";
import GenericCustomiseHeader from "../../../Components/Generic/GenericCustomiseHeader";
import GenericTable from "../../../Components/CustomTable/GenericTable";
import { useDebounce } from "../../../../utils/hooks";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import ViewDePalletDetail from "./DismantlePallet/ViewDismantlePalletDetail";
import { multiDateToISOConvertor } from "../../../Components/Generic/GenericMultiDateRange";
import ApproveRejectConfirmationDialog from "./ApproveRejectConfirmationDialog";
import CreateDePallet from "./DismantlePallet/CreateDismantlePallet";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


export const getSpentTime = (then, now) => {
  if (then != null) {
    then = moment(then);
    now = now ? moment(now) : moment();

    let dayDiff = now.diff(then, "days");
    let hourDiff = now.diff(then, "hours");
    let minDiff = now.diff(then, "minutes");
    let timeSpent;

    if (dayDiff < 1) {
      if (hourDiff < 1) {
        timeSpent = `${minDiff} min ago`;
      } else {
        timeSpent = `${hourDiff} hrs ago`;
      }
    } else {
      timeSpent = `${dayDiff} d ago`;
    }
    return timeSpent;
  } else {
    return "-";
  }
};

let initial_state = {
  "search": {},
  "other": {},
}

export default function InHousePalletization() {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [cardType, setCardType] = useState("palletization")
  const [operationType, setOperationType] = useState("kit")
  const { pageTitle = "Manage Pallet(s)" } = useSelector(state => state?.DynamicPageConfig?.inHousePalletizationPage) || {};
  const palletTableData = useSelector((state) => cardType === "palletization" ? state.InHousePalletization.inHousePalletizationTableData : state.InHousePalletization.inHouseDePalletizationTableData)
  const allWarehouses = useSelector((state) => state.inward.allWarehouses);
  const currPalletTableDataRows = useSelector((state) => state.InHousePalletization.totalPalletTableDataRows)
  const currDePalletTableDataRows = useSelector((state) => state.InHousePalletization.totalDePalletTableDataRows)
  const inHousePalletizationStatusCountData = useSelector((state) => cardType === "palletization" ? state.InHousePalletization.inHousePalletizationStatusWiseCount : state.InHousePalletization.inHouseDePalletizationStatusWiseCount);
  const totalKitRows = useSelector((state) => state.Kit.inHousePalletizationStatusCountData?.totalCount)
  const totalDekitRows = useSelector((state) => state.Dekit.inHousePalletizationStatusCountData?.totalCount)
  const [inHousePalletizationCardTypeWiseCount, setInHousePalletizationCardTypeWiseCount] = useState({})
  const [tabIndex, setTabIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loader, setLoader] = useState(false);
  const [statusFilter, setStatusFilter] = useState(null);
  const [initialRender, setInitialRender] = useState(true)
  const [filter, setFilter] = useState(initial_state)
  const [warehouseFilter, setWarehouseFilter] = useState(null);

  const isFilterApplied = (() => {
    // console.log("filter", filter,statusFilter)
    if (tabIndex == 0 && ((statusFilter?.value && statusFilter?.value != "all") || (filter?.other?.status && filter?.other?.status != "all"))) {
      return true;
    }
    let { status, ...restOther } = { ...filter["other"] || {} }
    // delete other["status"]
    if (Object.values(restOther || {})?.some(dl => dl && (typeof dl == "object" && !Array.isArray(dl) ? dl?.value : dl.length > 0)) || Object.values(filter["search"]).some(dl => dl && (typeof dl == "object" && !Array.isArray(dl) ? dl?.value : dl.length > 0))) {
      return true;
    }
    return false;
  })();

  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState(null);
  const openAction = Boolean(anchorEl);

  const { userPermissions } = useSelector((state) => state.userPermission);
  let permissions = userPermissions || {};

  const handleOpenAction = (event, i) => {
    setAnchorEl(event.currentTarget);
    setActionIndex(i);
  };
  const handleCloseAction = () => {
    setActionConfirmModal(null);
    setActionIndex(null);
    if (setSelectedEntries) {
      setSelectedEntries([]);
    }
    setTimeout(() => {
      setAnchorEl(null);
    }, 1);
  };
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [confirmModal, setConfirmModal] = useState(null);
  const [actionConfirmModal, setActionConfirmModal] = useState(null);
  const [actionReceiveModal, setActionReceiveModal] = useState(null);
  const [viewModal, setViewModal] = useState({ type: "", action: "" })
  const [viewDePalletDetailPage, setViewDePalletDetailPage] = useState(null)
  const debouncedFilterSearch = useDebounce(filter?.search, 500);
  const handleTypeChange = (type) => {
    setSelectedEntries([])
    setCardType(type);
    // setSelectedRow(null);
    setPage(0);
    setLimit(10);
    setInitialRender(true);
    // setOperationType(type);
    setStatusFilter(null)
    setTabIndex(0)
    setFilter(initial_state)
    setViewDePalletDetailPage(null)
    setWarehouseFilter(null);
    // setViewAdminWorkerDetail(false)
  };
  const handleTabChange = (value, statusObject = {}) => {
    setStatusFilter(statusObject)
    setPage(0);
    setTabIndex(value);
    setSelectedEntries([])
    // setViewAdminWorkerDetail(false)
    refreshTableData(1, limit, value && statusObject?.value || "", false, value)

  };


  // Check if only one checkbox is selected and its status is either 'pending' or 'partialApproved' for Approve button
  const approveCondition =
    selectedEntries.length >= 1 &&
    selectedEntries.every(entry => entry.warehouseId === selectedEntries[0].warehouseId) &&
    ["pending", "partialApproved"].includes(selectedEntries[0].status);

  // Check if only one checkbox is selected and its status is 'pending' for Reject button
  const rejectCondition = selectedEntries.length >= 1 && selectedEntries[0].status === "pending";

  const handleCheckAll = () => {
    if (selectedEntries.length < palletTableData?.length) {
      const sT = palletTableData?.map((t) => ({
        _id: t._id,
        status: t.status,
        name: t.name,
        ...(operationType === "kit"
          ? { KIT_ID: t.KIT_ID, totalQuantity: t.totalQuantity }
          : { itemId: t.itemId })
      }));
      setSelectedEntries(sT);
    } else {
      setSelectedEntries([]);
    }
  };


  const tableHeader = useSelector((state) => cardType === "palletization" ? state.customHeader["pallatizationTable"] : state.customHeader["dePalletizationTable"]) || {};
  const getTableCell = (header, row, i) => {

    if (header?.handleType) {
      if (header?.handleType === "selectRow") {
        return (
          <Checkbox
            size="small"
            checked={selectedEntries.map((o) => o._id).indexOf(row._id) >= 0}
            onChange={(e) => handleCheckChange(e, row)}
          />
        );
      } else if (header?.handleType === "showDetail") {
        return (
          cardType === "palletization" ? (
            <Link
              to={`/dashboard/data/view-pallet/${row.Pallet_ID}`}
              style={{
                ...textClasses.normalText,
                textDecoration: "none",
                color: theme.themeOrange,
                textAlign: "center",
              }}
            >
              {row[header?.mappingPath] ?? "-"}
            </Link>
          ) : (
            <Typography
              onClick={() => setViewDePalletDetailPage(row)}
              style={{
                ...textClasses.normalText,
                color: theme.themeOrange,
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              {row[header?.mappingPath] ?? "-"}
            </Typography>
          )
        );
      }
      else if (header?.handleType === "viewComponents") {
        return (
          row[header?.mappingPath]?.length > 0 ? <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, cursor: "pointer" }}
            onClick={() => {
              dispatch(
                getDeKitDetails({ kitId: row?.itemId, componentId: row?._id }, (data) => {
                  if (data) {
                    setViewModal({ ...viewModal, type: header?.handleType, action: data?.components || [] })
                  }
                })
              )

            }}>
            {row[header?.mappingPath]
              ? Array.isArray(row[header?.mappingPath])
                ? row[header?.mappingPath]?.length > 0 && row[header?.mappingPath].join(", ") || "-"
                : row[header?.mappingPath]
              : "-"
            }
          </Typography> : "-"
        )
      }
      else if (header?.handleType === "action") {
        return (
          <Typography
            data-testid="dropdown"
            sx={{
              ...textClasses.normalText,
              color: theme.themeOrange,
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenAction(e, i)}
          >
            Action <KeyboardArrowDown />
          </Typography>
        );
      }
    }
    else if (header?.isFromRenderFunction === true) {
      let no;
      if (page === 0) {
        no = i + 1;
      } else {
        no = page * 10 + (i + 1);
      }
      if (header?.renderFunction === "srNo") {
        return (
          <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
            {no}
          </Typography>
        );
      }
      else if (header?.renderFunction === "status") {
        const statusColor = {
          pending: "#FF7200",
          partialapproved: '#FF7200',
          approved: "#3E621E",
          completed: "#3E621E",
          drafted: "#FF0101",
          rejected: "#FF0101",
          default: "#000",
          completed: "#3E621E",
          inprogress: "#FF7200",
        };
        const statusText = {
          pending: "Pending",
          partialapproved: "Partial-Approved",
          approved: "Approved",
          completed: "Completed",
          drafted: "Drafted",
          rejected: "Rejected",
          completed: "Completed",
          inprogress: "Inprogress",
        };

        return (
          <>
            {["pending", "partialApproved", "approved", "completed", "drafted", "rejected", "completed", "inprogress"].includes(row[header?.mappingPath]) && (
              <Typography
                sx={{
                  ...textClasses.normalText,
                  textAlign: "center",
                  color: statusColor[row[header?.mappingPath] ? row[header?.mappingPath].toLowerCase() : "default"],
                }}
              >
                {row[header?.mappingPath] ? statusText[row[header?.mappingPath].toLowerCase()] : "-"}
              </Typography>
            )}
          </>
        );
      }
    }
    else {
      if (header?.fieldType === "array") {
        return (
          <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
            {header.mappingPath && row[header.mappingPath]
              ? Array.isArray(row[header.mappingPath])
                ? row[header.mappingPath].join(", ")
                : row[header.mappingPath]
              : "-"}
          </Typography>
        );
      }
      else if (header?.fieldType === "date") {
        return (
          <Typography
            sx={{
              ...textClasses.normalText,
              textAlign: "center",
              textTransform: "none",
            }}
          >
            {header.mappingPath && row[header.mappingPath]
              ? moment(row[header.mappingPath]).format(
                header?.format || "DD MMM YYYY hh:mm A"
              )
              : "-"}
          </Typography>
        );
      }
      else {
        return (
          <Typography
            sx={{
              ...textClasses.normalText,
              textAlign: "center",
              textTransform: "none",
            }}
          >
            {header.mappingPath ? row[header.mappingPath] : "-"}
          </Typography>
        );
      }
    }
  };


  let header = tableHeader && tableHeader?.validHeader && tableHeader.validHeader.map((h) => {
    if (h.handleType === "selectRow") {
      return (
        <Checkbox
          data-testid="checkbox"
          checked={
            palletTableData?.length != 0 &&
            selectedEntries.length == palletTableData?.length
          }
          onChange={handleCheckAll}
          size="small"
        />
      )
    }
    return h.fieldName
  })
    || [];

  const tableRows = palletTableData?.map((row, i) => {
    let items = [];

    const validHeaders = tableHeader?.validHeader ?? [];
    for (const header of validHeaders) {
      items.push(getTableCell(header, row, i));
    }
    return items;
  });


  const handleCheckChange = (event, row) => {
    if (event.target.checked) {
      handleAddSelection(row);
    } else {
      handleRemoveSelection(row._id);
    }
  };


  const handleAddSelection = (obj) => {
    setSelectedEntries([...selectedEntries, obj]);
  };

  const handleRemoveSelection = (id) => {
    let oldEntries = [...selectedEntries];
    const i = oldEntries.map((o) => o._id).indexOf(id);
    if (i >= 0) {
      oldEntries.splice(i, 1);
      setSelectedEntries(oldEntries);
      if (oldEntries.length == 0) {
        handleCloseConfirmModal();
      }
    }
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(null);
  };

  const handleCloseActionReceiveModal = () => {
    setActionReceiveModal(null);
  };

  const handleClearFilter = () => {
    setFilter(initial_state)
    setPage(0);
    setLimit(10);
    if (tabIndex == 0) {
      setStatusFilter(null);
      // setFilter(initial_state)

    }
  };

  const handleOperationTypeChange = (type) => {
    setOperationType(type);
    setStatusFilter(null)
    setTabIndex(0)
    setFilter(initial_state)
    setPage(0);
    setLimit(10);
    setSelectedEntries([])
    setViewDePalletDetailPage(null)
    setWarehouseFilter(null);
  };

  useEffect(() => {
    dispatch(getAllUsers({ "isAdminIncluded": true }));
  }, [])

  const searchableFilterItems = useMemo(() => {
    if (!tableHeader || !tableHeader.validHeader) return [];
    return tableHeader.validHeader.filter((dl) => dl?.isSearchable) || [];
  }, [tableHeader]);


  const refreshTableData = (pageNo, limitNo, status = "", fetchCount = true) => {
    setLoader(true);
    const payload = {
      page: pageNo,
      limit: limitNo,
      warehouseId: warehouseFilter ? warehouseFilter._id : "",
      status: status || ""
    };

    let allDynamicKeys = tableHeader?.validHeader?.filter(dl => dl?.dynamic)?.map(dl => dl?.payloadKey || dl?.mappingPath)
    if (filter && Object.keys(filter || {}).length > 0) {
      for (let key in filter) {
        if (key && Object.keys(filter[key] || {}).length > 0) {
          for (let filterKey in filter[key]) {
            // if (filterKey && Object.keys(filter[key][filterKey] || {}).length > 0) {
            if (allDynamicKeys?.includes(filterKey)) {
              // Object.assign(payload, filter[key][filterKey])
              payload[filterKey] = filter[key][filterKey]?.value || ""

            } else {
              payload[filterKey] = filter[key][filterKey] || ""
            }
          }
        }
      }
      // Process date filter items
      const dateFilterItems = searchableFilterItems?.filter(
        (item) => item?.searchInputType === "date"
      );
      dateFilterItems.forEach((element) => {
        const payloadKey = element?.payloadKey || element?.mappingPath || "";
        if (payloadKey && payload[payloadKey]) {
          payload[payloadKey] = multiDateToISOConvertor(payload[payloadKey]);
        }
      });
    }
    if (fetchCount) {
      dispatch(getInHouseDePalletizationStatusWiseCount({ ...payload, status: "" }));
    }
    dispatch(getInHousePalletizationCardTypeWiseCount({ warehouseId: warehouseFilter ? warehouseFilter._id : "" }, (data) => {
      setInHousePalletizationCardTypeWiseCount(data?.data)
    }))
    if (cardType == "palletization") {
      dispatch(
        getAllInHousePalletTableData(payload, () => {
          setLoader(false);
        })
      );
      dispatch(getInHousePalletizationStatusWiseCount({ ...payload, status: "" }));
    } else {
      dispatch(getAllInHouseDePalletTableData(payload, () => {
        setLoader(false);
      })
      );
      dispatch(getInHouseDePalletizationStatusWiseCount({ ...payload, status: "" }));
    }
  };

  let fetchData = (fetchCount = false) => {
    refreshTableData(page + 1, limit, statusFilter?.value || "", fetchCount);
    setConfirmModal(null);
    setActionConfirmModal(null);
    setAnchorEl(null);
    setActionIndex(null);
  };

  useEffect(() => {

    fetchData(initialRender);
    if (initialRender) {
      setInitialRender(false)
    }
  }, [page, limit, filter.other, debouncedFilterSearch, cardType, warehouseFilter]);

  useEffect(() => {
    dispatch(getAllWarehouses({ type: "warehouse" }));
  }, [])

  return (
    <>
      {
        <>
          <Grid container>
            <Grid item sm={6}>
              <Typography sx={{ ...textClasses.cardTitle }} role="heading">
                {pageTitle}
              </Typography>
            </Grid>
            <Grid container item sm={6} justifyContent="end" alignItems="end">
              <Autocomplete
                value={warehouseFilter}
                onChange={(e, option) => {
                  setWarehouseFilter(option);
                  setPage(0);
                  setLimit(10);
                }}
                getOptionLabel={(option) => option.name}
                size="small"
                options={allWarehouses}
                sx={{
                  ...inputClasses.filterField,
                  minWidth: "250px",
                  mr: 1,
                  ".MuiInputBase-input": {
                    fontSize: "15px",
                    color: "#455A64",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ color: "#455A64", ...textClasses.normalText }}
                    {...params}
                    label="All Warehouse"
                  />
                )}
              />
              {cardType === "palletization" ? (
                permissions &&
                (permissions.permissions || []).some(
                  (item) => item.permissionId === "createInhousePallet"
                ) && (
                  <Button
                    data-testid="create-new-kit"
                    // variant="outlined"
                    size="small"
                    startIcon={<Add />}
                    sx={{ ...buttonClasses.lynkitOrangeEmpty }}
                    onClick={() => history.push("/dashboard/data/inhouse-palletization")}
                  >
                    Add New Pallet
                  </Button>
                )
              ) : (
                permissions &&
                (permissions.permissions || []).some(
                  (item) => item.permissionId === "dismantlePalletInitiation"
                ) && (
                  <Button
                    data-testid="create-dekit"
                    // variant="outlined"
                    size="small"
                    startIcon={<Add />}
                    sx={{ ...buttonClasses.lynkitOrangeEmpty }}
                    onClick={() => {
                      setViewModal({ ...viewModal, type: "createDePalletRequest", action: '' })
                    }}
                  >
                    Create De-Pallet Request
                  </Button>
                )
              )}
            </Grid>
          </Grid>

          <GenericPageConfig
            storedInPage={"inHousePalletizationPage"}
            pageName={"inHousePalletization"}
            pageTabCount={inHousePalletizationCardTypeWiseCount}
            pageStatusCount={inHousePalletizationStatusCountData}
            handlePageTabChange={handleTypeChange}
            selectedPageTab={cardType}
            selectedStatusTab={tabIndex}
            handleStatusTabChange={handleTabChange}
            disabledPageTabs={loader}
            disabledStatusTabs={loader}
          />

          <Grid container spacing={1} xs={12} sx={{ mt: 2 }}>
            {renderHeadersFilter(tableHeader?.validHeader?.filter(dl => dl.isSearchable && dl?.mappingPath), filter, setFilter, setPage, setLimit, tabIndex)}
            {isFilterApplied && (
              <Grid container item sx={2} alignItems={"center"}>
                <Typography
                  sx={{
                    ...textClasses.boldText,
                    color: theme.themeOrange,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={handleClearFilter}
                >
                  Clear Filter(s)
                </Typography>
              </Grid>
            )}
            <Grid container item xs={"auto"} alignItems={"center"}>
              <GenericCustomiseHeader
                tableName={cardType == "palletization" ? "pallatizationTable" : "dePalletizationTable"}
                tableType={cardType == "palletization" ? "pallatizationTable" : "dePalletizationTable"}
              />
            </Grid>
          </Grid>
          {selectedEntries.length > 0 && (
            <Grid container sx={{ mt: 2, textTransform: "none" }}>
              {selectedEntries.length == 1 && (
                <>
                  {permissions &&
                    (permissions.permissions || []).some(
                      (item => ["viewInhousePallet", "viewDismantleInhousePallet"].includes(item.permissionId))
                    ) ? (
                    <Button
                      data-testid="view-btn"
                      size="small"
                      variant="contained"
                      sx={{
                        ...buttonClasses.lynkitOrangeFill,
                        textTransform: "none",
                      }}
                      onClick={() =>
                        cardType === "palletization" ?
                          history.push(
                            `/dashboard/data/view-pallet/${selectedEntries[0]?.Pallet_ID}`
                          ) : setViewDePalletDetailPage(selectedEntries[0])
                      }
                    >
                      View
                    </Button>
                  ) : (
                    ""
                  )}

                </>
              )}
              {selectedEntries.length == 1 && (
                <>
                  {permissions &&
                    (permissions.permissions || []).some(
                      (item => ["editInhousePallet"].includes(item.permissionId)))
                      &&
                  (["pending"].includes(selectedEntries[0]?.status)
                    ) ? (
                    <Button
                      data-testid="view-btn"
                      size="small"
                      variant="contained"
                      sx={{
                        ...buttonClasses.lynkitOrangeFill,
                        textTransform: "none",
                        ml: 1,
                        mr: 1
                      }}
                      onClick={() =>
                        history.push(
                          `/dashboard/data/edit-pallet/${selectedEntries[0]?._id}`
                        )
                      }
                    >
                      Edit
                    </Button>
                  ) : (
                    ""
                  )}

                </>
              )}
              {permissions &&
                (permissions.permissions || []).some(
                  (item => ["palletizationApproval", "dismantlePalletApproval"].includes(item.permissionId))
                ) ? <>
                {
                  approveCondition && (
                    <Button
                      data-testid="approve-btn"
                      variant="outlined"
                      sx={{
                        ...buttonClasses.approvedButton,
                        ml: 1,
                        mr: 1
                      }}
                      onClick={() => setConfirmModal("approved")}
                    >Mark As Approved</Button>
                  )}</> : (
                ""
              )
              }
              {permissions &&
                (permissions.permissions || []).some(
                  (item => ["palletizationApproval", "dismantlePalletApproval"].includes(item.permissionId))
                ) ? <>

                {rejectCondition && (
                  <Button
                    data-testid="reject-btn"
                    size="small"
                    variant="outlined"
                    sx={{
                      ...buttonClasses.lynkitOrangeFill,
                      backgroundColor: "#E9001A",
                      border: "1px solid #E9001A",
                      mr: 1,
                    }}
                    onClick={() => setConfirmModal("rejected")}
                  >
                    Reject
                  </Button>
                )}</> : (
                ""
              )
              }
              {permissions?.permissions?.some((item) => item.permissionId === "startPartialBundling") &&
                selectedEntries?.length == 1 && (["approved", "partialApproved"].includes(selectedEntries[0].status) && !["completed"].includes(selectedEntries[0].jobStatus)) && selectedEntries[0]?.availableForBundeling
                && cardType == "palletization"
                ? <Button
                  data-testid="startBundling-btn"
                  size="small"
                  variant="outlined"
                  sx={{
                    ...buttonClasses.lynkitOrangeFill,
                    mr: 1,
                  }}
                  onClick={() => {
                    setViewModal({ ...viewModal, type: "startBundling", action: selectedEntries?.at(0) })
                  }}
                >
                  Start Bundling
                </Button> :
                ""
              }
            </Grid>
          )}

          <Grid container sx={{ mt: 2 }}>
            <Grid item sm={viewDePalletDetailPage ? 8 : 12} xs={12}>
              <Paper>
                {loader ? (
                  <Grid
                    data-testid="loader"
                    container
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems="center"
                    sx={{ p: 10 }}
                  >
                    <Grid item>
                      <GenericLoader />
                    </Grid>
                  </Grid>
                ) : (
                  <GenericTable
                    data-testid="kittingTable"
                    header={header}
                    rows={tableRows}
                    pageCount={cardType == "palletization" ? currPalletTableDataRows : currDePalletTableDataRows}
                    pageNumber={page}
                    handleChangePage={(event, pagevalue) => {
                      setPage(pagevalue);
                    }}
                    handleChangeRowsPerPage={(event) => {
                      setLimit(+event.target.value);
                    }}
                    rowsPerPage={limit}
                  />
                )}
              </Paper>
            </Grid>
            {actionIndex != null && (
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={actionIndex != null}
                onClose={handleCloseAction}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  display: actionIndex != null ? "block !important" : "none !important",
                }}
              >
                {permissions &&
                  (permissions.permissions || []).some(
                    (item) => ["viewInhousePallet", "viewDismantleInhousePallet"].includes(item.permissionId)
                  ) ? (
                  <MenuItem
                    key={1}
                    sx={{ ...textClasses.normalText }}
                    onClick={() => {
                      if (palletTableData?.[actionIndex]) {
                        cardType === "palletization"
                          ? history.push(
                            `/dashboard/data/view-pallet/${palletTableData[actionIndex]?.Pallet_ID}`
                          )
                          : setViewDePalletDetailPage(palletTableData[actionIndex]);
                      }
                      handleCloseAction();
                    }}
                  >
                    View
                  </MenuItem>
                ) : (
                  ""
                )}
                {(permissions?.permissions?.some((item) => item.permissionId === "editInhousePallet") &&
                  (["pending"].includes(palletTableData[actionIndex].status)
                  ))
                  && (
                    <MenuItem
                      key={0}
                      sx={{ ...textClasses.normalText }}
                      onClick={() =>
                        history.push(
                          `/dashboard/data/edit-pallet/${palletTableData[actionIndex]?._id}`
                        )
                      }
                    >
                      Edit
                    </MenuItem>
                  )}

                {permissions &&
                  permissions?.permissions?.some((item) =>
                    ["palletizationApproval", "dismantlePalletApproval"].includes(item.permissionId)
                  ) &&
                  ["pending", "partialApproved"].includes(palletTableData?.[actionIndex]?.status) && (
                    <MenuItem
                      key={2}
                      sx={{ ...textClasses.normalText }}
                      onClick={() => setActionConfirmModal("approved")}
                    >
                      Mark As Approved
                    </MenuItem>
                  )}

                {permissions &&
                  permissions?.permissions?.some((item) =>
                    ["palletizationApproval", "dismantlePalletApproval"].includes(item.permissionId)
                  ) &&
                  palletTableData?.[actionIndex]?.status === "pending" && (
                    <MenuItem
                      key={3}
                      sx={{ ...textClasses.normalText }}
                      onClick={() => setActionConfirmModal("rejected")}
                    >
                      Reject
                    </MenuItem>
                  )}

                {palletTableData?.[actionIndex]?.status === "approved" &&
                  !["completed"]?.includes(palletTableData?.[actionIndex]?.jobStatus) &&
                  !!palletTableData?.[actionIndex]?.availableForBundeling && (
                    <MenuItem
                      key={4}
                      sx={{ ...textClasses.normalText }}
                      onClick={() => {
                        setViewModal({
                          ...viewModal,
                          type: "startBundling",
                          action: palletTableData[actionIndex],
                        });
                        handleCloseAction();
                      }}
                    >
                      Start Bundling
                    </MenuItem>
                  )}
              </Menu>
            )}

            <ApproveRejectConfirmationDialog
              status={confirmModal}
              handleClose={handleCloseConfirmModal}
              selectedEntries={selectedEntries}
              setSelectedEntries={setSelectedEntries}
              refreshTableData={() => {
                refreshTableData(page + 1, limit)
                setTabIndex(0)
              }}
              handleRemoveSelection={handleRemoveSelection}
              cardType={cardType}
            />

            {viewModal.type == "startBundling" &&
              <BundlingModal
                viewModal={viewModal}
                setViewModal={setViewModal}
                setSelectedEntries={setSelectedEntries}
                refreshTableData={() => {
                  refreshTableData(page + 1, limit)
                  setTabIndex(0)
                }}
              />
            }
            {viewModal.type == "createDePalletRequest" &&
              <CreateDePallet
                viewModal={viewModal}
                setViewModal={setViewModal}
              //  setSelectedEntries={setSelectedEntries}
              //  refreshTableData={() => refreshTableData(page + 1, limit)}
              />
            }
            {viewModal.type === "viewComponents" &&
              <ViewDekitSkuComponents
                viewModal={viewModal}
                setViewModal={setViewModal}
              />
            }
            {
              viewDePalletDetailPage && (
                <Grid item sm={4} xs={12} sx={{ p: 1, pt: 0 }}>
                  <ViewDePalletDetail
                    cardType={cardType}
                    viewDePalletDetailPage={viewDePalletDetailPage}
                    setViewDePalletDetailPage={setViewDePalletDetailPage}
                  />
                </Grid>
              )
            }
          </Grid>
          <ApproveRejectConfirmationDialog
            status={actionConfirmModal}
            cardType={cardType}
            handleClose={handleCloseAction}
            selectedEntries={
              actionIndex != null ? [palletTableData[actionIndex]] : []
            }
            refreshTableData={() => refreshTableData(page + 1, limit)}
          />
        </>
      }
    </>
  );
}
