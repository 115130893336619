import React, { useEffect, useState } from "react";
import AlertDialog from "../../../Components/DialogBox/dialogbox";
import { Grid, Box, Typography, Button, FormControl, OutlinedInput, IconButton, InputAdornment, Checkbox } from "@mui/material";
import theme, { textClasses, buttonClasses, inputClasses } from "../../../../static/styles/theme";
import { Clear, Search, Delete } from "@mui/icons-material";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import GenericTable from "../../../Components/CustomTable/GenericTable";
import GenericSingleRangeComponent from "../../../Components/Generic/GenericSingleRangeComponent";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../../../utils/hooks";
import { endJobFunction,getJobShipmentDetails } from "../.././../../redux/actions";
import { showToast } from "../../../../services/functions";

const StartJobDispatch = ({ checkedRow, setCheckedRow, initFilters, get_Job_All }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [pagination, setPagination] = useState({ page: 0, limit: 100 })
  const [jobInputId, setJobInputId] = useState(checkedRow?.selectedRow[0]?.groupBy)
  const [tableData, setTableData] = useState([])
  const [tableDataCount, setTableDataCount] = useState(0)
  const [shipmentFilter, setShipmentFilter] = useState("");
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [dataReceieved, isDataReceived] = useState(false)
  const debouncedShipmentFilter = useDebounce(shipmentFilter, 500)
  const handleInputChange = (e) => {
    setJobInputId(e.target.value)
    // setFilter({ ...filter, page: 0 })
  }
  const handleShipmentFilterChange = (event) => {
    setShipmentFilter(event.target.value);
  }
  const handleCheckAll = () => {
    if (selectedEntries.length < tableData?.length) {
      let sT = tableData?.map((t) => ({
        shipmentId: t.entityId,
        cellId: t.cellId,
        rackId: t.rackId,
        count: t.selectedCount,

      }));
      setSelectedEntries(sT);
    } else {
      setSelectedEntries([]);
    }
  };
  const handleAddSelection = (obj) => {
    setSelectedEntries((prevEntries) => [...prevEntries, obj]);
  };

  const handleRemoveSelection = (shipmentId, cellId, rackId) => {
    setSelectedEntries((prevEntries) =>
      prevEntries.filter(
        (entry) =>
          !(entry.shipmentId === shipmentId && entry.cellId === cellId && (!rackId || entry.rackId === rackId))
      )
    );
  };

  const handleCheckChange = (event, shipmentId, cellId, rackId, count) => {
    if (event.target.checked) {
      handleAddSelection({ shipmentId, cellId, rackId, count });
    } else {
      handleRemoveSelection(shipmentId, cellId, rackId);
    }
  };

  const countChangeHandler = (count, entityId, cellId, rackId) => {
    const updatedTableData = [...tableData];
    const index = updatedTableData.findIndex(
      (dl) => dl.entityId === entityId && dl.cellId === cellId && (!rackId || dl.rackId === rackId)
    );
    if (index !== -1) {
      updatedTableData.splice(index, 1, { ...updatedTableData[index], selectedCount: count > 0 ? count : "" });
      setTableData(updatedTableData);
    }
    const updatedSelectedEnriesData = [...selectedEntries];
    const ind = updatedSelectedEnriesData.findIndex(
      (dl) => dl.shipmentId === entityId && dl.cellId === cellId && (!rackId || dl.rackId === rackId)
    );
    if (ind !== -1) {
      updatedSelectedEnriesData.splice(index, 1, { ...updatedSelectedEnriesData[index], count: count > 0 ? count : "" });
      setSelectedEntries(updatedSelectedEnriesData);
    }
  };
  const fetchTableData = (page, limit) => {
    let payload = {
      jobComponentId: checkedRow?.selectedRow?.at(0)?.jobComponentId,
      page: page,
      limit: limit,
      isScan: false,
      fetch_from: 'web',
      unwindCell: true,
      // shipmentId:shipmentId,
      matchAll: true,
      matchRegex: true
    }
    if (shipmentFilter != "") {
      payload["shipmentId"] = shipmentFilter
    }
    setLoader(true)
    dispatch(getJobShipmentDetails(payload, (data = {}) => {
      setLoader(false)
      setTableDataCount(data?.count)
      let tempData = data?.data?.map(dl => {
        let countNumber = Number(dl?.count || 0) - Number(dl?.scannedCount || 0)
        let tempObj = { ...dl, totalCount: dl?.count || 0, remainingCount: countNumber, selectedCount: countNumber }
        delete tempObj["count"]
        delete tempObj["scannedCount"]
        let entityId, entityType, skuId, skuName;
        if (dl?.itemId) {
          entityId = dl?.itemId;
          entityType = "Item";
          skuId = dl?.SKU_Number;
          skuName = dl?.SKU_Name
        } else if (dl?.packageId) {
          entityId = dl?.packageId;
          entityType = "Package";
          skuId = dl?.packageName;
          skuName = dl?.packageDescription
        } else if (dl?.shipmentId) {
          entityId = dl?.shipmentId;
          entityType = "Shipment";
          skuId = "";
          skuName = ""
        }
        tempObj = { ...tempObj, entityId, entityType, skuId, skuName }
        return tempObj;
      })
      setTableData(tempData)

    }));
  }
  const renderTableCells = (item) => {
    return (
      <Typography sx={{ ...textClasses.normalText, color: theme.themeBlack }}>
        {item}
      </Typography>
    )
  }
  const tableHeaders = [<Checkbox
    data-testid="checkbox"
    checked={
      tableData?.length != 0 &&
      selectedEntries.length == tableData?.length
    }
    onChange={handleCheckAll}
    size='small' />, "S.No", "Entity ID", "Entity Type", "SKU ID", "SKU Name", "Total QTY", "Remaining QTY", "Location", "Enter QTY"]
  const tableRows = tableData?.map((row, index) => {

    let { entityId, entityType, skuId, skuName } = row
    const getLocation = () => {
      let location;
      if (row?.cellName) {
        location = `${row.cellName} ${row?.rackName ? `- ${row.rackName}` : ""
          }`;
      }
      return location;
    };
    let item = [];
    item.push(
      <Checkbox
        size="small"
        checked={
          selectedEntries.some(
            (entry) =>
              entry.shipmentId === row.entityId &&
              entry.cellId === row.cellId &&
              (!entry.rackId || entry.rackId === row.rackId)
          )
        }

        onChange={(e) => handleCheckChange(e, entityId, row?.cellId, row?.rackId, row?.selectedCount)}
      />
    )
    item.push(renderTableCells(index + 1))
    item.push(
      <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange }}>
        {entityId}
      </Typography>
    )
    item.push(
      renderTableCells(entityType)
    )
    item.push(
      renderTableCells(skuId)
    )
    item.push(
      renderTableCells(skuName)
    )
    item.push(
      renderTableCells(row?.totalCount)
    )
    item.push(
      renderTableCells(row?.remainingCount)
    )
    item.push(
      renderTableCells(getLocation())
    )
    item.push(
      <Typography sx={{ ...textClasses.normalText, }}>
        {<GenericSingleRangeComponent
          data-testid="rangeFilter"
          startRange={row?.selectedCount}
          endRange={row?.remainingCount}
          getOnChange={(count) => countChangeHandler(count, entityId, row?.cellId, row?.rackId)}
          valueType={"decimal"}
        />}
      </Typography>
    )
    return item;
  })

  const handleSubmitClick = () => {
    isDataReceived(true)
    let payload = {
      location: selectedEntries,
      jobComponentId: checkedRow?.selectedRow?.at(0)?.jobComponentId
    }
    dispatch(
      endJobFunction(payload, (data={}) => {
        showToast(data.message, !data.success);
        isDataReceived(false)
        if (data.success) {
          // callBack(data);
          setSelectedEntries([])
          get_Job_All({ ...initFilters, makeInitial: true }, true)
          if (data?.allScanned == true) {
            setCheckedRow({ visible: false, selectedRow: [], type: "" })
          }
          else {
            setPagination({ page: 0, limit: 100 })
            setShipmentFilter("")
          }
        }
      })
    )

  }
  useEffect(() => {
    fetchTableData(pagination?.page+1, pagination?.limit)
  }, [debouncedShipmentFilter, pagination])
  return (
    <AlertDialog
      viewDialog={["sendToUser"].includes(checkedRow?.type)}
      handleClose={() => setCheckedRow({ ...checkedRow, type: '' })}
      viewSearchInput={true}
      showDivider={true}
      width="80%"
      title="Select Inventory To Complete The Job"
      actionsCSS={{ justifyContent: 'flex-start' }}
      searchInputDisplay={
        <FormControl variant="outlined" size="small" fullWidth sx={{ width: 190, }}>
          {/* <Typography sx={{ display: 'inline-block', marginRight: 1, ...textClasses.t15nBold }} >
      Sales Order ID
    </Typography> */}
          <OutlinedInput
            data-testid="search-input"
            fullWidth
            value={jobInputId}
            onChange={handleInputChange}
            disabled={true}
            sx={{
              ...inputClasses.filterField,
              "&:focus": {
                borderColor: theme.themeOrange,
              },
              "&:before": {
                borderColor: theme.themeOrange,
              },
              "&:after": {
                borderColor: theme.themeOrange,
              },
              "& input::placeholder": { fontSize: "14px" },
            }}
            placeholder="Search By Job ID"
          // endAdornment={
          //     jobInputId == ""
          //         ? (
          //             <InputAdornment position="end">
          //                 <Search fontSize="small" />
          //             </InputAdornment>
          //         ) : (
          //             <IconButton
          //                 sx={{
          //                     visibility: jobInputId != "" ? "visible" : "hidden",
          //                     fontSize: "14px",
          //                 }}
          //                 onClick={() => {
          //                     setJobInputId("")
          //                 }}
          //             >
          //                 <Clear fontSize="inherit" />
          //             </IconButton>
          //         )
          // }
          />
        </FormControl>
      }
      body={
        <>
          {loader ?
            <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
              <Grid item><GenericLoader /></Grid>
            </Grid>
            :
            <Box sx={{ width: "inherit" }}>
              <Grid container sx={{ mb: 1,mt:2 }}>
                <Grid item sm={4}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <OutlinedInput
                      data-testid="searchFilter"
                      fullWidth
                      value={shipmentFilter}
                      onChange={handleShipmentFilterChange}
                      sx={{
                        ...inputClasses.filterField,
                        "&:focus": {
                          borderColor: theme.themeOrange,
                        },
                        "&:before": {
                          borderColor: theme.themeOrange,
                        },
                        "&:after": {
                          borderColor: theme.themeOrange,
                        },
                        "& input::placeholder": { fontSize: "14px" },
                      }}
                      placeholder="Search By Shipment ID, Package ID, Item ID "
                      endAdornment={
                        shipmentFilter == "" ? (
                          <InputAdornment position="end">
                            <Search fontSize="small" />
                          </InputAdornment>
                        ) : (
                          <IconButton
                            sx={{
                              visibility: shipmentFilter != "" ? "visible" : "hidden",
                              fontSize: "14px",
                            }}
                            onClick={() => {
                              setShipmentFilter("");
                              setPagination({ page: 0, limit: 100 })
                            }}
                          >
                            <Clear fontSize="inherit" />
                          </IconButton>
                        )
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <GenericTable
                data-testid="startJob-table"
                header={tableHeaders}
                rows={tableRows}
                pageCount={tableDataCount}
                pageNumber={pagination.page}
                handleChangePage={(event, pagevalue) => {
                  setPagination({ ...pagination, page: pagevalue })
                }}
                handleChangeRowsPerPage={(event) => {
                  setPagination({ ...pagination, limit: +event.target.value,page:0 })
                }}
                rowsPerPage={pagination.limit}
              />
            </Box>

          }
        </>
      }

    >

      <Button
        data-testid="cancelBtn"
        variant="outlined"
        size="small"
        onClick={() => setCheckedRow({ ...checkedRow, type: '', visible: false })}
        sx={{ ...buttonClasses.lynkitOrangeEmpty, width: '120px', mr: 1 }}
      >Cancel
      </Button>
      <Button
        data-testid="submitBtn"
        variant="contained"
        size="small"
        disabled={selectedEntries?.length == 0 || selectedEntries?.some(dl => !dl?.count) || dataReceieved}
        sx={{ ...buttonClasses.lynkitOrangeFill, width: '180px' }}
        onClick={handleSubmitClick}
      >
        Submit To Complete Job
      </Button>

    </AlertDialog>
  )

}

export default StartJobDispatch;

