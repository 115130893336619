import React, { useState } from "react";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField, Box, Grid } from "@mui/material";
import theme, { textClasses, inputClasses } from "../../../static/styles/theme";
import GenericMultiDateRange from "../../Components/Generic/GenericMultiDateRange";
import { selectStylesOverride } from "../../../utils/util";
const DateRangeFilter = ({ dateFilterOption = []}) => {
  const [selectedRange, setSelectedRange] = useState("7d");
  const [customRange, setCustomRange] = useState({ from: "", to: "" });
  const [dateFilter, setDateFilter] = useState([]);

  //   console.log("selectedRange", selectedRange, customRange, dateFilter);

  const handleSelection = (value) => {
    setSelectedRange(value);
    // onFilterChange(value, customRange);
  };

  const handleDateChange = (d) => {
    // console.log("Datae",d)
    setDateFilter(d);

  };
  return (
    <>
      {/* FormControl and RadioGroup */}
      <FormControl component="fieldset" sx={{ ...textClasses.t12n, color: theme.themeBlack }}>
        <RadioGroup row value={selectedRange} onChange={(e) => handleSelection(e.target.value)}>
          {dateFilterOption.map((item) => (
            <FormControlLabel
              key={item.value}
              value={item.value}
              control={
                <Radio
                  size="small"
                  sx={{
                    // color: theme.themeGray, // Unselected radio color
                    "&.Mui-checked": {
                      color: theme.themeOrange, // Selected radio color
                    },
                  }}
                />
              }
              label={item.category}
              sx={{
                "& .MuiTypography-root": {
                  fontSize: "12px",
                  //   color: theme.themeBlack // Label color
                }
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>

      {/* Custom Date Range Fields */}
      {selectedRange === "dateRange" &&
        <FormControl sx={{ ...selectStylesOverride, width: '30%', display: "flex", height: "100%" }} fullWidth>
          <GenericMultiDateRange
            label={"Custom Date Range"}
            getOnChange={(newValue) => handleDateChange(newValue || [])}
            value={dateFilter || []}
            inputStyle={inputClasses.filterField}
          />
        </FormControl>
      }
    </>

  );
};

export default DateRangeFilter;
