import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	FormControl, Grid, 
	Button, TextField,
	Typography, Tab, Tabs, Box, Checkbox, Autocomplete,
	Breadcrumbs
} from "@mui/material";
import colors, { buttonClasses, textClasses, inputClasses } from '../../../static/styles/theme';
import EmptyPage from "./EmptyPage";
import {
	Add, ExpandMore as ExpandMoreIcon, Search, BusinessCenter, Download, KeyboardArrowDown,
	FiberManualRecord as FiberManualRecordIcon
} from "@mui/icons-material";
import {
	getAllJobTypes, getAllJobs, getJobDetailV2, getJobProcessConfig, 
	getJobsStatus,
} from "../../../redux/actions/Job";
import { getAllWarehouses, getWarehouseFloors,getCompany } from '../../../redux/actions';
import theme from "../../../static/styles/theme";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useTheme, useMediaQuery } from '@mui/material';
import GenericTable from "../../Components/CustomTable/GenericTable"
import GenericCustomiseHeader from "../../Components/Generic/GenericCustomiseHeader";
import { useDebounce } from "../../../utils/hooks";
import { dateTimeFormater, capitalizeSentence, dataReadFunction, textCapitalize, renderHeadersFilter } from "../../../services/functions";
import { getAllUsers } from "../../../redux/actions";
import { getLabelKeyForJobConfig } from "./Constant";
import GenericFilterSection from "../../Components/Generic/GenericFilterSection";
import GenericLoader from "../../Components/Generic/GenericLoader";
import StartJobInward from "./JobCompletion/StartJobInward";
import StartJobDispatch from "./JobCompletion/StartJobDispatch";
import { multiDateToISOConvertor } from "../../Components/Generic/GenericMultiDateRange";
import ApproveJobCompletionSection from "./JobView/ApproveJobCompletionSection";
import AssignJobToUserSection from "./JobView/AssignJobToUserSection";
import PendencyDetailView from "./JobView/PendencyDetailView";
import JobDetail from "./JobView/JobDetail";

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}
const statusColor = {
	ongoing: "#FF7200",
	pending: "#FCCB3E",
	failed: "#E9001A",
	completed: "green",
};
const tabStautusMap = {
	0: "",
	1: "pending",
	2: "ongoing",
	3: "completed",
};

const initFilters = {
	"search": {},
    "other": {},
	page: 1,
	limit: 10,
	// jobStatus: "",
	// jobName: "",
	// jobType: "",
	// createdOn: null,
	// completedOn: null,
	// jobId: "",
	// shipmentId: "",
};

const initail_pendency = { pendencyType: "", items: [], title: "", pendencyColumn: "" }


export default function JobModule() {
	const dispatch = useDispatch();
    const muiTheme = useTheme();
    const isMd = useMediaQuery(muiTheme.breakpoints.up('md'));
    const isLg = useMediaQuery(muiTheme.breakpoints.up('lg'));
    const isSm = useMediaQuery(muiTheme.breakpoints.between('sm', 'md'));
	const history = useHistory();
	const loggedInUser = JSON.parse(localStorage.getItem("user")) || {};
	// let {jobId} = useParams();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const jobId = queryParams.get('jobId');
	const allWarehouses = useSelector((state) => state.inward.allWarehouses);
    const allFloors = useSelector((state) => state.inward.allFloors);
	const jobs = useSelector((state) => state.job.jobs.data);
	const jobCount = useSelector((state) => state?.job?.jobs?.totalCount);
	const { jobDetail} = useSelector((state) => state.job);
	const jobTypes = useSelector((state) => state?.job?.job_types?.data || []);
	const statusCount = useSelector((state) => state.job.jobs.statusCount);
	const allJobTypeName = jobTypes.map((jt) => ({ label: capitalizeSentence(jt.name), value: jt._id, _id: jt._id }));
	const { AllUserData } = useSelector((state) => state.users);
	const { userPermissions, sidebarList = [] } = useSelector((state) => state.userPermission);
	const { jobProcessConfig } = useSelector((state) => state?.job);//{jobCreationCategory=[]}

	const [tabIndex, setTabIndex] = useState(0);
	const [isViewDetail, setIsViewDetail] = useState("");
	const [checkedRow, setCheckedRow] = useState({ visible: false, selectedRow: [], type: "" });
	const [pendencyDetail, setPendencyDetail] = useState(initail_pendency)
	const [loader, setLoader] = useState(true);
	const [user, setUser] = useState({});
	const [condition, setCondition] = useState(true);
	const [filter, setFilter] = useState({ ...initFilters });
	const debouncedJobId = useDebounce(filter.jobId, 500);
	const debouncedShipmentId = useDebounce(filter.shipmentId, 500);
	const debouncedFilterSearch = useDebounce(filter?.search, 500);
	const [openAdvanceFilter, setOpenAdvanceFilter] = useState({right:false});
	const [advancedTempFilter, setAdvancedTempFilter] = useState(filter);
	const [warehouseFilter, setWarehouseFilter] = useState(null);
    const [floorFilter, setFloorFilter] = useState(null);
	let unassignedJob = [];
	(jobs || []).map((info, index) => {
		let { jobApprovalDetail = false, assignedTo = false, status, jobScanningType = "" } = info?.commonDetails || {}
		let condition1 = (!assignedTo || !assignedTo._id) && status == "pending"
		if (condition1) {
			unassignedJob.push({ groupBy: info?._id?.groupBy, isForApproval: false, isForUser: true })
		}
		else if (jobApprovalDetail?.isApprovalRequired && status == "completed" && !jobApprovalDetail.isApproved && Number(jobApprovalDetail?.allApporvals > 0) > 0) {
			unassignedJob.push({ groupBy: info?._id?.groupBy, isForApproval: true, isForUser: false })
		}
		let pendingCondition = status === "pending"
		if (status == "ongoing") {
			pendingCondition = assignedTo._id == loggedInUser?._id;
		}
		if (pendingCondition && (["gateIn"].includes(info?.items?.[0]?.jobType) || (info?.items?.[0]?.jobType == "sendToUser" && jobScanningType == "restricted"))) {
			if (condition1) {
				unassignedJob.splice(index, 0, {
					...unassignedJob[index],
					isForJobCompletion: true,
					floorId: info?.commonDetails?.shipmentDetail?.at(0)?.floorId,
					jobComponentId: info?.items?.at(0)?._id,
					jobType: info?.items?.at(0)?.jobType,
				});
			}
			else {
				unassignedJob.push({
					groupBy: info?._id?.groupBy,
					floorId: info?.commonDetails?.shipmentDetail?.[0]?.floorId,
					jobType: info?.items?.at(0)?.jobType,
					jobComponentId: info?.items?.at(0)?._id,
					isForJobCompletion: true
				})
			}
		}
	})

	const toggleDrawer = (anchor, open) => (event) => {
		if (
		  event &&
		  event.type === 'keydown' &&
		  (event.key === 'Tab' || event.key === 'Shift')
		) {
		  return;
		}
	
		setOpenAdvanceFilter({ ...openAdvanceFilter, [anchor]: open });
	  };

	const setPageHandler = (pageNo = 0) => {
        // setFilter({ ...filter, page: pageNo + 1 });
    }

    const setLimitHandler = (pageLimit = 10) => {
        // setFilter({ ...filter, limit: pageLimit });
    }
	const handleTabChange = (event, value) => {
		setTabIndex(value);
		const jobStatus = tabStautusMap[value];
		setFilter((f) => ({ ...f, jobStatus }));
		setIsViewDetail("")
		dispatch(getJobsStatus({ type: "all" }))
	};

	const handleRowSelection = (event, selected = {}) => {
		let checked = event.target.checked
		let temp_checkedRow = { ...checkedRow, type: "", visible: false }
		if (!temp_checkedRow["selectedRow"]) temp_checkedRow["selectedRow"] = []
		if (checked) {
			temp_checkedRow["selectedRow"].push(selected)
		}
		else {
			temp_checkedRow["selectedRow"] = temp_checkedRow["selectedRow"].filter(dl => dl?.groupBy != selected?.groupBy)
		}
		// console.log("temp_checkedRow",temp_checkedRow)
		setCheckedRow(temp_checkedRow)
	}

	const handleRowAllSelection = (event) => {
		let temp_checkedRow = { ...checkedRow, type: "", visible: false }
		let checked = event.target.checked
		if (!temp_checkedRow["selectedRow"]) temp_checkedRow["selectedRow"] = []
		if (checked) {
			let tempFilterJob = unassignedJob.filter(el => !temp_checkedRow?.selectedRow?.map(dl => dl.groupBy).includes(el.groupBy))
			let allJobId = [...tempFilterJob, ...temp_checkedRow["selectedRow"]]
			temp_checkedRow["selectedRow"] = allJobId
		}
		else {
			temp_checkedRow["selectedRow"] = []
		}
		// console.log("temp_checkedRow",temp_checkedRow)
		setCheckedRow(temp_checkedRow)
	}

	const tableHeader = useSelector((state) => state.customHeader["jobTable"]);
	let header = tableHeader && tableHeader?.validHeader && tableHeader.validHeader.map((h) => {
		if (h.handleType === "selectRow") {
			if (unassignedJob?.length > 0 && userPermissions?.permissions?.some(dl => ["jobReAssign", "approveJobCompletion", "manualJobEnd"].includes(dl?.permissionId))) {
				return(
					< Typography sx={{ ...textClasses.normalText, textAlign: "center", color: theme.themeOrange }}>
						<Checkbox
							checked={unassignedJob?.length > 0 ?
								unassignedJob?.every(dl => checkedRow?.selectedRow?.map(el => el.groupBy)?.includes(dl?.groupBy))
								: false
							}
							onClick={(e) => handleRowAllSelection(e)}
							size='small'
						/>
					</Typography >
				)
			
			}
			else{
				return("")
			}
		}
		return h.fieldName
	})
		|| [];

	const getTableCell = (header, row, i) => {
		let value = ""
		if (header?.mappingPath) {
			value = dataReadFunction({ name: header?.mappingPath || "", renderOption: header?.renderOption || [] }, row)
		}
		if (header?.handleType) {
			if (header?.handleType === "selectRow") {
				if (unassignedJob?.length > 0 && userPermissions?.permissions?.some(dl => ["jobReAssign", "approveJobCompletion", "manualJobEnd"].includes(dl?.permissionId))) {
					let isForApproval = (row?.commonDetails?.jobApprovalDetail?.isApprovalRequired || false) && !(row?.commonDetails?.jobApprovalDetail?.isApproved || false) && (row?.commonDetails?.jobApprovalDetail?.allApporvals || 0) > 0 && row?.commonDetails?.status == "completed"
					let isForUser = (!row?.commonDetails?.assignedTo || !row?.commonDetails?.assignedTo?._id) && row?.commonDetails?.status == "pending"
					let pendingCondition = row?.commonDetails?.status === "pending"
					if (row?.commonDetails?.status == "ongoing") {
						pendingCondition = row?.commonDetails?.assignedTo._id == loggedInUser?._id;
					}
					let isForJobCompletion = pendingCondition && (["gateIn"].includes(row?.items?.[0]?.jobType) || (row?.items?.[0]?.jobType == "sendToUser" && row?.commonDetails?.jobScanningType == "restricted"))
					if (isForApproval || isForUser || isForJobCompletion) {
						return (< Typography sx={{ ...textClasses.normalText, textAlign: "center", color: theme.themeOrange }}>
							<Checkbox
								data-testid="checkbox"
								size='small'
								key={row?._id?.groupBy + "/" + i}
								disabled={!isForUser && !isForApproval && !isForJobCompletion}
								checked={checkedRow?.selectedRow?.some(dl => dl?.groupBy == row?._id?.groupBy)}
								onClick={(e) => handleRowSelection(e, {
									groupBy: row?._id?.groupBy, isForUser, isForApproval, isForJobCompletion,
									floorId: row?.commonDetails?.shipmentDetail?.at(0)?.floorId || "",
									jobType: row?.items?.at(0)?.jobType || "",
									jobComponentId: row?.items?.at(0)?._id || ""
								})}
							/>
						</Typography >)
					}
					else {
						return ("")
					}
				}
			}
			else if (header?.handleType === "showDetail") {
				return (
					<Typography
						// onClick={() => handleRowAndViewClick(row)}
						onClick={() => {
							dispatch(getJobDetailV2({ jobId: row?._id?.groupBy, call_from: "web", page: 1, limit: 1 }));
							setIsViewDetail(row?._id?.groupBy);
						}}
						style={{
							...textClasses.normalText,
							color: theme.themeOrange,
							textAlign: "center",
							cursor: "pointer",
						}}
					>
						{value ?? "-"}
					</Typography>
				);
			}
			else if (header?.handleType === "handleAssignedTo") {
				return (
					row?.commonDetails?.assignedTo?._id ?
						<Typography
							sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", color: header?.color || theme.themeText }}
						>
							{value ||  "-"}
						</Typography>
						:
						userPermissions?.permissions?.some(dl => ["jobReAssign"].includes(dl?.permissionId)) && row?.commonDetails?.status == "pending"
							?
							<Typography
								variant="caption"
								key={row?._id?.groupBy}
								gutterBottom
								onClick={() => setCheckedRow({
									visible: true,
									selectedRow: [{ groupBy:row?._id?.groupBy, isForApproval: false, isForUser: true }],
									type: "assignTrigger"
								})}
								sx={{
									...buttonClasses.lynkitOrangeFill,
									pr: 1, pl: 1,
									fontWeight: "500",
									border: `1px solid ${theme.themeWhite}`,
									// color: theme.themeWhite,
									// backgroundColor: theme.themeOrange,
									// borderRadius: "12px",
									// fontWeight: "500",
									cursor: "pointer",
								}}
							>
								Assign Job
							</Typography>
							: "-"
				);
			}
			else if (header?.handleType === "viewPendency") {
				return (
					<Typography sx={{
						...textClasses.t13n,
						color: value ? theme.themeOrange : "black",
						textAlign: "center",
						cursor: value ? "pointer" : ""
					}}
						onClick={() => {
							if (value) {
								header?.fieldName == "Package Count" ?
									setPendencyDetail({ pendencyType: "packageWise", items: row?.items, title: "Package Wise Pendency", pendencyColumn: getLabelKeyForJobConfig(jobProcessConfig, "package", "label") }) :
									header?.fieldName == "Shipment Count" ?
										setPendencyDetail({ pendencyType: "shipmentWise", items: row?.items, title: "Shipment Wise Pendency", pendencyColumn: getLabelKeyForJobConfig(jobProcessConfig, "shipment", "label") }) :
										setPendencyDetail({ pendencyType: "itemWise", items: row?.items, title: "SKU Wise Pendency", pendencyColumn: getLabelKeyForJobConfig(jobProcessConfig, "item", "label") });
							}
						}}
					>
						{value || 0}
					</Typography>
				)
			}
			else if (header?.handleType === "action") {
				return (
					<Typography
						data-testid="dropdown"
						sx={{
							...textClasses.normalText,
							color: header?.color || theme.themeOrange,
							cursor: "pointer",
						}}
					// onClick={(e) => handleOpenAction(e, i)}
					>
						Action <KeyboardArrowDown />
					</Typography>
				);
			}
		}
		else if (header?.isFromRenderFunction === true) {
			let no;
			if (filter?.page === 0) {
				no = i + 1;
			} else {
				no = (filter?.page - 1) * filter?.limit + (i + 1);
			}
			if (header?.renderFunction === "srNo") {
				return (
					<Typography
						variant="caption"
						display="block"
						sx={{ textAlign: "center" }}
						gutterBottom

					>
						<FiberManualRecordIcon sx={{
							color: row?.commonDetails?.jobApprovalDetail?.rejectedBy?._id
								? theme.themeRed
								: (row?.commonDetails?.status == "completed" && ((row?.commonDetails?.jobApprovalDetail?.isApproved || true) || (row?.commonDetails?.jobApprovalDetail?.allApporvals || 0) == 0)) ? theme.themeGreen : theme.themeYellow
						}}
						/>						{no}
					</Typography>
				);
			}
			else if (header?.renderFunction === "status") {
				// let option = header?.values?.find(o => o.value == value) || {}
				return (
					<>
						<Typography
							variant="caption"
							// display="block"
							gutterBottom
							sx={{
								pr: 1, pl: 1,
								color: statusColor[row?.commonDetails?.status],
								fontWeight: "500",
								border: `1px solid ${statusColor[row?.commonDetails?.status]
									}`,
								borderRadius: "12px",
								fontWeight: "500",

							}}
						>
							{/* {option?.label || "-"} */}
							{row?.commonDetails?.status == "completed" && !(row?.commonDetails?.jobApprovalDetail?.isApproved || false) &&
								(row?.commonDetails?.jobApprovalDetail?.allApporvals || 0) > 0
								? "Under Approval"
								: textCapitalize(row?.commonDetails?.status || "-")
							}

						</Typography>
					</>
				);
			}
			
		}
		else {
			if (header?.fieldType === "array") {
				return (
					<Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "capitalize", color: header?.color || theme.themeText }}>
						{value && Array.isArray(value) ? value?.filter(dl=>!!dl).join(", ") : value || "-"}
						</Typography>
				);
			}
			else if (header?.fieldType === "date") {
				return (
					<Typography
						sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", color: header?.color || theme.themeText }}
					>
						{value ? dateTimeFormater(value) : "-"}
					</Typography>
				);
			}
			else {

				return (
					<Typography
						sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", textTransform: "capitalize", color: header?.color || theme.themeText }}
					>
						{(value) || "-"}
					</Typography>
				);
			}
		}
	};

	const tableRows = jobs?.map((row, i) => {
		let items = [];

		const validHeaders = tableHeader?.validHeader ?? [];
		for (const header of validHeaders) {
			items.push(getTableCell(header, row, i));
		}
		return items;
	});

    const isFilterApplied = (() => {
        if (tabIndex == 0 && filter?.other?.status) {
            return true;
        }
        let {status,...restOther}= { ...filter["other"] || {} }
        // delete other["status"]
        if (Object.values(restOther || {})?.some(dl => dl && (typeof dl == "object" && !Array.isArray(dl)? dl?.value : dl.length > 0)) || Object.values(filter["search"]).some(dl => dl && (typeof dl == "object" && !Array.isArray(dl)? dl?.value : dl.length > 0))) {
            return true;
        }
        return false;
    })();

	const handleClearFilter = () => {
        let tempFilter = { ...initFilters }
        tempFilter["jobStatus"] = tabIndex == 0 ? null : filter?.jobStatus || ""
        // tempFilter["other"]["status"] = tabIndex == 0 ? null : filter?.other?.status || ""
        setFilter(tempFilter)
		setIsViewDetail("")
    };


	const searchableFilterItems = useMemo(() => {
		if (!tableHeader || !tableHeader.validHeader) return [];
		return tableHeader.validHeader.filter((dl) => dl?.isSearchable) || [];
	  }, [tableHeader]);
	
	

	const refreshTableData = (pageNo, limitNo, fetchCount = false,makeInitial=false) => {

       

        const payload = {
            page: pageNo,
            limit: limitNo,
			jobStatus: filter?.jobStatus,
			warehouseId:warehouseFilter ? warehouseFilter._id : "",
            floorId: floorFilter ? floorFilter._id : "",
        }
		let allDynamicKeys= tableHeader?.validHeader?.filter(dl=>dl?.dynamic)?.map(dl=>dl?.payloadKey || dl?.mappingPath)
        if(makeInitial){
            setFilter({...initFilters,page: pageNo,limit: limitNo,})
        }
		else {
			// console.log(filter,"filter")
			if (filter && Object.keys(filter || {}).length > 0) {
				for (let key in filter) {
					if (key && Object.keys(filter[key] || {}).length > 0) {
						for (let filterKey in filter[key]) {
							// if (filterKey && Object.keys(filter[key][filterKey] || {}).length > 0) {
							if (allDynamicKeys?.includes(filterKey)) {
								// Object.assign(payload, filter[key][filterKey])
								payload[filterKey] = filter[key][filterKey]?.value || ""

							} else {
								payload[filterKey] = filter[key][filterKey] || ""
							}
						}
					}
				}
				// Process date filter items
				const dateFilterItems = searchableFilterItems?.filter(
					(item) => item?.searchInputType === "date"
				);
				dateFilterItems.forEach((element) => {
					const payloadKey = element?.payloadKey || element?.mappingPath || "";
					if (payloadKey && payload[payloadKey]) {
						payload[payloadKey] = multiDateToISOConvertor(payload[payloadKey]);
					}
				});
			}
		}
		setIsViewDetail("")
		setLoader(true);
		dispatch(getAllJobs(payload, () => {
			setLoader(false)
			if (fetchCount) dispatch(getJobsStatus({ type: "all",
                warehouseId: warehouseFilter ? warehouseFilter._id : "",
                floorId: floorFilter ? floorFilter._id : "",
            }))
			if (payload.makeInitial) {
				setFilter({ ...filter, initail: false, makeInitial: false })
			}

		}));
        // delete payload.status
		// if (fetchCount) dispatch(getJobsStatus({ type: "all" }))

    }


	const get_Job_All = (filer_d, fetchCount = false) => {
		refreshTableData(filer_d?.page,filer_d?.limit,fetchCount)
	}

	
	const list = (anchor) => (
<Grid container sx={{ mt: 6, width: isLg ? '20vw' : '30vw', p: 2 }} spacing={2}>
{renderHeadersFilter(
			  tableHeader?.validHeader?.filter(dl => dl.isSearchable && dl?.mappingPath), 
			  advancedTempFilter, 
			  (updatedTempFilter) => {
				setAdvancedTempFilter({...updatedTempFilter}); 
			  },
			  setPageHandler, 
			  setLimitHandler, 
			  tabIndex, 
			  true
			)}
	
		
		  <Grid container justifyContent="center" spacing={2} sx={{ mt: 2 }}>
			<Grid item >
			  <Button
				data-testid="clearall-btn"
				variant="outlined"
				size="small"
				sx={{
				  ...buttonClasses.outlined,
				  color: theme.themeOrange,
				  minWidth: "150px",
				}}
				onClick={() => {
				  // Optionally clear temp filters here if needed
				  setAdvancedTempFilter(initFilters);
				  toggleDrawer(anchor, false)();
				}}
			  >
				Clear All
			  </Button>
			</Grid>
			<Grid item>
			  <Button
				data-testid="submit-btn"
				variant="contained"
				size="small"
				sx={{
				  ...buttonClasses.small,
				  minHeight: "40px",
				  backgroundColor: theme.themeOrange,
				  minWidth: "150px",
				}}
				onClick={() => {
				  setFilter({ ...advancedTempFilter, page: 1, limit: 10 });
				  toggleDrawer(anchor, false)(); 
				}}
			  >
				Apply Filter(s)
			  </Button>
			</Grid>
		  </Grid>
		  </Grid>
	  );

	// const endJobAPI=()=>{
	// 	let payload={
	// 		"jobComponentId": "66584194a053d027887ac9c4",
	// 		"location": [
	// 			{
	// 				"count": 1, //give count in which u want to perform job
	// 				"shipmentId": "C-1900-S-160124/P2/I1"
	// 			}
	// 		]
	// 	}
	// 	for(let i=0;i<10;i++){
	// 		dispatch(endJobFunction(payload))
	// 	}
	// }


	useEffect(() => {
		
		if (!condition) {
			get_Job_All(filter, false)
		}
	}, [filter.other, debouncedFilterSearch, filter.page, filter.limit, filter.jobStatus, warehouseFilter, floorFilter]);

	useEffect(() => {
		
		if (!condition) {
			get_Job_All(filter, true)
		}
	}, [warehouseFilter, floorFilter]);

	useEffect(() => {
		if (condition || location) {
			if (jobId) {
				setLoader(true)
				dispatch(getJobDetailV2({ jobId, call_from: "web", page: 1, limit: 1 }, () => {
					setLoader(false)
				}));
				setIsViewDetail(jobId);
			}
			else {
				get_Job_All(initFilters, true)
				// get_Job_All({ ...initFilters, jobId }, !jobId)
			}
			dispatch(getAllJobTypes({ fetch_for: "getForFilter" }));
			dispatch(getAllUsers({ "approved": "all", "isAdminIncluded": true }));
			dispatch(getJobProcessConfig({ approved: true }));
			setCondition(false)
		}
	}, [location]);

	useEffect(() => {
		let temp = {}
		if (AllUserData?.data?.length) {
			for (let ele of AllUserData?.data) {
				temp[ele._id] = { name: ele.name, designation: ele.designation, email: ele.email }
			}
		}
		setUser(temp)
	}, [AllUserData]);

	useEffect(()=>{
        dispatch(getAllWarehouses({ type: "warehouse" }));
      },[])

      useEffect(() => {
        if (warehouseFilter) {
          dispatch(
            getWarehouseFloors({ type: "floor", warehouseId: warehouseFilter?._id })
          );
        }
      }, [warehouseFilter]);

	return (
		<Grid>
			{false ? (
				<EmptyPage />
			) : (
				<Box sx={{ flexShrink: 1 }}>
					<Grid container data-testid="job-dashboard">
						<Grid item sm={jobId ? 10 : 4} data-testid="job-header">
							<Typography sx={{ ...textClasses.cardTitle }}>
								{/* {jobId
									? "Job Detail"
									: "Manage Warehouse Jobs"
								} */}
								Manage Warehouse Jobs
							</Typography>
						</Grid>
							
						{!jobId && 
						<Grid
							container
							item
							sm={8}
							// xs={9}
							justifyContent="end"
							alignItems="end"
							gap={1}
						>
							<Autocomplete
								value={warehouseFilter}
								onChange={(e, option) => {
									setWarehouseFilter(option);
									setFilter({ ...filter, page: 1, limit: 10 });
									setFloorFilter(null)
								}}
								getOptionLabel={(option) => option.name}
								size="small"
								options={allWarehouses}
								sx={{
									...inputClasses.filterField,
									minWidth: "250px",
									mr: 1,
									".MuiInputBase-input": {
										fontSize: "15px",
										color: "#455A64",
									},
								}}
								renderInput={(params) => (
									<TextField
										sx={{ color: "#455A64", ...textClasses.normalText }}
										{...params}
										label="All Warehouse"
									/>
								)}
							/>
							{warehouseFilter && (
								<Autocomplete
									value={floorFilter}
									onChange={(e, option) => {
										setFloorFilter(option);
										setFilter({ ...filter, page: 1, limit: 10 });

									}}
									getOptionLabel={(option) => option.name}
									size="small"
									options={allFloors}
									sx={{
										...inputClasses.filterField,
										minWidth: "250px",
										mr: 1,
										".MuiInputBase-input": {
											fontSize: "15px",
											color: "#455A64",
										},
									}}
									renderInput={(params) => (
										<TextField
											sx={{ color: "#455A64", ...textClasses.normalText }}
											{...params}
											label="Select Floor"
										/>
									)}
								/>
							)}
							{userPermissions?.permissions?.find(dl => dl.permissionId == "viewJobType") && <Button
								variant="contained"
								size="small"
								startIcon={<BusinessCenter />}
								data-testid="viewJob-TypeButton"
								sx={buttonClasses.lynkitOrangeEmpty}
								onClick={() => {
									history.push("/dashboard/job/viewJobTypes")
								}}
							>
								View Job Types
							</Button>}
							{userPermissions?.permissions?.find(dl => ["createPickPackJob", "createPutAwayJob", "createStockTranferJob", "createReturnSellerJob", "createDispatchJob", "createNewJob"].includes(dl.permissionId)) && <Button
								variant="contained"
								size="small"
								data-testid="create-job-Button"
								startIcon={<Add />}
								sx={{
									...buttonClasses.lynkitOrangeFill,

								}}
								onClick={() => {
									history.push("/dashboard/jobs/create-job");
								}}
							>
								Create New Job
							</Button>}
						</Grid>}
					</Grid>

					{!jobId && <Box
						sx={{ borderBottom: 1, borderColor: "divider", mb: 1 }}
					>
						<Tabs
							value={tabIndex}
							aria-label="disabled tabs example"
							onChange={handleTabChange}
							style={{ height: "20px" }}
							sx={{
								"& .MuiTabs-indicator": {
									backgroundColor: theme.themeOrange,
								},
								"& .MuiTab-root.Mui-selected": {
									color: theme.themeOrange,
								},
							}}
							data-testid="job-tabs"
						>
							<Tab
								label={`All Jobs (${statusCount ? statusCount.total : 0
									})`}
								{...a11yProps(0)}
								sx={{ ...textClasses.normalText, textTransform: "none" }}
							/>
							<Tab
								label={`Pending Jobs (${statusCount ? statusCount.pending : 0
									})`}
								{...a11yProps(1)}
								sx={{ ...textClasses.normalText, textTransform: "none" }}
							/>
							<Tab
								label={`Ongoing Jobs (${statusCount ? statusCount.ongoing : 0
									})`}
								{...a11yProps(1)}
								sx={{ ...textClasses.normalText, textTransform: "none" }}
							/>
							<Tab
								label={`Completed Jobs (${statusCount ? statusCount.completed : 0
									})`}
								{...a11yProps(1)}
								sx={{ ...textClasses.normalText, textTransform: "none" }}
							/>
						</Tabs>
					</Box>}
					{!jobId && statusCount && statusCount.total ?
						<Grid container spacing={2} xs={12}>
								<GenericFilterSection
									validHeader={tableHeader?.validHeader?.filter(dl => dl.isSearchable && dl?.mappingPath)}
									filter={filter}
									setFilter={(tempFilter) => {
										setFilter({ ...tempFilter, page: 1, limit: 10 })
									}}
									setPage={setPageHandler}
									setLimit={setLimitHandler}
									tabIndex={tabIndex}
									advancedFilterPart={false}
								/>
                 
          
							{/* {viewClearButton() &&
								<Grid container item alignItems={"center"} xs={"auto"} data-testid="job-clear-filter">
									<Button type="button">
										<Typography
											sx={{
												...textClasses.boldText,
												color: theme.themeOrange, textDecoration: "underline", cursor: "pointer",
												textTransform: "none"
											}}
											onClick={() => {
												setFilter({ ...initFilters, jobStatus: filter.jobStatus });
												setIsViewDetail("")
												if (Object.values(filter["search"])?.some(dl => dl && dl.length > 0) || Object.values(filter["other"]).some(dl => dl && dl.length > 0)) {
													return true;
												}
												// setTabIndex(0);
												// setFilter({...initFilters,jobStatus:filter.jobStatus});
												// setTabIndex(
												// 	!jobStatus
												// 		? 0
												// 		: jobStatus == "pending"
												// 			? 1
												// 			: jobStatus == "ongoing"
												// 				? 2
												// 				: 3
												// );
											}}
										>
											Clear Filter(s)
										</Typography>
									</Button>
								</Grid>
							} */}
							<Grid container item xs={"auto"} alignItems={"flex-start"}>
								<GenericCustomiseHeader
									data-testid="customise-table"
									tableName={"jobTable"}
									tableType={"jobTable"}
								/>
							</Grid>
							{isFilterApplied && (
                            <Grid container item xs={"auto"}  alignItems={"center"}>
                                <Typography
                                    sx={{
                                        ...textClasses.boldText,
                                        color: theme.themeOrange,
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                    }}
                                    onClick={handleClearFilter}
                                >
                                    Clear Filter(s)
                                </Typography>
                            </Grid>
                        )}
						</Grid>
						: ""
					}

					{
						!jobId &&
						<>
							{checkedRow?.selectedRow?.some(dl => dl.isForUser) && userPermissions?.permissions?.some(dl => dl?.permissionId == "jobReAssign") &&

								<Button
									size="small"
									variant="contained"
									sx={{
										...buttonClasses.lynkitOrangeFill,
										mr: 1, mt: 1,
										textTransform: "none",
									}}
									onClick={() => setCheckedRow({ ...checkedRow, visible: true, type: "assignTrigger" })}
								>
									Assign Job
								</Button>
							}
							{
								userPermissions?.permissions?.find(dl => dl.permissionId == "manualJobEnd") &&
								checkedRow?.selectedRow?.length == 1 && checkedRow?.selectedRow?.some(dl => dl?.isForJobCompletion) &&
								<Button
									size="small"
									variant="contained"
									sx={{
										...buttonClasses.lynkitOrangeFill,
										mr: 1, mt: 1,
										textTransform: "none",
									}}
									onClick={() => setCheckedRow({ ...checkedRow, visible: true, type: checkedRow?.selectedRow?.at(0)?.jobType })}
								>
									Start Job
								</Button>
							}
							{checkedRow?.selectedRow?.length == 1 && checkedRow?.selectedRow?.some(dl => dl.isForApproval) &&
								userPermissions?.permissions?.some(dl => dl?.permissionId == "approveJobCompletion") &&

								<Button
									size="small"
									variant="contained"
									sx={{
										...buttonClasses.approvedButton,
										mr: 1, mt: 1,
									}}
									onClick={() => setCheckedRow({ ...checkedRow, visible: true, type: "approveTrigger" })}
								>
									Mark As Approved
								</Button>
							}
							{checkedRow?.selectedRow?.length == 1 && checkedRow?.selectedRow?.some(dl => dl.isForApproval) &&
								userPermissions?.permissions?.some(dl => dl?.permissionId == "approveJobCompletion") && <Button
									size="small"
									variant="contained"
									sx={{
										...buttonClasses.lynkitOrangeFill,
										mr: 1, mt: 1,
										textTransform: "none",
									}}
									onClick={() => setCheckedRow({ ...checkedRow, visible: true, type: "rejectTrigger" })}
								>
									Reject Job
								</Button>
							}
						</>
					}
					{
						jobId &&
						<Grid container item sm={9} xs={6}>
							<Breadcrumbs sx={{ mb: 1 }} data-testid="breadcrumb-viewkit">
								<Typography sx={{ ...textClasses.t13n, color: theme.themeOrange }}>
									<span style={{ color: theme.themeOrange, textDecoration: "none", cursor: 'pointer' }} onClick={() => history.goBack()}>
										Job Detail
									</span>
								</Typography>
								<Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>Job Id - {jobId}</Typography>
							</Breadcrumbs>
						</Grid>
					}
					{/*Complete Job UI */}
					{checkedRow?.visible == true && ["gateIn"].includes(checkedRow?.type) &&
						<StartJobInward
							checkedRow={checkedRow}
							setCheckedRow={setCheckedRow}
							get_Job_All={get_Job_All}
							initFilters={initFilters}
						/>
					}

					{checkedRow?.visible == true && ["sendToUser"].includes(checkedRow?.type) &&
						<StartJobDispatch
							checkedRow={checkedRow}
							setCheckedRow={setCheckedRow}
							get_Job_All={get_Job_All}
							initFilters={initFilters}
						/>
					}
					<Grid container sx={{ mt: 2 }}>
						{loader
							? <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>

								<Grid item><GenericLoader /></Grid>
							</Grid>
							: ""
						}
						{!loader && !jobId ?
							<Grid
								item
								md={jobId ? 12 : isViewDetail ? 8 : 12}
								sm={12} xs={12} order={{ xs: 2, sm: 2, md: 1 }}
								sx={{ display: { xs: isViewDetail ? "none" : "visible", sm: isViewDetail ? "none" : "visible", md: "unset" } }}
							// xs={12}
							// sx={{ transition: "all 1s" }}
							>
								<GenericTable
									data-testid="job-table"
									header={header}
									rows={tableRows}
									pageCount={jobCount}
									pageNumber={filter.page - 1}
									handleChangePage={(event, pagevalue) => {
										setFilter((f) => ({
											...f,
											page: pagevalue + 1,
										}));
									}}
									handleChangeRowsPerPage={(event) => {
										setFilter((f) => ({
											...f,
											limit: +event.target.value,
										}));
									}}
									rowsPerPage={filter.limit}
								/>
							</Grid>
							: ""
						}
						{isViewDetail && Object.keys(jobDetail).length ? (
							<JobDetail
								data-testid="job-card-detail"
								setIsViewDetail={setIsViewDetail}
								isViewDetail={isViewDetail}
								allJobTypeName={allJobTypeName}
								user={user}
								jobProcessConfig={jobProcessConfig}
								AllUserData={AllUserData}
								viewPageRender={!!jobId}
								checkedRow={checkedRow}
								setCheckedRow={setCheckedRow}
							/>
						) : null}
						{pendencyDetail && pendencyDetail?.pendencyType && pendencyDetail?.items?.length > 0 ? (
							<PendencyDetailView
								data-testid="pendency-card-detail"
								hide={() => setPendencyDetail(initail_pendency)}
								open={pendencyDetail?.pendencyType && pendencyDetail?.items?.length > 0 || false}
								pendencyDetail={pendencyDetail}
							/>
						) : null}
						{checkedRow?.visible && checkedRow?.selectedRow?.length > 0 && checkedRow?.type == "assignTrigger" ? (
							<AssignJobToUserSection
								setCheckedRow={setCheckedRow}
								checkedRow={{ ...checkedRow, selectedRow: checkedRow?.selectedRow?.filter(dl => dl.isForUser) }}
								setFilter={setFilter}
								setTabIndex={setTabIndex}
								get_Job_All={get_Job_All}
							/>
						) : null}
						{checkedRow?.visible && checkedRow?.selectedRow?.length > 0 && ["rejectTrigger", "approveTrigger"].includes(checkedRow?.type) ? (
							<ApproveJobCompletionSection
								setCheckedRow={setCheckedRow}
								checkedRow={{ ...checkedRow, selectedRow: checkedRow?.selectedRow?.filter(dl => dl.isForApproval) }}
								setFilter={setFilter}
								setTabIndex={setTabIndex}
								get_Job_All={get_Job_All}
							/>
						) : null}
					</Grid>

				</Box>
			)}
		</Grid>
	);
}
